import debounce from "lodash-es/debounce";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterActivities } from "../store/actions/filterActions";
import { getFiltersActivity } from "../store/selectors/filterSelectors";

const InlineSearchActivity: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(getFiltersActivity);

  const inputHandler = debounce((value: string) => {
    dispatch(
      changeFilterActivities({
        ...filters,
        search: {
          ...filters.search,
          input: value
        }
      })
    );
  }, 500);

  return (
    <div className="inline-search">
      <input
        placeholder={t("search.inline")}
        type="text"
        onChange={e => inputHandler(e.currentTarget.value)}
      />
    </div>
  );
};

export default InlineSearchActivity;
