import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useMemo, useState } from "react";

interface IProps {
  className?: string;
  title?: string;
}

const FormRow: FC<IProps> = ({ children, className = "", title }) => {
  const [isClosed, setIsClosed] = useState(false);

  const toggleSection = useCallback(() => {
    setIsClosed(x => !x);
  }, []);

  const classes = useMemo(() => `flex ${className}${isClosed ? " is-closed" : ""}`, [className, isClosed]);

  return (
    <div className="form-row">
      {title && (
        <div className="title pointer flex a-center" onClick={toggleSection}>
          <span className="caret-indicator">
            {isClosed ? (
              <FontAwesomeIcon icon={["fas", "caret-right"]} />
            ) : (
              <FontAwesomeIcon icon={["fas", "caret-down"]} />
            )}
          </span>
          <h1>{title}</h1>
        </div>
      )}
      <div className={classes}>{children}</div>
    </div>
  );
};

export default FormRow;
