import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { IAgreementHotel } from "../../interfaces/IHotel";
import AdminLayout from "../../layouts/AdminLayout";
import AdminSubRoute from "../../modules/adminPages/_components/AdminSubRoute";
import AgreementHotelEdit from "../../modules/adminPages/agreementHotel/AgreementHotelEdit";
import AgreementHotelList from "../../modules/adminPages/agreementHotel/AgreementHotelList";
import { api } from "../../services/api";
import { defaultAgreementHotel } from "../../utils/defaults";

const AdminHotelsPage: FC = () => {
  const match = useRouteMatch<{ guid: string }>();
  const { t } = useTranslation();
  const [hotels, setHotels] = useState<IAgreementHotel[]>([]);
  const [isBusy, setIsBusy] = useState(true);
  const fetchHotels = async () => {
    try {
      const { data } = await api().get("hotelagreement");
      if (data.agreement?.length) {
        setHotels(data.agreement);
      }
    } catch {}
    setIsBusy(false);
  };

  const selectedHotel = useMemo(() => {
    return hotels.find(h => h.guid === match.params.guid) ?? { ...defaultAgreementHotel };
  }, [match.params.guid, hotels]);

  useEffect(() => {
    fetchHotels();
  }, []);

  return (
    <AdminLayout>
      <div className="flex d-column">
        <AdminSubRoute />
        <aside className="admin-aside-menu gray-box ignore-top-left-radius">
          <Link className="agreement-hotel-add-button" to={"/admin/hotels"}>
            {t("admin.agreement.addNew")}
          </Link>
          <AgreementHotelList hotels={hotels} />
        </aside>
      </div>
      {!isBusy && <AgreementHotelEdit key={match.params.guid} hotel={selectedHotel} refresh={fetchHotels} />}
    </AdminLayout>
  );
};

export default AdminHotelsPage;
