import i18n from "i18next";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Initializer } from "./Initializer";
import store from "./store";
import "./styles/app.scss";
import en from "./translations/en.json";
import tr from "./translations/tr.json";
import languageStorage from "./utils/languageStorage";

i18n.init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  lng: languageStorage().current(),
  react: {
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
    wait: true
  },
  resources: {
    en: {
      translation: en
    },
    tr: {
      translation: tr
    }
  }
});

export const App = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Initializer />
      </I18nextProvider>
    </Provider>
  );
};
