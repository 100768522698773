import moment, { Moment } from "moment";
import React, { FC, useState, useEffect, memo } from "react";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import cx from "classnames";

type Props = {
  absolute?: boolean;
  startDate?: string;
  endDate?: string;
  isOpen: boolean;
  format?: string;
  onChange(startDate: string, endDate: string): void;
};

export const CustomDateRangePicker: FC<Props> = memo(
  ({ absolute = true, startDate, endDate, isOpen, format = "YYYY-MM-DD", onChange }) => {
    const [focus, setFocus] = useState<"startDate" | "endDate" | null>(null);
    const [parsedStartDate, setParsedStartDate] = useState(() => {
      const parsed = moment(startDate);
      return parsed.isValid() ? parsed : null;
    });
    const [parsedEndDate, setParsedEndDate] = useState(() => {
      const parsed = moment(endDate);
      return parsed.isValid() ? parsed : null;
    });

    const selectDates = (range: { startDate: Moment | null; endDate: Moment | null }) => {
      if (range.startDate) {
        setParsedStartDate(range.startDate);
      }
      if (range.endDate) {
        setParsedEndDate(range.endDate);
      }
    };

    useEffect(() => {
      if (isOpen) {
        setFocus("startDate");
      } else {
        setFocus(null);
      }
    }, [isOpen]);

    useEffect(() => {
      if (parsedStartDate?.isValid() && parsedEndDate?.isValid()) {
        onChange(parsedStartDate!.format(format), parsedEndDate!.format(format));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedStartDate, parsedEndDate]);

    return focus ? (
      <div
        className={cx("date-picker-box", {
          absolute,
        })}
      >
        <DateRangePicker
          daySize={30}
          block={!absolute}
          showDefaultInputIcon={false}
          endDateId="2"
          startDateId="1"
          focusedInput={focus}
          hideKeyboardShortcutsPanel
          noBorder
          numberOfMonths={2}
          startDate={parsedStartDate}
          endDate={parsedEndDate}
          onDatesChange={selectDates}
          onFocusChange={(input) => {
            if (input) {
              setFocus(input);
            }
          }}
          keepOpenOnDateSelect
          transitionDuration={0}
        />
      </div>
    ) : null;
  }
);
