import moment, { Moment } from "moment";
import React, { FC, useState, useEffect, memo } from "react";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import cx from "classnames";

type Props = {
  absolute?: boolean;
  date?: string;
  format?: string;
  allowPastDates?: boolean;
  onChange(date: string): void;
};

export const CustomDateSinglePicker: FC<Props> = memo(
  ({ absolute = true, allowPastDates = false, date, format = "YYYY-MM-DD", onChange }) => {
    const [parsedDate, setParsedDate] = useState(() => {
      const parsed = moment(date, format);
      return parsed.isValid() ? parsed : null;
    });

    const selectDates = (date: Moment | null) => {
      if (date?.isValid()) {
        setParsedDate(date);
      }
    };

    useEffect(() => {
      if (parsedDate?.isValid()) {
        onChange(parsedDate!.format(format));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parsedDate]);

    return (
      <div
        className={cx("date-picker-box", {
          absolute,
        })}
      >
        <SingleDatePicker
          isOutsideRange={(day: Moment) => {
            if (allowPastDates) {
              return false;
            } else {
              return moment().isAfter(day);
            }
          }}
          hideKeyboardShortcutsPanel
          noBorder
          numberOfMonths={1}
          id="1"
          date={parsedDate}
          focused
          onDateChange={selectDates}
          onFocusChange={() => null}
        />
      </div>
    );
  }
);
