import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOtherService } from "../../interfaces/IOtherService";
import { ActionTypes, ItemTypes } from "../../store/actions/basketActions";
import { currencyParser } from "../../utils/helpers";
import { Busy } from "../snippets/Busy";

interface IProps {
  offer: IOtherService;
  addToBasket(item: ItemTypes, action: ActionTypes): any;
}

const Offer: FC<IProps> = ({ offer, addToBasket }) => {
  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const addToBasketHandler = () => {
    setIsBusy(true);
    addToBasket(offer, offer.action_type)
      .then(() => {
        setIsBusy(false);
        setIsActive(false);
      })
      .catch(() => setIsBusy(false));
  };
  // const serviceIcon = () => {
  //   switch (offer.action_type) {
  //     case 'transfer':
  //       return <FontAwesomeIcon icon={['far', 'shuttle-van']}/>;
  //     case 'visa':
  //       return <FontAwesomeIcon icon={['far', 'passport']}/>;
  //     case 'viptransfer':
  //       return <FontAwesomeIcon icon={['far', 'shuttle-van']}/>;
  //     default:
  //       return <FontAwesomeIcon icon={['far', 'shuttle-van']}/>;
  //   }
  // };
  const cx = classNames("offer flex j-between", {
    active: isActive,
    "has-bg": offer.image_url
  });
  return (
    <div className={cx}>
      {isBusy && <Busy />}
      <div
        className="offer-content flex d-column bg-cover"
        style={{ backgroundImage: `url(${offer.image_url})`, backgroundSize: "cover" }}
      >
        <p className="offer-description">{offer.description}</p>
        <p className="offer-international">{offer.isinternational ? "International" : "Domestic"}</p>
        <div className="offer-actions flex d-column fill-space">
          <p className="price-tag">
            {offer.amount} {currencyParser(offer.currency)}
          </p>
          <button className="add-basket-button" onClick={addToBasketHandler}>
            <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
          </button>
          <div className="fill-space" />
          <p className="source fs-small">
            <span>{t("pnr.source")}:</span>
            {offer.source}
          </p>
        </div>
      </div>
      {/*<div className="fill-space">*/}
      {/*  {offer.image_url && <img className="offer-image" src={offer.image_url} alt={offer.action_type} />}*/}
      {/*</div>*/}
      <div className="offer-title">
        <h1 onClick={() => setIsActive(!isActive)}>{offer.title}</h1>
      </div>
      {/*<div className="fill-space"/>*/}
      {/*<div className="offer-icon">*/}
      {/*{serviceIcon()}*/}
      {/*</div>*/}
    </div>
  );
};

export default Offer;
