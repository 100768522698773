import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import toString from "lodash-es/toString";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { IActivity } from "../interfaces/IActivity";
import Thumbnail from "../libs/Image/Thumbnail";
import ActivityList from "./ActivityList";
import Dates from "./Dates";
import Gallery from "./Gallery";
import ActivityDetailsModal from "./modals/ActivityDetailsModal";

type Props = {
  activity: IActivity;
  activities?: IActivity[];
};

const ActivityCard: FC<Props> = ({ activity, activities }) => {
  const [galleryIsActive, setGalleryIsActive] = useState(false);
  const [detailsIsActive, setDetailsIsActive] = useState(false);
  const $description = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if ($description.current) {
      $description.current.innerHTML =
        toString($description.current.textContent).slice(0, 180) + "... <span class='bold'>read more</span>";
    }
  }, [activity]);

  const toggleGallery = () => {
    setGalleryIsActive(!galleryIsActive);
  };

  const gallery = useMemo(() => {
    if (activity.images && activity.images.length > 1) {
      return activity.images.map(i => ({
        url: i.url
      }));
    } else {
      return false;
    }
  }, [activity.images]);

  const image = useMemo(() => {
    return get(activity, "images[0].smallUrl", get(activity, "ThumbnailImage", "/images/default.png"));
  }, [activity]);

  const dates = [
    {
      date: activity.date,
      direction: "0"
    }
  ];

  return (
    <>
      {detailsIsActive && (
        <ActivityDetailsModal activity={activity} onClose={() => setDetailsIsActive(false)} />
      )}
      {galleryIsActive && gallery && <Gallery images={gallery} onClose={toggleGallery} />}
      <div className="hotel box is-relative">
        <div className="card flex column-gutter">
          <Thumbnail
            onClick={toggleGallery}
            width={75}
            height={75}
            rounded
            gap={10}
            layout="background"
            image={image}
          />
          <div className="info fill-space">
            <p className="name fs-prc bold">{activity.title}</p>
            <p
              onClick={() => setDetailsIsActive(true)}
              ref={$description}
              className="address fs-std pointer"
              dangerouslySetInnerHTML={{
                __html: activity.Description && activity.Description
              }}
            />

            <p className="fs-note c-gray source-note">{activity.source}</p>
          </div>
          <Dates dates={dates} />
        </div>
        <div>
          <p className="address fs-std">
            <FontAwesomeIcon icon={["fas", "map-marker"]} /> {activity.Address}
          </p>
        </div>
        {activities && (
          <div className="rooms">{<ActivityList selected={activity} activities={activities} />}</div>
        )}
      </div>
    </>
  );
};

export default ActivityCard;
