import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import tooltip from "react-tooltip";
import { IPnrFlight } from "../../interfaces/IPnr";
import { api } from "../../services/api";
import PolicyModal from "../modals/PolicyModal";
import { Busy } from "../snippets/Busy";
import { PnrTicket } from "./PnrTicket";

interface IProps {
  pnr: IPnrFlight;
}

const PnrTickets: FC<IProps> = ({ pnr }) => {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);
  const [isPolicyActive, setIsPolicyActive] = useState(false);

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  const sendVoucher = async (custGuid: string, OfferId: string, pnrNo: string) => {
    setIsBusy(true);
    try {
      await api().post("sendvoucher", {
        cust_guid: custGuid,
        offer_guid: OfferId,
        pnr: pnrNo
      });
    } catch {}
    setIsBusy(false);
  };

  const policyHandler = (bool: boolean) => {
    setIsPolicyActive(bool);
  };

  return (
    <>
      <PolicyModal
        active={isPolicyActive}
        policy={pnr.custlist[0].policy}
        onClose={() => setIsPolicyActive(false)}
      />
      {isBusy && <Busy />}
      {pnr.tktlist && pnr.tktlist.length > 0 ? (
        <div className="pnr-details">
          <div className="title flex j-between a-center is-relative">
            <span className="pnr-header-icon c-primary">
              <FontAwesomeIcon icon={["far", "user"]} />
            </span>
            <h1 className="text fs-med fill-space c-primary bold">{t("generic.title.personInfo")}</h1>
          </div>
          <div className="pnr-customer-list">
            <table className="table table-ticket is-fullwidth">
              <thead>
                <tr>
                  <th className="fs-med">{t("pnr.passenger")}</th>
                  <th className="fs-med">{t("pnr.ticketNo")}</th>
                  <th className="fs-med align-center">{t("pnr.voucher")}</th>
                  <th className="fs-med align-center">{t("pnr.policy")}</th>
                  {/*<th/>*/}
                </tr>
              </thead>
              <tbody>
                {pnr.tktlist.map((ticket, index) => (
                  <PnrTicket
                    sendVoucher={sendVoucher}
                    key={index}
                    pnr={pnr}
                    ticket={ticket}
                    policyHandler={policyHandler}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PnrTickets;
