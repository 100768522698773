import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";
import { getActivities } from "./activitySelectors";
import { getCars } from "./carSelectors";

export const getSelectedItemsState = (state: IStoreState) => state.selectedItems;

export const getSelectedActivityItems = createSelector(getSelectedItemsState, siState => siState.activity);

export const getSelectedCarItems = createSelector(getSelectedItemsState, siState => siState.car);

export const getSelectedActivity = (activityCode: string) =>
  createSelector(getActivities, getSelectedActivityItems, (activities, selectedItems) =>
    selectedItems && selectedItems[activityCode]
      ? selectedItems[activityCode]
      : activities.find(a => a.activityCode === activityCode)!
  );

export const getSelectedCar = (vehicleCategory: string) =>
  createSelector(getCars, getSelectedCarItems, (cars, selectedItems) =>
    selectedItems && selectedItems[vehicleCategory]
      ? selectedItems[vehicleCategory]
      : cars.find(a => a.VehicleCategory === vehicleCategory || a.DistUnitName === vehicleCategory)
  );
