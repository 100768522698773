import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Flight from "../components/Flight";
import Sort from "../components/Sort";
import { Statuses } from "../components/Statuses";
import { pay } from "../store/actions/bookingActions";
import { changeFilterFlightsSortBy, toggleFilterFlights } from "../store/actions/filterActions";
import { makeSearch } from "../store/actions/searchActions";
import { getBookingStatus } from "../store/selectors/bookingSelectors";
import {
  getCustomersValidForBasket,
  getCustomersValidForBooking
} from "../store/selectors/customerSelectors";
import { getFilterFlightSortBy } from "../store/selectors/filterSelectors";
import {
  getFinalFlights,
  getFlightSearchDone,
  getFlightsResultEmpty,
  getFlightStatuses
} from "../store/selectors/flightSelectors";
import { getLastSearch, isDoubleSearch } from "../store/selectors/searchSelectors";
import FilterFlight from "./FilterFlight";
import InlineSearchFlight from "./InlineSearchFlight";

interface IProps {}

const Flights: FC<IProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const bookingStatus = useSelector(getBookingStatus);
  const customersValidForBasket = useSelector(getCustomersValidForBasket);
  const customersValidForBooking = useSelector(getCustomersValidForBooking);
  const groupedFlights = useSelector(getFinalFlights);
  const isDouble = useSelector(isDoubleSearch);
  const isEmpty = useSelector(getFlightsResultEmpty);
  const search = useSelector(getLastSearch);
  const searchIsDone = useSelector(getFlightSearchDone);
  const sortBy = useSelector(getFilterFlightSortBy);
  const statuses = useSelector(getFlightStatuses);

  return statuses.some(s => s.fetched || s.fetching) ? (
    <div
      className={cx("box-container flights", {
        doubled: isDouble
      })}
    >
      <div className="title flex j-between a-center is-relative">
        <span className="result-header-icon fs-lrg">
          <FontAwesomeIcon icon={["fas", "plane"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary">{t("flight.resultTitle")}</h1>
        {searchIsDone ? (
          <>
            <div className="fill-space" />
            <Sort
              sortKeys={["price", "going", "returning"]}
              current={sortBy}
              onChange={key => dispatch(changeFilterFlightsSortBy(key))}
            />
            <InlineSearchFlight />
            <button onClick={() => dispatch(toggleFilterFlights())} className="button is-small filter-button">
              <FontAwesomeIcon icon={["fas", "filter"]} />
            </button>
          </>
        ) : (
          <Statuses statuses={statuses} />
        )}
        <FilterFlight />
      </div>
      {isEmpty && <p className="not-found-message">{t("search.notFound")}</p>}
      {isDouble && (
        <div className="double-flight-title">
          <h1>
            {search.orig} <FontAwesomeIcon icon={["far", "long-arrow-right"]} /> {search.dest}
          </h1>
          <h1>
            {search.dest} <FontAwesomeIcon icon={["far", "long-arrow-right"]} /> {search.orig}
          </h1>
        </div>
      )}
      <div className="result-list-set flex">
        <div
          className={cx("result-list", {
            "double-result": isDouble
          })}
        >
          {groupedFlights[0].map(flight => (
            <Flight
              customersValidForBasket={customersValidForBasket}
              customersValidForBooking={customersValidForBooking}
              flight={flight}
              bookingStatus={bookingStatus}
              pay={pay}
              makeSearch={makeSearch}
              key={flight.OfferId}
            />
          ))}
        </div>
        {isDouble && (
          <div className="result-list double-result">
            {groupedFlights[1].map(flight => (
              <Flight
                customersValidForBasket={customersValidForBasket}
                customersValidForBooking={customersValidForBooking}
                flight={flight}
                bookingStatus={bookingStatus}
                pay={pay}
                makeSearch={makeSearch}
                key={flight.OfferId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default memo(Flights);
