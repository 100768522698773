import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { CSSProperties, FC, useCallback, useContext, useLayoutEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AmenityList from "../../../components/snippets/AmenityList";
import Rating from "../../../components/snippets/Rating";
import { IHotel } from "../../../interfaces/IHotel";
import Thumbnail from "../../../libs/Image/Thumbnail";
import { getHotelsDetails } from "../../../store/selectors/hotelSelectors";
import { pwc } from "../../../utils/helpers";
import { HotelContext } from "../index";

interface IProps {
  hotel: IHotel;
  style?: CSSProperties;
}

const DetailsHotel: FC<IProps> = ({ hotel, style }) => {
  const { t } = useTranslation();

  const { selectedHotel, setSelectedHotel } = useContext(HotelContext);
  const hotelsDetails = useSelector(getHotelsDetails);
  const $hotelElement = useRef<HTMLDivElement>(null);

  const hotelDetail = useMemo(() => {
    return hotelsDetails[hotel.HotelCode];
  }, [hotel.HotelCode, hotelsDetails]);

  const images = useMemo(() => {
    return hotelDetail ? hotelDetail.images : [];
  }, [hotelDetail]);

  const isSelected = useMemo(() => selectedHotel && selectedHotel.HotelCode === hotel.HotelCode, [
    hotel,
    selectedHotel
  ]);

  useLayoutEffect(() => {
    if (isSelected && $hotelElement.current) {
      $hotelElement.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }, [isSelected]);

  const selectHandler = useCallback(() => {
    setSelectedHotel(hotel);
  }, [hotel, setSelectedHotel]);

  const cx = classNames("hotel-container flex", {
    agree: hotel.source === "AGREEMENT",
    "is-selected": isSelected
  });

  return (
    <div ref={$hotelElement} className={cx} onClick={selectHandler} style={style}>
      <div className="hotel box is-relative">
        {hotel.source === "AGREEMENT" && (
          <div className="promotion" data-tip="Agreement">
            <FontAwesomeIcon flip="horizontal" color="#F7941D" icon={["fas", "tag"]} />
          </div>
        )}
        <div className="card flex column-gutter">
          <Thumbnail width={75} height={75} rounded gap={10} layout="background" image={images} />
          <div className="info">
            <p className="name fs-prc c-black">{hotel.HotelName}</p>
            <div className="flex gutter-bottom-3">
              <Rating rating={hotel.Rating} className="fs-note" />
              <div className="distances">
                {!!hotel.centerDistance && (
                  <a
                    href={hotel.centerMapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-small c-black distance"
                    data-tip="Distance to the downtown"
                  >
                    <FontAwesomeIcon icon={["fas", "crosshairs"]} />
                    {hotel.centerDistance.toLocaleString("tr", {
                      maximumFractionDigits: 1
                    })}{" "}
                    km
                  </a>
                )}
                {!!hotel.airportDistance && (
                  <a
                    href={hotel.airportMapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-small c-black distance"
                    data-tip="Distance to the airport"
                  >
                    <FontAwesomeIcon icon={["fas", "plane-departure"]} />
                    {hotel.airportDistance.toLocaleString("tr", {
                      maximumFractionDigits: 1
                    })}{" "}
                    km
                  </a>
                )}
                {!!hotel.placeDistance && (
                  <a
                    href={hotel.placeMapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fs-small c-black distance place"
                    data-tip="Distance to POI"
                  >
                    <FontAwesomeIcon icon={["fas", "map-marked-alt"]} />
                    {hotel.placeDistance.toLocaleString("tr", {
                      maximumFractionDigits: 1
                    })}{" "}
                    km
                  </a>
                )}
              </div>
            </div>
            {/*<p className="fs-note c-gray source-note">{hotel.source}</p>*/}
          </div>
          {!!hotel.MinRate && (
            <div className="price-tag-box">
              <p className="price-tag-details">{pwc(hotel.MinRate, hotel.HotelCurrency, false)}</p>
              <p>'den başlayan fiyatlar</p>
            </div>
          )}
        </div>
        {!isSelected && (
          <p className="address fs-std c-black gutter-bottom-10">
            {hotel.HotelDescLong && hotel.HotelDescLong.slice(0, 80) + "..."}
          </p>
        )}
        <AmenityList small amenities={hotelDetail ? hotelDetail.JSONamenity : {}} />
        <p className="details-refundable">
          {hotel.rooms.some(r => !r.isNonRefundable) && t("generic.refundable")}
          {hotel.rooms.some(r => !!r.payAtHotel) && ", " + t("generic.noprepayment")}
        </p>
      </div>
    </div>
  );
};

export default DetailsHotel;
