import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { IExpenseBudgetSummary } from "../../pages/Company/CompanyExpenseBudgetPage";
import { chartColors } from "../../utils/constants";

function createSeries(field: string, name: string, chart: am4charts.XYChart) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = `${field}Percent`;
  series.dataFields.categoryY = "department";
  series.stacked = true;
  series.name = name;
  series.tooltipPosition = "pointer";
  series.columns.template.tooltipText = `[bold, white]${name}: {${field}Used}{currency}[/]`;
  series.strokeWidth = 0;
  series.fill = am4core.color(chartColors[name.toLowerCase()]);

  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.x = -40;
  labelBullet.label.text = `[bold, font-size: 12px]{${field}Used}{currency}[/]`;
  labelBullet.label.fill = am4core.color("#fff");
  labelBullet.label.truncate = false;
  labelBullet.label.hideOversized = true;
}

interface IProps {
  data: IExpenseBudgetSummary[];
  detailed?: boolean;
  showBudget?: boolean;
}

const ExpenseBudgetChart: FC<IProps> = ({ data, detailed = false, showBudget = true }) => {
  const container = useRef<HTMLDivElement>(null);

  const parsedData = useMemo(() => {
    return data.reduce(
      (output, d) => {
        const row = {
          currency: d.currency,
          department: d.department,
          remainingPercent: Math.round(100 * (1 - d.used / d.budget)),
          remainingUsed: d.budget,
          totalPercent: Math.round(100 * (d.used / d.budget)),
          totalUsed: d.used
        };
        d.items.forEach(i => {
          row[`${i.action}Percent`] = Math.round(100 * (i.used / d.budget));
          row[`${i.action}Used`] = i.used;
        });
        output.push(row);
        return output;
      },
      [] as Array<{
        [key: string]: any;
      }>
    );
  }, [data]);

  useEffect(() => {
    if (!container.current) {
      return;
    }
    const chart = am4core.create(container.current, am4charts.XYChart);
    chart.data = parsedData;
    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "department";
    categoryAxis.renderer.grid.template.opacity = 0;

    const valueAxisBase = new am4charts.ValueAxis();
    valueAxisBase.numberFormatter.numberFormat = "##'%'";

    const valueAxis = chart.xAxes.push(valueAxisBase);
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.title.text = "Percentage";
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 10;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.minGridDistance = 40;

    // Create series
    if (detailed) {
      createSeries("hotel", "Hotel", chart);
      createSeries("flight", "Flight", chart);
      createSeries("car", "Car", chart);
    } else {
      createSeries("total", "Expenses", chart);
    }
    createSeries("remaining", "Budget", chart);

    return () => chart.dispose();
  }, [container, detailed, parsedData]);
  return (
    <div className="chart-container">
      <div className="chart" ref={container} />
    </div>
  );
};

export default ExpenseBudgetChart;
