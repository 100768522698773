import i18n from "i18next";
import groupBy from "lodash-es/groupBy";
import map from "lodash-es/map";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import AppLoading from "../components/AppLoading";
import ExternalLogo from "../components/ExternalLogo";
import ProgramItems from "../components/ProgramItems";
import Qr from "../components/Qr";
import { IProgramVoucher } from "../interfaces/IProgram";
import { api } from "../services/api";
import "../styles/voucher.scss";

const ProgramVoucherPage: FC<RouteComponentProps> = () => {
  const location = useLocation();
  const [voucher, setVoucher] = useState<IProgramVoucher>();
  const [isEmpty, setIsEmpty] = useState(false);

  const query = useMemo(() => {
    return parse(location.search);
  }, [location]);

  const fetchVoucher = useCallback(async () => {
    if (query.lang === "en" || query.lang === "tr") {
      await i18n.changeLanguage(query.lang);
    }
    try {
      const { data } = await api().get("travelprogram", { params: query });
      if (data.voucher) {
        setVoucher(data.voucher);
      }
    } catch (e) {
      setIsEmpty(true);
    }
  }, [query]);

  const qrcode = useMemo(
    () => ({ guid: voucher && voucher.programs.length ? voucher.programs[0].travel_guid : "" }),
    [voucher]
  );

  const programsGroupedBydate = useMemo(() => {
    return voucher ? groupBy(voucher.programs[0].program_items, "item_date") : {};
  }, [voucher]);

  useEffect(() => {
    fetchVoucher();
  }, [fetchVoucher]);

  return (
    <div className="voucher-container">
      {voucher && voucher.programs && voucher.programs.length ? (
        <div className="voucher">
          <div className="overlay" />
          <div className="voucher-header">
            <div className="left">
              {voucher.agency && (
                <>
                  <ExternalLogo logos={voucher.agency.logos} />
                  <p className="address">{voucher.agency.address}</p>
                  <p>{voucher.agency.email}</p>
                  <p>{voucher.agency.phone}</p>
                </>
              )}
            </div>
            <div className="right">{voucher.agency && <ExternalLogo logos={voucher.company.logos} />}</div>
          </div>
          <div className="content">
            {<h1>{voucher.programs[0].title}</h1>}
            <Qr data={qrcode} />
            <div className="person">
              <p>
                {voucher.programs[0].description}
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="infos">
              {map(programsGroupedBydate, (programs, date) => (
                <ProgramItems items={programs} date={date} key={date} />
              ))}
            </div>
          </div>
          <div className="voucher-footer">
            <p>Powered by</p> <img src="/images/trippep-dark.png" alt="Trippep" />
          </div>
        </div>
      ) : isEmpty ? (
        <div className="voucher-not-found">
          <p>Voucher not found.</p>
        </div>
      ) : (
        <AppLoading />
      )}
    </div>
  );
};

export default ProgramVoucherPage;
