import React from "react";
import { IStatus } from "../interfaces/IState";
import Loading from "./Loading";

interface IStatusesComponentProps {
  statuses: IStatus[];
}

function Status(props: IStatus) {
  return <div className="status">{props.fetching && <Loading className="text c-primary" />}</div>;
}

export function Statuses(props: IStatusesComponentProps) {
  return (
    <div className="statuses flex">
      {props.statuses.map((status, key) => (
        <Status {...status} key={key} />
      ))}
    </div>
  );
}
