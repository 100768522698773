import includes from "lodash-es/includes";
import keys from "lodash-es/keys";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import toString from "lodash-es/toString";
import { createSelector } from "reselect";
import { IPnrSummary } from "../../interfaces/IPnr";
import { IStoreState } from "../../interfaces/IState";
import { getFiltersPnrItems, getFiltersPnrSearch } from "./filterSelectors";

export const getPnrState = (state: IStoreState) => state.pnrs;

export const getPnrs = createSelector(getPnrState, pnrState => pnrState.pnrs);

export const getPnrStatus = createSelector(getPnrState, pnrState => pnrState.status);

export const getFilteredPnrs = createSelector(getPnrs, getFiltersPnrItems, (pnrs, pnrItems) =>
  pnrs.filter(pnr => pnrItems.includes(toLower(pnr.action_type)))
);

export const getSearchedPnrs = createSelector(
  getFilteredPnrs,
  getFiltersPnrSearch,
  (pnrs, { input, type, searchable }) => {
    if (input.length < 3) {
      return pnrs;
    }
    return filterHandler<IPnrSummary>(pnrs, input, type, searchable);
  }
);

function filterHandler<T>(
  items: T[],
  input: string,
  type: "includes" | "startsWith",
  searchable: Array<string | { [key: string]: "includes" | "startsWith" }>
): T[] {
  const keywords = input.split(" ");

  return items.filter(item =>
    keywords.every(word =>
      keys(item).some(param => {
        if (includes(searchable, param) || searchable[param]) {
          return type === "startsWith" || searchable[param] === "startsWith"
            ? startsWith(toLower(toString(item[param])), toLower(word))
            : includes(toLower(toString(item[param])), toLower(word));
        }
        return false;
      })
    )
  );
}

//
// export const getDetailedPnrs = createSelector(getPnrState, pnrState => getDe)
