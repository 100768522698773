import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import isEmpty from "lodash-es/isEmpty";
import React, { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { IBookingOptions, ICheckoutData, IPaymentRequest } from "../interfaces/IBooking";
import { IAiritinprice, IDestination, IFlight } from "../interfaces/IFlight";
import { IBookingStatus } from "../interfaces/IState";
import { FlightAlternatives } from "../modules/flightModule/components/flightAlternatives";
import { resetAndAddtoBasket } from "../store/actions/basketActions";
import { selectFlightAlternative } from "../store/actions/flightActions";
import {
  setBasketData,
  setBasketIsPending,
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { getFlightAlternatives } from "../store/selectors/flightSelectors";
import { ReduxDispatch } from "../types";
import { pwc } from "../utils/helpers";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";
import DestinationCard from "./DestinationCard";
import { Busy } from "./snippets/Busy";

interface IProps extends WithTranslation {
  bookingStatus: IBookingStatus;
  customersValidForBooking: boolean;
  customersValidForBasket: boolean;
  flight: IFlight;
  returning?: boolean;
  style?: CSSProperties;
  makeSearch(): void;
  pay(requestData: IPaymentRequest, paymentMethod: IBookingOptions): void;
  toggle?(offerId: string): void;
}

const Flight: FC<IProps> = ({ flight, returning = false, style = {}, t, toggle, ...props }) => {
  const dispatch = useDispatch<ReduxDispatch>();
  const [detailIsActive, setDetailIsActive] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const selectedAlternatives = useSelector(getFlightAlternatives);

  const toggleDetail = useCallback(() => {
    setDetailIsActive(x => !x);
    toggle?.(flight.OfferId);
  }, [flight.OfferId, toggle]);

  const renderDestinations = useCallback(
    (dests: IDestination[]) => {
      let direction = "";
      return dests.map((dest, index) => {
        const transit = direction === dest.DirectionId;
        direction = dest.DirectionId;

        return <DestinationCard detailed={detailIsActive} transit={transit} destination={dest} key={index} />;
      });
    },
    [detailIsActive]
  );

  const selectedAlternative = useMemo(() => {
    return selectedAlternatives[flight.OfferId] ?? selectedAlternatives[0] ?? flight.airitinprice;
  }, [flight.OfferId, flight.airitinprice, selectedAlternatives]);

  const selectAlternative = useCallback(
    (alt: IAiritinprice) => {
      dispatch(selectFlightAlternative(flight.OfferId, alt));
    },
    [dispatch, flight.OfferId]
  );

  const checkoutData: ICheckoutData = useMemo(() => {
    const finalFlight = { ...flight, airitinprice: selectedAlternative };
    return {
      actionType: "flight",
      amount: finalFlight.airitinprice.TotalFare,
      currency: finalFlight.Currency,
      guid: finalFlight.OfferId,
      selectedAlternative,
      items: { flights: [finalFlight] }
    };
  }, [flight, selectedAlternative]);

  return (
    <div
      className={cx("flight-container flex is-relative", {
        detailed: detailIsActive
      })}
      style={style}
    >
      {isBusy && <Busy />}
      <div className={"flight flex d-column fill-space box is-relative"}>
        <div className="extensible">
          <div onClick={toggleDetail}>
            <div className="detail-indicator">
              {detailIsActive ? (
                <FontAwesomeIcon icon={["far", "chevron-up"]} />
              ) : (
                <FontAwesomeIcon icon={["far", "chevron-down"]} />
              )}
            </div>
            <p className="fs-note c-gray source-note">{flight.source}</p>
            {flight.airitin.origdests && renderDestinations(flight.airitin.origdests)}
          </div>
          {!isEmpty(flight.alternatives) && (
            <FlightAlternatives
              selected={selectedAlternative}
              onSelect={selectAlternative}
              alternatives={flight.alternatives!}
              currency={flight.Currency}
            />
          )}
        </div>
      </div>
      <div className="controllers">
        <p className="price-tag fs-prc is-centered">
          {pwc(selectedAlternative.TotalFare, flight.Currency, false)}
        </p>
        <ButtonWithConfirm
          actions={[
            {
              byPassAction: true,
              color: "success",
              handler() {
                setIsBusy(true);
                dispatch(resetAndAddtoBasket(checkoutData.items.flights![0], "flight"))
                  .then(() => setIsBusy(false))
                  .catch(() => setIsBusy(false));
              },
              text: "Proceed"
            },
            {
              color: "success",
              handler() {
                batch(() => {
                  dispatch(setBasketData(checkoutData.items.flights![0], "flight"));
                  dispatch(toggleCustomerScreen());
                  dispatch(setBasketIsPending(true));
                });
              },
              text: "Check Customers"
            }
          ]}
          className="button cart"
          message={t("customer.notValidBooking")}
          byPass={props.customersValidForBooking}
        >
          <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
        </ButtonWithConfirm>
        <ButtonWithConfirm
          actions={[
            {
              byPassAction: true,
              color: "success",
              handler() {
                dispatch(setCheckoutScreenData(checkoutData, true));
              },
              text: "Proceed"
            },
            {
              color: "success",
              handler() {
                batch(() => {
                  dispatch(setCheckoutScreenData(checkoutData));
                  dispatch(toggleCustomerScreen());
                  dispatch(setCheckoutIsPending(true));
                });
              },
              text: "Check Customers"
            }
          ]}
          className="button buy"
          message={t("customer.notValidBooking")}
          byPass={props.customersValidForBooking}
        >
          <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
        </ButtonWithConfirm>
      </div>
    </div>
  );
};

export default withTranslation()(Flight);
