import "moment/locale/tr";
import React from "react";
import { render } from "react-dom";
import { App } from "./App";
import "./plugins/fontAwesome";
import "./plugins/vendorStyles";
import "react-dates/initialize";

window.airports = [];

render(<App />, document.getElementById("root"));
