import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IDestination } from "../../interfaces/IFlight";
import { findAirportByCode } from "../../utils/airportFunctions";
import { dayMonth } from "../../utils/helpers";
import Rules from "../Rules";
import FlightLogo from "../snippets/FlightLogo";

interface IPaymentFlightComponentProps {
  dest: IDestination;
  offerId: string;
}

export default function PaymentFlight({ dest, offerId }: IPaymentFlightComponentProps) {
  return (
    <div className="flight-item flex a-center">
      <div className="item-icon">
        <FontAwesomeIcon transform={{ rotate: -45 }} icon={["fas", "plane"]} />
      </div>
      <div className="contents full-width">
        <div className="content top flex a-end">
          <p className="fs-med">{dayMonth(dest.DepDate)}</p>
          <span className="middot" />
          <p className="fs-med">
            <FlightLogo className={"text-image fs-small"} code={dest.MarketingAirline} />
            <br />
            <span>
              {dest.MarketingAirline} {dest.FlightNumber}
            </span>
          </p>
          <span className="middot" />
          <div className="flex a-center self-end">
            <div className="flex d-column">
              <span className="text fs-med">{findAirportByCode(dest.DepartureAirport).state}</span>
              <span className="text fs-small">{dest.DepTime}</span>
            </div>
            <FontAwesomeIcon icon={["fas", "plane"]} style={{ margin: "0 10px" }} />
            <div className="flex d-column">
              <span className="text fs-med">{findAirportByCode(dest.ArrivalAirport).state}</span>
              <span className="text fs-small">{dest.ArrTime}</span>
            </div>
          </div>
        </div>
        <hr />
        <div className="content bottom flex">
          <Rules action="flight" id={offerId} />
        </div>
      </div>
    </div>
  );
}
