import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext } from "react";
import { SearchContext } from "./Search";

interface IProps {
  propKey: string;
  title: string;
  value: number;
}

const Counter: FC<IProps> = ({ propKey, title, value }) => {
  const setSearch = useContext(SearchContext)[1];

  const decrement = () => {
    setSearch({ [propKey]: value - 1 });
  };

  const increment = () => {
    setSearch({ [propKey]: value + 1 });
  };

  return (
    <div className="counter">
      <span className="counter-name">{title}</span>
      <div className="counter-controller">
        <button onClick={decrement}>
          <FontAwesomeIcon icon={["far", "minus"]} />
        </button>
        <span>{value}</span>
        <button onClick={increment}>
          <FontAwesomeIcon icon={["far", "plus"]} />
        </button>
      </div>
    </div>
  );
};

export default Counter;
