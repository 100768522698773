import { ChangeEvent, useState } from "react";

function useTextField(initialValue = "") {
  const [value, setValue] = useState(initialValue);

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  return { value, onChange };
}

export default useTextField;
