import React from "react";
import { NavLink } from "react-router-dom";
import { ICustomer } from "../../../interfaces/ICustomer";

interface IComponentProps {
  customers: ICustomer[];
}

class PolicyCustomerList extends React.Component<IComponentProps> {
  public render() {
    const { customers } = this.props;
    return (
      <div className="agreement-hotel-list">
        {customers.map(c => (
          <NavLink
            key={c.cust_guid}
            activeClassName="selected"
            to={`/admin/policies/${c.cust_guid}`}
            className="agreement-hotel"
          >
            <p>
              {c.firstname} {c.lastname}
            </p>
          </NavLink>
        ))}
      </div>
    );
  }
}

export default PolicyCustomerList;
