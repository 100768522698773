import React, { FC, useMemo, useRef } from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import { GOOGLE_MAP_API } from "../utils/constants";

interface IProps {
  marker: { lat: any; lng: any };
}

const ActivityMap: FC<IProps> = ({ marker }) => {
  const map = useRef<GoogleMap>(null);

  const m = useMemo(() => {
    return {
      lat: parseFloat(marker.lat),
      lng: parseFloat(marker.lng)
    };
  }, [marker]);

  return (
    <GoogleMap
      ref={map}
      defaultZoom={10}
      center={m}
      options={{ fullscreenControl: false, streetViewControl: false }}
    >
      {marker && <Marker position={m} />}
    </GoogleMap>
  );
};

export default compose<any, any>(
  withProps({
    containerElement: <div style={{ position: "fixed", top: 0, right: 0, left: 0, bottom: 0, zIndex: -1 }} />,
    googleMapURL: GOOGLE_MAP_API,
    loadingElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap
)(ActivityMap);
