import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, ReactNode } from "react";

interface IProps {
  children: ReactNode;
  className?: string;
  color?: string;
  dimension?: boolean;
  error?: any;
  loading?: boolean;
}

const LoadingWrapper: FC<IProps> = ({
  children,
  className = "",
  color = "#231F20",
  dimension = true,
  error,
  loading = true
}) => {
  const cx = classNames("loading", {
    [className]: className,
    dimension
  });

  return (
    <>
      {loading && (
        <div className={cx}>
          <FontAwesomeIcon color={color} icon={["fas", "spinner"]} pulse={true} />
        </div>
      )}
      {error && <p>There was an error. Please try again.</p>}
      {!loading && !error && children}
    </>
  );
};

export default LoadingWrapper;
