import { Reducer } from "redux";
import { IBasketState } from "../../interfaces/IState";
import {
  BASKET_CHANGE_CURRENCY,
  BASKET_CHANGE_PAYMENT_METHOD,
  BASKET_CLEAR,
  BASKET_FETCH_EMPTY,
  BASKET_FETCH_FULFILLED,
  BASKET_FETCH_PENDING,
  BASKET_FETCH_REJECTED,
  BASKET_RESET_FULFILLED,
  BASKET_RESET_PENDING,
  BASKET_RESET_REJECTED
} from "../../utils/constants";
import { defaultBasket } from "../../utils/defaults";

const basketReducer: Reducer<IBasketState> = (state = defaultBasket, action) => {
  switch (action.type) {
    case BASKET_RESET_PENDING:
      return {
        ...state,
        status: {
          fetched: false,
          fetching: true
        }
      };
    case BASKET_RESET_FULFILLED:
      return { ...defaultBasket };
    case BASKET_RESET_REJECTED:
    case BASKET_FETCH_PENDING: {
      return {
        ...state,
        status: {
          fetched: false,
          fetching: true
        }
      };
    }
    case BASKET_FETCH_FULFILLED:
      return {
        ...state,
        basket: {
          ...action.payload,
          basketguid:
            !action.payload.basketguid || action.payload.basketguid === "null"
              ? ""
              : action.payload.basketguid,
          cars: action.payload && action.payload.cars ? action.payload.cars : [],
          currency: action.payload && action.payload.currency ? action.payload.currency : "TRY",
          flights: action.payload && action.payload.flights ? action.payload.flights : [],
          hotels: action.payload && action.payload.hotels ? action.payload.hotels : []
        },
        status: {
          fetched: true,
          fetching: false
        }
      };
    case BASKET_FETCH_EMPTY:
      return {
        ...state,
        status: {
          fetched: true,
          fetching: false
        }
      };
    case BASKET_CLEAR:
      return { ...defaultBasket };
    case BASKET_FETCH_REJECTED:
      return {
        ...state,
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
    case BASKET_CHANGE_CURRENCY:
      return {
        ...state,
        basket: {
          ...state.basket,
          currency: action.payload
        }
      };
    case BASKET_CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default basketReducer;
