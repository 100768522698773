import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

interface IProps {
  checked: boolean | undefined;
  className?: string;
  onClick?(): any;
}

const CheckboxSimple: FC<IProps> = ({ checked, children, className, onClick }) => {
  const classes = `checkbox-simple pointer ${className ? className : ""}`.trim();
  return (
    <span className={classes} onClick={onClick}>
      {checked ? (
        <FontAwesomeIcon icon={["fas", "check-square"]} />
      ) : (
        <FontAwesomeIcon icon={["far", "square"]} />
      )}
      {children && children}
    </span>
  );
};

export default CheckboxSimple;
