import { Reducer } from "redux";
import { IUserState } from "../../interfaces/IState";
import {
  USER_CHECK_AUTH_FULFILLED,
  USER_CHECK_AUTH_PENDING,
  USER_CHECK_AUTH_REJECTED,
  USER_COMPANY_CHANGED,
  USER_COMPANY_LOADED,
  USER_GENERATE_TABID,
  USER_HAS_ACCESS_TO_PAGE,
  USER_LOADED,
  USER_LOGIN_FULFILLED,
  USER_LOGIN_PENDING,
  USER_LOGIN_REJECTED,
  USER_LOGOUT_FULFILLED,
  USER_LOGOUT_REJECTED
} from "../../utils/constants";
import { defaultUser } from "../../utils/defaults";

const initialState: IUserState = {
  authenticated: false,
  user: defaultUser
};

const userReducer: Reducer<IUserState> = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADED:
      return {
        authenticated: true,
        user: action.payload
      };
    case USER_LOGIN_PENDING:
      return {
        ...state
      };
    case USER_LOGIN_FULFILLED:
    case USER_COMPANY_CHANGED:
      return {
        ...state,
        authenticated: true,
        user: {
          ...state.user,
          ...action.payload
        }
      };
    case USER_COMPANY_LOADED:
      return {
        ...state,
        user: {
          ...state.user,
          companies: action.payload
        }
      };
    case USER_LOGIN_REJECTED:
      return {
        ...state,
        authenticated: false,
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
    case USER_LOGOUT_FULFILLED:
      return {
        ...state,
        authenticated: false,
        user: {} as any
      };
    case USER_LOGOUT_REJECTED:
      return {
        ...state,
        user: {} as any
      };
    case USER_CHECK_AUTH_PENDING:
      return {
        ...state
      };
    case USER_CHECK_AUTH_FULFILLED:
      return {
        ...state,
        authenticated: true,
        status: {
          error: false,
          fetched: true,
          fetching: false
        },
        user: JSON.parse(localStorage.getItem("user")!)
      };
    case USER_HAS_ACCESS_TO_PAGE:
      return {
        ...state,
        status: {
          error: false,
          fetched: true,
          fetching: false
        }
      };
    case USER_CHECK_AUTH_REJECTED:
      return {
        ...state,
        authenticated: false
      };
    case USER_GENERATE_TABID:
      return {
        ...state,
        tabId: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default userReducer;
