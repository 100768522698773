import map from "lodash-es/map";
import uniq from "lodash-es/uniq";
import React, { FC, useCallback } from "react";
import Scrollbar from "react-custom-scrollbars";

interface IProps {
  label?: string;
  options: {
    [key: string]: string;
  };
  propKey: string;
  multiple: boolean;
  selected: string[];

  onChange(state: { [key: string]: string[] }): void;
}

const FilterSelect: FC<IProps> = ({ label, options, propKey, multiple, selected, onChange }) => {
  const selectHandler = useCallback(
    (key: string) => () => {
      let newFilter: {};

      if (multiple) {
        if (selected.includes(key)) {
          newFilter = {
            [propKey]: selected.filter(a => a !== key)
          };
        } else {
          newFilter = {
            [propKey]: uniq([...selected, key])
          };
        }
      } else {
        if (selected.includes(key)) {
          newFilter = {
            [propKey]: selected.filter(a => a !== key)
          };
        } else {
          newFilter = {
            [propKey]: [key]
          };
        }
      }
      onChange(newFilter);
    },
    [propKey, multiple, selected, onChange]
  );

  return (
    <div className="filter-select-element flex a-center">
      {label && (
        <p className="select-title" style={{ marginRight: 5 }}>
          {label}:
        </p>
      )}
      <div className="options">
        <Scrollbar>
          {map(options, (value, key) => (
            <p
              onClick={selectHandler(key)}
              className={`option ${selected.includes(key) ? " selected" : ""}`}
              key={key}
            >
              {value}
            </p>
          ))}
        </Scrollbar>
      </div>
    </div>
  );
};

export default FilterSelect;
