import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IBasketFlight } from "../interfaces/IBasket";
import { IBookingOptions, IProvisionCustomer } from "../interfaces/IBooking";
import { findAirportByCode } from "../utils/airportFunctions";
import { COLORS } from "../utils/constants";
import { pwc } from "../utils/helpers";
import Dates from "./Dates";
import BasketLine from "./snippets/BasketLine";
import FlightLogo from "./snippets/FlightLogo";

interface IProps {
  flight: IBasketFlight;
  custguid: IProvisionCustomer[];
  bookingOffer(payload: IBookingOptions): void;
  deleteItem?(OfferId: string): void;
}

const OrderFlight: FC<IProps> = ({ flight, custguid, bookingOffer }) => {
  const { t } = useTranslation();

  const bookingOfferHandler = () => {
    bookingOffer!({
      createTicket: false,
      custguid,
      isCompany: true,
      isRepeat: true,
      offerid: flight.OfferId,
      type: "flight"
    });
  };

  const date = useMemo(
    () => ({
      date: flight.DepDate,
      direction: flight.DirectionId
    }),
    [flight.DepDate, flight.DirectionId]
  );

  const line = useMemo(
    () => ({
      direction: flight.DirectionId,
      finishColor: COLORS[`route${flight.DirectionId}0`],
      startColor: COLORS[`route${flight.DirectionId}0`],
      type: "flight"
    }),
    [flight.DirectionId]
  );

  // const cancelPnr = async () => {
  //   toast.info('PNR is cancelling...');
  //   try {
  //     const {data} = await axios.post('cancelpnr', {
  //       action: 'flight',
  //       lastname: this.props.custguid,
  //       pnr: "9YDY93",
  //       source: ""
  //     });
  //     if (data.iscancelled) {
  //       toast.success('PNR is successfully cancelled.');
  //       updatePnr(pnr.pnr, 'CANCELLED');
  //     } else {
  //       toast.warn(data.message);
  //     }
  //   } catch(e) {
  //     toast.error(`There was an error while cancelling PNR: ${JSON.stringify(e)}`);
  //   }
  // };

  return (
    <div className={"flight box no-padding-right flex d-column " + (flight.direction ? "orange" : "blue")}>
      <div className="flex">
        <Dates className="no-margin-top" dates={[date]} />
        <BasketLine {...line} />
        <div className="description fill-space">
          <div className="from">
            <p className="fs-med">{flight.DepTime.substring(0, flight.DepTime.length - 3)}</p>
            <p className="fs-med">{findAirportByCode(flight.DepartureAirport).state}</p>
            <p className="fs-small">{findAirportByCode(flight.DepartureAirport).name}</p>
          </div>
          <div className="info">
            <p className="fs-lrg">
              <FlightLogo className="text-image" code={flight.MarketingAirline} />
            </p>
            <p className="fs-med">
              {flight.MarketingAirline} {flight.FlightNumber}
            </p>
            {flight.OperatingAirline !== flight.MarketingAirline && (
              <p className="fs-note">
                <span>{t("order.flightOperator")}:</span>
                <FlightLogo className="text-image" code={flight.OperatingAirline} />
              </p>
            )}
            <p className="fs-note">{flight.ClassDesc}</p>
          </div>
          <div className="from">
            <p className="fs-med">{flight.ArrTime.substring(0, flight.ArrTime.length - 3)}</p>
            <p className="fs-med">{findAirportByCode(flight.ArrivalAirport).state}</p>
            <p className="fs-small">{findAirportByCode(flight.ArrivalAirport).name}</p>
          </div>
        </div>
        <div className="right-side flex d-column">
          <p className="basket-item-status">{flight.status}</p>
          <button
            onClick={bookingOfferHandler}
            disabled={flight.status === "NEW"}
            className="button remove self-end left-radius"
          >
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
          <button
            onClick={bookingOfferHandler}
            disabled={flight.status === "NEW"}
            className="button cancel self-end left-radius"
          >
            <FontAwesomeIcon icon={["far", "ban"]} />
          </button>
          <button
            onClick={bookingOfferHandler}
            disabled={flight.status !== "NEW"}
            className="button buy self-end left-radius"
          >
            <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
          </button>
        </div>
      </div>
      <div className="basket-item-footer flex j-end">
        <p className="price-tag fs-prc is-centered left-radius">
          {flight.roundTrip ? pwc(flight.price.TotalFare, flight.Currency) : "Bundle"}
        </p>
      </div>
    </div>
  );
};

export default OrderFlight;
