import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext, useMemo } from "react";
import { SearchContext } from "./Search";

type Props = {
  propKey: string;
};

const SearchTimePicker: FC<Props> = ({ propKey }) => {
  const [search, setSearch] = useContext(SearchContext);

  const value = useMemo(() => {
    return search[propKey].split(":")[0] + ":" + search[propKey].split(":")[1];
  }, [search, propKey]);

  const onChange = (e: any) => {
    setSearch({
      [propKey]: e.currentTarget.value + ":00"
    });
  };

  return (
    <label className="time-select">
      <FontAwesomeIcon size="sm" icon={["fal", "clock"]} />
      <select onChange={onChange} value={value}>
        <option>00:00</option>
        <option>00:30</option>
        <option>01:00</option>
        <option>01:30</option>
        <option>02:00</option>
        <option>02:30</option>
        <option>03:00</option>
        <option>03:30</option>
        <option>04:00</option>
        <option>05:30</option>
        <option>06:00</option>
        <option>06:30</option>
        <option>07:00</option>
        <option>07:30</option>
        <option>08:00</option>
        <option>08:30</option>
        <option>09:00</option>
        <option>09:30</option>
        <option>10:00</option>
        <option>10:30</option>
        <option>11:00</option>
        <option>11:30</option>
        <option>12:00</option>
        <option>12:30</option>
        <option>13:00</option>
        <option>13:30</option>
        <option>14:00</option>
        <option>14:30</option>
        <option>15:00</option>
        <option>15:30</option>
        <option>16:00</option>
        <option>16:30</option>
        <option>17:00</option>
        <option>17:30</option>
        <option>18:00</option>
        <option>18:30</option>
        <option>19:00</option>
        <option>19:30</option>
        <option>20:00</option>
        <option>20:30</option>
        <option>21:00</option>
        <option>21:30</option>
        <option>22:00</option>
        <option>22:30</option>
        <option>23:00</option>
        <option>23:30</option>
      </select>
    </label>
  );
};

export default SearchTimePicker;
