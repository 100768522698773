import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash-es/debounce";
import includes from "lodash-es/includes";
import isNumber from "lodash-es/isNumber";
import isString from "lodash-es/isString";
import keys from "lodash-es/keys";
import startsWith from "lodash-es/startsWith";
import toArray from "lodash-es/toArray";
import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickDetect from "../../hooks/useClickDetect";
import { IAirport } from "../../interfaces/IAirport";
import { api } from "../../services/api";
import { checkKeyCode, clrStr } from "../../utils/helpers";
import { SearchContext } from "./Search";

const filterables = ["city", "code", "country", "name", "state"];

const HotelSearch: FC = () => {
  const $container = useClickDetect(() => setInputIsActive(false));
  const $list = useClickDetect(() => setListIsActive(false));
  const { t } = useTranslation();
  const [search, setSearch] = useContext(SearchContext);
  const $input = useRef<HTMLInputElement>(null);
  const [inputIsActive, setInputIsActive] = useState(false);
  const [hotels, setHotels] = useState<any>([]);
  const [destinations, setDestinations] = useState<any>([]);
  const [listIsActive, setListIsActive] = useState(false);
  const [airports, setAirports] = useState<IAirport[]>([]);
  const [index, setIndex] = useState(1);

  const parsedName = useMemo(() => {
    if (search.name) {
      return search.name.split("__");
    }
    return false;
  }, [search.name]);

  const hasItems = useMemo(() => {
    return airports.length + destinations.length + hotels.length > 0;
  }, [airports, destinations, hotels]);

  const items = useMemo(() => {
    const list = [];
    if (airports.length) {
      list.push("airports", ...airports);
    }

    if (destinations.length) {
      list.push("destinations", ...destinations);
    }

    if (hotels.length) {
      list.push("hotels", ...hotels);
    }

    return list;
  }, [airports, destinations, hotels]);

  const selectHandler = useCallback(
    (payload: any) => {
      setInputIsActive(false);
      setHotels([]);
      setDestinations([]);
      setAirports([]);

      if (payload.state) {
        setSearch({
          arrCity: payload.code,
          dest: payload.code,
          hotel: null,
          lat: null,
          lon: null,
          name: `${payload.city}__${payload.name} (${payload.code})`,
          routes: search.routes.map((r, i) =>
            i
              ? r
              : {
                  ...search.routes[i],
                  arr: payload.code,
                  arrMultiAirport: payload.multiAirport
                }
          )
        });
      } else if (payload.latitude) {
        setSearch({
          arrCity: null,
          dest: null,
          hotel: null,
          lat: payload.latitude,
          lon: payload.longitude,
          name: payload.name,
          orig: null
        });
      } else {
        setSearch({
          arrCity: null,
          dest: null,
          hotel: [payload],
          lat: null,
          lon: null,
          name: payload.name,
          orig: null
        });
      }
    },
    [search.routes, setSearch]
  );

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (checkKeyCode(e, 38) && index > 1) {
        e.preventDefault();
        if (isString(items[index - 1])) {
          setIndex(index - 2);
        } else {
          setIndex(index - 1);
        }
      }

      if (checkKeyCode(e, 40) && index < items.length - 1) {
        e.preventDefault();
        if (isString(items[index + 1])) {
          setIndex(index + 2);
        } else {
          setIndex(index + 1);
        }
      }

      if (checkKeyCode(e, 13)) {
        e.preventDefault();
        if (items.length && isNumber(index) && items[index]) {
          selectHandler(items[index]);
        }
      }
    },
    [index, items, selectHandler]
  );

  useEffect(() => {
    if (inputIsActive && $input.current) {
      $input.current.focus();
    }
  }, [inputIsActive]);

  useEffect(() => {
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [keyHandler]);

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    findLocations(e.currentTarget.value);
  };

  const findLocations = debounce(async (value: string) => {
    if (value.length > 2) {
      try {
        const [hotelResponse, destinationResponse] = await Promise.all([
          api().get("hotellisting", {
            params: { key: value }
          }),
          api().get("destinationlisting", {
            params: { key: value }
          })
        ]);
        setHotels(toArray(hotelResponse.data));
        setDestinations(toArray(destinationResponse.data));
      } catch {}

      const keywords = value.split(" ");

      setAirports(
        window.airports.filter(ap =>
          keys(ap).some(param => {
            if (ap[param] && includes(filterables, param)) {
              return keywords.every(v => startsWith(clrStr(ap[param]), clrStr(v)));
            }
            return false;
          })
        )
      );
    }
    setIndex(1);
    setListIsActive(true);
  }, 1000);

  return (
    <div className="airport-location" ref={$container}>
      {inputIsActive ? (
        <input
          className="airport-location-input"
          ref={$input}
          type="text"
          onChange={inputHandler}
          placeholder={t("search.place")}
        />
      ) : parsedName ? (
        <>
          <p className="city-name" onClick={() => setInputIsActive(true)}>
            {parsedName[0]}
          </p>
          {parsedName[1] && <p className="airport">{parsedName[1]}</p>}
        </>
      ) : (
        <p className="placeholder" onClick={() => setInputIsActive(true)}>
          {t("search.place")}
        </p>
      )}

      {hasItems && listIsActive && (
        <ul className="airport-list hotel-picker" ref={$list}>
          {items.map((item, i) => {
            if (isString(item)) {
              return (
                <li className="search-title" key={i}>
                  <FontAwesomeIcon
                    icon={["fas", item === "airports" ? "plane" : item === "hotels" ? "home" : "map-marker"]}
                  />{" "}
                  {t(`search.${item}Title`)}
                </li>
              );
            } else {
              return (
                <li className={index === i ? "selected" : ""} onClick={() => selectHandler(item)} key={i}>
                  {item.state
                    ? `${item.name} / ${item.state} (${item.code})`
                    : item.latitude
                    ? item.name
                    : `${item.name} (${item.source})`}
                </li>
              );
            }
          })}
        </ul>
      )}
    </div>
  );
};

export default HotelSearch;
