import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IFilterFlight } from "../interfaces/IFilter";
import { IStoreState } from "../interfaces/IState";
import { changeFilterFlights } from "../store/actions/filterActions";
import { getFlightFilters, getFiltersFlightDefaults } from "../store/selectors/filterSelectors";

interface IStateProps {
  defaults: IFilterFlight;
  filters: IFilterFlight;
}

interface IDispatchProps {
  actions: {
    changeFilter(filters: IFilterFlight): void;
  };
}

interface IProps extends IStateProps, IDispatchProps, WithTranslation {}

function InlineSearchFlight({ filters, actions, t }: IProps) {
  const inputHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    actions.changeFilter({
      ...filters,
      search: {
        ...filters.search,
        input: value
      }
    });
  };

  return (
    <div className="inline-search">
      <input placeholder={t("search.inline")} type="text" onChange={inputHandler} />
    </div>
  );
}

const mapStateToProps = (state: IStoreState): IStateProps => ({
  defaults: getFiltersFlightDefaults(state),
  filters: getFlightFilters(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      changeFilter: changeFilterFlights
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InlineSearchFlight));
