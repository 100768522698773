import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { IEmployeeSpent } from "../../pages/Company/CompanyStaffExpensesPage";
import { pwc } from "../../utils/helpers";

interface IProps extends RouteComponentProps {
  data?: { empItems: IEmployeeSpent[] };
}

const EmployeeSpentTable: FC<IProps> = ({ data, history }) => {
  const { t } = useTranslation();

  const linkHandler = useCallback(
    (spent: IEmployeeSpent) => () => {
      history.push("/company/reconciliation/" + spent.cust_guid);
    },
    [history]
  );

  return data && data.empItems ? (
    <div className="table-wrapper">
      <table className="table is-fullwidth spent-report">
        <thead>
          <tr>
            <th>{t("corpReport.employee.table.highestEmp")}</th>
            <th>{t("corpReport.employee.table.department")}</th>
            <th>{t("corpReport.employee.table.total")}</th>
            <th>{t("corpReport.employee.table.flightSpent")}</th>
            <th>{t("corpReport.employee.table.hotelSpent")}</th>
            <th>{t("corpReport.employee.table.carSpent")}</th>
          </tr>
        </thead>
        <tbody>
          {data.empItems.map((d, di) => (
            <tr key={di} onClick={linkHandler(d)} className="pointer">
              <td>{d.name}</td>
              <td>{d.department}</td>
              <td>{pwc(d.totalAmount, "TRY")}</td>
              <td>{pwc(d.items.find(i => i.action === "flight")!.used, "TRY")}</td>
              <td>{pwc(d.items.find(i => i.action === "hotel")!.used, "TRY")}</td>
              <td>{pwc(d.items.find(i => i.action === "car")!.used, "TRY")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default withRouter(EmployeeSpentTable);
