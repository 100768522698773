import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FilterChoice from "../components/filters/FilterChoice";
import Filters from "../components/filters/Filters";
import FilterSlider from "../components/filters/FilterSlider";
import {
  changeFilterTransfers,
  resetFilterTransfers,
  toggleFilterTransfers
} from "../store/actions/filterActions";
import { getFiltersTransfer } from "../store/selectors/filterSelectors";
import {
  getTransferCurrency,
  getTransferMaxPrice,
  getTransferMinPrice
} from "../store/selectors/transferSelectors";
import { currencyParser, uniqid } from "../utils/helpers";

const FilterTransfer: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currency = useSelector(getTransferCurrency);
  const minPrice = useSelector(getTransferMinPrice);
  const maxPrice = useSelector(getTransferMaxPrice);
  const filters = useSelector(getFiltersTransfer);

  const newFilterHandler = useCallback(
    (field: string) => (newValue: any) => {
      dispatch(
        changeFilterTransfers({
          ...filters,
          [field]: newValue
        })
      );
    },
    [dispatch, filters]
  );

  const filterHandler = useCallback(
    (newFilter: { [key: string]: any }) => {
      dispatch(
        changeFilterTransfers({
          ...filters,
          ...newFilter
        })
      );
    },
    [dispatch, filters]
  );

  const resetHandler = useCallback(() => {
    dispatch(resetFilterTransfers());
  }, [dispatch]);

  const toggleHandler = useCallback(() => {
    dispatch(toggleFilterTransfers());
  }, [dispatch]);

  return filters.isActive ? (
    <Filters key={uniqid()} onReset={resetHandler} onClose={toggleHandler}>
      <FilterSlider
        label={t("filter.flight.price")}
        suffix={currencyParser(currency)}
        propKey="price"
        step={50}
        values={{
          max: filters.price.max,
          min: filters.price.min
        }}
        limits={{
          max: maxPrice,
          min: minPrice
        }}
        onChange={filterHandler}
      />
      <FilterChoice
        allValue={0}
        choices={[2, 3, 4, 5, [6, "6+"]]}
        value={filters.seats}
        label={t("filter.car.seat")}
        onChange={newFilterHandler("seats")}
      />
      <FilterChoice
        allValue={0}
        choices={[2, 4, 5]}
        value={filters.doors}
        label={t("filter.car.door")}
        onChange={newFilterHandler("doors")}
      />
      <FilterChoice
        allValue="all"
        choices={["Automatic", "Manual"]}
        value={filters.transmission}
        label={t("filter.car.transmission")}
        onChange={newFilterHandler("transmission")}
      />
    </Filters>
  ) : null;
};

export default FilterTransfer;
