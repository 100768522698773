import { api } from "../../services/api";
import { AC } from "../../types";
import {
  NOTIFICATION_DISMISS,
  NOTIFICATION_DISMISS_ALL,
  NOTIFICATION_FETCH_FULFILLED,
  NOTIFICATION_INIT,
  NOTIFICATION_READ
} from "../../utils/constants";
// import {uniqid} from "../../utils/helperFunctions";
import storage from "../../utils/notificationStorage";

// function mockResponse() {
//   const mock = {
//     data: {
//       notifications: [{
//         date: "2019-01-24 15:20:06.236054",
//         message: "Hotel Booking ",
//         notificationid: uniqid(),
//         priority: 10,
//         title: "N5N12P: Hotel Reservation",
//         type: "info",
//         urlKey: "pnr",
//         urlLink: "",
//         urlValue: "N5N12P"
//       }]
//     }
//   };
//   return Promise.resolve(mock);
// }

export const initializeNotifications: AC = () => dispatch => {
  dispatch({
    payload: storage().fetch(),
    type: NOTIFICATION_INIT
  });
  dispatch<any>(fetchNotifications());
};

export const fetchNotifications: AC = () => async dispatch => {
  try {
    const { data } = await api().get("notifications");
    if (data?.notifications?.length > 0) {
      storage().save(data.notifications);
      dispatch({
        payload: data.notifications,
        type: NOTIFICATION_FETCH_FULFILLED
      });
    }
  } catch {}
};

export const dismissNotification: AC = (id: string) => dispatch => {
  storage().remove(id);
  dispatch({
    payload: id,
    type: NOTIFICATION_DISMISS
  });
};

export const dismissAllNotification: AC = () => dispatch => {
  storage().removeAll();
  dispatch({
    type: NOTIFICATION_DISMISS_ALL
  });
};

export const readNotifications: AC = () => dispatch => {
  storage().read();
  dispatch({
    type: NOTIFICATION_READ
  });
};
