import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import tooltip from "react-tooltip";
import { ICheckoutData } from "../interfaces/IBooking";
import { ITransfer } from "../interfaces/ITransfer";
import {
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { pwc } from "../utils/helpers";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";
import TransferCard from "./TransferCard";

interface IProps {
  transfer: ITransfer;
  customersValidForBooking: boolean;
  style?: CSSProperties;
}

const Transfer: FC<IProps> = ({ transfer, customersValidForBooking, style }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  const checkoutData: ICheckoutData | undefined = useMemo(() => {
    return {
      actionType: "transfer",
      amount: transfer.Amount,
      currency: transfer.Currency,
      guid: transfer.OfferId,
      items: { transfers: [transfer] }
    };
  }, [transfer]);

  return (
    <div className="hotel-container activity-container flex" style={style}>
      <TransferCard transfer={transfer} />
      <div className="controllers">
        <p className="price-tag fs-prc is-centered">{pwc(transfer.Amount, transfer.Currency, false)}</p>
        <ButtonWithConfirm
          actions={[
            {
              byPassAction: true,
              color: "success",
              handler() {
                dispatch(setCheckoutScreenData(checkoutData!, true));
              },
              text: "Proceed"
            },
            {
              color: "success",
              handler() {
                batch(() => {
                  dispatch(setCheckoutScreenData(checkoutData!));
                  dispatch(toggleCustomerScreen());
                  dispatch(setCheckoutIsPending(true));
                });
              },
              text: t("customer.checkButton")
            }
          ]}
          className="button buy"
          message={t("customer.notValidBooking")}
          byPass={customersValidForBooking}
        >
          <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
        </ButtonWithConfirm>
      </div>
    </div>
  );
};

export default Transfer;
