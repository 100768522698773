import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IVisa } from "../interfaces/IBasket";
import { pwc } from "../utils/helpers";

interface IBasketOtherComponentProps {
  item: IVisa;
}
export function OrderOther({ item }: IBasketOtherComponentProps) {
  return (
    <div className="flight box no-padding-right flex d-column">
      <div className="flex">
        <div className="description fill-space flex d-column j-even">
          <p className="fs-med">{item.title}</p>
          <p className="fs-med">{item.description}</p>
        </div>
        <div className="right-side flex d-column">
          <p className="basket-item-status">{item.status}</p>
          <button disabled={item.status === "NEW"} className="button remove self-end left-radius">
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
          <button disabled={item.status === "NEW"} className="button cancel self-end left-radius">
            <FontAwesomeIcon icon={["far", "ban"]} />
          </button>
          <button disabled={item.status !== "NEW"} className="button buy self-end left-radius">
            <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
          </button>
        </div>
      </div>
      <div className="basket-item-footer flex j-end">
        <p className="price-tag fs-prc is-centered left-radius">{pwc(item.amount, item.currency)}</p>
      </div>
    </div>
  );
}
