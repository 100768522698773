import get from "lodash-es/get";
import { utc } from "moment";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ColumnAndLineChart from "../../components/reports/ColumnAndLineChart";
import ColumnChart from "../../components/reports/ColumnChart";
import DonutChart from "../../components/reports/DonutChart";
import { Busy } from "../../components/snippets/Busy";
import DefaultLayout from "../../layouts/DefaultLayout";
import { api } from "../../services/api";
import { getUser } from "../../store/selectors/userSelectors";

const AgencyPage: FC = () => {
  const user = useSelector(getUser);

  const [isBusy, setIsBusy] = useState(false);
  const [hotelSales, setHotelSales] = useState([]);
  const [flightSales, setFlightSales] = useState([]);
  const [companySales, setCompanySales] = useState([]);
  const [agencySales, setAgencySales] = useState([]);
  // const [destFares, setDestFares] = useState([]);

  const requestPayload = useMemo(
    () => ({
      action: "hotel",
      company_code: user.companycode,
      currency: "TRY",
      edate: utc().format("YYYYMMDD"),
      sdate: "20190101"
    }),
    [user.companycode]
  );

  const fetcher = useCallback(async () => {
    setIsBusy(true);
    const [hotel, flight, company, agency] = await Promise.all([
      api().post("dashboard_sales", requestPayload),
      api().post("dashboard_sales", { ...requestPayload, action: "flight" }),
      api().post("dashboard_companysales", requestPayload),
      api().post("rptagencysales", {
        currency: "TRY",
        edate: utc()
          .startOf("year")
          .format("YYYYMMDD"),
        sdate: utc().format("YYYYMMDD")
      })
      // axios.post("/dashboard_destfares", requestPayload)
    ]);
    setHotelSales(get(hotel, "data.datas", []));
    setFlightSales(get(flight, "data.datas", []));
    setCompanySales(get(company, "data.datas", []));
    // setDestFares(get(dest, "data.datas", []));
    setAgencySales(get(agency, "data.datas", []));
    setIsBusy(false);
  }, [requestPayload]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return (
    <DefaultLayout>
      {isBusy && <Busy />}
      <div className="container main is-scrollable">
        <div className="charts">
          {hotelSales.length > 0 && (
            <DonutChart
              title="Hotel Sales"
              span={1}
              value={["count", "amount"]}
              category="action"
              data={hotelSales}
            />
          )}
          {companySales.length > 0 && (
            <ColumnChart
              title="Company Sales"
              span={2}
              value="amount"
              category="company_code"
              data={companySales}
            />
          )}
        </div>
        <div className="charts">
          {flightSales.length > 0 && (
            <DonutChart
              title="Flight Sales"
              span={1}
              value={["count", "amount"]}
              category="action"
              data={flightSales}
            />
          )}
          {companySales.length > 0 && (
            <ColumnChart
              title="Placeholder Report"
              span={2}
              value="amount"
              category="company_code"
              data={companySales}
            />
          )}
        </div>
        <div className="charts">
          {/*{destFares.length > 0 && (*/}
          {/*  <RadarChartComp span={1} data={destFares} value={["max", "min"]} category={"destination"} />*/}
          {/*)}*/}
          {agencySales.length > 0 && <ColumnAndLineChart span={3} />}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AgencyPage;
