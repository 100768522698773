import { Reducer } from "redux";
import { IActivityState } from "../../interfaces/IState";
import {
  ACTIVITY_CLEAR,
  ACTIVITY_FETCH_EMPTY,
  ACTIVITY_FETCH_FULFILLED,
  ACTIVITY_FETCH_PENDING,
  ACTIVITY_FETCH_REJECTED
} from "../../utils/constants";

const initialState: IActivityState[] = [];

const activityReducer: Reducer<IActivityState[]> = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITY_CLEAR:
      return action.payload.map((service: string) => ({
        activities: [],
        key: service,
        status: {
          error: false,
          fetched: false,
          fetching: false
        }
      }));

    case `${action.service}_${ACTIVITY_FETCH_PENDING}`:
      return [...state].map(activityState => {
        if (activityState.key === action.service) {
          return {
            ...activityState,
            status: {
              error: false,
              fetched: false,
              fetching: true
            }
          };
        }
        return activityState;
      });

    case `${action.service}_${ACTIVITY_FETCH_FULFILLED}`:
      return [...state].map(activityState => {
        if (activityState.key === action.service) {
          return {
            ...activityState,
            activities: [...activityState.activities, ...action.payload],
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          };
        }
        return activityState;
      });

    case `${action.service}_${ACTIVITY_FETCH_REJECTED}`:
      return [...state].map(activityState => {
        if (activityState.key === action.service) {
          return {
            ...activityState,
            status: {
              error: true,
              fetched: true,
              fetching: false
            }
          };
        }
        return activityState;
      });

    case `${action.service}_${ACTIVITY_FETCH_EMPTY}`:
      return [...state].map(activityState => {
        if (activityState.key === action.service) {
          return {
            ...activityState,
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          };
        }
        return activityState;
      });
    default:
      return state;
  }
};

export default activityReducer;
