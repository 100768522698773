import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { CSSProperties } from "react";

const data = [
  {
    expenses: 21.1,
    income: 23.5,
    year: "2009"
  },
  {
    expenses: 30.5,
    income: 26.2,
    year: "2010"
  },
  {
    expenses: 34.9,
    income: 30.1,
    year: "2011"
  },
  {
    expenses: 31.1,
    income: 29.5,
    year: "2012"
  },
  {
    expenses: 28.2,
    income: 30.6,
    lineDash: "5,5",
    year: "2013"
  },
  {
    additional: "(projection)",
    columnDash: "5,5",
    expenses: 32.9,
    fillOpacity: 0.2,
    income: 34.1,
    strokeWidth: 1,
    year: "2014"
  }
];

interface IComponentProps {
  span?: number;
}

class ColumnAndLineChart extends React.Component<IComponentProps> {
  public container = React.createRef<HTMLDivElement>();
  public chart: am4charts.XYChart;

  public componentDidMount() {
    const chart = am4core.create(this.container.current!, am4charts.XYChart);
    chart.exporting.menu = new am4core.ExportMenu();

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.minGridDistance = 30;

    /* Create value axis */
    chart.yAxes.push(new am4charts.ValueAxis());

    /* Create series */
    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = "Income";
    columnSeries.dataFields.valueY = "income";
    columnSeries.dataFields.categoryX = "year";

    columnSeries.columns.template.tooltipText =
      "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
    columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    columnSeries.columns.template.propertyFields.stroke = "stroke";
    columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
    columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
    columnSeries.tooltip!.label.textAlign = "middle";

    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = "Expenses";
    lineSeries.dataFields.valueY = "expenses";
    lineSeries.dataFields.categoryX = "year";

    lineSeries.stroke = am4core.color("#fdd400");
    lineSeries.strokeWidth = 3;
    lineSeries.propertyFields.strokeDasharray = "lineDash";
    lineSeries.tooltip!.label.textAlign = "middle";

    const bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
    bullet.tooltipText =
      "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
    const circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color("#fff");
    circle.strokeWidth = 3;

    chart.data = data;

    this.chart = chart;
  }

  public componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public render() {
    const styles: CSSProperties = {
      height: "350px",
      width: "100%"
    };

    if (this.props.span) {
      styles.width = (this.props.span * (100 / 3)).toFixed(1) + "%";
    }

    return (
      <div className="chart-container" style={styles}>
        <div className="chart" ref={this.container} />
      </div>
    );
  }
}

export default ColumnAndLineChart;
