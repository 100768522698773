import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import capitalize from "lodash-es/capitalize";
import React, { FC, useEffect, useRef } from "react";
import { chartColors } from "../../utils/constants";

function createSeries(field: string, name: string, chart: am4charts.XYChart) {
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = `${field}Percent`;
  series.dataFields.categoryY = "department";
  series.stacked = true;
  series.name = name;
  series.tooltipPosition = "pointer";
  series.columns.template.tooltipText = `[bold, white]${field}: {${field}Used}{currency}[/]`;
  series.strokeWidth = 0;
  series.fill = am4core.color(chartColors[name.toLowerCase()]);

  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.x = -35;
  labelBullet.label.text = `[font-size: 10px; bold]{${field}Used}{currency}[/]`;
  labelBullet.label.fill = am4core.color("#fff");
  labelBullet.label.truncate = false;
  labelBullet.label.hideOversized = true;
}

interface IProps {
  data?: any;
}

const DepartmentExpenseChart: FC<IProps> = ({ data }) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chart = am4core.create(container.current!, am4charts.XYChart);
    chart.data = data;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";

    // Create axes
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "department";
    categoryAxis.renderer.grid.template.opacity = 0;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 99;
    valueAxis.axisFullLength = 1;
    valueAxis.renderer.grid.template.opacity = 0;
    valueAxis.renderer.ticks.template.strokeOpacity = 100;
    valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
    valueAxis.renderer.ticks.template.length = 10;
    valueAxis.renderer.line.strokeOpacity = 0.5;
    valueAxis.renderer.minGridDistance = 40;

    if (data && data.length) {
      data[0].categories.forEach((cat: any) => {
        createSeries(cat, capitalize(cat), chart);
      });
    }
    chart.events.on("datavalidated", e => {
      // Get objects of interest
      const chartEl = e.target;
      // const ca = chartEl.yAxes.getIndex(0);
      //
      // // Calculate how we need to adjust chartEl height
      // const adjustHeight = chartEl.data.length * cellSize - ca!.pixelHeight;
      //
      // // get current chartEl height
      // const targetHeight = chartEl.pixelHeight + adjustHeight;

      // Set it on chartEl's container
      chartEl!.svgContainer!.htmlElement.style.height = 270 + "px";
    });

    return () => chart.dispose();
  }, [data]);
  return (
    <div className="chart-container shrinked">
      <div className="chart" ref={container} />
    </div>
  );
};

export default DepartmentExpenseChart;
