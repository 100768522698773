import React, { FC } from "react";
import { IVisa } from "../../interfaces/IBasket";
import { pwc } from "../../utils/helpers";

interface IProps {
  item: IVisa;

  deleteItem(OfferId: string): void;
}

const BasketOther: FC<IProps> = ({ item, deleteItem }) => {
  const deleteItemHandler = () => {
    deleteItem(item.offer_guid);
  };

  return (
    <div className="box other no-padding-right flex">
      <div className="description fill-space flex d-column j-even">
        <p className="fs-med">{item.title}</p>
        <p className="fs-med">{item.description}</p>
      </div>
      <div className="right-side flex d-column">
        <button className="remove button cart self-end gutter-bottom left-radius" onClick={deleteItemHandler}>
          X
        </button>

        <div className="fill-space" />

        <p className="price-tag fs-prc is-centered left-radius">{pwc(item.amount, item.currency)}</p>
      </div>
    </div>
  );
};

export default BasketOther;
