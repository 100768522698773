import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeTransfersModal } from "../../store/actions/transferActions";
import { getTransferModalIsOpen } from "../../store/selectors/transferSelectors";
import TransferSearch from "./components/TransferSearch";
import Transfers from "./containers/Transfers";
import "./styles/transport.scss";

type Props = {};

const style: Modal.Styles = {
  overlay: {
    backgroundColor: "var(--modalContainerBg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    border: "1px solid var(--primaryText)",
    overflow: "visible",
    position: "relative",
    backgroundColor: "var(--primary)",
    padding: 20
  }
};

const TransferModal: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(getTransferModalIsOpen);

  const closeHandler = () => {
    dispatch(closeTransfersModal());
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={style}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={closeHandler}
    >
      <div className="modal-title">
        <h1>{t("transfer.modalTitle")}</h1>
      </div>
      <div className="car-module">
        <button className="modal-close-button" onClick={closeHandler}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
        <TransferSearch />
        <Transfers />
      </div>
    </Modal>
  );
};

export default TransferModal;
