import { useFormikContext } from "formik";
import React, { FC, useState } from "react";
import { ISearchDestination } from "../../../components/search/HotelPicker";
import { useDestinationSearch } from "../../../hooks/useDestinationSearch";
import { IAirport } from "../../../interfaces/IAirport";
import { ISearch } from "../../../interfaces/ISearch";
import { TransportLocationPicker } from "./TransportLocationPicker";

type Props = {
  direction?: "departure" | "arrival";
  index?: number;
  placeholder?: string;
};

export const FormikLocation: FC<Props> = ({ direction = "arrival", index = 0, placeholder }) => {
  const { airports, locations, handle, reset, isBusy } = useDestinationSearch();
  const { setFieldValue } = useFormikContext<ISearch>();

  const [localValue, setLocalValue] = useState("");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handle(e.currentTarget.value);
    setLocalValue(e.currentTarget.value);
  };

  const selectAirport = (airport: IAirport) => {
    if (direction === "departure") {
      setFieldValue("lat", null);
      setFieldValue("lon", null);
      setFieldValue("orig", airport.code);
      setFieldValue("depCity", airport.code);
      setFieldValue(`routes[${index}].dep`, airport.code);
      setFieldValue(`routes[${index}].depMultiAirport`, !!airport.multiAirport);
    } else {
      setFieldValue("rlat", null);
      setFieldValue("rlon", null);
      setFieldValue("dest", airport.code);
      setFieldValue("arrCity", airport.code);
      setFieldValue(`routes[${index}].arr`, airport.code);
      setFieldValue(`routes[${index}].arrMultiAirport`, !!airport.multiAirport);
    }
    setLocalValue(`${airport.name} / ${airport.city} (${airport.code})`);
    reset();
  };

  const selectLocation = (location: ISearchDestination) => {
    if (direction === "departure") {
      setFieldValue("orig", null);
      setFieldValue("depCity", null);
      setFieldValue("lat", location.latitude);
      setFieldValue("lon", location.longitude);
    } else {
      setFieldValue("dest", null);
      setFieldValue("arrCity", null);
      setFieldValue("rlat", location.latitude);
      setFieldValue("rlon", location.longitude);
    }
    setLocalValue(location.name);
    reset();
  };

  const selectHandler = (type: "airport" | "location", payload: any) => {
    switch (type) {
      case "airport":
        selectAirport(payload);
        break;
      case "location":
        selectLocation(payload);
        break;
    }
  };

  return (
    <div className="formik-input has-dropdown">
      <input
        placeholder={placeholder}
        onFocus={e => e.target.select()}
        value={localValue}
        onChange={changeHandler}
      />
      {!isBusy && (
        <TransportLocationPicker destinations={{ airports, locations }} selectHandler={selectHandler} />
      )}
    </div>
  );
};
