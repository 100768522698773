import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import map from "lodash-es/map";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import tooltip from "react-tooltip";
import useObjectState from "../../hooks/useObjectState";
import { ICustomer } from "../../interfaces/ICustomer";
import { IRoom } from "../../interfaces/IHotel";
import { api } from "../../services/api";
import { toggleCheckoutScreen } from "../../store/actions/uiActions";
import { Dictionary } from "../../types";
import { Busy } from "../snippets/Busy";

interface IProps {
  customers: ICustomer[];
  room: IRoom;
  next(payload?: any): void;
}

const BookingDetails: FC<IProps> = ({ customers, room, next }: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [comment, setComment] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [occupancy, setOccupancy] = useState("single");
  const [details, setDetails] = useObjectState<Dictionary<boolean>>({
    Honeymoon: false,
    "King Bed": false,
    "Late Arrival": false,
    Smoking: false
  });

  const detailsCheckboxHandler = (item: string) => {
    setDetails({
      [item]: !details[item]
    });
  };

  const occupancyHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setOccupancy(e.currentTarget.value);
  };

  const commentHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  const nextHandler = () => {
    setIsBusy(true);
    const data = {
      action_type: "hotel",
      comment,
      custlist: customers.map(cust => ({ cust_guid: cust.cust_guid })),
      details,
      occupancy,
      offer_guid: room.OfferId
    };
    api()
      .post("bookingdetails", data)
      .then(() => {
        setIsBusy(false);
        next({ comment, details, occupancy });
      })
      .catch(() => {
        setIsBusy(false);
      });
  };

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  return (
    <div className="modal-wrapper">
      <div className="modal full-padding bordered">
        {isBusy && <Busy />}
        <div className="booking-details">
          <div className="payment-screen">
            <div className="payment-title flex j-between">
              <h2>
                <FontAwesomeIcon icon={["far", "scroll"]} />
                <span>{t("booking.detail")}</span>
              </h2>
              <button
                className="pointer c-primary modal-close-button"
                onClick={() => dispatch(toggleCheckoutScreen())}
              >
                <FontAwesomeIcon icon={["far", "times"]} />
              </button>
            </div>
          </div>
          <div>
            <h1 className="fs-underline">{room.HotelName}</h1>
          </div>
          <div className="payment-body flex j-between">
            <div className="booking-details-items">
              <h1>{t("booking.select")}</h1>
              {map(details, (value: boolean, item: string) => (
                <label key={item}>
                  <input type="checkbox" checked={value} onChange={() => detailsCheckboxHandler(item)} />
                  {item}
                </label>
              ))}
            </div>
            <div>
              <h1>{t("booking.occupancy")}:</h1>
              <label>
                <input
                  type="radio"
                  name="occupancy"
                  defaultChecked={true}
                  value="single"
                  onChange={occupancyHandler}
                />
                <span>{t("booking.occupancySingle")}</span>
              </label>
              <br />
              <label>
                <input type="radio" name="occupancy" value="multi" onChange={occupancyHandler} />
                <span>{t("booking.occupancyMulti")}</span>
              </label>
            </div>
          </div>
          <div className="booking-details-comment">
            <h1>{t("booking.comment")}:</h1>
            <textarea rows={10} onChange={commentHandler} />
          </div>
          <div className="payment-footer">
            <button className="booking-next" onClick={nextHandler}>
              <span>{t("booking.proceed")}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
