import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../../interfaces/ICustomer";
import ProgramPerson from "./ProgramPerson";

type Props = {
  customers: ICustomer[];
};

const ProgramPeopleList: FC<Props> = ({ customers }) => {
  const { t } = useTranslation();

  return (
    <div className="program-details-customers">
      <div className="title flex j-between a-center is-relative">
        <span className="pnr-header-icon c-primary">
          <FontAwesomeIcon icon={["far", "user"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary bold">{t("program.customers.title")}</h1>
      </div>

      <div className="pnr-customer-list">
        <table className="table table-ticket is-fullwidth">
          <thead>
            <tr>
              <th className="fs-med">{t("generic.name")}</th>
              <th className="fs-med align-center">{t("form.checkbox.hotel")}</th>
              <th className="fs-med align-center">{t("form.checkbox.flight")}</th>
              <th className="fs-med align-center">{t("form.checkbox.car")}</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer: ICustomer) => (
              <ProgramPerson customer={customer} key={customer.cust_guid} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProgramPeopleList;
