import toUpper from "lodash-es/toUpper";
import React from "react";
import Slider from "react-slick";
import { IRoom } from "../interfaces/IHotel";
import { pwc } from "../utils/helpers";

interface IRoomsComponentProps {
  rooms: IRoom[];
  settings?: {};

  selectRoom(room: IRoom): void;
}

const defaultSettings = {
  arrows: false,
  autoplay: false,
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 3,
  slidesToShow: 3,
  speed: 500
};

export default function Rooms({ rooms, settings, selectRoom }: IRoomsComponentProps) {
  return (
    <div className="slider-container room-slider left-align-slick">
      <Slider {...defaultSettings} {...settings}>
        {rooms.map(
          (room, index) =>
            room && (
              <div key={index} className="slide">
                <div className={`room ${room.selected ? " selected" : ""}`}>
                  <div className="caption" onClick={() => selectRoom(room)}>
                    <p className="type fs-small medium c-alternate">
                      {toUpper(room.RoomType)}
                      <br />
                      {room.Board}
                    </p>
                    <p className="price fs-med">{pwc(room.AmountAfterTax, room.HotelCurrency, false)}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </Slider>
    </div>
  );
}
