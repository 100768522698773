import toUpper from "lodash-es/toUpper";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAiritinprice } from "../../../interfaces/IFlight";
import { pwc } from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../style/flightModule.scss";
import cx from "classnames";

type Props = {
  alternatives: IAiritinprice[];
  currency: string;
  selected?: IAiritinprice;

  onSelect(alt: IAiritinprice): void;
};

export const FlightAlternatives: FC<Props> = ({ alternatives, currency, selected, onSelect }) => {
  // const [popover, setPopover] = useState<number>();
  const [touched, setTouched] = useState(false);

  const { t } = useTranslation();

  const isSelected = (code: string) => {
    return selected?.BrandCode === code && touched;
  };

  return (
    <div className="room-slider flex flight-alternatives">
      {alternatives.map((alt, idx) => (
        // <Popover
        //   key={idx}
        //   align="center"
        //   isOpen={idx === popover}
        //   position="top"
        //   padding={10}
        //   onClickOutside={() => setPopover(undefined)}
        //   content={({ position, targetRect, popoverRect }) => (
        //     <ArrowContainer
        //       position={position}
        //       targetRect={targetRect}
        //       popoverRect={popoverRect}
        //       arrowSize={10}
        //       arrowColor="var(--secondaryBg)"
        //       arrowStyle={{ opacity: 1 }}
        //     >
        //       <div className="panel panel-primary panel-flight-alternative">
        //         <div className="panel-heading clearfix">
        //           <span className="pull-left">{alt.BrandName || "#BrandName"}</span>
        //           <span className="pull-right">{pwc(alt.TotalFare, currency)}</span>
        //         </div>
        //         <div className="panel-body">jjhjk</div>
        //       </div>
        //     </ArrowContainer>
        //   )}
        // >
        <div
          key={idx}
          className={cx("flight-alternative", {
            selected: isSelected(alt.BrandCode!)
          })}
          onClick={() => {
            onSelect(alt);
            setTouched(true);
            // setPopover(x => (x === undefined || x !== idx ? idx : undefined));
          }}
        >
          <div className="flight-alternative-title">
            <p>{toUpper(alt.BrandName) || "#BrandName"}</p>
            <p className="price fs-med">{pwc(alt.TotalFare, currency, false)}</p>
          </div>
          <div className="flight-alternative-content">
            {alt.CarryOnBaggageKilo && (
              <p className="flight-alternative-info-item">
                <span className="flight-info-icon">
                  <FontAwesomeIcon icon={["fas", "backpack"]} />
                </span>
                <small>
                  {alt.CarryOnBaggageKilo} kg {t("flight.cabinBaggage")}
                </small>
              </p>
            )}
            {alt.CheckedOnBaggageKilo && (
              <p className="flight-alternative-info-item">
                <span className="flight-info-icon">
                  <FontAwesomeIcon icon={["fas", "luggage-cart"]} />
                </span>
                <small>
                  {alt.CheckedOnBaggageKilo} kg {t("flight.baggage")}
                </small>
              </p>
            )}
            {alt.MealDesc && (
              <p className="flight-alternative-info-item">
                <span className="flight-info-icon">
                  <FontAwesomeIcon icon={["fas", "utensils"]} />
                </span>
                <small>{alt.MealDesc}</small>
              </p>
            )}
          </div>
        </div>
        // </Popover>
      ))}
    </div>
  );
};
