import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { IActivity } from "../../interfaces/IActivity";

interface IProps {
  activity: IActivity;
}
const PaymentActivity: FC<IProps> = ({ activity }) => {
  return (
    <div className="other-item flex a-center">
      <div className="item-icon">
        <FontAwesomeIcon icon={["fas", "campground"]} />
      </div>
      <div className="contents full-width">
        <div className="content flex a-center">
          <p className="fs-med">{activity.name}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentActivity;
