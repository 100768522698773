import i18n from "i18next";
import get from "lodash-es/get";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import AppLoading from "../components/AppLoading";
import ExternalLogo from "../components/ExternalLogo";
import Qr from "../components/Qr";
import VoucherActivity from "../components/VoucherActivity";
import VoucherCar from "../components/VoucherCar";
import VoucherFlight from "../components/VoucherFlight";
import VoucherHotel from "../components/VoucherHotel";
import VoucherTransfer from "../components/VoucherTransfer";
import { ICustomer } from "../interfaces/ICustomer";
import { IVoucher } from "../interfaces/IVoucher";
import { api } from "../services/api";
import "../styles/voucher.scss";

const VoucherPage: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [voucher, setVoucher] = useState<IVoucher>();
  const [isEmpty, setIsEmpty] = useState(false);

  const query = useMemo(() => {
    return parse(location.search);
  }, [location]);

  const fetchVoucher = useCallback(async () => {
    if (query.lang === "en" || query.lang === "tr") {
      await i18n.changeLanguage(query.lang);
    }
    try {
      const { data } = await api().post("voucher", query);
      if (data.voucher) {
        setVoucher(data.voucher);
      }
    } catch (e) {
      setIsEmpty(true);
    }
  }, [query]);

  const qrcode = useMemo(() => {
    if (voucher) {
      const cust: ICustomer = get(voucher, "customer[0]", false);
      if (cust) {
        return {
          company: voucher.company.companyName,
          name: cust.firstname + " " + cust.lastname,
          pnr: voucher.pnr
        };
      }
    }
    return {
      company: "",
      name: "",
      pnr: ""
    };
  }, [voucher]);

  useEffect(() => {
    fetchVoucher();
  }, [fetchVoucher]);

  return (
    <div className="voucher-container">
      {voucher?.customer ? (
        <div className="voucher">
          <div className="overlay" />
          <div className="voucher-header">
            <div className="left">
              {voucher.agency && (
                <>
                  <ExternalLogo logos={voucher.agency.logos} />
                  <p className="address">{voucher.agency.address}</p>
                  <p>{voucher.agency.email}</p>
                  <p>{voucher.agency.phone}</p>
                </>
              )}
            </div>
            <div className="right">{voucher.agency && <ExternalLogo logos={voucher.company.logos} />}</div>
          </div>
          <div className="content">
            <h1>Voucher: {voucher.voucher_guid.split("-")[0]}</h1>
            <Qr data={qrcode} />
            <div className="person">
              <h2>
                {voucher.customer[0].title} {voucher.customer[0].firstname} {voucher.customer[0].lastname},
              </h2>
              <p>{t("voucher.personMessage")}</p>
            </div>
            <div className="infos">
              {voucher.flights && voucher.flights.length > 0 && (
                <VoucherFlight
                  pnr={voucher.pnr}
                  customers={voucher.customer}
                  flights={voucher.flights}
                  tickets={voucher?.tickets}
                />
              )}
              {voucher.hotels && voucher.hotels.length > 0 && (
                <VoucherHotel pnr={voucher.pnr} customers={voucher.customer} hotels={voucher.hotels} />
              )}
              {voucher.activities && voucher.activities.length > 0 && (
                <VoucherActivity
                  pnr={voucher.pnr}
                  customers={voucher.customer}
                  activities={voucher.activities}
                />
              )}
              {voucher.cars && voucher.cars.length > 0 && (
                <VoucherCar pnr={voucher.pnr} customers={voucher.customer} cars={voucher.cars} />
              )}
              {voucher.transfers && voucher.transfers.length > 0 && (
                <VoucherTransfer
                  pnr={voucher.pnr}
                  customers={voucher.customer}
                  transfers={voucher.transfers}
                />
              )}
            </div>
          </div>
          <div className="voucher-footer">
            <p>Powered by</p> <img src="/images/trippep-dark.png" alt="Trippep" />
          </div>
        </div>
      ) : isEmpty ? (
        <div className="voucher-not-found">
          <p>Voucher not found.</p>
        </div>
      ) : (
        <AppLoading />
      )}
    </div>
  );
};

export default VoucherPage;
