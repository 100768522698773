import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { utc } from "moment";
import React, { CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../interfaces/IOrder";
import Dates from "./Dates";

interface IProps {
  order: IOrder;
  selected?: string;
  style?: CSSProperties;
  onClick(guid: string): void;
}

const Order = ({ order, selected, style, onClick }: IProps) => {
  const { t } = useTranslation();

  const isSelected = useMemo(() => {
    return selected === order.basket_guid;
  }, [selected, order.basket_guid]);

  const dates = useMemo(() => {
    const payload = [];
    if (order.start_date) {
      payload.push({
        date: order.start_date!,
        direction: "0"
      });
    }

    if (order.start_date && order.end_date) {
      payload.push({
        date: order.end_date,
        direction: "1"
      });
    }
    return payload;
  }, [order.start_date, order.end_date]);

  const cx = classNames("flex d-column pnr pointer", {
    selected: isSelected
  });

  const cxOrder = classNames("flex j-between pnr-title", {
    [order.status.toLocaleLowerCase()]: order.status
  });

  return (
    <div className="list-item-normalizer" style={style}>
      <div onClick={() => onClick(order.basket_guid)} className={cx}>
        <div className={cxOrder}>
          <p>
            <span className="text fs-med">GUID:</span>{" "}
            <span className="bold">{order.basket_guid.split("-")[0]}</span>
          </p>
          <p className="pnr-status">
            <span className="bold" style={{ marginRight: 5 }}>
              {order.status === "PENDING" ? "ARCHIVED" : order.status}
            </span>
          </p>
        </div>
        <div className="pnr-content flex j-between gap-10 fill-space">
          <div>
            <Dates dates={dates} />
          </div>
          {/*{order.custlist && order.custlist.length > 0 && <div className="passenger-list">*/}
          {/*<h1 className="fs-med c-primary bold">Passengers:</h1>*/}
          {/*{order.custlist.map((cust, index) =>*/}
          {/*<p className="fs-std c-primary" key={index}>{cust.firstname + ' ' + cust.lastname}</p>)}*/}
          {/*</div>}*/}
          <div className="passenger-list">
            <h1 className="fs-med c-primary bold">{t("pnr.passengers")}:</h1>
            {order.custlist &&
              order.custlist.map((cust, index) => (
                <p className="fs-med c-primary" key={index}>
                  {cust.firstname + " " + cust.lastname}
                </p>
              ))}
          </div>
          <div className="fill-space" />
          <div className="corp-seal">
            <img src={`/images/company/${order.user.companycode}.png`} alt="" />
          </div>
        </div>
        <div className="pnr-footer flex j-between">
          <span className="gap-5 c-primary">
            {order.flight_count > 0 && <FontAwesomeIcon icon={["fas", "plane"]} />}
            {order.hotel_count > 0 && <FontAwesomeIcon icon={["fas", "home"]} />}
            {order.car_count > 0 && <FontAwesomeIcon icon={["far", "car"]} />}
          </span>
          <p className="timestamp">
            {t("pnr.creation")}: {utc(order.creation_date).format("Y.M.D hh:mm")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Order;
