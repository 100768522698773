import { IAirport } from "../interfaces/IAirport";
import { defaultAirport } from "./defaults";
import { clrStr } from "./helpers";

export const findAirportByCode = (code: string | null | undefined, city = false) => {
  if (city) {
    return window.airports.find(airport => airport.code === code && !!airport.multiAirport) ?? defaultAirport;
  }
  return window.airports.find(airport => airport.code === code && !airport.multiAirport) ?? defaultAirport;
};

export const filterAirports = (airports: IAirport[], query: string) => {
  return airports.filter((airport: IAirport) => {
    return Object.keys(airport).some(k => {
      if (!airport[k] || ["city", "code", "country", "name", "state"].indexOf(k) === -1) {
        return false;
      }
      return clrStr(airport[k]).startsWith(clrStr(query));
    });
  });
};
