import { useEffect, useRef, useState } from "react";

const useCounter = (seconds: number) => {
  const interval = useRef<any>(null);
  const [counter, setCounter] = useState(seconds || 60);

  useEffect(() => {
    if (!counter) {
      clearInterval(interval.current);
    }
  }, [counter]);

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => setCounter(x => x - 1), 1000);
    }

    return () => clearInterval(interval.current);
  }, []);

  return counter;
};

export default useCounter;
