import { utc } from "moment";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import LoadingWrapper from "../../components/LoadingWrapper";
import ExpenseBudgetChart from "../../components/reports/ExpenseBudgetChart";
import useDataFetch from "../../hooks/useDataFetch";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getUser } from "../../store/selectors/userSelectors";

export interface IExpenseBudgetItem {
  action: string;
  used: number;
  currency: string;
}

export interface IExpenseBudgetSummary {
  year: string;
  budget: number;
  used: number;
  currency: string;
  department: string;
  code: string;
  company: string;
  items: IExpenseBudgetItem[];
}

const CompanyExpensesBudgetsPage: FC = () => {
  const user = useSelector(getUser);

  const [currency] = useState("TRY");
  const { data, loading, error } = useDataFetch<IExpenseBudgetSummary[]>({
    dataKey: "report",
    method: "post",
    payload: {
      company_code: user.companycode,
      currency,
      edate: utc()
        .format("YYYY-MM-DD")
        .replace(/-/g, ""),
      groupby: "country",
      sdate: utc().year() + "0101"
    },
    url: "rptdeptbudget"
  });

  return (
    <DefaultLayout>
      <LoadingWrapper loading={loading} error={error} dimension={true}>
        <div className="container main is-scrollable">
          <div className="dashboard flex d-column">
            {data && <ExpenseBudgetChart data={data} />}
            {data && <ExpenseBudgetChart detailed={true} data={data} />}
          </div>
        </div>
      </LoadingWrapper>
    </DefaultLayout>
  );
};

export default CompanyExpensesBudgetsPage;
