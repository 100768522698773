import React, { FC } from "react";
import { IPolicyMoney } from "../../../../interfaces/IPolicy";
import PolicyMoneyEdit from "./PolicyMoneyEdit";

interface IProps {
  budget: IPolicyMoney;
  title: string;

  onChange(pending: Partial<IPolicyMoney>): void;
}

const PolicyApproved: FC<IProps> = ({ budget, title, onChange }) => {
  return (
    <div className="form-group">
      <div className="flex j-between a-center">
        <h4>{title}</h4>
      </div>
      <PolicyMoneyEdit budget={budget} onChange={onChange} />
      <div />
    </div>
  );
};

export default PolicyApproved;
