import React, { FC } from "react";
import HotelSearch from "./HotelSearch";
import SearchDatePicker from "./SearchDatePicker";

const HotelRoute: FC = () => {
  return (
    <div className="route">
      <div className="destinations">
        <div className="airport-search flex">
          <HotelSearch />
        </div>
      </div>
      <SearchDatePicker />
    </div>
  );
};

export default HotelRoute;
