import { library } from "@fortawesome/fontawesome-svg-core";
import { faKeycdn } from "@fortawesome/free-brands-svg-icons/faKeycdn";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { faClock } from "@fortawesome/pro-light-svg-icons/faClock";
import { faFile } from "@fortawesome/pro-light-svg-icons/faFile";
import { faLink } from "@fortawesome/pro-light-svg-icons/faLink";
import { faThermometerHalf } from "@fortawesome/pro-light-svg-icons/faThermometerHalf";
import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faBed } from "@fortawesome/pro-regular-svg-icons/faBed";
import { faBell as farBell } from "@fortawesome/pro-regular-svg-icons/faBell";
import { faBroadcastTower } from "@fortawesome/pro-regular-svg-icons/faBroadcastTower";
import { faCalendarCheck } from "@fortawesome/pro-regular-svg-icons/faCalendarCheck";
import { faCar } from "@fortawesome/pro-regular-svg-icons/faCar";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { faChevronUp } from "@fortawesome/pro-regular-svg-icons/faChevronUp";
import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardListCheck";
import { faConciergeBell } from "@fortawesome/pro-regular-svg-icons/faConciergeBell";
import { faDesktop } from "@fortawesome/pro-regular-svg-icons/faDesktop";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faExchange } from "@fortawesome/pro-regular-svg-icons/faExchange";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faFileSpreadsheet } from "@fortawesome/pro-regular-svg-icons/faFileSpreadsheet";
import { faGripVertical } from "@fortawesome/pro-regular-svg-icons/faGripVertical";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { faHome as farHome } from "@fortawesome/pro-regular-svg-icons/faHome";
import { faHotTub } from "@fortawesome/pro-regular-svg-icons/faHotTub";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faLanguage } from "@fortawesome/pro-regular-svg-icons/faLanguage";
import { faLongArrowRight } from "@fortawesome/pro-regular-svg-icons/faLongArrowRight";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons/faNewspaper";
import { faPassport } from "@fortawesome/pro-regular-svg-icons/faPassport";
import { faPersonCarry } from "@fortawesome/pro-regular-svg-icons/faPersonCarry";
import { faPhone } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faPlane as farPlane } from "@fortawesome/pro-regular-svg-icons/faPlane";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faPrescriptionBottleAlt } from "@fortawesome/pro-regular-svg-icons/faPrescriptionBottleAlt";
import { faScroll } from "@fortawesome/pro-regular-svg-icons/faScroll";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons/faShoppingBasket";
import { faShoppingCart as farShoppingCart } from "@fortawesome/pro-regular-svg-icons/faShoppingCart";
import { faShower } from "@fortawesome/pro-regular-svg-icons/faShower";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { faSmoking } from "@fortawesome/pro-regular-svg-icons/faSmoking";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faStar } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faSwimmingPool } from "@fortawesome/pro-regular-svg-icons/faSwimmingPool";
import { faSync } from "@fortawesome/pro-regular-svg-icons/faSync";
import { faTaxi } from "@fortawesome/pro-regular-svg-icons/faTaxi";
import { faTicketAlt as farTicketAlt } from "@fortawesome/pro-regular-svg-icons/faTicketAlt";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faTshirt } from "@fortawesome/pro-regular-svg-icons/faTshirt";
import { faTv } from "@fortawesome/pro-regular-svg-icons/faTv";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faUserEdit } from "@fortawesome/pro-regular-svg-icons/faUserEdit";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faUserTie } from "@fortawesome/pro-regular-svg-icons/faUserTie";
import { faWheelchair } from "@fortawesome/pro-regular-svg-icons/faWheelchair";
import { faWifi } from "@fortawesome/pro-regular-svg-icons/faWifi";
import { faArrowAltCircleLeft } from "@fortawesome/pro-solid-svg-icons/faArrowAltCircleLeft";
import { faAtlas } from "@fortawesome/pro-solid-svg-icons/faAtlas";
import { faBackpack } from "@fortawesome/pro-solid-svg-icons/faBackpack";
import { faBadgeDollar } from "@fortawesome/pro-solid-svg-icons/faBadgeDollar";
import { faBell as fasBell } from "@fortawesome/pro-solid-svg-icons/faBell";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons/faBookmark";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons/faBuilding";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCampground } from "@fortawesome/pro-solid-svg-icons/faCampground";
import { faCar as fasCar } from "@fortawesome/pro-solid-svg-icons/faCar";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCaretUp } from "@fortawesome/pro-solid-svg-icons/faCaretUp";
import { faCashRegister } from "@fortawesome/pro-solid-svg-icons/faCashRegister";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons/faChartLine";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare";
import { faChevronLeft as fasChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight as fasChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faClipboardList } from "@fortawesome/pro-solid-svg-icons/faClipboardList";
import { faClipboardListCheck as fasClipboardListCheck } from "@fortawesome/pro-solid-svg-icons/faClipboardListCheck";
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faCoins } from "@fortawesome/pro-solid-svg-icons/faCoins";
import { faCreditCardFront } from "@fortawesome/pro-solid-svg-icons/faCreditCardFront";
import { faCrosshairs } from "@fortawesome/pro-solid-svg-icons/faCrosshairs";
import { faDumbbell } from "@fortawesome/pro-solid-svg-icons/faDumbbell";
import { faEdit as fasEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { faGlassMartini } from "@fortawesome/pro-solid-svg-icons/faGlassMartini";
import { faGlobe } from "@fortawesome/pro-solid-svg-icons/faGlobe";
import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faKeynote } from "@fortawesome/pro-solid-svg-icons/faKeynote";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons/faLuggageCart";
import { faMap } from "@fortawesome/pro-solid-svg-icons/faMap";
import { faMapMarkedAlt } from "@fortawesome/pro-solid-svg-icons/faMapMarkedAlt";
import { faMapMarker } from "@fortawesome/pro-solid-svg-icons/faMapMarker";
import { faMapPin } from "@fortawesome/pro-solid-svg-icons/faMapPin";
import { faMinusSquare } from "@fortawesome/pro-solid-svg-icons/faMinusSquare";
import { faMoneyBill } from "@fortawesome/pro-solid-svg-icons/faMoneyBill";
import { faParkingCircle } from "@fortawesome/pro-solid-svg-icons/faParkingCircle";
import { faPaw } from "@fortawesome/pro-solid-svg-icons/faPaw";
import { faPlane } from "@fortawesome/pro-solid-svg-icons/faPlane";
import { faPlaneDeparture } from "@fortawesome/pro-solid-svg-icons/faPlaneDeparture";
import { faPlusCircle as fasPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faPlusSquare } from "@fortawesome/pro-solid-svg-icons/faPlusSquare";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons/faShoppingCart";
import { faShuttleVan } from "@fortawesome/pro-solid-svg-icons/faShuttleVan";
import { faSort } from "@fortawesome/pro-solid-svg-icons/faSort";
import { faSortDown } from "@fortawesome/pro-solid-svg-icons/faSortDown";
import { faSortUp } from "@fortawesome/pro-solid-svg-icons/faSortUp";
import { faSpa } from "@fortawesome/pro-solid-svg-icons/faSpa";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons/faSpinner";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faTag } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTicketAlt } from "@fortawesome/pro-solid-svg-icons/faTicketAlt";
import { faUtensils } from "@fortawesome/pro-solid-svg-icons/faUtensils";
import { faUtensilsAlt } from "@fortawesome/pro-solid-svg-icons/faUtensilsAlt";
import { faWallet } from "@fortawesome/pro-solid-svg-icons/faWallet";

library.add(
  faChevronRight,
  faEdit,
  faSync,
  faUserPlus,
  faBars,
  faPlus,
  faPlusCircle,
  faStar,
  faCheckCircle,
  faChevronLeft,
  faHome,
  faShoppingCart,
  faCheckSquare,
  faSquare,
  fasCheckCircle,
  faExchange,
  faLongArrowRight,
  faCalendarAlt,
  faCreditCardFront,
  faInfoCircle,
  faSpinner,
  faChevronDown,
  faMinus,
  faPlane,
  faFilter,
  faSearch,
  faUser,
  faKeycdn,
  faThermometerHalf,
  faDesktop,
  faTshirt,
  faConciergeBell,
  faSwimmingPool,
  faGlassMartini,
  faDumbbell,
  faWifi,
  faKeynote,
  faParkingCircle,
  faPaw,
  faUtensils,
  faSpa,
  fasPlusCircle,
  faTimes,
  farShoppingCart,
  faSignOut,
  faTag,
  faTicketAlt,
  faCheck,
  farBell,
  fasBell,
  faHistory,
  faMoneyBill,
  faChartLine,
  faCoins,
  faFile,
  farTicketAlt,
  faClipboardList,
  faShower,
  faHotTub,
  faPersonCarry,
  faCar,
  faBed,
  faShieldCheck,
  faPrescriptionBottleAlt,
  faLanguage,
  faNewspaper,
  faSmoking,
  faShoppingBasket,
  faPhone,
  faTaxi,
  faTv,
  faWheelchair,
  faBroadcastTower,
  faQuestion,
  fasStar,
  faScroll,
  faFileSpreadsheet,
  faPassport,
  faShuttleVan,
  faEye,
  faEnvelope,
  faGripVertical,
  faBan,
  faChevronUp,
  faUserTie,
  faCalendarCheck,
  faClipboardListCheck,
  faLink,
  faCog,
  faCaretRight,
  faCaretDown,
  faMapMarker,
  fasChevronRight,
  faCrosshairs,
  faPlaneDeparture,
  fasChevronLeft,
  faSort,
  faSortUp,
  faSortDown,
  faKey,
  faGlobe,
  faMapMarkedAlt,
  faMapPin,
  faMap,
  faBookmark,
  faWallet,
  faCashRegister,
  faBadgeDollar,
  faBuilding,
  faUserEdit,
  farPlane,
  farHome,
  fasClipboardListCheck,
  fasCar,
  faCampground,
  faPlusSquare,
  faMinusSquare,
  fasEdit,
  faClock,
  faAtlas,
  faUtensilsAlt,
  faCaretUp,
  faAtlas,
  faLuggageCart,
  faBackpack,
  faArrowAltCircleLeft
);
