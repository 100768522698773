import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, FastField, useFormikContext } from "formik";
import isEmpty from "lodash-es/isEmpty";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAgreementHotel } from "../../../interfaces/IHotel";
import FormRow from "../../../libs/Form/FormRow";
import StarSelector from "../../../libs/Form/StarSelector";
import Thumbnail from "../../../libs/Image/Thumbnail";
import { api } from "../../../services/api";
import { IGeoCode, IGeoCodeResult } from "../../../types/google";
import { GOOGLE_MAP_PUBLIC_KEY } from "../../../utils/constants";
import AgreementHotelDescriptionEditor from "./AgreementHotelDescriptionEditor";

type Props = {};

const AgreementHotelEditDetails: FC<Props> = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<IAgreementHotel>();
  const [locations, setLocations] = useState<IGeoCodeResult[]>([]);
  const [image, setImage] = useState<string>();

  useEffect(() => {
    if (isEmpty(values.guid)) {
      setFieldValue("hotel_code", values.hotel_name.replace(/[^0-9a-z]/gi, "").toUpperCase());
    }
    // eslint-disable-next-line
  }, [values.hotel_name, values.guid]);

  const generateImage = async (loc: IGeoCodeResult) => {
    try {
      const res = await api({
        auth: false,
        url: "https://maps.googleapis.com"
      }).get("maps/api/staticmap", {
        params: {
          zoom: 18,
          maptype: "roadmap",
          center: `${loc.geometry.location.lat},${loc.geometry.location.lng}`,
          size: "600x300",
          key: GOOGLE_MAP_PUBLIC_KEY,
          markers: `color:red|${loc.geometry.location.lat},${loc.geometry.location.lng}`
        }
      });
      setImage(res.request.responseURL);
    } catch {}
  };

  const findLocation = useCallback(async () => {
    try {
      const { data } = await api({
        auth: false,
        url: "https://maps.googleapis.com"
      }).get<IGeoCode>("maps/api/geocode/json", {
        params: {
          key: GOOGLE_MAP_PUBLIC_KEY,
          address: values.hotel_name,
          sensor: false
        }
      });
      if (data.status === "OK") {
        setLocations(data.results);
      }
    } catch {}
  }, [values.hotel_name]);

  const selectLocation = useCallback(
    (idx: number) => {
      const loc = locations[idx]!;
      const countryCode = loc.address_components.find(comp => comp.types.includes("country"))?.short_name;

      setFieldValue("latitude", loc.geometry.location.lat);
      setFieldValue("longitude", loc.geometry.location.lng);
      setFieldValue("address", loc.formatted_address);
      setFieldValue("country_code", countryCode);

      setLocations([]);
      generateImage(loc);
    },
    [locations, setFieldValue]
  );

  return (
    <>
      <FormRow title={t("admin.agreement.hotelTitle")}>
        <div className="form-section">
          <div className="form-group is-relative">
            <label className="agreement-input-label">
              <span className="label">{t("admin.agreement.hotelName")}:</span>
              <div className="has-addon is-relative">
                <button type="button" onClick={findLocation}>
                  <FontAwesomeIcon icon={["fas", "map-marker"]} />
                </button>
                <FastField name="hotel_name" />
              </div>
              <ErrorMessage name="hotel_name" component="span" className="input-helper danger" />
            </label>
            {locations.length > 0 && (
              <div className="geocode-selector">
                <ul>
                  {locations.map((loc, idx) => (
                    <li key={idx} onClick={() => selectLocation(idx)}>
                      {loc.formatted_address}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="form-group horizontal">
            <label className="agreement-input-label">
              <span className="label">{t("report.hotelCode")}:</span>
              <FastField name="hotel_code" />
              <ErrorMessage name="hotel_code" component="span" className="input-helper danger" />
            </label>
            <label className="agreement-input-label">
              <span className="label">{t("generic.source")}:</span>
              <FastField name="inventory_source" as="select">
                <option value="OFFLINE">OFFLINE</option>
                <option value="AMADEUS-EPOWER">AMADEUS-EPOWER</option>
                <option value="HOTELSPRO">HOTELSPRO</option>
                <option value="LOTSOFHOTEL">LOTSOFHOTEL</option>
              </FastField>
              <ErrorMessage name="inventory_source" component="span" className="input-helper danger" />
            </label>
          </div>
          <div className="form-group">
            <label className="agreement-input-label">
              <span className="label">{t("generic.rating")}:</span>
              <StarSelector stars={values.rating} onChange={rating => setFieldValue("rating", rating)} />
              <ErrorMessage name="rating" component="span" className="input-helper danger" />
            </label>
          </div>
          <div className="form-group">
            <label className="agreement-input-label">
              <span className="label">{t("generic.description")}:</span>
              <AgreementHotelDescriptionEditor
                html={values.description}
                onChange={html => setFieldValue("description", html)}
              />
              <ErrorMessage name="description" component="span" className="input-helper danger" />
            </label>
          </div>
        </div>
        <div className="form-section">
          <div className="form-group">
            <label className="agreement-input-label">
              <span className="label">{t("generic.image")}:</span>
              <FastField name="image_url" />
              <ErrorMessage name="image_url" component="span" className="input-helper danger" />
            </label>
            <Thumbnail height={250} layout="background" image={values.image_url} />
          </div>
        </div>
      </FormRow>
      <FormRow className="cols" title={t("admin.agreement.contactTitle")}>
        <div className="col-sm-15">
          <div className="form-group cols horizontal">
            <label className="agreement-input-label col-xs-4">
              <span className="label">{t("generic.cityCode")}:</span>
              <FastField name="city_code" />
              <ErrorMessage name="city_code" component="span" className="input-helper danger" />
            </label>
            <label className="agreement-input-label col-xs-4">
              <span className="label">{t("generic.countryCode")}:</span>
              <FastField name="country_code" />
              <ErrorMessage name="country_code" component="span" className="input-helper danger" />
            </label>

            <label className="agreement-input-label col-xs-10">
              <span className="label">{t("generic.email")}:</span>
              <FastField name="contact_email" />
              <ErrorMessage name="contact_email" component="span" className="input-helper danger" />
            </label>
          </div>
          <div className="form-group cols horizontal">
            <label className="agreement-input-label col-sm-5">
              <span className="label">{t("generic.latitude")}:</span>
              <div className="has-addon is-relative">
                <FastField name="latitude" />
              </div>
              <ErrorMessage name="latitude" component="span" className="input-helper danger" />
            </label>
            <label className="agreement-input-label col-sm-5">
              <span className="label">{t("generic.longitude")}:</span>
              <div className="has-addon is-relative">
                <FastField name="longitude" />
              </div>
              <ErrorMessage name="longitude" component="span" className="input-helper danger" />
            </label>

            <label className="agreement-input-label col-sm-14">
              <span className="label">{t("generic.address")}:</span>
              <FastField name="address" as="textarea" rows={1} />
              <ErrorMessage name="address" component="span" className="input-helper danger" />
            </label>
          </div>
        </div>
        <div className="col-sm-9">{image && <img src={image} alt="map" />}</div>
      </FormRow>
    </>
  );
};

export default AgreementHotelEditDetails;
