import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import tooltip from "react-tooltip";
import { ICheckoutData } from "../interfaces/IBooking";
import { ICar } from "../interfaces/ICar";
import {
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { getSelectedCar } from "../store/selectors/selectedItemSelectors";
import { pwc } from "../utils/helpers";
import CarCard from "./CarCard";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";

interface IProps {
  cars: ICar[];
  code: string;
  customersValidForBooking: boolean;
  style?: CSSProperties;
}

const Car: FC<IProps> = ({ cars, code, customersValidForBooking, style }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCar = useSelector(getSelectedCar(code));

  tooltip.rebuild();

  const checkoutData: ICheckoutData | undefined = useMemo(() => {
    if (selectedCar) {
      return {
        actionType: "car",
        amount: selectedCar.Amount,
        currency: selectedCar.Currency,
        guid: selectedCar.OfferId,
        items: { cars: [selectedCar] }
      };
    }
  }, [selectedCar]);

  return (
    <div className="hotel-container activity-container flex" style={style}>
      {selectedCar && (
        <>
          <CarCard car={selectedCar} cars={cars} />
          <div className="controllers">
            <p className="price-tag fs-prc is-centered">
              {pwc(selectedCar.Amount, selectedCar.Currency, false)}
            </p>
            {/*<ButtonWithConfirm*/}
            {/*  actions={[*/}
            {/*    {*/}
            {/*      byPassAction: true,*/}
            {/*      color: "success",*/}
            {/*      handler() {*/}
            {/*        if (selectedCar) {*/}
            {/*          setIsBusy(true);*/}
            {/*          dispatch<any>(resetAndAddtoBasket(selectedCar, "car"))*/}
            {/*            .then(() => setIsBusy(false))*/}
            {/*            .catch(() => setIsBusy(false));*/}
            {/*        }*/}
            {/*      },*/}
            {/*      text: "Proceed"*/}
            {/*    },*/}
            {/*    {*/}
            {/*      color: "success",*/}
            {/*      handler() {*/}
            {/*        batch(() => {*/}
            {/*          dispatch(setBasketData(selectedCar!, "car"));*/}
            {/*          dispatch(toggleCustomerScreen());*/}
            {/*          dispatch(setBasketIsPending(true));*/}
            {/*        });*/}
            {/*      },*/}
            {/*      text: "Check Customers"*/}
            {/*    }*/}
            {/*  ]}*/}
            {/*  className="button cart"*/}
            {/*  message={t("customer.notValidBooking")}*/}
            {/*  byPass={customersValidForBooking}*/}
            {/*>*/}
            {/*  <FontAwesomeIcon icon={["fas", "shopping-cart"]} />*/}
            {/*</ButtonWithConfirm>*/}
            <ButtonWithConfirm
              actions={[
                {
                  byPassAction: true,
                  color: "success",
                  handler() {
                    dispatch(setCheckoutScreenData(checkoutData!, true));
                  },
                  text: "Proceed"
                },
                {
                  color: "success",
                  handler() {
                    batch(() => {
                      dispatch(setCheckoutScreenData(checkoutData!));
                      dispatch(toggleCustomerScreen());
                      dispatch(setCheckoutIsPending(true));
                    });
                  },
                  text: "Check Customers"
                }
              ]}
              className="button buy"
              message={t("customer.notValidBooking")}
              byPass={customersValidForBooking}
            >
              <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
            </ButtonWithConfirm>
          </div>
        </>
      )}
    </div>
  );
};

export default Car;
