import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext } from "react";
import { SearchContext } from "./Search";

const AddRoute: FC = () => {
  const [search, setSearch] = useContext(SearchContext);

  const handler = () => {
    if (search.routes.length < 4) {
      setSearch({
        routes: [
          ...search.routes,
          {
            arr: search.routes[0].dep,
            arrMultiAirport: search.routes[0].depMultiAirport,
            date: "",
            dep: search.routes[search.routes.length - 1].arr,
            depMultiAirport: search.routes[search.routes.length - 1].arrMultiAirport,
            rdate: ""
          }
        ]
      });
    }
  };

  return (
    <button className="add-route-button" onClick={handler}>
      <FontAwesomeIcon icon={["fas", "plus-circle"]} />
    </button>
  );
};

export default AddRoute;
