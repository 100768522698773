import moment from "moment";
import React, { FC, useMemo } from "react";
import { IProgramItem } from "../interfaces/IProgram";
import ProgramIcon from "./ProgramIcon";

interface IProps {
  item: IProgramItem;
}

const ProgramItem: FC<IProps> = ({ item }) => {
  const parsedTime = useMemo(() => {
    return moment(item.item_time, "HHmm").format("HH:mm");
  }, [item.item_time]);

  return (
    <>
      <tr>
        <td className="program-item-time">{parsedTime}</td>
        <td className="program-item-title">
          <ProgramIcon type={item.action} />
          <span>{item.item_title}</span>
        </td>
      </tr>
      <tr>
        <td />
        <td className="program-item-description">{item.item_description}</td>
      </tr>
    </>
  );
};

export default ProgramItem;
