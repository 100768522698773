import debounce from "lodash-es/debounce";
import flatten from "lodash-es/flatten";
import get from "lodash-es/get";
import uniq from "lodash-es/uniq";
import values from "lodash-es/values";
import { utc } from "moment";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DepartmentExpenseChart from "../../components/reports/DepartmentExpenseChart";
import EmployeeSpentTable from "../../components/reports/EmployeeSpentTable";
import ShareChart from "../../components/reports/ShareChart";
import { Busy } from "../../components/snippets/Busy";
import useDataFetch from "../../hooks/useDataFetch";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getUser } from "../../store/selectors/userSelectors";
import { percent, pwc } from "../../utils/helpers";

interface IItems {
  action: string;
  used: number;
  currency: string;
}

export interface IEmployeeSpent {
  cust_guid: string;
  name: string;
  department: string;
  totalAmount: number;
  items: IItems[];
}

interface IEmployeeExpensesBudget {
  items: IItems[];
  budget: number;
  used: number;
  currency: string;
  empItems: IEmployeeSpent[];
}

const CompanyStaffExpensesPage: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const [currency] = useState("TRY");
  const { data, loading, fetcher } = useDataFetch<IEmployeeExpensesBudget>({
    dataKey: "report",
    method: "post",
    payload: {
      company_code: user.companycode,
      currency,
      edate: utc().format("YYYYMMDD"),
      groupby: "country",
      sdate: utc().year() + "0101"
    },
    url: "rptcorpmainemployee"
  });

  const departmentData = useMemo(() => {
    if (!data) {
      return;
    }
    const output: any = {};
    data.empItems.forEach(emp => {
      output[emp.department] = output[emp.department] || {};
      output[emp.department].total = emp.totalAmount + (output[emp.department].total || 0);
      output[emp.department].department = emp.department;
      output[emp.department].categories = uniq(flatten(emp.items.map(i => i.action)));
      output[emp.department].currency = data!.currency;
      output[emp.department].color = "#fff";
      emp.items.forEach(item => {
        output[emp.department][`${item.action}Used`] =
          item.used + (output[emp.department][`${item.action}Used`] || 0);
        output[emp.department][`${item.action}Percent`] = percent(
          output[emp.department][`${item.action}Used`],
          output[emp.department].total,
          false
        );
      });
    });
    return values(output);
  }, [data]);

  const searchDebounce = debounce((keyword: string) => {
    if (keyword.length > 2) {
      fetcher({ keyword });
    }

    if (!keyword.length) {
      fetcher();
    }
  }, 500);

  const searchHandler = (e: any) => {
    e.preventDefault();
    searchDebounce(e.currentTarget.value);
  };

  return (
    <DefaultLayout>
      {loading && <Busy />}
      <div className="container main is-scrollable">
        <section className="page-title">
          <h1>{t("corpReport.employee.dashboard.title")}</h1>
        </section>
        <div className="dashboard world">
          <div className="row separator flex wrap-mobile">
            <ShareChart
              currency={currency}
              data={get(data, "items", [])}
              value="used"
              category="action"
              legend="action"
              title={t("corpReport.employee.dashboard.chartTitle")}
            />
            <div className="employee-spent-summary flex d-column j-center">
              <table>
                <tbody>
                  <tr>
                    <td className="employee-spent-big" colSpan={2}>
                      {pwc(get(data, "used", 0), "TRY")}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-right">{t("generic.budget")}</td>
                    <td>{pwc(get(data, "budget", 0), "TRY")}</td>
                  </tr>
                  <tr>
                    <td className="align-right">{t("corpReport.employee.dashboard.spent")}</td>
                    <td className="employee-spent-big">
                      {percent(get(data, "used", 0), get(data, "budget", 0))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <DepartmentExpenseChart data={departmentData} />
          </div>
          <div className="row vertical">
            <div className="inline-search table-search">
              <input placeholder={t("search.inline")} type="text" onChange={searchHandler} />
            </div>
            <EmployeeSpentTable data={data} />
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CompanyStaffExpensesPage;
