import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Notifications from "../../containers/Notifications";
import findRoute from "../../routes/routeMap";
import { toggleBasket } from "../../store/actions/uiActions";
import { getUserMenu } from "../../store/selectors/userSelectors";
import HeaderUserPanel from "../snippets/HeaderUserPanel";

const HeaderMenuMobile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menu = useSelector(getUserMenu);

  const basketHandler = useCallback(() => {
    dispatch(toggleBasket());
  }, [dispatch]);

  return (
    <div className="mobile-menu">
      <HeaderUserPanel mobile={true} />
      <div className="links">
        {menu.map(main =>
          main.subitems ? (
            <div key={main.code} className="has-sub-links">
              <span className="link-title">
                <FontAwesomeIcon icon={main.iconClass!} />
                {main.title}
              </span>
              <ul>
                {main.subitems.map(sub => (
                  <li key={sub.code}>
                    <NavLink className="sub-link" to={findRoute(sub.code)}>
                      <FontAwesomeIcon icon={["fas", "caret-right"]} />
                      {sub.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <NavLink key={main.code} to={findRoute(main.code)} className="main-link">
              <FontAwesomeIcon icon={main.iconClass!} />
              {main.title}
            </NavLink>
          )
        )}
        <button className="external-service main-link pointer" onClick={basketHandler}>
          <FontAwesomeIcon icon={["far", "shopping-cart"]} />
          {t("header.link.basket")}
        </button>
        <Notifications mobile={true} />
      </div>
    </div>
  );
};

export default HeaderMenuMobile;
