import debounce from "lodash-es/debounce";
import toLower from "lodash-es/toLower";
import uniqBy from "lodash-es/uniqBy";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyGeneric } from "../../../../interfaces/IPolicy";
import { Dictionary } from "../../../../types";
import { AIRLINES } from "../../../../utils/constants";
import PolicyFilterRule from "./PolicyFilterRule";

interface IProps extends IPolicyGeneric {
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyAirlines: FC<IProps> = ({ onChange, ...p }) => {
  const { t } = useTranslation();
  const [airlines, setAirlines] = useState<Array<Dictionary<string>>>([]);
  const [section, setSection] = useState<"excluded" | "included">();

  const searchHandler = debounce((k: string, sc: "excluded" | "included") => {
    setAirlines(
      AIRLINES.filter(a => toLower(a.code).includes(toLower(k)) || toLower(a.name).includes(toLower(k)))
    );
    setSection(sc);
  }, 250);

  const airlineHandler = (airline: Dictionary<string>) => {
    onChange!({
      data: uniqBy([...p[section!], { code: airline.code }], x => x.code),
      key: `flights.airlines.${section}`
    });
    reset();
  };

  const removeHandler = (code: string, sc: "excluded" | "included") => {
    onChange!({
      data: p[sc].filter(x => x.code !== code),
      key: `flights.airlines.${sc}`
    });
  };

  const filterRuleHandler = (sc: "isFilter" | "isRule") => {
    onChange!({
      data: !p[sc],
      key: `flights.airlines.${sc}`
    });
  };

  const reset = () => {
    setAirlines([]);
    setSection(undefined);
  };

  return (
    <div>
      <div className="airlines excluded">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.excluded")}</h4>
          <div className="airline-search">
            <input
              onBlur={e => (e.target.value = "")}
              onFocus={reset}
              type="text"
              placeholder={t("search.inline")}
              onChange={e => searchHandler(e.target.value, "excluded")}
            />
            {!!airlines.length && section === "excluded" && (
              <ul>
                {airlines.map(a => (
                  <li key={a.code} onClick={() => airlineHandler(a)}>
                    {a.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="flex">
          {p.excluded.map(a => (
            <p
              key={a.code}
              className={"cluded-item is-selected"}
              onClick={() => removeHandler(a.code, "excluded")}
            >
              {a.code}
            </p>
          ))}
        </div>
      </div>
      <div className="airlines included">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.included")}</h4>
          <div className="airline-search">
            <input
              onBlur={e => (e.target.value = "")}
              onFocus={reset}
              type="text"
              placeholder={t("search.inline")}
              onChange={e => searchHandler(e.target.value, "included")}
            />
            {!!airlines.length && section === "included" && (
              <ul>
                {airlines.map(a => (
                  <li key={a.code} onClick={() => airlineHandler(a)}>
                    {a.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="flex">
          {p.included.map(a => (
            <p
              key={a.code}
              className={"cluded-item is-selected"}
              onClick={() => removeHandler(a.code, "included")}
            >
              {a.code}
            </p>
          ))}
        </div>
      </div>
      <PolicyFilterRule filter={p.isFilter} rule={p.isRule} onChange={filterRuleHandler} />
    </div>
  );
};

export default PolicyAirlines;
