import isEqual from "lodash-es/isEqual";
import orderBy from "lodash-es/orderBy";
import sortBy from "lodash-es/sortBy";
import uniq from "lodash-es/uniq";
import { createSelector } from "reselect";
import { IProvisionCustomer } from "../../interfaces/IBooking";
import { ICustomerPolicy } from "../../interfaces/ICustomer";
import { IStoreState } from "../../interfaces/IState";
import { getBasketCustomersGuids } from "./basketSelectors";
import { getLastSearchPassengerCount } from "./searchSelectors";

export const getCustomerState = (state: IStoreState) => state.customers;

export const getCustomerStatus = createSelector(getCustomerState, customerState => customerState.status);

export const getCustomers = createSelector(getCustomerState, customerState => customerState.customers);

export const getCustomerRooms = createSelector(getCustomerState, customerState => customerState.rooms);

export const getCustomerRoomCount = createSelector(getCustomerRooms, rooms => {
  return rooms.filter(room => room.custGuids.length).length;
});

export const getCustomerCount = createSelector(getCustomers, customers => ({
  adt: customers.filter(customer => customer.selected && customer.passenger_type === "ADT").length,
  chd: customers.filter(customer => customer.selected && customer.passenger_type === "CHD").length,
  inf: customers.filter(customer => customer.selected && customer.passenger_type === "INF").length
}));

export const getCustomerAdultCount = createSelector(getCustomerCount, counts => counts.adt);

export const getActiveCustomers = createSelector(getCustomers, customers =>
  sortBy(
    customers.filter(customer => customer.selected),
    "passenger_type"
  )
);

export const getCustomersPolicies = createSelector(
  getActiveCustomers,
  customers => customers.map(cust => cust.policy).filter(x => x) as ICustomerPolicy[]
);

export const getCustomersPolicyAirlines = createSelector(getCustomersPolicies, policies => {
  return policies.reduce(
    (result: any, p) => {
      const excluded = (p.flights && p.flights.airlines && p.flights.airlines.excluded) || [];
      const included = (p.flights && p.flights.airlines && p.flights.airlines.included) || [];
      result.excluded = uniq([...result.excluded, ...excluded.map(x => x.code)]);
      result.included = uniq([...result.included, ...included.map(x => x.code)]);
      return result;
    },
    {
      excluded: [],
      included: []
    }
  );
});

export const getCustomersPolicyHotels = createSelector(getCustomersPolicies, policies => {
  return policies.reduce(
    (result: any, p) => {
      const excluded = (p.hotels && p.hotels.rating && p.hotels.rating.excluded) || [];
      const included = (p.hotels && p.hotels.rating && p.hotels.rating.included) || [];
      result.excluded = uniq([...result.excluded, ...excluded.map(x => x.code)]);
      result.included = uniq([...result.included, ...included.map(x => x.code)]);
      return result;
    },
    {
      excluded: [],
      included: []
    }
  );
});

export const getCustomersGuids = createSelector(getActiveCustomers, customers =>
  customers.map(customer => ({ custguid: customer.cust_guid }))
);

export const getCustomersValidForBooking = createSelector(
  getCustomerCount,
  getLastSearchPassengerCount,
  (customerCount, passengerCount) => isEqual(customerCount, passengerCount)
);

export const getCustomersValidForBasket = createSelector(
  getCustomersGuids,
  getBasketCustomersGuids,
  getCustomersValidForBooking,
  (activeCustomers, basketCustomers, customersAreValid) =>
    customersAreValid && isEqual(activeCustomers, basketCustomers)
);

export const getFirstCustomerPolicy = createSelector(
  getActiveCustomers,
  getCustomersValidForBooking,
  (customers, isValid) =>
    isValid ? (customers.map(cust => cust.policy).filter(x => x)[0] as ICustomerPolicy) : null
);

export const getActiveCustomersExcludedAirlines = createSelector(getFirstCustomerPolicy, policy =>
  policy ? policy.flights.airlines.excluded.map(e => e.code) : null
);

export const getActiveCustomersExcludedHotels = createSelector(getFirstCustomerPolicy, policy =>
  policy ? policy.hotels.rating.excluded.map(e => parseInt(e.code)) : null
);

export const getCustomerParsedForSearchParam = createSelector(
  getActiveCustomers,
  getCustomerRooms,
  (customers, rooms) =>
    customers.map(cust => {
      const room = rooms.find(r => r.custGuids.includes(cust.cust_guid));
      return {
        birthdate: cust.birthdate,
        custguid: cust.cust_guid,
        roomNo: room ? room.no : 1,
        type: cust.passenger_type
      } as IProvisionCustomer;
    })
);

export const getDifferenceCustomerPassenger = createSelector(
  getCustomerCount,
  getLastSearchPassengerCount,
  (customerCount, passengerCount) => ({
    adt: passengerCount.adt - customerCount.adt,
    chd: passengerCount.chd - customerCount.chd,
    inf: passengerCount.inf - customerCount.inf
  })
);

export const getCustomersMinimumHotelLimit = createSelector(getActiveCustomers, customers => {
  const lowestLimitCustomer = orderBy(
    customers,
    cust => (cust.policy ? cust.policy.budget.approved.limit : 99999),
    "asc"
  )[0];
  return lowestLimitCustomer && lowestLimitCustomer.policy
    ? lowestLimitCustomer.policy.budget.approved.limit
    : 99999;
});
