import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { IHotelCancellationPolicy } from "../../interfaces/IHotel";
import { IProvision } from "../../interfaces/IProvision";
import Modal from "../../libs/Modal";
import { pwc } from "../../utils/helpers";

interface IProps {
  active: boolean;
  policies?: IHotelCancellationPolicy[];
  provision?: IProvision;
  onClose(): void;
}

function parseDate(policy: IHotelCancellationPolicy) {
  return policy.date
    ? moment(policy.date, "YYYY-MM-DD").format("DD.MM.YYYY")
    : " " + policy.time
    ? policy.time
    : "";
}

const CancellationPolicyModal = ({ active, policies, provision, onClose }: IProps) => {
  const { t } = useTranslation();

  return active && policies ? (
    <Modal.Container onClose={onClose} maxWidth={600}>
      <Modal.Header>
        <h1 className="bold">{t("cancellation.title")}</h1>
      </Modal.Header>
      <Modal.Body>
        <table className="cancellation-table">
          <thead>
            <tr>
              <th>{t("cancellation.date")}</th>
              <th>{t("cancellation.amount")}</th>
              <th className="align-left">{t("generic.description")}</th>
            </tr>
          </thead>
          <tbody>
            {policies.map((policy, i) => (
              <tr key={i}>
                <td className="fs-std">{parseDate(policy)}</td>
                <td className="fs-std">{pwc(policy.amount, policy.currency)}</td>
                <td className="fs-std">{policy.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      {provision && (
        <Modal.Footer>
          <h1 className="bold margin-bottom-5">{t("cancellation.rateTitle")}</h1>
          {provision.rateComments && <p className="fs-lrg-m margin-bottom-5">{provision.rateComments}</p>}
          <p className="fs-std">* {t("cancellation.ps")}</p>
        </Modal.Footer>
      )}
    </Modal.Container>
  ) : null;
};

export default CancellationPolicyModal;
