import React from "react";
import { IHotel } from "../interfaces/IHotel";
import Rating from "./snippets/Rating";

export default function PackageHotel({ hotel }: { hotel: IHotel }) {
  return (
    <div className="hotel flex a-center">
      <div className="hotel-thumb" style={{ backgroundImage: `url(${hotel.ThumbnailImage})` }} />
      <div>
        <div className="flex a-center">
          <p className="fs-med c-primary" style={{ marginRight: 5 }}>
            {hotel.HotelName}
          </p>
          <Rating rating={hotel.Rating} className="fs-note" />
        </div>
        <div>
          <p className="fs-med c-primary">{hotel.rooms[0].RoomType}</p>
        </div>
      </div>
    </div>
  );
}
