import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  onClose(): void;
  onReset(): void;
}

const Filters: FC<IProps> = ({ children, onClose, onReset }) => {
  const { t } = useTranslation();
  return (
    <div className="filter-box">
      <div className="filter-header">
        <FontAwesomeIcon icon={["fas", "filter"]} />
        <span className="pointer" onClick={onClose}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </span>
      </div>
      <div className="filter-body">{children}</div>
      <div className="filter-footer">
        <button className="filter-reset" onClick={onReset}>
          <span className="reset">{t("filter.reset")}</span>
        </button>
        <button className="filter-done" onClick={onClose}>
          <FontAwesomeIcon icon={["fas", "check-circle"]} />
        </button>
      </div>
    </div>
  );
};

export default Filters;
