import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getUiState = (state: IStoreState) => state.ui;

export const getAppLoaded = createSelector(getUiState, ui => ui.appLoaded);

export const getHotelDetailsScreenState = createSelector(getUiState, ui => ui.screens.hotelDetailScreen);

export const getBasketUiState = createSelector(getUiState, ui => ui.screens.basket);

export const getCustomerScreenUiState = createSelector(getUiState, ui => ui.screens.customersScreen);

export const getCheckoutScreenUiState = createSelector(getUiState, ui => ui.screens.checkoutScreen);

export const getCustomerScreenIsActive = createSelector(getCustomerScreenUiState, ui => ui.active);

export const getCheckoutScreenIsActive = createSelector(getCheckoutScreenUiState, ui => ui.active);

export const getBasketIsActive = createSelector(getBasketUiState, ui => ui.active);

export const getTheme = createSelector(getUiState, ui => ui.theme);

export const getCheckoutData = createSelector(getCheckoutScreenUiState, uiState => uiState.data);

export const getCheckoutIsPending = createSelector(getCheckoutScreenUiState, uiState => !!uiState.pending);

export const getCheckoutShouldBeClosed = createSelector(
  getCheckoutScreenUiState,
  uiState => !!uiState.shouldClose
);
export const getBasketIsPending = createSelector(getBasketUiState, uiState => !!uiState.pending);

export const getBasketData = createSelector(getBasketUiState, uiState => ({
  action: uiState.action,
  item: uiState.item
}));

export const getIsResetted = createSelector(getUiState, uiState => uiState.resetted);
