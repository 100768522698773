import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { IPnrFlight, IPnrTicket } from "../../interfaces/IPnr";

type Props = {
  ticket: IPnrTicket;
  pnr: IPnrFlight;
  sendVoucher(custGuid: string, OfferId: string, pnr: string): void;
  policyHandler(bool: boolean): void;
};

export const PnrTicket: FC<Props> = ({ ticket, pnr, sendVoucher, policyHandler }) => {
  const offerId =
    pnr.basket_guid !== "DIRECT"
      ? `basket_guid=${pnr.basket_guid}`
      : `offer_guid=${pnr.offer.OfferId}&pnr=${pnr.pnr}`;

  return (
    <>
      <tr>
        <td className="fs-med">{ticket.pax}</td>
        <td className="fs-med">{ticket.tktnumber}</td>
        <td className="align-center fs-med">
          <a
            data-tip="View voucher"
            className="voucher-link"
            target="_blank"
            rel="noopener noreferrer"
            href={`/voucher?${offerId}&cust_guid=${ticket.cust_guid}`}
          >
            <FontAwesomeIcon icon={["far", "eye"]} />
          </a>
          <button
            data-tip="Send voucher to customer"
            className="voucher-link pointer"
            onClick={() => sendVoucher(pnr.custlist[0].cust_guid, pnr.offer.OfferId, pnr.pnr)}
          >
            <FontAwesomeIcon icon={["far", "envelope"]} />
          </button>
        </td>
        <td className="align-center">
          <button
            data-tip="Customer Policies"
            className="voucher-link pointer"
            onClick={() => policyHandler(true)}
          >
            <FontAwesomeIcon icon={["far", "scroll"]} />
          </button>
        </td>
      </tr>
    </>
  );
};
