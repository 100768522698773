import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { api } from "../../services/api";
import languageStorage from "../../utils/languageStorage";
import PolicyModal from "../modals/PolicyModal";
import { Busy } from "../snippets/Busy";

interface IProps {
  customer: ICustomer;
  fulfilled: boolean;
  offerId: string;
  pnr: string;
  type: string;
}

export default function PnrPerson({ customer, fulfilled, offerId, pnr, type }: IProps) {
  const [isBusy, setIsBusy] = useState(false);
  const [isPolicyActive, setIsPolicyActive] = useState(false);

  const sendVoucher = useCallback(() => {
    setIsBusy(true);
    const [guidType, guid] = offerId.split("=");
    api()
      .post("sendvoucher", {
        cust_guid: customer.cust_guid,
        pnr,
        [guidType]: guid
      })
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [customer.cust_guid, offerId, pnr]);

  return (
    <>
      {isBusy && <Busy />}
      <PolicyModal
        active={isPolicyActive}
        policy={customer.policy}
        onClose={() => setIsPolicyActive(false)}
      />
      <tr>
        <td className="fs-med">
          {customer.firstname} {customer.lastname}, {customer.title}
        </td>
        <td className="fs-med">{customer.passenger_type}</td>
        {(type === "hotel" || type === "activity" || type === "car" || type === "transfer") && fulfilled && (
          <td className="align-center fs-med">
            <a
              data-tip="View voucher"
              className="voucher-link"
              target="_blank"
              rel="noopener noreferrer"
              href={`/voucher?${offerId}&firstname=${customer.firstname}&lastname=${
                customer.lastname
              }&cust_guid=${customer.cust_guid}&pnr=${pnr}&lang=${languageStorage().current()}`}
            >
              <FontAwesomeIcon icon={["far", "eye"]} />
            </a>
            <button
              data-tip="Send voucher to customer"
              className="voucher-link pointer"
              onClick={sendVoucher}
            >
              <FontAwesomeIcon icon={["far", "envelope"]} />
            </button>
          </td>
        )}
        <td className="align-center">
          <button
            data-tip="Customer Policies"
            className="voucher-link pointer"
            onClick={() => setIsPolicyActive(true)}
          >
            <FontAwesomeIcon icon={["far", "scroll"]} />
          </button>
        </td>
      </tr>
    </>
  );
}
