import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import times from "lodash-es/times";
import React, { FC, useCallback, useEffect, useState } from "react";

interface IProps {
  stars: number;
  onChange(stars: number): void;
}
const StarSelector: FC<IProps> = ({ stars, onChange }) => {
  const [localStars, setLocalStars] = useState(0);

  useEffect(() => {
    setLocalStars(stars);
  }, [stars]);

  const mouseOverHandler = useCallback(
    (count: number) => () => {
      setLocalStars(count);
    },
    []
  );

  const mouseLeaveHandler = useCallback(() => {
    setLocalStars(stars);
  }, [stars]);

  const selectHandler = useCallback(
    (count: number) => () => {
      onChange(count);
      setLocalStars(count);
    },
    [onChange]
  );

  return (
    <div className="stars" onMouseLeave={mouseLeaveHandler}>
      {times(7, index => (
        <span key={index} onClick={selectHandler(index + 1)} onMouseOver={mouseOverHandler(index + 1)}>
          {localStars > index ? (
            <FontAwesomeIcon color="#FFAD00" icon={["fas", "star"]} />
          ) : (
            <FontAwesomeIcon icon={["far", "star"]} />
          )}
        </span>
      ))}
    </div>
  );
};

export default StarSelector;
