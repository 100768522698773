import { ErrorMessage, useField } from "formik";
import toArray from "lodash-es/toArray";
import moment from "moment";
import React, { FC, memo } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

type Props = {
  name: string;
  inputFormat?: string;
  outputFormat?: string;
  placeholder?: string;
};

const DateField: FC<Props> = ({
  name,
  inputFormat = "DD.MM.YYYY",
  outputFormat = "YYYYMMDD",
  placeholder
}) => {
  const [{ value }, , { setValue, setTouched }] = useField(name);

  const initialDate = moment(value, outputFormat).format(inputFormat);

  const numberFormat = inputFormat?.replace(/[^.]/g, "#");
  const mask = toArray(inputFormat?.replace(/[.]/g, ""));

  const changeHandler = (values: NumberFormatValues) => {
    const date = moment(values.value, inputFormat);
    if (values.value.length === 8 && date.isValid()) {
      const newDate = date.format(outputFormat);
      if (newDate !== value) {
        setValue(date.format(outputFormat));
      }
    } else {
      setValue("");
    }
  };

  return (
    <div className="field">
      <NumberFormat
        format={numberFormat}
        defaultValue={initialDate}
        onValueChange={changeHandler}
        placeholder={placeholder || inputFormat}
        mask={mask}
        onBlur={() => setTouched(true)}
      />
      <ErrorMessage className="input-helper danger" name={name} component="span" />
    </div>
  );
};

export default memo(DateField);
