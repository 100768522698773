import difference from "lodash-es/difference";
import { Dispatch } from "redux";
import {
  IFilterActivity,
  IFilterCar,
  IFilterFlight,
  IFilterHotel,
  IFilterPnrItems,
  IFilterTransfer
} from "../../interfaces/IFilter";
import { GetState } from "../../types";
import {
  FILTER_ACTIVITY_CHANGE,
  FILTER_CAR_CHANGE,
  FILTER_CAR_RESET,
  FILTER_CAR_TOGGLE,
  FILTER_CLOSE,
  FILTER_FLIGHT_CHANGE,
  FILTER_FLIGHT_RESET,
  FILTER_FLIGHT_SORTBY,
  FILTER_FLIGHT_TOGGLE,
  FILTER_HOTEL_CHANGE,
  FILTER_HOTEL_RESET,
  FILTER_HOTEL_SORTBY,
  FILTER_HOTEL_TOGGLE,
  FILTER_PNR_SET_INPUT,
  FILTER_PNR_SET_ITEMS,
  FILTER_SET_DEFAULT,
  FILTER_TRANSFER_CHANGE,
  FILTER_TRANSFER_RESET,
  FILTER_TRANSFER_TOGGLE
} from "../../utils/constants";
import { getCustomersPolicyAirlines, getCustomersPolicyHotels } from "../selectors/customerSelectors";
import { getFlightsMinMaxPrice } from "../selectors/flightSelectors";

export const changeFilterFlights = (filters: IFilterFlight) => (dispatch: Dispatch) => {
  dispatch({
    payload: filters,
    type: FILTER_FLIGHT_CHANGE
  });
};

export const changeFilterFlightsSortBy = (payload: string) => ({
  payload,
  type: FILTER_FLIGHT_SORTBY
});

export const changeFilterHotelsSortBy = (payload: string) => ({
  payload,
  type: FILTER_HOTEL_SORTBY
});

export const toggleFilterFlights = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_FLIGHT_TOGGLE
  });
};

export const toggleFilterTransfers = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_TRANSFER_TOGGLE
  });
};

export const resetFilterFlights = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_FLIGHT_RESET
  });
};

export const changeFilterHotels = (filters: IFilterHotel) => (dispatch: Dispatch) => {
  dispatch({
    payload: filters,
    type: FILTER_HOTEL_CHANGE
  });
};

export const changeFilterActivities = (filters: IFilterActivity) => (dispatch: Dispatch) => {
  dispatch({
    payload: filters,
    type: FILTER_ACTIVITY_CHANGE
  });
};

export const changeFilterCars = (filters: IFilterCar) => (dispatch: Dispatch) => {
  dispatch({
    payload: filters,
    type: FILTER_CAR_CHANGE
  });
};

export const changeFilterTransfers = (filters: IFilterTransfer) => (dispatch: Dispatch) => {
  dispatch({
    payload: filters,
    type: FILTER_TRANSFER_CHANGE
  });
};

export const toggleFilterCars = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_CAR_TOGGLE
  });
};

export const resetFilterCars = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_CAR_RESET
  });
};

export const resetFilterTransfers = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_TRANSFER_RESET
  });
};

export const toggleFilterHotels = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_HOTEL_TOGGLE
  });
};

export const resetFilterHotels = () => (dispatch: Dispatch) => {
  dispatch({
    type: FILTER_HOTEL_RESET
  });
};

export const setFilterDefaults = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const airlines = getCustomersPolicyAirlines(state);
  const hotelRatings = getCustomersPolicyHotels(state);

  const [min, max] = getFlightsMinMaxPrice(state);

  const payload = {
    flights: {
      airlines: airlines.included,
      price: { min, max }
    },
    hotels: {
      price: { min, max },
      stars: difference(
        [1, 2, 3, 4, 5],
        hotelRatings.excluded.map((x: any) => parseInt(x))
      )
    }
  };
  dispatch({
    payload,
    type: FILTER_SET_DEFAULT
  });
};

export const filterReset = () => (dispatch: Dispatch) => {
  dispatch({ type: FILTER_CLOSE });
  dispatch({ type: FILTER_FLIGHT_RESET });
  dispatch({ type: FILTER_HOTEL_RESET });
};

export const changeFilterPnrItems = (items: IFilterPnrItems) => ({
  payload: items,
  type: FILTER_PNR_SET_ITEMS
});

export const changeFilterPnrInput = (input: string) => ({
  payload: input,
  type: FILTER_PNR_SET_INPUT
});
