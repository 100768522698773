import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext, useMemo } from "react";
import FlightSearch from "./FlightSearch";
import SearchDatePicker from "./SearchDatePicker";
import { SearchContext } from "./Search";
import SingleDatePicker from "./SingleDatePicker";

interface IProps {
  index?: number;
}

const FlightRoute: FC<IProps> = ({ index = 0 }) => {
  const [search, setSearch] = useContext(SearchContext);

  const isOneway = useMemo(() => {
    return search.ftype === "OW" || search.ftype === "MC";
  }, [search]);

  const handleRemove = () => {
    setSearch(
      {
        ...search,
        routes: search.routes.filter((r, i) => i !== index),
      },
      true
    );
  };

  return (
    <div className="route">
      <FlightSearch index={index} />
      {isOneway ? <SingleDatePicker index={index} /> : <SearchDatePicker index={index} />}
      {index > 0 && (
        <div className="route-delete">
          <button onClick={handleRemove}>
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FlightRoute;
