import isEmpty from "lodash-es/isEmpty";
import { utc } from "moment";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IActivity } from "../interfaces/IActivity";
import { ICustomer } from "../interfaces/ICustomer";

interface IProps {
  customers: ICustomer[];
  activities: IActivity[];
  pnr: string;
}

const VoucherActivity: FC<IProps> = ({ customers, activities = [], pnr }: IProps) => {
  const { t } = useTranslation();

  const allGuests = useMemo(() => {
    return customers.map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  const adults = useMemo(() => {
    return customers.filter(c => c.customer_type === "ADT").map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  return (
    <div className="hotel info">
      <div className="title">
        <img src="images/voucher/activity.png" alt="hotel" />
        <h3>{t("generic.title.activityInfo")}</h3>
      </div>
      {activities.map((activity, index) => (
        <div key={index} className="caption">
          <div className="top-caption">
            <p>
              {activity.title}
              <br />

              {activity.Address && (
                <>
                  {activity.Address}
                  <br />
                </>
              )}
              {activity.City && (
                <>
                  {activity.City}
                  <br />
                </>
              )}
            </p>
            {activity.supplier && !isEmpty(activity.supplier) && (
              <p className="supplier">
                Payable through <span className="bold">{activity.supplier.name}</span>, acting as agent for
                the service operating company, details of which can be provided upon request.
                <span className="bold">{pnr}</span>
              </p>
            )}
          </div>
          <p className="general">
            <span className="bold">PNR:</span> {pnr}
          </p>
          <table>
            <tbody>
              <tr>
                <td className="is-bold">{t("voucher.activityguest")}:</td>
                <td>{allGuests.join(", ")}</td>
                <td className="is-bold">{t("voucher.activityGuestCount")}:</td>
                <td>{adults.length}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.activityType")}:</td>
                <td>{activity.activityType}</td>
                <td className="is-bold">{t("voucher.activityDate")}:</td>
                <td>{utc(activity.date).format("DD MMMM Y")}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.activityDuration")}:</td>
                <td>{activity.durationValue + " " + activity.durationMetric}</td>
                <td className="is-bold">{t("voucher.paymentStatus")}:</td>
                <td>{activity.payStatus}</td>
              </tr>
            </tbody>
          </table>
          {activity.prov && activity.prov.rateComments && (
            <>
              <p className="bold no-margin">{t("cancellation.rateTitle")}:</p>
              <p>{activity.prov.rateComments}</p>
            </>
          )}
          {/*{hotel.prov && hotel.prov.cancellationPolicies && (*/}
          {/*  <>*/}
          {/*    <T tag="p" className="bold no-margin" tkey="cancellation.title" />*/}
          {/*    {hotel.prov.cancellationPolicies.map((policy, i) => (*/}
          {/*      <p key={i} className="general">*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.amount" punct=":" />{" "}*/}
          {/*        {pwc(policy.amount, policy.currency, true)}*/}
          {/*        {"  "}*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.date" punct=":" /> {policy.date}*/}
          {/*        {"  "}*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.remainingDays" punct=":" />{" "}*/}
          {/*        {policy.remainingDays}*/}
          {/*      </p>*/}
          {/*    ))}*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      ))}
    </div>
  );
};

export default VoucherActivity;
