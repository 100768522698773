import Axios, { Canceler } from "axios";
import debounce from "lodash-es/debounce";
import { useState } from "react";
import { ISearchDestination } from "../components/search/HotelPicker";
import { IAirport } from "../interfaces/IAirport";
import { api } from "../services/api";
import { filterAirports } from "../utils/airportFunctions";

const CancelToken = Axios.CancelToken;
let cancel: Canceler;

export const useDestinationSearch = () => {
  const [airports, setAirports] = useState<IAirport[]>([]);
  const [locations, setLocations] = useState<ISearchDestination[]>([]);
  const [isBusy, setIsBusy] = useState(false);

  const handle = debounce(async (key: string) => {
    if (key.length < 3) {
      return;
    }

    reset();

    if (cancel) {
      cancel();
    }

    setIsBusy(true);
    try {
      const { data } = await api().get("destinationlisting", {
        params: { key },
        cancelToken: new CancelToken(c => (cancel = c))
      });

      if (data && data.length) {
        setLocations(data);
      }
      setIsBusy(false);
    } catch (e) {
      if (!Axios.isCancel(e)) {
        setIsBusy(false);
      }
    }
    setAirports(filterAirports(window.airports, key));
  }, 250);

  const reset = () => {
    setAirports([]);
    setLocations([]);
  };

  return {
    airports,
    locations,
    handle,
    isBusy,
    reset
  };
};
