import i18n from "i18next";
import reduce from "lodash-es/reduce";
import { batch } from "react-redux";
import { ICredentials, IUser } from "../../interfaces/IUser";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  USER_COMPANY_CHANGED,
  USER_COMPANY_LOADED,
  USER_LOADED,
  USER_LOGIN_FULFILLED,
  USER_LOGOUT_FULFILLED
} from "../../utils/constants";
import { findLocalItems, removeByWildcard, safeJsonParse } from "../../utils/helpers";
import languageStorage from "../../utils/languageStorage";
import { getUser } from "../selectors/userSelectors";
import { initializePrivateData } from "./initializeActions";

export const checkUser: AC = () => async dispatch => {
  await api().get("checks");
  const user = safeJsonParse<IUser>(localStorage.getItem("user"));
  dispatch({
    payload: user,
    type: USER_LOADED
  });
};

export const loadUserCompanies: AC = () => async dispatch => {
  try {
    const { data } = await api().get("changecompany");
    dispatch({
      payload: data,
      type: USER_COMPANY_LOADED
    });
  } catch {}
};

export const changeUserCompany: AC = (companycode: string) => async (dispatch, getState) => {
  const user = getUser(getState());

  const payload = {
    app: "WEB",
    companycode,
    lang: languageStorage().current(),
    username: user.useremail
  };

  try {
    const { data } = await api().post("changecompany", payload);
    JSON.parse(localStorage.getItem("user")!);
    // data.user.menu = data.menu;
    localStorage.setItem("api_token", data.token);
    localStorage.removeItem("customers");
    localStorage.setItem(
      "user",
      JSON.stringify({ ...JSON.parse(localStorage.getItem("user") || "{}"), ...data.user })
    );
    dispatch({
      payload: data.user,
      type: USER_COMPANY_CHANGED
    });
  } catch {}
};

export const attemptLogin: AC = (credentials: ICredentials) => async dispatch => {
  const log = new Logger(i18n.t("login.attempt.pending"));

  try {
    const { data } = await api().post("login", credentials);
    data.user.menu = data.menu;
    localStorage.setItem("api_token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    log.success(i18n.t("login.attempt.success"));
    batch(async () => {
      dispatch({
        payload: data.user,
        type: USER_LOGIN_FULFILLED
      });
      await dispatch(initializePrivateData());
    });
  } catch {
    log.error(i18n.t("login.attempt.error"));
  }
};

export const loginFromPortal: AC = (token: string) => async dispatch => {
  await dispatch(logout());
  try {
    const { data } = await api({ token }).get("validateToken");
    data.user.menu = data.menu;
    localStorage.setItem("api_token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));
    dispatch({
      payload: data.user,
      type: USER_LOGIN_FULFILLED
    });
  } catch {}
};

export const logout: AC = () => async dispatch => {
  const amadeusCookies = findLocalItems("amadeus");
  const data = reduce(
    amadeusCookies,
    (result, value) => {
      result.push({ cookie: value });
      return result;
    },
    [] as any
  );
  try {
    await api().delete("login", { data });
    clearUserInfo();
    dispatch({
      type: USER_LOGOUT_FULFILLED
    });
  } catch {}
};

const clearUserInfo = () => {
  localStorage.removeItem("api_token");
  localStorage.removeItem("user");
  localStorage.removeItem("customers");
  localStorage.removeItem("history");
  localStorage.removeItem("cache");
  removeByWildcard("amadeus");
};
