import React, { FC } from "react";

interface IProps {
  color: string;
}

const HotelSvgMedia: FC<IProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.9 16.9">
    <title>Hotel Svg</title>
    <g>
      <circle fill={color} cx="8.5" cy="8.5" r="8.5" />
    </g>
    <path
      fill="#fff"
      d="M14,8.8c0,0-0.1,0.1-0.2,0.1l0,0c-0.1,0-0.1,0-0.2-0.1L8.4,4.5L3.2,8.9C3.2,8.9,3.1,9,3,8.9
	c-0.1,0-0.1,0-0.2-0.1L2.4,8.3C2.3,8.2,2.3,8.1,2.4,8l5.5-4.6c0.3-0.3,0.8-0.3,1.2,0l1.9,1.5V3.5c0-0.1,0.1-0.2,0.2-0.2c0,0,0,0,0,0
	h1.5c0.1,0,0.2,0.1,0.2,0.2v3.1l1.7,1.4c0.1,0.1,0.1,0.2,0,0.3L14,8.8z M12.8,12.4c0,0.3-0.2,0.5-0.5,0.5H9.4V10H7.5v2.9H4.6
	c-0.3,0-0.5-0.2-0.5-0.5V8.8l0,0l4.3-3.6l4.4,3.6l0,0L12.8,12.4z"
    />
  </svg>
);

export default HotelSvgMedia;
