import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import Activity from "../components/Activity";
import { Statuses } from "../components/Statuses";
import { IActivity } from "../interfaces/IActivity";
import {
  getActivitiesResultEmpty,
  getActivitySearchDone,
  getActivityStatuses,
  getGroupedActivities
} from "../store/selectors/activitySelectors";
import { getBookingStatus } from "../store/selectors/bookingSelectors";
import { getCustomersValidForBooking } from "../store/selectors/customerSelectors";
import { Entries, Merge } from "../types";
import FilterHotel from "./FilterHotel";
import InlineSearchActivity from "./InlineSearchActivity";

const Activities: FC = () => {
  const { t } = useTranslation();

  const bookingStatus = useSelector(getBookingStatus);
  // const customersValidForBasket = useSelector(getCustomersValidForBasket);
  const customersValidForBooking = useSelector(getCustomersValidForBooking);
  const activities = useSelector(getGroupedActivities);
  const isEmpty = useSelector(getActivitiesResultEmpty);
  const searchIsDone = useSelector(getActivitySearchDone);
  const statuses = useSelector(getActivityStatuses);

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: Entries<IActivity[]> }>> = useCallback(
    ({ index, data, style }) => {
      return (
        <Activity
          activities={data[index][1]}
          code={data[index][0]}
          bookingStatus={bookingStatus}
          customersValidForBasket={customersValidForBooking}
          customersValidForBooking={customersValidForBooking}
          style={style}
        />
      );
    },
    [bookingStatus, customersValidForBooking]
  );

  return statuses.some(s => s.fetched || s.fetching) ? (
    <div className="box-container hotels">
      <div className="title flex j-between a-center is-relative">
        <span className="result-header-icon c-primary">
          <FontAwesomeIcon icon={["fas", "campground"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary">{t("activity.resultTitle")}</h1>
        {searchIsDone ? (
          <>
            {/*<div className="fill-space" />*/}
            {/*<Sort*/}
            {/*  sortKeys={["price", "distance"]}*/}
            {/*  current={sortBy}*/}
            {/*  onChange={actions.changeFilterHotelsSortBy}*/}
            {/*/>*/}
            <InlineSearchActivity />
            {/*<button className="button is-small filter-button" onClick={actions.toggleFilter}>*/}
            {/*  <FontAwesomeIcon icon={["fas", "filter"]} />*/}
            {/*</button>*/}
          </>
        ) : (
          <Statuses statuses={statuses} />
        )}
        <FilterHotel />
      </div>
      {isEmpty && <p className="not-found-message">{t("search.notFound")}</p>}
      <FixedSizeList
        className={`result-list${activities.length > 0 ? "" : " hidden"}`}
        itemData={activities}
        itemSize={220}
        height={1000}
        itemCount={activities.length}
        width="100%"
      >
        {rowRenderer}
      </FixedSizeList>
    </div>
  ) : (
    <div />
  );
};

export default Activities;
