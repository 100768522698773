import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import take from "lodash-es/take";
import React, { FC, useContext, useState } from "react";
import { useSelector } from "react-redux";
import useClickDetect from "../../hooks/useClickDetect";
import { ISearch } from "../../interfaces/ISearch";
import { getSearchHistory } from "../../store/selectors/searchSelectors";
import { findAirportByCode } from "../../utils/airportFunctions";
import { dayMonth } from "../../utils/helpers";
import { SearchContext } from "./Search";

const SearchHistory: FC = () => {
  const $el = useClickDetect(() => setIsActive(false));
  const history = useSelector(getSearchHistory);
  const setSearch = useContext(SearchContext)[1];

  const [isActive, setIsActive] = useState(false);

  const selectHandler = (s: ISearch) => {
    setSearch(s, true);
    setIsActive(false);
  };

  return (
    <div ref={$el} className="is-relative">
      <button className="pointer c-primary" onClick={() => setIsActive(!isActive)}>
        <FontAwesomeIcon icon={["far", "history"]} />
      </button>
      {isActive && history.length > 0 && (
        <div className="search-history-list">
          {take(history, 5).map(s => (
            <p className="pointer c-primary fs-med flex" onClick={() => selectHandler(s)} key={s.guid}>
              {s.name ? (
                s.name
              ) : (
                <>
                  {findAirportByCode(s.orig!).state} ({findAirportByCode(s.orig!).code}) → (
                  {findAirportByCode(s.dest!).code}) {findAirportByCode(s.dest!).state}
                </>
              )}
              <span className="middot" />
              {dayMonth(s.fdate)}
              {s.rdate && ` → ${dayMonth(s.rdate)}`}
              <span className="middot" />
              A: {s.adt} C: {s.chd} I: {s.inf}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchHistory;
