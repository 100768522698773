import { useCallback, useEffect, useState } from "react";
import { api } from "../services/api";
import { RecursivePartial } from "../types";

type Method = "get" | "post";

type Payload<P = object> = P;

// type DataFetchReturn<S> = {
//   data?: S;
//   error: any;
//   loading: boolean;
//   refetch(payload?: RecursivePartial<Payload>): void;
// };

interface IOptions<S, P> {
  url: string;
  method?: Method;
  payload?: P;
  dataKey?: string;
  state?: S;
  fetchOnMount?: boolean;
}

const useDataFetch = <S, P = object>({
  dataKey,
  state,
  method = "get",
  payload,
  url,
  fetchOnMount = true
}: IOptions<S, P>) => {
  const [data, setData] = useState(state);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(false);

  const fetchHandler = useCallback(
    async (newPayload?: Payload) => {
      setError(false);
      setLoading(true);
      try {
        const res = await api()({
          [method === "get" ? "params" : "data"]: newPayload ? newPayload : payload,
          method,
          url
        });
        if (dataKey) {
          setData(res.data[dataKey]);
        } else {
          setData(res.data);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    },
    [dataKey, method, payload, url]
  );

  const fetcher = useCallback(
    async (newPayload?: RecursivePartial<Payload>) => {
      if (completed) {
        await fetchHandler(newPayload ? { ...payload, ...newPayload } : payload);
      }
    },
    [completed, fetchHandler, payload]
  );

  useEffect(() => {
    if (fetchOnMount) {
      fetchHandler().then(() => setCompleted(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnMount]);

  return { data, loading, error, fetcher };
};

export default useDataFetch;
