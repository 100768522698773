import React, { FC, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import AppLoading from "../components/AppLoading";
import { api } from "../services/api";

type Props = RouteComponentProps<{ token: string }>;

const RedirectPage: FC<Props> = ({ match }) => {
  const [isValid, setIsValid] = useState<boolean>();
  const token = match.params.token;

  api({ token })
    .get("/validateToken")
    .then(() => setIsValid(true))
    .catch(() => setIsValid(false));

  return isValid === true ? (
    <Redirect to="/" />
  ) : isValid === false ? (
    <Redirect to="/login" />
  ) : (
    <AppLoading />
  );
};

export default RedirectPage;
