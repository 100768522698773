import debounce from "lodash-es/debounce";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IFilterHotel } from "../interfaces/IFilter";
import { IStoreState } from "../interfaces/IState";
import { changeFilterHotels } from "../store/actions/filterActions";
import {
  getFilterHotelInput,
  getFiltersHotel,
  getFiltersHotelDefaults
} from "../store/selectors/filterSelectors";

interface IStateProps {
  defaults: IFilterHotel;
  filters: IFilterHotel;
  input: string;
}

interface IDispatchProps {
  actions: {
    changeFilter(filters: IFilterHotel): void;
  };
}

interface IProps extends IStateProps, IDispatchProps {}

const InlineSearchHotel: FC<IProps> = ({ filters, actions, input }) => {
  const { t } = useTranslation();
  const inputHandler = debounce((value: string) => {
    actions.changeFilter({
      ...filters,
      search: {
        ...filters.search,
        input: value
      }
    });
  }, 500);

  return (
    <div className="inline-search">
      <input
        defaultValue={input}
        placeholder={t("search.inline")}
        type="text"
        onChange={e => inputHandler(e.currentTarget.value)}
      />
    </div>
  );
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  defaults: getFiltersHotelDefaults(state),
  filters: getFiltersHotel(state),
  input: getFilterHotelInput(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      changeFilter: changeFilterHotels
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineSearchHotel);
