import { IActivity } from "../../interfaces/IActivity";
import { ICar } from "../../interfaces/ICar";
import { IFlight } from "../../interfaces/IFlight";
import { IHotel, IRoom } from "../../interfaces/IHotel";
import {
  SELECTED_ITEMS_SELECT_ACTIVITY,
  SELECTED_ITEMS_SELECT_CAR,
  SELECTED_ITEMS_SELECT_FLIGHT,
  SELECTED_ITEMS_SELECT_HOTEL,
  SELECTED_ITEMS_SELECT_ROOM
} from "../../utils/constants";

export const selectHotel = (hotel: IHotel) => ({
  payload: hotel,
  type: SELECTED_ITEMS_SELECT_HOTEL
});

export const selectRoom = (room: IRoom) => ({
  payload: room,
  type: SELECTED_ITEMS_SELECT_ROOM
});

export const selectFlight = (flight: IFlight) => ({
  payload: flight,
  type: SELECTED_ITEMS_SELECT_FLIGHT
});

export const selectActivity = (activity: IActivity) => ({
  payload: activity,
  type: SELECTED_ITEMS_SELECT_ACTIVITY
});

export const selectCar = (car: ICar) => ({
  payload: car,
  type: SELECTED_ITEMS_SELECT_CAR
});
