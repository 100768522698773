import moment from "moment";
import React, { FC, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import useClickDetect from "../hooks/useClickDetect";
import { CustomDateSinglePicker } from "./date/CustomDateSinglePicker";

type Props = {
  startDate: string;
  endDate: string;
  pickStartDate(date: string): void;
  pickEndDate(date: string): void;
};

const ReportDatePicker: FC<Props> = ({ startDate, endDate, pickStartDate, pickEndDate }) => {
  const { t } = useTranslation();

  const $startLabel = useClickDetect(() => setStartPickerIsActive(false));
  const $endLabel = useClickDetect(() => setEndPickerIsActive(false));

  const [localStartDate, setLocalStartDate] = useState(() => moment(startDate).format("DDMMYYYY"));
  const [localEndDate, setLocalEndDate] = useState(() => moment(endDate).format("DDMMYYYY"));

  const [startPickerIsActive, setStartPickerIsActive] = useState(false);
  const [endPickerIsActive, setEndPickerIsActive] = useState(false);

  const toggleStartPicker = useCallback(() => {
    setEndPickerIsActive(false);
    setStartPickerIsActive((x) => !x);
  }, []);

  const toggleEndPicker = useCallback(() => {
    setStartPickerIsActive(false);
    setEndPickerIsActive((x) => !x);
  }, []);

  const startDatePicker = (date: string) => {
    const formatted = moment(date, "YYYYMMDD");
    setLocalStartDate(formatted.format("DDMMYYYY"));
    // setStartPickerIsActive(false);
    // setEndPickerIsActive(false);
  };

  const endDatePicker = (date: string) => {
    const formatted = moment(date, "YYYYMMDD");
    setLocalEndDate(formatted.format("DDMMYYYY"));
    // setStartPickerIsActive(false);
    // setEndPickerIsActive(false);
  };

  const startDateHandler = ({ value }: NumberFormatValues) => {
    setLocalStartDate(value);
  };

  const endDateHandler = ({ value }: NumberFormatValues) => {
    setLocalEndDate(value);
  };

  useEffect(() => {
    const formattedStartDate = moment(localStartDate, "DDMMYYYY");
    if (formattedStartDate.isValid()) {
      pickStartDate(formattedStartDate.format("YYYYMMDD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStartDate]);

  useEffect(() => {
    const formattedEndDate = moment(localEndDate, "DDMMYYYY");
    if (formattedEndDate.isValid()) {
      pickEndDate(formattedEndDate.format("YYYYMMDD"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localEndDate]);

  return (
    <>
      <label className="report-date is-relative" ref={$startLabel}>
        <span>{t("report.startDate")}:</span>
        <br />
        <NumberFormat
          onClick={toggleStartPicker}
          onValueChange={startDateHandler}
          format="##.##.####"
          placeholder="DD.MM.YYYY"
          value={localStartDate}
          defaultValue={localStartDate}
          mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
        />
        {startPickerIsActive && (
          <CustomDateSinglePicker
            allowPastDates
            format="YYYYMMDD"
            date={startDate}
            onChange={startDatePicker}
          />
        )}
      </label>
      <label className="report-date is-relative" ref={$endLabel}>
        <span>{t("report.endDate")}:</span>
        <br />
        <NumberFormat
          onClick={toggleEndPicker}
          onValueChange={endDateHandler}
          format="##.##.####"
          placeholder="DD.MM.YYYY"
          value={localEndDate}
          defaultValue={localEndDate}
          mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
        />
        {endPickerIsActive && (
          <CustomDateSinglePicker allowPastDates format="YYYYMMDD" date={endDate} onChange={endDatePicker} />
        )}
      </label>
    </>
  );
};

export default ReportDatePicker;
