import i18n from "i18next";
import map from "lodash-es/map";
import times from "lodash-es/times";
import { Dispatch } from "redux";
import { ICustomer } from "../../interfaces/ICustomer";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC, GetState } from "../../types";
import {
  CUSTOMER_ADJUST_ROOMS,
  CUSTOMER_ASSIGN_ROOM,
  CUSTOMER_CLEAR,
  CUSTOMER_FETCH_FULFILLED,
  CUSTOMER_INITIALIZE,
  CUSTOMER_REMOVE_FROM_ROOMS,
  CUSTOMER_SET_FROM_LIST,
  CUSTOMER_TOGGLE
} from "../../utils/constants";
import { getCustomerAdultCount, getCustomerRooms } from "../selectors/customerSelectors";
import { mapCustomersToPassenger } from "./searchActions";

export const initCustomers = () => (dispatch: Dispatch) => {
  dispatch({
    type: CUSTOMER_INITIALIZE
  });
  dispatch<any>(setCustomerRooms());
};

export const setCustomersFromList: AC = (customers: ICustomer[] = []) => dispatch => {
  dispatch({
    payload: customers.map(cust => {
      cust.selected = true;
      return cust;
    }),
    type: CUSTOMER_SET_FROM_LIST
  });
};

export const fetchCustomers = (keyword = "") => async (dispatch: Dispatch) => {
  const log = new Logger(i18n.t("customer.fetchPending"));

  try {
    const { data } = await api().post("customerlist", { keyword });
    const customers: ICustomer[] = data.customers.map((customer: ICustomer) => {
      const localCustomers = JSON.parse(localStorage.getItem("customers") || "[]");
      if (map(localCustomers, "cust_guid").indexOf(customer.cust_guid) > -1) {
        customer.selected = true;
      }
      return customer;
    });
    dispatch({
      payload: customers.map(cust => {
        cust.passenger_type = cust.passenger_type.toUpperCase();
        cust.customer_type = cust.customer_type.toUpperCase();
        return cust;
      }),
      type: CUSTOMER_FETCH_FULFILLED
    });
    log.success(i18n.t("customer.fetchSuccess"));
  } catch (e) {
    log.error(e);
  }
};

export const toggleCustomer = (customer: ICustomer) => (dispatch: Dispatch<any>) => {
  const localCustomers: ICustomer[] = JSON.parse(localStorage.getItem("customers") || "[]");
  let newCustomers: ICustomer[];
  let removedCustomerGuid: string | undefined;

  if (customer.selected) {
    newCustomers = localCustomers.filter(c => c.cust_guid !== customer.cust_guid);
    removedCustomerGuid = customer.cust_guid;
  } else {
    newCustomers = [...localCustomers, { ...customer, selected: true }];
  }

  localStorage.setItem("customers", JSON.stringify(newCustomers));
  dispatch({
    payload: customer,
    type: CUSTOMER_TOGGLE
  });
  dispatch<any>(setCustomerRooms());
  if (removedCustomerGuid) {
    dispatch<any>(removeCustomerFromRooms(removedCustomerGuid));
  }
  dispatch(mapCustomersToPassenger());
};

export const setCustomerRooms = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const adultCount = getCustomerAdultCount(state);
  let rooms = [...getCustomerRooms(state)];

  if (adultCount > rooms.length) {
    times(adultCount - rooms.length, () =>
      rooms.push({
        custGuids: [],
        hasAdult: false,
        no: 0
      })
    );
  }

  if (adultCount < rooms.length) {
    rooms.pop();
  }

  rooms = rooms.map((r, i) => {
    r.no = i + 1;
    return r;
  });
  dispatch({
    payload: rooms,
    type: CUSTOMER_ADJUST_ROOMS
  });
};

export const removeCustomerFromRooms = (custGuid: string) => ({
  payload: custGuid,
  type: CUSTOMER_REMOVE_FROM_ROOMS
});

export const assignRoomForCustomer = (roomNo: number, custGuid: string) => {
  return {
    payload: { roomNo, custGuid },
    type: CUSTOMER_ASSIGN_ROOM
  };
};

export const clearCustomers: AC = () => dispatch => {
  localStorage.removeItem("customers");
  dispatch({
    type: CUSTOMER_CLEAR
  });
};

// function checkCustomerFlightType(customers: ICustomer[]): "BUSINESS" | "ECONOMY" {
//   return customers.length &&
//     customers.every(
//       c =>
//         !!c.policy &&
//         c.policy.flights &&
//         c.policy.flights.type &&
//         c.policy.flights.type.included.every(t => t.code === "BUSINESS")
//     )
//     ? "BUSINESS"
//     : "ECONOMY";
// }

// function addCustomerCountToSearch(
//   passengerCount: IPassengerCount,
//   customerCount: IPassengerCount,
//   dispatch: Dispatch<any>
// ) {
//   dispatch<any>(updateSearchPassenger({ [key]: passengerCount[key] - 1 } as any));
// }
