import { INotification } from "../interfaces/INotification";
import { IUser } from "../interfaces/IUser";

export default function notificationStorage() {
  const user: IUser = JSON.parse(localStorage.getItem("user")!);
  const notifications: INotification[] = user.notifications || [];

  const saveUser = () => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  return {
    fetch() {
      return notifications;
    },
    read() {
      user.notifications = notifications.map(n => {
        n.isRead = true;
        return n;
      });
      saveUser();
    },
    remove(id: any) {
      user.notifications = notifications.filter(n => n.notificationid !== id);
      saveUser();
    },
    removeAll() {
      user.notifications = [];
      saveUser();
    },
    save: (newNotifications: INotification[]) => {
      user.notifications = [...newNotifications, ...notifications];
      saveUser();
    }
  };
}
