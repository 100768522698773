import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import get from "lodash-es/get";
import React, { CSSProperties, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  IPnrActivity,
  IPnrCar,
  IPnrFlight,
  IPnrGeneric,
  IPnrHotel,
  IPnrSummary
} from "../../interfaces/IPnr";
import { getTheme } from "../../store/selectors/uiSelectors";
import Dates from "../Dates";

interface IProps {
  current?: IPnrSummary | IPnrHotel | IPnrFlight | IPnrActivity | IPnrCar | IPnrGeneric;
  pnr: IPnrSummary;
  selected: boolean;
  style?: CSSProperties;
  loadPnrDetails(requestData: any, basketGuid?: string): void;
}

const Pnr: FC<IProps> = ({ current, pnr, selected, style, loadPnrDetails }) => {
  const theme = useSelector(getTheme);
  const { t } = useTranslation();

  const logo = useMemo(() => {
    if (pnr.company_logos) {
      return get(
        pnr.company_logos.find(l => l.theme === theme),
        "url",
        ""
      );
    }
    return "";
  }, [pnr.company_logos, theme]);

  const dates = useMemo(() => {
    const payload = [];
    if (pnr.start_date) {
      payload.push({
        date: pnr.start_date!,
        direction: "0"
      });
    }
    if (pnr.start_date && pnr.end_date) {
      payload.push({
        date: pnr.end_date,
        direction: "1"
      });
    }
    return payload;
  }, [pnr.start_date, pnr.end_date]);

  const requestData = useMemo(
    () => ({
      action: pnr.action_type,
      lastname: pnr.lastname,
      pnr: pnr.pnr,
      source: pnr.source
    }),
    [pnr]
  );

  const cx = classNames("flex d-column pnr pointer", pnr.action_type, {
    selected
  });

  const cxStatus = classNames("flex j-between pnr-title", {
    [String(pnr.status).toLowerCase()]: pnr.status
  });

  const loadPnrDetailsHandler = () => {
    if (!current || pnr.pnr !== current.pnr) {
      loadPnrDetails(requestData, pnr.basket_guid);
    }
  };

  return (
    <div className="list-item-normalizer" style={style}>
      <div onClick={loadPnrDetailsHandler} className={cx}>
        <div className={cxStatus}>
          <p>
            <span className="text fs-med">PNR:</span> <span className="bold">{pnr.pnr}</span>
          </p>
          <p className="pnr-status">
            <span className="bold" style={{ marginRight: 5 }}>
              {pnr.status}
            </span>
            {pnr.action_type === "hotel" ? (
              <FontAwesomeIcon icon={["fas", "home"]} />
            ) : pnr.action_type === "flight" ? (
              <FontAwesomeIcon icon={["fas", "plane"]} />
            ) : pnr.action_type === "activity" ? (
              <FontAwesomeIcon icon={["fas", "campground"]} />
            ) : pnr.action_type === "car" ? (
              <FontAwesomeIcon icon={["fas", "car"]} />
            ) : pnr.action_type === "transfer" ? (
              <FontAwesomeIcon icon={["fas", "shuttle-van"]} />
            ) : (
              <FontAwesomeIcon icon={["fas", "question"]} />
            )}
          </p>
        </div>
        <div className="pnr-content flex j-between gap-10 fill-space">
          <div>
            <Dates dates={dates} />
          </div>
          {pnr.custlist && pnr.custlist.length > 0 && (
            <div className="passenger-list">
              <h1 className="fs-med c-primary bold">{t("pnr.passengers")}:</h1>
              {pnr.custlist.map((cust, index) => (
                <p className="fs-std c-primary" key={index}>
                  {cust.firstname + " " + cust.lastname}
                </p>
              ))}
            </div>
          )}
          <div className="fill-space" />
          <div className="corp-seal">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="pnr-footer flex j-between">
          {pnr.basket_guid !== "DIRECT" ? (
            <NavLink className="order-details" to={`/basket-module?basket=${pnr.basket_guid}`}>
              <FontAwesomeIcon icon={["far", "shopping-cart"]} />
              <span>{t("pnr.orderDetail")}</span>
            </NavLink>
          ) : (
            <p />
          )}

          <div>
            <p className="timestamp align-right">
              {t("pnr.creation")}: {pnr.creation_date}
            </p>
            <p className="timestamp align-right">
              {t("pnr.source")}: {pnr.source}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pnr;
