import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyFlight } from "../../../../interfaces/IPolicy";
import FormRow from "../../../../libs/Form/FormRow";
import FormSection from "../../../../libs/Form/FormSection";
import PolicyAirlines from "./PolicyAirlines";
import PolicyFlightCriteria from "./PolicyFlightCriteria";

interface IProps {
  policy?: IPolicyFlight;
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyFlights: FC<IProps> = ({ policy, onChange }) => {
  const { t } = useTranslation();

  return policy ? (
    <FormRow title={t("admin.policy.flightTitle")}>
      <FormSection title={t("admin.policy.airlineTitle")}>
        <div className="form-group">
          <PolicyAirlines onChange={onChange} {...policy.airlines} />
        </div>
      </FormSection>
      <FormSection title={t("admin.policy.flightCrit")}>
        <div className="form-group">
          <PolicyFlightCriteria onChange={onChange} {...policy.type} />
        </div>
      </FormSection>
    </FormRow>
  ) : null;
};

export default PolicyFlights;
