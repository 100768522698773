import i18n from "i18next";
import map from "lodash-es/map";
import { v5 as uuid } from "uuid";
import { ISearch } from "../../interfaces/ISearch";
import { IService } from "../../interfaces/IService";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  CAR_CLEAR,
  CAR_FETCH_EMPTY,
  CAR_FETCH_FULFILLED,
  CAR_FETCH_PENDING,
  CAR_FETCH_REJECTED,
  CAR_MODAL_CLOSE,
  CAR_MODAL_OPEN,
  CAR_SEARCH_SET
} from "../../utils/constants";
import { getCarServices } from "../selectors/serviceSelectors";
import { setFilterDefaults } from "./filterActions";

export const openCarsModal = () => ({
  type: CAR_MODAL_OPEN
});

export const closeCarsModal: AC = () => (dispatch, getState) => {
  const carServices = getCarServices(getState());

  dispatch({
    type: CAR_MODAL_CLOSE
  });
  dispatch({
    type: CAR_CLEAR,
    payload: carServices
  });
};

export const carsSetSearch = (payload: ISearch) => ({
  type: CAR_SEARCH_SET,
  payload
});

export const carsMakeSearch: AC = (search: ISearch) => (dispatch, getState) => {
  const state = getState();
  const carServices = getCarServices(state);
  search.guid = uuid(JSON.stringify(search) + Date.now(), uuid.URL);
  dispatch(carsSetSearch(search));
  dispatch(fetchCars(search, carServices));
};

export const clearCars: AC = (services: IService[]) => dispatch => {
  return dispatch({
    payload: map(services, "key"),
    type: CAR_CLEAR
  });
};

export const fetchCars: AC = (search: ISearch, services: IService[]) => dispatch => {
  dispatch(clearCars(services));

  for (const service of services) {
    const config: any = service.fullLink ? { baseUrl: "" } : {};

    let logger: Logger;

    logger = new Logger(i18n.t("car.searchPending"), {
      prefix: service.key
    });

    dispatch({
      service: service.key,
      type: `${service.key}_${CAR_FETCH_PENDING}`
    });

    api()
      .post(service.uri, search, config)
      .then(({ data }) => {
        if (data.cars && data.cars.length > 0) {
          if (logger) {
            logger.success(i18n.t("car.searchSuccess"));
          }
          dispatch({
            payload: data.cars,
            service: service.key,
            type: `${service.key}_${CAR_FETCH_FULFILLED}`
          });
          dispatch(setFilterDefaults());
        } else {
          if (logger) {
            logger.warning(i18n.t("car.searchEmpty"));
          }
          dispatch({
            service: service.key,
            type: `${service.key}_${CAR_FETCH_EMPTY}`
          });
        }
      })
      .catch(e => {
        logger.error(e);
        dispatch({
          service: service.key,
          type: `${service.key}_${CAR_FETCH_REJECTED}`
        });
      });
  }
};
