import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITransfer } from "../interfaces/ITransfer";
import Thumbnail from "../libs/Image/Thumbnail";
import Dates from "./Dates";

type Props = {
  transfer: ITransfer;
};

const TransferCard: FC<Props> = ({ transfer }) => {
  const { t } = useTranslation();
  const image = useMemo(() => {
    return get(transfer, "PictureUrl", "/images/default.png");
  }, [transfer]);

  const dates = useMemo(
    () => [
      {
        date: transfer.StartDate,
        direction: "0"
      }
    ],
    [transfer.StartDate]
  );

  const mapLink = `https://www.google.com/maps/dir/?api=1&origin=${transfer.PickupGeo.latitude},${transfer.PickupGeo.longitude}&destination=${transfer.DropoffGeo.latitude},${transfer.DropoffGeo.longitude}&travelMode=driving`;

  return (
    <div className="hotel box is-relative car-card">
      <div className="transferd flex column-gutter pointer">
        <div className="car-thumbnail">
          <Thumbnail width={75} height={75} rounded gap={10} layout="background" image={image} />
          <Thumbnail width={75} height={40} rounded gap={10} layout="fit" image={transfer.VendorLogo} />
        </div>
        <div className="info fill-space">
          <p className="name fs-prc bold">{transfer.ModelName}</p>
          <p className="fs-note c-gray source-note">{transfer.source}</p>
          <p className="address fs-std">{transfer.description}</p>
          <div className="car-amenities">
            {transfer.PassengerQty && <p className="car-amenity passenger">{transfer.seats}</p>}
            {transfer.NumberOfDoor && <p className="car-amenity door">{transfer.NumberOfDoor}</p>}
            {transfer.AirCondition && (
              <p className="car-amenity air-condition">{t("voucher.car.airCondition")}</p>
            )}
            {transfer.TransmissionType === "Manual" && (
              <p className="car-amenity manual">Manual Transmission</p>
            )}
            {transfer.TransmissionType === "Automatic" && (
              <p className="car-amenity automatic">Automatic Transmission</p>
            )}
          </div>
        </div>
        <Dates dates={dates} />
      </div>
      <a className="pickup-dropoff" href={mapLink} target="_blank" rel="noopener noreferrer">
        <p className="address fs-std pointer">
          <FontAwesomeIcon icon={["fas", "map-marker"]} />{" "}
          <span className="bold">{t("voucher.car.pickupAddress")}:</span>
          <br />
          {transfer.PickupAddress}
        </p>
        <p className="address fs-std pointer">
          <FontAwesomeIcon icon={["fas", "map-marker"]} />{" "}
          <span className="bold">{t("voucher.car.dropoffAddress")}:</span>
          <br />
          {transfer.DropoffAddress}
        </p>
      </a>
    </div>
  );
};

export default TransferCard;
