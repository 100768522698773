import isEmpty from "lodash-es/isEmpty";
import { utc as moment } from "moment";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../interfaces/ICustomer";
import { IHotel } from "../interfaces/IHotel";
import { pwc } from "../utils/helpers";
import languageStorage from "../utils/languageStorage";

interface IProps {
  customers: ICustomer[];
  hotels?: IHotel[];
  pnr: string;
}

const VoucherHotel: FC<IProps> = ({ customers, hotels = [], pnr }: IProps) => {
  const { t } = useTranslation();

  const allGuests = useMemo(() => {
    return customers.map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  const adults = useMemo(() => {
    return customers.filter(c => c.customer_type === "ADT").map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  const children = useMemo(() => {
    return customers
      .filter(c => c.customer_type === "CHD" || c.customer_type === "INF")
      .map(c => `${moment().diff(c.birthdate, "years")} ${t("voucher.age")}`);
  }, [customers, t]);

  return (
    <div className="hotel info">
      <div className="title">
        <img src="images/voucher/otel.png" alt="hotel" />
        <h3>{t("generic.title.hotelInfo")}</h3>
      </div>
      {hotels.map((hotel, index) => (
        <div key={index} className="caption">
          <div className="top-caption">
            <p>
              {hotel.HotelName}
              {hotel.HotelCategory && ` / ${hotel.HotelCategory}`}
              <br />

              {hotel.Address && (
                <>
                  {hotel.Address}
                  <br />
                </>
              )}
              {hotel.DestinationName && (
                <>
                  {hotel.DestinationName}
                  <br />
                </>
              )}
              {hotel.telephone && (
                <>
                  Tel: {hotel.telephone}
                  <br />
                </>
              )}
            </p>
            {hotel.supplier && !isEmpty(hotel.supplier) && (
              <p className="supplier">
                Payable through <span className="bold">{hotel.supplier.name}</span>, acting as agent for the
                service operating company, details of which can be provided upon request. VAT:{" "}
                <span className="bold">{hotel.supplier.vatNumber}</span>. Reference:{" "}
                <span className="bold">{pnr}</span>
              </p>
            )}
          </div>
          <p className="general">
            <span className="bold">PNR:</span> {pnr}
          </p>
          <table>
            <tbody>
              <tr>
                <td className="is-bold">{t("voucher.guest")}:</td>
                <td>{allGuests.join(", ")}</td>
                <td className="is-bold">{t("voucher.guestCount")}:</td>
                <td>{adults.length}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.checkIn")}:</td>
                <td>
                  {moment(hotel.CheckInDate)
                    .locale(languageStorage().current())
                    .format("DD MMMM Y")}
                </td>
                <td className="is-bold">{t("voucher.childCount")}:</td>
                <td>
                  {children.length} {children.length > 0 && "(" + children.join(", ") + ")"}
                </td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.board")}:</td>
                <td>{hotel.Board}</td>
                <td className="is-bold">{t("voucher.checkOut")}:</td>
                <td>
                  {moment(hotel.CheckOutDate)
                    .locale(languageStorage().current())
                    .format("DD MMMM Y")}
                </td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.room")}:</td>
                <td>1 {hotel.RoomType}</td>
                <td className="is-bold">{t("voucher.external")}:</td>
                <td />
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.paymentStatus")}:</td>
                <td>{hotel.payStatus}</td>
                <td className="is-bold">{t("voucher.roomCount")}:</td>
                <td>1</td>
              </tr>
              {hotel.source === "AGREEMENT" && (
                <tr>
                  <td className="is-bold">{t("voucher.totalFee")}:</td>
                  <td>
                    {pwc(hotel.OrgAmount, hotel.OrgCurrency)}
                    {hotel.vatIncluded === true
                      ? ` (%${hotel.vatRatio} ${t("generic.vatIncluded")})`
                      : hotel.vatIncluded === false && hotel.vatDescription}
                  </td>
                  <td />
                  <td />
                </tr>
              )}
            </tbody>
          </table>
          {hotel.prov && hotel.prov.rateComments && (
            <>
              <p className="bold no-margin">{t("cancellation.rateTitle")}:</p>
              <p>{hotel.prov.rateComments}</p>
            </>
          )}
          {hotel.source === "AGREEMENT" && (
            <>
              <br />
              <br />
              <p>{t("voucher.agreementMessage")}</p>
            </>
          )}
          {/*{hotel.prov && hotel.prov.cancellationPolicies && (*/}
          {/*  <>*/}
          {/*    <T tag="p" className="bold no-margin" tkey="cancellation.title" />*/}
          {/*    {hotel.prov.cancellationPolicies.map((policy, i) => (*/}
          {/*      <p key={i} className="general">*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.amount" punct=":" />{" "}*/}
          {/*        {pwc(policy.amount, policy.currency, true)}*/}
          {/*        {"  "}*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.date" punct=":" /> {policy.date}*/}
          {/*        {"  "}*/}
          {/*        <T tag="span" className="bold" tkey="cancellation.remainingDays" punct=":" />{" "}*/}
          {/*        {policy.remainingDays}*/}
          {/*      </p>*/}
          {/*    ))}*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      ))}
    </div>
  );
};

export default VoucherHotel;
