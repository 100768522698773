import isUndefined from "lodash-es/isUndefined";
import moment from "moment";
import React from "react";
import languageStorage from "../../utils/languageStorage";
import RibbonSvg from "../svg/RibbonSvg";

export default function DateRibbon({ date, direction }: { date: string; direction?: string }) {
  if (date && !date.includes("-")) {
    date = [date.slice(0, 4), "-", date.slice(4, 6), "-", date.slice(6)].join("");
  }
  const formatted = [
    moment(date).format("D"),
    moment(date)
      .locale(languageStorage().current())
      .format("MMM")
  ];

  return (
    <div className="date no-shrink flex a-center d-column">
      {!isUndefined(direction) && <RibbonSvg index={direction} transit={true} />}
      <span className="day text fs-med c-alternate bold">{formatted[0]}</span>
      <span className="month text fs-small c-alternate">{formatted[1]}</span>
    </div>
  );
}
