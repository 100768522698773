import uniqBy from "lodash-es/uniqBy";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import FilterSlider from "../../../../components/filters/FilterSlider";
import { IPolicyGeneric } from "../../../../interfaces/IPolicy";
import { hasPolicy, uniqid } from "../../../../utils/helpers";
import PolicyFilterRule from "./PolicyFilterRule";

interface IProps extends IPolicyGeneric {
  types?: Array<"ECONOMY" | "BUSINESS" | "FIRST CLASS">;
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyFlightCriteria: FC<IProps> = ({
  types = ["ECONOMY", "BUSINESS", "FIRST CLASS"],
  onChange,
  ...p
}) => {
  const { t } = useTranslation();
  const [time, setTime] = useState({ arrTime: { max: 24, min: 0 }, depTime: { max: 24, min: 0 } });

  const typeHandler = (type: string, sc: "excluded" | "included") => {
    const policy = p[sc].map(x => x.code).includes(type)
      ? p[sc].filter(x => x.code !== type)
      : [...p[sc], { code: type }];

    onChange!({
      data: uniqBy(policy, x => x.code),
      key: `flights.type.${sc}`
    });
  };

  const timeHandler = (state: { [key: string]: any }) => {
    setTime(x => ({ ...x, ...state }));
  };

  const filterRuleHandler = (section: "isFilter" | "isRule") => {
    onChange!({
      data: !p[section],
      key: `flights.type.${section}`
    });
  };

  return (
    <div>
      <div className="types excluded">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.excluded")}</h4>
        </div>
        <div className="flex">
          {types.map(ty => (
            <p
              key={ty}
              className={`cluded-item${hasPolicy(ty, p.excluded, "is-selected")}`}
              onClick={() => typeHandler(ty, "excluded")}
            >
              {ty}
            </p>
          ))}
        </div>
      </div>
      <div className="types included">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.included")}</h4>
        </div>
        <div className="flex">
          {types.map(ty => (
            <p
              key={ty}
              className={`cluded-item${hasPolicy(ty, p.included, "is-selected")}`}
              onClick={() => typeHandler(ty, "included")}
            >
              {ty}
            </p>
          ))}
        </div>
      </div>
      <div className="time-between">
        <FilterSlider
          suffix=":00"
          key={uniqid()}
          labelPosition="top"
          propKey="depTime"
          label={t("admin.policy.flightDeparture")}
          values={time.depTime}
          limits={{ max: 24, min: 0 }}
          onChange={timeHandler}
          step={1}
        />
        <FilterSlider
          suffix=":00"
          key={uniqid()}
          labelPosition="top"
          propKey="arrTime"
          label={t("admin.policy.flightArrival")}
          values={time.arrTime}
          limits={{ max: 24, min: 0 }}
          onChange={timeHandler}
          step={1}
        />
      </div>
      <PolicyFilterRule filter={p.isFilter} rule={p.isRule} onChange={filterRuleHandler} />
    </div>
  );
};

export default PolicyFlightCriteria;
