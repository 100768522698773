import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

type Props = {};

const AdminSubRoute: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <div className="admin-sub-nav">
      <ul className="flex">
        <li>
          <NavLink defaultChecked to="/admin/hotels">
            {t("admin.titles.hotels")}
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/policies">{t("admin.titles.policies")}</NavLink>
        </li>
        {/*<li>*/}
        {/*  <Link to="/admin/rights">{t("admin.titles.rights")}</Link>*/}
        {/*</li>*/}
      </ul>
    </div>
  );
};

export default AdminSubRoute;
