import classNames from "classnames";
import get from "lodash-es/get";
import React, { CSSProperties, FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IProgram } from "../../interfaces/IProgram";
import { selectProgram } from "../../store/actions/programAction";
import { getTheme } from "../../store/selectors/uiSelectors";
import Dates from "../Dates";

interface IProps {
  program: IProgram;
  style?: CSSProperties;
}

const ProgramListItem: FC<IProps> = ({ program, style }) => {
  const dispatch = useDispatch();

  const theme = useSelector(getTheme);
  const { t } = useTranslation();

  const selectHandler = useCallback(
    () => () => {
      dispatch(selectProgram(program.travel_guid));
    },
    [dispatch, program.travel_guid]
  );

  const logo = useMemo(() => {
    if (program.company && program.company.logos) {
      return get(
        program.company.logos.find(l => l.theme === theme),
        "url",
        ""
      );
    }
    return "";
  }, [program.company, theme]);

  const dates = useMemo(() => {
    const payload = [];
    if (program.search_params.fdate) {
      payload.push({
        date: program.search_params.fdate,
        direction: "0"
      });
    }
    if (program.search_params.fdate && program.search_params.rdate) {
      payload.push({
        date: program.search_params.rdate,
        direction: "1"
      });
    }
    return payload;
  }, [program.search_params.fdate, program.search_params.rdate]);

  const cx = classNames("flex d-column pnr pointer", {
    selected: program.selected
  });

  return (
    <div className="list-item-normalizer" style={style}>
      <div className={cx} onClick={selectHandler()}>
        <div className="flex j-between pnr-title confirmed">
          <p className="bold">{program.title}</p>
          <p className="pnr-status">
            <span className="bold" style={{ marginRight: 5 }}>
              ACTIVE
            </span>
          </p>
        </div>
        <div className="pnr-content flex j-between gap-10 fill-space">
          <div>
            <Dates dates={dates} />
          </div>
          <div className="fill-space" />
          <div className="corp-seal">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="pnr-footer flex j-between">
          <p />
          <div>
            <p className="timestamp align-right">{t("pnr.creation")}: #creationDate</p>
            <p className="timestamp align-right">{t("pnr.source")}: #source</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramListItem;
