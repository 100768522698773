import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import { INotification } from "../interfaces/INotification";

interface IProps extends INotification {
  dismiss(id: string): void;
}

export default function Notification({
  dismiss,
  notificationid,
  message,
  title,
  type,
  urlKey,
  urlValue
}: IProps) {
  const url = urlKey === "pnr" ? `/pnr-module?preload=${urlValue}` : "";

  return (
    <div className={`notification ${type}`}>
      <h1>
        <NavLink className="notification-title" to={url}>
          {title}
          <FontAwesomeIcon icon={["fal", "link"]} />
        </NavLink>
        <span className="dismiss pointer" onClick={() => dismiss(notificationid)}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </span>
      </h1>
      <hr />
      <p>{message}</p>
    </div>
  );
}
