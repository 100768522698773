import React, { FC, useEffect } from "react";
import ActivityMap from "../../containers/ActivityMap";
import { IActivity } from "../../interfaces/IActivity";
import Modal from "../../libs/Modal";

interface IProps {
  activity: IActivity;
  onClose(): void;
}

const ActivityDetailsModal: FC<IProps> = ({ activity, onClose }) => {
  useEffect(() => {
    const $el = document.querySelectorAll(".result-list") as NodeListOf<HTMLElement>;
    $el.forEach(el => (el.style.willChange = "unset"));
    return () => {
      $el.forEach(el => (el.style.willChange = "transform"));
    };
  }, []);

  return (
    <Modal.Container onClose={onClose} width={1000} padding={false}>
      <Modal.Body className="flex overflow-hidden activity-modal-body">
        <div className="activity-description">
          <h3 className="activity-modal-title">
            {activity.title}
            <br />
            {activity.name}
          </h3>
          <div
            className="text fs-med"
            dangerouslySetInnerHTML={{
              __html: activity.Description
            }}
          />
        </div>
        <div className="activity-map-container">
          <ActivityMap marker={{ lat: activity.Latitude, lng: activity.Longitude }} />
        </div>
      </Modal.Body>
    </Modal.Container>
  );
};

export default ActivityDetailsModal;
