import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import tooltip from "react-tooltip";
import { ICustomer } from "../../interfaces/ICustomer";
import { IPnrActivity, IPnrCar, IPnrFlight, IPnrGeneric, IPnrHotel } from "../../interfaces/IPnr";
import PnrPerson from "./PnrPerson";

interface IProps {
  pnr: IPnrFlight | IPnrHotel | IPnrActivity | IPnrCar | IPnrGeneric;
}

const PnrPeople: FC<IProps> = ({ pnr }) => {
  const { t } = useTranslation();

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  const fulfilled = useMemo(() => {
    return pnr.status === "TICKETED" || pnr.status === "CONFIRMED";
  }, [pnr]);

  return (
    <div className="pnr-details">
      {pnr.custlist && pnr.custlist.length > 0 && (
        <div className="title flex j-between a-center is-relative">
          <span className="pnr-header-icon c-primary">
            <FontAwesomeIcon icon={["far", "user"]} />
          </span>
          <h1 className="text fs-lrg fill-space c-primary bold">{t("pnr.passengers")}</h1>
        </div>
      )}
      <div className="pnr-customer-list">
        <table className="table table-ticket is-fullwidth">
          <thead>
            <tr>
              <th className="fs-med">{t("pnr.passenger")}</th>
              <th className="fs-med">{t("pnr.type")}</th>
              {(pnr.action === "hotel" || pnr.action === "activity") && fulfilled && (
                <th className="align-center fs-med">{t("pnr.voucher")}</th>
              )}
              <th className="align-center fs-med">{t("pnr.policy")}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {pnr.custlist &&
              pnr.custlist.length > 0 &&
              pnr.custlist.map((person: ICustomer, index: number) => (
                <PnrPerson
                  key={index}
                  offerId=""
                  customer={person}
                  pnr={pnr.pnr}
                  type={pnr.action}
                  fulfilled={fulfilled}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PnrPeople;
