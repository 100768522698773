import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import groupBy from "lodash-es/groupBy";
import map from "lodash-es/map";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IProgram } from "../../interfaces/IProgram";
import ProgramItems from "../ProgramItems";
import ProgramPeopleList from "./ProgramPeopleList";

interface IProps {
  program?: IProgram;
}

const ProgramDetails: FC<IProps> = ({ program }) => {
  const { t } = useTranslation();

  const [isDetailsActive, setIsDetailsActive] = useState(true);

  const programsGroupedBydate = useMemo(() => {
    return program ? groupBy(program.program_items, "item_date") : {};
  }, [program]);

  return (
    <div className="pnr-list-scroll-area">
      {program && (
        <div className="pnr-details">
          <div className="pnr-details-footer" />
          <div className="flex j-between">
            <div>
              <p className="c-primary text bold">{program.title}</p>
            </div>
            <div>
              <p className="c-primary pnr-status">
                <span className="text fs-med">{t("generic.status").toUpperCase()}:</span>
                <span className="ticketed bold">&nbsp;ACTIVE</span>
              </p>
              <p className="c-primary fs-std align-right">
                <span>{t("generic.source")}:</span>
                {program.status_id}
              </p>
            </div>
          </div>
          <div className="program-details-container">
            <h1 className="program-details-title" onClick={() => setIsDetailsActive(x => !x)}>
              {t("program.details")}{" "}
              <FontAwesomeIcon icon={["fas", isDetailsActive ? "caret-down" : "caret-up"]} />{" "}
            </h1>
            {isDetailsActive && (
              <table>
                <tbody>
                  {map(programsGroupedBydate, (programs, date) => (
                    <ProgramItems items={programs} date={date} key={date} />
                  ))}
                </tbody>
              </table>
            )}
          </div>
          {program && <ProgramPeopleList customers={program.customers} />}
        </div>
      )}
    </div>
  );
};

export default ProgramDetails;
