import includes from "lodash-es/includes";
import keys from "lodash-es/keys";
import orderBy from "lodash-es/orderBy";
import toLower from "lodash-es/toLower";
import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getAccountingState = (state: IStoreState) => state.accounting;

export const getAccountingReports = createSelector(getAccountingState, state => state.reports);

export const getHasAccountingReports = createSelector(getAccountingReports, reports => reports.length > 0);

export const getAccountingStatus = createSelector(getAccountingState, state => state.status);

export const getAccountingFilters = createSelector(getAccountingState, state => state.filters);

export const getAccountingOrders = createSelector(getAccountingState, state => state.orders);

// export const getAccountingFields = createSelector(
//   getAccountingReports,
//   reports => (reports.length ? union(...reports.map(r => Object.keys(r))) : undefined)
// );

export const getAccountingFields = createSelector(
  getAccountingState,
  accountingState => accountingState.fields
);

export const getAccountingSelectedReportKeys = createSelector(getAccountingState, state => state.selected);

export const getAccountingSelectedReports = createSelector(
  getAccountingReports,
  getAccountingSelectedReportKeys,
  (reports, reportKeys) => reports.filter(r => reportKeys.includes(r.paymentKey))
);

export const getAccountingOrderedReports = createSelector(
  getAccountingOrders,
  getAccountingReports,
  (orders, reports) => orderBy(reports, Object.keys(orders), Object.values(orders))
);

export const getAccountingFilteredReports = createSelector(
  getAccountingOrderedReports,
  getAccountingFilters,
  (reports, filters) => {
    return reports
      .filter(r => !filters.isCollected || !r.isCollected)
      .filter(r => !filters.isInvoiced || !r.isInvoiced)
      .filter(r => !filters.isCostInvoiced || !r.isCostinvoiced);
  }
);

export const getAccountingSearchedReports = createSelector(
  getAccountingFilteredReports,
  getAccountingFilters,
  (reports, filters) => {
    const { quickSearch, searchable } = filters;
    if (quickSearch.length < 3) {
      return reports;
    }

    const keywords = quickSearch.split(" ");

    return reports.filter(report =>
      keywords.every(word =>
        keys(report).some(param => {
          if (report[param] && includes(searchable, param)) {
            return includes(toLower(report[param]), toLower(word));
          }
          return false;
        })
      )
    );
  }
);

export const getAccountingAllFilteredReportsSelected = createSelector(
  getAccountingSelectedReportKeys,
  getAccountingSearchedReports,
  (selectedReports, searchedReports) => selectedReports.length === searchedReports.length
);
