import { Reducer } from "redux";
import { IProvisionState } from "../../interfaces/IState";
import {
  PROVISION_ANSWER_QUESTION,
  PROVISION_HAS_QUESTIONS,
  PROVISION_PENDING,
  PROVISION_REJECTED,
  PROVISION_FULFILLED
} from "../../utils/constants";

const initialState: IProvisionState = {
  provisions: {},
  rejectedRooms: {},
  rooms: [],
  status: {
    fetched: false,
    fetching: false
  }
};

const provisionReducer: Reducer<IProvisionState> = (state = initialState, action) => {
  switch (action.type) {
    case PROVISION_PENDING:
      return {
        ...state,
        status: {
          error: false,
          fetched: false,
          fetching: true
        }
      };
    case PROVISION_FULFILLED:
      return {
        ...state,
        rooms: [...state.rooms, action.payload],
        status: {
          error: false,
          fetched: true,
          fetching: false
        }
      };
    case PROVISION_REJECTED:
      return {
        ...state,
        rejectedRooms: {
          ...state.rejectedRooms,
          [action.payload.offerId]: action.payload.message
        },
        status: {
          error: false,
          fetched: true,
          fetching: false
        }
      };
    case PROVISION_HAS_QUESTIONS:
      return {
        ...state,
        provisions: {
          ...state.provisions,
          [action.payload.offerId]: action.payload.provision
        }
      };
    case PROVISION_ANSWER_QUESTION:
      return {
        ...state,
        provisions: {
          ...state.provisions,
          [action.payload.offerId]: {
            ...state.provisions[action.payload.offerId],
            confirmation: state.provisions[action.payload.offerId].confirmation.map(q => {
              if (q.question === action.payload.question.question) {
                return {
                  ...q,
                  isConfirmed: true
                };
              }
              return q;
            })
          }
        }
      };
  }
  return state;
};

export default provisionReducer;
