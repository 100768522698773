import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash-es/isEmpty";
import every from "lodash-es/every";
import flattenDeep from "lodash-es/flattenDeep";
import map from "lodash-es/map";
import uniq from "lodash-es/uniq";
import values from "lodash-es/values";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useObjectState from "../../hooks/useObjectState";
import { IActivity } from "../../interfaces/IActivity";
import { IVisa } from "../../interfaces/IBasket";
import { IBookingOptions, IPaymentRequest } from "../../interfaces/IBooking";
import { ICar } from "../../interfaces/ICar";
import { ICustomer } from "../../interfaces/ICustomer";
import { IDestination, IFlight } from "../../interfaces/IFlight";
import { IRoom } from "../../interfaces/IHotel";
import { IProvisionQuestion } from "../../interfaces/IProvision";
import { IBookingStatus } from "../../interfaces/IState";
import { ITransfer } from "../../interfaces/ITransfer";
import { IUser } from "../../interfaces/IUser";
import MultiSwitch from "../../libs/Switches/MultiSwitch";
import {
  answerProvisionQuestion,
  provisionFlight,
  provisionRoom
} from "../../store/actions/provisionActions";
import { toggleCheckoutScreen } from "../../store/actions/uiActions";
import { getProvisionResults } from "../../store/selectors/provisionSelectors";
import { getCheckoutShouldBeClosed } from "../../store/selectors/uiSelectors";
import { Dictionary } from "../../types";
import { pwc } from "../../utils/helpers";
import AccountingAction from "../AccountingAction";
import Checker from "../Checker";
import Countdown from "../Countdown";
import Loading from "../Loading";
import MiniModal from "../MiniModal";
import ReasonSelect from "../snippets/ReasonSelect";
import Switch from "../snippets/Switch";
import PaymentActivity from "./PaymentActivity";
import PaymentFlight from "./PaymentFlight";
import { PaymentHotel } from "./PaymentHotel";
import PaymentTransfer from "./PaymentTransfer";
import PaymentVisa from "./PaymentVisa";

interface IProps {
  actionType: string;
  amount: number;
  guid: string;
  bookingDetails: any;
  bookingStatus: IBookingStatus;
  currency: string;
  customers: ICustomer[];
  items: {
    activities?: IActivity[];
    flights?: IFlight[];
    hotels?: IRoom[];
    visas?: IVisa[];
    transfers?: ITransfer[];
    cars?: ICar[];
  };
  isBasket: boolean;
  isInternational?: boolean;
  user: IUser;
  pay(requestData: IPaymentRequest, options: IBookingOptions): any;
}

const Payment: FC<IProps> = props => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const provisionResults = useSelector(getProvisionResults);
  const checkoutShouldBeClosed = useSelector(getCheckoutShouldBeClosed);
  const [reason, setReason] = useState<string>();
  const [compliance, setCompliance] = useState("");
  const [overlimit, setOverlimit] = useState(false);
  const [createTicket, setCreateTicket] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [invoiceInfoIsActive, setInvoiceInfoIsActive] = useState(false);
  const [isProvision, setIsProvision] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useObjectState({
    comments: "",
    title: "",
    vkno: "",
    vkzone: ""
  });
  const [creditCard] = useObjectState({
    cardHolder: "",
    creditCard: "",
    expiryMonth: "",
    expiryYear: "",
    securityNo: ""
  });

  const questions = useMemo(() => {
    return [...(props.items.hotels || []), ...(props.items.flights || [])].reduce((acc, room) => {
      if (provisionResults[room.OfferId] && provisionResults[room.OfferId].confirmation) {
        acc[room.OfferId] = provisionResults[room.OfferId].confirmation;
      }
      return acc;
    }, {} as Dictionary<IProvisionQuestion[]>);
  }, [props.items.hotels, props.items.flights, provisionResults]);

  const reasons = useMemo(() => {
    return [...(props.items.hotels || []), ...(props.items.flights || [])].reduce((acc, room) => {
      if (provisionResults[room.OfferId] && provisionResults[room.OfferId].travelReason) {
        const roomReasons = provisionResults[room.OfferId]
          .travelReason!.map(r => r.description)
          .filter(x => !!x);
        return uniq([...acc, ...roomReasons]);
      }
      return acc;
    }, [] as string[]);
  }, [props.items.hotels, props.items.flights, provisionResults]);

  const compliances = useMemo(() => {
    return [...(props.items.hotels || []), ...(props.items.flights || [])].reduce((acc, room) => {
      if (provisionResults[room.OfferId] && provisionResults[room.OfferId].compliance) {
        const roomCompliances = provisionResults[room.OfferId]
          .compliance!.map(r => r[i18n.language])
          .filter(x => !!x);
        return uniq([...acc, ...roomCompliances]);
      }
      return acc;
    }, [] as string[]);
  }, [props.items.hotels, props.items.flights, provisionResults, i18n.language]);

  const hasUnansweredQuestions = useMemo(() => {
    return flattenDeep(values(questions)).some(q => !q.isConfirmed);
  }, [questions]);

  const checkProvisions = useCallback(async () => {
    setIsProvision(true);
    if (props.items.hotels) {
      for (const hotel of props.items.hotels) {
        await dispatch(provisionRoom(hotel, true));
      }
    }
    if (props.items.flights) {
      for (const flight of props.items.flights) {
        await dispatch(provisionFlight(flight));
      }
    }
    setIsProvision(false);
  }, [dispatch, props.items.flights, props.items.hotels]);

  useEffect(() => {
    checkProvisions();
    localStorage.setItem("lastPayment", JSON.stringify(props));
    if (props.customers.some(c => !!c.policy && props.amount > c.policy.budget.pending.limit)) {
      setOverlimit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasInvoiceInfo = useMemo(() => {
    return invoiceInfoIsActive && every(invoiceInfo, Boolean);
  }, [invoiceInfo, invoiceInfoIsActive]);

  const accountingHandler = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setInvoiceInfo({ [key]: e.currentTarget.value });
  };

  const disabledPaymentMethods = useMemo(() => {
    if (props.isInternational || props.actionType === "flight") {
      return ["guest"];
    }
    return [];
  }, [props.isInternational, props.actionType]);

  const createTicketHandler = useCallback((checked: boolean) => {
    setCreateTicket(checked);
  }, []);

  const makePayment = () => {
    const requestData: IPaymentRequest = {
      amount: props.amount,
      currency: props.currency,
      custlist: [],
      paymentType: {},
      user_guid: props.user.userguid
    };

    if (hasInvoiceInfo) {
      requestData.invoiceInfo = invoiceInfo;
    }

    if (compliance) {
      requestData.complianceReason = compliance;
    }

    if (reason) {
      requestData.travelReason = reason;
    }

    if (props.isBasket) {
      requestData.basket_guid = props.guid;
    } else {
      requestData.offer_guid = props.guid;
    }

    const options: IBookingOptions = {
      createTicket,
      custguid: [],
      isCompany: true,
      offerid: props.guid,
      type: props.actionType
    };

    switch (paymentMethod) {
      case "cash":
        requestData.paymentType.cash = true;
        break;
      case "vpos":
        requestData.paymentType.vpos = true;
        requestData.creditCard = creditCard;
        break;
      case "guest":
        requestData.paymentType.guest = true;
    }
    props.pay(requestData, options);
  };

  const hasFlight = useMemo(() => {
    return props.items && props.items.flights && props.items.flights.length;
  }, [props.items]);

  const hasCars = useMemo(() => {
    return props.items && props.items.cars && props.items.cars.length;
  }, [props.items]);

  const hasAgreementHotel = useMemo(() => {
    return props.items.hotels && props.items.hotels.some(h => h.source === "AGREEMENT");
  }, [props.items]);

  const paymentMethods = useMemo(() => {
    if (hasCars) {
      setPaymentMethod("guest");
      return ["guest"];
    }
    if (hasFlight || !hasAgreementHotel) {
      return ["cash"];
    } else {
      return ["guest", "cash"];
    }
  }, [hasAgreementHotel, hasCars, hasFlight]);

  const questionHandler = useCallback(
    (question: IProvisionQuestion, offerId: string) => () => {
      dispatch(answerProvisionQuestion(question, offerId));
    },
    [dispatch]
  );

  const listItems = useMemo(() => {
    const items = flattenDeep(Object.values(props.items) as any).filter(x => x);
    let i = 0;
    return items.map((item: any, index: number) => {
      if (item.action === "hotel") {
        i++;
        return <PaymentHotel hotel={item as IRoom} details={props.bookingDetails[i - 1]} key={index} />;
      } else if (item.action === "flight") {
        return (
          item.airitin &&
          item.airitin.origdests.map((dest: IDestination, di: number) => {
            return <PaymentFlight dest={dest} offerId={item.OfferId} key={index + di} />;
          })
        );
      } else if (item.action === "transfer") {
        return <PaymentTransfer transfer={item} key={index} />;
      } else if (item.activityId) {
        return <PaymentActivity activity={item as IActivity} key={index} />;
      }
      return <PaymentVisa visa={item} key={index} />;
    });
  }, [props.bookingDetails, props.items]);

  const buttonIsDisabled = useMemo(() => {
    return (
      props.bookingStatus.loading || hasUnansweredQuestions || isProvision || (!!reasons.length && !reason)
    );
  }, [props.bookingStatus.loading, hasUnansweredQuestions, isProvision, reasons.length, reason]);

  return (
    <div className="modal-wrapper">
      <div className="modal full-padding flex bordered width-500">
        <div className="payment-screen">
          {checkoutShouldBeClosed && <MiniModal />}
          <div className="payment-title flex j-between">
            <h2>
              {paymentMethod === "vpos" ? (
                <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
              ) : (
                <FontAwesomeIcon icon={["fas", "coins"]} />
              )}
              <span>{t("generic.payment")}</span>
            </h2>
            <button
              className="pointer c-primary modal-close-button"
              onClick={() => dispatch(toggleCheckoutScreen())}
            >
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          </div>
          <div className="flex full-width">
            <div className="full-width">
              <div className="payment-body">
                <p className="customers bold">
                  {props.customers.map(c => `${c.firstname} ${c.lastname}`).join(", ")}
                </p>
                <div className="items">{listItems}</div>
              </div>
              <div className="payment-footer">
                <div className="trip-reasons">
                  <div className="trip-reason accounting-actions">
                    <button onClick={() => setInvoiceInfoIsActive(!invoiceInfoIsActive)}>
                      <FontAwesomeIcon icon={["fas", invoiceInfoIsActive ? "minus-square" : "plus-square"]} />
                      <span className="fs-med">{t("payment.accounting.head")}</span>
                    </button>
                    {invoiceInfoIsActive && (
                      <div className="accounting-actions-list">
                        <AccountingAction
                          placeholder={t("payment.accounting.title")}
                          onChange={accountingHandler("title")}
                        />
                        <AccountingAction
                          placeholder={t("payment.accounting.taxNo")}
                          onChange={accountingHandler("vkno")}
                        />
                        <AccountingAction
                          placeholder={t("payment.accounting.taxOffice")}
                          onChange={accountingHandler("vkzone")}
                        />
                        <AccountingAction
                          extended
                          placeholder={t("payment.accounting.comment")}
                          onChange={accountingHandler("comments")}
                        />
                      </div>
                    )}
                  </div>
                  {!isProvision && (
                    <>
                      {overlimit && (
                        <div className="trip-reason">
                          <h3>{t("payment.compliancesTitle")}</h3>
                          <ReasonSelect onChange={value => setCompliance(value)} options={compliances} />
                        </div>
                      )}
                      {!isEmpty(reasons) && (
                        <div className="trip-reason">
                          <h3>{t("payment.reasonsTitle")}</h3>
                          <ReasonSelect onChange={value => setReason(value)} options={reasons} />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="provision-notes">
                  {isProvision ? (
                    <Loading />
                  ) : (
                    map(questions, (qs, offerId) =>
                      qs.map(q => (
                        <div key={q.title}>
                          <h1 className="fs-med fs-underline">{q.title}</h1>
                          <div className="flex a-center">
                            <Checker
                              checked={q.isConfirmed}
                              onCheck={questionHandler(q, offerId)}
                              description={q.question}
                            />
                          </div>
                        </div>
                      ))
                    )
                  )}
                </div>
                <MultiSwitch
                  className="payment"
                  selected={paymentMethod}
                  values={paymentMethods}
                  disabled={disabledPaymentMethods}
                  onSwitch={v => setPaymentMethod(v)}
                />
                {hasFlight && (
                  <Switch
                    alwaysTrue={overlimit}
                    className="payment"
                    labels={[t("payment.switchPrebkg"), t("payment.switchTicket")]}
                    checked={createTicket}
                    onChange={createTicketHandler}
                  />
                )}
                <p className="payment-price">
                  <span>{t("payment.total")}</span>
                  <span>{pwc(props.amount, props.currency)}</span>
                </p>
                <div className="flex j-between full-width">
                  <Countdown
                    seconds={120}
                    onFinish={() => dispatch(toggleCheckoutScreen())}
                    message={t("payment.countdownMessage")}
                  />
                  <span data-tip={t("payment.checkNotes")} data-tip-disable={!hasUnansweredQuestions}>
                    <button
                      disabled={buttonIsDisabled}
                      className="pay-button flex a-center"
                      onClick={makePayment}
                    >
                      {props.bookingStatus.loading ? (
                        <FontAwesomeIcon icon={["fas", "spinner"]} pulse={true} />
                      ) : props.bookingStatus.done === 1 ? (
                        <>
                          <span className="fs-med">Close</span>&nbsp;
                          <FontAwesomeIcon icon={["far", "times"]} />
                        </>
                      ) : (
                        <FontAwesomeIcon icon={["fas", "check-circle"]} />
                      )}
                    </button>
                  </span>
                </div>
              </div>
              {props.bookingStatus.steps.map((step, index) => {
                return (
                  <p key={index}>
                    {step.index}.&nbsp;{step.message}&nbsp;&nbsp;
                    {step.done === 0 ? (
                      <FontAwesomeIcon icon={["fas", "spinner"]} pulse={true} />
                    ) : step.done === 1 ? (
                      <FontAwesomeIcon icon={["far", "check"]} />
                    ) : (
                      <FontAwesomeIcon icon={["far", "times"]} />
                    )}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Payment.defaultProps = {
  isInternational: false
};

export default Payment;
