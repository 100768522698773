import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { bindActionCreators, Dispatch } from "redux";
import Hotel from "../components/Hotel";
import Sort from "../components/Sort";
import { Statuses } from "../components/Statuses";
import { IBookingOptions, IPaymentRequest } from "../interfaces/IBooking";
import { IHotel, IRoom } from "../interfaces/IHotel";
import { IBookingStatus, IStatus, IStoreState } from "../interfaces/IState";
import { pay, resetBooking } from "../store/actions/bookingActions";
import { changeFilterHotelsSortBy, toggleFilterHotels } from "../store/actions/filterActions";
import { fetchRooms, selectRoom } from "../store/actions/hotelActions";
import { makeSearch } from "../store/actions/searchActions";
import { getBookingStatus } from "../store/selectors/bookingSelectors";
import {
  getCustomersValidForBasket,
  getCustomersValidForBooking
} from "../store/selectors/customerSelectors";
import { getFilterHotelSortBy } from "../store/selectors/filterSelectors";
import {
  getHotelSearchDone,
  getHotelsResultEmpty,
  getHotelStatuses,
  getSearchedHotels
} from "../store/selectors/hotelSelectors";
import FilterHotel from "./FilterHotel";
import InlineSearchHotel from "./InlineSearchHotel";

interface IDispatchProps {
  actions: {
    changeFilterHotelsSortBy(sortBy: string): void;
    fetchRooms(hotelCode: string, source: string): void;
    makeSearch(): void;
    selectRoom(room: IRoom): void;
    pay(requestData: IPaymentRequest, options: IBookingOptions): void;
    resetBooking(): void;
    toggleFilter(): void;
  };
}

interface IStateProps {
  bookingStatus: IBookingStatus;
  customersValidForBooking: boolean;
  customersValidForBasket: boolean;
  hotels: IHotel[];
  isEmpty: boolean;
  searchIsDone: boolean;
  sortBy: string;
  statuses: IStatus[];
}

interface IProps extends IDispatchProps, IStateProps {}

const Hotels: FC<IProps> = ({ actions, ...p }) => {
  const { t } = useTranslation();

  // const renderHotels = () =>
  //   p.hotels.map((hotel, index) => (
  //     <Hotel
  //       key={index}
  //       selectRoom={actions.selectRoom}
  //       hotel={hotel}
  //       customersValidForBasket={p.customersValidForBasket}
  //       customersValidForBooking={p.customersValidForBooking}
  //       bookingStatus={p.bookingStatus}
  //       makeSearch={actions.makeSearch}
  //     />
  //   ));

  const rowRenderer: FC<ListChildComponentProps> = useCallback(
    ({ index, data, style }) => {
      return (
        <Hotel
          style={style}
          key={index}
          selectRoom={actions.selectRoom}
          hotel={data[index]}
          customersValidForBasket={p.customersValidForBasket}
          customersValidForBooking={p.customersValidForBooking}
          bookingStatus={p.bookingStatus}
          makeSearch={actions.makeSearch}
        />
      );
    },
    [
      actions.selectRoom,
      actions.makeSearch,
      p.customersValidForBasket,
      p.customersValidForBooking,
      p.bookingStatus
    ]
  );

  return p.statuses.some(s => s.fetched || s.fetching) ? (
    <div className="box-container hotels">
      <div className="title flex j-between a-center is-relative">
        <span className="result-header-icon c-primary">
          <FontAwesomeIcon icon={["fas", "home"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary">{t("hotel.resultTitle")}</h1>
        {p.searchIsDone ? (
          <>
            <div className="fill-space" />
            <Sort
              sortKeys={["price", "distance"]}
              current={p.sortBy}
              onChange={actions.changeFilterHotelsSortBy}
            />
            <InlineSearchHotel />
            <button className="button is-small filter-button" onClick={actions.toggleFilter}>
              <FontAwesomeIcon icon={["fas", "filter"]} />
            </button>
          </>
        ) : (
          <Statuses statuses={p.statuses} />
        )}
        <FilterHotel />
      </div>
      {p.isEmpty && <p className="not-found-message">{t("search.notFound")}</p>}
      <FixedSizeList
        className={`result-list${p.hotels.length > 0 ? "" : " hidden"}`}
        itemData={p.hotels}
        itemSize={240}
        height={1000}
        itemCount={p.hotels.length}
        width="100%"
      >
        {rowRenderer}
      </FixedSizeList>
    </div>
  ) : (
    <div />
  );
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  bookingStatus: getBookingStatus(state),
  customersValidForBasket: getCustomersValidForBasket(state),
  customersValidForBooking: getCustomersValidForBooking(state),
  hotels: getSearchedHotels(state),
  isEmpty: getHotelsResultEmpty(state),
  searchIsDone: getHotelSearchDone(state),
  sortBy: getFilterHotelSortBy(state),
  statuses: getHotelStatuses(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      changeFilterHotelsSortBy,
      fetchRooms,
      makeSearch,
      pay,
      resetBooking,
      selectRoom,
      toggleFilter: toggleFilterHotels
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Hotels);
