import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomePageBackground from "../components/HomePageBackground";
import SmartPackages from "../components/SmartPackages";
import Activities from "../containers/Activities";
import Flights from "../containers/Flights";
import Hotels from "../containers/Hotels";
import DefaultLayout from "../layouts/DefaultLayout";
import FullPage from "../libs/FullPage";
import Customers from "../modules/customersScreen/containers/Customers";
import HotelsDetailsScreen from "../modules/hotelDetailsScreen";
import { openHotelDetailsScreen } from "../store/actions/uiActions";
import { getFirstHotel, getOnlyHotelSearchDone } from "../store/selectors/hotelSelectors";
import { getPackagesReady } from "../store/selectors/smartPackagesSelectors";
import { getCustomerScreenIsActive, getHotelDetailsScreenState } from "../store/selectors/uiSelectors";
import { getUserBackgroundImage } from "../store/selectors/userSelectors";

const HomePage: FC = () => {
  const dispatch = useDispatch();
  const customerScreenIsActive = useSelector(getCustomerScreenIsActive);
  const hotelDetailsScreen = useSelector(getHotelDetailsScreenState);
  const packagesReady = useSelector(getPackagesReady);
  const onlyHotelSearchDone = useSelector(getOnlyHotelSearchDone);
  const firstHotel = useSelector(getFirstHotel);
  const backgroundImage = useSelector(getUserBackgroundImage);
  const [packagesIsActive, setPackagesIsActive] = useState(true);

  useEffect(() => {
    if (!packagesIsActive && !packagesReady) {
      setPackagesIsActive(true);
    }
  }, [packagesIsActive, packagesReady]);

  useEffect(() => {
    if (onlyHotelSearchDone) {
      dispatch(openHotelDetailsScreen(firstHotel));
    }
  }, [dispatch, firstHotel, onlyHotelSearchDone]);

  const closePackages = useCallback(() => {
    setPackagesIsActive(false);
  }, []);

  return (
    <DefaultLayout hasSearch>
      {customerScreenIsActive && <Customers />}
      {hotelDetailsScreen.active ? (
        <HotelsDetailsScreen />
      ) : (
        <>
          <FullPage>
            <>
              {packagesReady && packagesIsActive && <SmartPackages onClose={closePackages} />}
              <div className="results flex j-center container">
                <Flights />
                <Hotels />
                <Activities />
                {/*<Cars />*/}
              </div>
            </>
            <div>{packagesReady && <SmartPackages />}</div>
          </FullPage>
        </>
      )}
      <HomePageBackground src={backgroundImage} />
    </DefaultLayout>
  );
};

export default HomePage;
