import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "../store/selectors/uiSelectors";
import { getUserAgencyLogo } from "../store/selectors/userSelectors";

export const AgencyLogo = () => {
  const $img = useRef<HTMLImageElement>(null);
  const logo = useSelector(getUserAgencyLogo);
  const theme = useSelector(getTheme);

  const handler = useCallback(
    (e: any) => {
      e.currentTarget.src = `/images/trippep-${theme}.png`;
    },
    [theme]
  );

  return <img ref={$img} src={logo} onError={handler} alt={logo} />;
};
