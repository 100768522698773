import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getProgramState = (state: IStoreState) => state.programs;

export const getProgramStatus = createSelector(getProgramState, state => state.status);

export const getProgramSelectedGuid = createSelector(getProgramState, state => state.selected);

export const getProgramCompany = createSelector(getProgramState, state => state.company);

export const getProgramAgency = createSelector(getProgramState, state => state.agency);

export const getPrograms = createSelector(getProgramState, getProgramSelectedGuid, (state, guid) =>
  state.programs.map(p => ({
    ...p,
    agency: state.agency,
    company: state.company,
    selected: p.travel_guid === guid
  }))
);
