import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import isString from "lodash-es/isString";
import keys from "lodash-es/keys";
import map from "lodash-es/map";
import reduce from "lodash-es/reduce";
import sumBy from "lodash-es/sumBy";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import InlineSearch from "../../components/InlineSearch";
import Loading from "../../components/Loading";
import ReportDatePicker from "../../components/ReportDatePicker";
import SpreadsheetButton from "../../components/snippets/SpreadsheetButton";
import useObjectState from "../../hooks/useObjectState";
import { IReportField } from "../../interfaces/IAccounting";
import DefaultLayout from "../../layouts/DefaultLayout";
import { api } from "../../services/api";
import { getUser } from "../../store/selectors/userSelectors";
// @ts-ignore
import { Cell, Row, StickyTable } from "react-sticky-table";
import { Dictionary } from "../../types";

const CompanyReconciliationPage: FC = () => {
  const match = useRouteMatch<{ custguid: string }>();
  const { t } = useTranslation();

  const user = useSelector(getUser);
  const [fields, setFields] = useState<Dictionary<IReportField>>({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useObjectState({
    company: user.companycode,
    currency: "TRY",
    edate: moment().format("YYYYMMDD"),
    sdate: moment().format("YYYY0101")
  });
  const [filteredReports, setFilteredReports] = useState([]);

  const filterHandler = useCallback(
    (filteredItems: any) => {
      setFilteredReports(filteredItems);
    },
    [setFilteredReports]
  );

  const fetchReports = useCallback(
    async (custguid?: any) => {
      const x: any = { ...payload };
      if (isString(custguid)) {
        x.cust_guid = custguid;
      }
      setLoading(true);
      setReports([]);
      try {
        const { data } = await api().post("/rptreconcilationcompany", x);
        const hasData = get(data, "report.length", false);
        if (hasData) {
          setReports(data.report);
          setFilteredReports(data.report);
          if (data.fields) {
            setFields(data.fields);
          } else {
            setFields(
              reduce(
                keys(data.report[0]),
                (fields, field) => {
                  fields[field] = {
                    title: field.replace(/_/g, " ")
                  };
                  return fields;
                },
                {} as Dictionary<IReportField>
              )
            );
          }
        }
      } catch {}
      setLoading(false);
    },
    [payload]
  );

  useEffect(() => {
    if (match.params.custguid) {
      fetchReports(match.params.custguid);
    }
  }, [fetchReports, match.params.custguid]);

  const dateHandler = useCallback(
    (field: string) => (value: string) => {
      setPayload({
        [field]: value
      });
    },
    [setPayload]
  );

  return (
    <DefaultLayout>
      <div className="container main">
        <div className="reports">
          <div className="report-page-title">
            <h2>{t("report.title.companyrecon")}</h2>
          </div>
          <div className="reports-header">
            <div className="reports-header-row">
              <div className="report-filters flex inline a-center">
                <ReportDatePicker
                  startDate={payload.sdate}
                  endDate={payload.edate}
                  pickEndDate={dateHandler("edate")}
                  pickStartDate={dateHandler("sdate")}
                />
                <button disabled={loading} onClick={fetchReports}>
                  {loading ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
                </button>
              </div>
              {loading && <Loading />}
              {reports.length > 0 && (
                <SpreadsheetButton
                  filename="Reconciliation"
                  reports={filteredReports}
                  fields={fields}
                  total={true}
                />
              )}
            </div>
            {reports.length > 0 && (
              <div className="reports-header-row">
                <InlineSearch
                  items={reports}
                  searchableKeys={["company_code", "action", "pnr", "pax_name", "supplier_code", "service"]}
                  onSearch={filterHandler}
                />
              </div>
            )}
          </div>
          <div className="reports-table-container">
            {reports.length > 0 && (
              <StickyTable stickyColumnCount={0} className="report-table striped">
                <Row>
                  {map(fields, (options, field) => (
                    <Cell key={field}>{options.title}</Cell>
                  ))}
                </Row>
                {filteredReports.map((report, index) => (
                  <Row key={index}>
                    {map(fields, (options, field) => (
                      <Cell key={index + field}>{report[field]}</Cell>
                    ))}
                  </Row>
                ))}
                <Row className="total-row">
                  {keys(fields).map((field, index) => {
                    if (index === 0) {
                      return (
                        <Cell>
                          {filteredReports.length} {t("report.rows")}
                        </Cell>
                      );
                    }

                    if (
                      index > 1 &&
                      [
                        "markup",
                        "commission",
                        "total_amount",
                        "tax",
                        "surcharge",
                        "service_fee",
                        "fare"
                      ].includes(field)
                    ) {
                      return <Cell key={field}>{sumBy(filteredReports, field).toFixed(2)}</Cell>;
                    }
                    return <Cell key={field} />;
                  })}
                </Row>
              </StickyTable>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CompanyReconciliationPage;
