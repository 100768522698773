import chunk from "lodash-es/chunk";
import isUndefined from "lodash-es/isUndefined";
import reduce from "lodash-es/reduce";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AmenityIcon from "../../../components/amenity/AmenityIcon";
import Gallery from "../../../components/Gallery";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { IAmenity, IHotel, IHotelInfo } from "../../../interfaces/IHotel";
import Thumbnail from "../../../libs/Image/Thumbnail";
import { fetchHotelDetails } from "../../../store/actions/hotelActions";
import { getHotelsDetails } from "../../../store/selectors/hotelSelectors";
import HotelDetailsTitle from "./HotelDetailsTitle";

const DetailsHotelInfos: FC<IHotel> = hotel => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);
  const [galleryIsActive, setGalleryIsActive] = useState<number>();
  const hotelsDetails = useSelector(getHotelsDetails);

  const currentDetail: IHotelInfo | undefined = useMemo(() => {
    return hotelsDetails[hotel.HotelCode];
  }, [hotelsDetails, hotel.HotelCode]);

  const fetchDetailsHandler = useCallback(async () => {
    setIsBusy(true);
    await dispatch(fetchHotelDetails(hotel.HotelCode, hotel.source));
    setIsBusy(false);
  }, [dispatch, hotel.HotelCode, hotel.source]);

  useEffect(() => {
    if (!currentDetail) {
      fetchDetailsHandler();
    }
  }, [currentDetail, fetchDetailsHandler, hotel.HotelCode, hotel.source]);

  const amenities = useMemo(() => {
    if (currentDetail && currentDetail.amenity) {
      const filtered = reduce(
        currentDetail.amenity,
        (acc, amenity, key) => {
          if (amenity.isActive) {
            amenity.key = key;
            acc.push(amenity);
          }
          return acc;
        },
        [] as IAmenity[]
      );
      return chunk(filtered, 3);
    }
    return [];
  }, [currentDetail]);

  return (
    <div className="hotel-details-infos">
      <HotelDetailsTitle />
      <LoadingWrapper loading={isBusy}>
        {currentDetail && (
          <>
            {!isUndefined(galleryIsActive) && (
              <Gallery
                images={currentDetail.images}
                active={galleryIsActive}
                onClose={() => setGalleryIsActive(undefined)}
              />
            )}
            {currentDetail.images && (
              <div className="hotel-info-thumbnails flex">
                {currentDetail.images.slice(0, 8).map((image, index) => (
                  <Thumbnail
                    onClick={() => setGalleryIsActive(index)}
                    className="hotel-info-thumbnail"
                    column={4}
                    height={75}
                    gap={10}
                    rounded
                    layout="background"
                    image={image}
                    key={index}
                  />
                ))}
                <div className="fill-space" />
              </div>
            )}
            <div className="hotel-meta-infos">
              <p className="bold">{t("generic.address")}:</p>
              <p>{currentDetail.Address}</p>
              <p className="bold">{t("generic.phone")}:</p>
              <p>{currentDetail.telephone}</p>
              <p className="bold">{t("generic.email")}:</p>
              <p>{currentDetail.email}</p>
            </div>
            <p className="fs-std" dangerouslySetInnerHTML={{ __html: currentDetail.HotelLongDesc }} />
            <h1 className="bold">{t("detailHotel.info.title")}</h1>
            <table className="table fixed-layout is-fullwidth">
              <tbody>
                {amenities.map((row, ri) => (
                  <tr key={ri}>
                    {row.map((amenity, ai) => (
                      <td className="fs-std" key={ri + ai}>
                        <AmenityIcon amenity={amenity} />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {currentDetail.poilist && (
              <>
                <h1 className="bold">{t("detailHotel.info.poiTitle")}</h1>
                <table className="table fixed-layout is-fullwidth">
                  <tbody>
                    {currentDetail.poilist.map((poi, i) => (
                      <tr key={i}>
                        <td className="fs-std" key={poi.name}>
                          {poi.name}
                        </td>
                        <td className="fs-std" align="right" key={poi.distance}>
                          {poi.distance}
                        </td>
                        <td />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {currentDetail.rules && (
              <>
                <h1>{t("generic.rules")}</h1>
                <div className="rules">
                  {currentDetail.rules.map((r, i) => (
                    <p key={i}>{r}</p>
                  ))}
                </div>
              </>
            )}
            {/*<h1>Hotel Surroundings</h1>*/}
            {/*<div className="hotel-surroundings">*/}
            {/*{poilistDefault.map((poi, i) => <div className="flex j-between" key={i}>*/}
            {/*<p>{poi.name}</p>*/}
            {/*<p className="self-end">{poi.distance}</p>*/}
            {/*</div>)}*/}
            {/*</div>*/}
          </>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default DetailsHotelInfos;
