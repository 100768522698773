import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../interfaces/ICustomer";

interface IProps {
  customer: ICustomer;
}

const OfferCustomer: FC<IProps> = ({ customer }) => {
  const { t } = useTranslation();
  // const passengerTypeParser = useMemo(() => {
  //   switch (customer.passenger_type) {
  //     case "ADT":
  //       return t("customer.adt");
  //     case "CHD":
  //       return t("customer.chd");
  //     case "INF":
  //       return t("customer.inf");
  //     default:
  //       return t("customer.unknown");
  //   }
  // }, []);

  return (
    <div className="flex a-center full-width offer-customer">
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>
              {customer.firstname} {customer.lastname}, {customer.title}
            </th>
            <th>{t("generic.email")}</th>
            <th>{t("offerApprove.customer.mobile")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {customer.department}, {customer.company_title}
            </td>
            <td>{customer.email}</td>
            <td>{customer.telephone}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OfferCustomer;
