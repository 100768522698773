import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPassengerCount } from "../../../interfaces/ISearch";

interface IProps {
  count: IPassengerCount;
}

const PassengerCountMeter = ({ count: { adt, chd, inf } }: IProps) => {
  const { t } = useTranslation();

  const addMessage = useMemo(() => {
    const needAdd = [];

    if (adt > 0) {
      needAdd.push(`${adt} ${t("customer.adt")}`);
    }
    if (chd > 0) {
      needAdd.push(`${chd} ${t("customer.chd")}`);
    }
    if (inf > 0) {
      needAdd.push(`${inf} ${t("customer.inf")}`);
    }

    return needAdd.length ? t("customer.checkCount", { count: needAdd.join(", ") } as any) : false;
  }, [adt, chd, inf, t]);

  const removeMessage = useMemo(() => {
    const needRemove = [];

    if (adt < 0) {
      needRemove.push(`${Math.abs(adt)} adult`);
    }
    if (chd < 0) {
      needRemove.push(`${Math.abs(chd)} child`);
    }
    if (inf < 0) {
      needRemove.push(`${Math.abs(inf)} infant`);
    }

    return needRemove.length ? `You need to deselect ${needRemove.join(", ")}` : false;
  }, [adt, chd, inf]);
  return (
    <div className="passenger-count">
      {!adt && !chd && !inf ? (
        <p>All customers are set. You can continue to booking.</p>
      ) : (
        <>
          {addMessage && <p>{addMessage}</p>}
          {removeMessage && <p>{removeMessage}</p>}
        </>
      )}
    </div>
  );
};

export default PassengerCountMeter;
