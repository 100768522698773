import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IBookingOptions, IProvisionCustomer } from "../interfaces/IBooking";
import { IRoom } from "../interfaces/IHotel";
import { COLORS } from "../utils/constants";
import { pwc } from "../utils/helpers";
import Dates from "./Dates";
import BasketLine from "./snippets/BasketLine";

interface IProps {
  custguid: IProvisionCustomer[];
  room: IRoom;
  bookingOffer(payload: IBookingOptions): void;
  deleteItem?(OfferId: string): void;
}

const OrderRoom: FC<IProps> = ({ custguid, room, bookingOffer }) => {
  const { t } = useTranslation();

  const bookingOfferHandler = () => {
    bookingOffer({
      createTicket: false,
      custguid,
      isCompany: true,
      isRepeat: true,
      offerid: room.OfferId,
      type: "hotel"
    });
  };

  const dates = useMemo(
    () => [
      { date: room.CheckInDate, direction: "0" },
      { date: room.CheckOutDate, direction: "1" }
    ],
    [room.CheckInDate, room.CheckOutDate]
  );

  const line = useMemo(
    () => ({
      direction: "0",
      finishColor: COLORS.orange[0],
      startColor: COLORS.blue[0],
      type: "hotel"
    }),
    []
  );

  return (
    <div className="hotel box no-padding-right padding-bottom-10 flex d-column">
      <div className="flex">
        <Dates className="no-margin-top" dates={dates} vertical={true} />
        <BasketLine {...line} />
        <div className="description fill-space flex d-column j-even">
          <p className="fs-med">
            {room.CheckInTime}
            <span className="fs-small">{t("hotel.checkIn")}</span>
          </p>
          <div className="info flex a-center">
            <div
              className="bg-image bg-cover no-shrink"
              style={{ backgroundImage: `url(${room.HotelImageUrl})` }}
            />
            <p className="fs-med">
              {room.HotelName} <br /> Standard Twin Room
            </p>
          </div>
          <p className="fs-med">
            {room.CheckOutTime}
            <span className="fs-small">{t("hotel.checkOut")}</span>
          </p>
        </div>
        <div className="right-side flex d-column">
          <p className="basket-item-status">{room.status}</p>
          <button
            onClick={bookingOfferHandler}
            disabled={room.status === "NEW"}
            className="button remove self-end left-radius"
          >
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
          <button
            onClick={bookingOfferHandler}
            disabled={room.status === "NEW"}
            className="button cancel self-end left-radius"
          >
            <FontAwesomeIcon icon={["far", "ban"]} />
          </button>
          <button
            onClick={bookingOfferHandler}
            disabled={room.status !== "NEW"}
            className="button buy self-end left-radius"
          >
            <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
          </button>
        </div>
      </div>
      <div className="basket-item-footer flex j-end">
        <p className="price-tag fs-prc is-centered left-radius">
          {pwc(room.AmountAfterTax, room.HotelCurrency)}
        </p>
      </div>
    </div>
  );
};

export default OrderRoom;
