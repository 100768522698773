import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { percent, pwc } from "../../utils/helpers";

interface IProps {
  data?: any[];
}

const AgreeSpentTable: FC<IProps> = ({ data }: IProps) => {
  const { t } = useTranslation();
  const total = useMemo(() => {
    return data ? data.reduce((output, d) => output + d.total_amount, 0) : 0;
  }, [data]);

  const agreePercent = useMemo(() => {
    return data ? percent(data[0].total_amount, total) : 0;
  }, [total, data]);

  return data ? (
    <div className="spent-table">
      {t("corpReport.expense.table.title")}
      <div className="total">
        <FontAwesomeIcon color="#ed7d31" icon={["fas", "bookmark"]} />
        <p>{pwc(total, "TRY")}</p>
      </div>
      <table>
        <tbody>
          <tr>
            <td className="spent-title">{t("corpReport.expense.agreeTable.percent")}</td>
            <td className="spent-value">
              <span>{agreePercent}</span>
            </td>
          </tr>
          <tr>
            <td className="spent-title">{t("corpReport.expense.agreeTable.nonAgreeAmount")}</td>
            <td className="spent-value">
              <span>{data && data[0] ? pwc(data[0].total_amount, data[0].currency) : 0}</span>
            </td>
          </tr>
          <tr>
            <td className="spent-title">{t("corpReport.expense.agreeTable.agreeAmount")}</td>
            <td className="spent-value">
              <span>{data && data[1] ? pwc(data[1].total_amount, data[1].currency) : 0}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default AgreeSpentTable;
