import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FilterChoice from "../components/filters/FilterChoice";
import Filters from "../components/filters/Filters";
import FilterSlider from "../components/filters/FilterSlider";
import { changeFilterCars, resetFilterCars, toggleFilterCars } from "../store/actions/filterActions";
import { getCarCurrency, getCarMaxPrice, getCarMinPrice } from "../store/selectors/carSelectors";
import { getFiltersCar } from "../store/selectors/filterSelectors";
import { currencyParser, uniqid } from "../utils/helpers";

const FilterCar: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const currency = useSelector(getCarCurrency);
  const minPrice = useSelector(getCarMinPrice);
  const maxPrice = useSelector(getCarMaxPrice);
  const filters = useSelector(getFiltersCar);

  const newFilterHandler = useCallback(
    (field: string) => (newValue: any) => {
      dispatch(
        changeFilterCars({
          ...filters,
          [field]: newValue
        })
      );
    },
    [dispatch, filters]
  );

  const filterHandler = useCallback(
    (newFilter: { [key: string]: any }) => {
      dispatch(
        changeFilterCars({
          ...filters,
          ...newFilter
        })
      );
    },
    [dispatch, filters]
  );

  const resetHandler = useCallback(() => {
    dispatch(resetFilterCars());
  }, [dispatch]);

  const toggleHandler = useCallback(() => {
    dispatch(toggleFilterCars());
  }, [dispatch]);

  return filters.isActive ? (
    <Filters key={uniqid()} onReset={resetHandler} onClose={toggleHandler}>
      <FilterSlider
        label={t("filter.flight.price")}
        suffix={currencyParser(currency)}
        propKey="price"
        step={50}
        values={{
          max: filters.price.max,
          min: filters.price.min
        }}
        limits={{
          max: maxPrice,
          min: minPrice
        }}
        onChange={filterHandler}
      />
      <FilterChoice
        allValue={0}
        choices={[2, 3, 4, 5, [6, "6+"]]}
        value={filters.seats}
        label={t("filter.car.seat")}
        onChange={newFilterHandler("seats")}
      />
      <FilterChoice
        allValue={0}
        choices={[2, 4, 5]}
        value={filters.doors}
        label={t("filter.car.door")}
        onChange={newFilterHandler("doors")}
      />
      <FilterChoice
        allValue="all"
        choices={["Automatic", "Manual"]}
        value={filters.transmission}
        label={t("filter.car.transmission")}
        onChange={newFilterHandler("transmission")}
      />
    </Filters>
  ) : null;
};

export default FilterCar;
