import { Reducer } from "redux";
import { IPnrState } from "../../interfaces/IState";
import {
  PNR_FETCH_DETAILS_FULFILLED,
  PNR_FETCH_DETAILS_PENDING,
  PNR_FETCH_DETAILS_SELECT,
  PNR_FETCH_FULFILLED,
  PNR_FETCH_PENDING,
  PNR_FETCH_REJECTED,
  PNR_UPDATE
} from "../../utils/constants";

const initialState: IPnrState = {
  currentPnrNumber: "",
  detailIsLoading: false,
  pnrs: [],
  pnrsDetailed: [],
  status: {
    fetched: false,
    fetching: false
  }
};

const pnrReducer: Reducer<IPnrState> = (state = initialState, action) => {
  switch (action.type) {
    case PNR_FETCH_PENDING:
      return {
        ...state,
        currentPnrNumber: "",
        pnrs: [],
        status: {
          fetched: false,
          fetching: true
        }
      };
    case PNR_FETCH_FULFILLED:
      return {
        ...state,
        pnrs: action.payload,
        status: {
          fetched: true,
          fetching: false
        }
      };
    case PNR_FETCH_REJECTED:
      return {
        ...state,
        pnrs: [],
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
    case PNR_FETCH_DETAILS_PENDING:
      return {
        ...state,
        detailIsLoading: true
      };
    case PNR_FETCH_DETAILS_FULFILLED:
      return {
        ...state,
        currentPnrNumber: action.payload.pnr,
        detailIsLoading: false,
        pnrsDetailed: state.pnrsDetailed.find(pnr => pnr.pnr === action.payload.pnr)
          ? [...state.pnrsDetailed].map(pnr => {
              if (pnr.pnr === action.payload.pnr) {
                return action.payload;
              }
              return pnr;
            })
          : [...state.pnrsDetailed, action.payload]
      };
    case PNR_FETCH_DETAILS_SELECT:
      return {
        ...state,
        currentPnrNumber: action.payload
      };
    case PNR_UPDATE:
      return {
        ...state,
        pnrs: state.pnrs.map(pnr => {
          if (pnr.pnr === action.payload.pnr) {
            pnr.status = action.payload.status;
          }
          return pnr;
        })
      };
    default:
      return state;
  }
};

export default pnrReducer;
