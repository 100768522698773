import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Loading from "../components/Loading";
import PageFooter from "../components/PageFooter";
import useClickDetect from "../hooks/useClickDetect";
import { api } from "../services/api";
import Logger from "../services/Logger";
import { attemptLogin } from "../store/actions/userActions";
import languageStorage from "../utils/languageStorage";

type Props = {};

const LoginPage: FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const $el = useClickDetect(() => setMenuIsActive(false));

  const [changePassword, setChangePassword] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [lang, setLang] = useState("tr");

  useEffect(() => {
    languageStorage().refresh();
    setLang(languageStorage().current());
  }, []);

  const usernameHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  }, []);

  const passwordHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const changeLanguage = useCallback(
    (newLang: "en" | "tr") => {
      setLang(newLang);
      i18n.changeLanguage(newLang).then(() => languageStorage().save(newLang));
      setMenuIsActive(!menuIsActive);
    },
    [i18n, menuIsActive]
  );

  const changePasswordHandler = useCallback(() => {
    setIsBusy(true);
    api()
      .put("changepwd", username, {
        headers: { "Content-Type": "text/plain" }
      })
      .then(() => {
        setIsBusy(false);
        Logger.flash("Email has been sent.");
        setChangePassword(false);
      })
      .catch(() => {
        setIsBusy(false);
        Logger.flashError("User is not found.");
      });
  }, [username]);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setIsBusy(true);
    await dispatch(
      attemptLogin({
        app: "WEB",
        lang,
        password,
        username
      })
    );
    setIsBusy(false);
  };

  return (
    <div className="login-page">
      <div className="login-page-container">
        <form className="login-form" onSubmit={submit}>
          <div className="login-header">
            {/*<h1 className="login-title">{t(changePassword ? "login.form.forgotTitle" : "login.form.title")}</h1>*/}
            <span className="login-title">
              <img src="/images/trippep-light.png" alt="" />
            </span>
          </div>
          <div className="login-body">
            <div className="input">
              <span>
                <FontAwesomeIcon icon={["far", "user"]} />
              </span>
              <input
                autoFocus
                value={username}
                placeholder={t("login.form.username")}
                type="text"
                onChange={usernameHandler}
              />
            </div>

            {!changePassword && (
              <>
                <div className="input">
                  <span>
                    <FontAwesomeIcon icon={["far", "key"]} />
                  </span>
                  <input
                    value={password}
                    placeholder={t("login.form.password")}
                    type="password"
                    onChange={passwordHandler}
                  />
                </div>
                <div className="forgot">
                  <button type="button" className="pointer" onClick={() => setChangePassword(true)}>
                    {t("login.form.forgot")}
                  </button>
                </div>
              </>
            )}
            {changePassword && (
              <div className="forgot">
                <button type="button" className="pointer" onClick={() => setChangePassword(false)}>
                  {t("generic.back")}
                </button>
              </div>
            )}
            <div ref={$el} className="lang-select pointer">
              <div className="lang">
                <FontAwesomeIcon icon={["fas", "globe"]} />
                <p onClick={() => setMenuIsActive(!menuIsActive)}>{lang === "tr" ? "Türkçe" : "English"}</p>
                <FontAwesomeIcon icon={["far", "chevron-down"]} />
              </div>
              {menuIsActive && (
                <div className="lang-dropdown">
                  <p onClick={() => changeLanguage("en")}>English</p>
                  <p onClick={() => changeLanguage("tr")}>Türkçe</p>
                </div>
              )}
            </div>
          </div>
          <div className="login-footer">
            <button
              type="submit"
              disabled={isBusy}
              onClick={changePassword ? changePasswordHandler : submit}
              className="login-button"
            >
              {isBusy ? <Loading /> : changePassword ? t("login.form.forgotButton") : t("login.form.button")}
            </button>
          </div>
        </form>
      </div>
      <PageFooter />
    </div>
  );
};

export default LoginPage;
