import { utc } from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import AgreeSpentTable from "../../components/reports/AgreeSpentTable";
import BubbleChart from "../../components/reports/BubbleChart";
import HotelSpentBreadcrumb from "../../components/reports/HotelSpentBreadcrumb";
import HotelSpentTable from "../../components/reports/HotelSpentTable";
import ShareChart from "../../components/reports/ShareChart";
import SpentTable from "../../components/reports/SpentTable";
import useDataFetch from "../../hooks/useDataFetch";
import { ICorporateReportWorld } from "../../interfaces/IReport";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getUser } from "../../store/selectors/userSelectors";
import { percent } from "../../utils/helpers";

export interface ITotalExpenses {
  totals: {
    avg_agreement_price: number;
    avg_price: number;
    nights: number;
    nights_improper: number;
    total_amount: number;
    total_improper: number;
  };
  percentages: {
    [key: string]: number;
  };
}

const CompanyCompliancePage: FC = () => {
  const match = useRouteMatch<{ city: string; country: string }>();

  const { t } = useTranslation();
  const user = useSelector(getUser);

  const [currency] = useState("TRY");
  const { data, loading, fetcher } = useDataFetch<ICorporateReportWorld[]>({
    dataKey: "report",
    method: "post",
    payload: {
      city: match.params.city,
      company_code: user.companycode,
      country: match.params.country,
      currency,
      edate: utc()
        .format("YYYY-MM-DD")
        .replace(/-/g, ""),
      groupby: match.params.city ? "hotel" : match.params.country ? "city" : "country",
      sdate: utc().year() + "0101"
    },
    url: "rptcorpmainhotel"
  });

  useEffect(() => {
    fetcher({
      city: match.params.city,
      country: match.params.country,
      groupby: match.params.city ? "hotel" : match.params.country ? "city" : "country"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.city, match.params.country]);

  const parsedData = useMemo(() => {
    const output: any = {
      agreement: {
        category: t("corpReport.world.table.agree"),
        total_amount: 0
      },
      nonagreement: {
        category: t("corpReport.world.table.nonagree"),
        total_amount: 0
      }
    };
    if (match.params.city && data && !loading) {
      data.forEach(d => {
        output.agreement.total_amount += d.isAgreement ? d.total_amount : 0;
        output.nonagreement.total_amount += !d.isAgreement ? d.total_amount : 0;
      });
      return Object.values(output);
    }
    return data;
  }, [data, loading, match.params.city, t]);

  const totalExpenses = useMemo(() => {
    if (!data) {
      return;
    }

    const totals = data.reduce(
      (output, d) => {
        output.total_amount += d.total_amount;
        output.nights += d.nights;
        output.avg_agreement_price += d.avg_agreement_price;
        output.avg_price += d.avg_price;
        output.nights_improper += d.nights_improper;
        output.total_improper += d.total_improper;
        return output;
      },
      {
        avg_agreement_price: 0,
        avg_price: 0,
        nights: 0,
        nights_improper: 0,
        total_amount: 0,
        total_improper: 0
      } as any
    );

    const percentages = data.reduce((output, d) => {
      if (d.hotelName) {
        output[d.hotelName] = percent(d.total_amount, totals.total_amount);
      } else if (d.city) {
        output[d.city] = percent(d.total_amount, totals.total_amount);
      } else {
        output[d.country] = percent(d.total_amount, totals.total_amount);
      }

      return output;
    }, {} as any);
    return {
      percentages,
      totals
    };
  }, [data]) as ITotalExpenses;

  return (
    <DefaultLayout>
      <div className="container main is-scrollable">
        <div className="dashboard world">
          <section className="dashboard-title">
            <h2 className="location flex">
              <span>{t("generic.location")}:</span>
              <HotelSpentBreadcrumb params={match.params} loading={loading} />
            </h2>
            <h1 className="spent-report-title">{t("corpReport.world.title")}</h1>
          </section>
          <div className="row separator flex wrap">
            {parsedData && (
              <ShareChart
                value="total_amount"
                category={match.params.city ? "category" : match.params.country ? "city" : "country"}
                currency="TRY"
                data={parsedData}
                legend="country"
                customColor={false}
              />
            )}
            {match.params.city ? <AgreeSpentTable data={parsedData} /> : <SpentTable data={totalExpenses} />}
            <div className="report-placeholder">{parsedData && <BubbleChart data={data} />}</div>
          </div>
          <div className="row vertical">
            {data && (
              <HotelSpentTable
                currency={currency}
                data={data}
                params={match.params}
                totalExpenses={totalExpenses}
              />
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default CompanyCompliancePage;
