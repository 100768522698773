import map from "lodash-es/map";
import { Dispatch } from "redux";
import { api } from "../../services/api";
import {
  HOTEL_CLEAR,
  SERVICE_FETCH_FULFILLED,
  SERVICE_FETCH_PENDING,
  SERVICE_FETCH_REJECTED
} from "../../utils/constants";

export const fetchServices = () => async (dispatch: Dispatch) => {
  dispatch({
    type: SERVICE_FETCH_PENDING
  });
  try {
    const { data } = await api().get("services");
    dispatch({
      payload: data.services,
      type: SERVICE_FETCH_FULFILLED
    });
    dispatch({
      payload: map(data.services.hotels, "key"),
      type: HOTEL_CLEAR
    });
  } catch (e) {
    dispatch({
      type: SERVICE_FETCH_REJECTED
    });
  }
};
