import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext, useMemo, useState } from "react";
import Dates from "../Dates";
import { SearchContext } from "./Search";
import SearchTimePicker from "./SearchTimePicker";
import "react-dates/lib/css/_datepicker.css";
import { CustomDateRangePicker } from "../date/CustomDateRangePicker";
import useClickDetect from "../../hooks/useClickDetect";

interface IProps {
  index?: number;
}

const SearchDatePicker: FC<IProps> = ({ index = 0 }) => {
  const $el = useClickDetect(() => setIsOpen(false));
  const [search, setSearch] = useContext(SearchContext);
  const [isOpen, setIsOpen] = useState(false);

  const hasTime = useMemo(() => search.filters.hasCars, [search.filters.hasCars]);

  const route = useMemo(() => {
    return search.routes[index];
  }, [index, search.routes]);

  const toggleDatePicker = () => {
    setIsOpen((x) => !x);
  };

  const selectDates = (startDate: string, endDate: string) => {
    setSearch({
      fdate: startDate,
      rdate: endDate,
      routes: search.routes.map((r, i) =>
        i === index
          ? {
              ...search.routes[i],
              date: startDate,
              rdate: endDate,
            }
          : r
      ),
    });
  };

  return (
    <div className="trip-dates" ref={$el}>
      <div className="from">
        <div onClick={toggleDatePicker}>
          {route.date ? (
            <Dates className="search-date" dates={[{ date: route.date }]} />
          ) : (
            <FontAwesomeIcon icon={["fas", "calendar-alt"]} />
          )}
        </div>
        {hasTime && <SearchTimePicker propKey="ftime" />}
      </div>
      <div className="round-trip" onClick={toggleDatePicker}>
        <FontAwesomeIcon icon={["far", "long-arrow-right"]} />
      </div>
      <div className="to">
        <div onClick={toggleDatePicker}>
          {route.rdate ? (
            <Dates className="search-date" dates={[{ date: route.rdate }]} />
          ) : (
            <FontAwesomeIcon icon={["fas", "calendar-alt"]} />
          )}
        </div>
        {hasTime && <SearchTimePicker propKey="rtime" />}
      </div>
      {isOpen && (
        <CustomDateRangePicker
          startDate={route.date}
          endDate={route.rdate}
          isOpen={isOpen}
          onChange={selectDates}
        />
      )}
    </div>
  );
};

export default SearchDatePicker;
