import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pull from "lodash-es/pull";
import times from "lodash-es/times";
import React from "react";

interface IProps {
  label?: string;
  propKey: string;
  stars: number[];
  onChange(state: { [key: string]: number[] }): void;
}

const FilterStar = ({ label, propKey, stars, onChange }: IProps) => {
  const selectHandler = (star: number) => {
    if (stars.includes(star)) {
      onChange({ [propKey]: pull(stars, star) });
    } else {
      onChange({ [propKey]: [...stars, star] });
    }
  };

  return (
    <div className="filter">
      <span className="filter-title self-flex-start">{label}</span>
      <div className="stars">
        {times(5, index => (
          <span key={index} onClick={() => selectHandler(index + 1)}>
            {stars.includes(index + 1)
              ? times(index + 1, () => <FontAwesomeIcon color="#FFAD00" icon={["fas", "star"]} />)
              : times(index + 1, () => <FontAwesomeIcon icon={["far", "star"]} />)}
          </span>
        ))}
      </div>
    </div>
  );
};

export default FilterStar;
