import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import React, { FC, useCallback, useMemo, useState } from "react";
import { ICar } from "../interfaces/ICar";
import Thumbnail from "../libs/Image/Thumbnail";
import CarList from "./CarList";
import Dates from "./Dates";
import CarDetailsModal from "./modals/CarDetailsModal";

type Props = {
  car: ICar;
  cars?: ICar[];
};

const CarCard: FC<Props> = ({ car, cars }) => {
  const [detailsIsActive, setDetailsIsActive] = useState(false);

  const toggleModal = useCallback(() => {
    setDetailsIsActive(x => !x);
  }, []);

  const image = useMemo(() => {
    return get(car, "PictureUrl", "/images/default.png");
  }, [car]);

  const dates = useMemo(
    () => [
      {
        date: car.StartDate,
        direction: "0"
      },
      {
        date: car.EndDate,
        direction: "1"
      }
    ],
    [car.StartDate, car.EndDate]
  );

  return (
    <>
      {detailsIsActive && <CarDetailsModal car={car} onClose={toggleModal} />}
      <div className="hotel box is-relative car-card">
        <div className="card flex column-gutter pointer" onClick={toggleModal}>
          <div className="car-thumbnail">
            <Thumbnail width={75} height={75} rounded gap={10} layout="background" image={image} />
            <Thumbnail width={75} height={40} rounded gap={10} layout="fit" image={car.VendorLogo} />
          </div>
          <div className="info fill-space">
            <p className="name fs-prc bold">{car.ModelName}</p>
            <p className="fs-note c-gray source-note">{car.source}</p>
            <p className="address fs-std">{car.description}</p>
            <div className="car-amenities">
              {car.PassengerQty && <p className="car-amenity passenger">{car.seats}</p>}
              {car.NumberOfDoor && <p className="car-amenity door">{car.NumberOfDoor}</p>}
              {car.AirCondition && <p className="car-amenity air-condition">Air Conditioning</p>}
              {car.TransmissionType === "Manual" && <p className="car-amenity manual">Manual Transmission</p>}
              {car.TransmissionType === "Automatic" && (
                <p className="car-amenity automatic">Automatic Transmission</p>
              )}
            </div>
          </div>
          <Dates dates={dates} />
        </div>
        <div>
          <p className="address fs-std pointer" onClick={toggleModal}>
            <FontAwesomeIcon icon={["fas", "map-marker"]} /> <span className="bold">Pickup Adress:</span>{" "}
            {car.PickupAddress}
          </p>
        </div>
        {cars && <div className="rooms">{<CarList selected={car} cars={cars} />}</div>}
      </div>
    </>
  );
};

export default CarCard;
