import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash-es/isEmpty";
import React, { FC, useMemo } from "react";
import { IAmenity } from "../../interfaces/IHotel";
import { AMENITY_MAP } from "../../utils/amenities";

type Props = {
  amenity: IAmenity;
};

export const AmenityIcon: FC<Props> = ({ amenity }) => {
  const icon = useMemo(() => {
    if (!isEmpty(AMENITY_MAP[amenity.key]?.icon)) {
      return AMENITY_MAP[amenity.key].icon;
    }
  }, [amenity]);

  return (
    <>
      <span className="amenity-icon">{icon && <FontAwesomeIcon size="1x" icon={icon} color="black" />}</span>
      {amenity.title}
    </>
  );
};

export default AmenityIcon;
