import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { utc } from "moment";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../interfaces/ICustomer";
import { IFlight } from "../interfaces/IFlight";
import { IVoucherTicket } from "../interfaces/IVoucher";
import { findAirportByCode } from "../utils/airportFunctions";
import { formatDate, timeParser } from "../utils/helpers";
import FlightLogo from "./snippets/FlightLogo";

interface IProps {
  customers: ICustomer[];
  flights: IFlight[];
  tickets?: IVoucherTicket[];
  pnr: string;
}

const VoucherFlight = ({ customers, flights = [], pnr, tickets }: IProps) => {
  const { t } = useTranslation();

  const customer = useMemo(() => {
    return customers[0];
  }, [customers]);

  const prettyDate = useCallback((date: string, format = "DD MMM Y") => {
    return utc(formatDate(date)).format(format);
  }, []);

  const chainRoutes = useCallback(
    (flight: IFlight) => {
      let states = "";
      let dates = "";
      flight.airitin.origdests!.forEach((dest, index) => {
        if (!index) {
          dates += prettyDate(dest.DepDate) + " - ";
        }
        states += findAirportByCode(dest.DepartureAirport).state + " - ";
        if (flight.airitin.origdests!.length === index + 1) {
          states += findAirportByCode(dest.ArrivalAirport).state;
          dates += prettyDate(dest.ArrDate);
        }
      });
      return {
        date: dates,
        state: states
      };
    },
    [prettyDate]
  );

  return (
    <div className="airplane info">
      <div className="title">
        <img src="/images/voucher/airplane.png" alt="airline" />
        <h3>{t("generic.title.flightInfo")}</h3>
      </div>
      <div className="caption">
        {flights.map((flight, fi) => (
          <React.Fragment key={fi}>
            <p className="general">
              {chainRoutes(flight).state} / {chainRoutes(flight).date}
            </p>
            <div className="meta">
              <p>
                <span className="is-bold">{t("ticket.ticketNo")}:</span>
                <span>{customer.ticket?.tktnumber}</span>
              </p>
              <p>
                <span className="is-bold">PNR:</span> <span>{pnr}</span>
              </p>
              <p>
                <span className="is-bold">{t("pnr.passengers")}:</span>
                <span>{customer.ticket?.pax}</span>
              </p>
            </div>
            {flight.airitin.origdests!.map((dest, di) => (
              <p key={fi + di} className="flight">
                <span className="is-bold">Flight:</span>
                <span className="fs-voucher">
                  <FlightLogo className="text-image" code={dest.MarketingAirline} />
                </span>
                <span className="flight-info">
                  <u className="date-line">{dest.MarketingAirline + " " + dest.FlightNumber}</u>
                  <br />
                  {dest.MarketingAirlineName}
                  <br />
                  {dest.AirEquip}
                  <br />
                  {dest.ClassDesc}
                  <br />
                  {dest.MarketingAirline !== dest.OperatingAirline && (
                    <div className="operator">
                      <p>operating by:</p>
                      <p className="fs-voucher flex a-center">
                        <FlightLogo className="text-image" code={dest.OperatingAirline} />
                        <span>{dest.OperatingAirlineName}</span>
                      </p>
                    </div>
                  )}
                </span>
                <span className="is-bold">From:</span>
                <span className="from">
                  <u className="date-line">
                    {prettyDate(dest.DepDate, "DD MMM") + " - " + timeParser(dest.DepTime)}
                  </u>
                  <br />
                  {findAirportByCode(dest.DepartureAirport).state}
                  <br />
                  {findAirportByCode(dest.DepartureAirport).name}
                </span>
                <span className="is-bold">To:</span>
                <span className="to">
                  <u className="date-line">
                    {prettyDate(dest.ArrDate, "DD MMM") + " - " + timeParser(dest.ArrTime)}
                  </u>
                  <br />
                  {findAirportByCode(dest.ArrivalAirport).state}
                  <br />
                  {findAirportByCode(dest.ArrivalAirport).name}
                </span>
              </p>
            ))}
            <div className="voucher-airitinprice">
              {flight.airitinprice.CarryOnBaggageKilo && (
                <p>
                  <span className="flight-info-icon">
                    <FontAwesomeIcon icon={["fas", "backpack"]} />
                  </span>
                  <small>
                    {flight.airitinprice.CarryOnBaggageKilo} kg {t("flight.cabinBaggage")}
                  </small>
                </p>
              )}
              {flight.airitinprice.CheckedOnBaggageKilo && (
                <p>
                  <span className="flight-info-icon">
                    <FontAwesomeIcon icon={["fas", "luggage-cart"]} />
                  </span>
                  <small>
                    {flight.airitinprice.CheckedOnBaggageKilo} kg {t("flight.baggage")}
                  </small>
                </p>
              )}
              {flight.airitinprice.MealDesc && (
                <p>
                  <span className="flight-info-icon">
                    <FontAwesomeIcon icon={["fas", "utensils"]} />
                  </span>
                  <small>{flight.airitinprice.MealDesc}</small>
                </p>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default VoucherFlight;
