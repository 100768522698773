import React, { FC } from "react";
import { ErrorBoundary } from "../components/ErrorBoundary";
import PageFooter from "../components/PageFooter";
import Header from "../containers/Header";

interface IProps {
  hasSearch?: boolean;
}

const DefaultLayout: FC<IProps> = ({ children, hasSearch = false }) => {
  return (
    <div className="layout default-layout">
      <Header hasSearch={hasSearch} />
      <ErrorBoundary>{children}</ErrorBoundary>
      <PageFooter />
    </div>
  );
};

export default DefaultLayout;
