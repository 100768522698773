import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { ICustomer } from "../../interfaces/ICustomer";

type Props = {
  customer: ICustomer;
};

const ProgramPerson: FC<Props> = ({ customer }) => {
  return (
    <tr>
      <td className="fs-med">
        {customer.firstname} {customer.lastname}, {customer.title}
      </td>
      <td className="align-center">
        <FontAwesomeIcon icon={["far", "times"]} />
      </td>
      <td className="align-center">
        <FontAwesomeIcon icon={["far", "check"]} />
      </td>
      <td className="align-center">
        <FontAwesomeIcon icon={["far", "times"]} />
      </td>
    </tr>
  );
};
export default ProgramPerson;
