import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import map from "lodash-es/map";
import React from "react";
import { AMENITY_MAP } from "../../utils/amenities";

interface IProps {
  amenities?: {
    [key: string]: any;
  };
  only?: string[];
  small?: boolean;
}

export default function AmenityList({ amenities = {}, only, small }: IProps) {
  const cx = classNames("amenities flex wrap text", {
    "fs-small": small
  });
  return (
    <div className={cx}>
      {map(amenities, (has, amenity) => {
        if (only && !only.includes(amenity)) {
          return null;
        }
        if (!has) {
          return null;
        }
        if (!AMENITY_MAP[amenity] || !AMENITY_MAP[amenity].icon.length) {
          return null;
        }
        return (
          <FontAwesomeIcon
            key={amenity}
            data-tip={AMENITY_MAP[amenity].en}
            icon={AMENITY_MAP[amenity].icon}
            color="black"
          />
        );
      })}
    </div>
  );
}
