import toNumber from "lodash-es/toNumber";
import React, { useMemo, useState } from "react";
import Lightbox from "react-image-lightbox";

interface IProps {
  active?: number;
  images?: Array<{
    title?: string;
    type?: string;
    url: string;
  }>;
  onClose(): void;
}

export default function Gallery({ active, images = [], onClose }: IProps) {
  const [currentIndex, setCurrentIndex] = useState(toNumber(active));

  const mappedImages = useMemo(() => {
    return images.map(i => i.url);
  }, [images]);

  const currentImage = useMemo(() => {
    return images[currentIndex]!;
  }, [images, currentIndex]);

  const title = useMemo(() => {
    return currentImage.type && currentImage.title
      ? currentImage.type + " - " + currentImage.title
      : currentImage.type || currentImage.title;
  }, [currentImage]);

  const next = () => {
    setCurrentIndex((currentIndex! + 1) % mappedImages.length);
  };

  const previous = () => {
    setCurrentIndex((currentIndex! + mappedImages.length - 1) % mappedImages.length);
  };

  return (
    <Lightbox
      imageTitle={title}
      mainSrc={mappedImages[currentIndex]}
      nextSrc={mappedImages[(currentIndex + 1) % mappedImages.length]}
      prevSrc={mappedImages[(currentIndex + mappedImages.length - 1) % mappedImages.length]}
      onCloseRequest={onClose}
      onMovePrevRequest={previous}
      onMoveNextRequest={next}
      wrapperClassName="hotel-info-gallery"
    />
  );
}
