import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyHotel } from "../../../../interfaces/IPolicy";
import FormRow from "../../../../libs/Form/FormRow";
import FormSection from "../../../../libs/Form/FormSection";
import PolicyHotelRating from "./PolicyHotelRating";
import PolicyRoomCriteria from "./PolicyRoomCriteria";

interface IProps {
  policy?: IPolicyHotel;
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyHotels: FC<IProps> = ({ policy, onChange }) => {
  const { t } = useTranslation();
  return policy ? (
    <FormRow title={t("admin.policy.hotelTitle")}>
      <FormSection title={t("generic.rating")}>
        <div className="form-group">
          <PolicyHotelRating onChange={onChange} {...policy.rating} />
        </div>
      </FormSection>
      <FormSection title={t("admin.policy.roomCrit")}>
        <div className="form-group">
          <PolicyRoomCriteria onChange={onChange} {...policy.room} />
        </div>
      </FormSection>
    </FormRow>
  ) : null;
};

export default PolicyHotels;
