import debounce from "lodash-es/debounce";
import React, { ChangeEvent, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

const InlineSearchProgram: FC = () => {
  // const input = useSelector(getFiltersPnrInput);

  const { t } = useTranslation();
  const debouncer = debounce((value: string) => {
    // dispatch(changeFilterPnrInput(value));
  }, 500);

  const inputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debouncer(e.currentTarget.value);
    },
    [debouncer]
  );

  return (
    <div className="inline-search">
      <input defaultValue="" placeholder={t("search.inline")} type="text" onChange={inputHandler} />
    </div>
  );
};

export default InlineSearchProgram;
