import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getUserCompanyLogo } from "../store/selectors/userSelectors";

export const CompanyLogo = () => {
  const $img = useRef<HTMLImageElement>(null);
  const logo = useSelector(getUserCompanyLogo);

  const handler = useCallback((e: any) => {
    e.currentTarget.src = "/images/company/default.png";
  }, []);

  useEffect(() => {
    const $el = $img.current;
    $el?.addEventListener("error", handler);
    return () => $el?.removeEventListener("error", handler);
  }, [handler]);

  return <img ref={$img} src={logo} alt={logo} />;
};
