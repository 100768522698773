import { Reducer } from "redux";
import { ILog } from "../../interfaces/ILog";
import { ILogState } from "../../interfaces/IState";
import { LOG_PUSH_NEW } from "../../utils/constants";

const initialState: ILogState = {
  logs: []
};

const logReducer: Reducer<ILogState> = (state = initialState, action) => {
  switch (action.type) {
    case LOG_PUSH_NEW:
      const log: ILog = {
        active: true,
        message: action.payload.data,
        type: action.payload.status
      };
      return {
        logs: [...state.logs, log]
      };
    default:
      return state;
  }
};

export default logReducer;
