import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useDispatch } from "react-redux";
import { Busy } from "../../../components/snippets/Busy";
import { IRoom } from "../../../interfaces/IHotel";
import Modal from "../../../libs/Modal";
import Logger from "../../../services/Logger";
import { updateRoom } from "../../../store/actions/hotelActions";
import { currencyParser } from "../../../utils/helpers";

type Props = {
  canUpdate: boolean;
  room: IRoom;
  onClose(): void;
};

const UpdateRoomModal: FC<Props> = ({ canUpdate, room, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [markup, setMarkup] = useState(0);
  const [total, setTotal] = useState(room.AmountAfterTax);
  const [isBusy, setIsBusy] = useState(false);

  const markupHandler = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      setMarkup(floatValue!);
      setTotal(room.AmountAfterTax + (floatValue || 0));
    },
    [room.AmountAfterTax]
  );

  const totalHandler = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      setTotal(floatValue!);
      setMarkup(floatValue! - room.AmountAfterTax);
    },
    [room.AmountAfterTax]
  );

  const updateHandler = useCallback(async () => {
    const log = new Logger("Updating price...");
    setIsBusy(true);
    if (!canUpdate) {
      return;
    }
    try {
      await dispatch(updateRoom(room.OfferId, markup, total));
      log.success("Price has been updated.");
    } catch {
      log.error("There was an error while updating price.");
    }
    setIsBusy(false);
  }, [canUpdate, dispatch, room.OfferId, markup, total]);

  return (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>{t("generic.changePrice")}</h1>
      </Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        <div className="flex d-column edit-room">
          <label>
            {t("generic.markup")}:
            <br />
            <NumberFormat
              thousandSeparator={true}
              prefix={currencyParser(room.HotelCurrency)}
              value={markup}
              onValueChange={markupHandler}
            />
          </label>
          <br />
          <label>
            {t("generic.totalAmount")}:
            <br />
            <NumberFormat
              thousandSeparator={true}
              prefix={currencyParser(room.HotelCurrency)}
              value={total}
              onValueChange={totalHandler}
            />
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="submit modal-right-button" onClick={updateHandler}>
          <FontAwesomeIcon icon={["fas", "check-circle"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default UpdateRoomModal;
