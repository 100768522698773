import React from "react";
import { ICar } from "../../interfaces/ICar";
import { pwc } from "../../utils/helpers";

interface IProps {
  car: ICar;
  deleteItem(OfferId: string): void;
}
function BasketCar({ car, deleteItem }: IProps) {
  const deleteItemHandler = () => deleteItem(car.OfferId);

  return (
    <div className="box other no-padding-right flex">
      <div className="description fill-space flex d-column j-even">
        <p className="fs-med">{car.ModelName}</p>
        <p className="fs-med">{car.VehicleCategory}</p>
      </div>
      <div className="right-side flex d-column">
        <button className="remove button cart self-end gutter-bottom left-radius" onClick={deleteItemHandler}>
          X
        </button>

        <div className="fill-space" />

        <p className="price-tag fs-prc is-centered left-radius">{pwc(car.Amount, car.Currency)}</p>
      </div>
    </div>
  );
}

export default BasketCar;
