import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import map from "lodash-es/map";
import pick from "lodash-es/pick";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Scrollbar from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { batch, connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import BasketActivity from "../components/basket/BasketActivity";
import BasketCar from "../components/basket/BasketCar";
import BasketFlight from "../components/basket/BasketFlight";
import BasketOther from "../components/basket/BasketOther";
import BasketRoom from "../components/basket/BasketRoom";
import OtherOffers from "../components/basket/OtherOffers";
import ButtonWithConfirm from "../components/confirmation/ButtonWithConfirm";
import { Busy } from "../components/snippets/Busy";
import { IActivity } from "../interfaces/IActivity";
import { IBasketFlight, IVisa } from "../interfaces/IBasket";
import { IBookingOptions, ICheckoutData, IPaymentRequest } from "../interfaces/IBooking";
import { ICar } from "../interfaces/ICar";
import { IFlight } from "../interfaces/IFlight";
import { IRoom } from "../interfaces/IHotel";
import { IBasketState, IBookingStatus, IStoreState } from "../interfaces/IState";
import { ITransfer } from "../interfaces/ITransfer";
import {
  ActionTypes,
  addItemToBasket,
  changeCurrency,
  changePaymentMethod,
  deleteItem,
  fetchBasket,
  ItemTypes
} from "../store/actions/basketActions";
import { pay, resetBooking } from "../store/actions/bookingActions";
import {
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleBasket,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { getBasketItems, getBasketPayableItems, getBasketState } from "../store/selectors/basketSelectors";
import { getBookingStatus } from "../store/selectors/bookingSelectors";
import { getCustomersValidForBasket } from "../store/selectors/customerSelectors";
import { getBasketIsActive } from "../store/selectors/uiSelectors";
import { pwc } from "../utils/helpers";

interface IDispatchProps {
  actions: {
    addItemToBasket(item: ItemTypes, action: ActionTypes): any;
    changeCurrency(currency: string): void;
    changePaymentMethod(checked: boolean): void;
    deleteItem(OfferId: string): any;
    fetchBasket(): void;
    toggleBasket(switcher?: boolean): void;
    pay(requestData: IPaymentRequest, paymentMethod: IBookingOptions): void;
    resetBooking(): void;
  };
}

interface IStateProps {
  basketState: IBasketState;
  bookingStatus: IBookingStatus;
  isActive: boolean;
  items: {
    activities: IActivity[];
    cars: ICar[];
    orderedFlightsHotels: {
      [key: string]: IBasketFlight | IRoom;
    };
    others: IVisa[];
  };
  payableItems: Array<IFlight | IRoom | ITransfer | IVisa | IActivity>;
}

interface IProps extends IDispatchProps, IStateProps {}

const Basket: FC<IProps> = ({ actions, basketState: { basket, status }, isActive, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const customersValidForBasket = useSelector(getCustomersValidForBasket);

  const [isBusy, setIsBusy] = useState(false);
  const [specialOffersIsActive, setSpecialOffersIsActive] = useState(false);

  const basketReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      actions.fetchBasket();
    }
  }, [actions, isActive]);

  useEffect(() => {
    if (basketReference.current) {
      const headerElement = document.getElementById("header")!;
      basketReference.current.style.top = headerElement.clientHeight + 14 + "px";
    }
  }, [isActive]);

  const changeCurrencyHandler = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      actions.changeCurrency(e.currentTarget.value);
    },
    [actions]
  );

  const deleteItemHandler = useCallback(
    (OfferId: string) => {
      setIsBusy(true);
      actions
        .deleteItem(OfferId)
        .then(() => setIsBusy(false))
        .catch(() => setIsBusy(false));
    },
    [actions]
  );

  const renderItems = useMemo(() => {
    const { activities, cars, orderedFlightsHotels, others } = props.items;

    const orderedItems = map(orderedFlightsHotels, (item: any, key) => {
      if (key.indexOf("flight") > -1) {
        return <BasketFlight key={key} flight={item as IBasketFlight} deleteItem={deleteItemHandler} />;
      }
      return <BasketRoom key={key} room={item as IRoom} deleteItem={deleteItemHandler} />;
    });
    const carItems = cars.map(c => <BasketCar car={c} deleteItem={deleteItemHandler} key={c.OfferId} />);
    const activityItems = activities.map(a => (
      <BasketActivity activity={a} deleteItem={deleteItemHandler} key={a.OfferId} />
    ));
    const otherItems = others.map(other => (
      <BasketOther item={other} deleteItem={deleteItemHandler} key={other.offer_guid} />
    ));
    return [...orderedItems, ...activityItems, ...carItems, ...otherItems];
  }, [deleteItemHandler, props.items]);

  const checkoutData: ICheckoutData | undefined = useMemo(
    () => ({
      actionType: "basket",
      amount: basket.total,
      currency: basket.currency,
      guid: basket.basketguid,
      isInternational: true,
      items: pick(basket, ["cars", "flights", "hotels", "transfers", "visas", "others"])
    }),
    [basket]
  );

  return (
    <div ref={basketReference} className={`basket${isActive ? " basket-is-active" : ""}`}>
      <div className="basket-content">
        {isBusy || (!status.fetched && <Busy />)}

        <div className="basket-header">
          <div className="box-title has-padding flex j-between a-center">
            <span className="result-header-icon c-primary">
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
            </span>
            <h1 className="text fs-lrg fill-space c-primary">{t("basket.title")}</h1>
            <button className="button is-large" onClick={() => actions.toggleBasket()}>
              <FontAwesomeIcon icon={["fas", "chevron-left"]} />
            </button>
          </div>
          <div className="actions">
            <button className="refresh" onClick={actions.fetchBasket}>
              <FontAwesomeIcon spin={status.fetching} icon={["far", "sync"]} />
            </button>
            <button className="add-cust">
              <FontAwesomeIcon icon={["far", "user-plus"]} />
            </button>
            <button className="note" onClick={() => setSpecialOffersIsActive(!specialOffersIsActive)}>
              <FontAwesomeIcon icon={["far", "edit"]} />
            </button>
            <select className="currency-select" defaultValue="TRY" onChange={changeCurrencyHandler}>
              <option value="TRY">TRY</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
            </select>
          </div>
        </div>
        <div className="basket-items">
          <Scrollbar>{renderItems}</Scrollbar>
        </div>
        {basket.total && (
          <div className="basket-footer">
            <div className="basket-total">
              <p>
                <span>{t("generic.total")}:</span>
                <span className="value">{pwc(basket.total, basket.currency)}</span>
              </p>
            </div>
            <div className="basket-confirm">
              <ButtonWithConfirm
                className="confirm"
                actions={[
                  {
                    byPassAction: true,
                    color: "success",
                    handler() {
                      dispatch(setCheckoutScreenData(checkoutData, true));
                    },
                    text: "Proceed"
                  },
                  {
                    color: "success",
                    handler() {
                      batch(() => {
                        dispatch(setCheckoutScreenData(checkoutData));
                        dispatch(toggleCustomerScreen());
                        dispatch(setCheckoutIsPending(true));
                      });
                    },
                    text: "Check Customers"
                  }
                ]}
                message={t("customer.notValidBooking")}
                byPass={customersValidForBasket}
              >
                <FontAwesomeIcon icon={["far", "chevron-right"]} />
              </ButtonWithConfirm>
            </div>
          </div>
        )}
      </div>
      <div>
        {basket.basketguid && isActive && (
          <OtherOffers addToBasket={actions.addItemToBasket} basketGuid={basket.basketguid} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IStoreState): IStateProps => ({
  basketState: getBasketState(state),
  bookingStatus: getBookingStatus(state),
  isActive: getBasketIsActive(state),
  items: getBasketItems(state),
  payableItems: getBasketPayableItems(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      addItemToBasket,
      changeCurrency,
      changePaymentMethod,
      deleteItem,
      fetchBasket,
      pay,
      resetBooking,
      toggleBasket
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
