import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InlineSearchProgram from "../components/inlinesearches/InlineSearchProgram";
import Loading from "../components/Loading";
import ProgramDetails from "../components/program/ProgramDetails";
import ProgramListItem from "../components/program/ProgramListItem";
import { Busy } from "../components/snippets/Busy";
import { IProgram } from "../interfaces/IProgram";
import DefaultLayout from "../layouts/DefaultLayout";
import { fetchPrograms } from "../store/actions/programAction";
import { getPrograms, getProgramStatus } from "../store/selectors/programSelector";
import { Merge } from "../types";

interface IProps {}

const ProgramModulePage: FC<IProps> = () => {
  const dispatch = useDispatch();
  const programs = useSelector(getPrograms);

  const status = useSelector(getProgramStatus);

  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(1);

  const selected = useMemo(() => programs.find(p => p.selected), [programs]);

  const tabHandler = useCallback(
    (index: 1 | 2) => () => {
      setTabIndex(index);
    },
    []
  );

  const fetchProgramsHandler = useCallback(async () => {
    await dispatch(fetchPrograms());
  }, [dispatch]);

  useEffect(() => {
    fetchProgramsHandler();
  }, [fetchProgramsHandler]);

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: IProgram[] }>> = ({ index, data, style }) => (
    <ProgramListItem program={data[index]} style={style} />
  );

  return (
    <DefaultLayout>
      <div className="container main flex j-center">
        <div className={`box-container full-height${tabIndex !== 1 && " is-mobile-hidden"}`}>
          {status.fetching && <Busy />}
          <div className="title flex j-between a-center is-relative c-primary">
            <span className="pnr-header-icon is-tablet-only">
              <FontAwesomeIcon icon={["far", "bars"]} />
            </span>
            <h1 className="text fs-lrg fill-space c-primary bold is-tablet-only">{t("program.title")}</h1>
            <div className="quick-filter">
              {/*<button*/}
              {/*  className={`quick-filter-button${filters.items.flight ? " active" : ""}`}*/}
              {/*  onClick={filterItemsHandler("flight")}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={["fas", "plane"]} />*/}
              {/*</button>*/}
            </div>
            <InlineSearchProgram />
          </div>
          {status.fetching ? (
            <Loading />
          ) : (
            programs.length > 0 && (
              <FixedSizeList
                className="pnr-list"
                itemData={programs}
                itemSize={170}
                height={1000}
                itemCount={programs.length}
                width="100%"
              >
                {rowRenderer}
              </FixedSizeList>
            )
          )}
        </div>
        <div className={`box-container full-height${tabIndex !== 2 && " is-mobile-hidden"}`}>
          <ProgramDetails program={selected} />
        </div>
      </div>
      <div className="is-mobile-only mobile-tabs">
        <button className={tabIndex === 1 && ("is-active" as any)} onClick={tabHandler(1)}>
          Pnr List
        </button>
        <button className={tabIndex === 2 && ("is-active" as any)} onClick={tabHandler(2)}>
          Details
        </button>
      </div>
    </DefaultLayout>
  );
};

export default ProgramModulePage;
