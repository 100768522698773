import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompanyLogo } from "../components/CompanyLogo";
import { Busy } from "../components/snippets/Busy";
import useClickDetect from "../hooks/useClickDetect";
import { changeUserCompany } from "../store/actions/userActions";
import { getUserCompanyList } from "../store/selectors/userSelectors";

type Props = {};

const CompanyChanger: FC<Props> = () => {
  const $container = useClickDetect(() => setIsActive(false));
  const dispatch = useDispatch();
  const companies = useSelector(getUserCompanyList);

  const [isActive, setIsActive] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsActive(x => !x);
  }, []);

  const selectHandler = useCallback(
    (code: string) => async () => {
      if (!isBusy) {
        setIsBusy(true);
        setIsActive(false);
        await dispatch(changeUserCompany(code));
        setIsBusy(false);
        window.location.reload();
      }
    },
    [dispatch, isBusy]
  );

  const cx = classNames("company-logo", {
    pointer: companies.length
  });

  return companies.length > 0 ? (
    <div className="company-logo-changer" ref={$container}>
      {isBusy && <Busy />}
      <div className={cx} onClick={toggleMenu}>
        <CompanyLogo />
        {companies.length > 0 && <FontAwesomeIcon icon={["far", "chevron-down"]} />}
      </div>
      {isActive && (
        <div className="company-list">
          {companies.map(c => (
            <div className="text fs-lrg" key={c.companyCode} onClick={selectHandler(c.companyCode)}>
              {c.companyName}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default CompanyChanger;
