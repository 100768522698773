import React from "react";
import Modal from "../../libs/Modal";
import DetailsHotelInfos from "../../modules/hotelDetailsScreen/components/DetailsHotelInfos";

interface IProps {
  active: boolean;
  HotelCode: string;
  source: string;
  onClose(): void;
}

export default function HotelDetailsModal({ active, onClose, ...p }: IProps) {
  return active ? (
    <Modal.Container onClose={onClose} colorScheme="gray" closeOnOutsideClick={false}>
      <Modal.Body>
        <DetailsHotelInfos {...(p as any)} />
      </Modal.Body>
    </Modal.Container>
  ) : null;
}
