import classNames from "classnames";
import castArray from "lodash-es/castArray";
import React, { CSSProperties, useMemo } from "react";
import { IHotelImage } from "../../interfaces/IHotel";

interface IProps {
  className?: string;
  column?: number;
  gap?: number;
  height?: number | "auto";
  image?: string | IHotelImage | IHotelImage[];
  layout?: "normal" | "background" | "fit";
  rounded?: boolean;
  width?: number | "auto";

  onClick?(): void;
}

export default function Thumbnail({
  className = "",
  column,
  gap,
  height,
  image = [],
  layout = "normal",
  rounded,
  width,
  onClick
}: IProps) {
  let src = "/images/tbholder.jpg";

  if (image.constructor === String) {
    src = image as string;
  } else {
    const arrayed = castArray(image as IHotelImage | IHotelImage[]);
    if (arrayed.length > 0) {
      src = arrayed[0].smallUrl || "/images/tbholder.jpg";
    }
  }
  const cx = classNames("thumbnail", className, {
    pointer: !!onClick
  });

  const cxImg = useMemo(() => {
    if (layout === "fit") {
      return {
        height: "auto",
        maxHeight: height,
        maxWidth: width,
        width: "auto"
      } as CSSProperties;
    }
  }, [height, layout, width]);

  return (
    <div className={cx} style={style(height, width, column, rounded, gap)} onClick={onClick}>
      {layout === "normal" || layout === "fit" ? (
        <img src={src} alt="thumbnail" style={cxImg} />
      ) : (
        <div style={{ backgroundImage: `url(${src})` }} />
      )}
    </div>
  );
}

const style = (
  height?: number | "auto",
  width?: number | "auto",
  column?: number,
  rounded = false,
  gap = 0
) => {
  const s: CSSProperties = {
    height: "auto",
    width: "auto"
  };

  if (height) {
    s.height = height;
  }

  if (width) {
    s.width = width;
  }

  if (column) {
    s.width = `calc(100% / ${column} - ${gap}px)`;
  }

  if (rounded) {
    s.borderRadius = height && height !== "auto" ? (height as number) / 10 : 5;
  }

  if (gap) {
    s.marginRight = gap;
    s.marginBottom = gap;
  }
  return s;
};
