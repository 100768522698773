import debounce from "lodash-es/debounce";
import includes from "lodash-es/includes";
import keys from "lodash-es/keys";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  items: any[];
  searchableKeys: string[];
  searchType?: "includes" | "startsWith";

  onSearch(filteredItems: any): void;
}

const InlineSearch: FC<IProps> = ({ items, searchableKeys, searchType, onSearch }) => {
  const { t } = useTranslation();

  const debouncedFilter = useCallback(
    debounce((keywords: string[]) => {
      onSearch(
        items.filter((item: any) =>
          keywords.every(word =>
            keys(item).some((param: string) => {
              if (item[param] && includes(searchableKeys, param)) {
                return searchType === "startsWith"
                  ? startsWith(toLower(item[param]), toLower(word))
                  : includes(toLower(item[param]), toLower(word));
              }
              return false;
            })
          )
        )
      );
    }, 250),
    [items]
  );

  const filterhandler = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const keywords = e.currentTarget.value.split(" ");
      debouncedFilter(keywords);
    },
    [debouncedFilter]
  );

  return (
    <div className="inline-search">
      <input placeholder={t("search.inline")} type="text" onChange={filterhandler} />
    </div>
  );
};

export default InlineSearch;
