import { useState, useEffect } from "react";
import { api } from "../services/api";
import moment from "moment";
import { AxiosError } from "axios";

type UseCachedFetchProps<T> = {
  key: string;
  uri: string;
  method?: "get" | "post";
  timeInHours?: number;
  transform?: (data: T) => T;
};

function getCacheFromStorage(key: string) {
  const cache: Record<string, { time: string; value: any }> = JSON.parse(
    localStorage.getItem("cache") ?? "{}"
  );

  return cache[key];
}

function saveDateToCacheStorage(key: string, data: { time: string; value: any }) {
  const cache: Record<string, any> = JSON.parse(localStorage.getItem("cache") ?? "{}");
  cache[key] = data;
  localStorage.setItem("cache", JSON.stringify(cache));
}

export const useCachedFetch = <T>({
  key,
  uri,
  method = "get",
  timeInHours = 1,
  transform
}: UseCachedFetchProps<T>) => {
  const [data, setData] = useState(() => getCacheFromStorage(key));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api()[method as "get"]<T>(uri);
      const duration = moment()
        .add(timeInHours, "hours")
        .toISOString(true);
      if (transform) {
        setData({ time: duration, value: transform(response.data) });
      } else {
        setData({ time: duration, value: response.data });
      }
    } catch (e) {
      setError(JSON.stringify((e as AxiosError).response?.data));
    }

    setLoading(false);
  };

  useEffect(() => {
    saveDateToCacheStorage(key, data);
  }, [key, data]);

  useEffect(() => {
    if (!data?.value || moment().isAfter(data.time)) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data: (data?.value as T) ?? null, loading, error, fetchData };
};
