import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ActivityMap from "../../containers/ActivityMap";
import { ICar } from "../../interfaces/ICar";
import Modal from "../../libs/Modal";
import { pwc } from "../../utils/helpers";

interface IProps {
  car: ICar;
  onClose(): void;
}

const CarDetailsModal: FC<IProps> = ({ car, onClose }) => {
  const { t } = useTranslation();
  useEffect(() => {
    const $el = document.querySelectorAll(".result-list") as NodeListOf<HTMLElement>;
    $el.forEach(el => (el.style.willChange = "unset"));
    return () => {
      $el.forEach(el => (el.style.willChange = "transform"));
    };
  }, []);

  return (
    <Modal.Container
      onClose={onClose}
      width={1000}
      padding={false}
      className={!car.PickupGeo ? "no-geo" : ""}
    >
      <Modal.Body className="flex overflow-hidden">
        <div style={{ minWidth: 500 }}>
          <div className="car-details-cover">
            {car.PictureUrl && (
              <img className="car-details-picture" src={car.PictureUrl} alt={car.ModelName} />
            )}
            <img className="car-details-vendor" src={car.VendorLogo} alt={car.Vendor} />
          </div>
          <div className="activity-description">
            <div className="flex j-between">
              <h3 className="activity-modal-title">
                {car.ModelName}
                <br />
                Category: {car.VehicleCategory}
              </h3>
              <p className="price-tag fs-prc is-centered">{pwc(car.Amount, car.Currency, false)}</p>
            </div>
            <div className="car-description-group">
              <div className="car-amenities">
                {car.PassengerQty && <p className="car-amenity passenger">{car.seats}</p>}
                {car.NumberOfDoor && <p className="car-amenity door">{car.NumberOfDoor}</p>}
                {car.AirCondition && <p className="car-amenity air-condition">Air Conditioning</p>}
                {car.TransmissionType === "Manual" && (
                  <p className="car-amenity manual">Manual Transmission</p>
                )}
                {car.TransmissionType === "Automatic" && (
                  <p className="car-amenity automatic">Automatic Transmission</p>
                )}
              </div>
              <p className="fs-med">{car.description}</p>
              <div>
                <p className="fs-med">
                  <span className="bold">{t("voucher.car.pickupAddress")}: </span>
                  {car.PickupAddress}
                </p>
                <p className="fs-med">
                  <span className="bold">{t("voucher.car.dropoffAddress")}: </span>
                  {car.DropoffAddress}
                </p>
              </div>
            </div>
          </div>
        </div>
        {car.PickupGeo && (
          <div className="activity-map-container">
            <ActivityMap marker={{ lat: car.PickupGeo.latitude, lng: car.PickupGeo.longitude }} />
          </div>
        )}
      </Modal.Body>
    </Modal.Container>
  );
};

export default CarDetailsModal;
