import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash-es/isEmpty";
import React, { FC } from "react";
import { ISearchDestination } from "../../../components/search/HotelPicker";
import { IAirport } from "../../../interfaces/IAirport";

type Props = {
  destinations: {
    airports: IAirport[];
    locations: ISearchDestination[];
  };
  selectHandler(type: "airport" | "location", payload: any): void;
};

export const TransportLocationPicker: FC<Props> = ({ destinations, selectHandler }) => {
  const hasResult = !isEmpty(destinations.airports) || !isEmpty(destinations.locations);
  return (
    <div className={`formik-location-picker${hasResult ? "" : " hidden"}`}>
      <ul>
        {!isEmpty(destinations.airports) && (
          <>
            <li className="location-picker-title">
              <FontAwesomeIcon icon={["fas", "plane"]} /> Airports
            </li>
            {destinations.airports.map(airport => (
              <li key={airport.name} onClick={() => selectHandler("airport", airport)}>
                {airport.name} / {airport.city} ({airport.code})
              </li>
            ))}
          </>
        )}
        {!isEmpty(destinations.locations) && (
          <>
            <li className="location-picker-title">
              <FontAwesomeIcon icon={["fas", "map-marker"]} /> Locations
            </li>
            {destinations.locations.map(location => (
              <li key={location.name} onClick={() => selectHandler("location", location)}>
                {location.name}
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};
