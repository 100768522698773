import React from "react";
import { useDispatch } from "react-redux";
import { toggleCheckoutScreen } from "../store/actions/uiActions";

const MiniModal = () => {
  const dispatch = useDispatch();
  return (
    <div className="modal-wrapper flex j-center a-center">
      <div className="confirm-box bordered">
        <p>Search results is not valid anymore. New search is required.</p>
        <button className="cancel" onClick={() => dispatch(toggleCheckoutScreen(false))}>
          Return to Mainscreen
        </button>
      </div>
    </div>
  );
};

export default MiniModal;
