import isUndefined from "lodash-es/isUndefined";
import { Reducer } from "redux";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import { IAccountingState } from "../../interfaces/IState";
import {
  ACCOUNTING_FETCH_EMPTY,
  ACCOUNTING_FETCH_FULFILLED,
  ACCOUNTING_FETCH_PENDING,
  ACCOUNTING_FETCH_REJECTED,
  ACCOUNTING_FILTERED_REPORTS_TOGGLE,
  ACCOUNTING_REPORT_SELECT,
  ACCOUNTING_SELECT_ALL_FILTERED_REPORTS,
  ACCOUNTING_SET_FILTER,
  ACCOUNTING_SET_ORDER,
  ACCOUNTING_UPDATE_REPORT
} from "../../utils/constants";

const initialState: IAccountingState = {
  fields: {},
  filters: {
    isCollected: false,
    isCostInvoiced: false,
    isInvoiced: false,
    quickSearch: "",
    searchable: [
      "paymentKey",
      "pnr",
      "passengers",
      "route",
      "start_date",
      "end_date",
      "operator",
      "user_name",
      "source",
      "customer",
      "action_date",
      "marketer",
      "invoice_ref",
      "collection_ref"
    ]
  },
  orders: {},
  reports: [],
  selected: [],
  status: {
    fetched: false,
    fetching: false
  }
};

const accountingReducer: Reducer<IAccountingState> = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNTING_FETCH_PENDING:
      return {
        ...state,
        reports: [],
        status: {
          fetched: false,
          fetching: true
        }
      };
    case ACCOUNTING_FETCH_EMPTY:
      return {
        ...state,
        reports: [],
        status: {
          fetched: true,
          fetching: false
        }
      };
    case ACCOUNTING_FETCH_FULFILLED:
      return {
        ...state,
        fields: action.payload.fields,
        reports: action.payload.report,
        status: {
          fetched: true,
          fetching: false
        }
      };
    case ACCOUNTING_FETCH_REJECTED:
      return {
        ...state,
        reports: [],
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
    case ACCOUNTING_SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: !isUndefined(action.input) ? action.input : !state.filters[action.field]
        }
      };
    case ACCOUNTING_SET_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.payload]: isUndefined(state.orders[action.payload])
            ? "asc"
            : state.orders[action.payload] === "asc"
            ? "desc"
            : undefined
        }
      };
    case ACCOUNTING_REPORT_SELECT:
      return {
        ...state,
        selected: state.selected.includes(action.payload)
          ? state.selected.filter(k => k !== action.payload)
          : state.selected.concat(action.payload)
      };
    case ACCOUNTING_UPDATE_REPORT:
      return {
        ...state,
        reports: state.reports.map(report => {
          if (report.paymentKey === action.payload.paymentKey) {
            return {
              ...report,
              costinvoice_ref: action.payload.costInvoiceRef
            };
          }
          return report;
        })
      };
    case ACCOUNTING_FILTERED_REPORTS_TOGGLE:
      return {
        ...state,
        selected: action.payload ? action.payload.map((r: IAccountingPayment) => r.paymentKey) : []
      };
    case ACCOUNTING_SELECT_ALL_FILTERED_REPORTS:
      return {
        ...state,
        selected:
          state.selected.length === action.payload.length
            ? []
            : action.payload.map((r: IAccountingPayment) => r.paymentKey)
      };
    default:
      return state;
  }
};

export default accountingReducer;
