import get from "lodash-es/get";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IBookingOptions, IPaymentRequest } from "../../interfaces/IBooking";
import { pay, resetBooking } from "../../store/actions/bookingActions";
import { provisionRoom } from "../../store/actions/provisionActions";
import { getBookingStatus } from "../../store/selectors/bookingSelectors";
import { getActiveCustomers } from "../../store/selectors/customerSelectors";
import { getCheckoutData, getCheckoutScreenIsActive } from "../../store/selectors/uiSelectors";
import { getUser } from "../../store/selectors/userSelectors";
import BookingDetails from "./BookingDetails";
import Payment from "./Payment";

interface IProps {}

const CheckoutStandAlone: FC<IProps> = () => {
  const dispatch = useDispatch();

  const checkoutScreenIsActive = useSelector(getCheckoutScreenIsActive);
  const bookingStatus = useSelector(getBookingStatus);
  const customers = useSelector(getActiveCustomers);
  const data = useSelector(getCheckoutData);
  const user = useSelector(getUser);

  const [booking, setBooking] = useState<any[]>([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (data?.items?.hotels?.length) {
      data.items.hotels.forEach(h => dispatch(provisionRoom(h)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStep = useCallback((payload?: any) => {
    if (payload) {
      setBooking(x => [...x, payload]);
    }
    setStep(x => x + 1);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetBooking());
    };
  }, [dispatch, checkoutScreenIsActive]);

  const isAgreement = useMemo(() => {
    return get(data, "items.hotels[0].source", "") === "AGREEMENT";
  }, [data]);

  const payHandler = useCallback(
    (requestData: IPaymentRequest, options: IBookingOptions) => {
      dispatch(pay(requestData, options));
    },
    [dispatch]
  );

  return checkoutScreenIsActive && data ? (
    <div className="checkout-container">
      {data.items.hotels &&
        data.items.hotels.length > 0 &&
        isAgreement &&
        data.items.hotels.map(
          (room, index) =>
            step === index && (
              <BookingDetails customers={customers} room={room} key={room.OfferId} next={nextStep} />
            )
        )}
      {(!isAgreement || !data.items.hotels || step >= data.items.hotels.length) && (
        <Payment
          actionType={data.actionType}
          amount={data.amount}
          bookingDetails={booking}
          bookingStatus={bookingStatus}
          currency={data.currency}
          customers={customers}
          guid={data.guid}
          isInternational={data.isInternational}
          isBasket={!!data.isBasket}
          items={data.items}
          pay={payHandler}
          user={user}
        />
      )}
    </div>
  ) : null;
};

export default CheckoutStandAlone;
