import i18n from "i18next";
import map from "lodash-es/map";
import { Dispatch } from "redux";
import { IHotelInfo, IRoom } from "../../interfaces/IHotel";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC, GetState } from "../../types";
import {
  HOTEL_AMADEUS_CLEAR,
  HOTEL_CLEAR,
  HOTEL_DETAILS_BATCH_FETCH,
  HOTEL_DETAILS_SINGLE_FETCH,
  HOTEL_FETCH_EMPTY,
  HOTEL_FETCH_FULFILLED,
  HOTEL_FETCH_PENDING,
  HOTEL_FETCH_REJECTED,
  HOTEL_ROOM_CHANGE,
  HOTEL_ROOM_FETCH_EMPTY,
  HOTEL_ROOM_FETCH_FULFILLED,
  HOTEL_ROOM_SELECT
} from "../../utils/constants";
import { getLastSearch } from "../selectors/searchSelectors";
import { getHotelServices } from "../selectors/serviceSelectors";
import { filterReset, setFilterDefaults } from "./filterActions";

export const fetchHotelDetails: AC = (hotelCode: string, source: string) => async dispatch => {
  try {
    const { data } = await api().post<IHotelInfo>("gethoteldetail", {
      hotelCode,
      lang: i18n.language,
      source
    });
    if (data) {
      dispatch({
        payload: data,
        service: source,
        type: `${source}_${HOTEL_DETAILS_SINGLE_FETCH}`
      });
    }
  } catch {}
};

export const fetchHotels = (validServices?: string[]) => (dispatch: Dispatch, getState: GetState) => {
  dispatch<any>(filterReset());

  const state = getState();
  const search = getLastSearch(state);
  const services = getHotelServices(state);

  for (const service of services) {
    if (validServices && !validServices.includes(service.key)) {
      continue;
    }
    const config: any = service.fullLink ? { baseUrl: "" } : {};
    const log = new Logger(i18n.t("hotel.searchPending"), {
      prefix: service.key
    });
    dispatch({
      service: service.key,
      type: `${service.key}_${HOTEL_FETCH_PENDING}`
    });

    api()
      .post(service.uri, search, config)
      .then(({ data }) => {
        if (data.hotels && data.hotels.length > 0) {
          log.success(i18n.t("hotel.searchSuccess"));
          dispatch({
            payload: data.hotels,
            service: service.key,
            type: `${service.key}_${HOTEL_FETCH_FULFILLED}`
          });
          if (data.hoteldetails && data.hoteldetails.length) {
            dispatch({
              payload: data.hoteldetails,
              service: service.key,
              type: `${service.key}_${HOTEL_DETAILS_BATCH_FETCH}`
            });
          }
          dispatch<any>(setFilterDefaults());
        } else {
          log.warning(i18n.t("hotel.searchEmpty"));
          dispatch({
            service: service.key,
            type: `${service.key}_${HOTEL_FETCH_EMPTY}`
          });
        }
      })
      .catch(e => {
        log.error(e);
        dispatch({
          service: service.key,
          type: `${service.key}_${HOTEL_FETCH_REJECTED}`
        });
      });
  }
};

export const fetchRooms = (hotelCode: string, source: string) => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const search = getLastSearch(state);
  const roomsFetched = state.hotels.some(hs =>
    hs.hotels.some(h => h.HotelCode === hotelCode && h.roomsFetched)
  );
  if (!roomsFetched) {
    api()
      .post<IRoom[]>("gethotelrooms", {
        ...search,
        hotel: [
          {
            code: hotelCode,
            source: [source]
          }
        ]
      })
      .then(({ data }) => {
        if (data && data.length > 0) {
          dispatch({
            payload: {
              hotelCode,
              rooms: data,
              source
            },
            type: HOTEL_ROOM_FETCH_FULFILLED
          });
        } else {
          dispatch({
            payload: {
              hotelCode,
              source
            },
            type: HOTEL_ROOM_FETCH_EMPTY
          });
        }
      })
      .catch(() => {
        dispatch({
          payload: {
            hotelCode,
            source
          },
          type: HOTEL_ROOM_FETCH_EMPTY
        });
      });
  }
};
export const updateRoom: AC = (
  offerid: string,
  markupAmount: number,
  totalAmount: number
) => async dispatch => {
  try {
    const { data } = await api().post("offerchange", {
      action: "hotel",
      markupAmount,
      offerid,
      totalAmount
    });
    if (data && data !== "null") {
      dispatch({
        payload: data,
        type: HOTEL_ROOM_CHANGE
      });
    } else {
      fail();
    }
  } catch (e) {
    fail(e);
  }
};

const fail = (message?: string) => {
  throw new Error(message);
};

export const selectRoom: AC = (room: IRoom) => dispatch => {
  dispatch({
    payload: room,
    type: HOTEL_ROOM_SELECT
  });
};

export const clearHotels: AC = () => (dispatch, getState) => {
  const services = getHotelServices(getState());
  return dispatch({
    payload: map(services, "key"),
    type: HOTEL_CLEAR
  });
};

export const clearAmadeusHotels = () => (dispatch: Dispatch) => {
  dispatch({
    type: HOTEL_AMADEUS_CLEAR
  });
};
