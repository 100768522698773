import React, { FC } from "react";
import { Redirect } from "react-router";
import { IRouter } from "../../routes/routeList";

interface IProps {
  subroutes: IRouter[];
}

const AdminHomePage: FC<IProps> = () => {
  return <Redirect to="/admin/hotels" />;
};

export default AdminHomePage;
