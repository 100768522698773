import React from "react";
import { airlineLogo } from "../../utils/helpers";

interface IFlightLogoComponentProps {
  className?: string;
  code: string;
  alt?: string;
}

export default function FlightLogo({ alt, className, code }: IFlightLogoComponentProps) {
  const classes = className ? className : "";
  const src = airlineLogo(code);
  return <img src={src} className={classes} alt={alt} />;
}
