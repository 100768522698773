import React, { FC } from "react";
import { ICustomer } from "../../interfaces/ICustomer";

interface IProps {
  customers: ICustomer[];
}

const CustomerList: FC<IProps> = ({ customers }) => {
  return (
    <p className="c-primary customer-names">
      {customers
        .filter(c => c.selected)
        .map(c => `${c.title}. ${c.firstname} ${c.lastname}`)
        .join(", ")}
    </p>
  );
};

export default CustomerList;
