export const AMENITY_MAP = {
  aircondition: {
    en: "Air Condition",
    icon: ["fal", "thermometer-half"],
    tr: "Klima"
  },
  bar: {
    en: "Bar",
    icon: ["fas", "glass-martini"],
    tr: "Bar"
  },
  bathroom: {
    en: "Bathroom",
    icon: ["far", "shower"],
    tr: "Banyo"
  },
  bathtub: {
    en: "Bathtub",
    icon: ["far", "hot-tub"],
    tr: "Küvet"
  },
  bellboy: {
    en: "Bellboy Service",
    icon: ["far", "person-carry"],
    tr: "Belboy Hizmeti"
  },
  business_center: {
    en: "Business Center",
    icon: ["far", "desktop"],
    tr: "Konferans Salonu"
  },
  carhire: {
    en: "Car Hire",
    icon: ["far", "car"],
    tr: "Araba Kiralama"
  },
  carpeted: {
    en: "Carpeted Floors",
    icon: [],
    tr: "Halı Zemin"
  },
  cot_on_deman: {
    en: "Cot on Demand",
    icon: [],
    tr: "Bebek Karyolası"
  },
  currency_exchange: {
    en: "Currency Exchange",
    icon: ["far", "exchange"],
    tr: "Döviz Çevrimi"
  },
  desk: {
    en: "Desk",
    icon: [],
    tr: "Masa"
  },
  extra_beds: {
    en: "Extra Beds",
    icon: ["far", "bed"],
    tr: "Ekstra Yatak"
  },
  gym: {
    en: "Gym",
    icon: ["fas", "dumbbell"],
    tr: "Fitness Salonu"
  },
  hairdryer: {
    en: "Hair Dryer",
    icon: [],
    tr: "Saç Kurutma Makinesi"
  },
  hour24_reception: {
    en: "24-Hour Reception",
    icon: ["far", "bell"],
    tr: "24-Saat Resepsiyon"
  },
  hour24_security: {
    en: "24-Hour Security",
    icon: ["far", "shield-check"],
    tr: "24-Saat Güvenlik"
  },
  internet: {
    en: "Internet",
    icon: ["far", "wifi"],
    tr: "İnternet"
  },
  ironing: {
    en: "Ironing",
    icon: [],
    tr: "Ütü"
  },
  kettle: {
    en: "Kettle",
    icon: [],
    tr: "Su Isıtıcısı"
  },
  laundry: {
    en: "Laundry",
    icon: ["far", "tshirt"],
    tr: "Kuru Temizleme"
  },
  lift_access: {
    en: "Lift Access",
    icon: ["far", "grip-vertical"],
    tr: "Asansör Erişimi"
  },
  luggage_room: {
    en: "Luggage Room",
    icon: [],
    tr: "Bagaj Odası"
  },
  medical: {
    en: "Medical Service",
    icon: ["far", "prescription-bottle-alt"],
    tr: "Sağlık Hizmet"
  },
  meeting_rooms: {
    en: "Meeting Rooms",
    icon: ["fas", "keynote"],
    tr: "Toplantı Odaları"
  },
  multilingual: {
    en: "Multilingual Service",
    icon: ["far", "language"],
    tr: "Çoklu Dilde Hizmet"
  },
  newspaper: {
    en: "Newspaper Stand",
    icon: ["far", "newspaper"],
    tr: "Gazeteler"
  },
  parking: {
    en: "Parking",
    icon: ["fas", "parking-circle"],
    tr: "Park"
  },
  pet: {
    en: "Pet Friendly",
    icon: ["fas", "paw"],
    tr: "Hayvan Dostu"
  },
  radio: {
    en: "Radio",
    icon: ["far", "broadcast-tower"],
    tr: "Radyo"
  },
  restaurant: {
    en: "Restaurant",
    icon: ["fas", "utensils"],
    tr: "Restoran"
  },
  room_service: {
    en: "Room Service",
    icon: ["far", "concierge-bell"],
    tr: "Oda Servisi"
  },
  room_size: {
    en: "Room Size",
    icon: [],
    tr: "Oda Büyüklüğü"
  },
  safe_depbox: {
    en: "Safe Box",
    icon: ["fab", "keycdn"],
    tr: "Mini Kasa"
  },
  smoking: {
    en: "Smoking Room",
    icon: ["far", "smoking"],
    tr: "Sigara İçilebilir"
  },
  spa: {
    en: "Spa",
    icon: ["fas", "spa"],
    tr: "Spa"
  },
  supermarket: {
    en: "Supermarket/Shop",
    icon: ["far", "shopping-basket"],
    tr: "Market"
  },
  swimming: {
    en: "Swimming Pool",
    icon: ["far", "swimming-pool"],
    tr: "Yüzme Havuzu"
  },
  telephone: {
    en: "Telephone",
    icon: ["far", "phone"],
    tr: "Telefon"
  },
  terrace: {
    en: "Terrace",
    icon: [],
    tr: "Teras"
  },
  transfer: {
    en: "Transfer Service",
    icon: ["far", "taxi"],
    tr: "Transfer Servisi"
  },
  tv: {
    en: "Tv",
    icon: ["far", "tv"],
    tr: "Tv"
  },
  wheelchair: {
    en: "Wheelchair Access",
    icon: ["far", "wheelchair"],
    tr: "Engelli Dostu"
  },
  wifi: {
    en: "Wi-Fi",
    icon: ["far", "wifi"],
    tr: "Wi-Fi"
  }
};
