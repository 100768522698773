import { Reducer } from "redux";
import { IServiceState } from "../../interfaces/IState";
import {
  SERVICE_FETCH_FULFILLED,
  SERVICE_FETCH_PENDING,
  SERVICE_FETCH_REJECTED
} from "../../utils/constants";

const initialState: IServiceState = {
  services: {
    activities: [],
    cars: [],
    flights: [],
    hotels: [],
    transfers: []
  },
  status: {
    error: false,
    fetched: false,
    fetching: false
  }
};

const serviceReducer: Reducer<IServiceState> = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_FETCH_PENDING:
      return {
        ...state,
        status: {
          error: false,
          fetched: false,
          fetching: true
        }
      };
    case SERVICE_FETCH_FULFILLED:
      return {
        ...state,
        services: {
          ...state.services,
          ...action.payload
        },
        status: {
          error: false,
          fetched: true,
          fetching: false
        }
      };
    case SERVICE_FETCH_REJECTED:
      return {
        ...state,
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
  }
  return state;
};

export default serviceReducer;
