import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { CSSProperties, FC, useEffect, useMemo, useRef } from "react";

type Props = {
  category: string;
  data: Array<{
    amount: number;
    company_code: string;
    count: number;
    currency: string;
  }>;
  span: number;
  value: string;
  title?: string;
};

const ColumnChart: FC<Props> = ({ category, data, span, title, value }) => {
  const $container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chart = am4core.create($container.current!, am4charts.XYChart);

    chart.language.locale._decimalSeparator = ",";
    chart.language.locale._thousandSeparator = ".";

    chart.data = data;

    if (title) {
      const chartTitle = chart.titles.create();
      chartTitle.text = title;
      chartTitle.fontSize = 25;
      chartTitle.marginBottom = 30;
    }

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    // categoryAxis.renderer.labels.template.adapter.add("dy", (dy, target) => {
    //   if (target.dataItem && target.dataItem.index && 2 === 2) {
    //     return (dy || 0) + 25;
    //   }
    //   return dy;
    // });

    chart.yAxes.push(new am4charts.ValueAxis());

    // valueAxis.renderer.labels.template.adapter.add("text", text => text + " " + data[0].currency);

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.value = "count";
    series.dataFields.valueY = value;
    series.dataFields.categoryY = "currency";
    series.dataFields.categoryX = category;
    series.name = "Countries";
    series.columns.template.tooltipText =
      'Total: [bold]{valueY.formatNumber("#,###.##")} {categoryY}[/]\nCount: [bold]{value}[/]';
    series.columns.template.fillOpacity = 0.8;
    series.columns.template.strokeWidth = 0;

    series.columns.template.adapter.add("fill", (fill, target) =>
      chart.colors.getIndex(target.dataItem ? target.dataItem.index : 0)
    );

    return () => chart.dispose();
  }, [category, data, title, value]);

  const styles: CSSProperties = useMemo(() => {
    return {
      height: "350px",
      width: span ? (span * (100 / 3)).toFixed(1) + "%" : "100%"
    };
  }, [span]);

  return (
    <div className="chart-container" style={styles}>
      <div className="chart" ref={$container} />
    </div>
  );
};

export default ColumnChart;
