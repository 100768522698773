import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Statuses } from "../../../components/Statuses";
import Transfer from "../../../components/Transfer";
import FilterTransfer from "../../../containers/FilterTransfer";
import { ITransfer } from "../../../interfaces/ITransfer";
import { toggleFilterTransfers } from "../../../store/actions/filterActions";
import { getCustomersValidForBooking } from "../../../store/selectors/customerSelectors";
import {
  getTransfers,
  getTransferSearchDone,
  getTransfersResultEmpty,
  getTransfersStatuses
} from "../../../store/selectors/transferSelectors";
import { Merge } from "../../../types";

const Transfers: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customersValidForBooking = useSelector(getCustomersValidForBooking);
  const transfers = useSelector(getTransfers);
  const isEmpty = useSelector(getTransfersResultEmpty);
  const searchIsDone = useSelector(getTransferSearchDone);
  const statuses = useSelector(getTransfersStatuses);

  const toggleFilter = useCallback(() => {
    dispatch(toggleFilterTransfers());
  }, [dispatch]);

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: ITransfer[] }>> = useCallback(
    ({ index, data, style }) => (
      <Transfer transfer={data[index]} customersValidForBooking={customersValidForBooking} style={style} />
    ),
    [customersValidForBooking]
  );

  return statuses.some(s => s.fetched || s.fetching) ? (
    <div className="box-container transports">
      <div className="title flex j-between a-center is-relative">
        <span className="result-header-icon c-primary">
          <FontAwesomeIcon icon={["fas", "shuttle-van"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary">{t("transfer.resultTitle")}</h1>
        {searchIsDone ? (
          <>
            {/*<InlineSearchCar />*/}
            <button className="button is-small filter-button" onClick={toggleFilter}>
              <FontAwesomeIcon icon={["fas", "filter"]} />
            </button>
          </>
        ) : (
          <Statuses statuses={statuses} />
        )}
        <FilterTransfer />
      </div>
      {isEmpty && <p className="not-found-message">{t("search.notFound")}</p>}
      <FixedSizeList
        itemData={transfers}
        itemSize={250}
        height={500}
        itemCount={transfers.length}
        width="100%"
        className="padding-fix"
      >
        {rowRenderer}
      </FixedSizeList>
    </div>
  ) : (
    <div />
  );
};

export default Transfers;
