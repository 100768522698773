import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FC, useState } from "react";
import useObjectState from "../../hooks/useObjectState";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { CustomDateRangePicker } from "../date/CustomDateRangePicker";

interface IConfirmationEmailRequest {
  action_type: string;
  comments?: string;
  email_type?: string;
  offer_guid: string;
  pnr: string;
  checkInDate: string;
  checkOutDate: string;
  source?: string;
  mailto: string;
  replyto: string;
}

interface IProps extends IConfirmationEmailRequest {
  onClose(): void;
  reload(): void;
}

const ChangeDateModal: FC<IProps> = ({ onClose, reload, ...mailProps }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [mail, setMail] = useObjectState<IConfirmationEmailRequest>({
    action_type: "",
    checkInDate: "",
    checkOutDate: "",
    comments: "",
    email_type: "",
    offer_guid: "",
    pnr: "",
    source: "AGREEMENT",
    ...mailProps,
  });

  const dateHandler = (startDate: string, endDate: string) => {
    setMail({
      checkInDate: startDate,
      checkOutDate: endDate,
    });
  };

  const changeHandler = (key: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMail({
      [key]: e.currentTarget.value,
    });
    setValidationMessage("");
  };

  const sendMail = async () => {
    if (!mail.checkInDate || !mail.checkOutDate) {
      return setValidationMessage("All fields must be filled.");
    }

    setIsBusy(true);
    try {
      await api().post("changepnr", mail);
      Logger.flash("Mail successfully sent.");
      reload();
      onClose();
    } catch {
      Logger.flashError("There was an error while sending mail. Try again later...");
    }
    setIsBusy(false);
  };

  return (
    <Modal.Container isBusy={isBusy} bordered onClose={onClose}>
      <Modal.Header>
        <h1>Change Reservation Date Mail</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="form">
          <div className="form-group flex">
            <div>
              <label>Checkin / Checkout Date:</label>
              <CustomDateRangePicker
                absolute={false}
                format="YYYYMMDD"
                startDate={mail.checkInDate}
                endDate={mail.checkInDate}
                onChange={dateHandler}
                isOpen={true}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Comment:</label>
            <textarea rows={20} value={mail.comments} onChange={changeHandler("comments")} />
          </div>
          <div className="form-group">
            <label>Mail To:</label>
            <input readOnly value={mail.mailto} />
          </div>
          <div className="form-group">
            <label>Reply To:</label>
            <input readOnly value={mail.replyto} />
          </div>
          <div className="form-errors">
            {validationMessage !== "" && <p className="error">{validationMessage}</p>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="confirmation-send" onClick={sendMail}>
          <FontAwesomeIcon icon={["far", "envelope"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default ChangeDateModal;
