import React, { FC } from "react";
import Loading from "../../components/Loading";

interface IProps {
  className?: string;
  loading?: boolean;
}

const ModalBody: FC<IProps> = ({ className = "", children, loading }) => {
  return <div className={`modal-body ${className}`}>{loading ? <Loading /> : children}</div>;
};

export default ModalBody;
