import isNumber from "lodash-es/isNumber";
import merge from "lodash-es/merge";
import reverse from "lodash-es/reverse";
import set from "lodash-es/set";
import { Reducer } from "redux";
import { ISearch } from "../../interfaces/ISearch";
import { ISearchState } from "../../interfaces/IState";
import {
  SEARCH_ADD_ROUTE,
  SEARCH_CLEAR_CUSTOMERS,
  SEARCH_DESTINATION_UPDATE,
  SEARCH_INDEX_PUSH,
  SEARCH_INITIALIZE,
  SEARCH_MAP_CUSTOMER_TO_PASSENGER,
  SEARCH_RESET,
  SEARCH_SELECT_FROM_HISTORY,
  SEARCH_UI_CONTROL,
  SEARCH_UPDATE,
  SEARCH_UPDATE_PASSENGER
} from "../../utils/constants";
import { defaultSearchState } from "../../utils/defaults";

const initialState: ISearchState = {
  current: defaultSearchState,
  history: [],
  mode: "flight",
  searchIsMade: false,
  ui: {
    shrinked: false
  }
};

const searchReducer: Reducer<ISearchState> = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_RESET:
      return {
        ...state,
        current: defaultSearchState,
        history: []
      };

    case SEARCH_INITIALIZE:
      return {
        ...state,
        history: action.payload
      };

    case SEARCH_UPDATE:
      return {
        ...state,
        current: searchValidator(merge({}, state.current, action.payload))
      };

    case SEARCH_UPDATE_PASSENGER:
      return {
        ...state,
        current: passengerCountValidator(state.current, action.payload)
      };

    case SEARCH_DESTINATION_UPDATE:
      const newCurrentDest = { ...state.current };
      if (action.payload.name) {
        newCurrentDest.name = action.payload.name;
      }
      for (const [key, value] of Object.entries(action.payload.params)) {
        set(newCurrentDest, key, value);
      }
      return {
        ...state,
        current: searchValidator(newCurrentDest),
        mode: action.payload.mode
      };

    case SEARCH_ADD_ROUTE:
      return {
        ...state,
        current: {
          ...state.current,
          routes: [
            ...state.current.routes,
            {
              arr: state.current.routes[0].dep,
              arrMultiAirport: false,
              date: "",
              dep: state.current.routes[state.current.routes.length - 1].arr,
              depMultiAirport: false,
              rdate: ""
            }
          ]
        }
      };
    case SEARCH_SELECT_FROM_HISTORY:
      return {
        ...state,
        current: state.history.find(s => s.guid === action.payload)!
      };
    case SEARCH_UI_CONTROL:
      return {
        ...state,
        ui: merge({}, state.ui, action.payload)
      };
    case SEARCH_INDEX_PUSH:
      const history: ISearch[] = JSON.parse(localStorage.getItem("history") || "[]");
      history.push(action.payload);
      localStorage.setItem("history", JSON.stringify(history.filter(Boolean)));
      return {
        ...state,
        history: reverse(history.filter(Boolean)),
        searchIsMade: true
      };
    case SEARCH_MAP_CUSTOMER_TO_PASSENGER:
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload[0],
          rooms: action.payload[1]
        }
      };
    case SEARCH_CLEAR_CUSTOMERS:
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload
        }
      };
    default:
      return state;
  }
};

export default searchReducer;

function searchValidator(search: ISearch) {
  return {
    ...search,
    filters: {
      ...search.filters,
      hasHotels: search.ftype === "MC" || search.ftype === "OWC" ? false : search.filters.hasHotels,
      hasPackages: search.ftype === "MC" ? false : search.filters.hasPackages
    }
  };
}

function passengerCountValidator(oldSearch: ISearch, newSearch: ISearch) {
  const totalExceed = oldSearch.adt + oldSearch.chd > 8;
  let { adt, chd, inf } = oldSearch;

  if (isNumber(newSearch.adt)) {
    if (!totalExceed || newSearch.adt < adt) {
      adt = newSearch.adt;
    }
    if (adt <= 0) {
      adt = 0;
    }
    if (adt <= inf) {
      inf = adt;
    }
  }

  if (isNumber(newSearch.chd)) {
    if (!totalExceed || newSearch.chd < chd) {
      chd = newSearch.chd;
    }
    if (chd <= 0) {
      chd = 0;
    }
  }

  if (isNumber(newSearch.inf)) {
    inf = newSearch.inf;
    if (inf <= 0) {
      inf = 0;
    }
    if (inf >= adt) {
      inf = adt;
    }
  }

  return {
    ...oldSearch,
    adt,
    chd,
    inf
  };
}
