import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";

interface IProps {
  type: string;
}

const ProgramIcon: FC<IProps> = ({ type }) => {
  const icon: IconProp | undefined = useMemo(() => {
    switch (type) {
      case "meal":
        return ["fas", "utensils-alt"];
      case "transfer":
        return ["far", "taxi"];
      case "meeting":
        return ["far", "user-tie"];
    }
  }, [type]);

  return <>{icon && <FontAwesomeIcon icon={icon} />}</>;
};

export default ProgramIcon;
