import { Reducer } from "redux";
import { IService } from "../../interfaces/IService";
import { IFlightState, IFlightVendorState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import {
  FLIGHT_RESET,
  FLIGHT_FETCH_EMPTY,
  FLIGHT_FETCH_FULFILLED,
  FLIGHT_FETCH_PENDING,
  FLIGHT_FETCH_REJECTED,
  FLIGHT_UPDATE,
  FLIGHT_ALTERNATIVE_SELECT
} from "../../utils/constants";

const initialState: IFlightState = {
  alternatives: {},
  vendors: {}
};

const flightReducer: Reducer<IFlightState> = (state = initialState, action) => {
  switch (action.type) {
    case FLIGHT_RESET:
      return {
        alternatives: {},
        vendors: action.payload.reduce((vendors: Dictionary<IFlightVendorState>, service: IService) => {
          vendors[service.key] = {
            flights: [],
            departFlights: [],
            returnFlights: [],
            status: {
              error: false,
              fetched: false,
              fetching: false
            }
          };
          return vendors;
        }, {})
      };

    case FLIGHT_UPDATE:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.payload.source]: {
            ...state.vendors[action.payload.source],
            flights: state.vendors[action.payload.source].flights.map(flight => {
              if (flight.OfferId === action.payload.OfferId) {
                return {
                  ...flight,
                  ...action.payload
                };
              }
              return flight;
            })
          }
        }
      };

    case `${action.service}_${FLIGHT_FETCH_PENDING}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            flights: [],
            departFlights: [],
            returnFlights: [],
            status: {
              error: false,
              fetched: false,
              fetching: true
            }
          }
        }
      };

    case `${action.service}_${FLIGHT_FETCH_FULFILLED}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            ...state[action.service],
            flights: action.payload.flights.concat(state?.vendors?.[action.service]?.flights ?? []),
            departFlights: action.payload.departFlights ?? [],
            returnFlights: action.payload.returnFlights ?? [],
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          }
        }
      };

    case `${action.service}_${FLIGHT_FETCH_REJECTED}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            flights: [],
            departFlights: [],
            returnFlights: [],
            status: {
              error: true,
              fetched: true,
              fetching: false
            }
          }
        }
      };

    case `${action.service}_${FLIGHT_FETCH_EMPTY}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            flights: [],
            departFlights: [],
            returnFlights: [],
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          }
        }
      };

    case FLIGHT_ALTERNATIVE_SELECT:
      return {
        ...state,
        alternatives: {
          ...state.alternatives,
          [action.payload.offerid]: action.payload.alternative
        }
      };
    default:
      return state;
  }
};

export default flightReducer;

// TODO: RESET AFTER BOOKING
// case BOOKING_MAKE_FULFILLED:
//   return [...state].map(flightState => ({
//     ...flightState,
//     flights: []
//   }));

// TODO: AMADEUS CLEAR???
// case FLIGHT_AMADEUS_CLEAR:
//   return [...state].map(s => {
//     if (s.key === "AMADEUS-EPOWER") {
//       s.flights = [];
//     }
//     return s;
//   });
