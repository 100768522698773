import React from "react";
import FlightLogo from "./FlightLogo";

interface IOperatorComponentProps {
  name: string;
  code: string;
  no?: string;
  title?: string;
}

export default function AirlineCard({ name, code, no, title }: IOperatorComponentProps) {
  return (
    <div className="operator-airline">
      {title && <p className="fs-std">{title}</p>}
      <p className="fs-std same-line-height">
        {title && <FlightLogo code={code} className="text-image" />} {name}
      </p>
      {!!no && (
        <p className="fs-op flex a-center">
          <FlightLogo code={code} className="text-image" />
          <span style={{ marginLeft: 5 }}>
            {code} {no}
          </span>
        </p>
      )}
    </div>
  );
}
