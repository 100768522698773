import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import { pwc } from "../../utils/helpers";

interface IProps {
  items: IAccountingPayment[];
}

const AccountingPriceList: FC<IProps> = ({ items }) => {
  const { t } = useTranslation();
  const vatAmount = items.reduce((total, i) => total + i.vat_amount, 0);
  const totalAmount = items.reduce((total, i) => total + i.total_amount, 0);
  const baseAmount = totalAmount - vatAmount;
  return (
    <table className="accounting-action-table fs-std">
      <thead>
        <tr>
          <th>PNR</th>
          <th>{t("generic.vat")}</th>
          <th>
            {t("generic.vat")} {t("generic.amount")}
          </th>
          <th>
            {t("generic.base")} {t("generic.amount")}
          </th>
          <th>
            {t("generic.total")} {t("generic.amount")}
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr key={item.paymentKey}>
            <td>{item.pnr}</td>
            <td>{item.vat_ratio}%</td>
            <td>{pwc(item.vat_amount, item.currency)}</td>
            <td>{pwc(item.total_amount - item.vat_amount, item.currency)}</td>
            <td>{pwc(item.total_amount, item.currency)}</td>
          </tr>
        ))}
        <tr className="total-row">
          <td>Total:</td>
          <td />
          <td>{pwc(vatAmount, items[0].currency)}</td>
          <td>{pwc(baseAmount, items[0].currency)}</td>
          <td>{pwc(totalAmount, items[0].currency)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default AccountingPriceList;
