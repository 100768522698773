import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, useContext, useState } from "react";
import useClickDetect from "../../hooks/useClickDetect";
import { SearchContext } from "./Search";

interface IProps {
  className?: string;
  choices: Array<{
    value: string;
    name: string;
  }>;
  propKey: string;
  value: string;
}

const Dropdown: FC<IProps> = ({ className = "", choices, propKey, value }) => {
  const $el = useClickDetect(() => setIsActive(false));
  const setSearch = useContext(SearchContext)[1];
  const [isActive, setIsActive] = useState(false);

  const currentChoice = () => {
    return choices.find(choice => choice.value === value)!;
  };

  const selectHandler = (choice: { value: string; name: string }) => {
    setIsActive(false);
    setSearch({ [propKey]: choice.value });
  };

  const cx = classNames("dropdown", className, {
    active: isActive
  });

  return (
    <div ref={$el} className={cx}>
      <h1 onClick={() => setIsActive(!isActive)} className="medium">
        {currentChoice().name} <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </h1>
      <ul className="dropdown-item-list">
        {choices.map((choice, key) => (
          <li
            onClick={() => selectHandler(choice)}
            key={key}
            className={`dropdown-item${value === choice.value ? " selected" : ""}`}
          >
            {choice.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
