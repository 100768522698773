import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getLastSearch } from "../../../store/selectors/searchSelectors";
import languageStorage from "../../../utils/languageStorage";

const HotelDetailsTitle = () => {
  const { fdate, rdate } = useSelector(getLastSearch);
  return (
    <div className="hotel-details-title">
      <h1>
        {moment(fdate)
          .locale(languageStorage().current())
          .format("D MMM")}{" "}
        <FontAwesomeIcon icon={["far", "long-arrow-right"]} />{" "}
        {moment(rdate)
          .locale(languageStorage().current())
          .format("D MMM")}
      </h1>
    </div>
  );
};

export default HotelDetailsTitle;
