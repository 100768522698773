import find from "lodash-es/find";
import get from "lodash-es/get";
import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";
import { getTheme } from "./uiSelectors";

export const getUserState = (state: IStoreState) => state.user;

export const getUser = createSelector(getUserState, userState => userState.user);

export const getUserIsAuthenticated = createSelector(getUserState, userState => userState.authenticated);

export const getUserRole = createSelector(getUser, user => user.usertype);

export const getUserMenu = createSelector(getUser, user =>
  user.menu && user.menu.items
    ? user.menu.items.filter(
        main => main.code !== "notifications" && main.code !== "shopping-cart" && main.code !== "shoping-cart"
      )
    : []
);

export const getUserBackgroundImage = createSelector(getUser, user => user.backgroundImage);

export const getUserCompanyList = createSelector(getUser, user =>
  (user.companies || []).filter(c => c.companyCode !== user.companycode)
);

export const getUserAgencyLogo = createSelector(getUser, getTheme, (user, theme) =>
  get(
    find(user.agencyLogos, l => l.theme === theme),
    "url",
    "defaultAgency"
  )
);

export const getUserCompanyLogo = createSelector(getUser, getTheme, (user, theme) =>
  get(
    find(user.companyLogos, l => l.theme === theme),
    "url",
    "defaultCompany"
  )
);
