import entries from "lodash-es/entries";
import flatten from "lodash-es/flatten";
import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";
import keys from "lodash-es/keys";
import reduce from "lodash-es/reduce";
import sortBy from "lodash-es/sortBy";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import { createSelector } from "reselect";
import { IActivity } from "../../interfaces/IActivity";
import { IStoreState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import { getFiltersActivity, getFiltersActivitySortBy } from "./filterSelectors";

export function getActivityStates(state: IStoreState) {
  return state.activities;
}

export const getActivities = createSelector(getActivityStates, activityStates =>
  flatten(activityStates.map(activityState => activityState.activities))
);

export const getActivitiesSortedByPrice = createSelector(
  getActivities,
  getFiltersActivitySortBy,
  (activities, activitySortBy) => sortBy(activities, activitySortBy === "price" ? "AmountAfterTax" : "")
);

export const getSearchedActivities = createSelector(
  getActivitiesSortedByPrice,
  getFiltersActivity,
  (activities, filters) => {
    const { input, type, searchable } = filters.search;
    if (input.length < 3) {
      return activities;
    }

    const keywords = input.split(" ");

    return activities.filter(activity =>
      keywords.every(word =>
        keys(activity).some(param => {
          if (activity[param] && includes(searchable, param)) {
            return type === "startsWith"
              ? startsWith(toLower(activity[param]), toLower(word))
              : includes(toLower(activity[param]), toLower(word));
          }
          return false;
        })
      )
    );
  }
);

export const getGroupedActivities = createSelector(getSearchedActivities, activities => {
  return entries(
    reduce(
      activities,
      (grouped, a) => {
        if (isArray(grouped[a.activityCode])) {
          grouped[a.activityCode].push(a);
        } else {
          grouped[a.activityCode] = [a];
        }
        return grouped;
      },
      {} as Dictionary<IActivity[]>
    )
  );
});

export const getActivityStatuses = createSelector(getActivityStates, activities =>
  activities.map(activityState => {
    const status = activityState.status;
    status.serviceKey = activityState.key;
    return status;
  })
);

export const getActivityIsSearching = createSelector(getActivityStatuses, statuses =>
  statuses.some(s => s.fetching)
);

export const getActivitySearchDone = createSelector(getActivityStatuses, statuses => {
  return !!statuses.length && statuses.every(s => !s.fetching && s.fetched);
});

export const getActivitiesResultEmpty = createSelector(
  getActivities,
  getActivitySearchDone,
  (activities, searchDone) => !activities.length && searchDone
);
