import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CheckboxSimple from "../../../../components/snippets/CheckboxSimple";

interface IProps {
  filter: boolean;
  rule: boolean;

  onChange(section: "isFilter" | "isRule"): void;
}

const PolicyFilterRule: FC<IProps> = ({ rule, filter, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="policy-type">
      <span className="c-primary" onClick={() => onChange("isFilter")}>
        <CheckboxSimple checked={filter} /> {t("admin.policy.filter")}
      </span>
      <span className="c-primary" onClick={() => onChange("isRule")}>
        <CheckboxSimple checked={rule} /> {t("admin.policy.rule")}
      </span>
    </div>
  );
};

export default PolicyFilterRule;
