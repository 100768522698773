import { useField } from "formik";
import moment from "moment";
import React, { FC, useState, useEffect } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import useClickDetect from "../../../hooks/useClickDetect";
import { CustomDateSinglePicker } from "../../../components/date/CustomDateSinglePicker";

type Props = {
  name: string;
  format?: string;
  placeholder?: string;
};

const FormikDatePicker: FC<Props> = ({ format = "YYYY-MM-DD", name, placeholder }) => {
  const $el = useClickDetect(() => setIsActive(false));
  const [field, , { setValue }] = useField(name);
  const [isActive, setIsActive] = useState(false);
  const [localValue, setLocalValue] = useState(() => {
    const parsed = moment(field.value, format);
    if (parsed.isValid()) {
      return parsed.format("DDMMYYYY");
    } else {
      return moment().format("DDMMYYYY");
    }
  });

  // const date = useMemo(() => {
  //   const formattedDate = moment(, format);
  //   return formattedDate.isValid() ? formattedDate.format(format) : moment().format(format);
  // }, [field.value, format]);

  const changeHandler = (values: NumberFormatValues) => {
    setLocalValue(values.value);
  };

  const datePickHandler = (pickedDate: string) => {
    setLocalValue(pickedDate);
  };

  useEffect(() => {
    const formatted = moment(localValue, "DDMMYYYY");
    if (formatted.isValid()) {
      setValue(formatted.format(format));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  return (
    <div className="formik-input" ref={$el}>
      <NumberFormat
        onClick={() => setIsActive((x) => !x)}
        value={localValue}
        onValueChange={changeHandler}
        placeholder={placeholder}
        format="##.##.####"
        mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
      />
      {isActive && <CustomDateSinglePicker format="DDMMYYYY" date={localValue} onChange={datePickHandler} />}
    </div>
  );
};

export default FormikDatePicker;
