import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPnrPayment } from "../../interfaces/IPnr";
import { pwc } from "../../utils/helpers";

interface IProps {
  payments?: IPnrPayment[];
}

const PnrPayment: FC<IProps> = ({ payments }) => {
  const { t } = useTranslation();

  return payments && payments.length > 0 ? (
    <div className="pnr-details">
      <div className="title flex j-between a-center is-relative">
        <span className="pnr-header-icon c-primary">
          <FontAwesomeIcon icon={["fal", "file"]} />
        </span>
        <h1 className="text fs-med fill-space c-primary bold">{t("generic.payment")}</h1>
      </div>
      <div className="pnr-customer-list">
        <table className="table table-ticket is-fullwidth">
          <thead>
            <tr>
              <th className="fs-med">Key</th>
              <th className="fs-med">Amount</th>
              <th className="fs-med">Type</th>
              <th className="fs-med">Status</th>
            </tr>
          </thead>
          <tbody>
            {payments.map(p => (
              <tr key={p.paymentKey}>
                <td className="c-primary fs-med">{p.paymentKey}</td>
                <td className="c-primary fs-med">{pwc(p.amount, p.currency)}</td>
                <td className="c-primary fs-med">{p.paymentType && Object.keys(p.paymentType)[0]}</td>
                <td className="c-primary fs-med">{p.payStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};
export default PnrPayment;
