import React, { FC, useMemo } from "react";
import FlightSvgMedia from "../../assets/FlightSvgMedia";
import { COLORS } from "../../utils/constants";

interface IProps {
  className?: string;
  index: string;
  transit?: boolean;
}

const FlightSvg: FC<IProps> = ({ className, index, transit }) => {
  const color = useMemo(() => {
    const i = parseInt(index);
    const t = 1 || transit;
    switch (i) {
      case 0:
        return t ? COLORS.blue[0] : COLORS.blue[1];
      case 1:
        return t ? COLORS.orange[0] : COLORS.orange[1];
      case 2:
        return t ? COLORS.green[0] : COLORS.green[1];
      case 3:
        return t ? COLORS.purple[0] : COLORS.purple[1];
      default:
        return COLORS.blue[0];
    }
  }, [index, transit]);

  return (
    <span className={className}>
      <FlightSvgMedia color={color} />;
    </span>
  );
};

export default FlightSvg;
