import { ErrorMessage, Field, FieldAttributes } from "formik";
import React, { FC } from "react";

type Props = FieldAttributes<any>;

const FieldWithErrorMessage: FC<Props> = ({ name, ...props }) => {
  return (
    <div className="field">
      <Field name={name} {...props} />
      <ErrorMessage className="input-helper danger" name={name} component="span" />
    </div>
  );
};

export default FieldWithErrorMessage;
