import moment from "moment";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../interfaces/ICustomer";
import { ITransfer } from "../interfaces/ITransfer";

interface IProps {
  customers: ICustomer[];
  transfers: ITransfer[];
  pnr: string;
}

const VoucherTransfer: FC<IProps> = ({ customers, transfers = [], pnr }: IProps) => {
  const { t } = useTranslation();

  const allGuests = useMemo(() => {
    return customers.map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  return (
    <div className="hotel info">
      <div className="title">
        <img src="images/voucher/car.png" alt="transfer" />
        <h3>{t("generic.title.transferInfo")}</h3>
      </div>
      {transfers.map((transfer, index) => (
        <div key={index} className="caption">
          <div className="top-caption">
            <p>{transfer.title}</p>
            <p>
              <span className="is-bold">Date:</span>
              {moment(transfer.StartDate, "YYYY-MM-DD").format("D MMMM YYYY")}
            </p>
          </div>
          <div className="top-caption">
            {transfer.PickupAddress && (
              <p>
                <span className="is-bold">{t("voucher.car.pickupAddress")}:</span>
                <br />
                {transfer.PickupName} <br /> {transfer.PickupAddress}
              </p>
            )}
            {transfer.DropoffAddress && (
              <p>
                <span className="is-bold">{t("voucher.car.dropoffAddress")}:</span>
                <br />
                {transfer.DropoffName} <br /> {transfer.DropoffAddress}
              </p>
            )}
          </div>
          <p className="general">
            <span className="bold">PNR:</span> {pnr}
          </p>
          <table>
            <tbody>
              <tr>
                <td className="is-bold">{t("voucher.car.driver")}:</td>
                <td>{allGuests.join(", ")}</td>
                <td className="is-bold">{t("voucher.car.passengerCount")}:</td>
                <td>{transfer.PassengerQty}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.car.model")}:</td>
                <td>{transfer.ModelName}</td>
                <td className="is-bold">{t("voucher.car.category")}:</td>
                <td>{transfer.VehicleCategory}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.car.doorCount")}:</td>
                <td>{transfer.NumberOfDoor}</td>
                <td className="is-bold">{t("voucher.car.airCondition")}:</td>
                <td>{transfer.AirCondition ? t("generic.yes") : t("generic.no")}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.paymentStatus")}:</td>
                <td>{transfer.payStatus || t("voucher.notSpecified")}</td>
                <td className="is-bold">{t("voucher.car.vendor")}:</td>
                <td className="voucher-logo">
                  {transfer.VendorLogo ? (
                    <img src={transfer.VendorLogo} alt={transfer.Vendor} />
                  ) : (
                    transfer.Vendor
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default VoucherTransfer;
