import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { pwc } from "../../utils/helpers";

interface IProps {
  amount: number;
  currency: string;
  title: string;
  icon?: IconProp;
}

const Expense: FC<IProps> = ({ amount, currency, icon, title }) => {
  const { t } = useTranslation();
  return (
    <div className="expense-item has-icon">
      {icon && (
        <div className="icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <div className="content">
        <h1>{title}</h1>
        <div className="total">
          <FontAwesomeIcon color="#ed7d31" icon={["fas", "bookmark"]} />
          <p>{pwc(amount, currency)}</p>
        </div>
        <button className="pointer">
          {t("corpReport.expense.total")} <FontAwesomeIcon icon={["far", "chevron-right"]} />
        </button>
      </div>
    </div>
  );
};

export default Expense;
