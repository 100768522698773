import React from "react";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { IStoreState } from "../interfaces/IState";
import { changeTheme } from "../store/actions/uiActions";
import { getTheme } from "../store/selectors/uiSelectors";

interface IDispatchProps {
  switcher(): void;
}

interface IStateProps {
  theme: "dark" | "light";
}

interface IProps extends IDispatchProps, IStateProps {}

function ThemeChanger({ theme, switcher }: IProps) {
  return (
    <span className="theme-switch">
      <Toggle checked={theme === "light"} onChange={switcher} icons={false} />
    </span>
  );
}

const mapStateToProps = (state: IStoreState): IStateProps => ({
  theme: getTheme(state)
});

const mapDispatchToProps: IDispatchProps = {
  switcher: changeTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeChanger);
