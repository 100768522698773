import Color from "color";
import forEach from "lodash-es/forEach";
import isBoolean from "lodash-es/isBoolean";
import { Dispatch } from "redux";
import { ICheckoutData } from "../../interfaces/IBooking";
import { IHotel } from "../../interfaces/IHotel";
import { AC, GetState } from "../../types";
import {
  BOOKING_RESET,
  CUSTOMER_CLEAR,
  darkTheme,
  lightTheme,
  SEARCH_RESET,
  UI_APP_LOADED,
  UI_BASKET_SET_DATA,
  UI_BASKET_SET_PENDING,
  UI_BASKET_TOGGLE,
  UI_CHANGE_THEME,
  UI_CHECKOUT_SCREEN_TOGGLE,
  UI_CHECKOUT_SET_DATA,
  UI_CHECKOUT_SET_PENDING,
  UI_CHECKOUT_SET_SHOULD_CLOSE,
  UI_CLEAR_RESET,
  UI_CUSTOMER_SCREEN_TOGGLE,
  UI_HOTEL_DETAILS_SCREEN_CLOSE,
  UI_HOTEL_DETAILS_SCREEN_OPEN,
  UI_HOTEL_DETAILS_SCREEN_TOGGLE,
  UI_RESET
} from "../../utils/constants";
import { fetchBasket, ItemTypes } from "./basketActions";

export const toggleHotelDetailsScreen = (hotel?: IHotel) => ({
  payload: hotel,
  type: UI_HOTEL_DETAILS_SCREEN_TOGGLE
});

export const openHotelDetailsScreen = (hotel?: IHotel) => ({
  payload: hotel,
  type: UI_HOTEL_DETAILS_SCREEN_OPEN
});

export const closeHotelDetailScreen = () => ({
  type: UI_HOTEL_DETAILS_SCREEN_CLOSE
});

export const toggleBasket = (switcher?: boolean) => (dispatch: Dispatch<any>) => {
  if (switcher) {
    dispatch(fetchBasket());
  }
  dispatch({
    payload: isBoolean(switcher) ? switcher : undefined,
    type: UI_BASKET_TOGGLE
  });
};

export const appLoaded = () => ({
  type: UI_APP_LOADED
});

export const changeTheme = () => (dispatch: Dispatch, getState: GetState) => {
  dispatch({
    type: UI_CHANGE_THEME
  });

  const themeState = getState().ui.theme;
  const root = document.documentElement;
  const theme = themeState === "dark" ? darkTheme : themeState === "light" ? lightTheme : darkTheme;

  forEach(theme, (value, key) => {
    root.style.setProperty("--" + key, value);
  });

  root.style.setProperty(
    "--priceTagWithOpacity",
    Color(theme.danger)
      .alpha(0.5)
      .toString()
  );

  root.style.setProperty(
    "--modalContainerBgAlternate",
    Color(theme.primaryBg)
      .alpha(0.6)
      .toString()
  );

  root.style.setProperty(
    "--modalContainerBg",
    Color(theme.primary)
      .alpha(0.6)
      .toString()
  );

  root.style.setProperty(
    "--boxBg",
    Color(theme.primaryBg)
      .alpha(0.8)
      .toString()
  );
  root.style.setProperty(
    "--headerShadow",
    Color(theme.primary)
      .alpha(0.4)
      .toString()
  );
  root.style.setProperty(
    "--primaryDarken",
    Color(theme.primary)
      .darken(0.2)
      .toString()
  );
  root.style.setProperty(
    "--primaryLighten",
    Color(theme.primary)
      .lighten(0.2)
      .toString()
  );
  root.style.setProperty(
    "--primaryBgDarken",
    Color(theme.primaryBg)
      .darken(0.2)
      .toString()
  );
  root.style.setProperty(
    "--secondaryTextLigthen",
    Color(theme.secondaryText)
      .lighten(0.2)
      .toString()
  );
  root.style.setProperty("--priceTagText", Color(theme.priceTagText).toString());
};

export const toggleCustomerScreen = (switcher?: boolean, checkoutPending?: boolean) => ({
  payload: {
    pending: checkoutPending,
    switcher
  },
  type: UI_CUSTOMER_SCREEN_TOGGLE
});

export const toggleCheckoutScreen: AC = (switcher?: boolean) => dispatch => {
  dispatch({
    payload: switcher,
    type: UI_CHECKOUT_SCREEN_TOGGLE
  });
  dispatch({
    type: BOOKING_RESET
  });
};

export const setCheckoutScreenData = (data: ICheckoutData, open?: boolean) => ({
  payload: {
    data,
    open
  },
  type: UI_CHECKOUT_SET_DATA
});

export const setCheckoutIsPending = (payload: boolean) => ({
  payload,
  type: UI_CHECKOUT_SET_PENDING
});

export const setCheckoutShouldBeClosed = () => ({
  type: UI_CHECKOUT_SET_SHOULD_CLOSE
});

export const setBasketData = (item: ItemTypes, action: string) => ({
  payload: {
    action,
    item
  },
  type: UI_BASKET_SET_DATA
});

export const setBasketIsPending = (payload: boolean) => ({
  payload,
  type: UI_BASKET_SET_PENDING
});

export const clearReset = () => ({
  type: UI_CLEAR_RESET
});

export const resetState: AC = () => dispatch => {
  dispatch({
    type: UI_RESET
  });
  dispatch({
    type: CUSTOMER_CLEAR
  });
  dispatch({
    type: SEARCH_RESET
  });
};
