import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ITransfer } from "../../interfaces/ITransfer";

interface IPaymentTransferComponentProps {
  transfer: ITransfer;
}
export default function PaymentTransfer({ transfer }: IPaymentTransferComponentProps) {
  return (
    <div className="other-item flex a-center">
      <div className="item-icon">
        <FontAwesomeIcon icon={["far", "passport"]} />
      </div>
      <div className="contents full-width">
        <div className="content flex a-center">
          <p className="fs-med">{transfer.title}</p>
          <p className="fs-med">{transfer.description}</p>
        </div>
      </div>
    </div>
  );
}
