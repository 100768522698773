import { useField, ErrorMessage } from "formik";
import React, { FC, memo, useEffect, useState } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

type Props = {
  name: string;
  initialCode?: string;
} & NumberFormatProps;

const PhoneField: FC<Props> = ({ name, initialCode = 90 }) => {
  const [{ value }, , { setValue, setTouched }] = useField(name);
  const [code, setCode] = useState(() => value?.split?.(" ")?.[0] || initialCode);
  const [no, setNo] = useState(
    () =>
      value
        ?.split(" ")
        ?.slice(1)
        .join(" ") ?? ""
  );

  useEffect(() => {
    if (code.length && no.length === 10) {
      setValue(`+${code}${no}`);
    } else {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, no]);
  return (
    <div className="field">
      <div className="phone-field">
        <NumberFormat
          className="phone-code"
          defaultValue={code}
          placeholder="+"
          maxLength={4}
          prefix="+"
          onValueChange={values => setCode(values.value)}
        />
        <NumberFormat
          defaultValue={no}
          format="(###) ### ## ##"
          placeholder="(___) ___ __ __"
          mask="_"
          onValueChange={values => setNo(values.value)}
          onBlur={() => setTouched(true)}
        />
      </div>
      <ErrorMessage className="input-helper danger" name={name} component="span" />
    </div>
  );
};

export default memo(PhoneField);
