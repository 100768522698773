import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import useCounter from "../hooks/useCounter";
import Modal from "../libs/Modal";

interface IProps {
  seconds: number;
  message?: string;

  onFinish(): void;
}

const Countdown: FC<IProps> = ({ seconds, message, onFinish }) => {
  const counter = useCounter(seconds);
  const [messageIsActive, setMessageIsActive] = useState(false);

  const finishHandler = useCallback(() => {
    if (message) {
      setMessageIsActive(true);
    } else {
      onFinish();
    }
  }, [message, onFinish]);

  useEffect(() => {
    if (!counter) {
      finishHandler();
    }
  }, [counter, finishHandler]);

  const closeMessage = useCallback(() => {
    setMessageIsActive(false);
    onFinish();
  }, [onFinish]);

  const result = useMemo(() => {
    return new Date(counter * 1000).toISOString().substr(14, 5);
  }, [counter]);

  return (
    <>
      {messageIsActive && (
        <Modal.Container bordered onClose={closeMessage}>
          <Modal.Body>
            <p className="countdown-message">{message}</p>
          </Modal.Body>
        </Modal.Container>
      )}
      <p data-tip="Remaning time to complete payment" className="countdown-clock self-flex-end">
        {result}
      </p>
    </>
  );
};

export default Countdown;
