import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

type Props = {
  checked: boolean;
  description?: string;
  onCheck(e: any): void;
};

const Checker: FC<Props> = ({ checked, description, onCheck }) => {
  return (
    <button className="checker" onClick={onCheck}>
      {checked ? (
        <FontAwesomeIcon icon={["fas", "check-square"]} />
      ) : (
        <FontAwesomeIcon icon={["far", "square"]} />
      )}
      <p className="fs-std">{description}</p>
    </button>
  );
};

export default Checker;
