import entries from "lodash-es/entries";
import flatten from "lodash-es/flatten";
import groupBy from "lodash-es/groupBy";
import head from "lodash-es/head";
import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";
import keys from "lodash-es/keys";
import map from "lodash-es/map";
import max from "lodash-es/max";
import min from "lodash-es/min";
import reduce from "lodash-es/reduce";
import sortBy from "lodash-es/sortBy";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import toSafeInteger from "lodash-es/toSafeInteger";
import { createSelector } from "reselect";
import { ICar } from "../../interfaces/ICar";
import { IStoreState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import { getFiltersCar } from "./filterSelectors";

export function getCarState(state: IStoreState) {
  return state.carState;
}

export const getCarVendors = createSelector(getCarState, state => state.vendors);

export const getCars = createSelector(getCarVendors, vendors =>
  flatten(map(vendors, vendor => vendor.items))
);

export const getCarsSortedByPrice = createSelector(getCars, cars => sortBy(cars, "Amount"));

export const getCarsGroupedBySegment = createSelector(getCarsSortedByPrice, cars =>
  groupBy(cars, "VehicleCategory")
);

export const getCarsCheapestForEachSegment = createSelector(getCarsGroupedBySegment, cars =>
  flatten(map(cars, items => head(items)!))
);

export const getCarsStatuses = createSelector(getCarVendors, vendors =>
  map(vendors, (vendor, vendorKey) => {
    const status = vendor.status;
    status.serviceKey = vendorKey;
    return status;
  })
);
export const getCarSearchDone = createSelector(getCarsStatuses, statuses => {
  return !!statuses.length && statuses.every(s => !s.fetching && s.fetched);
});

export const getCarsResultEmpty = createSelector(
  getCars,
  getCarSearchDone,
  (cars, searchDone) => !cars.length && searchDone
);

export const getCarCurrency = createSelector(getCars, cars => {
  return cars.length ? cars[0].Currency : "TRY";
});

export const getCarPrices = createSelector(getCarsSortedByPrice, cars =>
  flatten(cars.map(car => toSafeInteger(car.Amount)))
);

export const getCarMinPrice = createSelector(getCarPrices, prices => {
  const price = min(prices) || 0;
  return price - (price % 50);
});

export const getCarMaxPrice = createSelector(getCarPrices, prices => {
  const price = max(prices) || 0;
  return price - (price % 50) + 50;
});

export const getFilteredCars = createSelector(getCarsSortedByPrice, getFiltersCar, (cars, filters) => {
  return cars.filter(car => {
    return (
      car.Amount <= filters.price.max + 10 &&
      car.Amount >= filters.price.min - 10 &&
      (toSafeInteger(car.NumberOfDoor) >= filters.doors || filters.doors === 0) &&
      (toSafeInteger(car.seats) === filters.seats || filters.seats === 0) &&
      (car.TransmissionType === filters.transmission || filters.transmission === "all")
    );
  });
});

export const getSearchedCars = createSelector(getFilteredCars, getFiltersCar, (cars, filters) => {
  const { input, type, searchable } = filters.search;
  if (input.length < 3) {
    return cars;
  }

  const keywords = input.split(" ");

  return cars.filter(car =>
    keywords.every(word =>
      keys(car).some(param => {
        if (car[param] && includes(searchable, param)) {
          return type === "startsWith"
            ? startsWith(toLower(car[param]), toLower(word))
            : includes(toLower(car[param]), toLower(word));
        }
        return false;
      })
    )
  );
});

export const getGroupedCars = createSelector(getSearchedCars, cars => {
  return entries(
    reduce(
      cars,
      (grouped, car) => {
        if (car.VehicleCategory) {
          if (isArray(grouped[car.VehicleCategory])) {
            grouped[car.VehicleCategory].push(car);
          } else {
            grouped[car.VehicleCategory] = [car];
          }
        } else {
          if (isArray(grouped[car.DistUnitName])) {
            grouped[car.DistUnitName].push(car);
          } else {
            grouped[car.DistUnitName] = [car];
          }
        }
        return grouped;
      },
      {} as Dictionary<ICar[]>
    )
  );
});

export const getCarUi = createSelector(getCarState, state => state.ui);

export const getCarModalIsOpen = createSelector(getCarUi, ui => ui.modalIsOpen);

export const getCarsSearch = createSelector(getCarState, state => state.search);
