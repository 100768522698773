import Axios, { AxiosRequestConfig } from "axios";
import merge from "lodash-es/merge";
import { logout } from "../store/actions/userActions";
import store from "../store";

type ApiOptions = {
  auth?: boolean;
  url?: string;
  self?: boolean;
  absolute?: boolean;
  config?: AxiosRequestConfig;
  token?: string;
};

export const api = (options?: ApiOptions) => {
  const token = localStorage.getItem("api_token");

  const config: AxiosRequestConfig = merge(
    {
      headers: {}
    },
    options?.config
  );

  if (options?.auth !== false) {
    if (token) {
      config.headers["Authorization-Key"] = token;
    }
  }

  if (options?.token) {
    config.headers["Authorization-Key"] = options.token;
  }

  if (!options?.absolute) {
    if (options?.self) {
      config.baseURL = "/";
    } else if (options?.url) {
      config.baseURL = options.url;
    } else {
      config.baseURL =
        process.env.NODE_ENV === "production" && process.env.REACT_APP_DEPLOY_TARGET === "TS"
          ? "https://hs-services-prod.appspot.com"
          : "https://hezarfenservices-dev.appspot.com/";
    }
  }

  const instance = Axios.create(config);

  instance.interceptors.request.use(request => {
    if (request.url) {
      if (request.url.includes("amadeusflights")) {
        request.headers.AmadeusCookie = localStorage.getItem(`${window.tabId}_amadeusFlightCookie`);
      }
      if (request.url.includes("amadeushotels")) {
        request.headers.AmadeusCookie = localStorage.getItem(`${window.tabId}_amadeusHotelCookie`);
      }
      if (request.url.includes("amadeusrentacar")) {
        request.headers.AmadeusCookie = localStorage.getItem(`${window.tabId}_amadeusRentACarCookie`);
      }
      if (request.url.includes("gethotelrooms")) {
        request.headers.AmadeusCookie = localStorage.getItem(`${window.tabId}_amadeusHotelCookie`);
      }
      if (request.url.includes("gethoteldetail")) {
        request.headers.AmadeusCookie = localStorage.getItem(`${window.tabId}_amadeusHotelCookie`);
      }
    }
    return request;
  });

  instance.interceptors.response.use(
    response => {
      if (response.headers["authorization-key"]) {
        localStorage.setItem("api_token", response.headers["authorization-key"]);
      }
      if (response.headers.amadeuscookie && response.config.url) {
        if (response.config.url.includes("amadeusflights")) {
          localStorage.setItem(`${window.tabId}_amadeusFlightCookie`, response.headers.amadeuscookie);
        }
        if (response.config.url.includes("amadeushotels")) {
          localStorage.setItem(`${window.tabId}_amadeusHotelCookie`, response.headers.amadeuscookie);
        }

        if (response.config.url.includes("amadeusrentacar")) {
          localStorage.setItem(`${window.tabId}_amadeusRentACarCookie`, response.headers.amadeuscookie);
        }
      }
      return response;
    },
    async error => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        await store.dispatch<any>(logout());
        throw error;
      }
    }
  );
  return instance;
};
