import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOfferApprove } from "../interfaces/IApprove";
import { api } from "../services/api";
import ApproveBudget from "./ApproveBudget";
import ApproveFlight from "./ApproveFlight";
import ApproveHotel from "./ApproveHotel";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";
import OfferCustomer from "./OfferCustomer";
import { Busy } from "./snippets/Busy";

interface IProps {
  offer: IOfferApprove;
}

const OfferApproveDetailed: FC<IProps> = ({ offer }) => {
  const { t, i18n } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);

  const approveOffer = useCallback(() => {
    const payload: any = {
      cust_guid: offer.cust[0].cust_guid,
      userguid: offer.user_guid
    };
    if (offer.basket_guid !== "DIRECT") {
      payload.basket_guid = offer.basket_guid;
    } else {
      payload.offer_guid = offer.offer_guid;
    }
    setIsBusy(true);
    api()
      .post("offerapprove", payload)
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [offer]);

  const rejectOffer = useCallback(() => {
    const payload: any = {
      cust_guid: offer.cust[0].cust_guid,
      description: "",
      userguid: offer.user_guid
    };
    if (offer.basket_guid !== "DIRECT") {
      payload.basket_guid = offer.basket_guid;
    } else {
      payload.offer_guid = offer.offer_guid;
    }
    setIsBusy(true);
    api()
      .post("offerreject", payload)
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [offer]);

  const renderItems = useMemo(() => {
    const items: any = [];
    const { flights = [], hotels = [], visas = [], transfers = [] } = offer.offers || offer;
    if (flights.length && flights[0].airitinprice) {
      flights.forEach((flight, index) => {
        items.push(<ApproveFlight flight={flight} diff={offer.limitDiff} key={index + "-0"} />);
      });
    }
    if (hotels.length) {
      hotels.forEach((hotel, index) => {
        items.push(<ApproveHotel hotel={hotel} diff={offer.limitDiff} key={index + "-1"} />);
      });
    }
    if (visas.length) {
      visas.forEach((visa, index) => {
        items.push(
          <div key={index + "-2"}>
            <p>visa: {visa.offer_guid}</p>
          </div>
        );
      });
    }
    if (transfers.length) {
      transfers.forEach((transfer, index) => {
        items.push(
          <div key={index + "-3"}>
            <p>transfer: {transfer.offer_guid}</p>
          </div>
        );
      });
    }
    return items;
  }, [offer]);

  // const firstPending = useMemo(() => {
  //   const policy = offer.cust[0].policy;
  //   if (policy && policy.budget.pending.constructor === Array) {
  //     return policy.budget.pending[0];
  //   }
  //   if (policy) {
  //     return policy.budget.pending;
  //   }
  //   return {
  //     currency: "",
  //     limit: "Not Set"
  //   };
  // }, [offer]);

  return (
    <>
      <div className="pnr-actions">
        <ButtonWithConfirm
          className="order-action-button bc-success text fs-lrg"
          message={t("generic.confirmAction")}
          actions={[
            {
              color: "success",
              handler: approveOffer,
              text: t("offer.button.approve")
            }
          ]}
        >
          <span>{t("offer.button.approve")}</span>
        </ButtonWithConfirm>
        <ButtonWithConfirm
          className="order-action-button bc-danger text fs-lrg"
          message={t("generic.confirmAction")}
          actions={[
            {
              color: "success",
              handler: rejectOffer,
              text: t("offer.button.reject")
            }
          ]}
        >
          <span>{t("offer.button.reject")}</span>
        </ButtonWithConfirm>
        <span className="fill-space is-tablet-only" />
      </div>
      <div className="basket-items full-height pnr-list order-detailed">
        {isBusy && <Busy />}
        <div className="order-items-list pnr-details">
          <ApproveBudget customers={offer.cust} departmentSpent={offer.totalDepartment} />
          <div className="pnr-items">
            <div className="offer-customers">
              {offer.cust.map(c => (
                <OfferCustomer key={c.cust_guid} customer={c} />
              ))}
            </div>
          </div>
          <div className="pnr-items">
            {offer.travelTitle && (
              <div className="travel-reason">
                <span className="bold">{t("offerApprove.reason")}:&nbsp;</span>
                <p>{offer.travelTitle[i18n.language]}</p>
              </div>
            )}
            {renderItems}
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferApproveDetailed;
