import React, { FC } from "react";

interface IProps {
  color: string;
}

const RouteSvgMedia: FC<IProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 408.6 56.8">
    <title>Route</title>
    <g>
      <line
        x1="7.5"
        y1="42"
        x2="402.4"
        y2="42"
        fill="none"
        stroke={color}
        style={{
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 5
        }}
      />
      <line
        x1="7.5"
        y1="54.3"
        x2="402.4"
        y2="54.3"
        stroke={color}
        fill="none"
        style={{
          opacity: 0.1,
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 5
        }}
      />
      <path
        d="M12.3,41.6c0,3.4-2.8,6.1-6.2,6.1S0,45,0,41.6c0-3.4,2.8-6.1,6.2-6.1S12.3,38.2,12.3,41.6L12.3,41.6z"
        fill={color}
      />
      <circle cx="402.4" cy="41.6" r="6.1" fill={color} />
      <path
        d="M15.6,35.4c0,0,1.4-0.4,3.8-1.2"
        fill="none"
        stroke="#bcbec0"
        style={{
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.01
        }}
      />
      <path
        d="M27.1,32.1C55.5,24.3,132.2,5.3,208,5.3c78.9,0,153.2,20.6,176.7,27.7"
        fill="none"
        stroke="#bcbec0"
        style={{
          strokeDasharray: "7.97, 7.97",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.01
        }}
      />
      <path
        d="M388.5,34.2c2.5,0.8,3.8,1.2,3.8,1.2"
        fill="none"
        stroke="#bcbec0"
        style={{
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 2.01
        }}
      />
      <path
        d="M162.8,9.1l-2.5,0.4l-3.2,7.3c-0.1,0.1-0.2,0.2-0.3,0.2H155c-0.1,0-0.1,0-0.2-0.1c0,0,0,0-0.1-0.1
		c-0.1-0.1-0.1-0.2-0.1-0.3l0.9-6.4l-4,0.6l-0.8,2.1c0,0.1-0.2,0.2-0.3,0.2l-1.5,0.2c-0.1,0-0.2,0-0.3-0.1l0,0
		c-0.1-0.1-0.1-0.2-0.1-0.3V9.4l-1-3.3c0-0.1,0-0.2,0-0.3c0.1-0.1,0.1-0.1,0.2-0.2l1.5-0.2c0.1,0,0.2,0,0.3,0.1l0.1,0.1l1.4,1.8
		l4-0.6l-2.6-5.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.3-0.2l2-0.3c0.1,0,0.2,0,0.3,0.1l4.9,5.8l2.5-0.4c1.5-0.2,3.3,0.3,3.5,1.3
		S164.3,8.8,162.8,9.1z"
        fill={color}
      />
    </g>
  </svg>
);

export default RouteSvgMedia;
