import flatten from "lodash-es/flatten";
import map from "lodash-es/map";
import mapKeys from "lodash-es/mapKeys";
import pick from "lodash-es/pick";
import values from "lodash-es/values";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBasket, IBasketFlight, IVisa } from "../interfaces/IBasket";
import { IBookingOptions } from "../interfaces/IBooking";
import { IDestination } from "../interfaces/IFlight";
import { IRoom } from "../interfaces/IHotel";
import { api } from "../services/api";
import Logger from "../services/Logger";
import { formatTime, orderByKeyBasket, pwc, uniqid } from "../utils/helpers";
import OrderFlight from "./OrderFlight";
import { OrderOther } from "./OrderOther";
import OrderRoom from "./OrderRoom";
import { Busy } from "./snippets/Busy";

interface IProps {
  basket: IBasket;
  bookingOffer(payload: IBookingOptions): void;
  fetchOrderList(): void;
}

const OrderDetailed: FC<IProps> = ({ basket, bookingOffer, fetchOrderList }) => {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);

  const destinations = useMemo(() => {
    if (basket.flights && basket.flights.length) {
      return mapKeys(
        flatten(
          basket.flights.map(flight => {
            return flight.airitin.origdests!.map(
              (dest: IDestination, key): IBasketFlight => ({
                ...dest,
                Currency: flight.Currency,
                OfferId: flight.OfferId,
                direction: key ? "returning" : "going",
                price: flight.airitinprice,
                roundTrip: !key,
                status: flight.status
              })
            );
          })
        ),
        (dest: IDestination) => dest.DepDate + "_" + formatTime(dest.DepTime) + "_flight_" + uniqid()
      );
    }
    return {};
  }, [basket.flights]);

  const rooms = useMemo(() => {
    if (basket.hotels && basket.hotels.length) {
      return mapKeys(
        basket.hotels,
        (room: IRoom) => room.CheckInDate + "_" + formatTime(room.CheckInTime) + "_room_" + uniqid()
      );
    }
    return {};
  }, [basket.hotels]);

  const otherServices = useMemo((): IVisa[] => {
    return flatten(values(pick(basket, ["visas"])) as any).filter(x => x) as any;
  }, [basket]);

  const items = useMemo(() => {
    return orderByKeyBasket(destinations, rooms);
  }, [destinations, rooms]);

  const listedItems = useMemo(() => {
    return map(items, (item: any, key) => {
      if (key.indexOf("flight") > -1) {
        return <OrderFlight key={key} flight={item} custguid={basket.custguid} bookingOffer={bookingOffer} />;
      }
      if (key.indexOf("room") > -1) {
        return <OrderRoom key={key} room={item} custguid={basket.custguid} bookingOffer={bookingOffer} />;
      }
      return;
    });
  }, [basket.custguid, bookingOffer, items]);

  const total = useMemo(() => {
    return pwc(basket.total, basket.currency);
  }, [basket.total, basket.currency]);

  const setBasketStatus = async (status: "READY" | "PENDING" | "EXPIRED") => {
    setIsBusy(true);
    const log = new Logger(t("order.basketPending"));
    try {
      await api().put("basket", { basket_guid: basket.basketguid, status });
      log.success(t("order.basketSuccess"));
      fetchOrderList();
    } catch {
      log.success(t("order.basketError"));
    }
    setIsBusy(false);
  };

  return (
    <div className="basket-items full-height pnr-list order-detailed">
      {isBusy && <Busy />}
      <div className="order-action-buttons flex">
        <button className="order-action-button" onClick={() => setBasketStatus("READY")}>
          {t("order.button.current")}
        </button>
        <button className="order-action-button" onClick={() => setBasketStatus("PENDING")}>
          {t("order.button.hold")}
        </button>
        <button className="order-action-button" onClick={() => setBasketStatus("EXPIRED")}>
          {t("order.button.expire")}
        </button>
        <span className="fill-space" />
        <p className="order-total">
          {t("generic.total")}: {total}
        </p>
      </div>
      <div className="order-items-list">
        {listedItems}
        {otherServices.map(service => (
          <OrderOther item={service} key={service.offer_guid} />
        ))}
      </div>
    </div>
  );
};

export default OrderDetailed;
