import pick from "lodash-es/pick";
import { createSelector } from "reselect";
import { IPassengerCount } from "../../interfaces/ISearch";
import { IStoreState } from "../../interfaces/IState";
import { findAirportByCode } from "../../utils/airportFunctions";
import { defaultSearchState } from "../../utils/defaults";
import { getBasketIsActive } from "./uiSelectors";

export const getSearchState = (state: IStoreState) => state.search;

export const getSearchUI = createSelector(getSearchState, (searchState) => searchState.ui);

export const getSearchIsMade = createSelector(getSearchState, (searchState) => searchState.searchIsMade);

export const getSearchHistory = createSelector(getSearchState, (searchState) => searchState.history);

export const getLastSearch = createSelector(getSearchHistory, (history) => {
  const lastSearch = history[0];
  return lastSearch ? lastSearch : defaultSearchState;
});

export const getIsShrinked = createSelector(
  getSearchUI,
  getBasketIsActive,
  (ui, isActive) => ui.shrinked || isActive
);

export const getCurrentRoute = createSelector(getLastSearch, (search) => ({
  from: findAirportByCode(search.orig),
  to: findAirportByCode(search.dest),
}));

export const isDoubleSearch = createSelector(getLastSearch, (search) => search.ftype === "OWC");
// export const isDoubleSearch = createSelector(getLastSearch, search => true);

export const getHasPackage = createSelector(getLastSearch, (search) => search.filters.hasPackages);

export const getLastSearchPassengerCount = createSelector(
  getLastSearch,
  (lastSearch) => pick(lastSearch, ["adt", "chd", "inf"]) as IPassengerCount
);
