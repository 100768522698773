import i18n from "i18next";
import { IAiritinprice, IFlight } from "../../interfaces/IFlight";
import { ISearch } from "../../interfaces/ISearch";
import { IService } from "../../interfaces/IService";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  FLIGHT_RESET,
  FLIGHT_FETCH_EMPTY,
  FLIGHT_FETCH_FULFILLED,
  FLIGHT_FETCH_PENDING,
  FLIGHT_FETCH_REJECTED,
  FLIGHT_ALTERNATIVE_SELECT
} from "../../utils/constants";
import { getLastSearch } from "../selectors/searchSelectors";
import { getFlightServices } from "../selectors/serviceSelectors";
import { filterReset } from "./filterActions";

function parseSearch(search: ISearch): [ISearch, ISearch?] {
  if (search.ftype === "OWC") {
    const parsed: ISearch = {
      ...search,
      ftype: search.ftype === "OWC" ? "OW" : search.ftype,
      guid: search.guid + "o"
    };

    const reversedParsed: ISearch = {
      ...search,
      arrCity: search.depCity,
      depCity: search.arrCity,
      dest: search.orig,
      fdate: search.rdate,
      ftype: search.ftype === "OWC" ? "OW" : search.ftype,
      guid: search.guid + "r",
      orig: search.dest,
      routes: [
        {
          ...search.routes[0],
          arr: search.routes[0].dep,
          date: search.rdate,
          dep: search.routes[0].arr
        }
      ]
    };
    return [parsed, reversedParsed];
  }
  return [search];
}

export const fetchFlight: AC = (search: ISearch, service: IService, reversed = false) => async dispatch => {
  const log = new Logger(i18n.t("flight.searchPending"), {
    prefix: service.key
  });

  dispatch({
    service: service.key,
    type: `${service.key}_${FLIGHT_FETCH_PENDING}`
  });

  try {
    const { data } = await api({
      absolute: service.fullLink
    }).post(service.uri, search);
    if (data.flights?.length) {
      dispatch({
        payload: {
          flights: data.flights.map((flight: IFlight) => {
            flight.returning = reversed;
            return flight;
          })
        },
        service: service.key,
        type: `${service.key}_${FLIGHT_FETCH_FULFILLED}`
      });
      log.success(i18n.t("flight.searchSuccess"));
    } else {
      dispatch({
        service: service.key,
        type: `${service.key}_${FLIGHT_FETCH_EMPTY}`
      });
      log.warning(i18n.t("flight.searchEmpty"));
    }
  } catch (e) {
    dispatch({
      service: service.key,
      type: `${service.key}_${FLIGHT_FETCH_REJECTED}`
    });
    log.error(e);
  }
};

export const fetchAllFlights: AC = () => (dispatch, getState) => {
  const state = getState();
  const lastSearch = getLastSearch(state);
  const services = getFlightServices(state);

  const [search, reversedSearch] = parseSearch(lastSearch);

  dispatch(filterReset());

  for (const service of services) {
    dispatch(fetchFlight(search, service));
  }

  if (reversedSearch) {
    for (const service of services) {
      dispatch(fetchFlight(reversedSearch, service, true));
    }
  }
  // dispatch(setFlightFilterPrice([0, 100000]));
};

export const resetFlightState = (services: IService[]) => ({
  payload: services,
  type: FLIGHT_RESET
});

export const selectFlightAlternative = (offerid: string, alternative: IAiritinprice) => ({
  payload: { offerid, alternative },
  type: FLIGHT_ALTERNATIVE_SELECT
});
