import React, { FC } from "react";
import { ErrorBoundary } from "../components/ErrorBoundary";
import DefaultLayout from "./DefaultLayout";

type Props = {};

const AdminLayout: FC<Props> = ({ children }) => {
  return (
    <DefaultLayout>
      <div className="container main admin-page">
        <div className="admin-page">
          <div className="admin-container">
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AdminLayout;
