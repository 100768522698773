import keys from "lodash-es/keys";
import pickBy from "lodash-es/pickBy";
import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getFilterState = (state: IStoreState) => state.filters;

export const getFiltersPnr = createSelector(getFilterState, filterState => filterState.pnrs);

export const getFiltersPnrItems = createSelector(getFiltersPnr, filters =>
  keys(pickBy(filters.items, Boolean))
);

export const getFiltersPnrInput = createSelector(getFiltersPnr, filtersPnr => filtersPnr.search.input);

export const getFiltersPnrSearch = createSelector(getFiltersPnr, filters => filters.search);

export const getFlightFilters = createSelector(getFilterState, filterState => filterState.flights);

export const getFlightFilterAirlines = createSelector(getFlightFilters, filters => filters.airlines);

export const getFlightFilterPrices = createSelector(getFlightFilters, filters => [0, 99999999]);

export const getFlightFilterStops = createSelector(getFlightFilters, filters => filters.stops ?? []);

export const getFlightFilterAirports = createSelector(getFlightFilters, filters => filters.airports ?? []);

export const getFlightFilterDepartureTime = createSelector(getFlightFilters, filters => [
  filters.depTime.min,
  filters.depTime.max
]);

export const getFlightFilterReturnTime = createSelector(getFlightFilters, filters => [
  filters.arrTime.min,
  filters.arrTime.max
]);

export const getFlightFilterSearch = createSelector(getFlightFilters, filters => filters.search);

export const getFlightSorter = createSelector(getFlightFilters, filters => filters.sortBy);

export const getFilterFlightSortBy = createSelector(getFlightFilters, filters => filters.sortBy);

export const getFiltersFlightDefaults = createSelector(
  getFilterState,
  filterState => filterState.defaults.flights
);

export const getFiltersHotel = createSelector(getFilterState, filterState => filterState.hotels);

export const getFilterHotelInput = createSelector(getFiltersHotel, filtersHotel => filtersHotel.search.input);

export const getFiltersActivity = createSelector(getFilterState, filterState => filterState.activities);

export const getFiltersActivitySortBy = createSelector(getFiltersActivity, filterState => filterState.sortBy);

export const getFiltersActivityDefaults = createSelector(
  getFilterState,
  filterState => filterState.defaults.activities
);

export const getFiltersHotelDefaults = createSelector(
  getFilterState,
  filterState => filterState.defaults.hotels
);

export const getFilterHotelSortBy = createSelector(getFiltersHotel, filters => filters.sortBy);

export const getFiltersCar = createSelector(getFilterState, filterState => filterState.cars);

export const getFiltersTransfer = createSelector(getFilterState, filterState => filterState.transfers);

export const getFiltersCarDefaults = createSelector(getFilterState, filterState => filterState.defaults.cars);
