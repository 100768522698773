import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import times from "lodash-es/times";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyGeneric } from "../../../../interfaces/IPolicy";
import { hasPolicy, uniqid } from "../../../../utils/helpers";
import PolicyFilterRule from "./PolicyFilterRule";

interface IProps extends IPolicyGeneric {
  ratings?: number[];
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyHotelRating: FC<IProps> = ({ onChange, ratings = [1, 2, 3, 4, 5], ...p }) => {
  const { t } = useTranslation();

  const toggleItem = (code: string, sc: "excluded" | "included") => {
    const data = p[sc].map(x => x.code).includes(code)
      ? p[sc].filter(x => x.code !== code)
      : [...p[sc], { code }];

    onChange!({
      data,
      key: `hotels.rating.${sc}`
    });
  };

  const filterRuleHandler = (sc: "isFilter" | "isRule") => {
    onChange!({
      data: !p[sc],
      key: `hotels.rating.${sc}`
    });
  };

  return (
    <div>
      <div className="airlines excluded">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.excluded")}</h4>
        </div>
        <div className="flex">
          {ratings.map(r => (
            <p
              key={r}
              onClick={() => toggleItem(r.toString(), "excluded")}
              className={`cluded-item star${hasPolicy(r, p.excluded, "is-selected")}`}
            >
              {times(r, () => (
                <FontAwesomeIcon key={uniqid()} color="#FFAD00" icon={["fas", "star"]} />
              ))}
            </p>
          ))}
        </div>
      </div>
      <div className="airlines included">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.included")}</h4>
          <div className="airline-search" />
        </div>
        <div className="flex">
          {ratings.map(r => (
            <p
              key={r}
              onClick={() => toggleItem(r.toString(), "included")}
              className={`cluded-item star${hasPolicy(r, p.included, "is-selected")}`}
            >
              {times(r, () => (
                <FontAwesomeIcon key={uniqid()} color="#FFAD00" icon={["fas", "star"]} />
              ))}
            </p>
          ))}
        </div>
      </div>
      <PolicyFilterRule filter={p.isFilter} rule={p.isRule} onChange={filterRuleHandler} />
    </div>
  );
};

PolicyHotelRating.defaultProps = {
  excluded: [],
  included: [],
  isFilter: false,
  isRule: false
};

export default PolicyHotelRating;
