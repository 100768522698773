import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import isBoolean from "lodash-es/isBoolean";
import toLower from "lodash-es/toLower";
import toString from "lodash-es/toString";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import ButtonWithConfirm from "../../../components/confirmation/ButtonWithConfirm";
import AmenityList from "../../../components/snippets/AmenityList";
import { Busy } from "../../../components/snippets/Busy";
import { ICheckoutData } from "../../../interfaces/IBooking";
import { IRoom } from "../../../interfaces/IHotel";
import { resetAndAddtoBasket } from "../../../store/actions/basketActions";
import {
  setBasketData,
  setBasketIsPending,
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen,
  toggleHotelDetailsScreen
} from "../../../store/actions/uiActions";
import { getProvisionRejectedRooms } from "../../../store/selectors/provisionSelectors";
import { getUserRole } from "../../../store/selectors/userSelectors";
import { ReduxDispatch } from "../../../types";
import { pwc } from "../../../utils/helpers";
import { HotelContext } from "../index";
import DetailsRoomBoard from "./DetailsRoomBoard";
import UpdateRoomModal from "./UpdateRoomModal";

interface IProps {
  isInternational: boolean;
  customersValidForBooking: boolean;
  customersValidForBasket: boolean;
  rooms: IRoom[];
  type: string;
}

const DetailsRooms: FC<IProps> = ({ isInternational, customersValidForBooking, rooms, type }) => {
  const { t } = useTranslation();
  const role = useSelector(getUserRole);
  const rejectedRooms = useSelector(getProvisionRejectedRooms);
  const { selectedRoom } = useContext(HotelContext);
  const dispatch = useDispatch<ReduxDispatch>();
  const [isBusy, setIsBusy] = useState(false);
  const [editIsActive, setEditIsActive] = useState(false);

  const isAvailable: any = useCallback(
    (room: IRoom) => {
      return [!rejectedRooms[room.OfferId], toString(rejectedRooms[room.OfferId])];
    },
    [rejectedRooms]
  );

  const canUpdate = useMemo(() => {
    return toLower(role) === "admin" || toLower(role) === "agency";
  }, [role]);

  const toggleEdit = useCallback(() => {
    if (canUpdate) {
      setEditIsActive(x => !x);
    }
  }, [canUpdate]);

  const fallbackSelected = useMemo(() => {
    return rooms[0];
  }, [rooms]);

  const hasSelected = useMemo(() => {
    return Boolean(rooms.find(room => selectedRoom && room.OfferId === selectedRoom.OfferId));
  }, [selectedRoom, rooms]);

  const refundable = useMemo(() => {
    if (selectedRoom && isBoolean(selectedRoom.isNonRefundable)) {
      return selectedRoom.isNonRefundable ? t("generic.nonrefundable") : t("generic.refundable");
    }
    return false;
  }, [selectedRoom, t]);

  const checkoutData: ICheckoutData | null = useMemo(() => {
    if (!selectedRoom) {
      return null;
    }
    return {
      actionType: "hotel",
      amount: selectedRoom!.AmountAfterTax,
      currency: selectedRoom!.HotelCurrency,
      guid: selectedRoom!.OfferId,
      isInternational,
      items: { hotels: [selectedRoom!] }
    };
  }, [selectedRoom, isInternational]);

  const cxRefundable = classNames({
    "non-refundable": selectedRoom && isBoolean(selectedRoom.isNonRefundable) && selectedRoom.isNonRefundable,
    refundable: selectedRoom && isBoolean(selectedRoom.isNonRefundable) && !selectedRoom.isNonRefundable
  });

  const cx = classNames("details-room", {
    selected: hasSelected
  });

  const cxPrice = classNames("price-tag fs-prc is-centered", {
    "has-edit pointer": canUpdate
  });

  return (
    <div className="details-room-box">
      {editIsActive && <UpdateRoomModal room={selectedRoom!} onClose={toggleEdit} canUpdate={canUpdate} />}
      {isBusy && <Busy />}
      <div className={cx}>
        <div className="details-room-header">
          {hasSelected && (
            <p className="tags self-baseline fs-med bold">
              {refundable && <span className={cxRefundable}>{refundable}</span>}
              {/*{selectedRoom!.rateType && (*/}
              {/*  <span>*/}
              {/*    <br />*/}
              {/*    {selectedRoom!.rateType}*/}
              {/*  </span>*/}
              {/*)}*/}
              {/*{fallbackSelected.isProvisionNeeded && <span>, PROVISION NEEDED</span>}*/}
            </p>
          )}

          <p className="room-type">{type}</p>
          <p className="allotment">
            {t("admin.room.only")} <span>+{fallbackSelected.allotment}</span> {t("admin.room.left")}
          </p>
        </div>
        <div className="room-boards">
          {rooms.map(room => (
            <DetailsRoomBoard key={room.OfferId} room={room} availability={isAvailable(room)} />
          ))}
        </div>
        <div className="details-room-footer">
          {/*<p className="fs-std" dangerouslySetInnerHTML={{ __html: fallbackSelected.RoomDescription }} />*/}
          <div className="amenity">
            <AmenityList small amenities={fallbackSelected.JSONamenity} only={["smoking", "wifi"]} />
          </div>
        </div>
      </div>
      {hasSelected && (
        <div className="details-payment">
          <div className="controllers">
            <p className={cxPrice} onClick={toggleEdit}>
              {pwc(selectedRoom!.AmountAfterTax, selectedRoom!.HotelCurrency, false)}
              {canUpdate && <FontAwesomeIcon icon={["fas", "edit"]} />}
            </p>
            <ButtonWithConfirm
              actions={[
                {
                  byPassAction: true,
                  color: "success",
                  handler() {
                    if (selectedRoom) {
                      setIsBusy(true);
                      dispatch(resetAndAddtoBasket(selectedRoom, "hotel"))
                        .then(() => {
                          setIsBusy(false);
                          dispatch(toggleHotelDetailsScreen());
                        })
                        .catch(() => setIsBusy(false));
                    }
                  },
                  text: "Proceed"
                },
                {
                  color: "success",
                  handler() {
                    batch(() => {
                      dispatch(setBasketData(selectedRoom!, "hotel"));
                      dispatch(toggleCustomerScreen());
                      dispatch(setBasketIsPending(true));
                    });
                  },
                  text: "Check Customers"
                }
              ]}
              className="button cart"
              message={t("customer.notValidBooking")}
              byPass={customersValidForBooking}
            >
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
            </ButtonWithConfirm>
            <ButtonWithConfirm
              actions={[
                {
                  byPassAction: true,
                  color: "success",
                  handler() {
                    dispatch(setCheckoutScreenData(checkoutData!, true));
                  },
                  text: "Proceed"
                },
                {
                  color: "success",
                  handler() {
                    batch(() => {
                      dispatch(setCheckoutScreenData(checkoutData!));
                      dispatch(toggleCustomerScreen());
                      dispatch(setCheckoutIsPending(true));
                    });
                  },
                  text: "Check Customers"
                }
              ]}
              className="button buy"
              message={t("customer.notValidBooking")}
              byPass={customersValidForBooking}
            >
              <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
            </ButtonWithConfirm>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsRooms;
