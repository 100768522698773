import React, { useEffect, useState } from "react";
import { IDestination, IFlightStats } from "../../interfaces/IFlight";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import Loading from "../Loading";

interface IProps {
  active: boolean;
  destination: IDestination;
  onClose(): void;
}

export const FlightStats = ({ active, destination, onClose }: IProps) => {
  const [isBusy, setIsBusy] = useState(false);
  const [flightStats, setFlightStats] = useState<IFlightStats>();
  useEffect(() => {
    if (active) {
      setIsBusy(true);
      api()
        .post("flightstatus", {
          arr: destination.ArrivalAirport,
          date: destination.DepDate,
          dep: destination.DepartureAirport,
          flightNumber: destination.MarketingAirline + destination.FlightNumber
        })
        .then(({ data }) => {
          if (data) {
            setFlightStats(data as IFlightStats);
          } else {
            setFlightStats(undefined);
          }
          setIsBusy(false);
        });
    } else {
      setFlightStats(undefined);
      setIsBusy(false);
    }
  }, [
    active,
    destination,
    destination.ArrivalAirport,
    destination.DepDate,
    destination.DepartureAirport,
    destination.FlightNumber,
    destination.MarketingAirline,
    onClose
  ]);

  return active ? (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>Flight Stats</h1>
      </Modal.Header>
      <Modal.Body>
        <div>
          {isBusy ? (
            <Loading />
          ) : flightStats ? (
            JSON.stringify(flightStats)
          ) : (
            <p className="align-center">Not found</p>
          )}
        </div>
      </Modal.Body>
    </Modal.Container>
  ) : null;
};
