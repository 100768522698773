import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useContext, useState } from "react";
import { IRoute } from "../../interfaces/ISearch";
import Location from "./Location";
import { SearchContext } from "./Search";

interface IProps {
  index?: number;
}

const FlightSearch: FC<IProps> = ({ index = 0 }) => {
  const [search, setSearch] = useContext(SearchContext);
  const [focusOn, setFocusOn] = useState(0);

  const reverseRoute = () => {
    setSearch({
      routes: search.routes.map((r, i) =>
        i === index
          ? {
              ...search.routes[i],
              arr: search.routes[i].dep,
              arrMultiAirport: search.routes[i].depMultiAirport,
              dep: search.routes[i].arr,
              depMultiAirport: search.routes[i].arrMultiAirport
            }
          : r
      )
    });
  };

  const selectHandler = (route: IRoute, isLast?: boolean) => {
    setFocusOn(isLast ? 1 : 2);
    setSearch({
      arrCity: route.arr,
      depCity: route.dep,
      dest: route.arr,
      hotel: null,
      lat: null,
      lon: null,
      name: null,
      orig: route.dep,
      routes: search.routes.map((r, i) => (i === index ? route : r))
    });
  };

  return (
    <div className="destinations">
      <div className="airport-search flex">
        {search.ftype && (
          <>
            <Location
              id={1}
              focus={focusOn}
              direction="dep"
              route={search.routes[index]}
              onSelect={selectHandler}
            />
            <LocationSeparator ftype={search.ftype} onClick={reverseRoute} />
          </>
        )}
        <Location
          id={2}
          last
          focus={focusOn}
          direction="arr"
          route={search.routes[index]}
          onSelect={selectHandler}
        />
      </div>
    </div>
  );
};

export default FlightSearch;

function LocationSeparator({ ftype, onClick }: { ftype: string; onClick(): void }) {
  return (
    <button className="round-trip pointer c-primary" onClick={onClick}>
      {ftype === "RT" || ftype === "OWC" ? (
        <FontAwesomeIcon icon={["far", "exchange"]} />
      ) : (
        <FontAwesomeIcon icon={["far", "long-arrow-right"]} />
      )}
    </button>
  );
}
