import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uniqBy from "lodash-es/uniqBy";
import React from "react";
import { IPackage } from "../interfaces/IPackage";
import { Batch } from "../store/actions/basketActions";
import PackageFlight from "./PackageFlight";
import PackageHotel from "./PackageHotel";

interface IPackageComponentProps extends IPackage {
  title: string;
  icon: IconProp;

  addBasket(batch: Batch): void;
}

function Package({ flight, flightType, hotel, total, addBasket, title, icon }: IPackageComponentProps) {
  const clickHandler = () => {
    const batch: Batch = [];
    if (flight) {
      batch.push({
        action: "flight",
        item: flight
      });
    }
    if (hotel && hotel.rooms && hotel.rooms.length) {
      batch.push({
        action: "hotel",
        item: hotel!.rooms[0]
      });
    }
    addBasket(batch);
  };

  return (
    <div className="package">
      <div className="title">
        <button className="add-basket" onClick={clickHandler}>
          <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
        </button>
        <p>
          <FontAwesomeIcon icon={icon} /> {title}
        </p>
        <p className="price-tag">{total}</p>
      </div>
      <div className="body">
        {flight.airitin.origdests && <PackageFlight dest={flight.airitin.origdests[0]} />}
        {hotel && <PackageHotel hotel={hotel} />}
        {flightType === "RT" && (
          <PackageFlight
            dest={uniqBy(flight.airitin.origdests, "DirectionId").find(d => d.DirectionId === "1")!}
          />
        )}
      </div>
    </div>
  );
}

export default Package;
