import { IAccountingPayment } from "../interfaces/IAccounting";
import { IAirport } from "../interfaces/IAirport";
import { ICustomer, ICustomerPolicy } from "../interfaces/ICustomer";
import {
  IFilterActivity,
  IFilterCar,
  IFilterFlight,
  IFilterHotel,
  IFilterTransfer
} from "../interfaces/IFilter";
import { IAgreementHotel, IAgreementRoom, IHotelPoilist } from "../interfaces/IHotel";
import { IRoute, ISearch } from "../interfaces/ISearch";
import { IBasketState } from "../interfaces/IState";
import { IMenu, IUser } from "../interfaces/IUser";

export const defaultUser: IUser = {
  agency: "",
  agencyLogos: [],
  agencycode: "",
  agencyid: 0,
  backgroundImage: "",
  companies: [],
  company: "",
  companyLogos: [],
  companycode: "",
  defaultTheme: "",
  firstname: "",
  lastname: "",
  notifications: [],
  useremail: "",
  userguid: "",
  username: "",
  usertype: ""
};

export const defaultPaymentMethods = ["vpos", "guest", "cash"];

export const defaultAgreementHotel: IAgreementHotel = {
  JSONamenity: {},
  address: "",
  agency_id: 0,
  city_code: "",
  company_code: "sisecam",
  contact_email: "",
  country_code: "",
  description: "",
  end_date: "",
  guid: "",
  hotel_code: "",
  hotel_name: "",
  image_url: "",
  inventory_source: "OFFLINE",
  latitude: "",
  longitude: "",
  rating: 0,
  start_date: "",
  status: 1
};

export const defaultAgreementRoom: IAgreementRoom = {
  agreement_guid: "",
  room_title: "",
  room_desc: "",
  room_type: "",
  extra_bed: 0,
  price: 0,
  currency: "TRY",
  status_id: 1,
  creation_date: "",
  image_url: "",
  bed_type: "",
  isrefundable: false,
  board: "",
  isvatincluded: true,
  vat: 0,
  user_guid: "",
  start_date: "",
  end_date: "",
  fresh: false
};

export const defaultRoute: IRoute = {
  arr: "",
  arrMultiAirport: false,
  date: "",
  dep: "",
  depMultiAirport: false,
  rdate: ""
};

export const defaultSearchState: Readonly<ISearch> = {
  adt: 1,
  arrCity: "",
  chd: 0,
  currency: "TRY",
  depCity: "",
  dest: "",
  fdate: "",
  filters: {
    hasActivities: false,
    hasCars: false,
    hasFlights: true,
    hasHotels: false,
    hasPackages: false,
    hasTransport: false
  },
  ftime: "09:00:00",
  ftype: "RT",
  guid: "",
  inf: 0,
  multicity: false,
  orig: "",
  rdate: "",
  rooms: 0,
  routes: [defaultRoute],
  rtime: "09:00:00",
  type: "ECONOMY"
};

export const defaultAirport: IAirport = {
  id: 0,
  code: "...",
  name: "...",
  city: "...",
  state: "...",
  country: "...",
  lat: "...",
  lon: "..."
};

export const dropdowns = {
  ftype: [
    {
      name: "One Way",
      value: "OW"
    },
    {
      name: "Round Trip",
      value: "RT"
    },
    {
      name: "Round Trip (Combine)",
      value: "OWC"
    },
    {
      name: "Multicity",
      value: "MC"
    }
  ],
  type: [
    {
      name: "Economy",
      value: "ECONOMY"
    },
    {
      name: "Business",
      value: "BUSINESS"
    }
  ]
};

export const defaultFilters: {
  activities: IFilterActivity;
  cars: IFilterCar;
  flights: IFilterFlight;
  hotels: IFilterHotel;
  transfers: IFilterTransfer;
} = {
  activities: {
    search: {
      input: "",
      searchable: ["name"],
      type: "includes"
    },
    sortBy: "price"
  },
  cars: {
    doors: 0,
    isActive: false,
    luggage: 0,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["ModelName", "Vendor", "description", "title"],
      type: "includes"
    },
    seats: 0,
    sortBy: "price",
    transmission: "all"
  },
  transfers: {
    doors: 0,
    isActive: false,
    luggage: 0,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["ModelName", "Vendor", "description", "title"],
      type: "includes"
    },
    seats: 0,
    sortBy: "price",
    transmission: "all"
  },
  flights: {
    airlines: [],
    arrTime: {
      max: 24,
      min: 0
    },
    depTime: {
      max: 24,
      min: 0
    },
    flightTime: {
      max: 24,
      min: 0
    },
    inlineSearch: "",
    isConnected: false,
    price: {
      max: 0,
      min: 0
    },
    search: {
      input: "",
      searchable: ["keywords"],
      type: "includes"
    },
    sortBy: "price"
  },
  hotels: {
    inlineSearch: "",
    price: {
      max: 0,
      min: 0
    },
    search: {
      input: "",
      searchable: ["HotelName"],
      type: "includes"
    },
    sortBy: "price",
    stars: [1, 2, 3, 4, 5]
  }
};

export const defaultCustomer: ICustomer = {
  birthdate: "",
  company_title: "",
  cust_guid: "",
  cust_level: "",
  customer_type: "",
  department: "",
  email: "",
  firstname: "",
  lastname: "",
  passenger_type: "ADT",
  telephone: "",
  title: "MR",
  milesId: "",
  national_id: ""
};

export const defaultCustomerPolicy: ICustomerPolicy = {
  budget: {
    approved: {
      currency: "TRY",
      department_limit: 0,
      limit: 0
    },
    pending: {
      currency: "TRY",
      department_limit: 0,
      limit: 0,
      parents: [],
      pendingList: []
    }
  },
  flights: {
    airlines: {
      excluded: [],
      included: [],
      isFilter: false,
      isRule: false
    },
    type: {
      excluded: [],
      included: [],
      isFilter: false,
      isRule: false
    }
  },
  hotels: {
    rating: {
      excluded: [],
      included: [],
      isFilter: false,
      isRule: false
    },
    room: {
      amenities: [],
      isFilter: false,
      isRule: false,
      size: []
    }
  }
};

export const defaultSupplierInvoiceRequest = {
  description: "açıklama",
  invoiceNo: "test",
  offer_guid: "9ee5b29a-d6fd-42f1-8917-d3f460d88886",
  price: 191.66,
  supplier: "BEDSONLINE",
  totalAmount: 191.66,
  type: "NEW",
  unit: 2,
  unitType: "GECE",
  user_guid: "12345",
  vatAmount: 29.24,
  vatRatio: 18
};

export const defaultInvoiceRequest = {
  invoice: {
    invoiceData: {
      currency: "TRY",
      invoice: {
        address_line1: "",
        address_line2: "",
        city: "",
        companyCode: "",
        country: "",
        invoiceReference: "",
        isCompany: true,
        tckno: "",
        title: "",
        vkno: "",
        vkzone: "",
        zipcode: ""
      },
      invoiceItems: [] as IAccountingPayment[],
      invoiceType: "",
      totalAmount: 0,
      totalVat: 0
    },
    invoiceDate: "",
    invoiceKey: "",
    invoiceReference: "",
    invoiceType: "",
    type: "NEW"
  },
  isInvoiced: true,
  items: [] as IAccountingPayment[]
};

export const poilistDefault: IHotelPoilist[] = [
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.3 km",
    name: "National Theatre of Opera and Ballet"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.4 km",
    name: "Skanderberg"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.5 km",
    name: "National Art Gallery"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.3 km",
    name: "National Theatre of Opera and Ballet"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.4 km",
    name: "Skanderberg"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.5 km",
    name: "National Art Gallery"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.3 km",
    name: "National Theatre of Opera and Ballet"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.4 km",
    name: "Skanderberg"
  },
  {
    description: "Lorem ipsum dolor sit amet",
    distance: "0.5 km",
    name: "National Art Gallery"
  }
];

export const defaultStars: number[] = [1, 2, 3, 4, 5, 6, 7];

export const defaultBasket: IBasketState = {
  basket: {
    activities: [],
    basketguid: "",
    cars: [],
    count: "",
    currency: "TRY",
    custguid: [],
    flights: [],
    hotels: [],
    total: 0,
    transfers: [],
    visas: []
  },
  custlist: [],
  paymentMethod: "cash",
  selectedItems: {
    car: null,
    flight: null,
    hotel: null
  },
  status: {
    error: null,
    fetched: false,
    fetching: false
  }
};

export const defaultMenu: IMenu = {
  items: [
    {
      code: "pnr-module",
      iconClass: ["fas", "ticket-alt"],
      title: "Pnr Yönetimi"
    },
    {
      code: "basket-module",
      iconClass: ["fas", "clipboard-list"],
      title: "Sepet Yönetimi"
    },
    {
      code: "offers-approve",
      iconClass: ["fas", "clipboard-list-check"],
      title: "Talep/Onay Yönetimi"
    },
    {
      code: "admin-page",
      iconClass: ["fas", "cog"],
      title: "Sistem Yönetimi"
    },
    {
      code: "agency-reports",
      iconClass: ["fas", "chart-line"],
      subitems: [
        {
          code: "agency-dashboard",
          title: "Acente Kontrol"
        },
        {
          code: "agency-sales",
          title: "Satış Raporu"
        },
        {
          code: "agency-reconciliation",
          title: "Devre Raporu"
        },
        {
          code: "agency-commission",
          title: "Komisyon Raporu"
        },
        {
          code: "agency-profitloss",
          title: "Kar/Zarar Raporu"
        },
        {
          code: "agency-accounting",
          title: "Muahsebe Modülü"
        }
      ],
      title: "Acente Raporları"
    },
    {
      code: "company-reports",
      iconClass: ["fas", "building"],
      subitems: [
        {
          code: "company-dashboard",
          title: "Firma Kontrol"
        },
        {
          code: "company-compliance",
          title: "Otel Politak Uyum Raporu"
        },
        {
          code: "company-expense-budget",
          title: "Harcama & Bütçe"
        },
        {
          code: "company-staff-expenses",
          title: "Personel Harcamaları"
        }
      ],
      title: "Firma Raporları"
    }
  ],
  type: "ADMIN"
};

export const defaultCarVoucher = {
  pnr: "202-3312500",
  agency: {
    agencyCode: "TRAVELSERVIS",
    agencyName: "Travel Ser-Vis",
    description: "Travel Ser-Vis Organizasyon",
    address: "Cumhuriyet Cd. Sipahi Apt. No:115/4 Harbiye-Şişli",
    email: "info@servistravel.com.tr",
    phone: "+90 212 291 77 91",
    city: "ISTANBUL",
    country: "TURKEY",
    logos: [
      { theme: "dark", url: "https://trippep.com/images/logo/travelservis-dark.png" },
      { theme: "light", url: "https://trippep.com/images/logo/travelservis-light.png" }
    ]
  },
  company: {
    companyCode: "sisecamold",
    companyName: "Şişecam",
    description: "Şişecam -old",
    vkno: "1212121212",
    vkloc: "Beyoğlu",
    vktitle: "ŞİŞECAM A.Ş.",
    address: "İçmeler Mah., D-100 Karayolu Cad. 44A Tuzla",
    city: "istanbul",
    country: "TURKEY",
    email: "info@servistravel.com.tr",
    phone: "+90 850 206 5050",
    logos: [
      { theme: "dark", url: "https://trippep.com/images/logo/sisecam-dark.png" },
      { theme: "light", url: "https://trippep.com/images/logo/sisecam-light.png" }
    ]
  },
  voucher_guid: "a0d1d8d8-4d7e-4f28-8aa0-4002c9a7ac39",
  voucher_type: "offer",
  action: "hotel",
  total: 0,
  customer: [
    {
      firstname: "Arif",
      lastname: "Susam",
      title: "MR",
      passport_no: "1234567890",
      passport_expire: "2020-01-01",
      passport_issue: "TR",
      birthdate: "1988-01-01",
      email: "kubilen.simsek@pisoco.com",
      telephone: "5375984488",
      cust_guid: "5143bf87-4770-444f-8de8-2c1b76ae7461",
      passenger_type: "ADT",
      cust_level: "ECONOMY",
      status_id: 1,
      department: "Information Technologies",
      roomNo: 1
    }
  ],
  count: "0",
  flights: [],
  tickets: null,
  hotels: [],
  cars: [
    {
      title: "Inclusive",
      description: "Includes time, mileage, CDW and TP",
      VendorLogo: "https://s3.eu-central-1.amazonaws.com/ctg-rf-backend/2018/12/1546080711-sixt-logo.jpg",
      Vendor: "Sixt",
      source: "CARTARGET",
      StartDate: "2019-10-24",
      EndDate: "2019-10-26",
      VendorCode: "2a83fdcd-a3d8-4cc3-aac6-a0512a34a963",
      VendorEmail: "res-fr@sixt.com",
      OfferId: "81ea27bb-c0a8-4321-a0db-ab9426aa4407",
      VendorId: "2a83fdcd-a3d8-4cc3-aac6-a0512a34a963",
      PickupName: "Paris Charles de Gaulle Airport Sixt Intl CDG",
      PickupAddress: "Terminal 1 + 2, Paris Roissy -- 95711",
      PickupGeo: {
        longitude: 2.54125,
        latitude: 49.01425,
        isCity: false,
        isAirport: false,
        isHotel: false,
        isPlace: false
      },
      DropoffName: "Paris Charles de Gaulle Airport Sixt Intl CDG",
      DropoffAddress: "Terminal 1 + 2, Paris Roissy -- 95711",
      DropoffGeo: {
        longitude: 2.54125,
        latitude: 49.01425,
        isCity: false,
        isAirport: false,
        isHotel: false,
        isPlace: false
      },
      VehicleId: "ddc692d7-42f4-4161-bb1c-eb9336c3242b",
      AirCondition: true,
      TransmissionType: "Manual",
      VehicleCategory: "FVMR",
      Currency: "TRY",
      Amount: 2511.4,
      MarkupAmount: 0.0,
      PictureUrl: "https://s3.eu-central-1.amazonaws.com/ctg-rf-backend/cars/Opel_Vauxhall_Vivaro_PVMR.png",
      Quantity: "1",
      ModelName: "Opel/Vauxhall Vivaro",
      NumberOfDoor: "5",
      seats: "9",
      PassengerQty: "1",
      key: "a94b952f-a89e-4196-ba1a-eba86593ea2b"
    }
  ]
};
