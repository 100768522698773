import isUndefined from "lodash-es/isUndefined";
import React, { ChangeEvent, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { IPolicyMoney } from "../../../../interfaces/IPolicy";

interface IProps {
  budget: IPolicyMoney;
  index?: number;

  onChange(budget: IPolicyMoney, index?: number): void;
}

const PolicyMoneyEdit: FC<IProps> = ({ budget, index, onChange }) => {
  const { t } = useTranslation();
  const limitHandler = useCallback(
    (value: NumberFormatValues) => {
      onChange(
        {
          ...budget,
          limit: value.floatValue!
        },
        index
      );
    },
    [budget, index, onChange]
  );

  const currencyHandler = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const v = e.target.value;
      onChange(
        {
          ...budget,
          currency: v
        },
        index
      );
    },
    [budget, index, onChange]
  );

  return (
    <div className="flex a-center gutter-bottom">
      <h5>{t("admin.policy.limit")}</h5>
      <div className="numeric-input">
        <NumberFormat
          className="numeric"
          value={budget.limit}
          isAllowed={v => v.floatValue! <= budget.department_limit}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          allowNegative={false}
          onValueChange={limitHandler}
        />
      </div>
      <select className="addon-input" value={budget.currency} onChange={currencyHandler}>
        <option value="TRY">₺</option>
        <option value="USD">$</option>
      </select>
      {!isUndefined(budget.parents) && (
        <div className="pending-people">
          <p className="c-primary">{"{Approver Options}"}</p>
        </div>
      )}
    </div>
  );
};

export default PolicyMoneyEdit;
