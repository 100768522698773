import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { ICorporateReportWorld } from "../../interfaces/IReport";
import { ITotalExpenses } from "../../pages/Company/CompanyCompliancePage";
import { pwc } from "../../utils/helpers";

interface IProps {
  currency: string;
  data: ICorporateReportWorld[];
  params: {
    city?: string;
    country?: string;
  };
  totalExpenses: ITotalExpenses;
}

const HotelSpentTable: FC<IProps> = ({ currency, data, params: { city, country }, totalExpenses }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="spent-report-title">{t("corpReport.world.reserveTitle")}</h1>
      <div className="table-wrapper">
        <table className="spent-report table is-fullwidth">
          <thead>
            <tr>
              {!city && !country && <th>{t("generic.country")}</th>}
              {!city && <th>{t("generic.city")}</th>}
              <th>{t("corpReport.main.service.hotel")}</th>
              {city && <th>{t("corpReport.world.table.agreement")}</th>}
              <th>{t("corpReport.world.table.totalSpentHotel")}</th>
              <th>{t("corpReport.world.table.room")}</th>
              <th>{t("corpReport.world.table.averageAgree")}</th>
              <th>{t("corpReport.world.table.averageReserve")}</th>
              <th>{t("corpReport.world.table.totalSpent")}</th>
              <th>{t("corpReport.world.table.complianceReserve")}</th>
              <th>{t("corpReport.world.table.compliance")}</th>
            </tr>
          </thead>
          <tbody>
            {data && (
              <>
                {data.map((d, i) => (
                  <tr className={data[i + 1] ? "" : "last-row"} key={i}>
                    {!city && !country && (
                      <td className="align-left no-wrap">
                        <NavLink to={`/company/compliance/${d.country}`}>{d.country}</NavLink>
                      </td>
                    )}
                    {!city && (
                      <td className="align-left no-wrap">
                        <NavLink to={`/company/compliance/${d.country}/${d.city}`}>{d.city}</NavLink>
                      </td>
                    )}
                    <td className="align-left no-wrap">
                      <NavLink to={`/company/compliance/${d.country}/${d.city}`}>{d.hotelName}</NavLink>
                    </td>
                    {city && (
                      <td className="align-left no-wrap">
                        {d.isAgreement
                          ? t("corpReport.world.table.agree")
                          : t("corpReport.world.table.nonagree")}
                      </td>
                    )}
                    <td>
                      {totalExpenses.percentages[d.hotelName ? d.hotelName : d.city ? d.city : d.country]}
                    </td>
                    <td>{d.nights}</td>
                    <td>{pwc(d.avg_agreement_price, currency)}</td>
                    <td>{pwc(d.total_amount / d.nights, currency)}</td>
                    <td>{pwc(d.total_amount, currency)}</td>
                    <td>{d.nights_improper}</td>
                    <td>{pwc(d.total_improper, currency)}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={country ? 1 : 2} />
                  <td>Toplam:</td>
                  <td>100%</td>
                  <td>{totalExpenses.totals.nights}</td>
                  <td>{/*pwc(totalExpenses.totals.avg_agreement_price / data.length, currency)*/}</td>
                  <td>{pwc(totalExpenses.totals.total_amount / totalExpenses.totals.nights, currency)}</td>
                  <td>{pwc(totalExpenses.totals.total_amount, currency)}</td>
                  <td>{totalExpenses.totals.nights_improper}</td>
                  <td>{pwc(totalExpenses.totals.total_improper, currency)}</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HotelSpentTable;
