import i18n from "i18next";
import get from "lodash-es/get";
import isString from "lodash-es/isString";
import { toast, ToastOptions } from "react-toastify";

type ToastOptionsEx = ToastOptions & {
  fallbackMessage?: string;
  message?: string;
  prefix?: string;
};

export default class Logger {
  public static flash(message: string, options: ToastOptionsEx = {}) {
    toast(message, { autoClose: 2000, type: "success", ...options });
  }

  public static flashError(error: any, options: ToastOptionsEx = {}) {
    const statusCode = get(error, "res.status", get(error, "status", false));

    let message = "";
    if (isString(error)) {
      message = error;
    } else {
      message = get(
        error,
        "res.data.errors[0].error",
        get(
          error,
          "res.data.errors[0]",
          get(
            error,
            "res.data.errors",
            get(
              error,
              "data.errors[0].error",
              get(
                error,
                "data.errors[0]",
                get(
                  error,
                  "data.errors",
                  get(error, "res.data.message", get(error, "data.message", options.fallbackMessage))
                )
              )
            )
          )
        )
      );
    }

    if (!message && statusCode) {
      switch (statusCode) {
        case 500:
          message = i18n.t("errors.generic");
          break;
        case 405:
          message = i18n.t("errors.permission");
          break;
        case 401:
          message = i18n.t("errors.sessionEnd");
          break;
        case 404:
          message = i18n.t("errors.serviceNotFound");
      }
    }

    if (!message && options.message) {
      message = i18n.t("errors.generic");
    }

    if (!message) {
      message = i18n.t("errors.generic");
    }

    if (options.prefix) {
      message = options.prefix + ": " + message;
    }
    toast(message, { autoClose: 2000, type: "error", ...options });
  }
  public toastId: any;
  public prefix: string;

  public constructor(message: string, { prefix, ...options }: ToastOptionsEx = {}) {
    if (prefix) {
      message = prefix + ": " + message;
      this.prefix = prefix;
    }
    this.toastId = toast(message, { autoClose: false, type: "info", ...options });
  }

  public updateToast(message: string, options: ToastOptionsEx = {}) {
    toast.update(this.toastId, {
      autoClose: 2000,
      render: this.prefix ? `${this.prefix}: ${message}` : message,
      ...options
    });
  }

  public error(error: any, options: ToastOptionsEx = {}) {
    const statusCode = get(error, "res.status", get(error, "status", false));
    let message = "";
    if (isString(error)) {
      message = error;
    } else {
      message = get(
        error,
        "res.data.errors[0].error",
        get(
          error,
          "res.data.errors[0]",
          get(
            error,
            "res.data.errors",
            get(
              error,
              "data.errors[0].error",
              get(
                error,
                "data.errors[0]",
                get(
                  error,
                  "data.errors",
                  get(error, "res.data.message", get(error, "data.message", options.fallbackMessage))
                )
              )
            )
          )
        )
      );
    }

    if (!message && statusCode) {
      switch (statusCode) {
        case 500:
          message = i18n.t("errors.generic");
          break;
        case 405:
          message = i18n.t("errors.permission");
          break;
        case 401:
          message = i18n.t("errors.sessionEnd");
          break;
        case 404:
          message = i18n.t("errors.serviceNotFound");
      }
    }

    if (!message && options.message) {
      message = i18n.t("errors.generic");
    }

    if (!message) {
      message = i18n.t("errors.generic");
    }

    this.updateToast(message, { type: "error" });
  }

  public success(message: string, options: ToastOptionsEx = {}) {
    this.updateToast(message, { type: "success", ...options });
  }

  public warning(message: string, options: ToastOptionsEx = {}) {
    this.updateToast(message, { type: "warning", ...options });
  }
}
