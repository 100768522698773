import i18n from "i18next";
import { Dispatch } from "redux";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import {
  PNR_FETCH_FULFILLED,
  PNR_FETCH_PENDING,
  PNR_FETCH_REJECTED,
  PNR_UPDATE
} from "../../utils/constants";

export const fetchPnrs = () => (dispatch: Dispatch) => {
  dispatch({
    type: PNR_FETCH_PENDING
  });

  // dispatch({
  //   payload: mockPnrList,
  //   type: PNR_FETCH_FULFILLED
  // });
  //
  // return Promise.resolve(mockPnrList);

  const log = new Logger(i18n.t("pnr.fetchListPending"));

  return api()
    .post("userpnrlist")
    .then(res => {
      if (res.data.pnrs && res.data.pnrs.constructor === Array) {
        log.success(i18n.t("pnr.fetchListSuccess"));
        dispatch({
          payload: res.data.pnrs,
          type: PNR_FETCH_FULFILLED
        });
        return Promise.resolve(res.data.pnrs);
      } else {
        log.error(res);
        dispatch({
          type: PNR_FETCH_REJECTED
        });
        return Promise.reject();
      }
    })
    .catch(e => {
      log.error(e);
      dispatch({
        type: PNR_FETCH_REJECTED
      });
      return Promise.reject();
    });
};

export const updatePnr = (pnr: string, status: string) => {
  return {
    payload: { pnr, status },
    type: PNR_UPDATE
  };
};

// export const fetchPnrDetails = (requestData: IPnrSummary, basketGuid?: string) => (
//   dispatch: Dispatch,
//   getState: GetState
// ) => {
//   // const pnrState = getState().pnrs;
//   const log = new Logger(i18n.t("pnr.fetchDetailPending"));
//   if (!requestData.pnr || requestData.pnr.includes("null")) {
//     log.warning(i18n.t("pnr.fetchDetailNotValid"));
//     return;
//   }
//
//   // if (pnrState.pnrsDetailed.find(p => p.pnr === requestData.pnr)) {
//   //   log.success('PnrOld details has been retrieved.');
//   //   dispatch({
//   //     payload: requestData.pnr,
//   //     type: PNR_FETCH_DETAILS_SELECT
//   //   });
//   //   return;
//   // }
//   dispatch({
//     type: PNR_FETCH_DETAILS_PENDING
//   });
//   return axios
//     .post("getpnr", requestData)
//     .then(res => {
//       if (res.data.pnr) {
//         log.success(i18n.t("pnr.fetchDetailSuccess"));
//         dispatch({
//           payload: res.data,
//           type: PNR_FETCH_DETAILS_FULFILLED
//         });
//         return Promise.resolve();
//       } else {
//         log.error(res);
//         dispatch({
//           type: PNR_FETCH_DETAILS_REJECTED
//         });
//         return Promise.reject();
//       }
//     })
//     .catch(e => {
//       log.error(e);
//       dispatch({
//         type: PNR_FETCH_DETAILS_REJECTED
//       });
//       return Promise.reject();
//     });
// };
