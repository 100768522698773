import React, { FC, useCallback } from "react";
import Toggle from "react-toggle";

interface IProps {
  alwaysTrue?: boolean;
  checked?: boolean;
  className?: string;
  labels?: [string, string];

  onChange(checked: boolean): void;
}

const Switch: FC<IProps> = ({ alwaysTrue = false, checked, className = "", labels = [], onChange }) => {
  const onChangeHandler = useCallback(() => {
    onChange(alwaysTrue || !checked);
  }, [alwaysTrue, checked, onChange]);

  return (
    <div className={`payment-switch ${className}`}>
      <label>
        {labels.length > 0 && <span className={checked ? "" : "is-bold"}>{labels[0]}</span>}
        <Toggle checked={checked} onChange={onChangeHandler} icons={false} />
        {labels.length > 1 && <span className={checked ? "is-bold" : ""}>{labels[1]}</span>}
      </label>
    </div>
  );
};

export default Switch;
