import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useState } from "react";
import ProgramModal from "./components/ProgramModal";

type Props = {};

const ProgramScreen: FC<Props> = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleModal = useCallback(() => {
    setIsActive(x => !x);
  }, []);

  return (
    <div>
      {isActive && <ProgramModal onClose={toggleModal} />}
      <button className="pointer c-primary" onClick={toggleModal}>
        <FontAwesomeIcon icon={["fas", "atlas"]} />
      </button>
    </div>
  );
};

export default ProgramScreen;
