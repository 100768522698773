import isBoolean from "lodash-es/isBoolean";
import React, { useMemo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import FilterRadio from "../components/filters/FilterRadio";
import Filters from "../components/filters/Filters";
import FilterSelect from "../components/filters/FilterSelect";
import FilterSlider from "../components/filters/FilterSlider";
import { IFilterFlight } from "../interfaces/IFilter";
import { IStoreState } from "../interfaces/IState";
import { changeFilterFlights, resetFilterFlights, toggleFilterFlights } from "../store/actions/filterActions";
import { getFlightFilters, getFiltersFlightDefaults } from "../store/selectors/filterSelectors";
import { getHasDirectFlights, getFlightAirlines } from "../store/selectors/flightSelectors";
import { uniqid } from "../utils/helpers";

interface IStateProps {
  defaults: IFilterFlight;
  filters: IFilterFlight;
  hasDirectFlights?: boolean;
  uniqueAirlines: {
    [key: string]: string;
  };
}

interface IDispatchProps {
  actions: {
    changeFilter(filters: IFilterFlight): void;
    resetFilter(): void;
    toggleFilter(): void;
  };
}

interface IProps extends IStateProps, IDispatchProps, WithTranslation {}

function FilterFlight({ defaults, filters, hasDirectFlights, uniqueAirlines, actions, t }: IProps) {
  const filterHandler = (newFilter: { [key: string]: any }) => {
    actions.changeFilter({
      ...filters,
      ...newFilter
    });
  };

  const isConnected = useMemo(() => {
    return isBoolean(hasDirectFlights) && !hasDirectFlights ? !hasDirectFlights : !!filters.isConnected;
  }, [hasDirectFlights, filters]);

  return filters.isActive ? (
    <Filters key={uniqid()} onReset={actions.resetFilter} onClose={actions.toggleFilter}>
      <FilterSlider
        label={t("filter.flight.price")}
        suffix="₺"
        propKey="price"
        step={50}
        values={filters.price}
        limits={defaults.price}
        onChange={filterHandler}
      />

      <FilterSlider
        label={t("filter.flight.depTime")}
        suffix=":00"
        propKey="depTime"
        step={1}
        values={filters.depTime}
        limits={defaults.depTime}
        onChange={filterHandler}
      />

      <FilterSlider
        label={t("filter.flight.arrTime")}
        suffix=":00"
        propKey="arrTime"
        step={1}
        values={filters.arrTime}
        limits={defaults.arrTime}
        onChange={filterHandler}
      />

      <FilterSlider
        label={t("filter.flight.time")}
        suffix="h"
        propKey="flightTime"
        step={1}
        values={filters.flightTime}
        limits={defaults.flightTime}
        onChange={filterHandler}
      />

      <div className="filter-group" />

      <div className="filter-group inline">
        <FilterRadio
          labels={[t("filter.flight.direct"), t("filter.flight.transit")]}
          propKey="isConnected"
          value={isConnected}
          onChange={filterHandler}
        />
        <FilterSelect
          label={t("filter.flight.airline")}
          options={uniqueAirlines}
          selected={filters.airlines}
          propKey="airlines"
          multiple={true}
          onChange={filterHandler}
        />
      </div>
    </Filters>
  ) : null;
}

const mapStateToProps = (state: IStoreState): IStateProps => ({
  defaults: getFiltersFlightDefaults(state),
  filters: getFlightFilters(state),
  hasDirectFlights: getHasDirectFlights(state),
  uniqueAirlines: getFlightAirlines(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      changeFilter: changeFilterFlights,
      resetFilter: resetFilterFlights,
      toggleFilter: toggleFilterFlights
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterFlight));
