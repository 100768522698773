import uniqBy from "lodash-es/uniqBy";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyRoom } from "../../../../interfaces/IPolicy";
import { hasPolicy } from "../../../../utils/helpers";
import PolicyFilterRule from "./PolicyFilterRule";

interface IProps extends IPolicyRoom {
  onChange?(payload: { data: any; key: string }): void;
}

const PolicyHotelRating: FC<IProps> = ({ onChange, ...p }) => {
  const { t } = useTranslation();
  const localAmenities = ["Honeymoon", "King Bed", "Late Arrival", "Smoking"];
  const localSize = ["SINGLE", "DOUBLE", "TRIPLE"];

  const toggleItem = (code: string, sc: "amenities" | "size") => {
    const data = p[sc].map(x => x.code).includes(code)
      ? p[sc].filter(x => x.code !== code)
      : uniqBy([...p[sc], { code }], x => x.code);

    onChange!({
      data,
      key: `hotels.room.${sc}`
    });
  };

  const filterRuleHandler = (sc: "isFilter" | "isRule") => {
    onChange!({
      data: !p[sc],
      key: `hotels.room.${sc}`
    });
  };

  return (
    <div>
      <div className="airlines excluded">
        <div className="flex j-between a-center">
          <h4>{t("admin.policy.size")}</h4>
        </div>
        <div className="flex">
          {localSize.map(x => (
            <p
              className={`cluded-item${hasPolicy(x, p.size, "is-selected")}`}
              key={x}
              onClick={() => toggleItem(x, "size")}
            >
              {x}
            </p>
          ))}
        </div>
      </div>
      <div className="airlines included">
        <div className="flex j-between a-center">
          <h4>{t("generic.details")}</h4>
          <div className="airline-search" />
        </div>
        <div className="flex">
          {localAmenities.map(x => (
            <p
              className={`cluded-item${hasPolicy(x, p.amenities, "is-selected")}`}
              key={x}
              onClick={() => toggleItem(x, "amenities")}
            >
              {x}
            </p>
          ))}
        </div>
      </div>
      <PolicyFilterRule filter={p.isFilter} rule={p.isRule} onChange={filterRuleHandler} />
    </div>
  );
};

PolicyHotelRating.defaultProps = {
  amenities: [],
  isFilter: false,
  isRule: false,
  size: []
};

export default PolicyHotelRating;
