import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends WithTranslation {
  options: string[];
  onChange(value: string): void;
}

function ReasonSelect({ options, onChange, t }: IProps) {
  const inputEl = useRef<HTMLInputElement>(null);
  const [customIsActive, setCustomIsActive] = useState(false);

  const selectCustomHandler = (e: any) => {
    onChange(e.target.value);
  };

  const blurInput = useCallback(() => {
    setCustomIsActive(false);
  }, []);

  useEffect(() => {
    if (customIsActive && inputEl.current) {
      inputEl.current.focus();
    }
  }, [customIsActive]);

  useEffect(() => {
    const $el = inputEl.current;
    $el?.addEventListener("blur", blurInput);
    return () => $el?.removeEventListener("blur", blurInput);
  }, [blurInput]);

  const cx = classNames("reason-select-box", {
    "custom-is-active": customIsActive
  });

  return (
    <div className={cx}>
      <select onChange={selectCustomHandler} defaultValue="">
        <option disabled value="">
          {t("generic.select")}
        </option>
        {options.map(o => (
          <option key={o} value={o}>
            {o}
          </option>
        ))}
      </select>
    </div>
  );
}

export default withTranslation()(ReasonSelect);
