import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import tooltip from "react-tooltip";
import { IActivity } from "../interfaces/IActivity";
import { ICheckoutData } from "../interfaces/IBooking";
import { IBookingStatus } from "../interfaces/IState";
import { resetAndAddtoBasket } from "../store/actions/basketActions";
import {
  setBasketData,
  setBasketIsPending,
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { getSelectedActivity } from "../store/selectors/selectedItemSelectors";
import { pwc } from "../utils/helpers";
import ActivityCard from "./ActivityCard";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";
import { Busy } from "./snippets/Busy";

interface IProps {
  activities: IActivity[];
  bookingStatus: IBookingStatus;
  code: string;
  customersValidForBasket: boolean;
  customersValidForBooking: boolean;
  style?: CSSProperties;
}

const Activity: FC<IProps> = ({
  activities,
  bookingStatus,
  code,
  customersValidForBasket,
  customersValidForBooking,
  style
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sa = useSelector(getSelectedActivity(code));
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  const checkoutData: ICheckoutData = useMemo(() => {
    return {
      actionType: "activity",
      amount: sa.AmountAfterTax,
      currency: sa.currency,
      guid: sa.OfferId,
      items: { activities: [sa] }
    };
  }, [sa]);

  return (
    <div className="hotel-container activity-container flex" style={style}>
      {isBusy && <Busy />}
      <ActivityCard activity={sa} activities={activities} />
      <div className="controllers">
        <p className="price-tag fs-prc is-centered">{pwc(sa.AmountAfterTax, sa.currency)}</p>
        <ButtonWithConfirm
          actions={[
            {
              byPassAction: true,
              color: "success",
              handler() {
                if (sa) {
                  setIsBusy(true);
                  dispatch<any>(resetAndAddtoBasket(sa, "activity"))
                    .then(() => setIsBusy(false))
                    .catch(() => setIsBusy(false));
                }
              },
              text: "Proceed"
            },
            {
              color: "success",
              handler() {
                batch(() => {
                  dispatch(setBasketData(sa!, "activity"));
                  dispatch(toggleCustomerScreen());
                  dispatch(setBasketIsPending(true));
                });
              },
              text: "Check Customers"
            }
          ]}
          className="button cart"
          message={t("customer.notValidBooking")}
          byPass={customersValidForBooking}
        >
          <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
        </ButtonWithConfirm>
        <ButtonWithConfirm
          actions={[
            {
              byPassAction: true,
              color: "success",
              handler() {
                dispatch(setCheckoutScreenData(checkoutData, true));
              },
              text: "Proceed"
            },
            {
              color: "success",
              handler() {
                batch(() => {
                  dispatch(setCheckoutScreenData(checkoutData));
                  dispatch(toggleCustomerScreen());
                  dispatch(setCheckoutIsPending(true));
                });
              },
              text: "Check Customers"
            }
          ]}
          className="button buy"
          message={t("customer.notValidBooking")}
          byPass={customersValidForBooking}
        >
          <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
        </ButtonWithConfirm>
      </div>
    </div>
  );
};

export default Activity;
