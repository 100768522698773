import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducers from "./reducers/index";

const composer: any = process.env.NODE_ENV === "development" ? composeWithDevTools : compose;

const storeEnhancer = composer(applyMiddleware(thunk));

export default createStore(reducers, undefined, storeEnhancer);
