import classNames from "classnames";
import React, { ReactNode } from "react";

interface IProps {
  children?: ReactNode;
  flow: "horizontal" | "vertical";
}

function MapContent({ children, flow }: IProps) {
  const cx = classNames("map-content", {
    flex: flow === "horizontal"
  });
  return <div className={cx}>{children}</div>;
}

MapContent.defaultProps = {
  flow: "vertical"
};

export default MapContent;
