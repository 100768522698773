import { Reducer } from "redux";
import { INotificationState } from "../../interfaces/IState";
import {
  NOTIFICATION_DISMISS,
  NOTIFICATION_DISMISS_ALL,
  NOTIFICATION_FETCH_FULFILLED,
  NOTIFICATION_INIT,
  NOTIFICATION_READ
} from "../../utils/constants";

const initialState: INotificationState = {
  notifications: [],
  status: {
    fetched: false,
    fetching: false
  }
};

const notificationReducer: Reducer<INotificationState> = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_INIT:
      return {
        ...state,
        notifications: action.payload
      };
    case NOTIFICATION_FETCH_FULFILLED:
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload]
      };
    case NOTIFICATION_DISMISS:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.notificationid !== action.payload)
      };
    case NOTIFICATION_DISMISS_ALL:
      return {
        ...state,
        notifications: []
      };
    case NOTIFICATION_READ:
      return {
        ...state,
        notifications: state.notifications.map(n => {
          n.isRead = true;
          return n;
        })
      };
    default:
      return state;
  }
};

export default notificationReducer;
