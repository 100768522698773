import React, { useEffect, useState } from "react";
import { IOtherService } from "../../interfaces/IOtherService";
import { api } from "../../services/api";
import { ActionTypes, ItemTypes } from "../../store/actions/basketActions";
import Offer from "./Offer";

interface IProps {
  basketGuid: string;
  addToBasket(item: ItemTypes, action: ActionTypes): any;
}

export default function OtherOffers({ basketGuid, addToBasket }: IProps) {
  const [offers, setOffers] = useState<IOtherService[]>([]);

  useEffect(() => {
    api()
      .get("otherservices", {
        params: { key: basketGuid }
      })
      .then(res => {
        if (res.status === 200) {
          setOffers(res.data);
        }
      })
      .catch(err => err);
  }, [basketGuid]);

  return (
    <div className="offers-container">
      {offers.map(offer => (
        <Offer addToBasket={addToBasket} offer={offer} key={offer.offer_guid} />
      ))}
    </div>
  );
}
