import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import Loading from "../components/Loading";
import { IBookingOptions } from "../interfaces/IBooking";
import DefaultLayout from "../layouts/DefaultLayout";
import FullPage from "../libs/FullPage";
import Modal from "../libs/Modal";
import { api } from "../services/api";
import { bookingBasket, bookingOffer } from "../store/actions/bookingActions";
import { browserHistory } from "../utils/history";

const PaymentErrorPage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const checkPayment = useCallback(async () => {
    const { paymentKey } = parse(location.search);

    if (!paymentKey) {
      await Promise.reject();
    } else {
      try {
        const { data } = await api().post("paymentresult", {
          payStatus: "SUCCESS",
          paymentKey,
          paymentType: {
            sec3d: true
          },
          status: true
        });
        return data.status ? await data : await Promise.reject();
      } catch (e) {
        await Promise.reject();
      }
    }
  }, [location]);

  const bookingBasketHandler = useCallback(
    async (payload: IBookingOptions) => {
      await dispatch(bookingBasket(payload));
    },
    [dispatch]
  );

  const bookingOfferHandler = useCallback(
    async (payload: IBookingOptions) => {
      await dispatch(bookingOffer(payload));
    },
    [dispatch]
  );

  const [statusType, setStatusType] = useState(0);
  const [message, setMessage] = useState<string>();
  const [isBusy, setIsBusy] = useState(true);

  const paymentHandler = useCallback(() => {
    setIsBusy(true);
    setStatusType(0);
    checkPayment()
      .then(data => {
        setTimeout(() => {
          if (data.basket_guid && data.basket_guid !== "null") {
            bookingBasketHandler({
              createTicket: true,
              custguid: [],
              isCompany: true,
              isRepeat: false,
              offerid: data.offer_guid,
              provisionKey: data.provisionKey,
              type: data.flights.length ? "flight" : "hotel"
            })
              .then(({ data: bookingData }: any) => {
                if (bookingData.status) {
                  setStatusType(3);
                  setMessage("Booking is completed. Redirecting to pnr page...");
                } else {
                  setStatusType(2);
                  setMessage(`There was an error: ${JSON.stringify(bookingData.errors)}`);
                }
                setIsBusy(false);
              })
              .catch(() => setIsBusy(false));
          } else {
            bookingOfferHandler({
              createTicket: true,
              custguid: [],
              isCompany: true,
              isRepeat: false,
              offerid: data.offer_guid,
              provisionKey: data.provisionKey,
              type: data.flights.length ? "flight" : "hotel"
            }).catch((e: any) => {
              setStatusType(2);
              setMessage(`There was an error: ${JSON.stringify(e)}`);
              setIsBusy(false);
            });
          }
        }, 500);
      })
      .catch(() => {
        setIsBusy(false);
        setStatusType(1);
        setMessage("Payment key is not valid. ");
        // setTimeout(() => browserHistory.push("/"));
      });
  }, [bookingBasketHandler, bookingOfferHandler, checkPayment]);

  useEffect(() => {
    paymentHandler();
  }, [paymentHandler]);

  return (
    <DefaultLayout hasSearch>
      <FullPage>
        <Modal.Container>
          <Modal.Body>
            {statusType ? (
              <div className="payment-error-module">
                <p>{message}</p>
                {(statusType === 1 || statusType === 2) && (
                  <div className="payment-error-buttons">
                    <button className="retry" onClick={paymentHandler}>
                      Retry
                    </button>
                    <button className="gohomepage" onClick={() => browserHistory.push("/")}>
                      Go to Homepage
                    </button>
                  </div>
                )}
              </div>
            ) : (
              isBusy && (
                <div className="payment-module">
                  <p>Completing action...</p>
                  <br />
                  <Loading />
                </div>
              )
            )}
          </Modal.Body>
        </Modal.Container>
      </FullPage>
    </DefaultLayout>
  );
};

export default PaymentErrorPage;
