import flatten from "lodash-es/flatten";
import includes from "lodash-es/includes";
import keys from "lodash-es/keys";
import map from "lodash-es/map";
import max from "lodash-es/max";
import min from "lodash-es/min";
import sortBy from "lodash-es/sortBy";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import toSafeInteger from "lodash-es/toSafeInteger";
import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";
import { getFiltersTransfer } from "./filterSelectors";

export function getTransferState(state: IStoreState) {
  return state.transferState;
}

export const getTransferVendors = createSelector(getTransferState, state => state.vendors);

export const getTransfers = createSelector(getTransferVendors, vendors =>
  flatten(map(vendors, vendor => vendor.items))
);

export const getTransfersSortedByPrice = createSelector(getTransfers, transfers =>
  sortBy(transfers, "Amount")
);

export const getTransfersStatuses = createSelector(getTransferVendors, vendors =>
  map(vendors, (vendor, vendorKey) => {
    const status = vendor.status;
    status.serviceKey = vendorKey;
    return status;
  })
);
export const getTransferSearchDone = createSelector(getTransfersStatuses, statuses => {
  return !!statuses.length && statuses.every(s => !s.fetching && s.fetched);
});

export const getTransfersResultEmpty = createSelector(
  getTransfers,
  getTransferSearchDone,
  (transfers, searchDone) => !transfers.length && searchDone
);

export const getTransferCurrency = createSelector(getTransfers, transfers => {
  return transfers.length ? transfers[0].Currency : "TRY";
});

export const getTransferPrices = createSelector(getTransfersSortedByPrice, transfers =>
  flatten(transfers.map(transfer => toSafeInteger(transfer.Amount)))
);

export const getTransferMinPrice = createSelector(getTransferPrices, prices => {
  const price = min(prices) || 0;
  return price - (price % 50);
});

export const getTransferMaxPrice = createSelector(getTransferPrices, prices => {
  const price = max(prices) || 0;
  return price - (price % 50) + 50;
});

export const getFilteredTransfers = createSelector(
  getTransfersSortedByPrice,
  getFiltersTransfer,
  (transfers, filters) => {
    return transfers.filter(transfer => {
      return (
        transfer.Amount <= filters.price.max + 10 &&
        transfer.Amount >= filters.price.min - 10 &&
        (toSafeInteger(transfer.NumberOfDoor) >= filters.doors || filters.doors === 0) &&
        (toSafeInteger(transfer.seats) === filters.seats || filters.seats === 0) &&
        (transfer.TransmissionType === filters.transmission || filters.transmission === "all")
      );
    });
  }
);

export const getSearchedTransfers = createSelector(
  getFilteredTransfers,
  getFiltersTransfer,
  (transfers, filters) => {
    const { input, type, searchable } = filters.search;
    if (input.length < 3) {
      return transfers;
    }

    const keywords = input.split(" ");

    return transfers.filter(transfer =>
      keywords.every(word =>
        keys(transfer).some(param => {
          if (transfer[param] && includes(searchable, param)) {
            return type === "startsWith"
              ? startsWith(toLower(transfer[param]), toLower(word))
              : includes(toLower(transfer[param]), toLower(word));
          }
          return false;
        })
      )
    );
  }
);

export const getTransferUi = createSelector(getTransferState, state => state.ui);

export const getTransferModalIsOpen = createSelector(getTransferUi, ui => ui.modalIsOpen);

export const getTransfersSearch = createSelector(getTransferState, state => state.search);
