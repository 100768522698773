import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import reverse from "lodash-es/reverse";
import { utc } from "moment";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPnrHotel } from "../../interfaces/IPnr";
import { IUser } from "../../interfaces/IUser";
import Thumbnail from "../../libs/Image/Thumbnail";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { findAirportByCode } from "../../utils/airportFunctions";
import { dayMonth, pwc } from "../../utils/helpers";
import ButtonWithConfirm from "../confirmation/ButtonWithConfirm";
import Dates from "../Dates";
import Gallery from "../Gallery";
import BookingDetailsModal from "../modals/BookingDetailsModal";
import CancellationPolicyModal from "../modals/CancellationPolicyModal";
import ChangeDateModal from "../modals/ChangeDateModal";
import HotelDetailsModal from "../modals/HotelDetailsModal";
import MailConfirmationModal from "../modals/MailConfirmationModal";
import { Busy } from "../snippets/Busy";
import Rating from "../snippets/Rating";
import PnrHistory from "./PnrHistory";
import PnrPayment from "./PnrPayment";
import PnrPeople from "./PnrPeople";

interface IProps {
  pnr: IPnrHotel;
  user: IUser;
  reload(pnr: IPnrHotel): any;
  updatePnr(pnr: string, status: string): void;
}

const PnrHotel: FC<IProps> = ({ pnr, user, reload, updatePnr }) => {
  const { t } = useTranslation();
  const [cancellationIsActive, setCancellationIsActive] = useState(false);
  const [bookingDetailsIsActive, setBookingDetailsIsActive] = useState(false);
  const [hotelDetailsIsActive, setHotelDetailsIsActive] = useState(false);
  const [confirmModalIsActive, setConfirmModalIsActive] = useState(false);
  const [changeDateModalIsActive, setChangeDateModalIsActive] = useState(false);
  const [galleryIsActive, setGalleryIsActive] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const pnrActions = useMemo(() => {
    return pnr.services ? pnr.services.map(s => s.uri.replace(/\//g, "")) : [];
  }, [pnr.services]);

  const cancellation = useMemo(() => {
    const now = utc();
    const policies = pnr.offer.cancellationPolicies;

    if (policies) {
      const policy = reverse(policies).find(p => utc(p.date) < now);
      if (policy) {
        return (
          <div className="cancellation-message">
            <p>{t("pnr.cancelPnr")}</p>
            <p>
              {t("pnr.cancelPnrPenalty")}: {pwc(policy.amount, policy.currency)}
            </p>
            <p>({policy.description})</p>
          </div>
        );
      }
    }

    return (
      <div className="cancellation-message">
        <p>{t("pnr.cancelPnr")}</p>
        <p>
          {t("pnr.cancelPnrPenalty")}: {t("pnr.cancelPnrFree")}
        </p>
      </div>
    );
  }, [pnr.offer.cancellationPolicies, t]);

  const reloadHandler = useCallback(() => {
    setIsBusy(true);
    reload(pnr)
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [pnr, reload]);

  // const confirmationEmail = () => {
  //   axios.post("mailservice", {
  //     action_type: "hotel",
  //     comments: "test comment",
  //     email_type: "reconfirmation",
  //     mailto: "kubilen.simsek@pisoco.com",
  //     offer_guid: pnr.offer.OfferId,
  //     pnr: pnr.pnr,
  //     replyto: "kubilen.simsek@pisoco.com",
  //     title: "Confirmation Email"
  //   });
  // };

  const cancelPnr = useCallback(async () => {
    setIsBusy(true);
    const log = new Logger(t("pnr.cancelPending"));
    try {
      const { data } = await api().post("cancelpnr", pnr);
      if (data.cancelled) {
        log.success(t("pnr.cancelSuccess"));
        updatePnr(pnr.pnr, "CANCELLING");
        reloadHandler();
      } else {
        log.warning(data.message);
      }
    } catch (e) {
      log.error(e);
    }
    setIsBusy(false);
  }, [t, pnr, updatePnr, reloadHandler]);

  const mailConfirmToggle = useCallback(() => {
    setConfirmModalIsActive(x => !x);
  }, []);

  const changeDateToggle = useCallback(() => {
    setChangeDateModalIsActive(x => !x);
  }, []);

  const dates = useMemo(
    () => [
      {
        date: pnr.offer.CheckInDate,
        direction: "0"
      },
      {
        date: pnr.offer.CheckOutDate,
        direction: "1"
      }
    ],
    [pnr]
  );

  const cx = classNames("pnr-details-hotel", {
    [(pnr.status || "").toLowerCase()]: pnr.status
  });

  return (
    <div className="pnr-container">
      {isBusy && <Busy />}
      <CancellationPolicyModal
        active={cancellationIsActive}
        policies={pnr.offer.cancellationPolicies}
        onClose={() => setCancellationIsActive(false)}
      />
      <HotelDetailsModal
        active={hotelDetailsIsActive}
        HotelCode={pnr.offer.HotelCode!}
        source={pnr.source}
        onClose={() => setHotelDetailsIsActive(false)}
      />
      <BookingDetailsModal
        active={bookingDetailsIsActive}
        details={pnr.bookingDetails!}
        onClose={() => setBookingDetailsIsActive(false)}
      />
      {confirmModalIsActive && (
        <MailConfirmationModal
          offer_guid={pnr.offer.OfferId}
          action_type="hotel"
          email_type="reconfirmation"
          mailto={pnr.offer.email}
          replyto={user.useremail}
          pnr={pnr.pnr}
          onClose={mailConfirmToggle}
        />
      )}
      {changeDateModalIsActive && (
        <ChangeDateModal
          offer_guid={pnr.offer.OfferId}
          action_type="hotel"
          email_type="datechange"
          pnr={pnr.pnr}
          onClose={changeDateToggle}
          checkInDate={pnr.offer.CheckInDate}
          checkOutDate={pnr.offer.CheckOutDate}
          reload={reloadHandler}
          mailto={pnr.offer.email!}
          replyto={user.useremail}
        />
      )}
      {galleryIsActive && pnr.offer.images && (
        <Gallery images={pnr.offer.images!} onClose={() => setGalleryIsActive(true)} />
      )}
      <div className="pnr-actions">
        {pnrActions.includes("cancelpnr") && (
          <ButtonWithConfirm
            message={cancellation}
            actions={[
              {
                color: "success",
                handler: cancelPnr,
                text: "Cancel Pnr"
              }
            ]}
          >
            {t("button.cancel")}
          </ButtonWithConfirm>
        )}
        {pnrActions.includes("mailservice") && (
          <button onClick={mailConfirmToggle}>{t("button.confirm")}</button>
        )}
        {pnrActions.includes("changepnr") && (
          <button onClick={changeDateToggle}>{t("button.changeDate")}</button>
        )}
      </div>
      <div className="is-scrollable">
        <div className="pnr-details">
          <div className="flex j-between sub-title">
            <div>
              <p className="c-primary">
                <span className="text fs-med">PNR:</span> <span className="text bold">{pnr.pnr}</span>
              </p>
            </div>
            <div>
              <p className="c-primary pnr-status">
                <span className="text fs-med">{t("generic.status")}:</span>
                <span className={pnr.status ? pnr.status.toLowerCase() : ""}>&nbsp;{pnr.status}</span>
              </p>
              <p className="c-primary fs-std align-right">
                {t("generic.source")}: {pnr.source}
              </p>
            </div>
          </div>
          <div className={cx}>
            <div className="pnr-flight flex a-center">
              <span className="c-primary">
                <FontAwesomeIcon icon={["fas", "home"]} />
              </span>
              <div className="content full-width">
                <div className="flex a-center j-even fill-space">
                  <p className="fs-lrg-m c-primary">
                    {dayMonth(pnr.offer.CheckInDate)}
                    {" - "}
                    {dayMonth(pnr.offer.CheckOutDate)}
                  </p>
                  <span className="middot c-primary" />
                  <span>
                    <p className="fs-prc c-primary">{pnr.offer.HotelName}</p>
                    <p className="fs-prc c-primary">{findAirportByCode(pnr.offer.CityCode).state}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="hotel box is-relative">
              {pnr.offer.source === "AGREEMENT" && (
                <div className="promotion" data-tip="Agreement">
                  <FontAwesomeIcon flip="horizontal" icon={["fas", "tag"]} />
                </div>
              )}
              <div className="card flex column-gutter">
                <div
                  className={`image-container no-shrink${
                    pnr.offer.images ? " pointer" : ""
                  } is-mobile-hidden`}
                  onClick={() => setGalleryIsActive(true)}
                >
                  <Thumbnail width={100} height={75} rounded image={pnr.offer.HotelImageUrl} />
                  <div className="booking-actions">
                    {pnr.bookingDetails && (
                      <button
                        className="clean-button"
                        data-tip="Booking Details"
                        onClick={() => setBookingDetailsIsActive(true)}
                      >
                        <FontAwesomeIcon icon={["far", "ticket-alt"]} />
                      </button>
                    )}
                    <button
                      className="clean-button"
                      data-tip="Hotel Details"
                      onClick={() => setHotelDetailsIsActive(true)}
                    >
                      <FontAwesomeIcon icon={["fas", "home"]} />
                    </button>
                  </div>
                </div>
                <div className="info fill-space">
                  <p className="name fs-prc">{pnr.offer.HotelName}</p>
                  <Rating rating={pnr.offer.Rating} />
                  <p className="fs-med">{pnr.offer.Address}</p>
                  <p className="fs-med">T: {pnr.offer.telephone}</p>
                  <p className="fs-med">E: {pnr.offer.email}</p>
                  <p className="fs-med">
                    {t("admin.room.type")}: {pnr.offer.RoomType}
                  </p>
                  <p className="fs-med">
                    {t("admin.room.board")}: {pnr.offer.Board}
                  </p>
                  <p className="fs-med">
                    {t("voucher.roomCount")}: {pnr.offer.roomNo}
                  </p>
                  <div className="pnr-refundable" onClick={() => setCancellationIsActive(true)}>
                    {pnr.offer.cancellationPolicies && (
                      <p className="align-right fs-small">
                        {t("pnr.cancelPnrBefore")}
                        <span className="bold">
                          {" "}
                          {utc(pnr.offer.cancellationPolicies[0].date).format("DD MMM YYYY")}
                        </span>
                      </p>
                    )}
                    {pnr.offer.isNonRefundable === true && (
                      <p className="danger fs-med bold">{t("generic.nonrefundable")}</p>
                    )}
                    {pnr.offer.isNonRefundable === false && (
                      <p className="success fs-lrg bold">{t("generic.refundable")}</p>
                    )}
                    <p className="fs-note c-gray source-note">{pnr.offer.source}</p>
                  </div>
                </div>
                <Dates dates={dates} />
              </div>
            </div>
          </div>
        </div>
        <PnrPayment payments={pnr.payment} />
        {pnr.offer && <PnrPeople pnr={pnr as IPnrHotel} />}
        <PnrHistory pnr={pnr} />
      </div>
    </div>
  );
};

export default PnrHotel;
