import React, { FC, useContext } from "react";
import FlightRoute from "./FlightRoute";
import HotelRoute from "./HotelRoute";
import { SearchContext } from "./Search";

const SearchRoutes: FC = () => {
  const [search] = useContext(SearchContext);

  return (
    <div className="routes">
      {!search.filters.hasFlights ? (
        <HotelRoute />
      ) : (
        search.routes.map((route, key) => <FlightRoute key={key} index={key} />)
      )}
    </div>
  );
};

export default SearchRoutes;
