import React, { FC, useCallback } from "react";
import Toggle from "react-toggle";

interface IProps {
  value: boolean;
  propKey: string;
  labels: [string, string];

  onChange(newFilter: { [key: string]: boolean }): void;
}

const FilterRadio: FC<IProps> = ({ value, propKey, labels, onChange }) => {
  const radioHandler = useCallback(() => {
    onChange({
      [propKey]: !value
    });
  }, [onChange, propKey, value]);

  return (
    <div className="filter-radio-element">
      <span className={`text${!value ? " active" : ""}`}>{labels[0]}</span>
      <Toggle icons={false} checked={value} onChange={radioHandler} />
      <span className={`text${value ? " active" : ""}`}>{labels[1]}</span>
    </div>
  );
};

export default FilterRadio;
