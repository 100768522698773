import i18n from "i18next";
import map from "lodash-es/map";
import { v5 as uuid } from "uuid";
import { ISearch } from "../../interfaces/ISearch";
import { IService } from "../../interfaces/IService";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  TRANSFER_CLEAR,
  TRANSFER_FETCH_EMPTY,
  TRANSFER_FETCH_FULFILLED,
  TRANSFER_FETCH_PENDING,
  TRANSFER_FETCH_REJECTED,
  TRANSFER_MODAL_CLOSE,
  TRANSFER_MODAL_OPEN,
  TRANSFER_SEARCH_SET
} from "../../utils/constants";
import { getTransferServices } from "../selectors/serviceSelectors";
import { setFilterDefaults } from "./filterActions";

export const openTransfersModal = () => ({
  type: TRANSFER_MODAL_OPEN
});

export const closeTransfersModal: AC = () => (dispatch, getState) => {
  const transferServices = getTransferServices(getState());

  dispatch({
    type: TRANSFER_MODAL_CLOSE
  });
  dispatch({
    type: TRANSFER_CLEAR,
    payload: transferServices
  });
};

export const transfersSetSearch = (payload: ISearch) => ({
  type: TRANSFER_SEARCH_SET,
  payload
});

export const transfersMakeSearch: AC = (search: ISearch) => (dispatch, getState) => {
  const state = getState();
  const transferServices = getTransferServices(state);
  search.guid = uuid(JSON.stringify(search) + Date.now(), uuid.URL);
  dispatch(transfersSetSearch(search));
  dispatch(fetchTransfers(search, transferServices));
};

export const clearTransfers: AC = (services: IService[]) => dispatch => {
  return dispatch({
    payload: map(services, "key"),
    type: TRANSFER_CLEAR
  });
};

export const fetchTransfers: AC = (search: ISearch, services: IService[]) => dispatch => {
  dispatch(clearTransfers(services));

  for (const service of services) {
    const config: any = service.fullLink ? { baseUrl: "" } : {};

    let logger: Logger;

    logger = new Logger(i18n.t("transfer.searchPending"), {
      prefix: service.key
    });

    dispatch({
      service: service.key,
      type: `${service.key}_${TRANSFER_FETCH_PENDING}`
    });

    api()
      .post(service.uri, search, config)
      .then(({ data }) => {
        if (data.transfers && data.transfers.length > 0) {
          if (logger) {
            logger.success(i18n.t("transfer.searchSuccess"));
          }
          dispatch({
            payload: data.transfers,
            service: service.key,
            type: `${service.key}_${TRANSFER_FETCH_FULFILLED}`
          });
          dispatch(setFilterDefaults());
        } else {
          if (logger) {
            logger.warning(i18n.t("transfer.searchEmpty"));
          }
          dispatch({
            service: service.key,
            type: `${service.key}_${TRANSFER_FETCH_EMPTY}`
          });
        }
      })
      .catch(e => {
        logger.error(e);
        dispatch({
          service: service.key,
          type: `${service.key}_${TRANSFER_FETCH_REJECTED}`
        });
      });
  }
};
