import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { IHotel } from "../../../interfaces/IHotel";
import { toggleHotelDetailsScreen } from "../../../store/actions/uiActions";

interface IProps {
  hotel: IHotel;
}

const HotelDetailsScreenButton: FC<IProps> = ({ hotel }) => {
  const dispatch = useDispatch();

  const toggleHandler = useCallback(() => {
    dispatch(toggleHotelDetailsScreen(hotel));
  }, [dispatch, hotel]);

  return (
    <button className="button info" onClick={toggleHandler}>
      <FontAwesomeIcon icon={["fas", "map"]} />
    </button>
  );
};

export default HotelDetailsScreenButton;
