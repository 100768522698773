import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import map from "lodash-es/map";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import ParentRows from "../../components/ParentRows";
import ReportDatePicker from "../../components/ReportDatePicker";
import useObjectState from "../../hooks/useObjectState";
import { ISaleFields, ISaleParent, ISalesReportResponse } from "../../interfaces/ISalesReport";
import DefaultLayout from "../../layouts/DefaultLayout";
import { api } from "../../services/api";

const SalesReport: FC = () => {
  const { t } = useTranslation();

  const [fields, setFields] = useState<ISaleFields>();
  const [sales, setSales] = useState<ISaleParent[]>([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useObjectState({
    company_code: "JP109516",
    currency: "TRY",
    edate: moment().format("YYYYMMDD"),
    sdate: moment().format("YYYY0101")
  });

  const dateHandler = useCallback(
    (field: string) => (value: string) => {
      setPayload({
        [field]: value
      });
    },
    [setPayload]
  );

  const fetchReports = useCallback(async () => {
    setLoading(true);
    setSales([]);
    try {
      const { data } = await api().post<ISalesReportResponse>("rptsales", payload);
      const hasData = get(data, "sales.length");
      if (hasData) {
        setSales(data.sales);
        setFields(data.fields);
      }
    } catch {}
    setLoading(false);
  }, [payload]);

  return (
    <DefaultLayout>
      <div className="container main">
        <div className="reports">
          <div className="report-page-title">
            <h2>{t("report.title.sales")}</h2>
          </div>
          <div className="reports-header">
            <div className="report-filters flex inline a-center">
              <ReportDatePicker
                startDate={payload.sdate}
                endDate={payload.edate}
                pickEndDate={dateHandler("edate")}
                pickStartDate={dateHandler("sdate")}
              />

              <button disabled={loading} onClick={fetchReports}>
                {loading ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
              </button>
            </div>
            {loading && <Loading />}
            {/*{sales.length > 0 && <SpreadsheetButton filename="AgencyCommission"*/}
            {/*                                        reports={sales}*/}
            {/*                                        fields={fields}*/}
            {/*                                        total={true} />}*/}
            <div className="reports-header-row" />
          </div>
          <div className="reports-table-container">
            {sales.length > 0 && (
              <table id="report-table" className="table table-sales is-fullwidth">
                <thead>
                  <tr>
                    <th />
                    {map(fields, (field, index) => (
                      <th key={index}>{field.title}</th>
                    ))}
                    <th>{t("report.budget")}</th>
                  </tr>
                </thead>
                <tbody>
                  <ParentRows depth={0} items={sales} fields={fields!} />
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default SalesReport;
