import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useMemo } from "react";
import { Busy } from "../../components/snippets/Busy";
import useClickDetect from "../../hooks/useClickDetect";

interface IProps {
  bordered?: true;
  className?: string;
  closeOnOutsideClick?: boolean;
  colorScheme?: string;
  isBusy?: boolean;
  isFullScreen?: boolean;
  maxWidth?: number;
  width?: number;
  padding?: boolean;
  onClose?(): void;
}

const ModalContainer: FC<IProps> = ({
  children,
  bordered,
  className = "",
  closeOnOutsideClick = true,
  colorScheme = "",
  isBusy,
  isFullScreen,
  maxWidth,
  width,
  padding = true,
  onClose
}) => {
  const $el = useClickDetect(() => closeOnOutsideClick && onClose && onClose());

  const classes = useMemo(
    () =>
      `modal-container ${className} ${colorScheme}${isFullScreen ? " full-screen" : ""}${
        padding ? "" : " no-padding"
      }`,
    [className, colorScheme, isFullScreen, padding]
  );

  const styles: CSSProperties = useMemo(() => {
    return {
      maxWidth,
      width
    };
  }, [width, maxWidth]);

  return (
    <div className={classes}>
      <div ref={$el} className={`modal${bordered ? " bordered" : ""}`} style={styles}>
        {!!onClose && (
          <button className="modal-dismiss pointer c-primary" onClick={() => onClose()}>
            <FontAwesomeIcon icon={["far", "times"]} />
          </button>
        )}
        {isBusy && <Busy />}
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
