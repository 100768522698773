import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import set from "lodash-es/set";
import React, { FC, useContext } from "react";
import { SearchContext } from "./Search";

interface IProps {
  disabled?: boolean[];
  label: string;
  propKey: string;
  value: boolean;
}

const Checkbox: FC<IProps> = ({ disabled = [], label, propKey, value }) => {
  const [search, setSearch] = useContext(SearchContext);

  const handler = () => {
    if (disabled.every(x => !x)) {
      setSearch(set({ ...search }, propKey, !value));
    }
  };

  const cx = classNames("checkbox", {
    disabled: disabled.every(x => !x)
  });

  return (
    <div className={cx} onClick={handler}>
      <span>
        {value ? (
          <FontAwesomeIcon icon={["fas", "check-square"]} />
        ) : (
          <FontAwesomeIcon icon={["far", "square"]} />
        )}
      </span>
      <p className="label">{label}</p>
    </div>
  );
};

export default Checkbox;
