import React from "react";
import { IDestination } from "../interfaces/IFlight";
import { findAirportByCode } from "../utils/airportFunctions";
import { timeParser } from "../utils/helpers";
import Dates from "./Dates";
import FlightLogo from "./snippets/FlightLogo";
import AirlineCard from "./snippets/Operator";
import RouteSvg from "./svg/RouteSvg";

interface IDestinationCardComponentProps {
  destination: IDestination;
  detailed: boolean;
  transit: boolean;
}

export function DestinationCard({ destination: dest, detailed, transit }: IDestinationCardComponentProps) {
  const depAirport = findAirportByCode(dest.DepartureAirport);
  const arrAirport = findAirportByCode(dest.ArrivalAirport);

  return !detailed ? (
    <div className="destination flex fill-space column-gutter">
      <Dates
        className="self-baseline"
        dates={[
          {
            date: dest.DepDate,
            direction: dest.DirectionId
          }
        ]}
      />
      <div className="operators">
        <AirlineCard
          code={dest.MarketingAirline}
          name={dest.MarketingAirlineName || dest.MarketingAirlineName}
          no={dest.FlightNumber}
        />
        {dest.MarketingAirline !== dest.OperatingAirline && (
          <AirlineCard
            code={dest.OperatingAirline}
            name={dest.OperatingAirlineName || dest.OperatingAirlineName}
            title="Operating By:"
          />
        )}
        <p className="fs-std">{dest.ClassDesc}</p>
      </div>
      <div className="route-container">
        <div className="route flex d-column j-center fill-space">
          <div className="time-container">
            <p className="fs-std elapsed">{dest.ElapsedTime}</p>
            <RouteSvg index={dest.DirectionId} transit={transit} />
            <div className="time flex j-between">
              <p className="fs-med">{timeParser(dest.DepTime)}</p>
              <p className="fs-med">{timeParser(dest.ArrTime)}</p>
            </div>
          </div>
          <div className="location flex j-between">
            <div className="from">
              <p className="fs-med">{depAirport.state}</p>
              <p className="fs-small">{depAirport.name}</p>
            </div>
            <div className="to">
              <p className="fs-med">{arrAirport.state}</p>
              <p className="fs-small">{arrAirport.name}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="destination flex d-column">
      <div className="flex a-center column-gutter">
        <Dates
          className="self-baseline"
          dates={[
            {
              date: dest.DepDate,
              direction: dest.DirectionId
            }
          ]}
        />
        <div className="operators flex j-around a-center">
          <div>
            <p className="airline-title">
              {dest.MarketingAirline !== dest.OperatingAirline && "Marketing Airline:"}
            </p>
            <div className="flex column-gutter a-center">
              <p className="fs-hug">
                <FlightLogo code={dest.MarketingAirline} className="text-image" />
              </p>
              <p className="fs-std">{dest.MarketingAirlineName}</p>
              <p className="fs-lrg nowrap">
                {dest.MarketingAirline} {dest.FlightNumber}
              </p>
            </div>
          </div>
          {dest.MarketingAirline !== dest.OperatingAirline && (
            <>
              <span style={{ margin: "0 10px" }}>|</span>
              <div>
                <p className="airline-title">Operating Airline:</p>
                <div className="flex column-gutter a-center">
                  <p className="fs-hug">
                    <FlightLogo code={dest.OperatingAirline} className="text-image" />
                  </p>
                  <p className="fs-std">{dest.OperatingAirlineName || dest.OperatingAirline}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="route-container fill-space flex a-center">
        <div className="route flex d-column j-center fill-space">
          <div className="time-container">
            <p className="fs-small elapsed">{dest.ElapsedTime}</p>
            <RouteSvg index={dest.DirectionId} transit={transit} />
            <div className="time flex j-between">
              <p className="fs-time">{timeParser(dest.DepTime)}</p>
              <p className="fs-time">{timeParser(dest.ArrTime)}</p>
            </div>
          </div>
          <div className="location flex j-between">
            <div className="from">
              <p className="fs-std">{depAirport.state}</p>
              <p className="fs-small">{depAirport.name}</p>
            </div>
            <div className="to">
              <p className="fs-std">{arrAirport.state}</p>
              <p className="fs-small">{arrAirport.name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="fill-space" />
      <div className="detailed-footer flex j-between">
        <p className="fs-small">Class: {dest.ClassDesc}</p>
        <p className="fs-small">Airplane: {dest.AirEquip}</p>
      </div>
    </div>
  );
}

export default DestinationCard;
