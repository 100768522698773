import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, ReactNode, useEffect, useState } from "react";
import useClickDetect from "../hooks/useClickDetect";

interface IProps {
  className?: string;
  closeOnOutsideClick?: boolean;
  direction: "horizontal" | "vertical";
  opener?: any;
  title?: ReactNode;
}

const CollapsibleContainer: FC<IProps> = ({
  children,
  closeOnOutsideClick,
  className = "",
  direction,
  opener,
  title
}) => {
  const $el = useClickDetect({
    excludes: ["hotel-info-gallery", "checkout-container"],
    outer: () => closeOnOutsideClick && setIsCollapsed(true)
  });
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    setIsCollapsed(false);
  }, [opener]);

  const cx = classNames(className, "collapsable", {
    collapsed: isCollapsed,
    horizontal: direction === "horizontal",
    vertical: direction === "vertical"
  });

  return (
    <div ref={$el} className={cx}>
      <div className="title">
        <button className="collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? (
            <FontAwesomeIcon icon={["fas", direction === "horizontal" ? "chevron-right" : "chevron-down"]} />
          ) : (
            <FontAwesomeIcon icon={["fas", direction === "horizontal" ? "chevron-left" : "chevron-up"]} />
          )}
        </button>
        {title && title}
      </div>
      <div className="items flex">{children}</div>
    </div>
  );
};

export default CollapsibleContainer;
