import isArray from "lodash-es/isArray";
import isUndefined from "lodash-es/isUndefined";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  choices: any[];
  value: any;
  label: string;
  allValue?: any;
  onChange(newValue: any): void;
}

const FilterChoice: FC<IProps> = ({ allValue, choices, label, value, onChange }) => {
  const { t } = useTranslation();

  const choiceHandler = useCallback(
    (newValue: any) => () => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <div className="filter">
      <span className="filter-title">{label}</span>
      <div className="filter-choices">
        {!isUndefined(allValue) && (
          <div
            className={`filter-choice text ${value === allValue && "selected"}`}
            onClick={choiceHandler(allValue)}
          >
            {t("generic.all")}
          </div>
        )}
        {choices.map(choice =>
          isArray(choice) ? (
            <div
              className={`filter-choice text ${choice[0] === value && "selected"}`}
              key={choice[0]}
              onClick={choiceHandler(choice[0])}
            >
              {choice[1]}
            </div>
          ) : (
            <div
              className={`filter-choice text ${choice === value && "selected"}`}
              key={choice}
              onClick={choiceHandler(choice)}
            >
              {t(["filter.car." + choice.toString(), choice.toString()])}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default FilterChoice;
