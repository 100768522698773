import React, { FC } from "react";

interface IProps {
  color: string;
}

const FlightSvgMedia: FC<IProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.92 16.92">
    <title>Flight Svg</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle fill={color} cx="8.46" cy="8.46" r="8.46" />
        <path
          fill="#fff"
          d="M7.27,12.58l0-1.6L3.13,8.18A.23.23,0,0,1,3,8l.19-1.11a.23.23,0,0,1,.07-.12l0,0a.2.2,0,0,1,.18,0L7.41,7.93l.08-2.58L6.28,4.61a.23.23,0,0,1-.11-.2l0-1a.24.24,0,0,1,.08-.16h0a.23.23,0,0,1,.19,0l2.19.38,2.21-.25a.22.22,0,0,1,.18.06.24.24,0,0,1,.07.17l0,1a.23.23,0,0,1-.07.16l0,0-1.25.67L9.64,8l4-1a.24.24,0,0,1,.2,0,.25.25,0,0,1,.08.18l0,1.28a.24.24,0,0,1-.12.19l-4.18,2.4,0,1.6c0,1-.54,2.06-1.18,2S7.24,13.54,7.27,12.58Z"
        />
      </g>
    </g>
  </svg>
);

export default FlightSvgMedia;
