import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickDetect from "../hooks/useClickDetect";

interface IProps {
  current: string;
  sortKeys: string[];

  onChange(payload: string): void;
}

const Sort: FC<IProps> = ({ current, sortKeys, onChange }) => {
  const { t } = useTranslation();

  const $el = useClickDetect(() => setShowList(false));
  const [showList, setShowList] = useState(false);

  const toggleList = useCallback(() => {
    setShowList(x => !x);
  }, []);

  const closeList = useCallback(() => {
    setShowList(false);
  }, []);

  const selectHandler = useCallback(
    (key: string) => () => {
      onChange(key);
      closeList();
    },
    [closeList, onChange]
  );

  return (
    <div className="sorter" ref={$el}>
      <h3 data-tip="Sorted By" className="current-sorter" onClick={toggleList}>
        {t(`sort.${current}`)}
      </h3>
      {showList && (
        <div className="sorter-list">
          {sortKeys.map(k => (
            <p
              className={`sorter-item${current === k ? " selected" : ""}`}
              key={k}
              onClick={selectHandler(k)}
            >
              {t(`sort.${k}`)}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sort;
