import floor from "lodash-es/floor";
import React, { CSSProperties, FC, useEffect, useMemo } from "react";
import tooltip from "react-tooltip";
import { currencyParser } from "../utils/helpers";

interface IProps {
  currency: string;
  total: number;
  remaining: number;
}

const ProgressBar: FC<IProps> = ({ currency, total, remaining }) => {
  const percentage = useMemo(() => {
    return total ? floor((total * 100) / remaining, 2) + "%" : "0%";
  }, [total, remaining]);

  const tip = useMemo(() => {
    return `${total - remaining}${currencyParser(currency)} / ${total}${currencyParser(currency)}`;
  }, [total, remaining, currency]);

  useEffect(() => {
    tooltip.rebuild();
  }, [percentage]);

  const style: CSSProperties = {
    background: `linear-gradient(to left, var(--warning) ${percentage}, var(--success) 0%)`
  };
  return <div className="progress-bar" style={style} data-tip={tip} data-for="left-sided" />;
};

export default ProgressBar;
