import { utc } from "moment";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Expense from "../../components/reports/Expense";
import ExpensesWaterFallChart from "../../components/reports/ExpensesWaterFallChart";
import { Busy } from "../../components/snippets/Busy";
import useDataFetch from "../../hooks/useDataFetch";
import { ICorporateReportMain } from "../../interfaces/IReport";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getUser } from "../../store/selectors/userSelectors";
import { currencyParser } from "../../utils/helpers";

const CompanyPage: FC = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const [currency] = useState("TRY");
  const { data, loading } = useDataFetch<ICorporateReportMain>({
    dataKey: "report",
    method: "post",
    payload: {
      company_code: user.companycode,
      currency,
      edate: utc().format("YYYYMMDD"),
      sdate: utc().year() + "0101"
    },
    url: "rptcorpmain"
  });

  const chartData = useMemo(() => {
    if (data) {
      return [
        {
          amount: data.TotalAmount,
          colorIndex: 1,
          currency: currencyParser(data.currency),
          displayAmount: data.TotalAmount,
          open: 0,
          service: t("corpReport.main.expense.totalSimple"),
          stepAmount: data.TotalAmount
        },
        {
          amount: data.TotalAmount - data.HotelAmount,
          colorIndex: 2,
          currency: currencyParser(data.currency),
          displayAmount: data.HotelAmount,
          open: data.TotalAmount,
          service: t("corpReport.main.service.hotel"),
          stepAmount: data.TotalAmount - data.HotelAmount
        },
        {
          amount: data.TotalAmount - data.HotelAmount - data.FlightAmount,
          colorIndex: 3,
          currency: currencyParser(data.currency),
          displayAmount: data.FlightAmount,
          open: data.TotalAmount - data.HotelAmount,
          service: t("corpReport.main.service.flight"),
          stepAmount: data.TotalAmount - data.HotelAmount - data.FlightAmount
        },
        {
          amount: data.TotalAmount - data.HotelAmount - data.FlightAmount - data.CarAmount,
          colorIndex: 4,
          currency: currencyParser(data.currency),
          displayAmount: data.CarAmount,
          open: data.TotalAmount - data.HotelAmount - data.FlightAmount,
          service: t("corpReport.main.service.car"),
          stepAmount: data.TotalAmount - data.HotelAmount - data.FlightAmount - data.CarAmount
        }
      ];
    }
  }, [data, t]);

  return (
    <DefaultLayout>
      <div className="container is-scrollable">
        {loading && <Busy />}
        {data && (
          <div className="dashboard main homepage">
            <div className="expenses">
              <Expense
                amount={data.TotalAmount}
                currency="TRY"
                title={t("corpReport.main.expense.total")}
                icon={["fas", "wallet"]}
              />
              {/*<Expense*/}
              {/*  amount={data.TotalCash}*/}
              {/*  currency="TRY"*/}
              {/*  title="Nakit Harcama YTD"*/}
              {/*  icon={["fas", "money-bill"]}*/}
              {/*/>*/}
              <Expense
                amount={data.TotalCard}
                currency="TRY"
                title={t("corpReport.main.expense.credit")}
                icon={["fas", "credit-card-front"]}
              />
              <Expense
                amount={data.TotalAccount}
                currency="TRY"
                title={t("corpReport.main.expense.cash")}
                icon={["fas", "badge-dollar"]}
              />
            </div>
            <div className="graph" style={{ width: 600 }}>
              <h1>{t("corpReport.main.title")}</h1>
              <ExpensesWaterFallChart data={chartData!} currency={currency} />
            </div>
            <div className="expenses">
              <Expense amount={data.FlightAmount} currency="TRY" title={t("corpReport.main.trip.flight")} />
              <Expense amount={data.HotelAmount} currency="TRY" title={t("corpReport.main.trip.hotel")} />
              <Expense amount={data.CarAmount} currency="TRY" title={t("corpReport.main.trip.car")} />
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default CompanyPage;
