import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IVisa } from "../../interfaces/IBasket";

interface IPaymentVisaComponentProps {
  visa: IVisa;
}
export default function PaymentVisa({ visa }: IPaymentVisaComponentProps) {
  return (
    <div className="other-item flex a-center">
      <div className="item-icon">
        <FontAwesomeIcon icon={["far", "taxi"]} />
      </div>
      <div className="contents full-width">
        <div className="content flex a-center">
          <p className="fs-med">{visa.title}</p>
          <p className="fs-med">{visa.description}</p>
        </div>
      </div>
    </div>
  );
}
