import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRoom } from "../../interfaces/IHotel";
import { COLORS } from "../../utils/constants";
import { pwc } from "../../utils/helpers";
import Dates from "../Dates";
import BasketLine from "../snippets/BasketLine";

interface IProps {
  room: IRoom;
  override?: boolean;
  custguid?: Array<{
    custguid: string;
  }>;

  deleteItem(OfferId: string): void;
}

const BasketRoom: FC<IProps> = ({ custguid, room, override, deleteItem }) => {
  const { t } = useTranslation();

  const dates = [
    { date: room.CheckInDate, direction: "0" },
    { date: room.CheckOutDate, direction: "1" }
  ];

  const line = {
    direction: "0",
    finishColor: COLORS.orange[0],
    startColor: COLORS.blue[0],
    type: "hotel"
  };

  const deleteItemHandler = () => {
    deleteItem(room.OfferId);
  };

  return (
    <div className="hotel box no-padding-right padding-bottom-10 flex d-column">
      <div className="flex">
        <Dates className="no-margin-top" dates={dates} vertical={true} />
        <BasketLine {...line} />
        <div className="description fill-space flex d-column j-even">
          <p className="fs-med">
            {room.CheckInTime} <span className="fs-small">{t("hotel.checkIn")}</span>
          </p>
          <div className="info flex a-center">
            <div
              className="bg-image bg-cover no-shrink"
              style={{ backgroundImage: `url(${room.HotelImageUrl})` }}
            />
            <p className="fs-med">{room.HotelName}</p>
          </div>
          <p className="fs-med">
            {room.CheckOutTime} <span className="fs-small">{t("hotel.checkOut")}</span>
          </p>
        </div>
        <div className="right-side flex d-column">
          <button
            className="remove button cart self-end gutter-bottom left-radius"
            onClick={deleteItemHandler}
          >
            X
          </button>
          <div className="fill-space" />
          <p className="price-tag fs-prc is-centered left-radius">
            {pwc(room.AmountAfterTax, room.HotelCurrency)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasketRoom;
