import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import Car from "../../../components/Car";
import InlineSearchCar from "../../../components/inlinesearches/InlineSearchCars";
import { Statuses } from "../../../components/Statuses";
import FilterCar from "../../../containers/FilterCar";
import { ICar } from "../../../interfaces/ICar";
import { toggleFilterCars } from "../../../store/actions/filterActions";
import {
  getCarSearchDone,
  getCarsResultEmpty,
  getCarsStatuses,
  getGroupedCars
} from "../../../store/selectors/carSelectors";
import { getCustomersValidForBooking } from "../../../store/selectors/customerSelectors";
import { Entries, Merge } from "../../../types";

const Cars: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const customersValidForBooking = useSelector(getCustomersValidForBooking);
  const cars = useSelector(getGroupedCars);
  const isEmpty = useSelector(getCarsResultEmpty);
  const searchIsDone = useSelector(getCarSearchDone);
  const statuses = useSelector(getCarsStatuses);

  const toggleFilter = useCallback(() => {
    dispatch(toggleFilterCars());
  }, [dispatch]);

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: Entries<ICar[]> }>> = useCallback(
    ({ index, data, style }) => (
      <Car
        cars={data[index][1]}
        code={data[index][0]}
        customersValidForBooking={customersValidForBooking}
        style={style}
      />
    ),
    [customersValidForBooking]
  );

  return statuses.some(s => s.fetched || s.fetching) ? (
    <div className="box-container transports">
      <div className="title flex j-between a-center is-relative">
        <span className="result-header-icon c-primary">
          <FontAwesomeIcon icon={["far", "car"]} />
        </span>
        <h1 className="text fs-lrg fill-space c-primary">{t("car.resultTitle")}</h1>
        {searchIsDone ? (
          <>
            <InlineSearchCar />
            <button className="button is-small filter-button" onClick={toggleFilter}>
              <FontAwesomeIcon icon={["fas", "filter"]} />
            </button>
          </>
        ) : (
          <Statuses statuses={statuses} />
        )}
        <FilterCar />
      </div>
      {isEmpty && <p className="not-found-message">{t("search.notFound")}</p>}
      <FixedSizeList
        itemData={cars}
        itemSize={250}
        height={500}
        itemCount={cars.length}
        width="100%"
        className="padding-fix"
      >
        {rowRenderer}
      </FixedSizeList>
    </div>
  ) : (
    <div />
  );
};

export default Cars;
