import React from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import { GOOGLE_MAP_API } from "../utils/constants";

const MapWrapper = compose(
  withProps({
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    googleMapURL: GOOGLE_MAP_API,
    loadingElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ mapRef, ...props }: any) => (
  <GoogleMap ref={mapRef} {...props}>
    {props.children}
  </GoogleMap>
));

export default MapWrapper as any;
