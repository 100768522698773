import capitalize from "lodash-es/capitalize";
import React, { FC, Fragment, useMemo } from "react";
import { NavLink } from "react-router-dom";
import Loading from "../Loading";

interface IProps {
  loading: boolean;
  params: {
    city?: string;
    country?: string;
  };
}

const HotelSpentBreadcrumb: FC<IProps> = ({ loading, params }) => {
  const crumbs = useMemo(() => {
    const steps: any[] = [];
    steps.push(<NavLink to="/company/compliance">World</NavLink>);
    if (params.country) {
      steps.push(
        <NavLink to={`/company/compliance/${params.country}`}>{capitalize(params.country)}</NavLink>
      );
    }
    if (params.city) {
      steps.push(
        <NavLink to={`/company/compliance/${params.country}/${params.city}`}>
          {capitalize(params.city)}
        </NavLink>
      );
    }
    return steps;
  }, [params]);

  return (
    <div className="spent-breadcrumb flex a-center">
      {crumbs.map((c, i) => (
        <Fragment key={i}>
          {!!i && <span className="breadcrumb-separator" />} {c}
        </Fragment>
      ))}
      &nbsp;&nbsp;
      {loading && <Loading />}
    </div>
  );
};

export default HotelSpentBreadcrumb;
