import i18n from "i18next";
import map from "lodash-es/map";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  ACTIVITY_CLEAR,
  ACTIVITY_FETCH_EMPTY,
  ACTIVITY_FETCH_FULFILLED,
  ACTIVITY_FETCH_PENDING,
  ACTIVITY_FETCH_REJECTED
} from "../../utils/constants";
import { getLastSearch } from "../selectors/searchSelectors";
import { getActivityServices } from "../selectors/serviceSelectors";
import { filterReset, setFilterDefaults } from "./filterActions";

export const clearActivities: AC = () => (dispatch, getState) => {
  const services = getActivityServices(getState());
  return dispatch({
    payload: map(services, "key"),
    type: ACTIVITY_CLEAR
  });
};

export const fetchActivities: AC = () => (dispatch, getState) => {
  dispatch<any>(filterReset());

  const state = getState();
  const search = getLastSearch(state);
  const services = getActivityServices(state);

  for (const service of services) {
    const config: any = service.fullLink ? { baseUrl: "" } : {};

    const log = new Logger(i18n.t("activity.searchPending"), {
      prefix: service.key
    });

    dispatch({
      service: service.key,
      type: `${service.key}_${ACTIVITY_FETCH_PENDING}`
    });

    api()
      .post(service.uri, search, config)
      .then(({ data }) => {
        if (data.activities && data.activities.length > 0) {
          log.success(i18n.t("activity.searchSuccess"));
          dispatch({
            payload: data.activities,
            service: service.key,
            type: `${service.key}_${ACTIVITY_FETCH_FULFILLED}`
          });
          dispatch<any>(setFilterDefaults());
        } else {
          log.warning(i18n.t("activity.searchEmpty"));
          dispatch({
            service: service.key,
            type: `${service.key}_${ACTIVITY_FETCH_EMPTY}`
          });
        }
      })
      .catch(e => {
        log.error(e);
        dispatch({
          service: service.key,
          type: `${service.key}_${ACTIVITY_FETCH_REJECTED}`
        });
      });
  }
};
