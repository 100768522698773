import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface IProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = props => {
  if (!props.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
