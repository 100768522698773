import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash-es/debounce";
import React, { FC, useState, useEffect, useCallback } from "react";
import { IAirport } from "../../interfaces/IAirport";
import { IDestinationUpdate } from "../../interfaces/ISearch";
import { api } from "../../services/api";
import { checkKeyCode, clrStr } from "../../utils/helpers";
import { filterableProps } from "./AirportPicker";
import useClickDetect from "../../hooks/useClickDetect";

enum Section {
  airport = "airport",
  hotel = "hotel",
  destination = "destination"
}

export interface ISearchDestination {
  code: string;
  country_code: string;
  index: number;
  latitude: number;
  longitude: number;
  name: string;
  source: string[];
}

interface ISearchHotel {
  availability_score: number;
  code: string;
  index: number;
  latitude: number;
  longitude: number;
  name: string;
  source: string[];
}

type Props = {
  value: string;
  onSelect(payload?: IDestinationUpdate): void;
};

const HotelPicker: FC<Props> = ({ value, onSelect }) => {
  const [currentSection, setCurrentSection] = useState<Section>(Section.airport);
  const [destinations, setDestinations] = useState<ISearchDestination[]>([]);
  const [hotels, setHotels] = useState<ISearchHotel[]>([]);
  const [filteredAirports, setFilteredAirports] = useState<IAirport[]>([]);
  const [index, setIndex] = useState(0);
  const [listIsActive, setListIsActive] = useState(false);
  const $el = useClickDetect(() => setListIsActive(false));

  const requestHandler = useCallback(
    debounce(async (val: string) => {
      if (value.length > 3) {
        const [hotelRes, destinationRes] = await Promise.all([
          api().get(`/hotellisting?key=${val}`),
          api().get(`/destinationlisting?key=${val}`)
        ]);

        if (hotelRes?.data?.length) {
          setHotels(
            hotelRes.data.map((h: ISearchHotel, i: number) => {
              h.index = i;
              return h;
            })
          );
          setListIsActive(true);
        }

        if (destinationRes?.data?.length) {
          setDestinations(
            destinationRes.data.map((d: ISearchHotel, i: number) => {
              d.index = i;
              return d;
            })
          );
          setListIsActive(true);
        }
      }
    }, 500),
    []
  );

  const filterAirports = (val: string) => {
    if (val.length < 3) {
      setFilteredAirports([]);
      setListIsActive(false);
    } else {
      const newAirports = window.airports.filter((airport: IAirport) => {
        return Object.keys(airport).some(k => {
          if (!airport[k] || filterableProps.indexOf(k) === -1) {
            return false;
          }
          return clrStr(airport[k]).startsWith(clrStr(val));
        });
      });
      setFilteredAirports(newAirports);
      setListIsActive(true);
    }
  };

  const selectHandler = useCallback(
    (item: IAirport | ISearchHotel | ISearchDestination) => {
      switch (currentSection) {
        case Section.airport:
          const route = {
            arr: (item as IAirport).code,
            arrMultiAirport: (item as IAirport).multiAirport
          };
          onSelect({ params: { routes: [route] }, mode: "flight" });
          break;
        case Section.hotel:
          onSelect({
            mode: "hotel",
            params: {
              hotel: [{ code: item.code, name: item.name, source: item.source }],
              lat: undefined,
              lon: undefined
            }
          });
          break;
        case Section.destination:
          onSelect({
            mode: "dest",
            params: {
              hotel: undefined,
              lat: (item as any).latitude,
              lon: (item as any).longitude,
              name: item.name
            }
          });
      }
      setDestinations([]);
      setHotels([]);
    },
    [currentSection, onSelect]
  );

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      switch (currentSection) {
        case Section.airport:
          if (checkKeyCode(e, 40)) {
            if (index < filteredAirports.length - 1) {
              setIndex(x => x + 1);
            } else {
              setCurrentSection(Section.hotel);
              setIndex(0);
            }
          } else if (checkKeyCode(e, 38)) {
            if (index > 0) {
              setIndex(x => x - 1);
            } else {
              setCurrentSection(Section.destination);
              setIndex(destinations.length - 1);
            }
          } else if (checkKeyCode(e, 13)) {
            selectHandler(filteredAirports[index]);
          }
          break;
        case Section.hotel:
          if (checkKeyCode(e, 40)) {
            if (index < filteredAirports.length - 1) {
              setIndex(x => x + 1);
            } else {
              setCurrentSection(Section.destination);
              setIndex(0);
            }
          } else if (checkKeyCode(e, 38)) {
            if (index > 0) {
              setIndex(x => x - 1);
            } else {
              setCurrentSection(Section.airport);
              setIndex(filteredAirports.length - 1);
            }
          } else if (checkKeyCode(e, 13)) {
            selectHandler(hotels[index]);
          }
          break;
        case Section.destination:
          if (checkKeyCode(e, 40)) {
            if (index < filteredAirports.length - 1) {
              setIndex(x => x + 1);
            } else {
              setCurrentSection(Section.airport);
              setIndex(0);
            }
          } else if (checkKeyCode(e, 38)) {
            if (index > 0) {
              setIndex(x => x - 1);
            } else {
              setCurrentSection(Section.hotel);
              setIndex(hotels.length - 1);
            }
          } else if (checkKeyCode(e, 13)) {
            selectHandler(destinations[index]);
          }
          break;
      }
    },
    [currentSection, destinations, filteredAirports, hotels, index, selectHandler]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [keyHandler]);

  useEffect(() => {
    if (value) {
      filterAirports(value);
      requestHandler(value);
    } else {
      setListIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return listIsActive ? (
    <ul ref={$el} className="airport-list hotel-picker">
      {filteredAirports.length > 0 && (
        <li className="search-title">
          <FontAwesomeIcon icon={["fas", "plane"]} /> Airports
        </li>
      )}
      {filteredAirports.length > 0 &&
        filteredAirports.map((airport: any) => (
          <li
            className={index === airport.index && currentSection === Section.airport ? "selected" : ""}
            onClick={() => selectHandler(airport)}
            key={airport.index}
          >
            {airport.name} / {airport.state} ({airport.code})
          </li>
        ))}
      {hotels.length > 0 && (
        <li className="search-title">
          <FontAwesomeIcon icon={["fas", "home"]} /> Hotels
        </li>
      )}
      {hotels.length > 0 &&
        hotels.map((h: ISearchHotel) => (
          <li
            className={index === h.index && currentSection === Section.hotel ? "selected" : ""}
            onClick={() => selectHandler(h)}
            key={h.index}
          >
            {h.name} ({h.source.join(", ")})
          </li>
        ))}
      {destinations.length > 0 && (
        <li className="search-title">
          <FontAwesomeIcon icon={["fas", "map-marker"]} />
          Destinations
        </li>
      )}
      {destinations.length > 0 &&
        destinations.map((d: ISearchDestination) => (
          <li
            className={index === d.index && currentSection === Section.destination ? "selected" : ""}
            onClick={() => selectHandler(d)}
            key={d.index}
          >
            {d.name}
          </li>
        ))}
    </ul>
  ) : null;
};

export default HotelPicker;
