import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, FC, useState } from "react";
import useObjectState from "../../hooks/useObjectState";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { Busy } from "../snippets/Busy";

interface IConfirmationEmailRequest {
  action_type: string;
  comments?: string;
  email_type: string;
  mailto?: string;
  replyto?: string;
  offer_guid: string;
  pnr: string;
  title?: string;
}

interface IProps extends IConfirmationEmailRequest {
  onClose(): void;
}

const MailConfirmationModal: FC<IProps> = ({ onClose, ...mailProps }) => {
  const [isBusy, setIsBusy] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [mail, setMail] = useObjectState<IConfirmationEmailRequest>({
    action_type: "",
    comments: "",
    email_type: "",
    mailto: "",
    offer_guid: "",
    pnr: "",
    replyto: "",
    title: "",
    ...mailProps
  });

  const sendMail = async () => {
    const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

    if (!mail.comments || !mail.mailto || !mail.replyto || !mail.title) {
      return setValidationMessage("All fields must be filled.");
    }

    if (!regex.test(mail.mailto.toLowerCase()) || !regex.test(mail.replyto.toLowerCase())) {
      return setValidationMessage("Enter a valid email address.");
    }
    setIsBusy(true);
    try {
      await api().post("mailservice", mail);
      Logger.flash("Mail successfully sent.");
      onClose();
    } catch {
      Logger.flashError("There was an error while sending mail. Try again later...");
    }
    setIsBusy(false);
  };

  const changeHandler = (key: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMail({
      [key]: e.currentTarget.value
    });
    setValidationMessage("");
  };

  return (
    <Modal.Container isBusy={isBusy} bordered onClose={onClose}>
      <Modal.Header>
        <h1>Confirmation Mail</h1>
      </Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        <div className="form">
          <div className="form-group">
            <label>Title:</label>
            <input type="text" value={mail.title} onChange={changeHandler("title")} />
          </div>
          <div className="form-group">
            <label>Comment:</label>
            <textarea rows={20} value={mail.comments} onChange={changeHandler("comments")} />
          </div>
          <div className="form-group">
            <label>Mail To:</label>
            <input type="email" value={mail.mailto} onChange={changeHandler("mailto")} />
          </div>
          <div className="form-group">
            <label>Reply To:</label>
            <input type="email" value={mail.replyto} onChange={changeHandler("replyto")} />
          </div>
          <div className="form-errors">
            {validationMessage !== "" && <p className="error">{validationMessage}</p>}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="confirmation-send" onClick={sendMail}>
          <FontAwesomeIcon icon={["far", "envelope"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default MailConfirmationModal;
