import { useFormikContext } from "formik";
import moment from "moment";
import React, { FC, memo, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import tooltip from "react-tooltip";
import { ICustomer } from "../../../interfaces/ICustomer";
import isEmpty from "lodash-es/isEmpty";

type Props = {
  names: string[];
  birthdate: string;
};

const CustomerPassengertype: FC<Props> = ({ names, birthdate }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ICustomer>();

  const years = useMemo(() => {
    const parsedDate = moment.utc(birthdate, "YYYYMMDD");
    return moment.utc().diff(parsedDate, "years");
  }, [birthdate]);

  useEffect(() => {
    tooltip.rebuild();
    if (years <= 2) {
      for (const name of names) {
        setFieldValue(name, "INF");
      }
    }
    if (years > 2 && years <= 12) {
      for (const name of names) {
        setFieldValue(name, "CHD");
      }
    }
    if (years > 2 && years > 12) {
      for (const name of names) {
        setFieldValue(name, "ADT");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  return (
    <div data-tip={t("customer.passengerTypeInfo")}>
      <input
        style={{ width: 65, textAlign: "center", backgroundColor: "white" }}
        value={
          isEmpty(birthdate)
            ? t("customer.passengerType")!
            : t(`customer.${values.passenger_type.toLowerCase()}`)!
        }
        disabled
      />
    </div>
  );
};

export default memo(CustomerPassengertype);
