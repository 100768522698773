import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { CSSProperties, FC, useEffect, useMemo, useRef } from "react";
import { chartColors } from "../../utils/constants";

interface IProps {
  category: string;
  currency: string;
  customColor?: boolean;
  data: any[];
  legend?: string;
  span?: number;
  title?: string;
  value: string;
}

const ShareChart: FC<IProps> = ({
  category,
  currency,
  customColor = true,
  data,
  legend,
  span,
  title,
  value
}) => {
  const container = useRef<HTMLDivElement>(null);

  const colors = useMemo(() => {
    return data.map(d => am4core.color(chartColors[d.action]));
  }, [data]);

  useEffect(() => {
    const chart = am4core.create(container.current!, am4charts.PieChart);
    chart.innerRadius = am4core.percent(40);
    chart.data = data;
    if (title) {
      const chartTitle = chart.titles.create();
      chartTitle.text = `[gray]${title}[/]`;
      chartTitle.fontSize = 17;
      chartTitle.marginBottom = 30;
    }
    chart.legend = new am4charts.Legend();
    if (legend) {
      chart.legend.labels.template.text = `[bold]{${category}}[/]`;
    }

    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = value;
    if (customColor) {
      pieSeries.colors.list = colors;
    }
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices!.template!.states!.getKey("hover")!.properties!.shiftRadius = 0;
    pieSeries.slices!.template!.states!.getKey("hover")!.properties!.scale = 1.1;
    pieSeries.slices.template.tooltipText =
      "[#fff font-size: 20px]{value}[/][#fff font-size: 20px] " + currency + "[/]";
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "[bold]{value.percent.formatNumber('#.0')}%[/]";
    pieSeries.labels.template.radius = am4core.percent(-25);
    pieSeries.labels.template.fill = am4core.color("white");
    return () => chart.dispose();
  }, [category, colors, currency, customColor, data, legend, title, value]);

  const styles: CSSProperties = {
    width: "500px"
  };

  if (span) {
    styles.width = (span * (100 / 3)).toFixed(1) + "%";
  }

  return (
    <div className="chart-container" style={styles}>
      <div className="chart" ref={container} />
    </div>
  );
};

export default ShareChart;
