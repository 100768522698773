import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import castArray from "lodash-es/castArray";
import React, { CSSProperties, FC, useEffect, useMemo, useRef } from "react";

interface IProps {
  data: any[];
  value: string | string[];
  category: string;
  span?: number;
  title?: string;
}

const DonutChart: FC<IProps> = ({ data, value, category, span, title }) => {
  const $container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chart = am4core.create($container.current!, am4charts.PieChart);
    chart.innerRadius = am4core.percent(40);
    chart.data = data;

    if (title) {
      const chartTitle = chart.titles.create();
      chartTitle.text = title;
      chartTitle.fontSize = 25;
      chartTitle.marginBottom = 30;
    }

    castArray(value).forEach((v, i) => {
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = v;
      pieSeries.dataFields.category = category;
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries!.slices!.template!.states!.getKey("hover")!.properties!.shiftRadius = 0;
      pieSeries!.slices!.template!.states!.getKey("hover")!.properties!.scale = 0.9 + i * 0.2;
      pieSeries.labels.template.text = `{value.value} ${"₺"}`;
      if (!i) {
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
      }
    });
    return () => chart.dispose();
  }, [category, data, title, value]);

  const styles: CSSProperties = useMemo(() => {
    return {
      height: "350px",
      width: span ? (span * (100 / 3)).toFixed(1) + "%" : "100%"
    };
  }, [span]);

  return (
    <div className="chart-container" style={styles}>
      <div className="chart" ref={$container} />
    </div>
  );
};

export default DonutChart;
