import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toUpper from "lodash-es/toUpper";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useObjectState from "../hooks/useObjectState";
import { api } from "../services/api";
import { openInNewTab } from "../utils/helpers";

interface IRule {
  index: number;
  rule_desc: string;
  rule_format: string;
  rule_url: string;
}

interface IProps {
  action: string;
  id: string;
}

const Rules: FC<IProps> = ({ action, id }) => {
  const { t } = useTranslation();

  const [isActive, setIsActive] = useState(false);
  const [rule, setRule] = useObjectState<IRule>({
    index: 0,
    rule_desc: "",
    rule_format: "",
    rule_url: ""
  });

  const getRules = async () => {
    try {
      if (action === "flight") {
        const { data } = await api().get("flightrules", {
          params: { key: id }
        });
        setRule(data);
        if (data.rule_url) {
          openInNewTab(data.rule_url);
        }
        if (data.rule_desc) {
          setIsActive(true);
        }
      }
    } catch {}
  };

  const buttonName = useMemo(() => {
    if (action === "flight") {
      return t("flight.rule");
    }
    return t("hotel.rule");
  }, [action, t]);

  return (
    <>
      <p className="pointer fs-small fs-italic" onClick={getRules}>
        {buttonName}
      </p>
      {isActive && (
        <div className="rule-box">
          <div className="rule-header">
            <h1>{toUpper(t("generic.title.rule"))}</h1>
            <button onClick={() => setIsActive(false)}>
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          </div>
          <div className="rule-description" dangerouslySetInnerHTML={{ __html: rule.rule_desc }} />
        </div>
      )}
    </>
  );
};

export default Rules;
