import React, { FC } from "react";
import { batch, useDispatch } from "react-redux";
import { ICarSetSearchProps } from "../../../interfaces/ICar";
import { openCarsModal } from "../../../store/actions/carActions";

type Props = ICarSetSearchProps & {
  className?: string;
  disabled?: boolean;
};

const CarButton: FC<Props> = ({ className, disabled, children }) => {
  const dispatch = useDispatch();

  const clickHandler = () => {
    batch(() => {
      dispatch(openCarsModal());
    });
  };
  return (
    <button className={`x-sell-button ${className}`} disabled={disabled} onClick={clickHandler}>
      {children}
    </button>
  );
};

export default CarButton;
