import i18n from "i18next";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  PROGRAM_FETCH_EMPTY,
  PROGRAM_FETCH_FULFILLED,
  PROGRAM_FETCH_REJECTED,
  PROGRAM_SELECT
} from "../../utils/constants";

export const fetchPrograms: AC = () => async dispatch => {
  const log = new Logger(i18n.t("program.fetchProgramPending"));
  try {
    const { data } = await api().get("travelprogram");
    if (data.programs.length > 0) {
      dispatch({
        payload: data,
        type: PROGRAM_FETCH_FULFILLED
      });
      log.success(i18n.t("program.fetchProgramSuccess"));
    } else {
      dispatch({
        type: PROGRAM_FETCH_EMPTY
      });
      log.warning(i18n.t("program.fetchProgramEmpty"));
    }
  } catch {
    dispatch({
      type: PROGRAM_FETCH_REJECTED
    });
    log.error(i18n.t("program.fetchProgramEmpty"));
  }
};

export const selectProgram: AC = (travelGuid: string) => dispatch => {
  dispatch({
    payload: travelGuid,
    type: PROGRAM_SELECT
  });
};
