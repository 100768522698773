import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toUpper from "lodash-es/toUpper";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDestination, IFlight } from "../interfaces/IFlight";
import { findAirportByCode } from "../utils/airportFunctions";
import { dayMonth, formatTime, pwc } from "../utils/helpers";
import { FlightStats } from "./modals/FlightStats";
import FlightLogo from "./snippets/FlightLogo";
import Popover, { ArrowContainer } from "react-tiny-popover";

type Props = {
  flight: IFlight;
};

const OfferFlight: FC<Props> = ({ flight }) => {
  const { t } = useTranslation();
  const [popover, setPopover] = useState<{ index?: boolean }>({});
  const [flightStatsIsActive, setFlightStatsIsActive] = useState(false);
  const [destination, setDestination] = useState<IDestination>();

  // const fetchFlightStatus = useCallback((dest: IDestination) => {
  //   axios.post("/flightstatus", {
  //     arr: dest.ArrivalAirport,
  //     date: dest.DepDate,
  //     dep: dest.DepartureAirport,
  //     flightNumber: dest.MarketingAirline + dest.FlightNumber
  //   });
  // }, []);
  //
  // const flightStatsLink = useCallback((dest: IDestination) => {
  //   return `https://www.flightstats.com/v2/flight-tracker/${dest.MarketingAirline}/
  //   ${dest.FlightNumber}?year=${year(dest.DepDate)}
  //   &month=${month(dest.DepDate)}&date=${day(dest.DepDate)}`;
  // }, []);

  const closeModal = useCallback(() => {
    setDestination(undefined);
    setFlightStatsIsActive(false);
  }, []);

  const flightStatsHandler = useCallback((dest: IDestination) => {
    setDestination(dest);
    setFlightStatsIsActive(true);
  }, []);

  return (
    <>
      {flightStatsIsActive && (
        <FlightStats destination={destination!} active={flightStatsIsActive} onClose={closeModal} />
      )}
      {flight.airitin.origdests!.map((dest, index) => (
        <div key={index} className="pnr-flight">
          <div className="pnr-dest">
            <div className="content full-width">
              <div className="flex a-center j-even fill-space d-column-mobile">
                <p className="fs-lrg-m c-primary">{dayMonth(dest.DepDate)}</p>
                <span className="middot c-primary" />
                <div className="flex a-center">
                  <FlightLogo className="text-image pnr-flight-logo" code={dest.MarketingAirline} />
                  <p className="fs-lrg-m c-primary ">
                    {dest.MarketingAirlineName}
                    <br />
                    {dest.MarketingAirline} {dest.FlightNumber}
                    {dest.MarketingAirline !== dest.OperatingAirline && (
                      <>
                        <br />
                        <span className="fs-std">operating by: {dest.OperatingAirlineName}</span>
                      </>
                    )}
                  </p>
                  <br />
                </div>
                <span className="middot c-primary" />
                <div className="flex a-center offer-line-height">
                  <div className="flex d-column">
                    <span className="text fs-lrg-m c-primary">
                      {findAirportByCode(dest.DepartureAirport).state}
                    </span>
                    <span className="text fs-lrg-m c-primary">{formatTime(dest.DepTime)}</span>
                  </div>
                  <span className="c-primary">
                    <FontAwesomeIcon icon={["fas", "plane"]} style={{ margin: "0 10px" }} />
                  </span>
                  <div className="flex d-column">
                    <span className="text fs-lrg-m c-primary">
                      {findAirportByCode(dest.ArrivalAirport).state}
                    </span>
                    <span className="text fs-lrg-m c-primary">{formatTime(dest.ArrTime)}</span>
                  </div>
                </div>
                <div>
                  <button className="pointer clean-button c-primary" onClick={() => flightStatsHandler(dest)}>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} />
                  </button>
                </div>
                <Popover
                  align="center"
                  isOpen={popover[index]}
                  position="top"
                  padding={10}
                  onClickOutside={() =>
                    setPopover(x => ({
                      ...x,
                      [index]: false
                    }))
                  }
                  content={({ position, targetRect, popoverRect }) => (
                    <ArrowContainer
                      position={position}
                      targetRect={targetRect}
                      popoverRect={popoverRect}
                      arrowSize={10}
                      arrowColor="var(--secondaryBg)"
                      arrowStyle={{ opacity: 1 }}
                    >
                      <div className="panel panel-primary panel-flight-alternative">
                        <div className="panel-heading clearfix">
                          <span className="pull-left">{flight.airitinprice.BrandName || "#BrandName"}</span>
                          <span className="pull-right">
                            {pwc(flight.airitinprice.TotalFare, flight.Currency)}
                          </span>
                        </div>
                        <div className="panel-body">
                          {flight.airitinprice.CarryOnBaggageKilo && (
                            <p>
                              <span className="flight-info-icon">
                                <FontAwesomeIcon icon={["fas", "backpack"]} />
                              </span>
                              <small>
                                {flight.airitinprice.CarryOnBaggageKilo} kg {t("flight.cabinBaggage")}
                              </small>
                            </p>
                          )}
                          {flight.airitinprice.CheckedOnBaggageKilo && (
                            <p>
                              <span className="flight-info-icon">
                                <FontAwesomeIcon icon={["fas", "luggage-cart"]} />
                              </span>
                              <small>
                                {flight.airitinprice.CheckedOnBaggageKilo} kg {t("flight.baggage")}
                              </small>
                            </p>
                          )}
                          {flight.airitinprice.MealDesc && (
                            <p>
                              <span className="flight-info-icon">
                                <FontAwesomeIcon icon={["fas", "utensils"]} />
                              </span>
                              <small>{flight.airitinprice.MealDesc}</small>
                            </p>
                          )}
                        </div>
                      </div>
                    </ArrowContainer>
                  )}
                >
                  <div
                    className="offer-airitinprice"
                    onClick={() => {
                      setPopover(x => ({
                        ...x,
                        [index]: !x[index]
                      }));
                    }}
                  >
                    <p>{toUpper(flight.airitinprice.BrandName) || "#BrandName"}</p>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default OfferFlight;
