import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import { ICustomer } from "../../interfaces/ICustomer";
import AdminLayout from "../../layouts/AdminLayout";
import AdminSubRoute from "../../modules/adminPages/_components/AdminSubRoute";
import PolicyCustomerEdit from "../../modules/adminPages/policy/PolicyCustomerEdit";
import PolicyCustomerList from "../../modules/adminPages/policy/PolicyCustomerList";
import { api } from "../../services/api";

const AdminPoliciesPage: FC = () => {
  const match = useRouteMatch<{ guid: string }>();
  const { t } = useTranslation();

  const [customers, setCustomers] = useState<ICustomer[]>([]);

  const fetchCustomers = async () => {
    try {
      const { data } = await api().post("customerlist");
      setCustomers(data.customers);
    } catch {}
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const selectedCustomer = useMemo(() => {
    return customers.find(c => c.cust_guid === match.params.guid);
  }, [match.params.guid, customers]);

  return (
    <AdminLayout>
      <div className="flex d-column">
        <AdminSubRoute />
        <aside className="admin-aside-menu gray-box ignore-top-left-radius">
          <div className="title main">
            <h1>
              <FontAwesomeIcon icon={["far", "user-tie"]} />
              {t("generic.customers")}
            </h1>
          </div>
          <PolicyCustomerList customers={customers} />
        </aside>
      </div>
      <div className="admin-content gray-box">
        {selectedCustomer && <PolicyCustomerEdit customer={selectedCustomer} />}
      </div>
    </AdminLayout>
  );
};

export default AdminPoliciesPage;
