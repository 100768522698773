import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDestination, IFlightOffer } from "../interfaces/IFlight";
import { findAirportByCode } from "../utils/airportFunctions";
import { dayMonth, formatTime, pwc } from "../utils/helpers";
import { FlightStats } from "./modals/FlightStats";

interface IProps {
  diff?: number;
  flight: IFlightOffer;
}

const ApproveFlight: FC<IProps> = ({ diff, flight }) => {
  const { t, i18n } = useTranslation();
  const [flightStatsIsActive] = useState(false);
  const [destination, setDestination] = useState<IDestination>();

  // const fetchFlightStatus = useCallback(async (dest: IDestination) => {
  //   await axios.post("flightstatus", {
  //     arr: dest.ArrivalAirport,
  //     date: dest.DepDate,
  //     dep: dest.DepartureAirport,
  //     flightNumber: dest.MarketingAirline + dest.FlightNumber
  //   });
  // }, []);
  //
  // const flightStatsLink = useCallback((dest: IDestination) => {
  //   return `https://www.flightstats.com/v2/flight-tracker/${dest.MarketingAirline}/
  //   ${dest.FlightNumber}?year=${year(dest.DepDate)}
  //   &month=${month(dest.DepDate)}&date=${day(dest.DepDate)}`;
  // }, []);

  const closeModal = useCallback(() => {
    setDestination(undefined);
    // setFlightStatsIsActive(false);
  }, []);

  // const flightStatsHandler = useCallback((dest: IDestination) => {
  //   setDestination(dest);
  //   setFlightStatsIsActive(true);
  // }, []);

  return (
    <div className="flex approve-container">
      <div className="approve-icon">
        <FontAwesomeIcon icon={["fas", "plane"]} />
      </div>
      <div className="route-items">
        {destination && (
          <FlightStats destination={destination} active={flightStatsIsActive} onClose={closeModal} />
        )}
        {flight.airitin.origdests!.map((dest, index) => (
          <div key={index} className="pnr-flight">
            <div className="pnr-dest">
              <div className="content full-width">
                <div className="flex a-center j-between fill-space d-column-mobile">
                  <div className="flex a-center">
                    {/*<FlightLogo className="text-image pnr-flight-logo" code={dest.MarketingAirline} />*/}
                    <p className="fs-lrg-m c-primary ">
                      {dest.MarketingAirlineName}&nbsp;
                      <br className="is-mobile-hidden" />
                      {dest.MarketingAirline} {dest.FlightNumber}
                      {dest.MarketingAirline !== dest.OperatingAirline && (
                        <>
                          <br className="is-mobile-hidden" />
                          <span className="fs-std">operating by: {dest.OperatingAirlineName}</span>
                        </>
                      )}
                    </p>
                    <br />
                  </div>
                  <span className="middot c-primary is-mobile-hidden" />
                  <p className="fs-lrg-m c-primary">{dayMonth(dest.DepDate)}</p>
                  <span className="middot c-primary is-mobile-hidden" />
                  <div className="flex a-center offer-line-height">
                    <div className="flex d-column">
                      <span className="text fs-lrg-m c-primary">
                        {findAirportByCode(dest.DepartureAirport).state}
                      </span>
                      <span className="text fs-med c-primary">{formatTime(dest.DepTime)}</span>
                    </div>
                    <span className="c-primary">
                      <FontAwesomeIcon icon={["fas", "plane"]} style={{ margin: "0 10px" }} />
                    </span>
                    <div className="flex d-column">
                      <span className="text fs-lrg-m c-primary">
                        {findAirportByCode(dest.ArrivalAirport).state}
                      </span>
                      <span className="text fs-med c-primary">{formatTime(dest.ArrTime)}</span>
                    </div>
                  </div>
                  <span className="middot c-primary" />
                  <p className="fs-med c-primary align-center">{dest.ClassDesc}</p>
                  <span className="fill-space" />
                  {/*<div>*/}
                  {/*  <button className="pointer clean-button" onClick={() => flightStatsHandler(dest)}>*/}
                  {/*    <FontAwesomeIcon className="c-primary" icon={["fas", "info-circle"]} />*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="compliances">
          {flight.limitMessage ? (
            <>
              <p className="fs-med c-orange bold">{t("offerApprove.flight.limitTitle")}</p>
              <p className="fs-med c-primary bold">
                <FontAwesomeIcon icon={["far", "user-edit"]} /> {flight.limitMessage[i18n.language]}
              </p>
            </>
          ) : flight.compMessage ? (
            <>
              <p className="fs-med c-orange bold">{t("offerApprove.flight.limitTitle")}</p>
              <p className="fs-med c-primary bold">
                <FontAwesomeIcon icon={["far", "user-edit"]} /> {flight.compMessage[i18n.language]}
              </p>
            </>
          ) : (
            <p />
          )}
        </div>
      </div>
      <div className="price-summary">
        <p className="fs-lrg-m c-primary">
          {t("offerApprove.flight.done")}:
          <br />
          <span className="bold">{pwc(flight.airitinprice.TotalFare, flight.Currency)}</span>
        </p>
        <p className="fs-lrg-m c-primary">
          {t("offerApprove.flight.minimumGape")}:
          <br /> <span className="bold">{pwc(diff, flight.Currency)}</span>
        </p>
      </div>
    </div>
  );
};

export default ApproveFlight;
