import React from "react";
import Loading from "../Loading";

export function Busy({ isBusy }: { isBusy?: boolean }) {
  return (
    <div className="busy-screen flex j-center">
      <Loading />
    </div>
  );
}
