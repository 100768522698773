import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import debounce from "lodash-es/debounce";
import map from "lodash-es/map";
import values from "lodash-es/values";
import moment from "moment";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AccountCollection from "../../components/accounting/AccountCollection";
import AccountInvoice from "../../components/accounting/AccountInvoice";
import AccountSupplierInvoice from "../../components/accounting/AccountSupplierInvoice";
import AccountTableCell from "../../components/accounting/AccountTableCell";
import Loading from "../../components/Loading";
import ReportDatePicker from "../../components/ReportDatePicker";
import CheckboxSimple from "../../components/snippets/CheckboxSimple";
import SpreadsheetButton from "../../components/snippets/SpreadsheetButton";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import DefaultLayout from "../../layouts/DefaultLayout";
import {
  fetchAccountingReports,
  selectAccountingReport,
  selectAllFilteredReports,
  setAccountingFilter,
  setAccountingOrder
} from "../../store/actions/accountingActions";
import {
  getAccountingAllFilteredReportsSelected,
  getAccountingFields,
  getAccountingFilters,
  getAccountingOrders,
  getAccountingSearchedReports,
  getAccountingSelectedReportKeys,
  getAccountingSelectedReports,
  getAccountingStatus,
  getHasAccountingReports
} from "../../store/selectors/accountingSelectors";
import { getUser } from "../../store/selectors/userSelectors";
// @ts-ignore
// tslint:disable
import { Cell, Row, StickyTable } from "react-sticky-table";

const AgencyAccountingPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const allSelected = useSelector(getAccountingAllFilteredReportsSelected);
  const fields = useSelector(getAccountingFields);
  const filters = useSelector(getAccountingFilters);
  const orders = useSelector(getAccountingOrders);
  const reports = useSelector(getAccountingSearchedReports);
  const hasResult = useSelector(getHasAccountingReports);
  const selectedKeys = useSelector(getAccountingSelectedReportKeys);
  const selectedReports = useSelector(getAccountingSelectedReports);
  const status = useSelector(getAccountingStatus);

  const [selectedCostInvoiceReport, setSelectedCostInvoiceReport] = useState<IAccountingPayment>();
  const [modalType, setModalType] = useState<string>();
  const [search, setSearch] = useState({
    company_code: user.companycode,
    currency: "TRY",
    edate: moment().format("YYYYMMDD"),
    sdate: moment().format("YYYY0101"),
    type: "company"
  });

  const dateHandler = useCallback(
    (field: keyof typeof search) => (value: string) => {
      setSearch(x => ({
        ...x,
        [field]: value
      }));
    },
    [search]
  );

  const modalHandler = useCallback(
    (mt: "collection" | "invoice" | "supplier") => () => {
      if (selectedReports.length) {
        setModalType(mt);
      }
    },
    [selectedReports]
  );

  const supplierModalHandler = useCallback((report: IAccountingPayment) => {
    setModalType("supplier");
    setSelectedCostInvoiceReport(report);
  }, []);

  const typeHandler = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setSearch(state => ({
      ...state,
      type: value
    }));
  }, []);

  const fetchReports = () => {
    dispatch(fetchAccountingReports(search));
  };

  const filter = debounce((keywords: string) => {
    dispatch(setAccountingFilter("quickSearch", keywords));
  }, 250);

  const filterHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => filter(e.target.value), [filter]);

  const cxRow = useCallback(
    (report: IAccountingPayment) =>
      classNames({
        "is-collected": report.isCollected,
        "is-costinvoiced": report.isCostinvoiced,
        "is-invoiced": report.isInvoiced,
        "row-checked": selectedKeys.includes(report.paymentKey)
      }),
    [selectedKeys]
  );

  return (
    <DefaultLayout>
      {modalType === "collection" && !!selectedReports.length && (
        <AccountCollection onClose={() => setModalType(undefined)} items={selectedReports} />
      )}
      {modalType === "invoice" && !!selectedReports.length && (
        <AccountInvoice onClose={() => setModalType(undefined)} items={selectedReports} />
      )}
      {modalType === "supplier" && selectedCostInvoiceReport && (
        <AccountSupplierInvoice onClose={() => setModalType(undefined)} item={selectedCostInvoiceReport} />
      )}
      <div className="container main">
        <div className="reports">
          <div className="report-page-title">
            <h2>{t("report.title.accounting")}</h2>
          </div>
          <div className="reports-header">
            <div className="reports-header-row">
              <div className="report-filters flex inline a-center">
                <ReportDatePicker
                  startDate={search.sdate}
                  endDate={search.edate}
                  pickEndDate={dateHandler("edate")}
                  pickStartDate={dateHandler("sdate")}
                />

                <label className="report-date">
                  <span>{t("report.typeTitle")}:</span>
                  <br />
                  <select value={search.type} onChange={typeHandler}>
                    <option value="company">{t("report.typeCompany")}</option>
                    <option value="commission">{t("report.typeCommission")}</option>
                  </select>
                </label>
                <button disabled={status.fetching} onClick={fetchReports}>
                  {status.fetching ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
                </button>
              </div>
              {hasResult && (
                <SpreadsheetButton filename="Accounting" reports={reports} fields={fields} total={true} />
              )}
            </div>
            {hasResult && (
              <div className="reports-header-row flex a-center">
                <div className="inline-search">
                  <input
                    placeholder={t("search.inline")}
                    type="text"
                    defaultValue={filters.quickSearch}
                    onChange={filterHandler}
                  />
                </div>
                <div className="form-group" style={{ paddingLeft: 10 }}>
                  <CheckboxSimple
                    checked={filters.isCollected}
                    onClick={() => dispatch(setAccountingFilter("isCollected"))}
                  >
                    {t("accounting.notCollected")}
                  </CheckboxSimple>
                </div>
                <div className="form-group" style={{ paddingLeft: 10 }}>
                  <CheckboxSimple
                    checked={filters.isInvoiced}
                    onClick={() => dispatch(setAccountingFilter("isInvoiced"))}
                  >
                    {t("accounting.notInvoiced")}
                  </CheckboxSimple>
                </div>
                <div className="form-group" style={{ paddingLeft: 10 }}>
                  <CheckboxSimple
                    checked={filters.isCostInvoiced}
                    onClick={() => dispatch(setAccountingFilter("isCostInvoiced"))}
                  >
                    {t("accounting.notCostInvoiced")}
                  </CheckboxSimple>
                </div>
              </div>
            )}
          </div>
          {hasResult && (
            <div className="report-actions">
              <button onClick={modalHandler("invoice")} className="invoice">
                {t("accounting.setInvoice")}
              </button>
              <button onClick={modalHandler("collection")} className="collection">
                {t("accounting.setCollection")}
              </button>
            </div>
          )}
          {status.fetching && <Loading />}
          <div className="reports-table-container">
            {values(fields).length > 0 && (
              <StickyTable className="accounting-table" stickyColumnCount={0}>
                <Row>
                  <Cell className="pointer flex" onClick={() => dispatch(selectAllFilteredReports())}>
                    {allSelected ? (
                      <FontAwesomeIcon icon={["fas", "check-square"]} />
                    ) : (
                      <FontAwesomeIcon icon={["far", "square"]} />
                    )}
                    &nbsp;
                    {t("accounting.selected")}
                  </Cell>
                  {map(fields, (options, field) => (
                    <Cell key={field} onClick={() => dispatch(setAccountingOrder(field))}>
                      <span className="flex">
                        {options.title}
                        &nbsp;
                        {orders[field] === "asc" ? (
                          <FontAwesomeIcon icon={["fas", "sort-up"]} />
                        ) : orders[field] === "desc" ? (
                          <FontAwesomeIcon icon={["fas", "sort-down"]} />
                        ) : (
                          <FontAwesomeIcon icon={["fas", "sort"]} />
                        )}
                      </span>
                    </Cell>
                  ))}
                </Row>
                {reports.map((report, ri: number) => (
                  <Row
                    key={ri}
                    className={cxRow(report)}
                    onDoubleClick={() => dispatch(selectAccountingReport(report.paymentKey))}
                  >
                    <Cell
                      className="align-center text fs-lrg"
                      onClick={() => dispatch(selectAccountingReport(report.paymentKey))}
                    >
                      {selectedKeys.length && selectedKeys.includes(report.paymentKey) ? (
                        <FontAwesomeIcon icon={["fas", "check-square"]} />
                      ) : (
                        <FontAwesomeIcon icon={["far", "square"]} />
                      )}
                    </Cell>
                    {map(fields, (options, field) => (
                      <AccountTableCell
                        field={field}
                        report={report}
                        key={field}
                        openCostInvoice={supplierModalHandler}
                        t={t}
                      />
                    ))}
                  </Row>
                ))}
              </StickyTable>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AgencyAccountingPage;
