export default function languageStorage() {
  const currentLang = localStorage.getItem("lang") || "en";

  return {
    current() {
      return currentLang;
    },
    refresh() {
      localStorage.setItem("lang", currentLang);
    },
    save(lang: string) {
      localStorage.setItem("lang", lang);
    }
  };
}
