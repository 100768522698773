import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import get from "lodash-es/get";
import map from "lodash-es/map";
import React, { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Circle, GoogleMap, Marker } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import CollapsibleContainer from "../../components/CollapsibleContainer";
import LoadingWrapper from "../../components/LoadingWrapper";
import MapContent from "../../components/MapContent";
import Rating from "../../components/snippets/Rating";
import Sort from "../../components/Sort";
import FilterHotel from "../../containers/FilterHotel";
import InlineSearchHotel from "../../containers/InlineSearchHotel";
import MapWrapper from "../../containers/MapWrapper";
import { IHotel, IRoom } from "../../interfaces/IHotel";
import Thumbnail from "../../libs/Image/Thumbnail";
import Modal from "../../libs/Modal";
import { changeFilterHotelsSortBy, toggleFilterHotels } from "../../store/actions/filterActions";
import { fetchRooms } from "../../store/actions/hotelActions";
import { makeSearchWithCoords } from "../../store/actions/searchActions";
import { toggleHotelDetailsScreen } from "../../store/actions/uiActions";
import {
  getActiveCustomers,
  getCustomersValidForBasket,
  getCustomersValidForBooking
} from "../../store/selectors/customerSelectors";
import { getFilterHotelSortBy } from "../../store/selectors/filterSelectors";
import { getSearchedHotels } from "../../store/selectors/hotelSelectors";
import { getHotelDetailsScreenState } from "../../store/selectors/uiSelectors";
import { Dictionary } from "../../types";
import { listCustomersName, pwc, uniqid } from "../../utils/helpers";
import DetailsHotel from "./components/DetailsHotel";
import DetailsHotelInfos from "./components/DetailsHotelInfos";
import DetailsRooms from "./components/DetailsRooms";

type ContextType = {
  selectedHotel?: IHotel;
  selectedRoom?: IRoom;
  setSelectedHotel(hotel: IHotel): void;
  setSelectedRoom(room?: IRoom): void;
};

export const HotelContext = createContext<ContextType>(null as any);

const HotelsDetailsScreen: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(getActiveCustomers);
  const customersValidForBasket = useSelector(getCustomersValidForBasket);
  const customersValidForBooking = useSelector(getCustomersValidForBooking);
  const hotelDetailsScreen = useSelector(getHotelDetailsScreenState);
  const hotels = useSelector(getSearchedHotels);
  const sortBy = useSelector(getFilterHotelSortBy);

  const searchBox = useRef<SearchBox>(null);
  const googleMap = useRef<GoogleMap>();

  const [selectedHotel, setSelectedHotel] = useState<IHotel | undefined>(hotelDetailsScreen.hotel);
  const [zoom, setZoom] = useState(12);
  const [selectedRooms, setSelectedRooms] = useState<Dictionary<IRoom>>({});
  const [opener, setOpener] = useState<string>();
  const [poi, setPoi] = useState<{ lat: number; lng: number }>();

  const hasRooms = useMemo(() => {
    return get(selectedHotel, "rooms", []).length > 0;
  }, [selectedHotel]);

  const selectedRoom = useMemo(() => {
    if (selectedHotel && selectedHotel.rooms[0]) {
      return selectedRooms[selectedHotel.HotelCode];
    }
  }, [selectedHotel, selectedRooms]);

  useEffect(() => {
    const newHotel = hotels.find(h => (selectedHotel ? h.HotelCode === selectedHotel.HotelCode : hotels[0]));
    setSelectedHotel(newHotel);
    if (newHotel && newHotel.rooms.find(r => selectedRoom && r.OfferId === selectedRoom.OfferId)) {
      setSelectedRooms(rooms => ({
        ...rooms,
        [newHotel.HotelCode]: newHotel.rooms.find(r => selectedRoom && r.OfferId === selectedRoom.OfferId)!
      }));
    }
    // if (selectedHotel) {
    //   hotels.
    // }
  }, [hotels, selectedHotel, selectedRoom]);

  useEffect(() => {
    if (selectedHotel && !hasRooms) {
      dispatch(fetchRooms(selectedHotel.HotelCode, selectedHotel.source));
    }
  }, [dispatch, hasRooms, selectedHotel]);

  const selectedCoords = useMemo(() => {
    if (selectedHotel) {
      return {
        lat: parseFloat(selectedHotel.Latitude),
        lng: parseFloat(selectedHotel.Longitude)
      };
    }
    return poi;
  }, [selectedHotel, poi]);

  const toggleScreenHandler = useCallback(() => {
    dispatch(toggleHotelDetailsScreen());
  }, [dispatch]);

  const toggleFilterHandler = useCallback(() => {
    dispatch(toggleFilterHotels());
  }, [dispatch]);

  const changeFilterHandler = useCallback(
    (payload: string) => {
      dispatch(changeFilterHotelsSortBy(payload));
    },
    [dispatch]
  );

  const selectRoom = useCallback(
    (room: IRoom) => {
      if (selectedHotel) {
        setSelectedRooms(rooms => ({
          ...rooms,
          [selectedHotel.HotelCode]: room
        }));
      }
    },
    [selectedHotel]
  );

  const selectHotel = useCallback((hotel: IHotel) => {
    setSelectedHotel(hotel);
    setOpener(uniqid());
  }, []);

  const zoomChangeHandler = useCallback(() => {
    if (googleMap.current) {
      setZoom(googleMap.current.getZoom());
    }
  }, []);

  const placeSelectHandler = useCallback(async () => {
    setSelectedHotel(null as any);
    setSelectedRooms({});
    if (searchBox.current) {
      const place = searchBox.current.getPlaces()[0];
      const coords = place.geometry!.location.toJSON();
      const { name } = place;

      const payload = {
        lat: coords.lat,
        lon: coords.lng,
        name
      };
      setZoom(14);
      setPoi(coords);

      await dispatch(makeSearchWithCoords(payload));
      setSelectedHotel(hotels[0]);
    }
  }, [dispatch, hotels]);

  const rowRenderer: FC<ListChildComponentProps> = useCallback(({ index, data, style }) => {
    return <DetailsHotel style={style} hotel={data[index]} />;
  }, []);

  const infoBoxCx = classNames("map-info-window small");

  return (
    <HotelContext.Provider
      value={{
        selectedHotel,
        selectedRoom,
        setSelectedHotel: selectHotel,
        setSelectedRoom: selectRoom
      }}
    >
      <Modal.Container
        colorScheme="gray"
        padding={false}
        isFullScreen
        closeOnOutsideClick={false}
        onClose={toggleScreenHandler}
      >
        <Modal.Body className="hotel-details-screen">
          <div className="hotel-details-map-container">
            <MapWrapper
              onZoomChanged={zoomChangeHandler}
              mapRef={googleMap}
              zoom={zoom}
              center={selectedCoords}
            >
              <SearchBox ref={searchBox} controlPosition={3} onPlacesChanged={placeSelectHandler}>
                <input type="text" className="google-map-place-search" />
              </SearchBox>
              {poi && <Marker position={poi} />}
              {poi && (
                <Circle
                  center={poi}
                  radius={3000}
                  options={{ fillColor: "lightblue", strokeColor: "lightblue" }}
                />
              )}
              {hotels.map(h => (
                <Marker
                  onClick={() => selectHotel(h)}
                  key={h.HotelCode}
                  position={{ lat: parseFloat(h.Latitude), lng: parseFloat(h.Longitude) }}
                  icon={icon(h, selectedHotel)}
                >
                  <InfoBox options={{ enableEventPropagation: true, disableAutoPan: true }}>
                    <div className={infoBoxCx} onClick={() => selectHotel(h)}>
                      <Thumbnail gap={10} rounded image={h.images} width={75} height={75} />
                      <div className="hotel-info">
                        <Rating emptyOffset={false} rating={h.Rating} className="fs-note" />
                        <p className="hotel-name">{h.HotelName}</p>
                        <div className="fill-space" />
                        <p className="hotel-price">{pwc(h.MinRate, h.HotelCurrency, false)}</p>
                      </div>
                    </div>
                  </InfoBox>
                </Marker>
              ))}
              <MapContent flow="horizontal">
                <div className="hotel-details list">
                  <div className="hotel-details-title title flex j-between a-center is-relative">
                    <div className="fill-space" />
                    <Sort sortKeys={["price", "distance"]} current={sortBy} onChange={changeFilterHandler} />
                    <InlineSearchHotel />
                    <button className="button is-small filter-button" onClick={toggleFilterHandler}>
                      <FontAwesomeIcon icon={["fas", "filter"]} />
                    </button>
                    <FilterHotel />
                  </div>
                  <FixedSizeList
                    className="result-list"
                    itemData={hotels}
                    itemSize={200}
                    height={1000}
                    itemCount={hotels.length}
                    width="100%"
                  >
                    {rowRenderer}
                  </FixedSizeList>
                </div>
                <CollapsibleContainer opener={opener} closeOnOutsideClick direction="horizontal">
                  <div className="details-tab">
                    {selectedHotel && <DetailsHotelInfos {...selectedHotel} />}
                    <LoadingWrapper dimension={false} loading={!selectedHotel || !selectedHotel.roomsFetched}>
                      {selectedHotel && hasRooms && (
                        <div className="room-details list">
                          <div className="room-details-list-header">
                            <h1 className="fs-note">
                              <span className="bold">#{t("generic.room")} 1 / </span>
                              {listCustomersName(customers)}
                            </h1>
                          </div>
                          <div className="room-details-list-items">
                            {map(selectedHotel.groupedRooms, (rooms, roomType) => (
                              <DetailsRooms
                                isInternational={selectedHotel.isInternational}
                                key={roomType}
                                rooms={rooms}
                                type={roomType}
                                customersValidForBasket={customersValidForBasket}
                                customersValidForBooking={customersValidForBooking}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </LoadingWrapper>
                  </div>
                </CollapsibleContainer>
              </MapContent>
            </MapWrapper>
          </div>
        </Modal.Body>
      </Modal.Container>
    </HotelContext.Provider>
  );
};

function icon(hotel: IHotel, selected?: IHotel) {
  if (selected && selected.Latitude === hotel.Latitude) {
    if (hotel.source === "AGREEMENT") {
      return "/images/map/hotel-agreement-selected.png";
    } else {
      return "/images/map/hotel-selected.png";
    }
  } else {
    if (hotel.source === "AGREEMENT") {
      return "/images/map/hotel-agreement.png";
    } else {
      return "/images/map/hotel-other.png";
    }
  }
}

export default HotelsDetailsScreen;
