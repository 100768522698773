import React from "react";
import { IBasketFlight } from "../../interfaces/IBasket";
import { findAirportByCode } from "../../utils/airportFunctions";
import { COLORS } from "../../utils/constants";
import { pwc } from "../../utils/helpers";
import Dates from "../Dates";
import BasketLine from "../snippets/BasketLine";
import FlightLogo from "../snippets/FlightLogo";

interface IBasKetFlightComponentProps {
  flight: IBasketFlight;

  deleteItem(OfferId: string): void;
}

export default function BasketFlight({ deleteItem, flight }: IBasKetFlightComponentProps) {
  const line = {
    direction: flight.DirectionId,
    finishColor: COLORS[`route${flight.DirectionId}0`],
    startColor: COLORS[`route${flight.DirectionId}0`],
    type: "flight"
  };

  const date = {
    date: flight.DepDate,
    direction: flight.DirectionId
  };

  const deleteItemHandler = () => {
    deleteItem!(flight.OfferId);
  };

  return (
    <div className={"flight box no-padding-right flex d-column"}>
      <div className="flex">
        <Dates className="no-margin-top" dates={[date]} />
        <BasketLine {...line} />
        <div className="description fill-space">
          <div className="from">
            <p className="fs-med">{flight.DepTime.substring(0, flight.DepTime.length - 3)}</p>
            <p className="fs-med">{findAirportByCode(flight.DepartureAirport).state}</p>
            <p className="fs-small">{findAirportByCode(flight.DepartureAirport).name}</p>
          </div>
          <div className="info">
            <p className="fs-lrg">
              <FlightLogo className="text-image" code={flight.MarketingAirline} />
            </p>
            <p className="fs-med">
              {flight.MarketingAirline} {flight.FlightNumber}
            </p>
            {flight.OperatingAirline !== flight.MarketingAirline && (
              <p className="fs-note">
                operator:
                <FlightLogo className="text-image" code={flight.OperatingAirline} />
              </p>
            )}
            <p className="fs-note">{flight.ClassDesc}</p>
          </div>
          <div className="from">
            <p className="fs-med">{flight.ArrTime.substring(0, flight.ArrTime.length - 3)}</p>
            <p className="fs-med">{findAirportByCode(flight.ArrivalAirport).state}</p>
            <p className="fs-small">{findAirportByCode(flight.ArrivalAirport).name}</p>
          </div>
        </div>
        <div className="right-side flex d-column">
          <button
            className="remove button cart self-end gutter-bottom left-radius"
            onClick={deleteItemHandler}
          >
            X
          </button>

          <p className="price-tag fs-prc is-centered left-radius">
            {!flight.bundle ? pwc(flight.price.TotalFare, flight.Currency) : "Bundle"}
          </p>
        </div>
      </div>
    </div>
  );
}
