import React, { ChangeEvent, FC } from "react";

interface IProps {
  extended?: boolean;
  placeholder: string;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

const AccountingAction: FC<IProps> = ({ extended = false, placeholder, onChange }) => {
  return (
    <div>
      {extended ? (
        <textarea rows={3} placeholder={placeholder} onChange={onChange} />
      ) : (
        <input type="text" placeholder={placeholder} onChange={onChange} />
      )}
    </div>
  );
};

export default AccountingAction;
