import { Reducer } from "redux";
import { IFilterState } from "../../interfaces/IState";
import {
  FILTER_ACTIVITY_CHANGE,
  FILTER_CAR_CHANGE,
  FILTER_CAR_RESET,
  FILTER_CAR_TOGGLE,
  FILTER_CLOSE,
  FILTER_FLIGHT_CHANGE,
  FILTER_FLIGHT_RESET,
  FILTER_FLIGHT_SORTBY,
  FILTER_FLIGHT_TOGGLE,
  FILTER_HOTEL_CHANGE,
  FILTER_HOTEL_RESET,
  FILTER_HOTEL_SORTBY,
  FILTER_HOTEL_TOGGLE,
  FILTER_PNR_SET_INPUT,
  FILTER_PNR_SET_ITEMS,
  FILTER_SET_DEFAULT,
  FILTER_TRANSFER_CHANGE,
  FILTER_TRANSFER_RESET,
  FILTER_TRANSFER_TOGGLE
} from "../../utils/constants";
import { defaultFilters } from "../../utils/defaults";

const initialState: IFilterState = {
  activities: {
    search: {
      input: "",
      searchable: ["title"],
      type: "includes"
    },
    sortBy: "price"
  },
  cars: {
    doors: 0,
    isActive: false,
    luggage: 0,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["ModelName", "Vendor", "description", "title"],
      type: "includes"
    },
    seats: 0,
    sortBy: "price",
    transmission: "all"
  },
  transfers: {
    doors: 0,
    isActive: false,
    luggage: 0,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["ModelName", "Vendor", "description", "title"],
      type: "includes"
    },
    seats: 0,
    sortBy: "price",
    transmission: "all"
  },
  defaults: defaultFilters,
  flights: {
    airlines: [],
    arrTime: {
      max: 24,
      min: 0
    },
    depTime: {
      max: 24,
      min: 0
    },
    flightTime: {
      max: 24,
      min: 0
    },
    inlineSearch: "",
    isActive: false,
    isConnected: false,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["keywords"],
      type: "includes"
    },
    sortBy: "price"
  },
  hotels: {
    inlineSearch: "",
    isActive: false,
    price: {
      max: 10000,
      min: 0
    },
    search: {
      input: "",
      searchable: ["HotelName"],
      type: "includes"
    },
    sortBy: "price",
    stars: [1, 2, 3, 4, 5]
  },
  pnrs: {
    items: {
      activity: true,
      car: true,
      flight: true,
      hotel: true,
      transfer: true
    },
    search: {
      input: "",
      searchable: ["keywords"],
      type: "includes"
    },
    sortBy: "creation_date"
  }
};

const filterReducer: Reducer<IFilterState> = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_FLIGHT_SORTBY:
      return {
        ...state,
        flights: {
          ...state.flights,
          sortBy: action.payload
        }
      };
    case FILTER_FLIGHT_CHANGE:
      return {
        ...state,
        flights: { ...action.payload }
      };
    case FILTER_FLIGHT_RESET: {
      return {
        ...state,
        flights: {
          ...state.flights,
          ...state.defaults.flights
        }
      };
    }
    case FILTER_FLIGHT_TOGGLE:
      return {
        ...state,
        flights: {
          ...state.flights,
          isActive: !state.flights.isActive
        }
      };
    case FILTER_HOTEL_SORTBY:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          sortBy: action.payload
        }
      };
    case FILTER_HOTEL_CHANGE:
      return {
        ...state,
        hotels: { ...action.payload }
      };
    case FILTER_HOTEL_RESET: {
      return {
        ...state,
        hotels: {
          ...state.hotels,
          ...state.defaults.hotels
        }
      };
    }
    case FILTER_HOTEL_TOGGLE:
      return {
        ...state,
        hotels: {
          ...state.hotels,
          isActive: !state.hotels.isActive
        }
      };

    case FILTER_ACTIVITY_CHANGE:
      return {
        ...state,
        activities: { ...action.payload }
      };
    case FILTER_CAR_CHANGE:
      return {
        ...state,
        cars: { ...action.payload }
      };
    case FILTER_CAR_RESET: {
      return {
        ...state,
        cars: {
          ...state.cars,
          ...state.defaults.cars
        }
      };
    }
    case FILTER_CAR_TOGGLE:
      return {
        ...state,
        cars: {
          ...state.cars,
          isActive: !state.cars.isActive
        }
      };

    case FILTER_TRANSFER_CHANGE:
      return {
        ...state,
        transfers: { ...action.payload }
      };
    case FILTER_TRANSFER_RESET: {
      return {
        ...state,
        transfers: {
          ...state.transfers,
          ...state.defaults.transfers
        }
      };
    }
    case FILTER_TRANSFER_TOGGLE:
      return {
        ...state,
        transfers: {
          ...state.transfers,
          isActive: !state.transfers.isActive
        }
      };
    case FILTER_CLOSE:
      return {
        ...state,
        flights: {
          ...state.flights,
          isActive: false
        },
        hotels: {
          ...state.hotels,
          isActive: false
        }
      };

    case FILTER_SET_DEFAULT:
      return {
        ...state,
        defaults: {
          ...state.defaults,
          flights: {
            ...state.defaults.flights,
            price: action.payload.flights.price
          },
          hotels: {
            ...state.defaults.hotels,
            price: action.payload.hotels.price
          }
        },
        flights: {
          ...state.flights,
          ...action.payload.flights
        },
        hotels: {
          ...state.hotels,
          ...action.payload.hotels
        }
      };
    case FILTER_PNR_SET_ITEMS:
      return {
        ...state,
        pnrs: {
          ...state.pnrs,
          items: action.payload
        }
      };

    case FILTER_PNR_SET_INPUT:
      return {
        ...state,
        pnrs: {
          ...state.pnrs,
          search: {
            ...state.pnrs.search,
            input: action.payload
          }
        }
      };
  }
  return state;
};

export default filterReducer;
