import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

interface IProps {
  bg?: boolean;
}

const AppLoading = ({ bg = false }: IProps) => {
  const cx = classNames("app-loading", {
    "has-mask": bg
  });

  return (
    <div className={cx}>
      <FontAwesomeIcon size="2x" icon={["fas", "spinner"]} pulse={true} />
    </div>
  );
};

export default AppLoading;
