import merge from "lodash-es/merge";
import { useState } from "react";
import { RecursivePartial } from "../types";

export type UpdateObjectType<T> = (param: RecursivePartial<T>) => void;

const useObjectState = <T>(initialState: T): [T, UpdateObjectType<T>] => {
  const [object, setObject] = useState(initialState);

  const updateObject: UpdateObjectType<T> = param => {
    setObject(merge({}, object, param));
  };
  return [object, updateObject];
};

export default useObjectState;
