import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { ILogo } from "../interfaces/IUser";
import { getTheme } from "../store/selectors/uiSelectors";

type Props = {
  logos?: ILogo[];
};

const ExternalLogo: FC<Props> = ({ logos = [] }) => {
  const theme = useSelector(getTheme);

  const logo = useMemo(() => {
    const current = logos.find(l => l.theme === theme);
    if (current) {
      return current.url;
    }
    return "";
  }, [logos, theme]);

  return (
    <div>
      <img src={logo} alt="" />
    </div>
  );
};

export default ExternalLogo;
