import reduce from "lodash-es/reduce";
import React, { useMemo } from "react";
import { IBookingDetails } from "../../interfaces/IPnr";
import Modal from "../../libs/Modal";

interface IProps {
  active: boolean;
  details: IBookingDetails;
  onClose(): void;
}

export default function BookingDetailsModal({ active, details, onClose }: IProps) {
  const selections = useMemo(
    () =>
      details
        ? reduce(
            details.details,
            (total, has, detail) => {
              if (has) {
                total.push(detail);
              }
              return total;
            },
            [] as string[]
          )
        : [],
    [details]
  );

  return active ? (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>Booking Details</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="booking-details-modal">
          <div className="booking-details-item">
            <p>
              <span className="bold">Rooms:</span>
            </p>
            {details.custlist.map((c, i) => (
              <p key={i}>
                {c.roomNo}: {c.type}
              </p>
            ))}
          </div>
          <div className="booking-details-item">
            <p>
              <span className="bold">Occupancy:</span> {details.occupancy}
            </p>
          </div>
          {!!selections.length && (
            <div className="booking-details-item">
              <p>
                <span className="bold">Details:</span> {selections.join(", ")}
              </p>
            </div>
          )}
          {!!details.status && (
            <div className="booking-details-item">
              <p>
                <span className="bold">Status:</span> {details.status}
              </p>
            </div>
          )}
          {!!details.comment && (
            <div className="booking-details-item">
              <p>
                <span className="bold">Comment:</span>{" "}
              </p>
              <p>{details.comment}</p>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal.Container>
  ) : null;
}
