/* tslint:disable */

export const COLORS = {
  blue: ["#1b75bc", "#4ea8ef"],
  green: ["#28C651", "#5bf984"],
  orange: ["#f15a29", "#ff8d5c"],
  purple: ["#991EC6", "#cc51f9"]
};

export const AIRLINES = [
  {
    code: "KK",
    name: "Atlas Global"
  },
  {
    code: "TK",
    name: "Turkish Airlines"
  },
  {
    code: "QR",
    name: "Qatar Airways"
  },
  {
    code: "BA",
    name: "British Airways"
  },
  {
    code: "LH",
    name: "Lufthansa"
  }
];

export const FLIGHT_SERVICES = [
  {
    key: "AMA",
    title: "Amadeus",
    uri: "amadeusflights"
  },
  {
    key: "KK-XML",
    title: "Atlas",
    uri: "atlasflights"
  },
  {
    key: "THY",
    title: "Turk Hava Yollari",
    uri: "thyflights"
  }
];

export const darkTheme = {
  primary: "#132744",
  secondary: "#18325d",
  primaryBg: "#58585b",
  secondaryBg: "#ccc",
  danger: "#be1e2d",
  warning: "#f05a28",
  success: "#8cc63e",
  info: "#1b75bb",
  primaryText: "#fff",
  secondaryText: "#1a1a1a",
  alternateText: "#fff",
  priceTagText: "#1a1a1a",
  blackText: "#000",
  promotion: "#ffa931",
  agreement: "#FBEAD6"
};

export const lightTheme = {
  primary: "#f2f2f2",
  secondary: "#e1e1e1",
  primaryBg: "#b9b9b9",
  secondaryBg: "#fff",
  danger: "#ba4f5d",
  warning: "#eb8266",
  success: "#a2c669",
  info: "#5590b7",
  primaryText: "#575757",
  secondaryText: "#1a1a1a",
  alternateText: "#fff",
  priceTagText: "#fff",
  blackText: "#000",
  promotion: "#ffa931",
  agreement: "#FBEAD6"
};

export const chartColors = {
  car: "#7c9cd6",
  flight: "#203864",
  hotel: "#c0c0c0",
  "rent a car": "#7c9cd6",
  expenses: "#3b64ad",
  budget: "#8fa2d4"
};

export const GOOGLE_MAP_PUBLIC_KEY = "AIzaSyCdUxD1Duq_ptrqI75NlP07iMbfC_XJoJg";

export const GOOGLE_MAP_API = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_PUBLIC_KEY}&v=3.exp&libraries=geometry,drawing,places`;

export const FLIGHT_RESET = "FLIGHT_RESET";
export const FLIGHT_UPDATE = "FLIGHT_UPDATE";
export const FLIGHT_AMADEUS_CLEAR = "FLIGHT_AMADEUS_CLEAR";
export const FLIGHT_FETCH = "FLIGHT_FETCH";
export const FLIGHT_FETCH_EMPTY = "FLIGHT_FETCH_EMPTY";
export const FLIGHT_FETCH_PENDING = "FLIGHT_FETCH_PENDING";
export const FLIGHT_FETCH_FULFILLED = "FLIGHT_FETCH_FULFILLED";
export const FLIGHT_FETCH_REJECTED = "FLIGHT_FETCH_REJECTED";
export const FLIGHT_ALTERNATIVE_SELECT = "FLIGHT_ALTERNATIVE_SELECT";

export const BASKET_ADD_FLIGHT = "BASKET_ADD_FLIGHT";
export const BASKET_ADD_FLIGHT_PENDING = "BASKET_ADD_FLIGHT_PENDING";
export const BASKET_ADD_FLIGHT_FULFILLED = "BASKET_ADD_FLIGHT_FULFILLED";
export const BASKET_ADD_FLIGHT_REJECTED = "BASKET_ADD_FLIGHT_REJECTED";

export const BASKET_ADD_HOTEL = "BASKET_ADD_HOTEL";
export const BASKET_ADD_HOTEL_PENDING = "BASKET_ADD_HOTEL_PENDING";
export const BASKET_ADD_HOTEL_FULFILLED = "BASKET_ADD_HOTEL_FULFILLED";
export const BASKET_ADD_HOTEL_REJECTED = "BASKET_ADD_HOTEL_REJECTED";

export const BASKET_DELETE_ITEM = "BASKET_DELETE_ITEM";
export const BASKET_DELETE_ITEM_PENDING = "BASKET_DELETE_ITEM_PENDING";
export const BASKET_DELETE_ITEM_FULFILLED = "BASKET_DELETE_ITEM_FULFILLED";
export const BASKET_DELETE_ITEM_REJECTED = "BASKET_DELETE_ITEM_REJECTED";

export const BASKET_FETCH = "BASKET_FETCH";
export const BASKET_FETCH_EMPTY = "BASKET_FETCH_EMPTY";
export const BASKET_FETCH_PENDING = "BASKET_FETCH_PENDING";
export const BASKET_FETCH_FULFILLED = "BASKET_FETCH_FULFILLED";
export const BASKET_FETCH_REJECTED = "BASKET_FETCH_REJECTED";

export const BASKET_RESET_PENDING = "BASKET_RESET_PENDING";
export const BASKET_RESET_FULFILLED = "BASKET_RESET_FULFILLED";
export const BASKET_RESET_REJECTED = "BASKET_RESET_REJECTED";

export const BASKET_SELECT_ITEM = "BASKET_SELECT_ITEM";
export const BASKET_CLEAR = "BASKET_CLEAR";
export const BASKET_CHANGE_CURRENCY = "BASKET_CHANGE_CURRENCY";
export const BASKET_CHANGE_PAYMENT_METHOD = "BASKET_CHANGE_PAYMENT_METHOD";

export const BASKET_PAYMENT_CASH = "BASKET_PAYMENT_CASH";
export const BASKET_PAYMENT_CASH_PENDING = "BASKET_PAYMENT_CASH_PENDING";
export const BASKET_PAYMENT_CASH_FULFILLED = "BASKET_PAYMENT_CASH_FULFILLED";
export const BASKET_PAYMENT_CASH_REJECTED = "BASKET_PAYMENT_CASH_REJECTED";

export const BASKET_ORDER_BOOK = "BASKET_ORDER_BOOK";
export const BASKET_ORDER_BOOK_PENDING = "BASKET_ORDER_BOOK_PENDING";
export const BASKET_ORDER_BOOK_FULFILLED = "BASKET_ORDER_BOOK_FULFILLED";
export const BASKET_ORDER_BOOK_REJECTED = "BASKET_ORDER_BOOK_REJECTED";

export const CAR_CLEAR = "CAR_CLEAR";
export const CAR_FETCH_EMPTY = "CAR_FETCH_EMPTY";
export const CAR_FETCH_PENDING = "CAR_FETCH_PENDING";
export const CAR_FETCH_FULFILLED = "CAR_FETCH_FULFILLED";
export const CAR_FETCH_REJECTED = "CAR_FETCH_REJECTED";
export const CAR_MODAL_CLOSE = "CAR_MODAL_CLOSE";
export const CAR_MODAL_OPEN = "CAR_MODAL_OPEN";
export const CAR_SEARCH_SET = "CAR_SEARCH_SET";
export const CAR_SEARCH_SET_FROM_PROPS = "CAR_SEARCH_SET_FROM_PROPS";

export const TRANSFER_CLEAR = "TRANSFER_CLEAR";
export const TRANSFER_FETCH_EMPTY = "TRANSFER_FETCH_EMPTY";
export const TRANSFER_FETCH_PENDING = "TRANSFER_FETCH_PENDING";
export const TRANSFER_FETCH_FULFILLED = "TRANSFER_FETCH_FULFILLED";
export const TRANSFER_FETCH_REJECTED = "TRANSFER_FETCH_REJECTED";
export const TRANSFER_MODAL_CLOSE = "TRANSFER_MODAL_CLOSE";
export const TRANSFER_MODAL_OPEN = "TRANSFER_MODAL_OPEN";
export const TRANSFER_SEARCH_SET = "TRANSFER_SEARCH_SET";
export const TRANSFER_SEARCH_SET_FROM_PROPS = "TRANSFER_SEARCH_SET_FROM_PROPS";

export const SEARCH_INDEX_PUSH = "SEARCH_INDEX_PUSH";
export const SEARCH_INITIALIZE = "SEARCH_INITIALIZE";
export const SEARCH_FINALIZE = "SEARCH_FINALIZE";

export const SEARCH_RESET = "SEARCH_RESET";
export const SEARCH_UPDATE = "SEARCH_UPDATE";
export const SEARCH_UPDATE_PASSENGER = "SEARCH_UPDATE_PASSENGER";
export const SEARCH_DESTINATION_UPDATE = "SEARCH_DESTINATION_UPDATE";
export const SEARCH_CURRENT_HOTEL_MODE_UPDATE = "SEARCH_CURRENT_HOTEL_MODEUPDATE";
export const SEARCH_SELECT_FROM_HISTORY = "SEARCH_SELECT_FROM_HISTORY";
export const SEARCH_ADD_ROUTE = "SEARCH_ADD_ROUTE";
export const SEARCH_REMOVE_ROUTE = "SEARCH_REMOVE_ROUTE";

export const SEARCH_MAP_CUSTOMER_TO_PASSENGER = "SEARCH_MAP_CUSTOMER_TO_PASSENGER";

export const SEARCH_CLEAR_CUSTOMERS = "SEARCH_CLEAR_CUSTOMERS";

export const SEARCH_SHRINK_OPEN = "SEARCH_SHRINK_OPEN";
export const SEARCH_SHRINK_CLOSE = "SEARCH_SHRINK_CLOSE";
export const SEARCH_UI_CONTROL = "SEARCH_UI_CONTROL";

export const SEARCH_AIRPORTS = "SEARCH_AIRPORTS";
export const SEARCH_AIRPORTS_PENDING = "SEARCH_AIRPORTS_PENDING";
export const AIRPORTS_FETCH_FULFILLED = "AIRPORTS_FETCH_FULFILLED";
export const AIRPORTS_FETCH_REJECTED = "AIRPORTS_FETCH_REJECTED";
export const AIRPORTS_FETCH_SKIPPED = "AIRPORTS_FETCH_SKIPPED";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_PENDING = "USER_LOGOUT_PENDING";
export const USER_LOGOUT_FULFILLED = "USER_LOGOUT_FULFILLED";
export const USER_LOGOUT_REJECTED = "USER_LOGOUT_REJECTED";

export const USER_LOADED = "USER_LOADED";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_PENDING = "USER_LOGIN_PENDING";
export const USER_LOGIN_FULFILLED = "USER_LOGIN_FULFILLED";
export const USER_LOGIN_REJECTED = "USER_LOGIN_REJECTED";

export const USER_COMPANY_CHANGED = "USER_COMPANY_CHANGED";
export const USER_COMPANY_LOADED = "USER_COMPANY_LOADED";

export const USER_GENERATE_TABID = "USER_GENERATE_TABID";

export const USER_CHECK_AUTH_FAILED = "USER_CHECK_AUTH_FAILED";
export const USER_CHECK_AUTH_SUCCESS = "USER_CHECK_AUTH_SUCCESS";
export const USER_CHECK_AUTH = "USER_CHECK_AUTH";
export const USER_CHECK_AUTH_PENDING = "USER_CHECK_AUTH_PENDING";
export const USER_CHECK_AUTH_FULFILLED = "USER_CHECK_AUTH_FULFILLED";
export const USER_CHECK_AUTH_REJECTED = "USER_CHECK_AUTH_REJECTED";
export const USER_HAS_ACCESS_TO_PAGE = "USER_HAS_ACCESS_TO_PAGE";

export const HOTEL_CLEAR = "HOTEL_CLEAR";
export const HOTEL_AMADEUS_CLEAR = "HOTEL_AMADEUS_CLEAR";
export const HOTEL_FETCH = "HOTEL_FETCH";
export const HOTEL_FETCH_EMPTY = "HOTEL_FETCH_EMPTY";
export const HOTEL_FETCH_PENDING = "HOTEL_FETCH_PENDING";
export const HOTEL_FETCH_FULFILLED = "HOTEL_FETCH_FULFILLED";
export const HOTEL_FETCH_REJECTED = "HOTEL_FETCH_REJECTED";

export const HOTEL_ROOM_UPDATE = "HOTEL_ROOM_UPDATE";

export const HOTEL_DETAILS_BATCH_FETCH = "HOTEL_DETAILS_BATCH_FETCH";
export const HOTEL_DETAILS_SINGLE_FETCH = "HOTEL_DETAILS_SINGLE_FETCH";
export const HOTEL_DETAILS_FETCH_PENDING = "HOTEL_DETAILS_FETCH_PENDING";
export const HOTEL_DETAILS_FETCH_FULFILLED = "HOTEL_DETAILS_FETCH_FULFILLED";
export const HOTEL_DETAILS_FETCH_REJECTED = "HOTEL_FETCH_REJECTED";

export const ACTIVITY_CLEAR = "ACTIVITY_CLEAR";
export const ACTIVITY_FETCH = "ACTIVITY_FETCH";
export const ACTIVITY_FETCH_EMPTY = "ACTIVITY_FETCH_EMPTY";
export const ACTIVITY_FETCH_PENDING = "ACTIVITY_FETCH_PENDING";
export const ACTIVITY_FETCH_FULFILLED = "ACTIVITY_FETCH_FULFILLED";
export const ACTIVITY_FETCH_REJECTED = "ACTIVITY_FETCH_REJECTED";

export const HOTEL_ROOM_FETCH = "HOTEL_ROOM_FETCH";
export const HOTEL_ROOM_FETCH_PENDING = "HOTEL_ROOM_FETCH_PENDING";
export const HOTEL_ROOM_FETCH_FULFILLED = "HOTEL_ROOM_FETCH_FULFILLED";
export const HOTEL_ROOM_FETCH_EMPTY = "HOTEL_ROOM_FETCH_EMPTY";
export const HOTEL_ROOM_FETCH_REJECTED = "HOTEL_ROOM_FETCH_REJECTED";
export const HOTEL_ROOM_SELECT = "HOTEL_ROOM_SELECT";
export const HOTEL_ROOM_SELECT_CHEAPEST = "HOTEL_ROOM_SELECT_CHEAPEST";
export const HOTEL_ROOM_CHANGE = "HOTEL_ROOM_CHANGE";

export const HOTEL_ROOM_FETCH_ORDERED = "HOTEL_ROOM_FETCH_ORDERED";
export const HOTEL_ROOM_FETCH_ORDERED_PENDING = "HOTEL_ROOM_FETCH_PENDINGORDERED_";
export const HOTEL_ROOM_FETCH_ORDERED_FULFILLED = "HOTEL_ROOM_FETCH_ORDERED_FULFILLED";
export const HOTEL_ROOM_FETCH_ORDERED_REJECTED = "HOTEL_ROOM_FETCH_ORDERED_REJECTED";
export const HOTEL_ROOM_FETCH_ORDERED_DONE = "HOTEL_ROOM_FETCH_ORDERED_DONE";

export const CUSTOMER_FETCH = "CUSTOMER_FETCH";
export const CUSTOMER_FETCH_PENDING = "CUSTOMER_FETCH_PENDING";
export const CUSTOMER_FETCH_FULFILLED = "CUSTOMER_FETCH_FULFILLED";
export const CUSTOMER_FETCH_REJECTED = "CUSTOMER_FETCH_REJECTED";
export const CUSTOMER_SELECT = "CUSTOMER_SELECT";
export const CUSTOMER_SCREEN_TOGGLE = "CUSTOMER_SCREEN_TOGGLE";
export const CUSTOMER_TOGGLE = "CUSTOMER_TOGGLE";
export const CUSTOMER_SET_FROM_LIST = "CUSTOMER_SET_FROM_LIST";
export const CUSTOMER_INITIALIZE = "CUSTOMER_INITIALIZE";

export const CUSTOMER_ADJUST_ROOMS = "CUSTOMER_ADJUST_ROOMS";
export const CUSTOMER_ASSIGN_ROOM = "CUSTOMER_ASSIGN_ROOM";
export const CUSTOMER_REMOVE_FROM_ROOMS = "CUSTOMER_REMOVE_FROM_ROOMS";
export const CUSTOMER_CLEAR = "CUSTOMER_CLEAR";

export const BOOKING_PROVISION_PENDING = "BOOKING_PROVISION_PENDING";
export const BOOKING_PROVISION_FULFILLED = "BOOKING_PROVISION_FULFILLED";
export const BOOKING_PROVISION_REJECTED = "BOOKING_PROVISION_REJECTED";

export const BOOKING_MAKE = "BOOKING_MAKE";
export const BOOKING_MAKE_PENDING = "BOOKING_MAKE_PENDING";
export const BOOKING_MAKE_FULFILLED = "BOOKING_MAKE_FULFILLED";
export const BOOKING_MAKE_REJECTED = "BOOKING_MAKE_REJECTED";
export const BOOKING_FETCH_RULE = "BOOKING_FETCH_RULE";
export const BOOKING_FETCH_RULE_PENDING = "BOOKING_FETCH_RULE_PENDING";
export const BOOKING_FETCH_RULE_FULFILLED = "BOOKING_FETCH_RULE_FULFILLED";
export const BOOKING_FETCH_RULE_REJECTED = "BOOKING_FETCH_RULE_REJECTED";

export const BOOKING_RESET = "BOOKING_RESET";

export const BOOKING_PAY_CASH = "BOOKING_PAY_CASH";
export const BOOKING_PAY_CASH_PENDING = "BOOKING_PAY_CASH_PENDING";
export const BOOKING_PAY_CASH_FULFILLED = "BOOKING_PAY_CASH_FULFILLED";
export const BOOKING_PAY_CASH_REJECTED = "BOOKING_PAY_CASH_REJECTED";
export const BOOKING_PAY_CASH_NEED_CONFIRMATION = "BOOKING_PAY_CASH_NEED_CONFIRMATION";
export const BOOKING_PAY_CREDIT = "BOOKING_PAY_CREDIT";
export const BOOKING_PAY_CREDIT_PENDING = "BOOKING_PAY_CREDIT_PENDING";
export const BOOKING_PAY_CREDIT_FULFILLED = "BOOKING_PAY_CREDIT_FULFILLED";
export const BOOKING_PAY_CREDIT_REJECTED = "BOOKING_PAY_CREDIT_REJECTED";

export const NOTIFICATION_INIT = "NOTIFICATION_INIT";
export const NOTIFICATION_FETCH = "NOTIFICATION_FETCH";
export const NOTIFICATION_FETCH_PENDING = "NOTIFICATION_FETCH_PENDING";
export const NOTIFICATION_FETCH_FULFILLED = "NOTIFICATION_FETCH_FULFILLED";
export const NOTIFICATION_FETCH_REJECTED = "NOTIFICATION_FETCH_REJECTED";

export const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";
export const NOTIFICATION_DISMISS_ALL = "NOTIFICATION_DISMISS_ALL";

export const NOTIFICATION_READ = "NOTIFICATION_READ";

export const PNR_FETCH = "PNR_FETCH";
export const PNR_FETCH_PENDING = "PNR_FETCH_PENDING";
export const PNR_FETCH_FULFILLED = "PNR_FETCH_FULFILLED";
export const PNR_FETCH_REJECTED = "PNR_FETCH_REJECTED";

export const PNR_FETCH_DETAILS = "PNR_FETCH_DETAILS";
export const PNR_FETCH_DETAILS_SELECT = "PNR_FETCH_DETAILS_SELECT";
export const PNR_FETCH_DETAILS_PENDING = "PNR_FETCH_DETAILS_PENDING";
export const PNR_FETCH_DETAILS_FULFILLED = "PNR_FETCH_DETAILS_FULFILLED";
export const PNR_FETCH_DETAILS_REJECTED = "PNR_FETCH_DETAILS_REJECTED";

export const PNR_FETCH_DETAILS_NOT_VALID = "PNR_FETCH_DETAILS_NOT_VALID";

export const PNR_UPDATE = "PNR_UPDATE";

export const LOG_PUSH_NEW = "LOG_PUSH_NEW";

export const SERVICE_FETCH = "SERVICE_FETCH";
export const SERVICE_FETCH_PENDING = "SERVICE_FETCH_PENDING";
export const SERVICE_FETCH_FULFILLED = "SERVICE_FETCH_FULFILLED";
export const SERVICE_FETCH_REJECTED = "SERVICE_FETCH_REJECTED";

export const FILTER_SET_DEFAULT_PRICE = "FILTER_SET_DEFAULT_PRICE";
export const FILTER_SET_DEFAULT = "FILTER_SET_DEFAULT";
export const FILTER_CLOSE = "FILTER_CLOSE";

export const FILTER_FLIGHT_CHANGE = "FILTER_FLIGHT_CHANGE";
export const FILTER_FLIGHT_RESET = "FILTER_FLIGHT_RESET";
export const FILTER_FLIGHT_TOGGLE = "FILTER_FLIGHT_TOGGLE";
export const FILTER_FLIGHT_SORTBY = "FILTER_FLIGHT_SORTBY";

export const FILTER_HOTEL_CHANGE = "FILTER_HOTEL_CHANGE";
export const FILTER_HOTEL_RESET = "FILTER_HOTEL_RESET";
export const FILTER_HOTEL_TOGGLE = "FILTER_HOTEL_TOGGLE";
export const FILTER_HOTEL_SORTBY = "FILTER_HOTEL_SORTBY";

export const FILTER_PNR_SET_ITEMS = "FILTER_PNR_SET_ITEMS";
export const FILTER_PNR_SET_INPUT = "FILTER_PNR_SET_INPUT";

export const FILTER_CAR_CHANGE = "FILTER_CAR_CHANGE";
export const FILTER_CAR_RESET = "FILTER_CAR_RESET";
export const FILTER_CAR_TOGGLE = "FILTER_CAR_TOGGLE";

export const FILTER_TRANSFER_CHANGE = "FILTER_TRANSFER_CHANGE";
export const FILTER_TRANSFER_RESET = "FILTER_TRANSFER_RESET";
export const FILTER_TRANSFER_TOGGLE = "FILTER_TRANSFER_TOGGLE";

export const FILTER_ACTIVITY_CHANGE = "FILTER_ACTIVITY_CHANGE";
export const FILTER_ACTIVITY_RESET = "FILTER_ACTIVITY_RESET";
export const FILTER_ACTIVITY_TOGGLE = "FILTER_ACTIVITY_TOGGLE";
export const FILTER_ACTIVITY_SORTBY = "FILTER_ACTIVITY_SORTBY";

export const ACCOUNTING_FETCH_PENDING = "ACCOUNTING_FETCH_PENDING";
export const ACCOUNTING_FETCH_EMPTY = "ACCOUNTING_FETCH_EMPTY";
export const ACCOUNTING_FETCH_FULFILLED = "ACCOUNTING_FETCH_FULFILLED";
export const ACCOUNTING_FETCH_REJECTED = "ACCOUNTING_FETCH_REJECTED";

export const ACCOUNTING_SET_FILTER = "ACCOUNTING_SET_FILTER";
export const ACCOUNTING_SET_ORDER = "ACCOUNTING_SET_ORDER";
export const ACCOUNTING_REPORT_SELECT = "ACCOUNTING_REPORT_SELECT";
export const ACCOUNTING_FILTERED_REPORTS_TOGGLE = "ACCOUNTING_FILTERED_REPORTS_TOGGLE";
export const ACCOUNTING_UPDATE_REPORT = "ACCOUNTING_UPDATE_REPORT";
export const ACCOUNTING_SELECT_ALL_FILTERED_REPORTS = "ACCOUNTING_SELECT_ALL_FILTERED_REPORTS";

export const UI_APP_LOADED = "UI_APP_LOADED";
export const UI_HOTEL_DETAILS_SCREEN_TOGGLE = "UI_HOTEL_DETAILS_SCREEN_TOGGLE";
export const UI_HOTEL_DETAILS_SCREEN_OPEN = "UI_HOTEL_DETAILS_SCREEN_OPEN";
export const UI_HOTEL_DETAILS_SCREEN_CLOSE = "UI_HOTEL_DETAILS_SCREEN_CLOSE";
export const UI_BASKET_TOGGLE = "UI_BASKET_TOGGLE";
export const UI_CUSTOMER_SCREEN_TOGGLE = "UI_CUSTOMER_SCREEN_TOGGLE";
export const UI_CHECKOUT_SCREEN_TOGGLE = "UI_CHECKOUT_SCREEN_TOGGLE";
export const UI_CHECKOUT_SET_DATA = "UI_CHECKOUT_SET_DATA";
export const UI_CHECKOUT_SET_FLIGHT = "UI_CHECKOUT_SET_FLIGHT";
export const UI_CHECKOUT_HOTEL_DATA_UPDATE = "UI_CHECKOUT_HOTEL_DATA_UPDATE";
export const UI_CHECKOUT_FLIGHT_DATA_UPDATE = "UI_CHECKOUT_FLIGHT_DATA_UPDATE";
export const UI_CHECKOUT_AMOUNT_DATA_UPDATE = "UI_CHECKOUT_AMOUNT_DATA_UPDATE";
export const UI_CHECKOUT_SET_PENDING = "UI_CHECKOUT_SET_PENDING";
export const UI_CHECKOUT_SET_SHOULD_CLOSE = "UI_CHECKOUT_SET_SHOULD_CLOSE";
export const UI_BASKET_SET_PENDING = "UI_BASKET_SET_PENDING";
export const UI_BASKET_SET_DATA = "UI_BASKET_SET_DATA";
export const UI_CHANGE_THEME = "UI_CHANGE_THEME";
export const UI_RESET = "UI_RESET";
export const UI_CLEAR_RESET = "UI_CLEAR_RESET";

export const PROVISION_PENDING = "PROVISION_PENDING";
export const PROVISION_FULFILLED = "PROVISION_FULFILLED";
export const PROVISION_REJECTED = "PROVISION_ROOM_REJECTED";

export const PROVISION_ANSWER_QUESTION = "PROVISION_ANSWER_QUESTION";

export const PROVISION_HAS_QUESTIONS = "PROVISION_HAS_QUESTIONS";

export const SELECTED_ITEMS_SELECT_HOTEL = "SELECTED_ITEMS_SELECT_HOTEL";
export const SELECTED_ITEMS_SELECT_ROOM = "SELECTED_ITEMS_SELECT_ROOM";
export const SELECTED_ITEMS_SELECT_FLIGHT = "SELECTED_ITEMS_SELECT_FLIGHT";
export const SELECTED_ITEMS_SELECT_CAR = "SELECTED_ITEMS_SELECT_CAR";
export const SELECTED_ITEMS_SELECT_ACTIVITY = "SELECTED_ITEMS_SELECT_ACTIVITY";

export const PROGRAM_SELECT = "PROGRAM_SELECT";
export const PROGRAM_FETCH_EMPTY = "PROGRAM_FETCH_EMPTY";
export const PROGRAM_FETCH_PENDING = "PROGRAM_FETCH_PENDING";
export const PROGRAM_FETCH_FULFILLED = "PROGRAM_FETCH_FULFILLED";
export const PROGRAM_FETCH_REJECTED = "PROGRAM_FETCH_REJECTED";

export const TRANSPORT_MODAL_OPEN = "TRANSPORTATION_MODAL_OPEN";
export const TRANSPORT_MODAL_CLOSE = "TRANSPORTATION_MODAL_CLOSE";
export const TRANSPORT_SET_SEARCH = "TRANSPORT_SET_SEARCH";
export const TRANSPORT_SET_SEARCH_FROM_PROPS = "TRANSPORT_SET_SEARCH_FROM_PROPS";

export const TRANSPORT_CAR_FETCH_PENDING = "TRANSPORT_CAR_FETCH_PENDING";
export const TRANSPORT_CAR_FETCH_FULFILLED = "TRANSPORT_CAR_FETCH_FULFILLED";
export const TRANSPORT_CAR_FETCH_EMPTY = "TRANSPORT_CAR_FETCH_EMPTY";
export const TRANSPORT_CAR_FETCH_REJECTED = "TRANSPORT_CAR_FETCH_REJECTED";
export const TRANSPORT_CAR_CLEAR = "TRANSPORT_CAR_CLEAR";
