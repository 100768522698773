import { Formik, Form } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { IAgreementHotel, IAgreementRoom } from "../../../interfaces/IHotel";
import { api } from "../../../services/api";
import Logger from "../../../services/Logger";
import { getUser } from "../../../store/selectors/userSelectors";
import AgreementHotelEditDetails from "./AgreementHotelEditDetails";
import AgreementHotelEditRooms from "./AgreementHotelEditRooms";
import cx from "classnames";
import omit from "lodash-es/omit";
import * as yup from "yup";

type Props = {
  hotel: IAgreementHotel;
  refresh(): void;
};

const validationSchema = yup.object({
  address: yup.string().required(),
  agency_id: yup.number().required(),
  city_code: yup.string().required(),
  company_code: yup.string().required(),
  contact_email: yup.string().required(),
  country_code: yup.string().required(),
  description: yup.string().required(),
  hotel_code: yup.string().required(),
  hotel_name: yup.string().required(),
  image_url: yup.string().required(),
  inventory_source: yup.string().required(),
  latitude: yup.string().required(),
  longitude: yup.string().required(),
  rating: yup.string().required(),
  status: yup.string().required(),
  rooms: yup.array().of(
    yup.object({
      agreement_guid: yup.string().required(),
      room_title: yup.string().required(),
      room_type: yup.string().required(),
      price: yup.number().required(),
      currency: yup.string().required(),
      status_id: yup.number().required(),
      bed_type: yup.string().required(),
      board: yup.string().required(),
      vat: yup.number().required(),
      start_date: yup.string().required(),
      end_date: yup.string().required()
    })
  )
});

const AgreementHotelEdit: FC<Props> = ({ hotel, refresh }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [tab, setTab] = useState(0);
  const [rooms, setRooms] = useState<IAgreementRoom[]>([]);

  useEffect(() => {
    if (hotel.guid) {
      api()
        .get("hotelagreementrooms", {
          params: {
            key: hotel.guid
          }
        })
        .then(({ data }) => {
          setRooms(data.rooms ?? []);
        });
    }
  }, [hotel.guid]);

  const initialValues: IAgreementHotel = useMemo(() => {
    return {
      ...hotel,
      agency_id: user.agencyid,
      company_code: user.companycode,
      rooms
    };
  }, [hotel, rooms, user]);

  const submitHandler = useCallback(
    async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
      setSubmitting(true);
      try {
        await api().post("hotelagreement", omit(values, "rooms"));
        Logger.flash(t("admin.agreement.success"));
        refresh();
      } catch {
        Logger.flash(t("admin.agreement.error"), { type: "error" });
      }
      try {
        await api().post("hotelagreementrooms", values.rooms);
        Logger.flash(t("admin.agreement.roomSuccess"));
      } catch {
        Logger.flash(t("admin.agreement.roomError"), { type: "error" });
      }
      setSubmitting(false);
    },
    [initialValues, refresh, t]
  );

  return (
    <div className="flex d-column grow">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="admin-sub-nav">
              <ul className="flex full-width">
                <li
                  className={cx("tab-link", {
                    "tab-is-active": tab === 0
                  })}
                >
                  <button type="button" className="pointer" onClick={() => setTab(0)}>
                    Information
                  </button>
                </li>
                <li
                  className={cx("tab-link", {
                    "tab-is-active": tab === 1
                  })}
                >
                  <button type="button" className="pointer" onClick={() => setTab(1)}>
                    Rooms
                  </button>
                </li>
              </ul>
              <ul>
                <li>
                  <button disabled={isSubmitting} className="agreement-submit" type="submit">
                    {isSubmitting ? <Loading /> : t("generic.button.save")}
                  </button>
                </li>
              </ul>
            </div>
            <div className="admin-content gray-box ignore-top-radius">
              <div className="form">
                <div
                  className={cx({
                    "tab-is-active": tab === 0,
                    "tab-is-hidden": tab !== 0
                  })}
                >
                  <AgreementHotelEditDetails />
                </div>
                <div
                  className={cx({
                    "tab-is-active": tab === 1,
                    "tab-is-hidden": tab !== 1
                  })}
                >
                  <AgreementHotelEditRooms />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AgreementHotelEdit;
