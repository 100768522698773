import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPnrFlight, IPnrService } from "../../interfaces/IPnr";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { pnrTKey } from "../../utils/helpers";
import ButtonWithConfirm from "../confirmation/ButtonWithConfirm";
import OfferFlight from "../OfferFlight";
import { Busy } from "../snippets/Busy";
import PnrHistory from "./PnrHistory";
import PnrPayment from "./PnrPayment";
import PnrPeople from "./PnrPeople";
import PnrTickets from "./PnrTickets";

interface IProps {
  pnr: IPnrFlight;

  reload(pnr: IPnrFlight): any;
  updatePnr(pnr: string, status: string): void;
}

const PnrFlight: FC<IProps> = ({ pnr, reload, updatePnr }) => {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);

  const reloadHandler = useCallback(() => {
    setIsBusy(true);
    reload(pnr)
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [pnr, reload]);

  const pnrAction = useCallback(
    async (service: IPnrService) => {
      setIsBusy(true);
      const log = new Logger(t(`pnr.${pnrTKey(service.uri)}Pending`));
      try {
        await api().post(service.uri, pnr);
        log.success(t(`pnr.${pnrTKey(service.uri)}Success`));
        updatePnr(pnr.pnr, pnr.status);
        reloadHandler();
      } catch (e) {
        log.error(e);
      }
      setIsBusy(false);
    },
    [pnr, reloadHandler, t, updatePnr]
  );

  return (
    <>
      {isBusy && <Busy />}
      <div className="pnr-actions">
        {pnr.services &&
          pnr.services.map(s => {
            if (s.uri.includes("pnrqueue")) {
              return (
                <button key={s.title} onClick={() => pnrAction(s)}>
                  {s.title}
                </button>
              );
            }
            return (
              <ButtonWithConfirm
                key={s.title}
                message={t("generic.confirmAction")}
                actions={[
                  {
                    color: "success",
                    handler: () => pnrAction(s),
                    text: s.title
                  }
                ]}
              >
                {s.title}
              </ButtonWithConfirm>
            );
          })}
        {/*<CarButton*/}
        {/*  customers={pnr.custlist}*/}
        {/*  deliverDate={pnr.offer.airitin.origdests![pnr.offer.airitin.origdests!.length - 1].DepDate}*/}
        {/*  pickupDate={pnr.offer.airitin.origdests![0].DepDate}*/}
        {/*  pickupFrom={pnr.offer.airitin.origdests![0].ArrivalAirport}*/}
        {/*  deliverTo={pnr.offer.airitin.origdests![pnr.offer.airitin.origdests!.length - 1].ArrivalAirport}*/}
        {/*  passenger={countCustomers(pnr.custlist)}*/}
        {/*/>*/}
        <span className="fill-space is-tablet-only" />
      </div>
      <div className="pnr-list-scroll-area">
        <div className="pnr-details">
          <div className="title flex j-between a-center is-relative">
            <span className="pnr-header-icon c-primary">
              <FontAwesomeIcon icon={["far", "ticket-alt"]} />
            </span>
            <h1 className="text fs-lrg fill-space c-primary bold">{t("pnr.bookingCard")}</h1>
          </div>
          <div className="flex j-between sub-title align-center">
            <div>
              <span className="c-primary">
                <span className="text fs-med">PNR:</span> <span className="text bold">{pnr.pnr}</span>
              </span>
            </div>
            <div>
              <div>
                {pnr.timelimit && <p className="pnr-timelimit">son gün: {pnr.timelimit}</p>}
                <p className="c-primary pnr-status">
                  <span className="text fs-med">{t("generic.status").toUpperCase()}:</span>
                  <span className={pnr.status ? pnr.status.toLowerCase() : ""}>&nbsp;{pnr.status}</span>
                </p>
              </div>
              <p className="c-primary fs-std align-right">
                <span>{t("generic.source")}:</span>
                {pnr.source}
              </p>
            </div>
          </div>
          <div className={`pnr-details-flights ${pnr.status ? pnr.status.toLowerCase() : ""}`}>
            {pnr.offer && pnr.offer.airitin.origdests && <OfferFlight flight={pnr.offer} />}
          </div>
        </div>
        <PnrPayment payments={pnr.payment} />
        {pnr.tktlist ? <PnrTickets pnr={pnr} /> : pnr.offer && <PnrPeople pnr={pnr as IPnrFlight} />}
        <PnrHistory pnr={pnr} />
      </div>
    </>
  );
};

export default PnrFlight;
