import { utc } from "moment";
import React, { FC } from "react";
import { IActivity } from "../../interfaces/IActivity";
import { pwc } from "../../utils/helpers";
import Dates from "../Dates";

interface IProps {
  activity: IActivity;
  override?: boolean;

  deleteItem(OfferId: string): void;
}

const BasketActivity: FC<IProps> = ({ activity, deleteItem }) => {
  const dates = [{ date: activity.date, direction: "0" }];

  const deleteItemHandler = () => {
    deleteItem(activity.OfferId);
  };

  return (
    <div className="hotel box no-padding-right padding-bottom-10 flex d-column">
      <div className="flex">
        <Dates className="no-margin-top" dates={dates} vertical={true} />
        <div className="description fill-space flex d-column j-even">
          <p className="fs-med">&nbsp;&nbsp;{utc(activity.date).format("D MMM YYYY")}</p>
          <p className="fs-med">
            &nbsp;&nbsp;{activity.City} / {activity.Country}
          </p>
          <p className="fs-med">
            &nbsp;&nbsp;{activity.Quantity} {activity.activityType}
          </p>
          <div className="info flex a-center">
            <div
              className="bg-image bg-cover no-shrink"
              style={{ backgroundImage: `url(${activity.ActivityImageUrl})` }}
            />
            <p className="fs-med">{activity.title}</p>
          </div>
        </div>
        <div className="right-side flex d-column">
          <button
            className="remove button cart self-end gutter-bottom left-radius"
            onClick={deleteItemHandler}
          >
            X
          </button>
          <div className="fill-space" />
          <p className="price-tag fs-prc is-centered left-radius">
            {pwc(activity.AmountAfterTax, activity.currency)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasketActivity;
