import isArray from "lodash-es/isArray";
import map from "lodash-es/map";
import { batch } from "react-redux";
import { IAirport } from "../../interfaces/IAirport";
import { api } from "../../services/api";
import { AC } from "../../types";
import {
  ACTIVITY_CLEAR,
  ACTIVITY_FETCH_FULFILLED,
  CAR_CLEAR,
  CAR_FETCH_FULFILLED,
  FLIGHT_FETCH_FULFILLED,
  HOTEL_FETCH_FULFILLED
} from "../../utils/constants";
import { getActivityServices, getCarServices } from "../selectors/serviceSelectors";
import { fetchBasket } from "./basketActions";
import { initCustomers } from "./customerActions";
import { filterReset } from "./filterActions";
import { initializeNotifications } from "./notificationActions";
import { searchInitialize } from "./searchActions";
import { fetchServices } from "./serviceActions";
import { loadUserCompanies } from "./userActions";

export const initializePublicData = async () => {
  await fetchAirports();
};

export const initializePrivateData: AC = () => async dispatch => {
  batch(async () => {
    await Promise.all([
      dispatch(initCustomers()),
      dispatch(initializeNotifications()),
      dispatch(searchInitialize()),
      dispatch(loadUserCompanies()),
      dispatch(fetchBasket()),
      dispatch(fetchServices())
    ]);

    if (process.env.NODE_ENV !== "production") {
      dispatch(loadMockFlights());
      // dispatch(loadMockHotels());
      // dispatch(loadMockActivities());
      // dispatch(loadMockCars());
    }
  });
};

const fetchAirports = async () => {
  try {
    const { data } = await api({ self: true }).get<IAirport[]>("airports.json");
    if (isArray(data)) {
      window.airports = data;
    }
  } catch {}
};

export const loadMockHotels: AC = () => async dispatch => {
  const { data } = await api({ self: true }).get("/mocks/hotels.json");
  dispatch({
    payload: data,
    service: "AMA",
    type: `AMA_${HOTEL_FETCH_FULFILLED}`
  });
};

export const loadMockFlights: AC = () => async dispatch => {
  dispatch(filterReset());

  const { data } = await api({ self: true }).get("/mocks/flights.json");
  dispatch({
    payload: { flights: data },
    service: "TK-XML",
    type: `TK-XML_${FLIGHT_FETCH_FULFILLED}`
  });
};

export const ldataoadMockActivities: AC = () => async (dispatch, getState) => {
  dispatch({
    payload: map(getActivityServices(getState()), "key"),
    type: ACTIVITY_CLEAR
  });

  const { data } = await api({ self: true }).get("/mocks/activities.json");

  dispatch({
    key: "BEDSONLINE",
    payload: data,
    service: "BEDSONLINE",
    type: `BEDSONLINE_${ACTIVITY_FETCH_FULFILLED}`
  });
};

export const loadMockCars: AC = () => async (dispatch, getState) => {
  dispatch({
    payload: map(getCarServices(getState()), "key"),
    type: CAR_CLEAR
  });

  const { data } = await api({ self: true }).get("/mocks/cars.json");

  dispatch({
    key: "CARTARGET",
    payload: data,
    service: "CARTARGET",
    type: `CARTARGET_${CAR_FETCH_FULFILLED}`
  });
};
