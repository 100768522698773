import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, FC, useCallback, useMemo, useState } from "react";

interface IProps {
  column?: number;
  title?: string;
  totalColumn?: number;
}

const FormSection: FC<IProps> = ({ children, column, title, totalColumn = 3 }) => {
  const [isClosed, setIsClosed] = useState(false);

  const toggleSection = useCallback(() => {
    setIsClosed(x => !x);
  }, []);

  const styles: CSSProperties = useMemo(() => {
    return column
      ? {
          flexBasis: "unset",
          width: ((100 / totalColumn!) * column).toFixed(2) + "%"
        }
      : {};
  }, [totalColumn, column]);

  return (
    <div className="form-section" style={styles}>
      {title && (
        <div className="title pointer flex a-center" onClick={toggleSection}>
          <span className="caret-indicator">
            {isClosed ? (
              <FontAwesomeIcon icon={["fas", "caret-right"]} />
            ) : (
              <FontAwesomeIcon icon={["fas", "caret-down"]} />
            )}
          </span>
          <h1>{title}</h1>
        </div>
      )}
      <div className={isClosed ? "is-closed" : ""}>{children}</div>
    </div>
  );
};

export default FormSection;
