import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissAllNotification,
  dismissNotification,
  fetchNotifications,
  readNotifications
} from "../store/actions/notificationActions";
import { getNotifications } from "../store/selectors/notificationSelectors";
import Notification from "./../components/Notification";

interface IProps {
  mobile?: boolean;
}

const Notifications: FC<IProps> = ({ mobile = false }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications);

  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const newNotificationCount = useMemo(() => {
    return notifications.filter(n => !n.isRead).length;
  }, [notifications]);

  const toggleNotifications = () => {
    setIsActive(notifications.length > 0 && !isActive);
    dispatch(readNotifications());
  };

  const dismissAllHandler = useCallback(() => {
    dispatch(dismissAllNotification());
  }, [dispatch]);

  const dismissHandler = useCallback(
    (id: string) => {
      dispatch(dismissNotification(id));
    },
    [dispatch]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchNotifications());
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [dispatch]);

  const NotificationButton = () => {
    return mobile ? (
      <button className="notification-badge main-link pointer c-primary" onClick={toggleNotifications}>
        {newNotificationCount > 0 && <span className="count">{newNotificationCount}</span>}
        <FontAwesomeIcon icon={["far", "bell"]} />
        {t("notification.title")}
      </button>
    ) : (
      <button
        data-tip={t("notification.title")}
        className="notification-badge pointer c-primary"
        onClick={toggleNotifications}
      >
        {newNotificationCount > 0 && <span className="count">{newNotificationCount}</span>}
        <FontAwesomeIcon icon={["far", "bell"]} />
      </button>
    );
  };

  return (
    <div className="notification-center">
      <NotificationButton />
      {notifications.length > 0 && isActive && (
        <div className="notifications">
          {notifications.map(notification => (
            <Notification key={notification.notificationid} {...notification} dismiss={dismissHandler} />
          ))}
          <button className="dismiss-all" onClick={dismissAllHandler}>
            {t("notification.clear")}
          </button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
