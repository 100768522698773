import { Reducer } from "redux";
import { ISelectedItemsState } from "../../interfaces/IState";
import {
  SELECTED_ITEMS_SELECT_ACTIVITY,
  SELECTED_ITEMS_SELECT_CAR,
  SELECTED_ITEMS_SELECT_FLIGHT,
  SELECTED_ITEMS_SELECT_HOTEL,
  SELECTED_ITEMS_SELECT_ROOM
} from "../../utils/constants";

const initialState: ISelectedItemsState = {};

const selectedItemsReducer: Reducer<ISelectedItemsState> = (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_ITEMS_SELECT_FLIGHT:
      return {
        ...state,
        flight: payload
      };
    case SELECTED_ITEMS_SELECT_HOTEL:
      return {
        ...state,
        hotel: payload
      };
    case SELECTED_ITEMS_SELECT_ROOM:
      return {
        ...state,
        room: {
          [state.hotel!.OfferId]: payload
        }
      };
    case SELECTED_ITEMS_SELECT_ACTIVITY:
      return {
        ...state,
        activity: {
          ...state.activity,
          [payload.activityCode]: payload
        }
      };

    case SELECTED_ITEMS_SELECT_CAR:
      return {
        ...state,
        car: {
          ...state.car,
          [payload.VehicleCategory]: payload
        }
      };
  }
  return state;
};

export default selectedItemsReducer;
