import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { IAgreementHotel } from "../../../interfaces/IHotel";

interface IProps {
  hotels: IAgreementHotel[];
}

const AgreementHotelList: FC<IProps> = ({ hotels }) => {
  return (
    <div className="agreement-hotel-list">
      {hotels.map(hotel => (
        <NavLink
          activeClassName="selected"
          to={"/admin/hotels/" + hotel.guid}
          key={hotel.guid}
          className="agreement-hotel"
        >
          <p>{hotel.hotel_name}</p>
        </NavLink>
      ))}
    </div>
  );
};

export default AgreementHotelList;
