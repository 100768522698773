import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickDetect from "../../hooks/useClickDetect";
import Counter from "./Counter";

interface IProps {
  propKeys: string[];
  title: string;
  values: { [key: string]: number };
}

const Counters: FC<IProps> = ({ propKeys, title, values }: IProps) => {
  const $el = useClickDetect(() => setIsActive(false));
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const infantCount = useMemo(() => {
    return values.inf ? `(+${values.inf} Inf)` : "";
  }, [values.inf]);

  const countToTitle = () => {
    return `${values.adt + values.chd}${infantCount} ${title}`;
  };

  return (
    <div ref={$el} className="passenger-box">
      <h1 className="dropdown-title medium" onClick={() => setIsActive(!isActive)}>
        {countToTitle()}
        <FontAwesomeIcon icon={["far", "chevron-down"]} />
      </h1>
      {isActive && (
        <div className="counters">
          {propKeys.map(k => (
            <Counter key={k} title={t(`search.${k}`)} propKey={k} value={values[k]} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Counters;
