import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Filters from "../components/filters/Filters";
import FilterSlider from "../components/filters/FilterSlider";
import FilterStar from "../components/filters/FilterStar";
import { IFilterHotel } from "../interfaces/IFilter";
import { IStoreState } from "../interfaces/IState";
import { changeFilterHotels, resetFilterHotels, toggleFilterHotels } from "../store/actions/filterActions";
import { getFiltersHotel, getFiltersHotelDefaults } from "../store/selectors/filterSelectors";
import { getFlightAirlines } from "../store/selectors/flightSelectors";
import { uniqid } from "../utils/helpers";

interface IStateProps {
  defaults: IFilterHotel;
  filters: IFilterHotel;
  uniqueAirlines: {
    [key: string]: string;
  };
}

interface IDispatchProps {
  actions: {
    changeFilter(filters: IFilterHotel): void;
    resetFilter(): void;
    toggleFilter(): void;
  };
}

interface IProps extends IStateProps, IDispatchProps, WithTranslation {}

function FilterHotel({ defaults, filters, actions, t }: IProps) {
  const filterHandler = (newFilter: { [key: string]: any }) => {
    actions.changeFilter({
      ...filters,
      ...newFilter
    });
  };

  return filters.isActive ? (
    <Filters key={uniqid()} onReset={actions.resetFilter} onClose={actions.toggleFilter}>
      <FilterSlider
        label={t("filter.flight.price")}
        suffix="₺"
        propKey="price"
        step={50}
        values={filters.price}
        limits={defaults.price}
        onChange={filterHandler}
      />

      <FilterStar
        label={t("filter.hotel.star")}
        stars={filters.stars}
        propKey="stars"
        onChange={filterHandler}
      />
    </Filters>
  ) : null;
}

const mapStateToProps = (state: IStoreState): IStateProps => ({
  defaults: getFiltersHotelDefaults(state),
  filters: getFiltersHotel(state),
  uniqueAirlines: getFlightAirlines(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      changeFilter: changeFilterHotels,
      resetFilter: resetFilterHotels,
      toggleFilter: toggleFilterHotels
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterHotel));
