import i18n from "i18next";
import get from "lodash-es/get";
import { IBookingOptions } from "../../interfaces/IBooking";
import { IFlight } from "../../interfaces/IFlight";
import { IRoom } from "../../interfaces/IHotel";
import { IProvisionFlightResponse, IProvisionQuestion } from "../../interfaces/IProvision";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  FLIGHT_UPDATE,
  HOTEL_ROOM_UPDATE,
  PROVISION_ANSWER_QUESTION,
  PROVISION_HAS_QUESTIONS,
  PROVISION_PENDING,
  PROVISION_REJECTED,
  PROVISION_FULFILLED,
  UI_CHECKOUT_AMOUNT_DATA_UPDATE,
  UI_CHECKOUT_FLIGHT_DATA_UPDATE,
  UI_CHECKOUT_HOTEL_DATA_UPDATE
} from "../../utils/constants";
import { getCustomerParsedForSearchParam } from "../selectors/customerSelectors";
import { getFlightAlternatives } from "../selectors/flightSelectors";
import { getRoomProvisions } from "../selectors/provisionSelectors";

export const provisionFlight: AC = (flight: IFlight) => async (dispatch, getState) => {
  const state = getState();
  const custguid = getCustomerParsedForSearchParam(state);
  const alternatives = getFlightAlternatives(state);
  dispatch({
    type: PROVISION_PENDING
  });

  const payload: IBookingOptions = {
    createTicket: false,
    custguid,
    isCompany: true,
    isRepeat: false,
    offerid: flight.OfferId,
    type: "flight"
  };
  try {
    const res = await api().post<IProvisionFlightResponse>("provision", payload);
    if (res.data.status) {
      const altCode = alternatives[res.data.flight.OfferId]?.BrandCode;

      const finalResult: IProvisionFlightResponse = {
        ...res.data,
        flight: {
          ...res.data.flight,
          airitinprice: altCode
            ? res.data.flight.alternatives?.find(x => x.BrandCode === altCode)!
            : res.data.flight.airitinprice
        }
      };
      dispatch({
        payload: {
          offerId: flight.OfferId,
          provision: finalResult
        },
        type: PROVISION_HAS_QUESTIONS
      });
      dispatch({
        payload: res.data.flight,
        type: FLIGHT_UPDATE
      });
      dispatch({
        payload: res.data.flight,
        type: UI_CHECKOUT_FLIGHT_DATA_UPDATE
      });
      dispatch({
        type: UI_CHECKOUT_AMOUNT_DATA_UPDATE
      });
    } else {
      dispatch({
        payload: flight.OfferId,
        type: PROVISION_REJECTED
      });
      throw res;
    }
  } catch (e) {
    dispatch({
      payload: {
        message: get(e, "res.data.errors[0].error", i18n.t("provision.genericError")),
        offerId: flight.OfferId
      },
      type: PROVISION_REJECTED
    });
    Logger.flashError(e, {
      fallbackMessage: i18n.t("provision.genericError")
    });
    throw e;
  }
};

export const provisionRoom: AC = (item: IRoom | IFlight, force: boolean = false) => async (
  dispatch,
  getState
) => {
  if (!force) {
    const provision = getRoomProvisions(getState()).find(p => p.offerId === item.OfferId);

    if (provision) {
      return;
    }
  }
  const custguid = getCustomerParsedForSearchParam(getState());

  dispatch({
    type: PROVISION_PENDING
  });

  const payload: IBookingOptions = {
    createTicket: false,
    custguid,
    isCompany: true,
    isRepeat: false,
    offerid: item.OfferId,
    type: "hotel"
  };

  try {
    const res = await api().post("provision", payload);
    if (res.data.status) {
      dispatch({
        payload: { ...res.data, offerId: item.OfferId },
        type: PROVISION_FULFILLED
      });
      dispatch({
        payload: {
          offerId: item.OfferId,
          provision: res.data
        },
        type: PROVISION_HAS_QUESTIONS
      });
      dispatch({
        payload: res.data.hotel,
        type: HOTEL_ROOM_UPDATE
      });
      if (force) {
        dispatch({
          payload: res.data.hotel,
          type: UI_CHECKOUT_HOTEL_DATA_UPDATE
        });
        dispatch({
          type: UI_CHECKOUT_AMOUNT_DATA_UPDATE
        });
      }
    } else {
      dispatch({
        payload: item.OfferId,
        type: PROVISION_REJECTED
      });
      throw res;
    }
  } catch (e) {
    dispatch({
      payload: {
        message: get(e, "res.data.errors[0].error", i18n.t("provision.genericError")),
        offerId: item.OfferId
      },
      type: PROVISION_REJECTED
    });
    Logger.flashError(e, {
      fallbackMessage: i18n.t("provision.genericError")
    });
    throw e;
  }
};

export const answerProvisionQuestion = (question: IProvisionQuestion, offerId: string) => ({
  payload: { question, offerId },
  type: PROVISION_ANSWER_QUESTION
});
