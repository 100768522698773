import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import set from "lodash-es/set";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import { updateReport } from "../../store/actions/accountingActions";
import { getUser } from "../../store/selectors/userSelectors";
import { currencyParser } from "../../utils/helpers";
import { Busy } from "../snippets/Busy";

interface IProps {
  item: IAccountingPayment;
  onClose(): void;
}

const AccountSupplierInvoice: FC<IProps> = ({ item, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState(false);

  const [invoiceRequest, setInvoiceRequest] = useState({
    description: "",
    invoiceDate: "",
    invoiceNo: "",
    offer_guid: item.offer_guid,
    price: "",
    supplier: item.source,
    totalAmount: "",
    type: "NEW",
    unit: item.unit,
    unitType: item.unitType,
    user_guid: user.userguid,
    vatAmount: "",
    vatRatio: item.vat_ratio
  });
  const [response, setResponse] = useState<any>({});

  const inputHandler = useCallback(
    (field: keyof typeof invoiceRequest) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setInvoiceRequest(state => ({
        ...state,
        [field]: value
      }));
    },
    [invoiceRequest]
  );

  const numericHandler = useCallback(
    (field: string) => ({ floatValue }: NumberFormatValues) => {
      setInvoiceRequest(state => set(state, field, floatValue));
    },
    []
  );

  const submit = useCallback(async () => {
    setIsBusy(true);
    try {
      const { data } = await api().post("costinvoice", invoiceRequest);
      setResponse(data);
      dispatch(updateReport(item.paymentKey, data.invoiceNo));
    } catch (e) {}
    setIsBusy(false);
  }, [dispatch, invoiceRequest, item.paymentKey]);

  return (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>{t("accounting.modal.title")}</h1>
      </Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        <table className="table fixed-layout invoice-table fs-std">
          <tbody>
            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "25%" }}>
                        {t("accounting.modal.supplier")}:
                        <br />
                        <input
                          type="text"
                          placeholder="Supplier"
                          value={invoiceRequest.supplier}
                          onChange={inputHandler("supplier")}
                        />
                      </td>
                      <td style={{ width: "25%" }}>
                        {t("accounting.modal.invoiceDate")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.invoiceDate}
                          onValueChange={numericHandler("invoiceDate")}
                          format="####.##.##"
                          placeholder="YYYY.MM.DD"
                          mask={["Y", "Y", "Y", "Y", "M", "M", "D", "D"]}
                        />
                      </td>
                      <td style={{ width: "50%" }}>
                        {t("accounting.modal.referenceNo")}:
                        <br />
                        <input
                          type="text"
                          placeholder=""
                          value={invoiceRequest.invoiceNo}
                          onChange={inputHandler("invoiceNo")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>
                        {t("accounting.modal.price")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.price}
                          onValueChange={numericHandler("price")}
                          thousandSeparator
                          prefix={currencyParser(item.currency)}
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        {t("accounting.modal.count")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.unit}
                          onValueChange={numericHandler("unit")}
                          format="##"
                        />
                      </td>
                      <td style={{ width: "16%" }}>
                        {t("accounting.modal.type")}:
                        <br />
                        <input
                          type="text"
                          placeholder=""
                          value={invoiceRequest.unitType}
                          onChange={inputHandler("unitType")}
                        />
                      </td>
                      <td style={{ width: "49%" }}>
                        {t("accounting.modal.description")}:
                        <br />
                        <input
                          type="text"
                          placeholder=""
                          value={invoiceRequest.description}
                          onChange={inputHandler("description")}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <tr>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "35%" }} />
                      <td style={{ width: "20%" }}>
                        {t("accounting.modal.vatRatio")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.vatRatio}
                          onValueChange={numericHandler("vatRatio")}
                          format="%##"
                        />
                      </td>
                      <td style={{ width: "20%" }}>
                        {t("accounting.modal.vatAmount")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.vatAmount}
                          onValueChange={numericHandler("vatAmount")}
                          thousandSeparator
                          prefix={currencyParser(item.currency)}
                        />
                      </td>
                      <td style={{ width: "25%" }}>
                        {t("generic.total")}:
                        <br />
                        <NumberFormat
                          value={invoiceRequest.totalAmount}
                          onValueChange={numericHandler("totalAmount")}
                          thousandSeparator
                          prefix={currencyParser(item.currency)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        {/*<AccountingPriceList items={[item]} />*/}
      </Modal.Body>
      <Modal.Footer>
        {response.status && (
          <>
            <p>{t("accounting.invoiceSubmit")}</p>
            <p>Reference No: {response.invoiceNo}</p>
          </>
        )}
        <button disabled={response && response.status} className="submit modal-right-button" onClick={submit}>
          <FontAwesomeIcon icon={["far", "check-circle"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default AccountSupplierInvoice;
