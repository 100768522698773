import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React, { FC, useCallback, useMemo, useState } from "react";

interface IProps {
  propKey: string;
  label: string;
  labelPosition?: "top" | "left";
  values: {
    min: number;
    max: number;
  };
  limits: {
    min: number;
    max: number;
  };
  suffix?: string;
  step: number;

  onChange(state: { [key: string]: any }): void;
}

const FilterSlider: FC<IProps> = ({
  propKey,
  label,
  labelPosition = "left",
  values,
  limits,
  suffix = "",
  step,
  onChange
}) => {
  const [markers, setMarkers] = useState(values);

  const changeHandler = useCallback((newValues: number[]) => {
    setMarkers({ max: newValues[1], min: newValues[0] });
  }, []);

  const sliderHandler = useCallback(
    (newValues: number[]) => {
      onChange({
        [propKey]: {
          max: newValues[1],
          min: newValues[0]
        }
      });
    },
    [onChange, propKey]
  );

  const marks = useMemo(
    () => ({
      [limits.min]: limits.min + suffix,
      [limits.max]: limits.max + suffix,
      [markers.min]: markers.min + suffix,
      [markers.max]: markers.max + suffix
    }),
    [limits.max, limits.min, markers.max, markers.min, suffix]
  );

  return (
    <div className={`filter${labelPosition === "top" ? " label-top" : ""}`}>
      <span className="filter-title">{label}</span>
      <Range
        defaultValue={[values.min, values.max]}
        onChange={changeHandler}
        onAfterChange={sliderHandler}
        step={step}
        min={limits.min}
        max={limits.max}
        marks={marks}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        dotStyle={dotStyle}
        railStyle={railStyle}
      />
    </div>
  );
};

export default FilterSlider;

const trackStyle = [
  {
    backgroundColor: "#414042"
  }
];

const railStyle = {
  backgroundColor: "#c7c7c7"
};

const dotStyle = {
  backgroundColor: "#c7c7c7",
  border: 0
};

const handleStyle = [
  {
    backgroundColor: "#414042",
    border: 0,
    height: 16,
    marginTop: -6,
    width: 16
  },
  {
    backgroundColor: "#414042",
    border: 0,
    height: 16,
    marginTop: -6,
    width: 16
  }
];
