import classNames from "classnames";
import { utc } from "moment";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import tooltip from "react-tooltip";
import Loading from "../../../components/Loading";
import CancellationPolicyModal from "../../../components/modals/CancellationPolicyModal";
import { IRoom } from "../../../interfaces/IHotel";
import { provisionRoom } from "../../../store/actions/provisionActions";
import { getRoomProvisions } from "../../../store/selectors/provisionSelectors";
import { pwc } from "../../../utils/helpers";
import { HotelContext } from "../index";

interface IProps {
  availability: [boolean, string];
  room: IRoom;
}

const DetailsRoomBoard: FC<IProps> = ({ availability: [isAvailable, message], room }) => {
  const { t } = useTranslation();
  const { selectedRoom, setSelectedRoom } = useContext(HotelContext);
  const dispatch = useDispatch();
  const provisions = useSelector(getRoomProvisions);

  const [isBusy, setIsBusy] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    tooltip.rebuild();
  }, [message]);

  const provision = useMemo(() => {
    return provisions.find(p => p.offerId === room.OfferId);
  }, [provisions, room.OfferId]);

  const cancellationPolicies = useMemo(() => {
    return provision ? provision.cancellationPolicies : room.cancellationPolicies;
  }, [room.cancellationPolicies, provision]);

  // const fetchProvision = useCallback(async () => {
  //   try {
  //     await dispatch(provisionRoom);
  //   } catch {
  //     setSelectedRoom(undefined);
  //   }
  // }, [dispatch, setSelectedRoom]);

  const cx = classNames("details-room-board", {
    "not-available": !isAvailable,
    selected: selectedRoom && room.OfferId === selectedRoom.OfferId
  });

  const selectHandler = useCallback(async () => {
    if (!isAvailable) {
      return;
    }
    setIsBusy(true);
    room.action = "hotel";
    try {
      await dispatch(provisionRoom(room));
      setSelectedRoom(room);
    } catch {
      setSelectedRoom(undefined);
    }
    setIsBusy(false);
  }, [dispatch, isAvailable, room, setSelectedRoom]);

  return (
    <div className={cx} data-tip={message ? message : undefined}>
      <CancellationPolicyModal
        active={isActive}
        policies={cancellationPolicies}
        provision={provision}
        onClose={() => setIsActive(false)}
      />
      <div className="info" onClick={selectHandler}>
        <p className="board-type">{room.Board}</p>
        <p className="price-tag-board">{pwc(room.AmountAfterTax, room.HotelCurrency, false)}</p>
      </div>
      {isBusy ? (
        <div className="cancellation">
          <Loading />
        </div>
      ) : (
        <div className="cancellation" onClick={() => setIsActive(true)}>
          {provision ? (
            <p>{t("generic.moreInfo")}...</p>
          ) : room.cancellationPolicies ? (
            <p>
              {t("detailHotel.room.cancel")}
              <br />
              {t("generic.before")}{" "}
              <span className="bold">{utc(room.cancellationPolicies[0].date).format("D MMM")}</span>
            </p>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default DetailsRoomBoard;
