import set from "lodash-es/set";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Busy } from "../../../components/snippets/Busy";
import { ICustomer } from "../../../interfaces/ICustomer";
import FormRow from "../../../libs/Form/FormRow";
import { api } from "../../../services/api";
import Logger from "../../../services/Logger";
import { defaultCustomerPolicy } from "../../../utils/defaults";
import PolicyBudgets from "./snippets/PolicyBudgets";
import PolicyFlights from "./snippets/PolicyFlights";
import PolicyHotels from "./snippets/PolicyHotels";

interface IProps {
  customer: ICustomer;
}

const PolicyCustomerEdit: FC<IProps> = ({ customer }) => {
  const { t } = useTranslation();
  const [policy, setPolicy] = useState(defaultCustomerPolicy);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setPolicy(customer.policy!);
  }, [customer]);

  const updatePolicy = (payload: { data: any; key: string }) => {
    const rootPolicy = { ...policy };
    set(rootPolicy, payload.key, payload.data);
    setPolicy(rootPolicy);
  };

  const submit = async () => {
    setIsBusy(true);
    const cust: ICustomer = { ...customer, policy };
    try {
      await api().post("customer", cust);
      Logger.flash("Successfully saved");
    } catch (e) {
      Logger.flashError(e);
      return;
    }

    setIsBusy(false);
  };

  return (
    <>
      {isBusy && <Busy />}
      <h1 className="policy-title">
        {customer.firstname} {customer.lastname}
      </h1>
      <div className="form">
        <PolicyFlights onChange={updatePolicy} policy={policy && policy.flights} />
        <PolicyHotels onChange={updatePolicy} policy={policy && policy.hotels} />
        <PolicyBudgets onChange={updatePolicy} policy={policy && policy.budget} />
        <FormRow className="j-end">
          <button className="agreement-submit" onClick={submit}>
            {t("generic.button.submit")}
          </button>
        </FormRow>
      </div>
    </>
  );
};

export default PolicyCustomerEdit;
