import { AnyAction, combineReducers, Reducer } from "redux";
import { IStoreState } from "../../interfaces/IState";
import { USER_LOGOUT_FULFILLED } from "../../utils/constants";
import accountingReducer from "./accountingReducer";
import activityReducer from "./activityReducer";
import basketReducer from "./basketReducer";
import bookingReducer from "./bookingReducer";
import carReducer from "./carReducer";
import customerReducer from "./customerReducer";
import filterReducer from "./filterReducer";
import flightReducer from "./flightReducer";
import hotelReducer from "./hotelReducer";
import logReducer from "./logReducer";
import notificationReducer from "./notificationReducer";
import pnrReducer from "./pnrReducer";
import programReducer from "./programReducer";
import provisionReducer from "./provisionReducer";
import searchReducer from "./searchReducer";
import selectedItemsReducer from "./selectedItemsReducer";
import serviceReducer from "./serviceReducer";
import transferReducer from "./transferReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";

const appReducer: Reducer<IStoreState> = combineReducers({
  accounting: accountingReducer,
  activities: activityReducer,
  basket: basketReducer,
  bookings: bookingReducer,
  carState: carReducer,
  customers: customerReducer,
  filters: filterReducer,
  flightState: flightReducer,
  hotels: hotelReducer,
  logs: logReducer,
  notifications: notificationReducer,
  pnrs: pnrReducer,
  programs: programReducer,
  provisions: provisionReducer,
  search: searchReducer,
  selectedItems: selectedItemsReducer,
  services: serviceReducer,
  ui: uiReducer,
  user: userReducer,
  transferState: transferReducer
});

export default (state: any, action: AnyAction) => {
  if (action.type === USER_LOGOUT_FULFILLED) {
    state = undefined;
  }
  return appReducer(state, action);
};
