import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InlineSearch from "../components/InlineSearch";
import LoadingWrapper from "../components/LoadingWrapper";
import OfferApprove from "../components/OfferApprove";
import OfferApproveDetailed from "../components/OfferApproveDetailed";
import useDataFetch from "../hooks/useDataFetch";
import { IOfferApprove } from "../interfaces/IApprove";
import DefaultLayout from "../layouts/DefaultLayout";
import cx from "classnames";
import { ErrorBoundary } from "../components/ErrorBoundary";

const ApproveModulePage: FC = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<string>();
  const { data: offers, loading, error } = useDataFetch<IOfferApprove[]>({
    dataKey: "offers",
    method: "post",
    payload: {
      userguid: "2143bf87-4770-444f-8de8-2c1b76ae7463"
    },
    state: [],
    url: "offerlist"
  });
  const [tabIndex, setTabIndex] = useState(1);

  const tabHandler = useCallback(
    (index: 1 | 2) => () => {
      setTabIndex(index);
    },
    []
  );

  // const [offers, loading, error] = useAxios<IOfferApprove[]>({
  //   dataKey: "offers",
  //   initialState: [],
  //   method: "post",
  //   payload: {
  //     userguid: "2143bf87-4770-444f-8de8-2c1b76ae7463"
  //   },
  //   url: "offerlist"
  // });

  const selectedOffer = useMemo(() => {
    if (offers) {
      return offers.find(order => order.pnr === selected);
    }
  }, [offers, selected]);

  const fetchDetails = useCallback((pnr: string) => {
    setSelected(pnr);
    setTabIndex(2);
  }, []);

  const searchHandler = useCallback((filteredItems: any) => {
    // this.setState({filteredItems});
  }, []);

  return (
    <DefaultLayout>
      <div className="container main flex j-center approve-module">
        <div
          className={cx("box-container full-height list", {
            "is-mobile-hidden": tabIndex !== 1
          })}
        >
          <div className="title flex j-between a-center is-relative c-primary">
            <ErrorBoundary>
            <span className="pnr-header-icon">
              <FontAwesomeIcon icon={["fas", "clipboard-list"]} />
            </span>
            </ErrorBoundary>
            <h1>{t("offer.title")}</h1>
            <div className="fill-space" />
            <InlineSearch
              onSearch={searchHandler}
              items={offers ?? []}
              searchableKeys={["custlist.lastname", "custlist.firstname"]}
            />
          </div>
          <LoadingWrapper loading={loading} error={error}>
            <div className="pnr-list order-list">
              {offers?.map(offer => (
                <OfferApprove selected={selected} onClick={fetchDetails} order={offer} />
              ))}
            </div>
          </LoadingWrapper>
        </div>
        <div className={`box-container full-height details${tabIndex !== 2 ? " is-mobile-hidden" : ""}`}>
          {selectedOffer && <OfferApproveDetailed offer={selectedOffer!} />}
        </div>
      </div>
      <div className="is-mobile-only mobile-tabs">
        <button
          className={cx({
            "is-active": tabIndex === 1
          })}
          onClick={tabHandler(1)}
        >
          Offer List
        </button>
        <button
          className={cx({
            "is-active": tabIndex === 2
          })}
          onClick={tabHandler(2)}
        >
          Details
        </button>
      </div>
    </DefaultLayout>
  );
};

export default ApproveModulePage;
