import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { IAgreementRoom } from "../../../interfaces/IHotel";
import Thumbnail from "../../../libs/Image/Thumbnail";
import { currencyParser } from "../../../utils/helpers";
import { ErrorMessage, FastField, useFormikContext } from "formik";

interface IProps {
  index: number;
  onRemove(): void;
}

const AgreementRoomField: FC<IProps> = ({ index, onRemove }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{ rooms: IAgreementRoom[] }>();

  return (
    <div className="form-item">
      {values.rooms[index].fresh && (
        <button className="remove-form-item" onClick={onRemove}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
      )}
      <div className="form-group horizontal half">
        <label className="agreement-input-label">
          <span className="label">{t("report.startDate")}:</span>
          <NumberFormat
            name={`rooms.${index}.start_date`}
            defaultValue={moment(values.rooms[index].start_date, "YYYYMMDD").format("DDMMYYYY")}
            onValueChange={({ value }) =>
              setFieldValue(`rooms.${index}.start_date`, moment(value, "DDMMYYYY").format("YYYYMMDD"))
            }
            format="##.##.####"
            placeholder="DD.MM.YYYY"
            mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
          />
          <ErrorMessage name={`rooms.${index}.start_date`} component="span" className="input-helper danger" />
        </label>
        <label className="agreement-input-label">
          <span className="label">{t("report.endDate")}:</span>
          <NumberFormat
            defaultValue={moment(values.rooms[index].end_date, "YYYYMMDD").format("DDMMYYYY")}
            onValueChange={({ value }) =>
              setFieldValue(`rooms.${index}.end_date`, moment(value, "DDMMYYYY").format("YYYYMMDD"))
            }
            format="##.##.####"
            placeholder="DD.MM.YYYY"
            mask={["D", "D", "M", "M", "Y", "Y", "Y", "Y"]}
          />
          <ErrorMessage name={`rooms.${index}.end_date`} component="span" className="input-helper danger" />
        </label>
      </div>
      <div className="form-group horizontal">
        <label className="agreement-input-label">
          <span className="label">{t("admin.room.title")}:</span>
          <FastField name={`rooms.${index}.room_title`} />
          <ErrorMessage name={`rooms.${index}.room_title`} component="span" className="input-helper danger" />
        </label>
        <label className="agreement-input-label">
          <span className="label">{t("admin.room.type")}:</span>
          <FastField name={`rooms.${index}.room_type`} as="select">
            <option value="">Not Specified</option>
            <option value="STANDARD">Standard</option>
            <option value="BUSINESS">Business</option>
            <option value="SUPERIOR">Superior</option>
            <option value="SUITE">Suite</option>
            <option value="APARTMENT">Apartment</option>
          </FastField>
          <ErrorMessage name={`rooms.${index}.room_type`} component="span" className="input-helper danger" />
        </label>
        <label className="agreement-input-label">
          <span className="label">{t("admin.room.bed")}:</span>
          <FastField name={`rooms.${index}.bed_type`} as="select">
            <option value="">Not Specified</option>
            <option value="SINGLE">Single</option>
            <option value="DOUBLE">Double</option>
            <option value="TRIPLE">Triple</option>
            <option value="KING">King</option>
          </FastField>
          <ErrorMessage name={`rooms.${index}.bed_type`} component="span" className="input-helper danger" />
        </label>
        <label className="agreement-input-label">
          <span className="label">{t("admin.room.extraBed")}:</span>
          <FastField name={`rooms.${index}.extra_bed`} type="number" />
          <ErrorMessage name={`rooms.${index}.extra_bed`} component="span" className="input-helper danger" />
        </label>
        <label className="agreement-input-label">
          <span className="label">{t("admin.room.board")}:</span>
          <FastField name={`rooms.${index}.board`} as="select">
            <option value="">Not Specified</option>
            <option value="ROOM ONLY">Room Only</option>
            <option value="HALF BOARD">Half Board</option>
            <option value="BREAKFAST">Breakfast</option>
            <option value="CONTINENTAL BREAKFAST">Continental Breakfast</option>
            <option value="BED&BREAKFAST">Bed & Breakfast</option>
            <option value="ALL INCLUSIVE">All Inclusive</option>
          </FastField>
          <ErrorMessage name={`rooms.${index}.board`} component="span" className="input-helper danger" />
        </label>
      </div>
      <div className="form-group horizontal">
        <div className="form-sub-group">
          <div className="form-group">
            <label className="agreement-input-label">
              <span className="label">{t("generic.description")}:</span>
              <FastField name={`rooms.${index}.room_desc`} as="textarea" />
              <ErrorMessage
                name={`rooms.${index}.room_desc`}
                component="span"
                className="input-helper danger"
              />
            </label>
          </div>
          <div className="form-group horizontal">
            <label className="agreement-input-label">
              <span className="label">{t("generic.currency")}:</span>
              <FastField name={`rooms.${index}.currency`} as="select">
                <option value="TRY">₺</option>
                <option value="EUR">€</option>
                <option value="USD">$</option>
                <option value="RUB">₽</option>
                <option value="CHF">₣</option>
              </FastField>
              <ErrorMessage
                name={`rooms.${index}.currency`}
                component="span"
                className="input-helper danger"
              />
            </label>
            <label className="agreement-input-label">
              <span className="label">{t("generic.vat")}:</span>
              <FastField name={`rooms.${index}.vat`} as="select">
                <option value="0">0</option>
                <option value="8">8</option>
                <option value="18">18</option>
              </FastField>
              <ErrorMessage name={`rooms.${index}.vat`} component="span" className="input-helper danger" />
            </label>
            <label className="agreement-input-label">
              <span className="label">{t("generic.vatIncluded")}:</span>
              <FastField name={`rooms.${index}.isvatincluded`} as="select">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </FastField>
              <ErrorMessage
                name={`rooms.${index}.isvatincluded`}
                component="span"
                className="input-helper danger"
              />
            </label>
          </div>
          <div className="form-group horizontal">
            <label className="agreement-input-label">
              <span className="label">{t("generic.price")}:</span>
              <NumberFormat
                defaultValue={values.rooms[index].price}
                onValueChange={({ value }) => setFieldValue(`rooms.${index}.price`, value)}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                allowNegative={false}
                prefix={currencyParser(values.rooms[index].currency)}
              />
              <ErrorMessage
                name={`rooms.${index}.currency`}
                component="span"
                className="input-helper danger"
              />
            </label>
            <label className="agreement-input-label">
              <span className="label">{t("admin.room.refundable")}:</span>
              <FastField name={`rooms.${index}.isrefundable`} as="select">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </FastField>
              <ErrorMessage
                name={`rooms.${index}.isrefundable`}
                component="span"
                className="input-helper danger"
              />
            </label>
            <label className="agreement-input-label">
              <span className="label">{t("generic.status")}:</span>
              <FastField name={`rooms.${index}.status_id`} as="select">
                <option value="1">Active</option>
                <option value="0">Passive</option>
              </FastField>
              <ErrorMessage
                name={`rooms.${index}.status_id`}
                component="span"
                className="input-helper danger"
              />
            </label>
          </div>
        </div>
        <div className="form-sub-group">
          <label className="agreement-input-label">
            <span className="label">{t("generic.image")}:</span>
            <FastField name={`rooms.${index}.image_url`} />
            <Thumbnail layout="background" height={200} image={values.rooms[index].image_url} />
            <ErrorMessage
              name={`rooms.${index}.image_url`}
              component="span"
              className="input-helper danger"
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default AgreementRoomField;
