import isArray from "lodash-es/isArray";
import { createSelector } from "reselect";
import { IBasketFlight } from "../../interfaces/IBasket";
import { IStoreState } from "../../interfaces/IState";
import { timeParser, uniqid } from "../../utils/helpers";

export const getBasketState = (state: IStoreState) => state.basket;

export const getBasket = createSelector(getBasketState, basketState => basketState.basket);

export const getBasketCustomers = createSelector(getBasket, basket => basket.custguid);

export const getBasketCustomersGuids = createSelector(getBasketCustomers, customers =>
  customers.map(customer => ({ custguid: customer.custguid }))
);

export const getBasketItems = createSelector(getBasket, basket => {
  const activities = isArray(basket.activities) ? basket.activities : [];
  const cars = isArray(basket.cars) ? basket.cars : [];
  const flights = isArray(basket.flights) ? basket.flights : [];
  const hotels = isArray(basket.hotels) ? basket.hotels : [];
  const visas = isArray(basket.visas) ? basket.visas : [];

  const dests = flights.map((flight, index) =>
    flight.airitin.origdests!.reduce((output: any, dest: any, key) => {
      dest = {
        ...dest,
        Currency: flight.Currency,
        OfferId: flight.OfferId,
        bundle: !!key,
        price: flight.airitinprice
      };
      output[index * 2 + "_" + dest.DepDate + "_" + timeParser(dest.DepTime) + "_flight_" + uniqid()] = dest;
      return output;
    }, {})
  ) as IBasketFlight[];

  const orderedHotels = hotels.reduce((output: any, hotel) => {
    hotel.action = "hotel";
    output[
      "1_" + hotel.CheckInDate.replace(/-/g, "") + "_" + hotel.CheckInTime + "_hotel_" + uniqid()
    ] = hotel;
    return output;
  }, {});

  const flightsHotels = Object.assign(orderedHotels, ...dests);

  const orderedFlightsHotels = {};

  Object.keys(flightsHotels)
    .sort()
    .forEach(k => {
      orderedFlightsHotels[k] = flightsHotels[k];
    });

  return { activities, cars, orderedFlightsHotels, others: [...visas] };
});

export const getBasketPayableItems = createSelector(getBasket, basket => {
  return [
    ...basket.flights.map(flight => {
      flight.action = "flight";
      return flight;
    }),
    ...basket.hotels.map(hotel => {
      hotel.action = "hotel";
      return hotel;
    }),
    ...(basket.visas ? basket.visas : []),
    ...(basket.transfers ? basket.transfers : [])
  ];
});

// export const getCustomersValidForBasket = (state: IStoreState) => true;
