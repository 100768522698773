import ModalBody from "./ModalBody";
import ModalContainer from "./ModalContainer";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";

const Modal = {
  Body: ModalBody,
  Container: ModalContainer,
  Footer: ModalFooter,
  Header: ModalHeader
};

export default Modal;
