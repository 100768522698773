import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import useClickDetect from "../../hooks/useClickDetect";
import Dates from "../Dates";
import { SearchContext } from "./Search";
import { CustomDateSinglePicker } from "../date/CustomDateSinglePicker";

interface IProps {
  index?: number;
}

const SingleDatePicker: FC<IProps> = ({ index = 0 }) => {
  const $container = useClickDetect(() => setIsActive(false));
  const [search, setSearch] = useContext(SearchContext);
  const [isActive, setIsActive] = useState(false);

  const route = useMemo(() => {
    return search.routes[index];
  }, [search.routes, index]);

  const toggleDatePicker = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const selectDate = (date: string) => {
    setSearch({
      fdate: date,
      rdate: "",
      routes: search.routes.map((r, i) =>
        i === index
          ? {
              ...search.routes[i],
              date,
              rdate: "",
            }
          : r
      ),
    });
  };

  return (
    <div className="trip-dates" ref={$container}>
      <div className="from" onClick={toggleDatePicker}>
        {route.date ? (
          <Dates className="search-date" dates={[{ date: route.date }]} />
        ) : (
          <FontAwesomeIcon icon={["fas", "calendar-alt"]} />
        )}
      </div>
      {isActive && <CustomDateSinglePicker date={moment().format("YYYY-MM-DD")} onChange={selectDate} />}
    </div>
  );
};

export default SingleDatePicker;
