import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import map from "lodash-es/map";
import React from "react";
import tooltip from "react-tooltip";
import { IRoom } from "../../interfaces/IHotel";
import { findAirportByCode } from "../../utils/airportFunctions";
import { dayMonth } from "../../utils/helpers";

interface IProps {
  details?: any;
  hotel: IRoom;
}

export const PaymentHotel = ({ details, hotel }: IProps) => {
  tooltip.rebuild();

  return (
    <div className="hotel-item flex a-center">
      <div className="item-icon">
        <FontAwesomeIcon icon={["fas", "home"]} />
      </div>
      <div className="contents full-width">
        <div className="content top flex a-center">
          <p className="fs-med">{dayMonth(hotel.CheckInDate)}</p>
          <span className="middot" />
          <span>
            {hotel.HotelName && (
              <p className="fs-med" data-tip={hotel.HotelName}>
                {hotel.HotelName.substring(0, 12) + "..."}
              </p>
            )}
            <p className="fs-std">{findAirportByCode(hotel.CityCode).state}</p>
            <p className="fs-std">
              {hotel.RoomType}
              {hotel.Board && `/ ${hotel.Board}`}
            </p>
          </span>
          <span className="middot" />
          <p className="fs-med">{dayMonth(hotel.CheckOutDate)}</p>
        </div>
        <div>
          {details &&
            map(
              details.details,
              (v, k) =>
                v && (
                  <span className="fs-small" key={k}>
                    {k},{" "}
                  </span>
                )
            )}
          <span className="fs-small">{details && details.occupancy}</span>
        </div>
        <hr />
      </div>
    </div>
  );
};
