import React, { Children, FC, useCallback, useMemo, useState } from "react";
import Controller from "./Controller";
import Page from "./Page";

const FullPage: FC = ({ children }) => {
  const [index, setIndex] = useState(0);

  const pageCount = useMemo(() => Children.count(children), [children]);

  const nextPage = useCallback(() => {
    setIndex(x => (x === pageCount - 1 ? 0 : x + 1));
  }, [pageCount]);

  const prevPage = useCallback(() => {
    setIndex(x => (x ? x - 1 : pageCount - 1));
  }, [pageCount]);

  const goToPage = useCallback((idx: number) => {
    setIndex(idx);
  }, []);

  return (
    <div className="fullpage">
      {React.Children.toArray(children).map((node, i) => (
        <Page key={i} active={i === index}>
          {node}
        </Page>
      ))}
      <Controller count={pageCount} current={index} next={nextPage} prev={prevPage} goTo={goToPage} />
    </div>
  );
};

export default FullPage;
