import React from "react";
import { IDestination } from "../interfaces/IFlight";
import { findAirportByCode } from "../utils/airportFunctions";
import { timeParser } from "../utils/helpers";
import Dates from "./Dates";
import FlightLogo from "./snippets/FlightLogo";

export default function PackageFlight({ dest }: { dest: IDestination }) {
  return (
    <div className="flight flex a-center j-between">
      <Dates
        dates={[
          {
            date: dest.DepDate,
            direction: dest.DirectionId
          }
        ]}
      />
      <p className="fs-hug">
        <FlightLogo code={dest.MarketingAirline} className="text-image" />
      </p>
      <p className="fs-med c-primary">
        {dest.MarketingAirline} {dest.FlightNumber}
      </p>
      <span className="middot c-primary" />
      <p className="fs-med c-primary">{timeParser(dest.DepTime)}</p>
      <span className="middot c-primary" />
      <p className="fs-med c-primary">{findAirportByCode(dest.DepartureAirport).state}</p>
    </div>
  );
}
