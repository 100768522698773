import React, { FC } from "react";

interface IProps {
  active: boolean;
}

const Page: FC<IProps> = ({ active, children }: any) => {
  return <div className={`page${active ? " current" : ""}`}>{children}</div>;
};

export default Page;
