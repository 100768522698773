import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { carsMakeSearch } from "../../../store/actions/carActions";
import { getCarsSearch, getCarsStatuses } from "../../../store/selectors/carSelectors";
import FormikDatePicker from "./FormikDatePicker";
import { FormikLocation } from "./FormikLocation";
import FormikTimeField from "./FormikTimeField";

type Props = {};

const CarSearch: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search = useSelector(getCarsSearch);
  const statuses = useSelector(getCarsStatuses);

  const isSearching = statuses.some(s => s.fetching);

  return (
    <div>
      <Formik
        initialValues={search}
        onSubmit={values => {
          dispatch(carsMakeSearch(values));
        }}
      >
        <Form className="formik-form">
          <div className="formik-input-group fill">
            <div className="formik-input-group-content">
              <FormikLocation direction="departure" placeholder={t("car.placeholder.from")} />
            </div>
          </div>
          <div className="formik-input-group fill">
            <div className="formik-input-group-content">
              <FormikLocation placeholder={t("car.placeholder.to")} />
            </div>
          </div>
          <div className="formik-input-group w-175">
            <div className="formik-input-group-content">
              <FormikDatePicker name="fdate" placeholder={t("car.placeholder.fromDate")} />
              <FormikTimeField name="ftime" />
            </div>
          </div>
          <div className="formik-input-group w-175">
            <div className="formik-input-group-content">
              <FormikDatePicker name="rdate" placeholder={t("car.placeholder.toDate")} />
              <FormikTimeField name="rtime" />
            </div>
          </div>
          <div className="formik-input-group">
            <div className="formik-input-group-content">
              <button type="submit" className="submit" disabled={isSearching}>
                {isSearching ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default CarSearch;
