const routeMap = {
  "pnr-module": "/pnr-module",
  "basket-module": "/basket-module",
  "offers-approve": "/approve-module",
  "admin-page": "/admin",
  "agency-dashboard": "/agency",
  "agency-sales": "/agency/sales",
  "agency-reconciliation": "/agency/reconciliation",
  "agency-commission": "/agency/commission",
  "agency-profitloss": "/agency/profit-loss",
  "agency-accounting": "/agency/accounting",
  "company-dashboard": "/company",
  "company-compliance": "/company/compliance",
  "company-expense-budget": "/company/expense-budget",
  "company-staff-expenses": "/company/staff-expenses",
  "company-reconciliation": "/company/reconciliation",
  "program-module": "/program-module"
};

function findRoute(code: string) {
  if (routeMap[code]) {
    return routeMap[code];
  }
  return "/";
}

export default findRoute;
