import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPnrActivity, IPnrService } from "../../interfaces/IPnr";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { pnrTKey } from "../../utils/helpers";
import ActivityCard from "../ActivityCard";
import { Busy } from "../snippets/Busy";
import PnrHistory from "./PnrHistory";
import PnrPayment from "./PnrPayment";
import PnrPeople from "./PnrPeople";

interface IProps {
  pnr: IPnrActivity;

  reload(pnr: IPnrActivity): any;
  updatePnr(pnr: string, status: string): void;
}

const PnrActivity: FC<IProps> = ({ pnr, reload, updatePnr }) => {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);

  const reloadHandler = useCallback(() => {
    setIsBusy(true);
    reload(pnr)
      .then(() => setIsBusy(false))
      .catch(() => setIsBusy(false));
  }, [pnr, reload]);

  const pnrAction = useCallback(
    (service: IPnrService) => {
      setIsBusy(true);
      const log = new Logger(t(`pnr.${pnrTKey(service.uri)}Pending`));
      api()
        .post(service.uri, pnr)
        .then(() => {
          log.success(t(`pnr.${pnrTKey(service.uri)}Success`));
          updatePnr(pnr.pnr, pnr.status);
          reloadHandler();
          setIsBusy(false);
        })
        .catch(e => {
          log.error(e);
          setIsBusy(false);
        });
    },
    [pnr, reloadHandler, t, updatePnr]
  );

  return (
    <div className="pnr-list-scroll-area">
      {isBusy && <Busy />}
      <div className="pnr-details details">
        <div className="pnr-details-footer">
          {pnr.services &&
            pnr.services.map(s => (
              <button key={s.title} onClick={() => pnrAction(s)}>
                <span>{t(`button.${pnrTKey(s.uri)}`)}</span>
              </button>
            ))}
          <span className="fill-space" />
        </div>
        <div className="flex j-between">
          <div>
            <span className="c-primary">
              <span className="text fs-med">PNR:</span> <span className="text bold">{pnr.pnr}</span>
            </span>
          </div>
          <div>
            <p className="c-primary pnr-status">
              <span className="text fs-med">{t("generic.status").toUpperCase()}:</span>
              <span className={pnr.status ? pnr.status.toLowerCase() : ""}>&nbsp;{pnr.status}</span>
            </p>
            <p className="c-primary fs-std align-right">
              <span>{t("generic.source")}:</span>
              {pnr.source}
            </p>
          </div>
        </div>
        <div className="activity-container">
          <ActivityCard activity={pnr.offer} />
        </div>
      </div>
      <PnrPayment payments={pnr.payment} />
      {pnr.offer && <PnrPeople pnr={pnr as IPnrActivity} />}
      <PnrHistory pnr={pnr} />
    </div>
  );
};

export default PnrActivity;
