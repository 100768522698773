import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import { utc } from "moment";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IPnrHistoryLogs } from "../../interfaces/IPnr";
import { api } from "../../services/api";

interface IProps {
  pnr: any;
}

const PnrHistory: FC<IProps> = ({ pnr }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<IPnrHistoryLogs[]>([]);

  useEffect(() => {
    api()
      .post("pnrhistory", pnr)
      .then(res => {
        setData(get(res, "data.logs", []));
      });
  }, [pnr]);

  return (
    <div className="pnr-details">
      <div className="title flex j-between a-center is-relative">
        <span className="pnr-header-icon c-primary">
          <FontAwesomeIcon icon={["fal", "file"]} />
        </span>
        <h1 className="text fs-std fill-space c-primary bold">{t("generic.history")}</h1>
      </div>
      <div className="pnr-customer-list">
        <table className="table table-ticket table-history is-fullwidth">
          <tbody>
            {data &&
              data.map((log, index) => (
                <tr key={index}>
                  <td className="c-primary fs-std">{utc(log.logdate).format("DD.MM.Y hh:mm:ss")}</td>
                  <td className="c-primary fs-std">{log.action}</td>
                  <td className="c-primary fs-std">{log.username}</td>
                  <td className="c-primary fs-std">{log.app}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PnrHistory;
