import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IOfferApprove } from "../interfaces/IApprove";
import { pwc } from "../utils/helpers";
import cx from "classnames";

interface IProps {
  order: IOfferApprove;
  selected?: string;
  onClick(guid: string): void;
}

const OfferApprove: FC<IProps> = ({ order, selected, onClick }) => {
  const { t } = useTranslation();

  const clickHandler = useCallback(() => {
    onClick(order.pnr);
  }, [order, onClick]);

  return (
    <div className="list-item-normalizer">
      <div
        onClick={clickHandler}
        className={cx("flex d-column pnr pointer", {
          selected: selected === order.pnr
        })}
      >
        <div
          className={cx("flex j-between pnr-title", {
            [order.status.toLowerCase()]: order.status
          })}
        >
          <p>
            <span className="text fs-med">PNR:</span> <span className="bold">{order.pnr}</span>
          </p>
          <p className="pnr-status">
            <span className={`bold ${order.status.toLowerCase()}`} style={{ marginRight: 5 }}>
              {order.status}
            </span>
          </p>
        </div>
        <div className="pnr-content flex j-between gap-10 fill-space">
          {/*{this.props.order.custlist && this.props.order.custlist.length > 0 && <div className="passenger-list">*/}
          {/*<h1 className="fs-med c-primary bold">Passengers:</h1>*/}
          {/*{this.props.order.custlist.map((cust, index) =>*/}
          {/*<p className="fs-std c-primary" key={index}>{cust.firstname + ' ' + cust.lastname}</p>)}*/}
          {/*</div>}*/}
          <div className="passenger-list">
            <h1 className="fs-med c-primary bold">{t("pnr.passengers")}:</h1>
            {order.cust &&
              order.cust.map((cust, index) => (
                <p className="fs-med c-primary" key={index}>
                  {cust.firstname + " " + cust.lastname}
                </p>
              ))}
          </div>

          <div className="corp-seal">
            {/*<img src={`/images/company/${this.props.order.user.companycode}.png`} alt=""/>*/}
          </div>
        </div>
        <div className="pnr-footer flex j-between">
          <div className="customer-limit">
            {order.cust[0].policy && (
              <p>Limit: {pwc(order.cust[0].policy.budget.approved.limit, order.currency)}</p>
            )}
          </div>
          <div className="offer-totals">
            {order.offers.flights && order.offers.flights[0] && order.offers.flights[0].airitinprice && (
              <div className="offer-total">
                <span className="offer-icon c-primary">
                  <FontAwesomeIcon icon={["fas", "plane"]} />
                </span>
                <p className="c-primary bold">
                  {pwc(
                    order.offers.flights[0].airitinprice.TotalFare,
                    order.offers.flights[0].Currency,
                    false
                  )}
                </p>
              </div>
            )}
            {order.offers.hotels && order.offers.hotels[0] && (
              <div className="offer-total">
                <span className="offer-icon c-primary">
                  <FontAwesomeIcon icon={["fas", "home"]} />
                </span>
                <p className="c-primary bold">
                  {pwc(order.offers.hotels[0].AmountAfterTax, order.offers.hotels[0].HotelCurrency, false)}
                </p>
              </div>
            )}
            {order.offers.flights &&
              order.offers.flights[0] &&
              order.offers.flights[0].airitinprice &&
              order.offers.hotels &&
              order.offers.hotels[0] && (
                <div className="offer-total">
                  <p className="c-primary  offer-icon">
                    {pwc(
                      order.offers.hotels[0].AmountAfterTax + order.offers.flights[0].airitinprice.TotalFare,
                      order.offers.hotels[0].HotelCurrency,
                      false
                    )}
                  </p>
                  <span className="c-primary total-title">Total</span>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferApprove;
