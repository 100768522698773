import classNames from "classnames";
import React, { FC } from "react";
import DateRibbon from "./snippets/DateRibbon";

interface IProps {
  className?: string;
  dates: Array<{
    date: any;
    direction?: string;
  }>;
  vertical?: boolean;
}

const Dates: FC<IProps> = ({ className, dates, vertical }) => {
  const cx = classNames(className, "dates flex j-between", {
    "d-column": vertical
  });

  return (
    <div className={cx}>
      {dates.map((date, key) => (
        <DateRibbon {...date} key={key} />
      ))}
    </div>
  );
};

export default Dates;
