import orderBy from "lodash-es/orderBy";
import flatten from "lodash-es/flatten";
import flattenDeep from "lodash-es/flattenDeep";
import get from "lodash-es/get";
import includes from "lodash-es/includes";
import keys from "lodash-es/keys";
import max from "lodash-es/max";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import map from "lodash-es/map";
import inRange from "lodash-es/inRange";
import uniq from "lodash-es/uniq";
import values from "lodash-es/values";
import countBy from "lodash-es/countBy";
import intersection from "lodash-es/intersection";
import last from "lodash-es/last";
import { createSelector } from "reselect";
import { IFlight } from "../../interfaces/IFlight";
import { IStoreState } from "../../interfaces/IState";
import { elapsedTimeInMinutes, toAbsoluteInteger } from "../../utils/helpers";
import { getActivityIsSearching } from "./activitySelectors";
import { getActiveCustomersExcludedAirlines } from "./customerSelectors";
import {
  getFlightFilterAirlines,
  getFlightFilterAirports,
  getFlightFilterDepartureTime,
  getFlightFilterPrices,
  getFlightFilterReturnTime,
  getFlightFilterSearch,
  getFlightFilterStops,
  getFlightSorter
} from "./filterSelectors";
import { getHotelIsSearching } from "./hotelSelectors";

export const getFlightState = (state: IStoreState) => state.flightState;

export const getFlightVendors = createSelector(getFlightState, state => state.vendors);

export const getFlightStatuses = createSelector(getFlightVendors, vendors => {
  return map(vendors, (vendor, vendorKey) => {
    const status = { ...vendor.status };
    status.serviceKey = vendorKey;
    return status;
  });
});

export const getFlightAlternatives = createSelector(getFlightState, state => state.alternatives);

export const getFlightIsSearching = createSelector(getFlightStatuses, statuses =>
  statuses.some(s => s.fetching)
);

export const getSearching = createSelector(
  [getHotelIsSearching, getFlightIsSearching, getActivityIsSearching],
  (hotelSearching, flightSearching, activitySearching) =>
    hotelSearching || flightSearching || activitySearching
);

export const getFlightSearchDone = createSelector(getFlightStatuses, statuses =>
  statuses.every(s => !s.fetching && s.fetched)
);

export const getFlights = createSelector(getFlightVendors, vendors => {
  return orderBy(
    flatten(
      map(vendors, vendor =>
        vendor.flights.map(flight => {
          if (flight.alternatives && flight.alternatives.some(a => !a.TotalFare)) {
            flight.alternatives = undefined;
          }
          const directions = flight.airitin.origdests.map(d => d.DirectionId);
          const returnFlight = flight.airitin.origdests.find(d => d.DirectionId === last(directions))!;

          flight.price = flight.airitinprice.TotalFare;
          flight.departTime = toAbsoluteInteger(flight.airitin.origdests[0].DepTime);
          flight.arriveTime = toAbsoluteInteger(returnFlight.ArrTime);
          flight.ElapsedTime = elapsedTimeInMinutes(flight.airitin.origdests);
          return flight;
        })
      )
    ),
    "airitinprice.TotalFare",
    "asc"
  ) as IFlight[];
});

export const getFlightsResultEmpty = createSelector(
  getFlights,
  getFlightStatuses,
  (flights, statuses) => !flights.length && statuses.every(status => status.fetched)
);

export const getFetchingFlightsDone = createSelector(getFlightStatuses, statuses =>
  statuses.every(s => s.fetched)
);

export const getFlightAirlines = createSelector(getFlights, flights =>
  flattenDeep(flights.map(flight => flight.airitin.origdests || [])).reduce((airlines, dest: any) => {
    airlines[dest.OperatingAirline] = dest.OperatingAirlineName || dest.OperatingAirline;
    return airlines;
  }, {})
);

export const getDirectFlightsCount = createSelector(
  getFlights,
  flights => flights.filter(f => f.isConnected).length
);

export const getHasDirectFlights = createSelector(
  getDirectFlightsCount,
  getFlightSearchDone,
  (flightCount, flightSearchDone) => {
    return flightSearchDone ? !!flightCount : undefined;
  }
);

export const getFlightStops = createSelector(getFlights, flights =>
  uniq(flatten(flights.map(flight => values(countBy(flight.airitin.origdests, "DirectionId")))))
);

export const getFlightsMinMaxPrice = createSelector(getFlights, flights => {
  const minPrice = flights[0]?.airitinprice?.TotalFare ?? 0;
  const maxPrice = flights[flights.length - 1]?.airitinprice?.TotalFare ?? 99999;
  return [minPrice - (minPrice % 100), 100 - (maxPrice % 100) + maxPrice];
});

export const getIncludedFlights = createSelector(
  getFlights,
  getActiveCustomersExcludedAirlines,
  (flights, airlines) => {
    // console.log("flights", flights);
    return airlines
      ? flights.filter(
          flight => !flight.airitin.origdests!.some(dest => airlines.includes(dest.MarketingAirline))
        )
      : flights;
  }
);

export const getFilteredByAirlinesFlights = createSelector(
  getIncludedFlights,
  getFlightFilterAirlines,
  (flights, airlines) => {
    // console.log("included", flights);
    return flights.filter(flight =>
      flight.airitin.origdests?.some(
        dest => !airlines.length || includes(airlines, dest.MarketingAirlineName)
      )
    );
  }
);

export const getFilteredByPriceFlights = createSelector(
  getFilteredByAirlinesFlights,
  getFlightFilterPrices,
  (flights, prices) => {
    // console.log("airlines", flights);
    return flights.filter(flight => inRange(flight.airitinprice.TotalFare, prices[0], prices[1]));
  }
);

export const getFilteredByStopsFlights = createSelector(
  getFilteredByPriceFlights,
  getFlightFilterStops,
  (flights, stops) => {
    // console.log("price", flights);
    return flights.filter(flight => {
      return (
        !stops.length || stops.includes(max(values(countBy(flight.airitin.origdests, "DirectionId"))) ?? -1)
      );
    });
  }
);

export const getFilteredByAirportsFlights = createSelector(
  getFilteredByStopsFlights,
  getFlightFilterAirports,
  (flights, airports) => {
    // console.log("stops", flights);
    return flights.filter(
      flight =>
        !airports.length ||
        intersection(
          airports,
          flatten(flight.airitin.origdests?.map(dest => [dest.ArrivalAirport, dest.DepartureAirport]))
        ).length
    );
  }
);

export const getFilteredByFlightTimeFlights = createSelector(
  getFilteredByAirportsFlights,
  getFlightFilterDepartureTime,
  getFlightFilterReturnTime,
  (flights, departureTime, returnTime) => {
    // console.log("airports", flights);
    return flights.filter(f => {
      const directions = f.airitin.origdests.map(d => d.DirectionId);
      const depTime = toAbsoluteInteger(f.airitin.origdests[0].DepTime);
      const returnDepTime = toAbsoluteInteger(
        f.airitin.origdests.find(d => d.DirectionId === last(directions))!.DepTime
      );
      return (
        inRange(depTime, departureTime[0] * 10000, departureTime[1] * 10000) &&
        inRange(returnDepTime, returnTime[0] * 10000, returnTime[1] * 10000)
      );
    });
  }
);

export const getFilteredBySearchFlights = createSelector(
  getFilteredByFlightTimeFlights,
  getFlightFilterSearch,
  (items, filters) => {
    // console.log("time", items);
    const { input, type, searchable } = filters;
    if (input.length < 3) {
      return items;
    }

    const keywords = input.split(" ");

    return items.filter(item =>
      keywords.every(word =>
        keys(item).some(param => {
          const key = param as keyof typeof item;
          if (item[key] && includes(searchable, param)) {
            return type === "startsWith"
              ? startsWith(toLower(`${item[key]}`), toLower(word))
              : includes(toLower(`${item[key]}`), toLower(word));
          }
          return false;
        })
      )
    );
  }
);

export const getSortedFlights = createSelector(
  getFilteredBySearchFlights,
  getFlightSorter,
  (flights, sorter) => {
    // console.log("search", flights);
    return orderBy(
      flights,
      flight => {
        return flight[sorter as keyof IFlight];
      },
      sorter[1] ? "asc" : "desc"
    );
  }
);

export const getFinalFlights = createSelector(getSortedFlights, flights => {
  // console.log("sorted", flights);
  return [flights.filter(flight => !flight.returning), flights.filter(flight => flight.returning)];
});

export const getCheapestFlight = createSelector(getFinalFlights, getFetchingFlightsDone, (flights, done) => {
  return done ? flights[0][0] : null;
});

export const getCheapestTKFlight = createSelector(
  getFinalFlights,
  getFetchingFlightsDone,
  (flights, done) => {
    return done ? flights[0].find(f => get(f, "airitin.origdests[0].OperatingAirline", "") === "TK") : null;
  }
);
