import React from "react";
import FlightSvg from "../svg/FlightSvg";
import HotelSvg from "../svg/HotelSvg";

interface IBasketLineComponent {
  direction: string;
  finishColor: string;
  startColor: string;
  type: string;
}

export default function BasketLine({ direction, finishColor, startColor, type }: IBasketLineComponent) {
  return (
    <div className="basket-line">
      <span className="pointer start" style={{ backgroundColor: startColor }} />
      <span className="pointer finish" style={{ backgroundColor: finishColor }} />
      <span
        className="line"
        style={{ background: `linear-gradient(to bottom, ${startColor} 0, ${finishColor} 100%)` }}
      />
      {type === "hotel" ? (
        <HotelSvg className="line-icon" transit={false} index={direction} />
      ) : (
        <FlightSvg className="line-icon" transit={false} index={direction} />
      )}
    </div>
  );
}
