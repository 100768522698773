import { Reducer } from "redux";
import { ICustomer } from "../../interfaces/ICustomer";
import { ICustomerState } from "../../interfaces/IState";
import Logger from "../../services/Logger";
import {
  CUSTOMER_ADJUST_ROOMS,
  CUSTOMER_ASSIGN_ROOM,
  CUSTOMER_CLEAR,
  CUSTOMER_FETCH_FULFILLED,
  CUSTOMER_FETCH_PENDING,
  CUSTOMER_FETCH_REJECTED,
  CUSTOMER_INITIALIZE,
  CUSTOMER_REMOVE_FROM_ROOMS,
  CUSTOMER_SCREEN_TOGGLE,
  CUSTOMER_SET_FROM_LIST,
  CUSTOMER_TOGGLE
} from "../../utils/constants";

const initialState: ICustomerState = {
  customers: [],
  isActive: false,
  rooms: [],
  status: {
    error: false,
    fetched: false,
    fetching: false
  }
};

const customerReducer: Reducer<ICustomerState> = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_INITIALIZE:
      return {
        ...state,
        customers: JSON.parse(localStorage.getItem("customers") || "[]")
      };
    case CUSTOMER_SCREEN_TOGGLE:
      return {
        ...state,
        isActive: !state.isActive
      };
    case CUSTOMER_TOGGLE:
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (customer.cust_guid === action.payload.cust_guid) {
            customer.selected = !action.payload.selected;
          }
          return customer;
        })
      };
    case CUSTOMER_SET_FROM_LIST:
      return {
        ...state,
        customers: action.payload
      };
    case CUSTOMER_FETCH_PENDING:
      Logger.flash("Müşteri Listesi Alınıyor...");
      return {
        ...state,
        status: {
          error: false,
          fetched: false,
          fetching: true
        }
      };
    case CUSTOMER_FETCH_FULFILLED:
      return {
        ...state,
        customers: action.payload,
        status: {
          error: false,
          fetched: true,
          fetching: false
        }
      };
    case CUSTOMER_FETCH_REJECTED: {
      Logger.flashError("Müşteri listeleri alınırken bir hata oluştu");
      return {
        ...state,
        status: {
          error: true,
          fetched: false,
          fetching: false,
          message: "bir hata olustu"
        }
      };
    }
    case CUSTOMER_ADJUST_ROOMS: {
      return {
        ...state,
        rooms: action.payload
      };
    }
    case CUSTOMER_REMOVE_FROM_ROOMS: {
      return removeRoom(state, action.payload);
    }
    case CUSTOMER_ASSIGN_ROOM: {
      return assignRoom(state, action.payload);
    }
    case CUSTOMER_CLEAR:
      return {
        ...state,
        customers: []
      };
    default:
      return state;
  }
};

export default customerReducer;

function removeRoom(state: ICustomerState, payload: any): ICustomerState {
  const newState = { ...state };
  newState.rooms = newState.rooms.map(room => {
    room.custGuids = room.custGuids.filter(g => g !== payload);
    room.hasAdult = hasAdult(newState.customers, room.custGuids);
    if (!room.hasAdult) {
      room.custGuids = [];
    }
    return room;
  });
  return newState;
}

function assignRoom(state: ICustomerState, payload: any): ICustomerState {
  const newState = { ...state };

  newState.rooms = newState.rooms.map(room => {
    room.custGuids = room.custGuids.filter(g => g !== payload.custGuid);
    if (room.no === payload.roomNo) {
      room.custGuids.push(payload.custGuid);
    }
    room.hasAdult = hasAdult(newState.customers, room.custGuids);
    if (!room.hasAdult) {
      room.custGuids = [];
    }
    return room;
  });
  return newState;
}

function hasAdult(customers: ICustomer[], custGuids: string[]): boolean {
  return customers.some(cust => custGuids.includes(cust.cust_guid) && cust.passenger_type === "ADT");
}
