import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ITotalExpenses } from "../../pages/Company/CompanyCompliancePage";
import { pwc } from "../../utils/helpers";

interface IProps {
  data: ITotalExpenses;
}

const SpentTable: FC<IProps> = ({ data }) => {
  const { t } = useTranslation();

  const count = useMemo(() => {
    if (data) {
      return Object.keys(data.percentages).length;
    }
    return 1;
  }, [data]);
  return data ? (
    <div className="spent-table">
      <h1>{t("corpReport.expense.table.title")}</h1>
      <div className="total">
        <FontAwesomeIcon color="#ed7d31" icon={["fas", "bookmark"]} />
        <p>{pwc(data.totals.total_amount, "TRY")}</p>
      </div>
      <table>
        <tbody>
          <tr>
            <td className="spent-title">{t("corpReport.expense.table.budget")}</td>
            <td className="spent-value">
              <span>56%</span>
            </td>
          </tr>
          <tr>
            <td className="spent-title">{t("corpReport.expense.table.nightTitle")}</td>
            <td className="spent-value">
              <span>{data.totals.nights}</span> {t("corpReport.expense.table.night")}
            </td>
          </tr>
          <tr>
            <td className="spent-title">{t("corpReport.expense.table.average")}</td>
            <td className="spent-value">
              <span>{pwc(data.totals.avg_price / count, "TRY")}</span>
            </td>
          </tr>
          <tr>
            <td className="spent-title">{t("corpReport.expense.table.compliance")}</td>
            <td className="spent-value">
              <span>{data.totals.nights_improper}</span> {t("corpReport.expense.table.reserve")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default SpentTable;
