import moment from "moment";
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../../components/search/Search";
import { Busy } from "../../../components/snippets/Busy";
import { IProgram } from "../../../interfaces/IProgram";
import Modal from "../../../libs/Modal";
import { fetchPrograms } from "../../../store/actions/programAction";
import { makeSearchFromProgram } from "../../../store/actions/searchActions";
import { getPrograms } from "../../../store/selectors/programSelector";

type Props = {
  onClose(): void;
};

const ProgramModal: FC<Props> = ({ onClose }) => {
  const setSearch = useContext(SearchContext)[1];
  const dispatch = useDispatch();
  const programs = useSelector(getPrograms);

  const [isBusy, setIsBusy] = useState(false);

  const fetchHandler = useCallback(async () => {
    setIsBusy(true);
    await dispatch(fetchPrograms());
    setIsBusy(false);
  }, [dispatch]);

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  const searchHandler = useCallback(
    (program: IProgram) => () => {
      setSearch(program.search_params);
      dispatch(makeSearchFromProgram(program.search_params));
    },
    [dispatch, setSearch]
  );
  return (
    <Modal.Container onClose={onClose}>
      <Modal.Header>Programs</Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        <table className="program-list-table">
          <tbody>
            {programs.map(program => (
              <tr key={program.travel_guid}>
                <td className="fs-std">{program.title}</td>
                <td className="fs-std">
                  {moment(program.search_params.fdate).format("D MMM YYYY")}
                  &nbsp;-&nbsp;
                  {moment(program.search_params.rdate).format("D MMM YYYY")}
                </td>
                <td>
                  <button className="program-search-button" onClick={searchHandler(program)}>
                    Search
                  </button>
                  <a
                    className="program-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"/travelprogram?key=" + program.travel_guid}
                  >
                    Program
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal.Container>
  );
};

export default ProgramModal;
