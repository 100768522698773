import React, { useEffect, useMemo, useRef, useState } from "react";
import { GoogleMap, Marker, Polyline, withGoogleMap, withScriptjs } from "react-google-maps";
import { connect, MapStateToProps } from "react-redux";
import { compose, withProps } from "recompose";
import { IAirport } from "../interfaces/IAirport";
import { IHotel } from "../interfaces/IHotel";
import { IStoreState } from "../interfaces/IState";
import { getCurrentRoute } from "../store/selectors/searchSelectors";
import { GOOGLE_MAP_API } from "../utils/constants";

interface IStateProps {
  marker?: IHotel;
  routes?: {
    from?: IAirport;
    to?: IAirport;
  };
}

interface IProps extends IStateProps {}

function Map({ marker, routes }: IProps) {
  const map = useRef<GoogleMap>(null);
  const [center] = useState({ lat: 39.256, lng: 34.976 });

  const polylineOptions = {
    geodesic: true,
    icons: [
      {
        icon: {
          path: 1,
          scale: 2,
          strokeColor: "#FF00FF"
        },
        offset: "0%"
      }
    ],
    strokeColor: "#FF00FF",
    strokeOpacity: 0.75,
    strokeWeight: 2
  };

  const routeBounds = useMemo(() => {
    if (routes && routes.from && routes.to) {
      return [
        {
          lat: parseFloat(routes.from.lat || ""),
          lng: parseFloat(routes.from.lon || "")
        },
        {
          lat: parseFloat(routes.to.lat || ""),
          lng: parseFloat(routes.to.lon || "")
        }
      ];
    }
    return [
      {
        lat: 36.0,
        lng: 26.0
      },
      {
        lat: 45.0,
        lng: 42.0
      }
    ];
  }, [routes]);

  useEffect(() => {
    // let count = 0;
    // this.setState((state: any) => ({
    //   ...state,
    //   interval: setInterval(() => {
    //     count = (count + 1) % 200;
    //     this.setState((prevState: any) => {
    //       prevState.polylineOptions.icons[0].offset = (count / 2) + '%';
    //       return prevState;
    //     });
    //   }, 50)
    // }));
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (map.current) {
      const bounds = new (window as any).google.maps.LatLngBounds();
      for (const route of routeBounds) {
        bounds.extend(route);
      }
      // @ts-ignore
      map.current.fitBounds(bounds, { top: 250, bottom: 75, left: 0, right: 0 });
    }
  }, [routeBounds]);

  const findCoordsByCode = useMemo(() => {
    if (routes && routes.from && routes.to) {
      return [
        {
          lat: parseFloat(routes.from.lat || ""),
          lng: parseFloat(routes.from.lon || "")
        },
        {
          lat: parseFloat(routes.to.lat || ""),
          lng: parseFloat(routes.to.lon || "")
        }
      ];
    }
  }, [routes]);

  return (
    <GoogleMap ref={map} defaultZoom={6} center={center}>
      {marker && (
        <Marker
          position={{
            lat: parseFloat(marker.Latitude),
            lng: parseFloat(marker.Longitude)
          }}
        />
      )}
      {routes && <Polyline options={polylineOptions} path={findCoordsByCode} />}
    </GoogleMap>
  );
}

const mapStateToProps: MapStateToProps<IStateProps, void, IStoreState> = state => ({
  // marker: state.basket.selectedItems.hotel,
  routes: getCurrentRoute(state)
});

export default compose<IStateProps, IStateProps>(
  withProps({
    containerElement: <div style={{ position: "fixed", top: 0, right: 0, left: 0, bottom: 0, zIndex: -1 }} />,
    googleMapURL: GOOGLE_MAP_API,
    loadingElement: <div style={{ height: "100%" }} />,
    mapElement: <div style={{ height: "100%" }} />
  }),
  withScriptjs,
  withGoogleMap,
  connect(mapStateToProps)
)(Map);
