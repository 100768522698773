import isFunction from "lodash-es/isFunction";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { hasParent } from "../utils/helpers";

type ClickDetectProps =
  | {
      excludes?: string[];
      inner?(): void;
      outer?(): void;
    }
  | func;

type func = () => void;

const useClickDetect = (args: ClickDetectProps) => {
  const $el = useRef<any>(null);
  const { excludes = [], outer, inner } = useMemo(() => {
    if (isFunction(args)) {
      return {
        outer: args
      };
    } else {
      return args;
    }
  }, [args]);

  const excluded = useCallback(
    (el: any) => {
      return excludes.some(x => hasParent(el, x));
    },
    [excludes]
  );

  const handleClick = useCallback(
    (e: any) => {
      if ($el.current && $el.current.contains(e.target)) {
        if (inner) {
          inner();
        }
      } else if ($el.current && !excluded($el.current)) {
        if (outer) {
          outer();
        }
      }
    },
    [excluded, inner, outer]
  );

  useEffect(() => {
    document.addEventListener("mouseup", handleClick);
    return () => document.addEventListener("mouseup", handleClick);
  }, [handleClick]);

  return $el;
};

export default useClickDetect;
