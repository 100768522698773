import { TFunction } from "i18next";
import toString from "lodash-es/toString";
import React, { FC, memo, useCallback, useMemo } from "react";
// @ts-ignore
import { Cell } from "react-sticky-table";

type Props = {
  field: any;
  report: any;
  t: TFunction;
  openCostInvoice(report: any): void;
};

const AccountTableCell: FC<Props> = ({ field, report, openCostInvoice, t }) => {
  const cellValue = useMemo(() => {
    return toString(report[field]);
  }, [field, report]);

  const hasCostInvoiceAction = useMemo(() => {
    return field === "costinvoice_ref" && cellValue === "";
  }, [field, cellValue]);

  const clickHandler = useCallback(() => {
    openCostInvoice(report);
  }, [openCostInvoice, report]);

  return (
    <Cell className={hasCostInvoiceAction ? "inline-report-action" : ""}>
      {hasCostInvoiceAction ? (
        <button className="supplier" onClick={clickHandler}>
          {t("accounting.setCostInvoice")}
        </button>
      ) : (
        cellValue
      )}
    </Cell>
  );
};

export default memo(AccountTableCell, (prev, next) => prev.report[prev.field] === next.report[next.field]);
