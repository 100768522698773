import React, { FC } from "react";

interface IProps {
  color: string;
}

const RibbonSvgMedia: FC<IProps> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.63 30.3">
    <title>Ribbon Svg</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill={color}
          className="svg-ribbon-color"
          d="M24.19.18a2.22,2.22,0,0,1,1.44,2.06V28.06a2.28,2.28,0,0,1-2.32,2.22,2.43,2.43,0,0,1-1.66-.64l-8.83-8.49L4,29.64a2.45,2.45,0,0,1-1.66.66,2.24,2.24,0,0,1-.88-.18A2.22,2.22,0,0,1,0,28.06V2.24A2.22,2.22,0,0,1,1.44.18,2.25,2.25,0,0,1,2.32,0h21A2.23,2.23,0,0,1,24.19.18Z"
        />
      </g>
    </g>
  </svg>
);

export default RibbonSvgMedia;
