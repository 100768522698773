import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pick from "lodash-es/pick";
import set from "lodash-es/set";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import useObjectState from "../../hooks/useObjectState";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import { Busy } from "../snippets/Busy";
import AccountingPriceList from "./AccountingPriceList";

interface IProps {
  items: IAccountingPayment[];
  onClose(): void;
}

const AccountCollection: FC<IProps> = ({ items, onClose }) => {
  const { t } = useTranslation();

  const [collection, setCollection] = useObjectState({
    collectionDate: "",
    collectionName: "",
    collectionReference: "",
    collectionType: "CASH",
    type: "NEW"
  });

  const [isBusy, setIsBusy] = useState(false);

  const [response, setResponse] = useState<{ [key: string]: any }>({});

  const inputHandler = (field: string, value: any) => {
    setCollection(set({ ...collection }, field, value));
  };

  const submit = async () => {
    setIsBusy(true);
    const requestPayload: any = {};
    requestPayload.items = items.map(i => pick(i, ["paymentKey", "offer_guid", "action", "source"]));
    requestPayload.isCollected = true;
    requestPayload.collection = collection;
    try {
      const { data } = await api().post("paymentcollection", requestPayload);
      setResponse(data);
    } catch {}
    setIsBusy(false);
  };

  return (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>{t("accounting.form.action")}</h1>
      </Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        <p className="fs-underline" style={{ marginBottom: 5 }}>
          {t("generic.customer")}: {items[0].customer}
        </p>
        <AccountingPriceList items={items} />
        <div className="form">
          <div className="form-group horizontal">
            <label className="fs-std">
              {t("accounting.form.name")}
              <input
                type="text"
                value={collection.collectionName}
                onChange={e => inputHandler("collectionName", e.target.value)}
              />
            </label>
            <label className="fs-std">
              {t("accounting.form.date")}
              <NumberFormat
                value={collection.collectionDate}
                onValueChange={({ value }) => inputHandler("collectionDate", value)}
                format="####.##.##"
                placeholder="YYYY.MM.DD"
                mask={["Y", "Y", "Y", "Y", "M", "M", "D", "D"]}
              />
            </label>
          </div>
          <div className="form-group horizontal">
            <label className="fs-std">
              {t("accounting.form.type")}
              <select
                value={collection.collectionType}
                onChange={e => inputHandler("collectionType", e.target.value)}
              >
                <option value="CASH">CASH</option>
                <option value="VPOS">VPOS</option>
              </select>
            </label>
            <label className="fs-std">
              {t("accounting.form.reference")}
              <input
                type="text"
                value={collection.collectionReference}
                onChange={e => inputHandler("collectionReference", e.target.value)}
              />
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {response.status && (
          <>
            <p>{t("accounting.collectSubmit")}</p>
            <p>
              {t("accounting.referenceNo")}: {response.collection.collectionKey}
            </p>
          </>
        )}
        <button className="submit modal-right-button" onClick={submit}>
          <FontAwesomeIcon icon={["far", "check-circle"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default AccountCollection;
