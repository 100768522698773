import debounce from "lodash-es/debounce";
import React, { ChangeEvent, FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterPnrInput } from "../store/actions/filterActions";
import { getFiltersPnrInput } from "../store/selectors/filterSelectors";

const InlineSearchPnr: FC = () => {
  const dispatch = useDispatch();

  const input = useSelector(getFiltersPnrInput);

  const { t } = useTranslation();
  const debouncer = debounce((value: string) => {
    dispatch(changeFilterPnrInput(value));
  }, 0);

  const inputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debouncer(e.currentTarget.value);
    },
    [debouncer]
  );

  return (
    <div className="inline-search">
      <input defaultValue={input} placeholder={t("search.inline")} type="text" onChange={inputHandler} />
    </div>
  );
};

export default InlineSearchPnr;
