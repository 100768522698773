import flatten from "lodash-es/flatten";
import includes from "lodash-es/includes";
import isBoolean from "lodash-es/isBoolean";
import keys from "lodash-es/keys";
import max from "lodash-es/max";
import min from "lodash-es/min";
import startsWith from "lodash-es/startsWith";
import toLower from "lodash-es/toLower";
import toNumber from "lodash-es/toNumber";
import toSafeInteger from "lodash-es/toSafeInteger";
import { createSelector } from "reselect";
import { IHotel, IHotelInfo } from "../../interfaces/IHotel";
import { IStoreState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import { defaultStars } from "../../utils/defaults";
import { getActiveCustomersExcludedHotels, getCustomersMinimumHotelLimit } from "./customerSelectors";
import { getFilterHotelSortBy, getFiltersHotel } from "./filterSelectors";
import { getLastSearch } from "./searchSelectors";

export function getHotelStates(state: IStoreState) {
  return state.hotels;
}

export const getHotels = createSelector(
  getHotelStates,
  hotelStates =>
    flatten(hotelStates.map(hotelState => hotelState.hotels)).map(h => {
      h.Rating = h.Rating ? parseInt(h.Rating.toString()) : 0;
      return h;
    }) as IHotel[]
);

export const getHotelsDetails = createSelector(getHotelStates, hotelStates =>
  hotelStates.reduce((acc, hotelState) => {
    acc = { ...acc, ...hotelState.details };
    return acc;
  }, {} as Dictionary<IHotelInfo>)
);

export const getHotelStatuses = createSelector(getHotelStates, hotels =>
  hotels.map(hotelState => {
    const status = hotelState.status;
    status.serviceKey = hotelState.key;
    return status;
  })
);

export const getHotelActiveStatuses = createSelector(getHotelStatuses, statuses =>
  statuses.filter(s => s.active)
);

export const getHotelIsSearching = createSelector(getHotelActiveStatuses, statuses =>
  statuses.some(s => s.fetching)
);

export const getHotelSearchDone = createSelector(getHotelActiveStatuses, statuses => {
  return !!statuses.length && statuses.every(s => !s.fetching && s.fetched);
});

export const getExcludedByBudgetLimitHotels = createSelector(
  getHotels,
  getCustomersMinimumHotelLimit,
  (hotels, limit) => {
    return hotels.map(hotel => {
      hotel.rooms = hotel.rooms.filter(
        room => (isBoolean(room.isNonRefundable) && !room.isNonRefundable) || limit >= room.AmountAfterTax
      );
      return hotel;
    });
  }
);

export const getSortedHotels = createSelector(
  getExcludedByBudgetLimitHotels,
  getFilterHotelSortBy,
  (hotels, filterSortBy) => {
    switch (filterSortBy) {
      case "price":
        return hotels.sort((a, b) => {
          if (a.source === "AGREEMENT") {
            return -1;
          }
          if (b.source === "AGREEMENT") {
            return 1;
          }
          return toNumber(a.MinRate) - toNumber(b.MinRate);
        });
      case "distance":
        return hotels.sort((a, b) => {
          if (a.source === "AGREEMENT") {
            return -1;
          }
          if (b.source === "AGREEMENT") {
            return 1;
          }
          return toNumber(a.placeDistance) - toNumber(b.placeDistance);
        });
      default:
        return hotels;
    }
  }
);

export const getHotelPrices = createSelector(getSortedHotels, hotels =>
  flatten(hotels.map(hotel => hotel.rooms.map(room => toSafeInteger(room.AmountAfterTax))))
);

export const getHotelMinPrice = createSelector(getHotelPrices, prices => {
  const price = min(prices) || 0;
  return price - (price % 50);
});

export const getHotelMaxPrice = createSelector(getHotelPrices, prices => {
  const price = max(prices) || 0;
  return price - (price % 50) + 50;
});

export const getHotelsResultEmpty = createSelector(getHotelStates, hotelStates =>
  hotelStates
    .map(hotelState => hotelState.status.fetched && hotelState.hotels.length === 0)
    .every(isEmpty => isEmpty === true)
);

export const getFetchingHotelsDone = createSelector(getHotelStatuses, statuses =>
  statuses.every(h => h.fetched)
);

export const getIncludedHotels = createSelector(
  getSortedHotels,
  getActiveCustomersExcludedHotels,
  (hotels, exHotels) => (exHotels ? hotels.filter(hotel => !exHotels.includes(hotel.Rating)) : hotels)
);

export const getFilteredHotels = createSelector(getSortedHotels, getFiltersHotel, (hotels, filters) =>
  hotels.filter(
    hotel =>
      (!defaultStars.includes(parseInt(hotel.stars as string)) ||
        filters.stars.includes(parseInt(hotel.stars as string))) &&
      hotel.rooms &&
      hotel.rooms.length &&
      hotel.rooms[0].AmountAfterTax <= filters.price.max + 10 &&
      hotel.rooms[hotel.rooms.length - 1].AmountAfterTax >= filters.price.min - 10
  )
);

export const getSearchedHotels = createSelector(getFilteredHotels, getFiltersHotel, (hotels, filters) => {
  const { input, type, searchable } = filters.search;
  if (input.length < 3) {
    return hotels;
  }

  const keywords = input.split(" ");

  return hotels.filter(hotel =>
    keywords.every(word =>
      keys(hotel).some(param => {
        if (hotel[param] && includes(searchable, param)) {
          return type === "startsWith"
            ? startsWith(toLower(hotel[param]), toLower(word))
            : includes(toLower(hotel[param]), toLower(word));
        }
        return false;
      })
    )
  );
});

// export const getSearchedHotels = createSelector(
//   getFilteredHotels,
//   getFiltersHotel,
//   (hotels, filters) => {
//     const searchedHotels: IHotel[][] = [];
//     const { input, primaryKey, searchable, type } = filters.search;
//     if (input === "") {
//       return hotels;
//     }
//     const keywords = input.split(" ");
//
//     keywords.forEach((v, i) => {
//       searchedHotels[i] = hotels.filter(hotel => {
//         return (
//           hotel[primaryKey!].filter((room: IRoom) => {
//             return Object.keys(room).some(k => {
//               if (!room[k] || searchable.indexOf(k) === -1) {
//                 return false;
//               }
//               if (type === "startsWith") {
//                 return keywords.some(value => room[k].toLowerCase().startsWith(value.toLowerCase()));
//               } else {
//                 return keywords.some(value => room[k].toLowerCase().indexOf(value.toLowerCase()) !== -1);
//               }
//             });
//           }).length > 0
//         );
//       });
//     });
//     return intersection(...searchedHotels);
//   }
// );

export const getCheapestHotelWithRoom = createSelector(
  getSortedHotels,
  getFetchingHotelsDone,
  (hotels, done) => done && hotels.find(h => h.rooms && h.rooms.length > 0)
);

export const getCheapestFourStarHotelWithRoom = createSelector(
  getSortedHotels,
  getFetchingHotelsDone,
  (hotels, done) => done && hotels.find(h => h.rooms.length > 0 && parseInt(h.stars || "0") > 3)
);

export const getOnlyHotelSearch = createSelector(
  getLastSearch,
  lastSearch => !lastSearch.filters.hasFlights && lastSearch.filters.hasHotels
);

export const getFirstHotel = createSelector(getHotels, getHotelSearchDone, (hotels, hotelSearchDone) =>
  hotelSearchDone && hotels.length ? hotels[0] : undefined
);

export const getOnlyHotelSearchDone = createSelector(
  getHotels,
  getOnlyHotelSearch,
  getHotelSearchDone,
  (hotels, onlyHotelSearch, hotelSearchDone) => !!hotels.length && onlyHotelSearch && hotelSearchDone
);
