import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isArray from "lodash-es/isArray";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InlineSearch from "../components/InlineSearch";
import Loading from "../components/Loading";
import Order from "../components/Order";
import OrderDetailed from "../components/OrderDetailed";
import { Busy } from "../components/snippets/Busy";
import { IBookingOptions } from "../interfaces/IBooking";
import { IOrder } from "../interfaces/IOrder";
import DefaultLayout from "../layouts/DefaultLayout";
import { api } from "../services/api";
import { bookingOffer } from "../store/actions/bookingActions";
import { Merge } from "../types";

const BasketModulePage: FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bookingOfferHandler = useCallback(
    (payload: IBookingOptions) => {
      dispatch(bookingOffer(payload));
    },
    [dispatch]
  );

  const [detailedOrder, setDetailedOrder] = useState<any>();
  const [isBusy, setIsBusy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [selected, setSelected] = useState<string>();

  const searchHandler = (filteredItems: any) => {
    // this.setState({filteredItems});
  };

  const fetchDetails = (guid: string) => {
    setDetailedOrder(null);
    setLoading(true);
    api()
      .get("basket", {
        params: { basket_guid: guid }
      })
      .then(({ data }) => {
        setDetailedOrder(data.basket);
        setLoading(false);
        setSelected(data.basket.basketguid);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchOrderList = () => {
    setIsBusy(true);
    api()
      .post("orderlist")
      .then(({ data }) => {
        setIsBusy(false);
        if (isArray(data.orders)) {
          setOrders(data.orders);
        }
      })
      .catch(() => setIsBusy(false));
  };

  useEffect(() => {
    const basketGuid = parse(location.search).basket as string;
    if (basketGuid) {
      fetchDetails(basketGuid);
    }
    fetchOrderList();
  }, [location.search]);

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: IOrder[] }>> = ({ index, data, style }) => (
    <Order selected={selected} onClick={fetchDetails} order={data[index]} style={style} />
  );

  return (
    <DefaultLayout>
      <div className="container main flex j-center">
        <div className="box-container full-height">
          <div className="title flex j-between a-center is-relative c-primary">
            <span className="pnr-header-icon">
              <FontAwesomeIcon icon={["fas", "clipboard-list"]} />
            </span>
            <h1>{t("order.title")}</h1>
            <div className="fill-space" />
            <InlineSearch
              onSearch={searchHandler}
              items={orders}
              searchableKeys={["custlist.lastname", "custlist.firstname"]}
            />
          </div>
          {isBusy && <Busy />}
          {!isBusy && orders.length === 0 ? (
            <Loading />
          ) : (
            <FixedSizeList
              className="pnr-list"
              itemData={orders}
              itemSize={170}
              height={1000}
              itemCount={orders.length}
              width="100%"
            >
              {rowRenderer}
            </FixedSizeList>
          )}
        </div>
        <div className="box-container full-height">
          <div className="title flex j-between a-center is-relative c-primary">
            <span className="pnr-header-icon">
              <FontAwesomeIcon icon={["fas", "clipboard-list"]} />
            </span>
            <h1 className="text fs-lrg fill-space c-primary bold">{t("generic.details")}</h1>
          </div>
          {loading ? (
            <Loading />
          ) : (
            detailedOrder && (
              <OrderDetailed
                fetchOrderList={fetchOrderList}
                bookingOffer={bookingOfferHandler}
                basket={detailedOrder}
              />
            )
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default BasketModulePage;
