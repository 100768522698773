import React, { CSSProperties, FC } from "react";
import Map from "../containers/Map";

type Props = {
  src?: string;
};

const HomePageBackground: FC<Props> = ({ src }) => {
  const style: CSSProperties = {
    backgroundImage: src ? `url(${src})` : "white"
  };
  return src ? <div className="homepage-background" style={style} /> : <Map />;
};

export default HomePageBackground;
