import moment from "moment";
import { Reducer } from "redux";
import { ICar, ICarSetSearchProps } from "../../interfaces/ICar";
import { ISearch } from "../../interfaces/ISearch";
import { ICarState, IVendorState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import {
  CAR_CLEAR,
  CAR_FETCH_EMPTY,
  CAR_FETCH_FULFILLED,
  CAR_FETCH_PENDING,
  CAR_FETCH_REJECTED,
  CAR_MODAL_CLOSE,
  CAR_MODAL_OPEN,
  CAR_SEARCH_SET_FROM_PROPS
} from "../../utils/constants";
import { defaultSearchState } from "../../utils/defaults";

const initialState: ICarState = {
  ui: {
    modalIsOpen: false
  },
  search: defaultSearchState,
  vendors: {}
};

const carReducer: Reducer<ICarState> = (state = initialState, action) => {
  switch (action.type) {
    case CAR_CLEAR:
      return {
        ...state,
        vendors: action.payload.reduce((acc: Dictionary<IVendorState<ICar>>, service: string) => {
          acc[service] = {
            items: [],
            status: {
              fetched: false,
              fetching: false,
              error: false
            }
          };
          return acc;
        }, {})
      };

    case `${action.service}_${CAR_FETCH_PENDING}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            items: [],
            status: {
              error: false,
              fetched: false,
              fetching: true
            }
          }
        }
      };

    case `${action.service}_${CAR_FETCH_FULFILLED}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            items: action.payload,
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          }
        }
      };

    case `${action.service}_${CAR_FETCH_REJECTED}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            items: [],
            status: {
              error: true,
              fetched: true,
              fetching: false
            }
          }
        }
      };

    case `${action.service}_${CAR_FETCH_EMPTY}`:
      return {
        ...state,
        vendors: {
          ...state.vendors,
          [action.service]: {
            items: [],
            status: {
              error: false,
              fetched: true,
              fetching: false
            }
          }
        }
      };
    case CAR_MODAL_OPEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: true
        }
      };
    case CAR_MODAL_CLOSE:
      return {
        ...state,
        ui: {
          ...state.ui,
          modalIsOpen: false
        }
      };
    case CAR_SEARCH_SET_FROM_PROPS:
      return {
        ...state,
        search: {
          ...setSearchFromProps(action.payload, state.search)
        }
      };
    default:
      return state;
  }
};

export default carReducer;

function setSearchFromProps(props: ICarSetSearchProps, search: ISearch) {
  const newSearch = { ...search };
  if (props.deliverDate) {
    newSearch.rdate = props.deliverDate.includes("-")
      ? props.deliverDate
      : moment(props.deliverDate, "YYYYMMDD").format("YYYY-MM-DD");
  }
  if (props.pickupDate) {
    newSearch.fdate = props.pickupDate.includes("-")
      ? props.pickupDate
      : moment(props.pickupDate, "YYYYMMDD").format("YYYY-MM-DD");
  }
  if (props.pickupFrom) {
    newSearch.orig = props.pickupFrom;
  }
  if (props.deliverTo) {
    newSearch.dest = props.deliverTo;
  }
  if (props.passenger) {
    newSearch.adt = props.passenger.adt;
    newSearch.chd = props.passenger.chd;
    newSearch.inf = props.passenger.inf;
  }
  return newSearch;
}
