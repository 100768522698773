import { createSelector } from "reselect";
import { currencyParser, pwc } from "../../utils/helpers";
import { getCheapestFlight, getCheapestTKFlight, getSortedFlights } from "./flightSelectors";
import { getCheapestFourStarHotelWithRoom, getCheapestHotelWithRoom } from "./hotelSelectors";
import { getHasPackage, getLastSearch } from "./searchSelectors";

export const getEcoPackage = createSelector(
  getCheapestFlight,
  getCheapestHotelWithRoom,
  getLastSearch,
  (flight, hotel, search) => {
    if (flight && hotel && hotel.rooms.length) {
      return {
        currency: flight.Currency,
        flight,
        flightType: search.ftype,
        hotel,
        total: pwc(flight.airitinprice.TotalFare + hotel.rooms[0].AmountAfterTax, flight.Currency)
      };
    }
  }
);

export const getTKPackage = createSelector(
  getCheapestTKFlight,
  getCheapestFourStarHotelWithRoom,
  getLastSearch,
  (flight, hotel, search) => {
    if (flight && hotel && hotel.rooms.length) {
      return {
        currency: flight.Currency,
        flight,
        flightType: search.ftype,
        hotel,
        total: pwc(flight.airitinprice.TotalFare + hotel.rooms[0].AmountAfterTax, flight.Currency)
      };
    }
  }
);

export const getOptimumPackageSimple = createSelector(
  getSortedFlights,
  getCheapestFourStarHotelWithRoom,
  getLastSearch,
  (flights, hotel, search) => {
    const flight = flights.find(f => {
      if (f.airitin.origdests) {
        const goHour = parseInt(f.airitin.origdests[0].DepTime.split(":")[0]);
        const returnHour = parseInt(
          f.airitin.origdests[f.airitin.origdests.length - 1].DepTime.split(":")[0]
        );
        return goHour <= 12 && goHour >= 6 && returnHour >= 16;
      }
      return false;
    });
    if (flight && hotel && hotel.rooms.length) {
      return {
        currency: flight.Currency,
        flight,
        flightType: search.ftype,
        hotel,
        total: pwc(flight.airitinprice.TotalFare + hotel.rooms[0].AmountAfterTax, flight.Currency)
      };
    }
  }
);

export const getOptimumPackage = createSelector(
  getSortedFlights,
  getCheapestHotelWithRoom,
  getLastSearch,
  (flights, hotel, search) => {
    if (search.ftype === "RT") {
      const flight = flights.find(f => {
        if (f.airitin.origdests) {
          const goHour = parseInt(f.airitin.origdests[0].DepTime.split(":")[0]);
          const returnHour = parseInt(
            f.airitin.origdests[f.airitin.origdests.length - 1].DepTime.split(":")[0]
          );
          return goHour <= 12 && goHour >= 6 && returnHour >= 16;
        }
        return false;
      });
      if (flight && hotel && hotel.rooms && hotel.rooms.length > 0) {
        return {
          currency: flight.Currency,
          flights: [flight],
          hotel,
          total:
            (flight.airitinprice.TotalFare + hotel.rooms[0].AmountAfterTax).toFixed(2) +
            currencyParser(flight.Currency)
        };
      }
    }

    if (search.ftype === "OWC") {
      const going = flights.find(f => {
        if (f.airitin.origdests) {
          const depHour = parseInt(f.airitin.origdests[0].DepTime.split(":")[0]);
          return depHour <= 12 && depHour >= 7;
        }
        return false;
      });
      const returning = flights.find(f => {
        if (f.airitin.origdests) {
          const depHour = parseInt(f.airitin.origdests[0].DepTime.split(":")[0]);
          return depHour >= 16;
        }
        return false;
      });

      if (going && returning && hotel && hotel.rooms && hotel.rooms.length > 0) {
        const total =
          (
            going.airitinprice.TotalFare +
            returning.airitinprice.TotalFare +
            hotel.rooms[0].AmountAfterTax
          ).toFixed(2) + currencyParser(going.Currency);
        return {
          currency: going.Currency,
          flights: [going, returning],
          hotel,
          total
        };
      }
    }
  }
);

export const getPackagesReady = createSelector(
  getEcoPackage,
  getTKPackage,
  getOptimumPackage,
  getHasPackage,
  (eco, tk, daily, hasPackage) => {
    return hasPackage && (!!eco || !!tk || !!daily);
  }
);
