import uniqBy from "lodash-es/uniqBy";
import React, { useState } from "react";
import Modal from "../libs/Modal";
import { priceParser } from "../utils/helpers";
import Loading from "./Loading";
import { useCachedFetch } from "../hooks/useCachedFetch";

interface IExchangeRate {
  bankNoteBuying: number;
  bankNoteSelling: number;
  currencyCode: string;
  currencyName: string;
  date: string;
  forexBuying: number;
  forexSelling: number;
  isim: string;
  unit: number;
}

export default function ExchangeRates() {
  const [modalIsActive, setModalIsActive] = useState(false);
  const { data, loading, error } = useCachedFetch<IExchangeRate[]>({
    method: "post",
    uri: "tcmbrates",
    key: "exchangeRates",
    timeInHours: 6,
    transform: data => uniqBy(data, "currencyCode")
  });

  const usdEur = data?.filter(d => d.currencyCode === "EUR" || d.currencyCode === "USD") ?? [];

  return (
    <div className="exchange-rate">
      {modalIsActive && (
        <Modal.Container onClose={() => setModalIsActive(false)}>
          <Modal.Header>
            <h1>Exchange Rates</h1>
          </Modal.Header>
          <Modal.Body>
            <table>
              <thead>
                <tr>
                  <td>Code</td>
                  <td>Name</td>
                  <td>Banknote Buying</td>
                  <td>Banknote Selling</td>
                  <td>Forex Buying</td>
                  <td>Forex Selling</td>
                </tr>
              </thead>
              <tbody>
                {data!.map(d => (
                  <tr key={d.currencyCode}>
                    <td>{d.currencyCode}</td>
                    <td>{d.currencyName}</td>
                    <td>{priceParser(d.bankNoteBuying)}</td>
                    <td>{priceParser(d.bankNoteSelling)}</td>
                    <td>{priceParser(d.forexBuying)}</td>
                    <td>{priceParser(d.forexSelling)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal.Container>
      )}
      {loading ? (
        <Loading />
      ) : (
        !error && (
          <div className="flex pointer" onClick={() => setModalIsActive(true)}>
            {usdEur.map(rate => (
              <p key={rate.currencyCode}>
                {rate.currencyCode}: {priceParser(rate.bankNoteSelling)}
              </p>
            ))}
          </div>
        )
      )}
    </div>
  );
}
