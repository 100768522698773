import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isUndefined from "lodash-es/isUndefined";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import Loading from "../components/Loading";
import LoadingWrapper from "../components/LoadingWrapper";
import Pnr from "../components/pnr/Pnr";
import PnrActivity from "../components/pnr/PnrActivity";
import PnrCar from "../components/pnr/PnrCar";
import PnrFlight from "../components/pnr/PnrFlight";
import PnrGeneric from "../components/pnr/PnrGeneric";
import PnrHotel from "../components/pnr/PnrHotel";
import PnrTransfer from "../components/pnr/PnrTransfer";
import { Busy } from "../components/snippets/Busy";
import InlineSearchPnr from "../containers/InlineSearchPnr";
import {
  IPnrActivity,
  IPnrCar,
  IPnrFlight,
  IPnrGeneric,
  IPnrHotel,
  IPnrSummary,
  IPnrTransfer
} from "../interfaces/IPnr";
import DefaultLayout from "../layouts/DefaultLayout";
import { api } from "../services/api";
import Logger from "../services/Logger";
import { changeFilterPnrItems } from "../store/actions/filterActions";
import { fetchPnrs, updatePnr } from "../store/actions/pnrActions";
import { getFiltersPnr } from "../store/selectors/filterSelectors";
import { getPnrStatus, getSearchedPnrs } from "../store/selectors/pnrSelectors";
import { getUser } from "../store/selectors/userSelectors";
import { Merge } from "../types";
import { isMobile } from "../utils/helpers";
import cx from "classnames";

const PnrModulePage: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const status = useSelector(getPnrStatus);
  const pnrs = useSelector(getSearchedPnrs);
  const filters = useSelector(getFiltersPnr);
  const user = useSelector(getUser);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentPnr, setCurrentPnr] = useState<
    IPnrSummary | IPnrHotel | IPnrFlight | IPnrActivity | IPnrCar | IPnrGeneric
  >();
  const [preloaded, setPreloaded] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);

  const tabHandler = useCallback(
    (index: 1 | 2) => () => {
      setTabIndex(index);
    },
    []
  );

  const updatePnrHandler = useCallback(
    (p: string, s: string) => {
      dispatch(updatePnr(p, s));
    },
    [dispatch]
  );

  const fetchPnrDetails = useCallback(
    async (pnr: IPnrSummary | IPnrHotel | IPnrFlight | IPnrActivity | IPnrCar | IPnrGeneric) => {
      setLoading(true);
      setCurrentPnr(undefined);
      const getPnrPayload = {
        ...pnr,
        action: pnr.action || (pnr as any).action_type
      };

      const log = new Logger(t("pnr.fetchDetailPending"));
      try {
        const { data } = await api().post("getpnr", getPnrPayload);
        if (data.pnr) {
          log.success(t("pnr.fetchDetailSuccess"));
          setCurrentPnr(data);
          setTabIndex(2);
        } else {
          log.error(t("pnr.fetchDetailsError"));
        }
      } catch (e) {
        log.error(e);
      }
      setLoading(false);
    },
    [t]
  );

  useEffect(() => {
    const { preload } = parse(location!.search);
    dispatch<any>(fetchPnrs()).then((newPnrs: IPnrSummary[]) => {
      if (!isUndefined(preload) && !preloaded) {
        setPreloaded(true);
        const loadingPnr = newPnrs.find(p => p.pnr === preload) || newPnrs[0];
        fetchPnrDetails(loadingPnr).then();
      }
    });
  }, [dispatch, fetchPnrDetails, location, preloaded]);

  const filterItemsHandler = useCallback(
    (item: string) => () => {
      dispatch(
        changeFilterPnrItems({
          ...filters.items,
          [item]: !filters.items[item]
        })
      );
    },
    [dispatch, filters.items]
  );

  const rowRenderer: FC<Merge<ListChildComponentProps, { data: IPnrSummary[] }>> = ({
    index,
    data,
    style
  }) => (
    <Pnr
      current={currentPnr}
      pnr={data[index]}
      selected={!!currentPnr && currentPnr.pnr === data[index].pnr}
      loadPnrDetails={fetchPnrDetails}
      style={style}
    />
  );

  return (
    <DefaultLayout>
      <div className="container main flex j-center">
        <div className={`box-container full-height${tabIndex !== 1 && " is-mobile-hidden"}`}>
          {loading && <Busy />}
          <div className="title flex j-between a-center is-relative c-primary">
            <span className="pnr-header-icon is-tablet-only">
              <FontAwesomeIcon icon={["far", "bars"]} />
            </span>
            <h1 className="text fs-lrg fill-space c-primary bold is-tablet-only">{t("pnr.title")}</h1>
            <div className="quick-filter">
              <button
                className={`quick-filter-button${filters.items.flight ? " active" : ""}`}
                onClick={filterItemsHandler("flight")}
              >
                <FontAwesomeIcon icon={["fas", "plane"]} />
              </button>
              <button
                className={`quick-filter-button${filters.items.hotel ? " active" : ""}`}
                onClick={filterItemsHandler("hotel")}
              >
                <FontAwesomeIcon icon={["fas", "home"]} />
              </button>
              <button
                className={`quick-filter-button${filters.items.car ? " active" : ""}`}
                onClick={filterItemsHandler("car")}
              >
                <FontAwesomeIcon icon={["fas", "car"]} />
              </button>
              <button
                className={`quick-filter-button${filters.items.transfer ? " active" : ""}`}
                onClick={filterItemsHandler("transfer")}
              >
                <FontAwesomeIcon icon={["fas", "shuttle-van"]} />
              </button>
              <button
                className={`quick-filter-button${filters.items.activity ? " active" : ""}`}
                onClick={filterItemsHandler("activity")}
              >
                <FontAwesomeIcon icon={["fas", "campground"]} />
              </button>
            </div>
            <InlineSearchPnr />
          </div>
          {status.fetching ? (
            <Loading />
          ) : (
            pnrs.length > 0 && (
              <FixedSizeList
                className="pnr-list"
                itemData={pnrs}
                itemSize={isMobile() ? 200 : 170}
                height={1000}
                itemCount={pnrs.length}
                width="100%"
              >
                {rowRenderer}
              </FixedSizeList>
            )
          )}
        </div>
        <div className={`box-container full-height${tabIndex !== 2 && " is-mobile-hidden"}`}>
          <LoadingWrapper loading={loading}>
            {currentPnr &&
              (currentPnr.action === "hotel" ? (
                <PnrHotel
                  reload={fetchPnrDetails}
                  updatePnr={updatePnrHandler}
                  pnr={currentPnr as IPnrHotel}
                  user={user}
                />
              ) : currentPnr.action === "flight" ? (
                <PnrFlight
                  reload={fetchPnrDetails}
                  updatePnr={updatePnrHandler}
                  pnr={currentPnr as IPnrFlight}
                />
              ) : currentPnr.action === "activity" ? (
                <PnrActivity
                  reload={fetchPnrDetails}
                  updatePnr={updatePnrHandler}
                  pnr={currentPnr as IPnrActivity}
                />
              ) : currentPnr.action === "car" ? (
                <PnrCar reload={fetchPnrDetails} updatePnr={updatePnrHandler} pnr={currentPnr as IPnrCar} />
              ) : currentPnr.action === "transfer" ? (
                <PnrTransfer
                  reload={fetchPnrDetails}
                  updatePnr={updatePnrHandler}
                  pnr={currentPnr as IPnrTransfer}
                />
              ) : (
                <PnrGeneric
                  reload={fetchPnrDetails}
                  updatePnr={updatePnrHandler}
                  pnr={currentPnr as IPnrGeneric}
                />
              ))}
          </LoadingWrapper>
        </div>
      </div>
      <div className="is-mobile-only mobile-tabs">
        <button
          className={cx({
            "is-active": tabIndex === 1
          })}
          onClick={tabHandler(1)}
        >
          Pnr List
        </button>
        <button
          className={cx({
            "is-active": tabIndex === 2
          })}
          onClick={tabHandler(2)}
        >
          Details
        </button>
      </div>
    </DefaultLayout>
  );
};

export default PnrModulePage;
