import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import React, { FC, useEffect, useRef } from "react";

interface IProps {
  currency: string;
  data: Array<{
    amount: number;
    colorIndex: number;
    color?: am4core.Color;
    currency: string;
    service: string;
    open: number;
    stepAmount: number;
    displayAmount: number;
  }>;
}

const ExpensesWaterFallChart: FC<IProps> = ({ data }) => {
  const $chart = useRef(null);

  useEffect(() => {
    const chart = am4core.create($chart.current!, am4charts.XYChart);

    chart.hiddenState.properties.opacity = 0;

    chart.data = data.map(d => {
      d.color = chart.colors.getIndex(d.colorIndex);
      return d;
    });

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "service";
    categoryAxis.renderer.minGridDistance = 40;

    chart.yAxes.push(new am4charts.ValueAxis());

    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.categoryX = "service";
    columnSeries.dataFields.valueY = "amount";
    columnSeries.dataFields.openValueY = "open";
    columnSeries.fillOpacity = 0.8;
    columnSeries.sequencedInterpolation = true;
    columnSeries.interpolationDuration = 1500;

    const columnTemplate = columnSeries.columns.template;
    columnTemplate.strokeOpacity = 0;
    columnTemplate.propertyFields.fill = "color";

    const label = columnTemplate.createChild(am4core.Label);
    label.text = "[font-size: 10px; white; bold]{displayAmount} {currency}[/]";
    label.align = "center";
    label.valign = "middle";

    const stepSeries = chart.series.push(new am4charts.StepLineSeries());
    stepSeries.dataFields.categoryX = "service";
    stepSeries.dataFields.valueY = "stepAmount";
    stepSeries.noRisers = true;
    stepSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    stepSeries.strokeDasharray = "3,3";
    stepSeries.interpolationDuration = 2000;
    stepSeries.sequencedInterpolation = true;

    stepSeries.startLocation = 0.1;
    stepSeries.endLocation = 1.1;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "none";

    return () => chart.dispose();
  }, [data]);

  return (
    <div className="chart-container fill-height">
      <div className="chart" ref={$chart} />
    </div>
  );
};

export default ExpensesWaterFallChart;
