import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckboxSimple from "../../../components/snippets/CheckboxSimple";
import Select from "../../../components/snippets/Select";
import { ICustomer, ICustomerRoom } from "../../../interfaces/ICustomer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  customer: ICustomer;
  rooms: ICustomerRoom[];

  assignRoom(roomNo: number, custGuid: string): void;
  onSelect(customer: ICustomer): void;
  selectEditCustomer(customer: ICustomer): void;
}

const Customer: FC<IProps> = ({ customer, rooms, assignRoom, onSelect, selectEditCustomer }) => {
  const { t } = useTranslation();
  const passengerTypeParser = useMemo(() => {
    switch (customer.passenger_type) {
      case "ADT":
        return t("customer.adt");
      case "CHD":
        return t("customer.chd");
      case "INF":
        return t("customer.inf");
      default:
        return t("customer.unknown");
    }
  }, [customer.passenger_type, t]);

  const selectCustomerHandler = useCallback(() => {
    onSelect(customer);
  }, [customer, onSelect]);

  const customerRoom = useMemo(() => {
    const room = rooms.find(r => r.custGuids.includes(customer.cust_guid));
    return room ? String(room.no) : undefined;
  }, [rooms, customer]);

  const chooseRoom = useCallback(
    (value: string) => {
      assignRoom(parseInt(value), customer.cust_guid);
    },
    [assignRoom, customer.cust_guid]
  );

  const options = useMemo(() => {
    const validRooms = rooms.filter(r => (customer.passenger_type === "ADT" || r.hasAdult) && String(r.no));
    return validRooms.map(r => String(r.no));
  }, [customer.passenger_type, rooms]);

  return (
    <div className="customer flex a-center">
      <CheckboxSimple checked={customer.selected} onClick={selectCustomerHandler} />
      <div onClick={selectCustomerHandler}>
        <p>
          {customer.title} {customer.firstname} {customer.lastname}
        </p>
        <p className="fs-med">{customer.company_title}</p>
      </div>
      <div className="fill-space" />
      <div>
        <p className="fs-small align-right">{passengerTypeParser}</p>
        <p className="fs-med">{customer.telephone}</p>
      </div>
      <div className="room-select">
        <Select label={t("generic.room")} options={options} onChange={chooseRoom} value={customerRoom} />
      </div>
      <div onClick={() => selectEditCustomer(customer)}>
        <FontAwesomeIcon icon={['fas', 'edit']}/>
      </div>
    </div>
  );
};

export default Customer;
