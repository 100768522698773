import { useField } from "formik";
import React, { FC, useEffect, useState } from "react";
import isEmpty from "lodash-es/isEmpty";

type Props = {
  name: string;
};

const CustomerMiles: FC<Props> = ({ name }) => {
  const [{ value }, , { setValue }] = useField<string>(name);
  const [id, setId] = useState(value.replace("TK", ""));
  const [code, setCode] = useState("TK");

  useEffect(() => {
    if (!isEmpty(id) && !isEmpty(code)) {
      setValue(code + id);
    } else {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, id]);

  return (
    <div className="flex">
      <select onChange={e => setCode(e.currentTarget.value)}>
        <option value="TK">TK</option>
      </select>
      <input defaultValue={id} onChange={e => setId(e.currentTarget.value)} placeholder="Miles Id" />
    </div>
  );
};

export default CustomerMiles;
