import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBatchToBasket, Batch } from "../store/actions/basketActions";
import {
  getEcoPackage,
  getOptimumPackageSimple,
  getTKPackage
} from "../store/selectors/smartPackagesSelectors";
import Package from "./Package";
import { Busy } from "./snippets/Busy";

interface IProps {
  onClose?(): void;
}

const SmartPackages: FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const ecoPackage = useSelector(getEcoPackage);
  const optimumPackage = useSelector(getOptimumPackageSimple);
  const tkPackage = useSelector(getTKPackage);

  const [isBusy, setIsBusy] = useState(false);

  const addToBasketHandler = useCallback(
    async (batch: Batch) => {
      setIsBusy(true);
      await dispatch(addBatchToBasket(batch));
      setIsBusy(false);
    },
    [dispatch]
  );

  return (
    <div className="smart-packages-wrapper">
      <div className="container">
        <div className="smart-packages flex">
          {onClose && (
            <button onClick={onClose} className="smart-close">
              <FontAwesomeIcon icon={["far", "times"]} />
            </button>
          )}
          {isBusy && <Busy />}
          {ecoPackage ? (
            <Package
              {...ecoPackage}
              addBasket={addToBasketHandler}
              title="Best Offer"
              icon={["far", "star"]}
            />
          ) : (
            <div className="package transparent" />
          )}
          {tkPackage ? (
            <Package
              {...tkPackage}
              addBasket={addToBasketHandler}
              title="TK Offer"
              icon={["far", "user-tie"]}
            />
          ) : (
            <div className="package transparent" />
          )}
          {optimumPackage ? (
            <Package
              {...optimumPackage}
              title="Optimum Offer"
              icon={["fas", "plane"]}
              addBasket={addToBasketHandler}
            />
          ) : (
            <div className="package transparent" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SmartPackages;
