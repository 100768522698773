import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { browserHistory } from "../../utils/history";
import routeList, { GuardType } from "../routeList";
import { GuestOnlyRoute } from "./GuestOnlyRoute";
import { ProtectedRoute } from "./ProtectedRoute";
import { useSelector } from "react-redux";
import { getUserIsAuthenticated } from "../../store/selectors/userSelectors";

type Props = {};

const Routes: React.FC<Props> = () => {
  const isAuthenticated = useSelector(getUserIsAuthenticated);

  return (
    <Router history={browserHistory}>
      <Switch>
        {routeList.map(({ guard, ...props }, idx) => {
          switch (guard) {
            case GuardType.guest:
              return <GuestOnlyRoute key={idx} {...props} isAuthenticated={isAuthenticated} />;
            case GuardType.protected:
              return <ProtectedRoute key={idx} {...props} isAuthenticated={isAuthenticated} />;
            default:
              return <Route key={idx} {...props} />;
          }
        })}
      </Switch>
    </Router>
  );
};

export default Routes;
