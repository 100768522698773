import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import times from "lodash-es/times";
import React from "react";

interface IProps {
  className?: string;
  emptyOffset?: boolean;
  rating?: number | string;
}

export default function Rating({ rating = 0, emptyOffset = true, className = "" }: IProps) {
  const stars: any = [];
  const full = rating ? parseInt(rating.toString()) : 0;
  const empty = 7 - full;
  const classes = className ? className : "fs-med";

  times(full, i =>
    stars.push(
      <FontAwesomeIcon key={i} className={`text ${classes}`} icon={["fas", "star"]} color="#FFAD00" />
    )
  );
  if (emptyOffset) {
    times(empty, i =>
      stars.push(
        <FontAwesomeIcon
          key={full + i}
          className={`text ${classes}`}
          icon={["fas", "star"]}
          color="transparent"
        />
      )
    );
  }

  return <div className="stars">{stars}</div>;
}
