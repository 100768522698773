import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getProvisionState = (state: IStoreState) => state.provisions;

export const getRoomProvisions = createSelector(getProvisionState, provisionState => provisionState.rooms);

export const getProvisionStatus = createSelector(getProvisionState, provisionState => provisionState.status);

export const getProvisionRejectedRooms = createSelector(
  getProvisionState,
  provisionState => provisionState.rejectedRooms
);

export const getProvisionResults = createSelector(
  getProvisionState,
  provisionState => provisionState.provisions
);
