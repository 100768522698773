import { ContentState, convertFromHTML, Editor, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";

// const sampleMarkup =
//   '<b>Bold text</b>, <i>Italic text</i><br/ ><br />' +
//   '<a href="http://www.facebook.com">Example link</a>';

interface IProps {
  html?: string;

  onChange(html: string): void;
}

const AgreementHotelDescriptionEditor: FC<IProps> = ({ html = "", onChange }) => {
  const $editor = useRef<Editor>(null);
  const [state, setState] = useState(EditorState.createEmpty);

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(html);

    const editorState = blocksFromHTML.contentBlocks
      ? ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
      : ContentState.createFromText("");
    setState(EditorState.createWithContent(editorState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeHandler = useCallback(
    (editorState: EditorState) => {
      setState(editorState);
      onChange(stateToHTML(editorState.getCurrentContent()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const focusEditor = useCallback(() => {
    $editor.current!.focus();
  }, []);

  return (
    <div style={styles.editor} onClick={focusEditor}>
      <Editor ref={$editor} editorState={state} onChange={changeHandler} />
    </div>
  );
};

const styles = {
  editor: {
    backgroundColor: "white",
    border: "1px solid gray",
    color: "black",
    cursor: "text",
    fontSize: 13,
    minHeight: "10em",
    padding: 5
  }
};

export default AgreementHotelDescriptionEditor;
