import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash-es/get";
import keys from "lodash-es/keys";
import map from "lodash-es/map";
import reduce from "lodash-es/reduce";
import round from "lodash-es/round";
import sumBy from "lodash-es/sumBy";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import InlineSearch from "../../components/InlineSearch";
import Loading from "../../components/Loading";
import ReportDatePicker from "../../components/ReportDatePicker";
import SpreadsheetButton from "../../components/snippets/SpreadsheetButton";
import useObjectState from "../../hooks/useObjectState";
import { IReportField } from "../../interfaces/IAccounting";
import DefaultLayout from "../../layouts/DefaultLayout";
// @ts-ignore
import { Cell, Row, StickyTable } from "react-sticky-table";
import { api } from "../../services/api";
import { Dictionary } from "../../types";

const AgencyProfitLossPage: FC = () => {
  const { t } = useTranslation();

  const [fields, setFields] = useState<Dictionary<IReportField>>({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useObjectState({
    currency: "TRY",
    company: "sisecam",
    edate: moment().format("YYYYMMDD"),
    sdate: moment().format("YYYY0101")
  });

  const [filteredReports, setFilteredReports] = useState([]);

  const filterHandler = useCallback(
    (filteredItems: any) => {
      setFilteredReports(filteredItems);
    },
    [setFilteredReports]
  );

  const dateHandler = useCallback(
    (field: string) => (value: string) => {
      setPayload({
        [field]: value
      });
    },
    [setPayload]
  );

  const fetchReports = useCallback(async () => {
    setLoading(true);
    setReports([]);
    try {
      const { data } = await api().post("rptagencysales", payload);
      const hasData = get(data, "report.length", false);
      if (hasData) {
        setReports(data.report);
        setFilteredReports(data.report);
        if (data.fields) {
          setFields(data.fields);
        } else {
          setFields(
            reduce(
              keys(data.report[0]),
              (fields, field) => {
                fields[field] = {
                  title: field.replace(/_/g, " ")
                };
                return fields;
              },
              {} as Dictionary<IReportField>
            )
          );
        }
      }
    } catch {}
    setLoading(false);
  }, [payload]);

  return (
    <DefaultLayout>
      <div className="container main">
        <div className="reports">
          <div className="report-page-title">
            <h2>{t("report.title.profitloss")}</h2>
          </div>
          <div className="reports-header">
            <div className="reports-header-row">
              <div className="report-filters flex inline a-center">
                <ReportDatePicker
                  startDate={payload.sdate}
                  endDate={payload.edate}
                  pickEndDate={dateHandler("edate")}
                  pickStartDate={dateHandler("sdate")}
                />

                <button disabled={loading} onClick={fetchReports}>
                  {loading ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
                </button>
              </div>
              {loading && <Loading />}
              {reports.length > 0 && (
                <SpreadsheetButton
                  filename="Accounting"
                  reports={filteredReports}
                  fields={fields}
                  total={true}
                />
              )}
            </div>
            {reports.length > 0 && (
              <div className="reports-header-row">
                <InlineSearch
                  items={reports}
                  searchableKeys={[
                    "pnr",
                    "route",
                    "marketer",
                    "start_date",
                    "end_date",
                    "user_name",
                    "source",
                    "customer",
                    "operator",
                    "collection_type",
                    "collection_name"
                  ]}
                  onSearch={filterHandler}
                />
              </div>
            )}
          </div>
          <div className="reports-table-container">
            {reports.length > 0 && (
              <StickyTable stickyColumnCount={0} className="report-table striped">
                <Row>
                  {map(fields, (options, field) => (
                    <React.Fragment key={field}>
                      <Cell key={field}>{options.title}</Cell>
                      {field === "total_cost" && <Cell>{t("report.profitloss")}</Cell>}
                    </React.Fragment>
                  ))}
                </Row>
                {filteredReports.map((report: any, index) => (
                  <Row key={index}>
                    {map(fields, (options, field) => (
                      <React.Fragment key={index + field}>
                        <Cell>{report[field]}</Cell>
                        {field === "total_cost" && (
                          <Cell>{round(report.total_amount - report.total_cost, 2)}</Cell>
                        )}
                      </React.Fragment>
                    ))}
                  </Row>
                ))}
                <Row className="total-row">
                  {keys(fields).map((field, index) => {
                    if (index === 0) {
                      return (
                        <Cell>
                          {filteredReports.length} {t("report.rows")}
                        </Cell>
                      );
                    }

                    if (index > 1 && ["total_amount", "total_cost", "cost_amount"].includes(field)) {
                      if (field === "total_cost") {
                        return (
                          <>
                            <Cell key={field}>{sumBy(filteredReports, field).toFixed(2)}</Cell>
                            <Cell>
                              {round(
                                sumBy(filteredReports, "total_amount") - sumBy(filteredReports, "total_cost"),
                                2
                              )}
                            </Cell>
                          </>
                        );
                      }
                      return <Cell key={field}>{sumBy(filteredReports, field).toFixed(2)}</Cell>;
                    }
                    return <Cell key={field} />;
                  })}
                </Row>
              </StickyTable>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AgencyProfitLossPage;
