import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CarButton from "../../modules/TransportationScreen/components/CarButton";
import TransferButton from "../../modules/TransportationScreen/components/TransferButton";
import { getCarServices, getTransferServices } from "../../store/selectors/serviceSelectors";

type Props = {};

const CrossSellSection: FC<Props> = () => {
  const { t } = useTranslation();
  const carServices = useSelector(getCarServices);
  const transferServices = useSelector(getTransferServices);

  const carMessage = !carServices.length ? t("car.required") : undefined;
  const transferMessage = !transferServices.length ? t("transfer.required") : undefined;

  return (
    <div className="x-sell-section">
      <div className="x-sell-item-container" data-tip={carMessage}>
        <CarButton className="x-sell-item" disabled={!!carMessage}>
          <span>Car</span> <FontAwesomeIcon icon={["fas", "car"]} />
        </CarButton>
      </div>
      <div className="x-sell-item-container" data-tip={transferMessage}>
        <TransferButton className="x-sell-item" disabled={!!transferMessage}>
          <span>Transfer</span> <FontAwesomeIcon icon={["fas", "shuttle-van"]} />
        </TransferButton>
      </div>
    </div>
  );
};

export default CrossSellSection;
