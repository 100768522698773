import classNames from "classnames";
import map from "lodash-es/map";
import { utc } from "moment";
import React from "react";
import { ISaleItem } from "../interfaces/ISalesReport";

interface IProps {
  depth: number;
  expenses: ISaleItem[];
  visible?: boolean;
}

const ExpenseRows: any = ({ depth, expenses, visible = true }: IProps) => {
  const cx = classNames(`depth-${depth}`, {
    hidden: !visible
  });

  return expenses.map((trans, index) => (
    <tr className={cx} key={index}>
      <td />
      {map(trans, (value, i) => (
        <td className="align-right" key={index + "-" + i}>
          {i === "action_date" ? utc(value).format("DD.MM.YYYY") : value}
        </td>
      ))}
      <td />
    </tr>
  ));
};

export default ExpenseRows;
