import moment from "moment";
import React, { FC, useMemo } from "react";
import { IProgramItem } from "../interfaces/IProgram";
import ProgramItem from "./ProgramItem";

interface IProps {
  date: string;
  items: IProgramItem[];
}

const ProgramItems: FC<IProps> = ({ date, items }) => {
  const parsedDate = useMemo(() => {
    return moment(date).format("D MMMM YYYY");
  }, [date]);

  return (
    <div className="airplane info">
      <div className="title">
        {/*<img src="/images/voucher/airplane.png" alt="airline" />*/}
        <h3>{parsedDate}</h3>
      </div>
      <div className="caption program-container">
        <table>
          <tbody>
            {items.map((item, idx) => (
              <ProgramItem item={item} key={idx} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProgramItems;
