import React, { FC, useState, useEffect } from "react";
import { IAirport } from "../../interfaces/IAirport";
import { checkKeyCode, clrStr } from "../../utils/helpers";
import useClickDetect from "../../hooks/useClickDetect";

type Props = {
  value: string;
  onSelect(airport?: IAirport): void;
};

export const filterableProps = ["city", "code", "country", "name", "state"];

const AirportPicker: FC<Props> = ({ value, onSelect }) => {
  const [filteredAirports, setFilteredAirports] = useState<IAirport[]>([]);
  const [listIsActive, setListIsActive] = useState(false);
  const [index, setIndex] = useState(0);
  const $el = useClickDetect(() => setListIsActive(false));

  const filterAirports = (value: string) => {
    if (value.length < 3) {
      setFilteredAirports([]);
      setListIsActive(false);
    } else {
      const newAirports = window.airports.filter((airport: IAirport) => {
        return Object.keys(airport).some(k => {
          if (!airport[k] || filterableProps.indexOf(k) === -1) {
            return false;
          }
          return clrStr(airport[k]).startsWith(clrStr(value));
        });
      });
      setFilteredAirports(newAirports);
      setListIsActive(true);
    }
  };

  useEffect(() => {
    function keyHandler(e: KeyboardEvent) {
      if (checkKeyCode(e, 40) && index < filteredAirports.length - 1) {
        setIndex(x => x + 1);
      }
      if (checkKeyCode(e, 38) && index > 0) {
        setIndex(x => x - 1);
      }
      if (filteredAirports.length > 0 && checkKeyCode(e, 13)) {
        onSelect(filteredAirports[index]);
      }
    }
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [filteredAirports, index, onSelect]);

  useEffect(() => {
    filterAirports(value);
    if (!value) {
      setListIsActive(false);
    }
  }, [value]);

  return listIsActive ? (
    <ul ref={$el} className="airport-list">
      {filteredAirports.map((airport: IAirport, idx) => (
        <li className={index === idx ? "selected" : ""} onClick={() => onSelect(airport)} key={idx}>
          {airport.name} / {airport.state} ({airport.code})
        </li>
      ))}
    </ul>
  ) : null;
};

export default AirportPicker;
