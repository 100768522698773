import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import randomColor from "randomcolor";
import React, { useEffect, useMemo, useRef } from "react";

am4core.useTheme(am4themes_animated);

// avg_agreement_price: 0
// avg_price: 664.75
// country: "France"
// isAgreement: false
// nights: 4
// nights_agreement: 0
// nights_improper: 0
// total_amount: 2659
// total_improper: 0

interface IProps {
  data: any;
}
const BubbleChart = ({ data }: IProps) => {
  const $container = useRef<HTMLDivElement>(null);

  const chartData = useMemo(() => {
    return data.map((d: any) => ({
      color: randomColor(),
      currency: d.currency || "TRY",
      title: d.hotelName ? d.hotelName : d.city ? d.city : d.country ? d.country : null,
      value: d.nights,
      x: d.total_amount,
      y: d.total_amount
    }));
  }, [data]);

  useEffect(() => {
    const chart = am4core.create($container.current!, am4charts.XYChart);

    const valueAxisXBase = new am4charts.ValueAxis();
    valueAxisXBase.numberFormatter.numberFormat = "#,###' TRY'";

    const valueAxisX = chart.xAxes.push(valueAxisXBase);
    valueAxisX.renderer.ticks.template.disabled = true;
    valueAxisX.renderer.axisFills.template.disabled = true;
    valueAxisX.title.text = "Expenses";

    const valueAxisYBase = new am4charts.ValueAxis();
    valueAxisYBase.numberFormatter.numberFormat = "#,###' TRY'";

    const valueAxisY = chart.yAxes.push(valueAxisYBase);
    valueAxisY.renderer.ticks.template.disabled = true;
    valueAxisY.renderer.axisFills.template.disabled = true;
    valueAxisY.title.text = "Budget";

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueX = "x";
    series.dataFields.valueY = "y";
    series.dataFields.value = "value";
    series.strokeOpacity = 0;
    series.sequencedInterpolation = true;
    series.tooltip!.pointerOrientation = "vertical";

    const bullet = series.bullets.push(new am4core.Circle());
    bullet.fill = am4core.color("#ff0000");
    bullet.propertyFields.fill = "color";
    bullet.strokeOpacity = 0;
    bullet.strokeWidth = 2;
    bullet.fillOpacity = 0.5;
    bullet.stroke = am4core.color("#ffffff");
    bullet.hiddenState.properties.opacity = 0;
    bullet.tooltipText = "[bold]{title}:[/]\nReservations: {value.value}";

    const outline = chart.plotContainer.createChild(am4core.Circle);
    outline.fillOpacity = 0;
    outline.strokeOpacity = 0.8;
    outline.stroke = am4core.color("#ff0000");
    outline.strokeWidth = 2;
    outline.hide(0);

    const blurFilter = new am4core.BlurFilter();
    outline.filters.push(blurFilter);

    bullet.events.on("over", event => {
      const target = event.target;
      chart.cursor.triggerMove({ x: target.pixelX, y: target.pixelY }, "hard");
      chart.cursor.lineX.y = target.pixelY;
      chart.cursor.lineY.x = target.pixelX - chart.plotContainer.pixelWidth;
      valueAxisX.tooltip!.disabled = false;
      valueAxisY.tooltip!.disabled = false;

      outline.radius = target.pixelRadius + 2;
      outline.x = target.pixelX;
      outline.y = target.pixelY;
      outline.show();
    });

    bullet.events.on("out", event => {
      chart.cursor.triggerMove(event.pointer.point, "none");
      chart.cursor.lineX.y = 0;
      chart.cursor.lineY.x = 0;
      valueAxisX.tooltip!.disabled = true;
      valueAxisY.tooltip!.disabled = true;
      outline.hide();
    });

    const hoverState = bullet.states.create("hover");
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.strokeOpacity = 1;

    series.heatRules.push({ target: bullet, min: 2, max: 60, property: "radius" });

    bullet.adapter.add("tooltipY", (tooltipY, target) => {
      return -target.radius;
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.behavior = "zoomXY";

    // chart.scrollbarX = new am4core.Scrollbar();
    // chart.scrollbarY = new am4core.Scrollbar();

    chart.data = chartData;

    return () => chart.dispose();
  }, [chartData]);

  return (
    <div className="chart-container">
      <div className="chart" ref={$container} />
    </div>
  );
};

export default BubbleChart;
