import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isUndefined from "lodash-es/isUndefined";
import React from "react";
import { IPolicyMoney } from "../../../../interfaces/IPolicy";
import PolicyMoneyEdit from "./PolicyMoneyEdit";

interface IProps {
  budget: IPolicyMoney;
  title: string;

  onChange(budget: IPolicyMoney): void;
}

function PolicyPending({ budget, title, onChange }: IProps) {
  const changeHandler = (newBudget: IPolicyMoney, index?: number) => {
    if (!isUndefined(index)) {
      budget.pendingList![index] = newBudget;
    } else {
      budget = { ...newBudget };
    }
    onChange(budget);
  };
  const addPending = () => {
    const pendingList = budget.pendingList ? [...budget.pendingList] : [];

    pendingList.push({
      currency: budget.currency,
      department_limit: budget.department_limit,
      limit: 0,
      parents: []
    });

    budget.pendingList = pendingList;

    onChange(budget);
  };

  return (
    <div className="form-group">
      <div className="flex j-between a-center">
        <h4>{title}</h4>
      </div>
      <PolicyMoneyEdit budget={budget} onChange={changeHandler} />
      {budget.pendingList &&
        budget.pendingList!.map((b, i) => (
          <PolicyMoneyEdit key={i} budget={b} index={i} onChange={changeHandler} />
        ))}
      <div>
        {(!budget.pendingList || budget.pendingList.length < 3) && (
          <button onClick={addPending}>
            <FontAwesomeIcon icon={["far", "plus"]} />
          </button>
        )}
      </div>
    </div>
  );
}

export default PolicyPending;
