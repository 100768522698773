import isEmpty from "lodash-es/isEmpty";
import isNumber from "lodash-es/isNumber";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ICustomerPolicy } from "../../interfaces/ICustomer";
import Modal from "../../libs/Modal";

interface IProps {
  active: boolean;
  policy?: ICustomerPolicy;
  onClose(): void;
}

const emptyPolicy = (policyValue: string | number, fallback: string, ...args: string[]) => {
  return isNumber(policyValue) || !isEmpty(policyValue) ? `${policyValue} ${args.join(" ")}` : fallback;
};

const PolicyModal: FC<IProps> = ({ active, policy, onClose }) => {
  const { t } = useTranslation();

  return active && policy ? (
    <Modal.Container onClose={onClose} width={500}>
      <Modal.Header>
        <p>{t("policyModal.title")}</p>
      </Modal.Header>
      <Modal.Body>
        <div className="policy-details">
          <div className="policy-detail">
            <h4>{t("generic.budget")}</h4>
            <div>
              <p>
                <span className="policy-item-title">{t("policyModal.approved")}:</span>{" "}
                {emptyPolicy(policy.budget.approved.limit, "-", policy.budget.approved.currency)}
              </p>
              <p>
                <span className="policy-item-title">{t("policyModal.pending")}:</span>{" "}
                {emptyPolicy(policy.budget.pending.limit, "-", policy.budget.pending.currency)}
              </p>
            </div>
            <h4>{t("admin.policy.flightTitle")}</h4>
            <div>
              <p>
                <span className="policy-item-title">{t("policyModal.excludedAirlines")}:</span>{" "}
                {emptyPolicy(policy?.flights?.airlines?.excluded?.join(", "), "-")}
              </p>
              <p>
                <span className="policy-item-title">{t("policyModal.includedAirlines")}:</span>{" "}
                {emptyPolicy(policy?.flights?.airlines?.included?.join(", "), "-")}
              </p>
            </div>
            <div>
              <p>
                <span className="policy-item-title">{t("policyModal.excludedFlightTypes")}:</span>{" "}
                {emptyPolicy(policy?.flights?.type?.excluded?.join(", "), "-")}
              </p>
              <p>
                <span className="policy-item-title">{t("policyModal.includedFlightTypes")}:</span>{" "}
                {emptyPolicy(policy?.flights?.type?.included?.join(", "), "-")}
              </p>
            </div>
            <h4>{t("admin.policy.hotelTitle")}</h4>
            <div>
              <p>
                <span className="policy-item-title">{t("policyModal.excludedRatings")}:</span>{" "}
                {emptyPolicy(policy?.hotels?.rating?.excluded?.map(r => r.code)?.join(", "), "-")}
              </p>
              <p>
                <span className="policy-item-title">{t("policyModal.includedRatings")}:</span>{" "}
                {emptyPolicy(policy?.hotels?.rating?.included?.map(r => r.code)?.join(", "), "-")}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal.Container>
  ) : null;
};

export default PolicyModal;
