import React, { FC, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export type IButtonWithConfirmActions = Array<{
  byPassAction?: boolean;
  color: "success" | "warning" | "danger";
  handler: () => any;
  text: string;
}>;

interface IProps {
  byPass?: boolean;
  actions: IButtonWithConfirmActions;
  canBypass?: boolean;
  className?: string;
  disabled?: boolean;
  message?: string | ReactNode;
}

const ButtonWithConfirm: FC<IProps> = ({
  byPass = false,
  actions,
  children,
  className = "",
  disabled,
  message
}) => {
  const { t } = useTranslation();

  const [isActive, setActive] = useState(false);

  const mainButtonHandler = () => {
    if (byPass) {
      const mainAction = actions.find(a => a.byPassAction) || actions[0];
      mainAction.handler();
    } else {
      setActive(true);
    }
  };

  const actionButtonHandler = (handler: () => any) => {
    setActive(false);
    handler();
  };
  const closeScreen = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      {
        <button disabled={disabled} className={className} onClick={mainButtonHandler}>
          {children}
        </button>
      }
      {isActive && (
        <div className="modal-wrapper flex j-center a-center">
          <div className="confirm-box">
            {message && message.constructor === String ? <p>{message}</p> : message}
            {actions
              .filter(a => !a.byPassAction)
              .map(a => (
                <button className="confirm" onClick={() => actionButtonHandler(a.handler)} key={a.text}>
                  {a.text}
                </button>
              ))}
            <button className="cancel" onClick={closeScreen}>
              {t("generic.button.close")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ButtonWithConfirm;
