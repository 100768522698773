import { IconProp } from "@fortawesome/fontawesome-svg-core";
import isString from "lodash-es/isString";
import toString from "lodash-es/toString";
import unset from "lodash-es/unset";
import moment from "moment";
import { ICustomer } from "../interfaces/ICustomer";
import { IDestination } from "../interfaces/IFlight";
import { IHotel } from "../interfaces/IHotel";
import { IPassengerCount } from "../interfaces/ISearch";
import languageStorage from "./languageStorage";

export const orderByKey = (object: any) =>
  Object.keys(object)
    .sort()
    .reduce((result: any, key) => {
      result[key] = object[key];
      return result;
    }, {});

export const orderByKeyBasket = (flights: any, rooms: any) => {
  const ordered = orderByKey({ ...flights, ...rooms });
  const keys = Object.keys(ordered);
  if (
    keys.length > 1 &&
    keys[0].includes("room") &&
    keys[1].includes("flight") &&
    ordered[keys[0]].checkInDate === ordered[keys[1]].DepDate
  ) {
    const newOrdered = { ...ordered };
    unset(newOrdered, keys[0]);
    unset(newOrdered, keys[1]);
    return {
      [keys[1]]: ordered[keys[1]],
      [keys[0]]: ordered[keys[0]],
      ...newOrdered
    };
  }
  return ordered;
};

export const hasPolicy = (needle: any, haystack: any[], className?: string): string => {
  const bool = haystack.map(e => e.code || e).includes(needle.toString());
  if (bool && className) {
    return ` ${className}`;
  }

  return "";
};

export const priceParser = (price?: number | string, int = false) => {
  price = price ? price : 0;
  if (int) {
    return parseInt(price.toString()).toLocaleString("tr");
  }
  return parseFloat(price.toString()).toLocaleString("tr", {
    maximumFractionDigits: 2
  });
};

export const currencyParser = (code: string | undefined) => {
  switch (code) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "RUB":
      return "₽";
    case "CHF":
      return "₣";
    case undefined:
      return "-";
    default:
      return code;
  }
};

export const pwc = (price?: number | string, currency = "TRY", fractions = true) => {
  return `${priceParser(price, !fractions)} ${currencyParser(currency)}`;
};

export const searchMultipleKeywords = (object: { [key: string]: any }, keywords: string) => {
  const arrayed = keywords.split(" ").map(w => w.toLowerCase());
  const keys = Object.keys(object);

  return arrayed.every(word =>
    keys.some(k =>
      String(object[k])
        .toLowerCase()
        .includes(word)
    )
  );
};

export const imageExists = (url: string, callback: (exists: boolean) => void) => {
  const img = new Image();
  img.onload = () => callback(true);
  img.onerror = () => callback(false);
  img.src = url;
};

export const airlineLogo = (code: string) => {
  return `https://www.gstatic.com/flights/airline_logos/70px/${code}.png`;
};

export const timeParser = (time: string) => {
  return time.substring(0, time.length - 3);
};

export const capitalize = (str: string) => {
  // @ts-ignore
  return str.replace(/^\w/, c => c.toLocaleUpperCase("tr"));
};

export const getHour = (time: string) => {
  if (time.constructor === String && time.split(":").length > 0) {
    return parseInt(time.split(":")[0]);
  }
  return 0;
};

export const formatDate = (date = "") => {
  const dashed = date.split("-");
  if (dashed.length === 3) {
    return date;
  }

  const dotted = date.split(".");
  if (dotted.length === 3) {
    return date;
  }

  return date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6);
};

export const formatTime = (time = "") => {
  const splitted = time.split(":");

  return splitted[0] + ":" + splitted[1];
};

export const dayMonth = (date: string) => {
  return moment(date)
    .locale(languageStorage().current())
    .format("D MMMM");
};

export const day = (date: string) => {
  return formatDate(date).split("-")[2];
};

export const month = (date: string) => {
  return formatDate(date).split("-")[1];
};

export const year = (date: string) => {
  return formatDate(date).split("-")[0];
};

export const sanitizeString = (phrase: string) => {
  const maxLength = 100;
  let str = phrase.toLowerCase();
  // noinspection NonAsciiCharacters
  const charMap = {
    ç: "c",
    ö: "o",
    ü: "u",
    ğ: "g",
    ı: "i",
    ş: "s"
  };

  const rx = /([öçşığü])/g;

  if (rx.test(str)) {
    str = str.replace(rx, (m: string, key: string) => {
      return charMap[key];
    });
  }

  str = str.replace(/[^a-z\d\s-]/gi, "");
  str = str.replace(/[\s-]+/g, "");
  str.replace(/^\s+|\s+$/g, "");
  str = str.substring(0, str.length <= maxLength ? str.length : maxLength);
  str = str.replace(/\s/g, "");

  return str;
};

export const openInNewTab = (url: string) => {
  const a = document.createElement("a");
  const ev = document.createEvent("MouseEvents");
  a.href = url;

  ev.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, true, false, false, true, 0, null);
  a.dispatchEvent(ev);
};

export const uniqid = () => {
  return Math.random()
    .toString(36)
    .substring(7);
};

export const listCustomersName = (customers: ICustomer[]) => {
  return customers.map(cust => `${cust.firstname} ${cust.lastname} (${cust.passenger_type})`).join(", ");
};

// export const fixedDec = (input: number, decimal: number) => {
//   return parseFloat(input.toFixed(decimal));
// };

export const pnrTKey = (uri: string) => uri.substring(1).trim();

export const percent = (value: number, of: number, toStr = true) => {
  if (!of) {
    return "0%";
  }
  return Math.round((1000 * value) / of) / 10 + (toStr ? ("%" as any) : 0);
};

export const icon = (code: string): IconProp => {
  switch (code) {
    case "car":
      return ["fas", "car"];
    case "hotel":
      return ["fas", "home"];
    case "flight":
      return ["fas", "plane"];
    case "visa":
      return ["fas", "passport"];
    case "transfer":
      return ["fas", "taxi"];
    default:
      return ["fas", "info"];
  }
};

export const generateTabId = () =>
  Math.random()
    .toString(36)
    .substring(7);

export const findLocalItems = (query: string) => {
  const results: { [key: string]: any } = {};

  for (const i in localStorage) {
    if (localStorage.hasOwnProperty(i)) {
      if (i.includes(query)) {
        results[i] = safeStringToJSON(localStorage.getItem(i));
      }
    }
  }
  return results;
};

export const safeStringToJSON = (str: string | null) => {
  try {
    return JSON.parse(str as string);
  } catch (e) {
    return str;
  }
};

export const removeByWildcard = (query: string) => {
  for (const i in localStorage) {
    if (localStorage.hasOwnProperty(i)) {
      if (i.includes(query)) {
        localStorage.removeItem(i);
      }
    }
  }
};

export const hasParent = (element: any, classname: any): any => {
  if (isString(element.className) && element.className.split(" ").indexOf(classname) >= 0) {
    return true;
  }
  return element.parentNode && hasParent(element.parentNode, classname);
};

export const checkKeyCode = (event: KeyboardEvent, keyCode: number) => {
  // let code;
  // if (event.key !== undefined) {
  //   code = event.key;
  // } else if (event.keyIdentifier !== undefined) {
  //   code = event.keyIdentifier;
  // } else if (event.keyCode !== undefined) {
  //   code = event.keyCode;
  // }
  //

  return event.keyCode === keyCode;
};

export const validLanguage = (lang?: any) => {
  return ["tr", "en"].includes(toString(lang));
};

export const hotelMarker = (hotel: IHotel, selected?: IHotel) => {
  if (selected && selected.Latitude === hotel.Latitude) {
    if (hotel.source === "AGREEMENT") {
      return "/images/map/hotel-agreement-selected.png";
    } else {
      return "/images/map/hotel-selected.png";
    }
  } else {
    if (hotel.source === "AGREEMENT") {
      return "/images/map/hotel-agreement.png";
    } else {
      return "/images/map/hotel-other.png";
    }
  }
};

export const isMobile = () => {
  return window.innerWidth < 768;
};

export const stripHtmlTags = (html: string): string => {
  const el = document.createElement("div");
  el.innerHTML = html;
  return (el.textContent || el.innerText || "").trim();
};

export const countCustomers = (customers: ICustomer[]): IPassengerCount => {
  return customers.reduce(
    (count, cust) => {
      count[cust.passenger_type.toLowerCase()] = count[cust.passenger_type.toLowerCase()] + 1;
      return count;
    },
    { adt: 0, chd: 0, inf: 0 }
  );
};

export const safeJsonParse = <T>(str: any): T | undefined => {
  try {
    return JSON.parse(str);
  } catch {}
};

export const toAbsoluteInteger = (input: string) => {
  return parseInt(input.replace(/\D/g, ""));
};

export const elapsedTimeInMinutes = (destinations: IDestination[]) => {
  return destinations.reduce((total, dest) => {
    const times = dest.ElapsedTime.split(":");
    return parseInt(times[0]) * 60 + parseInt(times[1]) + total;
  }, 0);
};

// noinspection NonAsciiCharacters
const turkishCharacters = {
  Ç: "C",
  Ö: "O",
  Ş: "S",
  İ: "I",
  I: "i",
  Ü: "U",
  Ğ: "G",
  ç: "c",
  ö: "o",
  ş: "s",
  ı: "i",
  ü: "u",
  ğ: "g"
};

export const clrStr = (str: string) => {
  return str
    .split("")
    .map(x => turkishCharacters[x] ?? x)
    .join("")
    .toLowerCase();
};
