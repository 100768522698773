import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash-es/debounce";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import tooltip from "react-tooltip";
import { bindActionCreators, Dispatch } from "redux";
import { Busy } from "../../../components/snippets/Busy";
import { ICustomer, ICustomerRoom } from "../../../interfaces/ICustomer";
import { IPassengerCount } from "../../../interfaces/ISearch";
import { IStatus, IStoreState } from "../../../interfaces/IState";
import Modal from "../../../libs/Modal";
import { addItemToBasket, ItemTypes } from "../../../store/actions/basketActions";
import {
  assignRoomForCustomer,
  fetchCustomers,
  toggleCustomer
} from "../../../store/actions/customerActions";
import {
  setBasketIsPending,
  setCheckoutIsPending,
  toggleCheckoutScreen,
  toggleCustomerScreen
} from "../../../store/actions/uiActions";
import {
  getCustomerRooms,
  getCustomers,
  getCustomerStatus,
  getDifferenceCustomerPassenger
} from "../../../store/selectors/customerSelectors";
import {
  getBasketData,
  getBasketIsPending,
  getCheckoutIsPending
} from "../../../store/selectors/uiSelectors";
import AddCustomer from "../components/AddCustomer";
import Customer from "../components/Customer";
import PassengerCountMeter from "../components/PassengerCountMeter";

interface IStateProps {
  basketData: any;
  basketIsPending: boolean;
  checkoutIsPending: boolean;
  countDifference: IPassengerCount;
  customers: ICustomer[];
  rooms: ICustomerRoom[];
  status: IStatus;
}

interface IDispatchProps {
  actions: {
    addItemToBasket(item: ItemTypes, action: string): void;
    assignRoom(roomNo: number, custGuid: string): void;
    selectCustomer(customer: ICustomer): void;
    setBasketIsPending(payload: boolean): void;
    setCheckoutIsPending(payload: boolean): void;
    toggleCustomerScreen(switcher?: any): void;
    toggleCheckoutScreen(switcher?: any): void;
  };
}

function Customers({
  basketData,
  basketIsPending,
  checkoutIsPending,
  countDifference,
  customers,
  rooms,
  status,
  actions
}: IStateProps & IDispatchProps) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isBusy, setIsBusy] = useState(false);
  const [formIsActive, setFormIsActive] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();

  const bookingIsValid = useMemo(() => {
    return !countDifference.adt && !countDifference.chd && !countDifference.inf;
  }, [countDifference]);

  const fetchCustomersHander = useCallback(async () => {
    tooltip.rebuild();
    setIsBusy(true);
    await dispatch(fetchCustomers());
    setIsBusy(false);
  }, [dispatch]);

  useEffect(() => {
    fetchCustomersHander();
  }, [fetchCustomersHander]);

  const toggleAddCustomer = async (customer?: ICustomer) => {
    setFormIsActive(!formIsActive);
    if (customer) {
      setIsBusy(true);
      await dispatch(fetchCustomers());
      setIsBusy(false);
    }
    setSelectedCustomer(undefined);
  };

  const inputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    search(e.currentTarget.value);
  };

  const search = debounce(async (keyword: string) => {
    setIsBusy(true);
    await dispatch(fetchCustomers(keyword));
    setIsBusy(false);
  }, 500);

  const closeModal = () => {
    actions.toggleCustomerScreen(false);
    actions.setCheckoutIsPending(false);
  };

  const checkoutScreenHandler = () => {
    actions.toggleCustomerScreen(false);
    actions.toggleCheckoutScreen(true);
    actions.setCheckoutIsPending(false);
  };

  const basketHandler = () => {
    actions.toggleCustomerScreen(false);
    actions.addItemToBasket(basketData.item, basketData.action);
    actions.setBasketIsPending(false);
  };

  const selectCustomerHandler = (customer: ICustomer) => {
    setFormIsActive(true);
    setSelectedCustomer(customer);
  };

  return (
    <Modal.Container className="customers" onClose={closeModal}>
      <Modal.Header>
        <div className="flex full-width j-between a-center">
          {formIsActive ? (
            <span className="pointer" onClick={() => setFormIsActive(false)}>
              <FontAwesomeIcon size="2x" icon={["fas", "arrow-alt-circle-left"]} data-tip="Go Back" />
            </span>
          ) : (
            <span className="pointer" onClick={() => toggleAddCustomer()}>
              <FontAwesomeIcon size="2x" icon={["far", "user-plus"]} data-tip="Add Customer" />
            </span>
          )}
          <div className="customer-search">
            <input type="text" placeholder={t("customer.searchInput")} onChange={inputHandler} />
            <button>
              <FontAwesomeIcon icon={["far", "search"]} />
            </button>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body loading={status.fetching}>
        {isBusy && <Busy />}
        {formIsActive ? (
          <AddCustomer toggle={toggleAddCustomer} customer={selectedCustomer} />
        ) : (
          <>
            {(checkoutIsPending || basketIsPending) && <PassengerCountMeter count={countDifference} />}
            {customers.map(customer => (
              <Customer
                key={customer.cust_guid}
                onSelect={actions.selectCustomer}
                customer={customer}
                assignRoom={actions.assignRoom}
                rooms={rooms}
                selectEditCustomer={selectCustomerHandler}
              />
            ))}
          </>
        )}
      </Modal.Body>
      {!formIsActive && (
        <Modal.Footer>
          {checkoutIsPending ? (
            <button
              className="submit continue-booking"
              disabled={!bookingIsValid}
              onClick={checkoutScreenHandler}
            >
              {t("customer.continue")}
            </button>
          ) : basketIsPending ? (
            <button className="submit continue-booking" disabled={!bookingIsValid} onClick={basketHandler}>
              {t("customer.continue")}
            </button>
          ) : (
            <button className="submit" onClick={closeModal}>
              <FontAwesomeIcon icon={["fas", "check-circle"]} />
            </button>
          )}
        </Modal.Footer>
      )}
    </Modal.Container>
  );
}

const mapStateToProps = (state: IStoreState): IStateProps => ({
  basketData: getBasketData(state),
  basketIsPending: getBasketIsPending(state),
  checkoutIsPending: getCheckoutIsPending(state),
  countDifference: getDifferenceCustomerPassenger(state),
  customers: getCustomers(state),
  rooms: getCustomerRooms(state),
  status: getCustomerStatus(state)
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  actions: bindActionCreators(
    {
      addItemToBasket,
      assignRoom: assignRoomForCustomer,
      selectCustomer: toggleCustomer,
      setBasketIsPending,
      setCheckoutIsPending,
      toggleCheckoutScreen,
      toggleCustomerScreen
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
