import React from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { IActivity } from "../interfaces/IActivity";
import { selectActivity } from "../store/actions/selectedItemsActions";
import { pwc } from "../utils/helpers";

interface IProps {
  activities: IActivity[];
  selected: IActivity;
  settings?: {};
}

const defaultSettings = {
  arrows: false,
  autoplay: false,
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 3,
  slidesToShow: 3,
  speed: 500
};

const ActivityList = ({ activities, selected, settings }: IProps) => {
  const dispatch = useDispatch();

  return (
    <div className="slider-container room-slider left-align-slick">
      <Slider {...defaultSettings} {...settings}>
        {activities.map(
          (activity, index) =>
            activity && (
              <div key={index} className="slide">
                <div className={`room ${activity.OfferId === selected.OfferId ? " selected" : ""}`}>
                  <div className="caption" onClick={() => dispatch(selectActivity(activity))}>
                    <p className="type fs-small medium c-alternate">{activity.name}</p>
                    <p className="price fs-med">{pwc(activity.AmountAfterTax, activity.currency, false)}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </Slider>
    </div>
  );
};

export default ActivityList;
