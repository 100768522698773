import i18n from "i18next";
import entries from "lodash-es/entries";
import flatten from "lodash-es/flatten";
import uniq from "lodash-es/uniq";
import uniqBy from "lodash-es/uniqBy";
import { Dispatch } from "redux";
import { v5 as uuid } from "uuid";
import { ICustomer } from "../../interfaces/ICustomer";
import { ISearch, ISearchUI } from "../../interfaces/ISearch";
import Logger from "../../services/Logger";
import { AC, GetState, RecursivePartial } from "../../types";
import {
  SEARCH_INDEX_PUSH,
  SEARCH_INITIALIZE,
  SEARCH_MAP_CUSTOMER_TO_PASSENGER,
  SEARCH_UI_CONTROL,
  SEARCH_UPDATE
} from "../../utils/constants";
import {
  getCustomerCount,
  getCustomerParsedForSearchParam,
  getCustomerRoomCount
} from "../selectors/customerSelectors";
import { getLastSearch } from "../selectors/searchSelectors";
import { getServices } from "../selectors/serviceSelectors";
import { getCheckoutIsPending } from "../selectors/uiSelectors";
import { clearActivities, fetchActivities } from "./activityActions";
import { clearCars, fetchCars } from "./carActions";
import { changeFilterHotelsSortBy } from "./filterActions";
import { fetchAllFlights, resetFlightState } from "./flightActions";
import { clearHotels, fetchHotels } from "./hotelActions";

export const searchInitialize: AC = () => dispatch => {
  const customers = JSON.parse(localStorage.getItem("customers") || "[]");
  const history = JSON.parse(localStorage.getItem("history") || "[]");

  const passengerCount = customers.reduce(
    (result: any, c: ICustomer) => {
      result[c.customer_type.toLowerCase()]++;
      return result;
    },
    { adt: 0, chd: 0, inf: 0 }
  );

  dispatch(updateSearch(passengerCount));
  dispatch({
    payload: history,
    type: SEARCH_INITIALIZE
  });
};

export const searchIndex: AC = (search: ISearch) => dispatch => {
  dispatch({
    payload: search,
    type: SEARCH_INDEX_PUSH
  });
};

export const searchUIControl = (payload: Partial<ISearchUI>) => ({
  payload,
  type: SEARCH_UI_CONTROL
});

export const updateSearch = (payload: RecursivePartial<ISearch>) => ({
  payload,
  type: SEARCH_UPDATE
});

export const mapCustomersToPassenger = () => (dispatch: Dispatch<any>, getState: GetState) => {
  const state = getState();
  const customerCount = getCustomerCount(state);
  const roomCount = getCustomerRoomCount(state);
  const checkoutIsPending = getCheckoutIsPending(state);
  if (!checkoutIsPending) {
    dispatch({
      payload: [customerCount, roomCount],
      type: SEARCH_MAP_CUSTOMER_TO_PASSENGER
    });
  }
};

export const makeSearchWithCoords: AC = (payload: { lat: string; lon: string; name: string }) => (
  dispatch,
  getState
) => {
  const lastSearch = getLastSearch(getState());
  const search: ISearch = {
    ...lastSearch,
    ...payload,
    arrCity: "",
    depCity: "",
    dest: "",
    filters: {
      hasActivities: false,
      hasCars: false,
      hasFlights: false,
      hasHotels: true,
      hasPackages: false,
      hasTransport: false
    },
    hotel: null,
    orig: "",
    routes: [lastSearch.routes[0]]
  };
  search.guid = uuid(JSON.stringify(search) + Date.now(), uuid.URL);
  dispatch(makeSearch(search));
  dispatch(changeFilterHotelsSortBy("distance"));
};

export const makeSearchFromProgram: AC = (search: ISearch) => dispatch => {
  dispatch(clearHotels());
  dispatch(clearActivities());
  dispatch(clearCars());

  dispatch(searchIndex(search));
  dispatch(searchUIControl({ shrinked: true }));

  entries(search.filters).forEach(([filter, has]) => {
    if (has) {
      switch (filter) {
        case "hasActivities":
          dispatch(fetchActivities());
          break;
        case "hasHotels":
          dispatch(fetchHotels());
          break;
        case "hasCars":
          dispatch(fetchCars());
          break;
        case "hasFlights":
          dispatch(fetchAllFlights());
          break;
      }
    }
  });
};

export const makeSearch: AC = (search: ISearch) => (dispatch, getState) => {
  const state = getState();

  if (!searchIsValid(search)) {
    return;
  }
  const services = getServices(state);

  dispatch(clearHotels());
  dispatch(resetFlightState(services.flights));
  dispatch(clearActivities());
  dispatch(clearCars());

  search.guid = uuid(JSON.stringify(search) + Date.now(), uuid.URL);
  search.custguid = getCustomerParsedForSearchParam(state);
  search.rooms = uniqBy(search.custguid, "roomNo").length;

  dispatch(searchIndex(search));
  dispatch(searchUIControl({ shrinked: true }));

  if (search.filters.hasFlights) {
    dispatch(fetchAllFlights());
  }
  if (search.filters.hasHotels) {
    if (search.hotel && search.hotel.length) {
      const services = uniq(flatten(search.hotel.map(h => h.source))) as string[];
      dispatch(fetchHotels(services));
    } else {
      dispatch(fetchHotels());
    }
  }

  if (search.filters.hasActivities) {
    dispatch(fetchActivities());
  }

  if (search.filters.hasCars) {
    dispatch(fetchCars());
  }
};

function searchIsValid(search: ISearch): boolean {
  const errors: any = [];

  if (
    !search.filters.hasFlights &&
    !search.filters.hasHotels &&
    !search.filters.hasActivities &&
    !search.filters.hasCars
  ) {
    errors.push(i18n.t("search.error.flightOrHotel"));
  }
  if (search.adt + search.chd + search.inf === 0) {
    errors.push(i18n.t("search.error.passengerCount"));
  }
  if (!search.routes[0].dep && search.filters.hasFlights) {
    errors.push(i18n.t("search.error.depPoint"));
  }
  if (!search.routes[0].arr && search.hotel && !search.hotel.length) {
    errors.push(i18n.t("search.error.arrPoint"));
  }
  if (!search.routes[0].date) {
    errors.push(i18n.t("search.error.depEnterTime"));
  }
  if (!search.routes[0].rdate && (search.ftype === "RT" || search.filters.hasHotels)) {
    errors.push(i18n.t("search.error.arrLeaveTime"));
  }

  if (errors.length) {
    for (const error of errors) {
      Logger.flashError(error, {
        position: "bottom-left"
      });
    }
    return false;
  }

  return true;
}
