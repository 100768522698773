import { Reducer } from "redux";
import { IProgramState } from "../../interfaces/IState";
import {
  PROGRAM_FETCH_FULFILLED,
  PROGRAM_FETCH_PENDING,
  PROGRAM_FETCH_REJECTED,
  PROGRAM_SELECT
} from "../../utils/constants";

const initialState: IProgramState = {
  programs: [],
  selected: "",
  status: {
    fetched: false,
    fetching: false
  }
};

const programReducer: Reducer<IProgramState> = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAM_FETCH_PENDING:
      return {
        ...state,
        programs: [],
        status: {
          fetched: false,
          fetching: true
        }
      };
    case PROGRAM_FETCH_FULFILLED:
      return {
        ...state,
        agency: action.payload.agency,
        company: action.payload.company,
        programs: action.payload.programs,
        status: {
          fetched: true,
          fetching: false
        }
      };
    case PROGRAM_FETCH_REJECTED:
      return {
        ...state,
        programs: [],
        status: {
          error: true,
          fetched: true,
          fetching: false
        }
      };
    case PROGRAM_SELECT:
      return {
        ...state,
        selected: action.payload
      };
    default:
      return state;
  }
};

export default programReducer;
