import groupBy from "lodash-es/groupBy";
import { Reducer } from "redux";
import { IHotel, IHotelInfo, IRoom } from "../../interfaces/IHotel";
import { IHotelState } from "../../interfaces/IState";
import { Dictionary } from "../../types";
import {
  BOOKING_MAKE_FULFILLED,
  HOTEL_CLEAR,
  HOTEL_DETAILS_BATCH_FETCH,
  HOTEL_DETAILS_SINGLE_FETCH,
  HOTEL_FETCH_EMPTY,
  HOTEL_FETCH_FULFILLED,
  HOTEL_FETCH_PENDING,
  HOTEL_FETCH_REJECTED,
  HOTEL_ROOM_CHANGE,
  HOTEL_ROOM_FETCH_FULFILLED,
  HOTEL_ROOM_SELECT,
  HOTEL_ROOM_UPDATE
} from "../../utils/constants";

const initialState: IHotelState[] = [
  {
    details: {},
    hotels: [],
    key: "AMA",
    status: {
      fetched: false,
      fetching: false
    }
  }
];

const hotelReducer: Reducer<IHotelState[]> = (state = initialState, action) => {
  switch (action.type) {
    case HOTEL_CLEAR:
      const ns: IHotelState[] = [];
      action.payload.forEach((service: string) => {
        ns.push({
          details: {},
          hotels: [],
          key: service,
          status: {
            active: false,
            error: false,
            fetched: false,
            fetching: false
          }
        });
      });
      return ns;
    case `${action.service}_${HOTEL_FETCH_PENDING}`:
      return [...state].map(hotelState => {
        if (hotelState.key === action.service) {
          return {
            ...hotelState,
            status: {
              active: true,
              error: false,
              fetched: false,
              fetching: true
            }
          };
        }
        return hotelState;
      });

    case `${action.service}_${HOTEL_FETCH_FULFILLED}`:
      return hotelFetchFulfilled(state, action.payload, action.service);

    case `${action.service}_${HOTEL_DETAILS_BATCH_FETCH}`:
      return [...state].map(hotelState => {
        if (hotelState.key === action.service) {
          return {
            ...hotelState,
            details: action.payload.reduce((acc: Dictionary<IHotelInfo>, hotel: IHotelInfo) => {
              acc[hotel.HotelCode] = hotel;
              return acc;
            }, {})
          };
        }
        return hotelState;
      });

    case `${action.service}_${HOTEL_DETAILS_SINGLE_FETCH}`:
      return [...state].map(hotelState => {
        if (hotelState.key === action.service) {
          return {
            ...hotelState,
            details: {
              ...hotelState.details,
              [action.payload.HotelCode]: action.payload
            }
          };
        }
        return hotelState;
      });

    case `${action.service}_${HOTEL_FETCH_REJECTED}`:
      return [...state].map(hotelState => {
        if (hotelState.key === action.service) {
          return {
            ...hotelState,
            hotels: [],
            status: {
              ...hotelState.status,
              error: true,
              fetched: true,
              fetching: false
            }
          };
        }
        return hotelState;
      });

    case `${action.service}_${HOTEL_FETCH_EMPTY}`:
      return [...state].map(hotelState => {
        if (hotelState.key === action.service) {
          return {
            ...hotelState,
            hotels: [],
            status: {
              ...hotelState.status,
              error: false,
              fetched: true,
              fetching: false
            }
          };
        }
        return hotelState;
      });
    case HOTEL_ROOM_UPDATE:
      return [...state].map(hotelState => {
        if (hotelState.key === action.payload.source) {
          return {
            ...hotelState,
            hotels: hotelState.hotels.map(hotel => {
              if (hotel.HotelCode === action.payload.HotelCode) {
                return {
                  ...hotel,
                  rooms: hotel.rooms.map(room => {
                    if (room.OfferId === action.payload.OfferId) {
                      return {
                        ...room,
                        ...action.payload,
                        Address: "shkjahdlkjashdlkjahsakj"
                      };
                    }
                    return room;
                  })
                };
              }
              return hotel;
            })
          };
        }
        return hotelState;
      });
    case HOTEL_ROOM_SELECT:
      return hotelRoomSelect(state, action.payload.HotelCode, action.payload.OfferId);

    case HOTEL_ROOM_FETCH_FULFILLED:
      return hotelRoomFetchFulfilled(
        state,
        action.payload.rooms,
        action.payload.hotelCode,
        action.payload.source
      );
    case HOTEL_ROOM_CHANGE:
      return [...state].map(hotelState => {
        hotelState.hotels.map(hotel => {
          if (hotel.HotelCode === action.payload.HotelCode) {
            hotel.rooms = hotel.rooms.map(room => {
              if (room.OfferId === action.payload.OfferId) {
                return action.payload;
              }
              return room;
            });
          }
          hotel.groupedRooms = groupBy(hotel.rooms, "RoomType");
          return hotel;
        });
        return hotelState;
      });
    case BOOKING_MAKE_FULFILLED:
      return [...state].map(hotelState => ({
        ...hotelState,
        hotels: []
      }));
    default:
      return state;
  }
};

export default hotelReducer;

function hotelRoomSelect(state: IHotelState[], hotelCode: string, offerId: string) {
  return state.map(hs => ({
    ...hs,
    hotels: hs.hotels.map(h => {
      if (h.HotelCode === hotelCode) {
        return {
          ...h,
          rooms: h.rooms.map(r => {
            if (r.OfferId === offerId) {
              return {
                ...r,
                selected: true
              };
            }
            return {
              ...r,
              selected: false
            };
          })
        };
      }
      return h;
    })
  }));
}

function hotelRoomFetchFulfilled(state: IHotelState[], rooms: IRoom[], hotelCode: string, source: string) {
  return state.map(hs => {
    if (hs.key === source) {
      return {
        ...hs,
        hotels: hs.hotels.map(h => {
          if (h.HotelCode === hotelCode) {
            return {
              ...h,
              groupedRooms: groupBy(rooms, "RoomType"),
              rooms,
              roomsFetched: true
            };
          }
          return h;
        })
      };
    }
    return hs;
  });
}

function hotelFetchFulfilled(state: IHotelState[], payload: IHotel[], service: string): IHotelState[] {
  const hotels: IHotel[] = payload.map(h => {
    h.groupedRooms = {};
    if (h.rooms && h.rooms.length) {
      h.rooms[0].selected = true;
      h.groupedRooms = groupBy(h.rooms, "RoomType");
      h.roomsFetched = true;
    } else {
      h.rooms = [];
      h.roomsFetched = false;
    }
    return h;
  });

  return [...state].map(hotelState => {
    if (hotelState.key === service) {
      return {
        ...hotelState,
        hotels,
        status: {
          ...hotelState.status,
          error: false,
          fetched: true,
          fetching: false
        }
      };
    }
    return hotelState;
  });
}
