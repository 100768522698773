import i18n from "i18next";
import { batch } from "react-redux";
import { IBookingOptions, IPaymentRequest } from "../../interfaces/IBooking";
import { api } from "../../services/api";
import Logger from "../../services/Logger";
import { AC } from "../../types";
import {
  BASKET_CLEAR,
  BOOKING_MAKE_FULFILLED,
  BOOKING_MAKE_PENDING,
  BOOKING_MAKE_REJECTED,
  BOOKING_PAY_CASH_FULFILLED,
  BOOKING_PAY_CASH_NEED_CONFIRMATION,
  BOOKING_PAY_CASH_PENDING,
  BOOKING_PAY_CASH_REJECTED,
  BOOKING_PROVISION_FULFILLED,
  BOOKING_PROVISION_PENDING,
  BOOKING_PROVISION_REJECTED,
  BOOKING_RESET,
  UI_HOTEL_DETAILS_SCREEN_CLOSE
} from "../../utils/constants";
import { browserHistory } from "../../utils/history";
import { getBasketCustomers } from "../selectors/basketSelectors";
import { getActiveCustomers, getCustomerParsedForSearchParam } from "../selectors/customerSelectors";
import { getProvisionResults } from "../selectors/provisionSelectors";
import { closeCarsModal } from "./carActions";
import { closeTransfersModal } from "./transferActions";
import { resetState, toggleBasket } from "./uiActions";

export const pay: AC = (requestData: IPaymentRequest, options: IBookingOptions) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const customers = getActiveCustomers(state);
  let payUrl = "paycash";

  try {
    if (options.type === "basket") {
      requestData.basket_guid = requestData.offer_guid;
      delete requestData.offer_guid;
      await dispatch(provisionBasket(options));
    } else {
      delete requestData.basket_guid;
      await dispatch(provisionOffer(options));
    }
  } catch (e) {
    return;
  }

  dispatch({
    type: BOOKING_PAY_CASH_PENDING
  });

  const log = new Logger(i18n.t("booking.paymentPending"));

  switch (Object.keys(requestData.paymentType)[0]) {
    case "cash":
      payUrl = "paycash";
      break;
    case "vpos":
      payUrl = "paycard";
      break;
    case "guest":
      payUrl = "payguest";
      break;
  }

  api()
    .post(payUrl, {
      ...requestData,
      custlist: customers
    } as IPaymentRequest)
    .then(({ data }) => {
      if (data.gatewayUrl) {
        window.location = data.gatewayUrl;
        return;
      }
      if (!data.payment) {
        if (data.message === "pending") {
          log.success(i18n.t("booking.paymentConfirmation"));
          dispatch({
            type: BOOKING_PAY_CASH_NEED_CONFIRMATION
          });
          return;
        }
        log.error(i18n.t("booking.paymentError"));
        dispatch({
          type: BOOKING_PAY_CASH_REJECTED
        });
        return;
      }
      log.success(i18n.t("booking.paymentSuccess"));
      dispatch({
        type: BOOKING_PAY_CASH_FULFILLED
      });
      if (requestData.basket_guid) {
        dispatch(bookingBasket(options));
      } else if (requestData.offer_guid) {
        dispatch(bookingOffer(options));
      }
    })
    .catch(e => {
      log.error(e);
      dispatch({
        type: BOOKING_PAY_CASH_REJECTED
      });
    });
};

export const provisionOffer: AC = (payload: IBookingOptions) => async (dispatch, getState) => {
  const state = getState();
  const provisionResults = getProvisionResults(state);
  const custguid = getActiveCustomers(getState()).map(cust => ({ custguid: cust.cust_guid }));

  if (provisionResults[payload.offerid!]) {
    dispatch({
      type: BOOKING_PROVISION_PENDING
    });

    const log = new Logger(i18n.t("booking.provisionPending"));

    try {
      const res = await api().put("provision", provisionResults[payload.offerid!]);
      if (res.data.status) {
        log.success(i18n.t("booking.provisionSuccess"));
        dispatch({
          type: BOOKING_PROVISION_FULFILLED
        });
      } else {
        throw res;
      }
    } catch (e) {
      log.error(e);
      dispatch({
        type: BOOKING_PROVISION_REJECTED
      });
    }
  } else if (payload.type !== "hotel") {
    dispatch({
      type: BOOKING_PROVISION_PENDING
    });
    const log = new Logger(i18n.t("booking.provisionPending"));
    try {
      const res = await api().post("provision", { ...payload, custguid });
      if (res.data.status) {
        log.success(i18n.t("booking.provisionSuccess"));
        dispatch({
          type: BOOKING_PROVISION_FULFILLED
        });
      } else {
        throw res;
      }
    } catch (e) {
      log.error(e);
      dispatch({
        type: BOOKING_PROVISION_REJECTED
      });
    }
  }
};

export const provisionBasket: AC = (payload: IBookingOptions) => async (dispatch, getState) => {
  const customers = getBasketCustomers(getState());
  dispatch({
    type: BOOKING_PROVISION_PENDING
  });
  payload.basket_guid = payload.offerid;
  payload.custguid = customers;
  delete payload.offerid;
  const log = new Logger(i18n.t("booking.provisionPending"));
  try {
    const res = await api().post("provision", payload);
    if (res.data.status) {
      log.success(i18n.t("booking.provisionSuccess"));
      dispatch({
        type: BOOKING_PROVISION_FULFILLED
      });
    } else {
      throw res;
    }
  } catch (e) {
    log.error(e);
    dispatch({
      type: BOOKING_PROVISION_REJECTED
    });
  }
};

export const bookingOffer: AC = (payload: IBookingOptions) => async (dispatch, getState) => {
  const custguid = getCustomerParsedForSearchParam(getState());
  dispatch({
    type: BOOKING_MAKE_PENDING
  });
  payload.offer_guid = payload.offerid;
  const log = new Logger(i18n.t("booking.bookingPending"));
  try {
    const res = await api().post("offerbook", {
      ...payload,
      custguid
    });
    if (res.data.status) {
      log.success(`${i18n.t("booking.bookingSuccess")} Pnr: ${res.data.booking.pnr}`);
      batch(() => {
        dispatch({
          type: BOOKING_MAKE_FULFILLED
        });
        dispatch(resetState());
        dispatch({
          type: UI_HOTEL_DETAILS_SCREEN_CLOSE
        });
      });
      dispatch(closeCarsModal());
      dispatch(closeTransfersModal());
      browserHistory.push("/pnr-module?preload");
      localStorage.removeItem("lastPayment");
    } else {
      throw res;
    }
  } catch (e) {
    log.error(e);
    dispatch({
      type: BOOKING_MAKE_REJECTED
    });
  }
};

export const bookingBasket: AC = (payload: IBookingOptions) => dispatch => {
  dispatch({
    type: BOOKING_MAKE_PENDING
  });
  const log = new Logger(i18n.t("booking.bookingPending"));
  return api()
    .post("basketbook", payload)
    .then(res => {
      if (res.data.status) {
        log.success(`${i18n.t("booking.bookingSuccess")} Pnr: ${res.data.booking.pnr}`);
        dispatch({
          type: BOOKING_MAKE_FULFILLED
        });
        dispatch(resetState());
        dispatch({
          type: UI_HOTEL_DETAILS_SCREEN_CLOSE
        });
        dispatch(closeCarsModal());
        dispatch(closeTransfersModal());
        dispatch({
          type: BASKET_CLEAR
        });
        browserHistory.push(`/basket-module?basket_guid=${payload.basket_guid}`);
        dispatch(toggleBasket(false));
        localStorage.removeItem("lastPayment");
        return Promise.resolve();
      } else {
        log.error(res);
        dispatch({
          type: BOOKING_MAKE_REJECTED
        });
        return Promise.reject();
      }
    })
    .catch(e => {
      log.error(e);
      dispatch({
        type: BOOKING_MAKE_REJECTED
      });
      return Promise.reject();
    });
};

export const resetBooking = () => {
  return {
    type: BOOKING_RESET
  };
};

// export const clearAmadeus = (type: string) => (dispatch: Dispatch<any> /*, getState: GetState*/) => {
//   // const tabId = getTabId(getState());
//   if (type === "flight") {
//     // localStorage.removeItem(tabId + "_amadeusFlightCookie");
//     dispatch(clearFlights());
//   } else if (type === "hotel") {
//     // localStorage.removeItem(tabId + "_amadeusHotelCookie");
//     dispatch(clearAmadeusHotels());
//   }
//
//   dispatch(setCheckoutShouldBeClosed());
// };
