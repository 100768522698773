import classNames from "classnames";
import each from "lodash-es/each";
import filter from "lodash-es/filter";
import has from "lodash-es/has";
import isUndefined from "lodash-es/isUndefined";
import map from "lodash-es/map";
import sumBy from "lodash-es/sumBy";
import React, { useState } from "react";
import { ISaleFields, ISaleItem, ISaleParent } from "../interfaces/ISalesReport";
import ExpenseRows from "./ExpenseRow";
import ProgressBar from "./ProgressBar";

interface IProps {
  items: ISaleParent[];
  depth: number;
  fields: ISaleFields;
  visible?: boolean;
}

const ParentRows: any = ({ items, depth, fields, visible = true }: IProps) => {
  const [isActive, setIsActive] = useState({});

  const toggleRow = (index: number) => () => {
    setIsActive({
      ...isActive,
      [index]: !isActive[index]
    });
  };

  const cx = classNames(`depth-${depth}`, {
    hidden: !visible
  });

  return items.map((item, index) => (
    <React.Fragment key={index}>
      <tr onClick={toggleRow(index)} className={cx}>
        <td>
          {isActive[index] ? "▲" : "▼"} {item.info.desc}
        </td>
        {map(calculateFieldTotal(item.items, fields), (value, i) => (
          <td className="align-right" key={index + "-" + i}>
            {value}
          </td>
        ))}
        <td className="budget-cell">
          <ProgressBar
            currency={item.budget.currency}
            total={item.budget.total}
            remaining={item.budget.remaining}
          />
        </td>
      </tr>
      {isActive[index] && has(item, "items[0].items") ? (
        <ParentRows depth={depth + 1} items={item.items as ISaleParent[]} fields={fields} />
      ) : (
        isActive[index] && <ExpenseRows depth={depth + 1} expenses={item.items as ISaleItem[]} />
      )}
    </React.Fragment>
  ));
};

export default ParentRows;

function flatExpenses(data: any) {
  return filter(
    data.reduce((result: any, next: any) => {
      result.push(next);
      if (next.items && isUndefined(next.items.action_date) && next.items.length > 0) {
        result = result.concat(flatExpenses(next.items));
      }
      return result;
    }, []),
    "action_date"
  );
}

function calculateFieldTotal(items: any, fields: any) {
  const total: any = {};
  if (!items.length) {
    Object.keys(fields).forEach(key => {
      total[key] = null;
    });
    return total;
  }
  const flatted = flatExpenses([].concat(items));

  each(fields, (field, key) => {
    if (field.calculate) {
      total[key] = sumBy(flatted, key);
    } else if (field.isRecursive) {
      total[key] = flatted[0][key];
    } else {
      total[key] = null;
    }
  });
  return total;
}
