import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import map from "lodash-es/map";
import sum from "lodash-es/sum";
import uniq from "lodash-es/uniq";
import values from "lodash-es/values";
import React, { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICustomer } from "../interfaces/ICustomer";
import { icon, percent, pwc } from "../utils/helpers";

interface IProps {
  customers: ICustomer[];
  departmentSpent: number;
}

const ApproveBudget: FC<IProps> = ({ customers, departmentSpent }) => {
  const { t } = useTranslation();

  const departmentBudget = useMemo(() => {
    const departmentsBudgets = uniq(customers.map(c => c.policy!.budget.approved.department_limit));
    if (departmentsBudgets && departmentsBudgets.length) {
      return departmentsBudgets[0];
    }
    return 0;
  }, [customers]);

  const customerBudgets = useMemo(() => {
    return customers.map(c => ({
      budget: c.policy!.budget.pending.limit,
      name: `${c.firstname} ${c.lastname}, ${c.title}`,
      spent: {
        car: c.spent!.carAmount,
        flight: c.spent!.flightAmount,
        hotel: c.spent!.hotelAmount
      }
    }));
  }, [customers]);

  return (
    <div className="approve-budget pnr-items">
      <div className="budget-details">
        <div className="customer-budget">
          <h1 className="pnr-items-title">{t("offerApprove.budget.expenseTitle")}</h1>
          {customerBudgets.map(bud => (
            <Fragment key={bud.name}>
              <div className="until-spent">
                {map(bud.spent, (expense, service) => (
                  <p key={service}>
                    <FontAwesomeIcon icon={icon(service)} /> {pwc(expense, "TRY")}
                  </p>
                ))}
              </div>
              <div className="total-spent">
                <p>
                  {t("offerApprove.budget.userSepentTitle")} <span>{pwc(sum(values(bud.spent)), "TRY")}</span>
                </p>
              </div>
            </Fragment>
          ))}
        </div>
        <div className="separator" />
        <div className="department-budget">
          <h1 className="pnr-items-title">{t("offerApprove.budget.departmentTitle")}</h1>
          <p className="budget-summary">
            {pwc(departmentSpent, "TRY")}{" "}
            <span>
              {pwc(departmentBudget, "TRY")} {t("offerApprove.budget.totalBudget")}
            </span>
          </p>
          <div className="budget-bar">
            <i style={{ width: percent(departmentSpent, departmentBudget) }} />
            <span>{percent(departmentSpent, departmentBudget)}</span>
          </div>
          <p className="budget-summary">
            {pwc(departmentBudget - departmentSpent, "TRY")}{" "}
            <span>{t("offerApprove.budget.remainingBudget")}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApproveBudget;
