import classNames from "classnames";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  className?: string;
  disabled?: any[];
  selected: any;
  values: any[];
  onSwitch(value: any): void;
}

const MultiSwitch: FC<IProps> = ({ className = "", disabled = [], selected, values, onSwitch }) => {
  const { t } = useTranslation();

  const cx = classNames("multi-switch", {
    [className]: className
  });

  const cxButton = (v: any) =>
    classNames({
      selected: selected === v
    });

  return (
    <div className={cx}>
      {values.map(
        v =>
          !disabled.includes(v) && (
            <button key={v} className={cxButton(v)} onClick={() => onSwitch(v)}>
              {t(`paymentMethods.${v}`)}
            </button>
          )
      )}
    </div>
  );
};

export default MultiSwitch;
