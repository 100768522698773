import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPolicyBudget, IPolicyMoney } from "../../../../interfaces/IPolicy";
import FormRow from "../../../../libs/Form/FormRow";
import FormSection from "../../../../libs/Form/FormSection";
import PolicyApproved from "./PolicyApproved";
import PolicyPending from "./PolicyPending";

interface IProps {
  policy: IPolicyBudget;

  onChange(payload: { data: IPolicyMoney; key: string }): void;
}

const PolicyBudgets: FC<IProps> = ({ policy, onChange }) => {
  const { t } = useTranslation();
  const updatePending = (budget: IPolicyMoney) => {
    onChange({
      data: budget,
      key: `budget.pending`
    });
  };

  const updateApproved = (budget: IPolicyMoney) => {
    onChange({
      data: budget,
      key: `budget.approved`
    });
  };

  return policy ? (
    <FormRow title={t("admin.policy.budgetTitle")}>
      <FormSection column={1}>
        <PolicyApproved
          title={t("policyModal.approved")}
          onChange={updateApproved}
          budget={policy.approved}
        />
      </FormSection>
      <FormSection column={2}>
        <PolicyPending title={t("policyModal.pending")} onChange={updatePending} budget={policy.pending} />
      </FormSection>
    </FormRow>
  ) : null;
};

export default PolicyBudgets;
