import get from "lodash-es/get";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickDetect from "../../hooks/useClickDetect";
import { IAirport } from "../../interfaces/IAirport";
import { IDestinationUpdate, IRoute } from "../../interfaces/ISearch";
import { findAirportByCode } from "../../utils/airportFunctions";
import AirportPicker from "./AirportPicker";
import HotelPicker from "./HotelPicker";
import { SearchContext } from "./Search";

interface IProps {
  direction: "arr" | "dep";
  focus?: number;
  id?: number;
  last?: boolean;
  route: IRoute;

  onDestSelect?(payload: IDestinationUpdate): void;

  onSelect?(route: IRoute, isLast?: boolean): void;
}

const Location = ({ direction, focus, id, last, route, onDestSelect, onSelect }: IProps) => {
  const $el = useClickDetect(() => setInputIsActive(false));
  const { t } = useTranslation();

  const [search] = useContext(SearchContext);

  const $input = useRef<HTMLInputElement>(null);
  const [inputIsActive, setInputIsActive] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (inputIsActive && $input.current) {
      $input.current.focus();
    }
  }, [inputIsActive]);

  useEffect(() => {
    if (!search.name && focus === id && route[direction] === "") {
      setInputIsActive(true);
    }
  }, [direction, focus, id, route, search.name]);

  const selectDest = (payload: IDestinationUpdate) => {
    setInputValue("");
    setInputIsActive(false);

    if (!payload) {
      return;
    }

    if (payload.mode === "flight") {
      payload = {
        ...payload,
        params: {
          hotel: [],
          lat: undefined,
          lon: undefined,
          name: undefined,
          routes: [
            {
              ...route,
              ...payload.params.routes[0]
            }
          ]
        }
      };
    } else {
      payload.params.arrCity = null;
      payload.params.dest = null;
    }

    payload.name = get(payload, "params.hotel[0].name", false)
      ? payload.params.hotel[0].name
      : payload.params.name
      ? payload.params.name
      : undefined;

    onDestSelect!(payload);
  };

  const selectAirport = (ap: IAirport) => {
    setInputValue("");
    if (!ap) {
      return;
    }
    route[direction] = ap.code;
    route[`${direction}MultiAirport`] = ap.multiAirport;
    setInputIsActive(false);
    onSelect!(route, last);
  };

  const airport = findAirportByCode(route[direction], route[`${direction}MultiAirport`]);

  return (
    <div ref={$el} className="airport-location">
      {inputIsActive ? (
        <input
          className="airport-location-input"
          ref={$input}
          type="text"
          onChange={e => setInputValue(e.currentTarget.value)}
          placeholder={direction === "arr" ? t("search.to") : t("search.from")}
        />
      ) : search.name ? (
        <p className="city-name" onClick={() => setInputIsActive(true)}>
          {search.name}
        </p>
      ) : route[direction] ? (
        <>
          <p className="city-name" onClick={() => setInputIsActive(true)}>
            {airport.state}
          </p>
          <p className="airport" onClick={() => setInputIsActive(true)}>
            {airport.name} / {airport.code}
          </p>
        </>
      ) : (
        <p className="placeholder" onClick={() => setInputIsActive(true)}>
          {direction === "arr" ? t("search.to") : t("search.from")}
        </p>
      )}

      {onDestSelect ? (
        <HotelPicker value={inputValue} onSelect={selectDest} />
      ) : (
        <AirportPicker value={inputValue} onSelect={selectAirport} />
      )}
    </div>
  );
};

export default Location;
