import flattenDeep from "lodash-es/flattenDeep";
import isBoolean from "lodash-es/isBoolean";
import merge from "lodash-es/merge";
import values from "lodash-es/values";
import { Reducer } from "redux";
import { IAiritinprice, IFlight } from "../../interfaces/IFlight";
import { IUiState } from "../../types/IUiState";
import {
  UI_APP_LOADED,
  UI_BASKET_SET_DATA,
  UI_BASKET_SET_PENDING,
  UI_BASKET_TOGGLE,
  UI_CHANGE_THEME,
  UI_CHECKOUT_AMOUNT_DATA_UPDATE,
  UI_CHECKOUT_FLIGHT_DATA_UPDATE,
  UI_CHECKOUT_HOTEL_DATA_UPDATE,
  UI_CHECKOUT_SCREEN_TOGGLE,
  UI_CHECKOUT_SET_DATA,
  UI_CHECKOUT_SET_PENDING,
  UI_CHECKOUT_SET_SHOULD_CLOSE,
  UI_CLEAR_RESET,
  UI_CUSTOMER_SCREEN_TOGGLE,
  UI_HOTEL_DETAILS_SCREEN_CLOSE,
  UI_HOTEL_DETAILS_SCREEN_OPEN,
  UI_HOTEL_DETAILS_SCREEN_TOGGLE,
  UI_RESET
} from "../../utils/constants";

const initialState: IUiState = {
  appLoaded: false,
  resetted: false,
  screens: {
    basket: {
      active: false
    },
    checkoutScreen: {
      active: false
    },
    customersScreen: {
      active: false
    },
    hotelDetailScreen: {
      active: false
    }
  },
  theme: "dark"
};

const uiReducer: Reducer<IUiState> = (state = initialState, action) => {
  switch (action.type) {
    case UI_APP_LOADED:
      return merge({}, state, {
        appLoaded: true
      });

    case UI_HOTEL_DETAILS_SCREEN_OPEN:
      return merge({}, state, {
        screens: {
          hotelDetailScreen: {
            active: true,
            hotel: action.payload
          }
        }
      });

    case UI_HOTEL_DETAILS_SCREEN_CLOSE:
      return merge({}, state, {
        screens: {
          hotelDetailScreen: {
            active: false
          }
        }
      });

    case UI_HOTEL_DETAILS_SCREEN_TOGGLE:
      return merge({}, state, {
        screens: {
          hotelDetailScreen: {
            active: !state.screens.hotelDetailScreen.active,
            hotel: state.screens.hotelDetailScreen.active ? undefined : action.payload
          }
        }
      });
    case UI_BASKET_TOGGLE:
      return merge({}, state, {
        screens: {
          basket: {
            active:
              window.location.pathname !== "/"
                ? false
                : isBoolean(action.payload)
                ? action.payload
                : !state.screens.basket.active
          }
        }
      });
    case UI_CUSTOMER_SCREEN_TOGGLE:
      return merge({}, state, {
        screens: {
          basket: {
            pending: isBoolean(action.payload.pending)
              ? action.payload.pending
              : state.screens.checkoutScreen.pending
          },
          checkoutScreen: {
            pending: isBoolean(action.payload.pending)
              ? action.payload.pending
              : state.screens.checkoutScreen.pending,
            shouldClose: false
          },
          customersScreen: {
            active:
              window.location.pathname !== "/"
                ? false
                : isBoolean(action.payload.switcher)
                ? action.payload.switcher
                : !state.screens.customersScreen.active
          }
        }
      });
    case UI_CHECKOUT_SET_SHOULD_CLOSE:
      return {
        ...state,
        screens: {
          ...state.screens,
          checkoutScreen: {
            ...state.screens.checkoutScreen,
            shouldClose: true
          }
        }
      };
    case UI_CHECKOUT_SCREEN_TOGGLE:
      return merge({}, state, {
        screens: {
          checkoutScreen: {
            active:
              window.location.pathname !== "/"
                ? false
                : isBoolean(action.payload)
                ? action.payload
                : !state.screens.checkoutScreen.active,
            shouldClose: false
          }
        }
      });
    case UI_CHECKOUT_SET_PENDING:
      return merge({}, state, {
        screens: {
          basket: {
            pending: false
          },
          checkoutScreen: {
            pending: action.payload,
            shouldClose: false
          }
        }
      });
    case UI_CHECKOUT_SET_DATA:
      return {
        ...state,
        screens: {
          ...state.screens,
          basket: {
            active: false,
            item: undefined,
            pending: false
          },
          checkoutScreen: {
            active: action.payload.open,
            data: action.payload.data,
            pending: true,
            shouldClose: false
          }
        }
      };
    case UI_CHECKOUT_HOTEL_DATA_UPDATE:
      return {
        ...state,
        screens: {
          ...state.screens,
          checkoutScreen: {
            ...state.screens.checkoutScreen,
            data: {
              ...(state.screens.checkoutScreen.data as any),
              items: {
                ...state.screens.checkoutScreen.data!.items,
                hotels: state.screens.checkoutScreen.data!.items!.hotels!.map(hotel => {
                  if (hotel.OfferId === action.payload.OfferId) {
                    return {
                      ...hotel,
                      ...action.payload
                    };
                  }
                  return hotel;
                })
              }
            }
          }
        }
      };
    case UI_CHECKOUT_FLIGHT_DATA_UPDATE:
      return {
        ...state,
        screens: {
          ...state.screens,
          checkoutScreen: {
            ...state.screens.checkoutScreen,
            data: {
              ...(state.screens.checkoutScreen.data as any),
              items: {
                ...state.screens.checkoutScreen.data!.items,
                flights: state.screens.checkoutScreen.data!.items!.flights!.map(flight => {
                  if (flight.OfferId === action.payload.OfferId) {
                    const alt = state.screens.checkoutScreen.data?.selectedAlternative;
                    return {
                      ...flight,
                      ...action.payload,
                      airitinprice: alt
                        ? action.payload.alternatives.find(
                            (x: IAiritinprice) => alt.BrandCode === x.BrandCode
                          )
                        : action.payload.airitinprice
                    };
                  }
                  return flight;
                })
              }
            }
          }
        }
      };
    case UI_CHECKOUT_AMOUNT_DATA_UPDATE:
      return {
        ...state,
        screens: {
          ...state.screens,
          checkoutScreen: {
            ...state.screens.checkoutScreen,
            data: {
              ...(state.screens.checkoutScreen.data as any),
              amount: flattenDeep(values(state.screens.checkoutScreen.data!.items! as any)).reduce(
                (total: any, item: any) => {
                  if (item) {
                    if ((item as IFlight).airitin) {
                      total += (item as IFlight).airitinprice.TotalFare;
                    } else {
                      total += item.AmountAfterTax;
                    }
                  }
                  return total;
                },
                0
              )
            }
          }
        }
      };
    case UI_BASKET_SET_PENDING:
      return merge({}, state, {
        screens: {
          basket: {
            active: false,
            item: undefined,
            pending: action.payload
          },
          checkoutScreen: {
            pending: false,
            shouldClose: false
          }
        }
      });
    case UI_BASKET_SET_DATA:
      return {
        ...state,
        screens: {
          ...state.screens,
          basket: {
            action: action.payload.action,
            active: state.screens.basket.active,
            item: action.payload.item,
            pending: true
          },
          checkoutScreen: {
            active: false,
            data: undefined,
            pending: false,
            shouldClose: false
          }
        }
      };
    case UI_RESET:
      return {
        ...state,
        resetted: true,
        screens: {
          ...state.screens,
          basket: {
            action: undefined,
            active: false,
            item: undefined,
            pending: false
          },
          checkoutScreen: {
            active: false,
            data: undefined,
            pending: false,
            shouldClose: false
          },
          hotelDetailScreen: {
            active: false
          }
        }
      };

    case UI_CLEAR_RESET:
      return {
        ...state,
        resetted: false
      };

    case UI_CHANGE_THEME:
      return merge({}, state, { theme: state.theme === "dark" ? "light" : "dark" });
    default:
      return state;
  }
};

export default uiReducer;
