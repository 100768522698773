import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeCarsModal } from "../../store/actions/carActions";
import { getCarModalIsOpen } from "../../store/selectors/carSelectors";
import CarSearch from "./components/CarSearch";
import Cars from "./containers/Cars";
import "./styles/transport.scss";

type Props = {};

const style: Modal.Styles = {
  overlay: {
    backgroundColor: "var(--modalContainerBg)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content: {
    border: "1px solid var(--primaryText)",
    overflow: "visible",
    position: "relative",
    backgroundColor: "var(--primary)",
    padding: 20
  }
};

const CarModal: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(getCarModalIsOpen);

  const closeHandler = () => {
    dispatch(closeCarsModal());
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={style}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={closeHandler}
    >
      <div className="modal-title">
        <h1>{t("car.modalTitle")}</h1>
      </div>
      <div className="car-module">
        <button className="modal-close-button" onClick={closeHandler}>
          <FontAwesomeIcon icon={["far", "times"]} />
        </button>
        <CarSearch />
        <Cars />
      </div>
    </Modal>
  );
};

export default CarModal;
