import each from "lodash-es/each";
import React, { FC, useMemo } from "react";

interface IProps {
  data: { [key: string]: any };
}

const Qr: FC<IProps> = ({ data }) => {
  const parsedData = useMemo(() => {
    let str = "";
    each(data, (v, k) => {
      str = str + `${k}:%20${v}%0A`;
    });
    return str;
  }, [data]);

  return (
    <img
      src={`https://chart.googleapis.com/chart?chl=${parsedData}&cht=qr&chs=120x120&choe=utf-8&chld=L|0`}
      className="qrcode"
      alt="QR Code"
    />
  );
};

export default Qr;
