import { api } from "../../services/api";
import { AC } from "../../types";
import {
  ACCOUNTING_FETCH_EMPTY,
  ACCOUNTING_FETCH_FULFILLED,
  ACCOUNTING_FETCH_PENDING,
  ACCOUNTING_FILTERED_REPORTS_TOGGLE,
  ACCOUNTING_REPORT_SELECT,
  ACCOUNTING_SELECT_ALL_FILTERED_REPORTS,
  ACCOUNTING_SET_FILTER,
  ACCOUNTING_SET_ORDER,
  ACCOUNTING_UPDATE_REPORT
} from "../../utils/constants";
import { getAccountingFilteredReports, getAccountingSearchedReports } from "../selectors/accountingSelectors";

export const fetchAccountingReports: AC = requestPayload => dispatch => {
  dispatch({
    type: ACCOUNTING_FETCH_PENDING
  });
  if (requestPayload.type === "") {
    delete requestPayload.type;
  }

  api()
    .post("accpaymentlist", requestPayload)
    .then(res => {
      if (res.status === 204 || !res.data || !res.data.report || !res.data.report.length) {
        dispatch({
          type: ACCOUNTING_FETCH_EMPTY
        });
        return;
      }

      dispatch({
        payload: res.data,
        type: ACCOUNTING_FETCH_FULFILLED
      });
    });
};

export const setAccountingFilter: AC = (field: string, input?: string) => dispatch => {
  dispatch({
    field,
    input,
    type: ACCOUNTING_SET_FILTER
  });
};

export const setAccountingOrder: AC = (field: string) => dispatch => {
  dispatch({
    payload: field,
    type: ACCOUNTING_SET_ORDER
  });
};

export const selectAccountingReport: AC = (paymentKey: string) => dispatch => {
  dispatch({
    payload: paymentKey,
    type: ACCOUNTING_REPORT_SELECT
  });
};

export const updateReport: AC = (paymentKey: string, costInvoiceRef: string) => dispatch => {
  dispatch({
    payload: { paymentKey, costInvoiceRef },
    type: ACCOUNTING_UPDATE_REPORT
  });
};

export const toggleFilteredAccountingReports: AC = () => (dispatch, getState) => {
  const state = getState();
  let payload;

  if (state.accounting.selected.length !== getAccountingFilteredReports(state).length) {
    payload = getAccountingFilteredReports(state);
  }

  dispatch({
    payload,
    type: ACCOUNTING_FILTERED_REPORTS_TOGGLE
  });
};

export const selectAllFilteredReports: AC = () => (dispatch, getState) => {
  const state = getState();
  const filteredReports = getAccountingSearchedReports(state);
  dispatch({
    payload: filteredReports,
    type: ACCOUNTING_SELECT_ALL_FILTERED_REPORTS
  });
};
