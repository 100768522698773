import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import flatten from "lodash-es/flatten";
import merge from "lodash-es/merge";
import pick from "lodash-es/pick";
import React, { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ISearch } from "../../interfaces/ISearch";
import ProgramScreen from "../../modules/programScreen";
import { makeSearch, searchUIControl } from "../../store/actions/searchActions";
import { clearReset, toggleCustomerScreen } from "../../store/actions/uiActions";
import {
  getActiveCustomers,
  getCustomerCount,
  getCustomerRoomCount
} from "../../store/selectors/customerSelectors";
import { getSearching } from "../../store/selectors/flightSelectors";
import { getIsShrinked } from "../../store/selectors/searchSelectors";
import { getHasServices } from "../../store/selectors/serviceSelectors";
import { getIsResetted } from "../../store/selectors/uiSelectors";
import { RecursivePartial } from "../../types";
import { defaultSearchState } from "../../utils/defaults";
import Loading from "../Loading";
import AddRoute from "./AddRoute";
import Checkbox from "./Checkbox";
import Counters from "./Counters";
import CrossSellSection from "./CrossSellSection";
import CustomerList from "./CustomerList";
import Dropdown from "./Dropdown";
import SearchHistory from "./SearchHistory";
import SearchRoutes from "./SearchRoutes";

export const SearchContext = createContext<
  [ISearch, (changes: RecursivePartial<ISearch>, replace?: boolean) => void]
>(null as any);

const Search: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector(getActiveCustomers);
  const customerCount = useSelector(getCustomerCount);
  const roomCount = useSelector(getCustomerRoomCount);
  const shrinked = useSelector(getIsShrinked);
  const searching = useSelector(getSearching);
  const services = useSelector(getHasServices);
  const [search, setSearch] = useState<ISearch>(defaultSearchState);
  const isResetted = useSelector(getIsResetted);

  useEffect(() => {
    return () => setSearch(defaultSearchState);
  }, []);

  const dropdowns = useMemo(
    () => ({
      ftype: [
        {
          name: t("search.ow"),
          value: "OW"
        },
        {
          name: t("search.rt"),
          value: "RT"
        },
        {
          name: t("search.owc"),
          value: "OWC"
        },
        {
          name: t("search.mc"),
          value: "MC"
        }
      ],
      type: [
        {
          name: t("search.economy"),
          value: "ECONOMY"
        },
        {
          name: t("search.business"),
          value: "BUSINESS"
        }
      ]
    }),
    [t]
  );

  useEffect(() => {
    if (customers.length) {
      const flightTypes = flatten(
        customers.map(cust => (cust.policy ? cust.policy.flights.type : []))
      ).reduce(
        (acc, policy) => {
          if (!acc.excluded) {
            acc.excluded = [];
          }

          if (!acc.included) {
            acc.included = [];
          }
          acc.excluded = [...acc.excluded, ...policy.excluded.map(exc => exc.code)];
          acc.included = [...acc.included, ...policy.included.map(inc => inc.code)];
          return acc;
        },
        {} as {
          included: string[];
          excluded: string[];
        }
      );
      setSearch(x => ({
        ...x,
        type:
          flightTypes && flightTypes.included && flightTypes.included.includes("BUSINESS")
            ? "BUSINESS"
            : "ECONOMY"
      }));
    } else {
      setSearch(x => ({
        ...x,
        type: "ECONOMY"
      }));
    }
  }, [customers]);

  useEffect(() => {
    setSearch(x => ({ ...x, rooms: roomCount }));
  }, [roomCount]);

  useEffect(() => {
    setSearch(x => ({ ...x, ...customerCount }));
  }, [customerCount]);

  useEffect(() => {
    if (isResetted) {
      setSearch(defaultSearchState);
      dispatch(clearReset());
    }
  }, [dispatch, isResetted]);

  const updateSearch = (changes: RecursivePartial<ISearch>, replace?: boolean) => {
    if (replace) {
      setSearch(changes as ISearch);
    } else {
      setSearch(merge({ ...search }, changes));
    }
  };

  const unshrink = useCallback(() => {
    if (shrinked) {
      dispatch(searchUIControl({ shrinked: false }));
    }
  }, [dispatch, shrinked]);

  const submit = () => {
    if (!searching) {
      dispatch(makeSearch(search));
    }
  };

  return (
    <SearchContext.Provider value={[search, updateSearch]}>
      <div
        className={cx("search-area", {
          "is-shrinked": shrinked
        })}
      >
        <CrossSellSection />
        <div className="search-body" onClick={unshrink}>
          <div className="options">
            <div className="text fs-hug add-user flex align-center">
              <SearchHistory />
              <ProgramScreen />
              <button
                className="pointer c-primary"
                onClick={() => dispatch(toggleCustomerScreen(true, false))}
              >
                <FontAwesomeIcon icon={["far", "user-plus"]} />
              </button>
            </div>
            <div className="flex wrap selectors">
              <Dropdown choices={dropdowns.type} propKey="type" value={search.type} />
              <Dropdown choices={dropdowns.ftype} propKey="ftype" value={search.ftype} />
              <Counters
                title={t("search.passenger")}
                propKeys={["adt", "chd", "inf", "rooms"]}
                values={pick(search, ["adt", "chd", "inf", "rooms"]) as any}
              />
              {services.flights && (
                <Checkbox
                  label={t("form.checkbox.flight")}
                  propKey="filters.hasFlights"
                  value={search.filters.hasFlights}
                />
              )}
              {services.hotels && (
                <Checkbox
                  label={t("form.checkbox.hotel")}
                  propKey="filters.hasHotels"
                  value={search.filters.hasHotels}
                  disabled={[search.multicity, search.ftype === "OWC"]}
                />
              )}
              {services.activities && (
                <Checkbox
                  label={t("form.checkbox.activity")}
                  propKey="filters.hasActivities"
                  value={search.filters.hasActivities}
                />
              )}
              {/*{services.cars && (*/}
              {/*  <Checkbox*/}
              {/*    label={t("form.checkbox.car")}*/}
              {/*    propKey="filters.hasCars"*/}
              {/*    value={search.filters.hasCars}*/}
              {/*  />*/}
              {/*)}*/}
              {services.flights && services.hotels && (
                <Checkbox
                  label={t("form.checkbox.smart")}
                  propKey="filters.hasPackages"
                  value={search.filters.hasPackages}
                  disabled={[search.multicity]}
                />
              )}
            </div>
          </div>
          <CustomerList customers={customers} />
          <SearchRoutes />
        </div>
        {!shrinked ? (
          <div className="search-footer flex j-between">
            {search.ftype === "MC" && search.routes.length < 4 && <AddRoute />}
            <div className="fill-space" />
            <button id="main-search-button" className="submit" onClick={submit} disabled={searching}>
              {searching ? <Loading /> : <FontAwesomeIcon icon={["far", "search"]} />}
            </button>
          </div>
        ) : (
          <div className="search-footer flex j-end">
            <button onClick={unshrink} className="unshrink-button">
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </button>
          </div>
        )}
      </div>
    </SearchContext.Provider>
  );
};

export default Search;
