import keys from "lodash-es/keys";
import map from "lodash-es/map";
import sumBy from "lodash-es/sumBy";
import values from "lodash-es/values";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IReportField } from "../interfaces/IAccounting";
import { Dictionary } from "../types";

type Props = {
  fields: Dictionary<IReportField>;
  reports: any;
  total: boolean;
};

const ExportTable: FC<Props> = ({ fields, reports, total }) => {
  const { t } = useTranslation();

  return fields && values(fields).length && reports ? (
    <table id="export-table">
      <thead>
        <tr>
          {map(fields, (options, field) => (
            <th className={options.title === "commission" ? "commission" : ""} key={field}>
              {options.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {reports.map((report: any, index: number) => (
          <tr key={index}>
            {map(fields, (options, field) => (
              <td className={field === "commission" ? "commission" : ""} key={index + field}>
                &nbsp;{report[field]}
              </td>
            ))}
          </tr>
        ))}
        {total && (
          <tr className="total-row">
            {keys(fields).map((field, index) => {
              if (index === 0) {
                return (
                  <td key={field} colSpan={2}>
                    {reports.length} {t("report.rows")}
                  </td>
                );
              }

              if (
                index > 1 &&
                ["markup", "commission", "total_amount", "tax", "surcharge", "service_fee", "fare"].includes(
                  field
                )
              ) {
                return <td key={field}>&nbsp;{sumBy(reports, field).toFixed(2)}</td>;
              }
              return <td key={field} />;
            })}
          </tr>
        )}
      </tbody>
    </table>
  ) : null;
};

export default ExportTable;
