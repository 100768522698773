import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import { ICar } from "../interfaces/ICar";
import { selectCar } from "../store/actions/selectedItemsActions";
import { pwc } from "../utils/helpers";

interface IProps {
  cars: ICar[];
  selected: ICar;
  settings?: {};
}

const defaultSettings = {
  arrows: false,
  autoplay: false,
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 3,
  slidesToShow: 3,
  speed: 500
};

const CarList = ({ cars, selected, settings }: IProps) => {
  const dispatch = useDispatch();

  const selectHandler = useCallback(
    (car: ICar) => () => {
      dispatch(selectCar(car));
    },
    [dispatch]
  );

  return (
    <div className="slider-container room-slider left-align-slick">
      <Slider {...defaultSettings} {...settings}>
        {cars.map(
          (car, index) =>
            car && (
              <div key={index} className="slide">
                <div className={`room ${car.OfferId === selected.OfferId ? " selected" : ""}`}>
                  <div className="caption" onClick={selectHandler(car)}>
                    <p className="type fs-small medium c-alternate">
                      {car.ModelName}
                      {/*<br />*/}
                      {/*{toUpper(parseType(car))}*/}
                    </p>
                    <p className="price fs-med">{pwc(car.Amount, car.Currency, false)}</p>
                  </div>
                </div>
              </div>
            )
        )}
      </Slider>
    </div>
  );
};

export default CarList;
