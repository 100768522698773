import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parse } from "query-string";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import PageFooter from "../components/PageFooter";
import { Busy } from "../components/snippets/Busy";
import useClickDetect from "../hooks/useClickDetect";
import useTextField from "../hooks/useTextField";
import { api } from "../services/api";
import Logger from "../services/Logger";
import { uniqid, validLanguage } from "../utils/helpers";
import languageStorage from "../utils/languageStorage";

const captchaCode = uniqid();

const ConfirmBookingPage: FC<RouteComponentProps> = ({ location }) => {
  const { t, i18n } = useTranslation();
  const $langSelect = useClickDetect(() => setMenuIsActive(false));

  const [menuIsActive, setMenuIsActive] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [success, setSuccess] = useState(false);
  const captcha = useTextField();
  const [html, setHtml] = useState();

  const changeLanguage = useCallback(
    (newLang: string) => {
      i18n.changeLanguage(newLang).then(() => languageStorage().save(newLang));
      setMenuIsActive(false);
    },
    [i18n]
  );

  useEffect(() => {
    const { lang } = parse(location.search);
    if (validLanguage(lang)) {
      changeLanguage(lang as string);
    }
  }, [changeLanguage, location.search]);

  const confirmButton = async () => {
    setIsBusy(true);
    try {
      const { key } = parse(location.search);
      const { data } = await api().get("confirmbooking", {
        params: { key }
      });
      if (data.includes("null") || data.includes("error")) {
        Logger.flashError(t("hotelActionPage.confirmBooking.error"));
      } else {
        setHtml(data);
        Logger.flash(t("hotelActionPage.confirmBooking.success"));
        setSuccess(true);
      }
    } catch {
      Logger.flashError(t("hotelActionPage.confirmBooking.error"));
    }
    setIsBusy(false);
  };

  const disabled = useMemo(() => success || isBusy || captchaCode !== captcha.value, [
    isBusy,
    captcha.value,
    success
  ]);

  return (
    <div className="login-page">
      <div className="login-page-container">
        <div className="booking-confirm">
          {isBusy && <Busy />}
          <div className="booking-header">
            <h1>{t("hotelActionPage.titleConfirm")}</h1>
          </div>
          {success ? (
            <div className="text success-message" dangerouslySetInnerHTML={{ __html: html! }} />
          ) : (
            <div className="input-group">
              <p>
                <span className="bold">{t("hotelActionPage.code")}:</span> {captchaCode}
              </p>
              <input type="text" {...captcha} placeholder={t("hotelActionPage.captcha")} />
            </div>
          )}
          {success ? (
            <div>
              <FontAwesomeIcon icon={["far", "check"]} />
            </div>
          ) : (
            <div className="flex full-width">
              <button disabled={disabled} className="confirm" onClick={confirmButton}>
                {t("hotelActionPage.button.ok")}
              </button>
            </div>
          )}
          <div className="lang-select pointer no-margin-bottom no-margin-left" ref={$langSelect}>
            <div className="lang">
              <FontAwesomeIcon icon={["fas", "globe"]} />
              <p onClick={() => setMenuIsActive(!menuIsActive)}>
                {i18n.language === "tr" ? "Türkçe" : "English"}
              </p>
              <FontAwesomeIcon icon={["far", "chevron-down"]} />
            </div>
            {menuIsActive && (
              <div className="lang-dropdown">
                <p onClick={() => changeLanguage("en")}>English</p>
                <p onClick={() => changeLanguage("tr")}>Türkçe</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default ConfirmBookingPage;
