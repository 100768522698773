import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "../store/selectors/uiSelectors";

const PageFooter: FC = () => {
  const theme = useSelector(getTheme);
  return (
    <div className="page-footer">
      <div className="footer-container">
        <p>All Rights Reserved &copy; 2019, Trippep</p>
        <img src={`/images/trippep-${theme}.png`} alt="" />
      </div>
    </div>
  );
};

export default PageFooter;
