import moment from "moment";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ICar } from "../interfaces/ICar";
import { ICustomer } from "../interfaces/ICustomer";

interface IProps {
  customers: ICustomer[];
  cars: ICar[];
  pnr: string;
}

const VoucherCar: FC<IProps> = ({ customers, cars = [], pnr }: IProps) => {
  const { t } = useTranslation();

  const allGuests = useMemo(() => {
    return customers.map(c => c.firstname + " " + c.lastname);
  }, [customers]);

  return (
    <div className="hotel info">
      <div className="title">
        <img src="images/voucher/car.png" alt="hotel" />
        <h3>{t("generic.title.carInfo")}</h3>
      </div>
      {cars.map((car, index) => (
        <div key={index} className="caption">
          <div className="top-caption">
            <p>{car.title}</p>
            <p>
              <span className="is-bold">Date:</span>
              {moment(car.StartDate, "YYYY-MM-DD").format("D MMMM YYYY")} -{" "}
              {moment(car.EndDate, "YYYY-MM-DD").format("D MMMM YYYY")}
            </p>
          </div>
          <div className="top-caption">
            {car.PickupAddress && (
              <p>
                <span className="is-bold">{t("voucher.car.pickupAddress")}:</span>
                <br />
                {car.PickupName} <br /> {car.PickupAddress}
              </p>
            )}
            {car.DropoffAddress && (
              <p>
                <span className="is-bold">{t("voucher.car.dropoffAddress")}:</span>
                <br />
                {car.DropoffName} <br /> {car.DropoffAddress}
              </p>
            )}
          </div>
          <p className="general">
            <span className="bold">PNR:</span> {pnr}
          </p>
          <table>
            <tbody>
              <tr>
                <td className="is-bold">{t("voucher.car.driver")}:</td>
                <td>{allGuests.join(", ")}</td>
                <td className="is-bold">{t("voucher.car.passengerCount")}:</td>
                <td>{car.PassengerQty}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.car.model")}:</td>
                <td>{car.ModelName}</td>
                <td className="is-bold">{t("voucher.car.category")}:</td>
                <td>{car.VehicleCategory}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.car.doorCount")}:</td>
                <td>{car.NumberOfDoor}</td>
                <td className="is-bold">{t("voucher.car.airCondition")}:</td>
                <td>{car.AirCondition ? t("generic.yes") : t("generic.no")}</td>
              </tr>
              <tr>
                <td className="is-bold">{t("voucher.paymentStatus")}:</td>
                <td>{car.payStatus || t("voucher.notSpecified")}</td>
                <td className="is-bold">{t("voucher.car.vendor")}:</td>
                <td className="voucher-logo">
                  {car.VendorLogo ? <img src={car.VendorLogo} alt={car.Vendor} /> : car.Vendor}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default VoucherCar;
