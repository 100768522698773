import AdminHomePage from "../pages/Admin/AdminHomePage";
import AdminHotelsPage from "../pages/Admin/AdminHotelsPage";
import AdminPoliciesPage from "../pages/Admin/AdminPoliciesPage";
import AgencyAccountingPage from "../pages/Agency/AgencyAccountingPage";
import AgencyCommissionPage from "../pages/Agency/AgencyCommissionPage";
import AgencyPage from "../pages/Agency/AgencyPage";
import AgencyProfitLossPage from "../pages/Agency/AgencyProfitLossPage";
import AgencyReconciliationPage from "../pages/Agency/AgencyReconciliationPage";
import AgencySalesPage from "../pages/Agency/AgencySalesPage";
import ApproveModulePage from "../pages/ApproveModulePage";
import BasketModulePage from "../pages/BasketModulePage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import CompanyCompliancePage from "../pages/Company/CompanyCompliancePage";
import CompanyExpenseBudgetPage from "../pages/Company/CompanyExpenseBudgetPage";
import CompanyPage from "../pages/Company/CompanyPage";
import CompanyReconciliationPage from "../pages/Company/CompanyReconciliationPage";
import CompanyStaffExpensesPage from "../pages/Company/CompanyStaffExpensesPage";
import ConfirmBookingPage from "../pages/ConfirmBookingPage";
import ConfirmCancelPage from "../pages/ConfirmCancelPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import PaymentErrorPage from "../pages/PaymentErrorPage";
import PnrModulePage from "../pages/PnrModulePage";
import ProgramModulePage from "../pages/ProgramModulePage";
import ProgramVoucherPage from "../pages/ProgramVoucherPage";
import RedirectPage from "../pages/RedirectPage";
import RejectBookingPage from "../pages/RejectBookingPage";
import VoucherPage from "../pages/VoucherPage";

export enum GuardType {
  protected = "protected",
  guest = "guest"
}

export interface IRouter {
  component: any;
  exact?: boolean;
  path: string;
  guard?: GuardType;
}

const routeList: IRouter[] = [
  {
    guard: GuardType.protected,
    component: HomePage,
    exact: true,
    path: "/"
  },
  {
    guard: GuardType.protected,
    component: AgencyPage,
    exact: true,
    path: "/agency"
  },
  {
    guard: GuardType.protected,
    component: AgencySalesPage,
    path: "/agency/sales"
  },
  {
    guard: GuardType.protected,
    component: AgencyReconciliationPage,
    path: "/agency/reconciliation"
  },
  {
    guard: GuardType.protected,
    component: AgencyCommissionPage,
    path: "/agency/commission"
  },
  {
    guard: GuardType.protected,
    component: AgencyAccountingPage,
    path: "/agency/accounting"
  },
  {
    guard: GuardType.protected,
    component: AgencyProfitLossPage,
    path: "/agency/profit-loss"
  },
  {
    guard: GuardType.protected,
    component: PnrModulePage,
    path: "/pnr-module"
  },
  {
    guard: GuardType.protected,
    component: BasketModulePage,
    path: "/basket-module"
  },
  {
    guard: GuardType.protected,
    component: ApproveModulePage,
    path: "/approve-module"
  },
  {
    guard: GuardType.protected,
    component: PaymentErrorPage,
    path: "/3dsecerror"
  },
  {
    guard: GuardType.protected,
    component: CompanyPage,
    exact: true,
    path: "/company"
  },
  {
    guard: GuardType.protected,
    component: CompanyExpenseBudgetPage,
    path: "/company/expense-budget"
  },
  {
    guard: GuardType.protected,
    component: CompanyCompliancePage,
    path: "/company/compliance/:country?/:city?"
  },
  {
    guard: GuardType.protected,
    component: CompanyReconciliationPage,
    path: "/company/reconciliation/:custguid?"
  },
  {
    guard: GuardType.protected,
    component: CompanyStaffExpensesPage,
    path: "/company/staff-expenses"
  },
  {
    component: VoucherPage,
    path: "/voucher"
  },
  {
    component: ChangePasswordPage,
    path: "/changepwd"
  },
  {
    guard: GuardType.guest,
    component: LoginPage,
    path: "/login/:token?"
  },
  {
    guard: GuardType.guest,
    component: RedirectPage,
    path: "/redirect/:token"
  },
  {
    component: ConfirmBookingPage,
    path: "/confirmbooking"
  },
  {
    component: RejectBookingPage,
    path: "/rejectbooking"
  },
  {
    component: ConfirmCancelPage,
    path: "/confirmcancel"
  },
  {
    guard: GuardType.protected,
    component: ProgramVoucherPage,
    path: "/travelprogram"
  },
  {
    guard: GuardType.protected,
    component: ProgramModulePage,
    path: "/program-module"
  },
  {
    guard: GuardType.protected,
    component: AdminHomePage,
    exact: true,
    path: "/admin"
  },
  {
    guard: GuardType.protected,
    component: AdminHotelsPage,
    path: "/admin/hotels/:guid?"
  },
  {
    guard: GuardType.protected,
    component: AdminPoliciesPage,
    path: "/admin/policies/:guid?"
  }
  // {
  //   guard: GuardType.protected,
  //   component: AdminRightsPage,
  //   path: "/admin/rights"
  // }
];

export default routeList;
