import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { FC } from "react";
import DateField from "../../../components/DateField";
import FieldWithErrorMessage from "../../../components/FieldWithErrorMessage";
import PhoneField from "../../../components/PhoneField";
import { api } from "../../../services/api";
import CustomerMiles from "./CustomerMiles";
import CustomerPassengertype from "./CustomerPassengerType";
import { ICustomer } from "../../../interfaces/ICustomer";
import { defaultCustomer } from "../../../utils/defaults";
import * as yup from "yup";
import merge from "lodash-es/merge";

const validationSchema = yup.object({
  birthdate: yup.string().required("Enter valid date"),
  customer_type: yup.string().required(),
  email: yup
    .string()
    .email("Enter valid email address"),
    // .required("Enter valid email address"),
  firstname: yup.string().required("Firstname is required"),
  lastname: yup.string().required("Lastname is required"),
  passenger_type: yup.string().required(),
  telephone: yup.string(),
    // .required("Enter valid phone number"),
  title: yup.string().required(),
  national_id: yup.string(),
    // .required("Social Security Number is required")
});

type Props = {
  customer?: ICustomer;
  toggle(customer?: ICustomer): void;
};

export const AddCustomer: FC<Props> = ({ customer, toggle }) => {
  const submit = async (cust: ICustomer, { setSubmitting }: FormikHelpers<ICustomer>) => {
    setSubmitting(true);
    try {
      if (cust.cust_guid) {
        await api().put("customer", cust);
      } else {
        await api().post("customer", cust);
      }
      toggle(cust);
    } catch {}
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={merge({}, defaultCustomer, customer)}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div className="form">
            <div className="form-group horizontal">
              <Field name="title" as="select">
                <option value="MR">MR</option>
                <option value="MS">MS</option>
                <option value="MRS">MRS</option>
              </Field>
              <FieldWithErrorMessage name="firstname" placeholder="Firstname" />
              <FieldWithErrorMessage name="lastname" placeholder="Lastname" />
            </div>
            <div className="form-group horizontal">
              <CustomerPassengertype
                names={["passenger_type", "customer_type"]}
                birthdate={values.birthdate}
              />
              <DateField
                name="birthdate"
                placeholder="Birth Date"
                inputFormat="DD.MM.YYYY"
                outputFormat="YYYYMMDD"
              />
              <FieldWithErrorMessage name="national_id" placeholder="Social Security Number" />
            </div>
            <div className="form-group horizontal">
              <FieldWithErrorMessage name="email" placeholder="Email" />
              <PhoneField name="telephone" initialCode="90" />
            </div>
            <div className="form-group">
              <CustomerMiles name="milesId" />
            </div>
            <div className="form-group">
              <button type="submit" className="submit modal-right-button" disabled={isSubmitting}>
                <FontAwesomeIcon icon={["fas", "check-circle"]} />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCustomer;
