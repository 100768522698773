import i18n from "i18next";
import { Reducer } from "redux";
import { IBookingState } from "../../interfaces/IState";
import {
  BOOKING_MAKE_FULFILLED,
  BOOKING_MAKE_PENDING,
  BOOKING_MAKE_REJECTED,
  BOOKING_PAY_CASH_FULFILLED,
  BOOKING_PAY_CASH_NEED_CONFIRMATION,
  BOOKING_PAY_CASH_PENDING,
  BOOKING_PAY_CASH_REJECTED,
  BOOKING_PROVISION_FULFILLED,
  BOOKING_PROVISION_PENDING,
  BOOKING_PROVISION_REJECTED,
  BOOKING_RESET
} from "../../utils/constants";

const initialState: IBookingState = {
  bookedItems: [],
  bookingRules: [],
  paidItems: [],
  status: {
    done: 0,
    loading: false,
    steps: []
  }
};

const bookingReducer: Reducer<IBookingState> = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_PROVISION_PENDING: {
      const provisionStep = {
        done: 0,
        index: 1,
        message: i18n.t("booking.provisionPending")
      };
      return {
        ...state,
        status: {
          done: 0,
          loading: true,
          steps: [provisionStep]
        }
      };
    }
    case BOOKING_PROVISION_FULFILLED:
      return {
        ...state,
        status: {
          done: 1,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 1) {
              return {
                done: 1,
                index: 1,
                message: i18n.t("booking.provisionSuccess")
              };
            }
            return step;
          })
        }
      };
    case BOOKING_PROVISION_REJECTED:
      return {
        ...state,
        status: {
          done: 2,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 1) {
              return {
                done: 2,
                index: 1,
                message: i18n.t("booking.provisionError")
              };
            }
            return step;
          })
        }
      };

    case BOOKING_PAY_CASH_PENDING: {
      const paymentStep = {
        done: 0,
        index: 2,
        message: i18n.t("booking.paymentPending")
      };
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          steps: [...state.status.steps, paymentStep]
        }
      };
    }
    case BOOKING_PAY_CASH_NEED_CONFIRMATION:
      return {
        ...state,
        status: {
          ...state.status,
          done: 1,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 2) {
              return {
                done: 1,
                index: 2,
                message: i18n.t("booking.paymenConfirmation")
              };
            }
            return step;
          })
        }
      };
    case BOOKING_PAY_CASH_FULFILLED: {
      return {
        ...state,
        status: {
          ...state.status,
          done: 0,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 2) {
              return {
                done: 1,
                index: 2,
                message: i18n.t("booking.paymentSuccess")
              };
            }
            return step;
          })
        }
      };
    }
    case BOOKING_PAY_CASH_REJECTED: {
      return {
        ...state,
        status: {
          ...state.status,
          done: 0,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 2) {
              return {
                done: 2,
                index: 2,
                message: i18n.t("booking.paymentError")
              };
            }
            return step;
          })
        }
      };
    }
    case BOOKING_MAKE_PENDING:
      const bookingStep = {
        done: 0,
        index: 3,
        message: i18n.t("booking.bookingPending")
      };
      return {
        ...state,
        status: {
          done: 0,
          loading: true,
          steps: [...state.status.steps, bookingStep]
        }
      };
    case BOOKING_MAKE_FULFILLED:
      return {
        ...state,
        status: {
          done: 1,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 3) {
              return {
                done: 1,
                index: 3,
                message: i18n.t("booking.bookingSuccess")
              };
            }
            return step;
          })
        }
      };
    case BOOKING_MAKE_REJECTED:
      return {
        ...state,
        status: {
          done: 2,
          loading: false,
          steps: state.status.steps.map(step => {
            if (step.index === 3) {
              return {
                done: 2,
                index: 3,
                message: i18n.t("booking.bookingError")
              };
            }
            return step;
          })
        }
      };
    case BOOKING_RESET:
      return {
        ...state,
        status: {
          done: 0,
          loading: false,
          steps: []
        }
      };
    default:
      return state;
  }
};

export default bookingReducer;
