import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IRoom } from "../interfaces/IHotel";
import Thumbnail from "../libs/Image/Thumbnail";
import { dayMonth, pwc } from "../utils/helpers";
import HotelDetailsModal from "./modals/HotelDetailsModal";
import Rating from "./snippets/Rating";

interface IProps {
  hotel: IRoom;
  diff?: number;
}

const ApproveHotel: FC<IProps> = ({ diff = 0, hotel }) => {
  const { t, i18n } = useTranslation();

  const [hotelDetailsIsActive, setHotelDetailsIsActive] = useState(false);

  return (
    <div className="flex approve-container">
      <HotelDetailsModal
        active={hotelDetailsIsActive}
        HotelCode={hotel.HotelCode!}
        source={hotel.source}
        onClose={() => setHotelDetailsIsActive(false)}
      />
      <div className="approve-icon">
        <FontAwesomeIcon icon={["fas", "home"]} />
      </div>
      <div className="route-items">
        <div className="pnr-flight flex a-center">
          <div className="content full-width">
            <div className="flex a-center j-between fill-space d-column-mobile">
              <p className="fs-lrg-m c-primary">
                {dayMonth(hotel.CheckInDate)}
                {" - "}
                {dayMonth(hotel.CheckOutDate)}
              </p>
              <span className="middot c-primary" />
              <span data-tip={hotel.HotelName}>
                <p className="fs-prc c-primary">{hotel.HotelName}</p>
                <Rating rating={hotel.Rating} />
              </span>
              <span className="middot c-primary" />
              <Thumbnail
                width={75}
                height={75}
                rounded
                gap={10}
                layout="background"
                image={hotel.HotelImageUrl}
                onClick={() => setHotelDetailsIsActive(true)}
              />
              <span className="fill-space" />
            </div>
          </div>
        </div>
        {hotel.compMessage && (
          <div className="compliances">
            <p className="c-orange bold">{t("offerApprove.hotel.compTitle")}</p>
            <p className="c-primary bold">
              <FontAwesomeIcon icon={["far", "user-edit"]} /> {hotel.compMessage[i18n.language]}
            </p>
          </div>
        )}
      </div>
      <div className="price-summary">
        <p className="fs-lrg-m c-primary">
          {t("offerApprove.hotel.done")}:
          <br /> <span className="bold">{pwc(hotel.AmountAfterTax, hotel.HotelCurrency)}</span>
        </p>
        <p className="fs-lrg-m c-primary">
          {t("offerApprove.hotel.minimumGape")}:
          <br /> <span className="bold">{pwc(diff, hotel.HotelCurrency)}</span>
        </p>
      </div>
      {/*<div className="hotel box is-relative no-margin">*/}
      {/*  {hotel.source === "AGREEMENT" && (*/}
      {/*    <div className="promotion" data-tip="Agreement">*/}
      {/*      <FontAwesomeIcon flip="horizontal" icon={["fas", "tag"]} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  <div className="card flex column-gutter">*/}
      {/*    <div className="image-container no-shrink">*/}
      {/*      {hotel.HotelImageUrl && (*/}
      {/*        <img className="image responsive" src={hotel.HotelImageUrl} alt="" style={{ width: "100px" }} />*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*    <div className="info fill-space">*/}
      {/*      <p className="name fs-prc">{hotel.HotelName}</p>*/}
      {/*      <Rating rating={hotel.Rating} />*/}
      {/*      <p className="fs-med">{hotel.Address}</p>*/}
      {/*      <p className="fs-med">T: {hotel.telephone}</p>*/}
      {/*      <p className="fs-med">E: {hotel.email}</p>*/}
      {/*      <p className="fs-med">Room Type: {hotel.RoomType}</p>*/}
      {/*      <p className="fs-med">Board: {hotel.Board}</p>*/}
      {/*      {hotel.isNonRefundable === true && <p className="danger fs-med bold">NON-REFUNDABLE</p>}*/}
      {/*      {hotel.isNonRefundable === false && <p className="success fs-lrg bold">REFUNDABLE</p>}*/}

      {/*      <p className="fs-note c-gray source-note">{hotel.source}</p>*/}
      {/*    </div>*/}
      {/*    <Dates dates={dates} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default ApproveHotel;
