import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ExportTable from "../ExportTable";

interface IProps {
  filename?: string;
  fields: any;
  reports: any;
  total: boolean;
}

const SpreadsheetButton: FC<IProps> = ({ filename, fields, reports, total = false }) => {
  const { t } = useTranslation();
  return (
    <>
      <ExportTable reports={reports} fields={fields} total={total} />
      <button className="export-excel" onClick={() => exportTableToExcel("export-table", filename)}>
        <FontAwesomeIcon icon={["far", "file-spreadsheet"]} />
        <span>{t("report.spreadsheet")}</span>
      </button>
    </>
  );
};

function exportTableToExcel(tableId: string, filename = "") {
  let downloadLink;
  const dataType = "data:application/vnd.ms-excel;charset=UTF-8";
  const table = document.getElementById(tableId)!;
  if (!table) {
    return;
  }
  const tbody = table.querySelector("tbody")!;

  let rows = Array.prototype.slice.call(table.getElementsByTagName("tr"));

  rows = rows.filter(row => row.className.includes("checked"));

  if (rows.length) {
    tbody.innerHTML = "";
    rows.forEach(r => tbody.appendChild(r));
  }

  const tableHTML = table.outerHTML.replace(/ /g, "%20");

  // Specify file name
  filename = filename ? filename + ".xls" : "excel_data.xls";

  // Create download link element
  downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob(["\ufeff", tableHTML], {
      type: dataType
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = "data:" + dataType + ", " + tableHTML;

    // Setting the file name
    downloadLink.download = filename;

    // triggering the function
    downloadLink.click();
  }
}

export default SpreadsheetButton;
