import uniqBy from "lodash-es/uniqBy";
import { createSelector } from "reselect";
import { IStoreState } from "../../interfaces/IState";

export const getServices = (state: IStoreState) => state.services.services;

export const getCarServices = createSelector(getServices, services =>
  uniqBy(
    services.cars.filter(s => s.uri),
    "key"
  )
);

export const getTransferServices = createSelector(getServices, services =>
  uniqBy(
    services.transfers.filter(s => s.uri),
    "key"
  )
);

export const getHotelServices = createSelector(getServices, services =>
  uniqBy(
    services.hotels.filter(s => s.uri),
    "key"
  )
);

export const getFlightServices = createSelector(getServices, services =>
  uniqBy(
    services.flights.filter(s => s.uri),
    "key"
  )
);

export const getActivityServices = createSelector(getServices, services =>
  uniqBy(
    services.activities.filter(s => s.uri),
    "key"
  )
);

export const getHasServices = createSelector(
  getCarServices,
  getHotelServices,
  getFlightServices,
  getActivityServices,
  (carServices, hotelServices, flightServices, activityServices) => ({
    activities: !!activityServices.length,
    cars: !!carServices.length,
    flights: !!flightServices.length,
    hotels: !!hotelServices.length
  })
);
