import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldArray, useFormikContext } from "formik";
import React, { FC } from "react";
import { useRouteMatch } from "react-router";
import { IAgreementRoom } from "../../../interfaces/IHotel";
import { defaultAgreementRoom } from "../../../utils/defaults";
import AgreementRoomField from "./AgreementRoomField";

type Props = {};

const AgreementHotelEditRooms: FC<Props> = () => {
  const match = useRouteMatch<{ guid: string }>();
  const { values } = useFormikContext<{ rooms: IAgreementRoom[] }>();

  return (
    <FieldArray name="rooms">
      {helpers => (
        <div className="form-section">
          {values.rooms?.map((room, idx) => (
            <AgreementRoomField index={idx} onRemove={() => helpers.remove(idx)} key={idx} />
          ))}
          <div className="form-group">
            <button
              className="add-form-item"
              onClick={() =>
                helpers.push({ ...defaultAgreementRoom, agreement_guid: match.params.guid, fresh: true })
              }
            >
              <FontAwesomeIcon icon={["far", "plus"]} />
            </button>
          </div>
        </div>
      )}
    </FieldArray>
  );
};

export default AgreementHotelEditRooms;
