import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import AppLoading from "./components/AppLoading";
import CheckoutStandAlone from "./components/checkoutModal/CheckoutStandAlone";
import CarModal from "./modules/TransportationScreen/CarModal";
import TransferModal from "./modules/TransportationScreen/TransferModal";
import Routes from "./routes/components/Routes";
import { initializePrivateData, initializePublicData } from "./store/actions/initializeActions";
import "./styles/app.scss";
import { parse } from "query-string";
import { loginFromPortal, checkUser } from "./store/actions/userActions";
import { getUserIsAuthenticated } from "./store/selectors/userSelectors";

export const Initializer = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { redirect } = parse(window.location.search);
  const isAuthenticated = useSelector(getUserIsAuthenticated);

  useEffect(() => {
    (async () => {
      try {
        if (redirect) {
          await dispatch(loginFromPortal(redirect));
        } else {
          await dispatch(checkUser());
        }
        await Promise.all([initializePublicData(), dispatch(initializePrivateData())]);
      } catch (e) {
        await initializePublicData();
      }
      setLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <>
      <Routes />
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000} />
      <ReactTooltip effect="solid" />
      <ReactTooltip effect="solid" place="left" id="left-sided" type="info" />
      {isAuthenticated && (
        <>
          <CheckoutStandAlone />
          <CarModal />
          <TransferModal />
        </>
      )}
    </>
  ) : (
    <AppLoading />
  );
};
