import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "i18next";
import { parse } from "query-string";
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import Loading from "../components/Loading";
import PageFooter from "../components/PageFooter";
import useClickDetect from "../hooks/useClickDetect";
import { api } from "../services/api";
import Logger from "../services/Logger";
import { checkKeyCode } from "../utils/helpers";
import languageStorage from "../utils/languageStorage";

const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const $el = useClickDetect(() => setMenuIsActive(false));
  const [isBusy, setIsBusy] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [lang, setLang] = useState("tr");
  const [menuIsActive, setMenuIsActive] = useState(false);

  useEffect(() => {
    setIsBusy(true);
    api()
      .get(location.pathname + location.search)
      .then(() => {
        setIsBusy(false);
      })
      .catch(() => {
        setIsBusy(false);
      });
  }, [location.pathname, location.search]);

  const changeLanguage = useCallback(
    (newLang: "en" | "tr") => {
      setLang(newLang);
      i18n.changeLanguage(newLang).then(() => languageStorage().save(newLang));
      setMenuIsActive(!menuIsActive);
    },
    [menuIsActive]
  );

  const passwordHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  const passwordConfirmationHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value);
  }, []);

  let submit: () => Promise<void>;
  submit = useCallback(async () => {
    const authToken = parse(location.search).key;

    if (!authToken || !password.length || password !== passwordConfirmation) {
      return;
    }
    setIsBusy(true);
    //TODO: adapt to new api
    try {
      await api().post("changepwd", password, {
        headers: {
          "Authorization-Key": authToken,
          "Content-Type": "text/plain"
        }
      });
      history.push("/login?changed");
      Logger.flash("You can now sign in.");
    } catch (e) {
      Logger.flashError("Token is not valid.");
    }
    setIsBusy(false);
  }, [password, passwordConfirmation, history, location.search]);

  const keyHandler = useCallback(
    (e: KeyboardEvent) => {
      if (checkKeyCode(e, 13) && !isBusy) {
        submit();
      }
    },
    [isBusy, submit]
  );

  useEffect(() => {
    languageStorage().refresh();
    setLang(languageStorage().current());
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [keyHandler]);

  return (
    <div className="login-page">
      <div className="login-page-container">
        <div className="login-form">
          <div className="login-header">
            <span className="login-title">
              <img src="/images/trippep-light.png" alt="" />
            </span>
          </div>
          <div className="login-body">
            <div className="input">
              <span>
                <FontAwesomeIcon icon={["far", "key"]} />
              </span>
              <input
                value={password}
                placeholder={t("login.password.placeholder")}
                type="password"
                onChange={passwordHandler}
              />
            </div>
            <div className="input">
              <span>
                <FontAwesomeIcon icon={["far", "key"]} />
              </span>
              <input
                value={passwordConfirmation}
                placeholder={t("login.password.placeholderConfirm")}
                type="password"
                onChange={passwordConfirmationHandler}
              />
            </div>

            <div ref={$el} className="lang-select pointer">
              <div className="lang">
                <FontAwesomeIcon icon={["fas", "globe"]} />
                <p onClick={() => setMenuIsActive(!menuIsActive)}>{lang === "tr" ? "Türkçe" : "English"}</p>
                <FontAwesomeIcon icon={["far", "chevron-down"]} />
              </div>
              {menuIsActive && (
                <div className="lang-dropdown">
                  <p onClick={() => changeLanguage("en")}>English</p>
                  <p onClick={() => changeLanguage("tr")}>Türkçe</p>
                </div>
              )}
            </div>
          </div>
          <div className="login-footer">
            <button disabled={isBusy} onClick={submit} className="login-button">
              {isBusy ? <Loading /> : t("login.password.button")}
            </button>
          </div>
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

export default ChangePasswordPage;
